import { useState } from "react";
import { DateRangePicker } from "react-date-range";

export default function DatePickerModal() {
  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(), 
    endDate: new Date(),
    key: "selection",
  });

  const handleSelect = (ranges) => {
    setSelectionRange({
      startDate: ranges.selection.startDate,
      endDate: ranges.selection.endDate,
      key: "selection",
    });
  };
  return <DateRangePicker ranges={[selectionRange]} onChange={handleSelect} />;
}
