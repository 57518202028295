import React from 'react';
import Skeleton from 'react-loading-skeleton';
// import { Skeleton } from '@mui/material';
// import 'react-loading-skeleton/dist/skeleton.css';
import 'react-loading-skeleton/dist/skeleton.css';

function DataGridSkeleton({ columns, rowCount }) {
  return (
    <>
      {Array.from({ length: rowCount }).map((_, rowIndex) => (
        <div key={rowIndex} style={{ display: 'flex', padding: '8px' }} className='container'>
          {columns.map((col, colIndex) => (
            <div key={colIndex} style={{ marginRight: '8px', flex: 1 }} className='container'>
              <Skeleton height={40} width={col.width || 100} />
            </div>
          ))}
        </div>
      ))}
    </>
  );
}

export default DataGridSkeleton;
