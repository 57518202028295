import React, { useEffect, useState } from "react";
import "../../Styles/AddSupplier.css";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import TextInputWithAll from "../Inputs/TextInputWithAll";
import TextInput from "../Inputs/TextInput";
import DropDownWithInput from "../Inputs/DropDownWithInput";
import { makeApiCall } from "../../StaticComponents/API";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";

function AddCarton() {
  const maxChars = 50;
  const maxchardesc = 150;
  const [inputValue, setInputValue] = useState("");
  const [aliasNameCharCount, setAliasNameCharCount] = useState(0);
  const [descCharCount, setDescCharCount] = useState(0);
  const [file, setFile] = useState();
  const [value, setValue] = useState();
  const [fileName, setFileName] = useState("");
  const [image, setImage] = useState(null);
  const [status, setStatus] = useState("");
  const [show, setShow] = useState(false);

  const token = localStorage.getItem("token");
  let clientID = localStorage.getItem("CLIENT_ID");

  const navigate = useNavigate();

  const lengthOptions = [
    { value: "MTR", label: "m" },
    { value: "CM", label: "cm" },
    { value: "IN", label: "in" },
    { value: "FEET", label: "feet" },
    { value: "MM", label: "mm" },
  ];

  const weightOptions = [
    { value: "GM", label: "g" },
    { value: "KG", label: "Kg" },
    { value: "LBS", label: "lbs" },
  ];

  const volumeOptions = [
    { value: "CB_CM", label: "cm³" },
    { value: "CB_MTR", label: "m³" },
    { value: "CB_FEET", label: "feet³" },
    { value: "CB_MM", label: "mm³" },
    { value: "CB_IN", label: "in³" },
  ];

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (
      ["LENGTH", "WIDTH", "HEIGHT", "VOLUME", "MAX_WEIGHT_CAPACITY"].includes(
        name
      ) &&
      !/^\d*\.?\d*$/.test(value)
    ) {
      return;
    }

    let newErrors = { ...errors };

    if (name === "ALIAS_NAME") {
      if (!value) {
        newErrors.ALIAS_NAME = "Alias Name is required.";
      } else {
        delete newErrors.ALIAS_NAME;
      }
    }

    // if (name === "DESCRIPTION") {
    //   if (!value) {
    //     newErrors.DESCRIPTION = "Description is required.";
    //   } else {
    //     delete newErrors.DESCRIPTION;
    //   }
    // }

    if (
      ["LENGTH", "WIDTH", "HEIGHT", "VOLUME", "MAX_WEIGHT_CAPACITY"].includes(
        name
      )
    ) {
      if (!value) {
        newErrors[name] = `${name} is required.`;
      } else if (!/^\d*\.?\d*$/.test(value)) {
        newErrors[name] = `${name} must be a valid number.`;
      } else {
        delete newErrors[name];
      }
    }

    setData({
      ...data,
      [name]: value,
    });

    setErrors(newErrors);

    if (name === "ALIAS_NAME") {
      setCharCountAlias(maxChars - value.length);
    } else if (name === "DESCRIPTION") {
      setCharCountDesc(maxchardesc - value.length);
    }
  };

  const addcartonvalues = {
    APP_CLIENT_ID: localStorage.getItem("CLIENT_ID"),
    ALIAS_NAME: "",
    DESCRIPTION: "",
    LENGTH: "",
    WIDTH: "",
    HEIGHT: "",
    SIZE_UNIT: "MTR",
    VOLUME: "",
    VOLUME_UNIT: "KG",
    MAX_WEIGHT_CAPACITY: "",
    MAX_WEIGHT_CAPACITY_UNIT: "KG",
  };

  const [data, setData] = useState(addcartonvalues);
  const [charCountAlias, setCharCountAlias] = useState(maxChars);
  const [charCountDesc, setCharCountDesc] = useState(maxchardesc);
  const [errors, setErrors] = useState({});

  const payload = {
    APP_CLIENT_ID: data.APP_CLIENT_ID,
    ALIAS_NAME: data.ALIAS_NAME,
    DESCRIPTION: data.DESCRIPTION,
    LENGTH: data.LENGTH,
    WIDTH: data.WIDTH,
    HEIGHT: data.HEIGHT,
    SIZE_UNIT: data.SIZE_UNIT,
    VOLUME: data.VOLUME,
    VOLUME_UNIT: data.VOLUME_UNIT,
    MAX_WEIGHT_CAPACITY: data.MAX_WEIGHT_CAPACITY,
    MAX_WEIGHT_CAPACITY_UNIT: data.MAX_WEIGHT_CAPACITY_UNIT,
  };

  console.log("Payload", payload);

  const formData = new FormData();
  formData.append("file", image);
  formData.append("mod", "Shipping");
  formData.append("action", "add-ctn");
  formData.append("payload", JSON.stringify(payload));

  console.log(image, "file");
  console.log(formData, "formdata");

  const handleSelectChange = (event) => {
    const { name, value } = event.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      console.log("Selected file:", e.target.files[0]);
      console.log("Selected file:", e.target.files[0].lastModifiedDate);
      setFile(URL.createObjectURL(e.target.files[0]));
      setImage(e.target.files[0]);
      setFileName(e.target.files[0].name);
    } else {
      console.error("No file selected");
    }
  };

  const handleDeleteImage = (e, indexToDelete) => {
    setFile(file.filter((_, index) => index !== indexToDelete));
    e.stopPropagation();
  };

  const handleSubmit = (e) => {
    

    const checkRequiredFields = () => {
      let missingFields = [];
      let newErrors = {};
      if (!payload.APP_CLIENT_ID) missingFields.push("App Client ID");

      if (!payload.ALIAS_NAME) {
        missingFields.push("Alias Name");
        newErrors.ALIAS_NAME = "Alias Name is required.";
      }
      // if (!payload.DESCRIPTION) {
      //   missingFields.push("Description");
      //   newErrors.DESCRIPTION = "Description is required.";
      // }
      if (!payload.LENGTH) {
        missingFields.push("Length");
        newErrors.LENGTH = "Length is required.";
      }
      if (!payload.WIDTH) {
        missingFields.push("Width");
        newErrors.WIDTH = "Width is required.";
      }
      if (!payload.HEIGHT) {
        missingFields.push("Height");
        newErrors.HEIGHT = "Height is required.";
      }
      if (!payload.VOLUME) {
        missingFields.push("Volume");
        newErrors.VOLUME = "Volume is required.";
      }
      if (!payload.MAX_WEIGHT_CAPACITY) {
        missingFields.push("Max Weight Capacity");
        newErrors.MAX_WEIGHT_CAPACITY = "Max Weight Capacity is required.";
      }

      setErrors(newErrors);
      if (!payload.ALIAS_NAME) missingFields.push("Alias Name");
      if (!payload.DESCRIPTION) missingFields.push("Description");
      if (
        payload.LENGTH === undefined ||
        payload.LENGTH === null ||
        payload.LENGTH === ""
      )
        missingFields.push("Length");
      if (
        payload.WIDTH === undefined ||
        payload.WIDTH === null ||
        payload.WIDTH === ""
      )
        missingFields.push("Width");
      if (
        payload.HEIGHT === undefined ||
        payload.HEIGHT === null ||
        payload.HEIGHT === ""
      )
        missingFields.push("Height");
      if (!payload.SIZE_UNIT) missingFields.push("Size Unit");
      if (
        payload.VOLUME === undefined ||
        payload.VOLUME === null ||
        payload.VOLUME === ""
      )
        missingFields.push("Volume");
      if (!payload.VOLUME_UNIT) missingFields.push("Volume Unit");
      if (
        payload.MAX_WEIGHT_CAPACITY === undefined ||
        payload.MAX_WEIGHT_CAPACITY === null ||
        payload.MAX_WEIGHT_CAPACITY === ""
      )
        missingFields.push("Max Carton Weight ");
      if (
        payload.MAX_WEIGHT_CAPACITY_UNIT === undefined ||
        payload.MAX_WEIGHT_CAPACITY_UNIT === null
      )
        missingFields.push(" Maximum Weight Capacity Unit");
        setErrors(newErrors);
        return missingFields;
    };

    const missingFields = checkRequiredFields(payload);
    console.log(missingFields);
    console.log(formData, "formdata");

    axios({
      method: "post",
      url: process.env.REACT_APP_API_URL,
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        console.log(res, "carton added successfully");
        if (missingFields.length > 0) {
          const errorMessage = `Please fill in the following fields: ${missingFields.join(", ")}`;
          toast.error(errorMessage);
          return; 
        }
    
        if (res.data.XscStatus !== 0) {
          toast.success(res.data.XscMessage);
          localStorage.setItem("shippingTab", "carton");
    
          navigate("/shipping");
        } else {
          toast.error(res.data.XscMessage);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div id="supp_bg">
      <div
        id="suppliers_topbar_bg"
        style={{ paddingLeft: "0", paddingRight: "0" }}
      >
        <div className="container d-flex justify-content-between align-items-center">
          <div id="dashboard_sub_header">Add New Carton</div>
        </div>
      </div>
      <div className="container">
        <div id="add_supplier_bg">
          <form className="row">
            <div className="col-12">
              <label
                style={{ width: "380px" }}
                htmlFor="file-input"
                className="d-flex align-items-center"
                id=""
              >
                <div id="upload_img_bg" style={{ cursor: "pointer" }}>
                  {file ? (
                    <img src={file} alt="Uploaded file" />
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="40"
                      height="40"
                      viewBox="0 0 40 40"
                      fill="none"
                    >
                      <path
                        d="M34.9987 25V30H39.9987V33.3333H34.9987V38.3333H31.6654V33.3333H26.6654V30H31.6654V25H34.9987ZM35.0124 5C35.9254 5 36.6654 5.74158 36.6654 6.65567V21.6667H33.332V8.33333H6.66536V31.665L23.332 15L28.332 20V24.715L23.332 19.714L11.377 31.6667H23.332V35H4.98503C4.07211 35 3.33203 34.2585 3.33203 33.3443V6.65567C3.33203 5.74127 4.09088 5 4.98503 5H35.0124ZM13.332 11.6667C15.173 11.6667 16.6654 13.159 16.6654 15C16.6654 16.841 15.173 18.3333 13.332 18.3333C11.4911 18.3333 9.9987 16.841 9.9987 15C9.9987 13.159 11.4911 11.6667 13.332 11.6667Z"
                        fill="#5A5892"
                      />
                    </svg>
                  )}
                </div>
                <div id="upload_img_text">
                  {/* {fileName}{" "} */}
                  {/* {file  ? (
                    <div id="change_img_text">Change</div>
                  ) : (
                    <>
                      Upload Supplier Logo
                      <div id="img_size">It should be 400 by 400 pixels</div>
                      <div id="upload_img_button">Upload</div>
                    </>
                  )} */}
                  <>
                    Upload Carton Icon(Optional)
                    <div id="img_size">It should be 400 by 400 pixels</div>
                    <div id="upload_img_button">
                      {file ? "Change" : "Upload"}
                    </div>
                  </>
                </div>
              </label>
              <TextInputWithAll
                name="file-input"
                type="file"
                onChange={handleChange}
                id="file-input"
                style={{ display: "none" }}
                maxLength={1}
                accept="image/*"
              />
            </div>

            <div className="col-5" id="add_supplier_input">
              <div className="position-relative" style={{ width: "380px" }}>
                <label>Alias Name <span style={{color: "#950F27"}}>*</span></label>
                <br />
                <TextInputWithAll
                  type="text"
                  onChange={handleInputChange}
                  maxLength={maxChars}
                  name="ALIAS_NAME"
                  style={{ paddingRight: "75px" }}
                  // className={errors.ALIAS_NAME ? "error-input" : ""}
                />
                {errors.ALIAS_NAME && (
                  <span style={{ color: "red" }}>{errors.ALIAS_NAME}</span>
                )}

                <div
                  id="remaining_characters"
                  className="position-absolute transalate-middle-x "
                >
                  {charCountAlias}/50
                </div>
              </div>
              <div className=" position-relative" style={{ width: "380px" }}>
                <label>Length <span style={{color: "#950F27"}}>*</span></label>
                <br />
                <DropDownWithInput
                  options={lengthOptions}
                  inputProps={{
                    className: "packaging_input",
                    style: {
                      width: "310px",
                      marginRight: "0px",
                      border: "none",
                      background: "transparent",
                      marginTop: "0px",
                    },
                    name: "LENGTH",
                    value: data.LENGTH,
                    onChange: handleInputChange,
                  }}
                  selectProps={{
                    className: "selectpackageing_type",
                    style: {
                      width: "55px",
                      border: "none",
                      background: "transparent",
                      outline: "none",
                      paddingLeft: "0px",
                    },
                    name: "SIZE_UNIT",
                    value: data.SIZE_UNIT,
                    onChange: handleSelectChange,
                  }}
                  className={errors.LENGTH ? "error-input" : ""}
                />
                {errors.LENGTH && (
                  <span style={{ color: "red" }}>{errors.LENGTH}</span>
                )}
              </div>
              <div className="position-relative" style={{ width: "380px" }}>
                <label>Height <span style={{color: "#950F27"}}>*</span></label>
                <br />
                <DropDownWithInput
                  options={lengthOptions}
                  inputProps={{
                    className: `packaging_input ${
                      errors.HEIGHT ? "error-input" : ""
                    }`,
                    style: {
                      width: "310px",
                      marginRight: "0px",
                      border: "none", 
                      background: "transparent",
                      marginTop: "0px",
                    },
                    name: "HEIGHT",
                    value: data.HEIGHT,
                    onChange: handleInputChange,
                  }}
                  className={errors.HEIGHT ? "error-input" : ""}
                  selectProps={{
                    className: `selectpackageing_type ${
                      errors.HEIGHT ? "error-input" : ""
                    }`,
                    style: {
                      width: "55px",
                      border: "none",
                      background: "transparent",
                      outline: "none",
                      paddingLeft: "0px",
                    },
                    name: "SIZE_UNIT",
                    value: data.SIZE_UNIT,
                    onChange: handleSelectChange,
                  }}
               
                />
                {errors.HEIGHT && (
                  <span style={{ color: "red" }}>{errors.HEIGHT}</span>
                )}
              </div>

              <div className=" position-relative" style={{ width: "380px" }}>
                <label>Max Carton Weight <span style={{color: "#950F27"}}>*</span></label>
                <br />
                <DropDownWithInput
                  options={weightOptions}
                  inputProps={{
                    className: "packaging_input",
                    style: {
                      width: "310px",
                      marginRight: "0px",
                      border: "none",
                      background: "transparent",
                      marginTop: "0px",
                    },
                    name: "MAX_WEIGHT_CAPACITY",
                    value: data.MAX_WEIGHT_CAPACITY,
                    onChange: handleInputChange,
                  }}
                  selectProps={{
                    className: "selectpackageing_type",
                    style: {
                      width: "55px",
                      border: "none",
                      background: "transparent",
                      outline: "none",
                      paddingLeft: "0px",
                    },
                    name: "MAX_WEIGHT_CAPACITY_UNIT",
                    value: data.MAX_WEIGHT_CAPACITY_UNIT,
                    onChange: handleSelectChange,
                  }}
                  className={errors.MAX_WEIGHT_CAPACITY ? "error-input" : ""}
                />
                {errors.MAX_WEIGHT_CAPACITY && (
                  <span style={{ color: "red" }}>
                    {errors.MAX_WEIGHT_CAPACITY}
                  </span>
                )}
              </div>
            </div>
            <div className="col-5" id="add_supplier_input">
              <div className="position-relative" style={{ width: "380px" }}>
                <label>Description</label>
                <br />
                <TextInputWithAll
                  type="text"
                  // onChange={handleDescInputChange}
                  // maxLength={descMaxChars}
                  name="DESCRIPTION"
                  value={data.DESCRIPTION}
                  maxLength={maxchardesc}
                  onChange={handleInputChange}
                  style={{ paddingRight: "75px" }}
                  // className={errors.DESCRIPTION ? "error-input" : ""}
                />{" "}
                {errors.DESCRIPTION && (
                  <span style={{ color: "red" }}>{errors.DESCRIPTION}</span>
                )}
                <div id="remaining_characters" className="d9380549810">
                  {charCountDesc}/150
                </div>
              </div>
              <div className=" position-relative" style={{ width: "380px" }}>
                <label>Width <span style={{color: "#950F27"}}>*</span></label>
                <br />
                <DropDownWithInput
                  options={lengthOptions}
                  inputProps={{
                    className: "packaging_input",
                    style: {
                      width: "310px",
                      marginRight: "0px",
                      border: "none",
                      background: "transparent",
                      marginTop: "0px",
                    },
                    name: "WIDTH",
                    value: data.WIDTH,
                    onChange: handleInputChange,
                  }}
                  selectProps={{
                    className: "selectpackageing_type",
                    style: {
                      width: "55px",
                      border: "none",
                      background: "transparent",
                      outline: "none",
                      paddingLeft: "0px",
                    },
                    name: "SIZE_UNIT",
                    value: data.SIZE_UNIT,
                    onChange: handleSelectChange,
                  }}
                  className={errors.WIDTH ? "error-input" : ""}
                />
                {errors.WIDTH && (
                  <span style={{ color: "red" }}>{errors.WIDTH}</span>
                )}
              </div>
              <div className=" position-relative" style={{ width: "380px" }}>
                <label>Volume <span style={{color: "#950F27"}}>*</span></label>
                <br />
                <DropDownWithInput
                  options={volumeOptions}
                  inputProps={{
                    className: "packaging_input",
                    style: {
                      width: "310px",
                      marginRight: "0px",
                      border: "none",
                      background: "transparent",
                      marginTop: "0px",
                    },
                    name: "VOLUME",
                    value: data.VOLUME,
                    onChange: handleInputChange,
                  }}
                  selectProps={{
                    className: "selectpackageing_type",
                    style: {
                      width: "60px",
                      border: "none",
                      background: "transparent",
                      outline: "none",
                      paddingLeft: "0px",
                    },
                    name: "VOLUME_UNIT",
                    value: data.VOLUME_UNIT,
                    onChange: handleSelectChange,
                  }}
                  className={errors.VOLUME ? "error-input" : ""}
                />
                {errors.VOLUME && (
                  <span style={{ color: "red" }}>{errors.VOLUME}</span>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
      <div id="add_supplier_footer" className="">
        <div>
          <div id="add_supplier_buttons" className="container">
            {" "}
            <div></div>
            {/* <DeleteSupplier show={show} onHide={() => setShow(false)} /> */}
            <div
              className="d-flex align-items-center justify-content-right "
              style={{ gap: "12px" }}
            >
              <a href="/shipping">
                {" "}
                <div id="cancel">Cancel</div>
              </a>
              <a >
                <div
                  id="submitsupplier"
                  type="submit"
                  className="submit"
                  onClick={handleSubmit}
                >
                  Add Carton
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
        position="bottom-center"
        style={{ width: "max-content" }}
      />
    </div>
  );
}

export default AddCarton;
