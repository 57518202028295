import React, { useEffect, useState } from "react";
import "../../Styles/AddSupplier.css";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import DeleteSupplier from "./DeleteSupplier";
import axios from "axios";
import {
  useParams,
  useHistory,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { makeApiCall } from "../../StaticComponents/API";
import { toast, ToastContainer } from "react-toastify";
import TextInput from "../Inputs/TextInput";
import TextInputWithAll from "../Inputs/TextInputWithAll";
import DropDownInput from "../Inputs/DropDownInput";

function EditSupplier() {
  let [searchParams] = useSearchParams();

  const maxChars = 50;
  const [inputValue, setInputValue] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [file, setFile] = useState();
  const [image, setImage] = useState(null);
  const [filename, setFilename] = useState("");

  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [states, setStates] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedCities, setSelectedCities] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [selectedDistrict, setSelectedDistrict] = useState("");
  const [selectedCity, setSelectedCity] = useState("");

  // const { id } = searchParams.get("id");
  let token = localStorage.getItem("token");
  const [show, setShow] = useState(false);
  const clientID = localStorage.getItem("CLIENT_ID");
  const [favoriteCount, setFavoriteCount] = useState(0);

  const initialData = {
    ADDR_1: "",
    ADDR_2: "",
    ALTERNATE_CONTACT_NUMBER: "",
    CITY: "",
    CITY_ID: "",
    COMPANY_ID: "",
    COUNTRY: "",
    COUNTRY_ID: "",
    CREATED_ON: "",
    DISTRICT_ID: "",
    DISTRICT_NAME: "",
    EMAIL: "",
    FAX: "",
    LOGO: "",
    PHONE_NUMBER: "",
    POSTAL_CODE: "",
    REG_NUMBER: "",
    STATE: "",
    STATE_ID: "",
    SUPP_NAME: "",
    SUPP_NUM: "",
    TAX_NUMBER: "",
    WEBSITE: "",
    IS_ACTIVE: "",
    IS_SUPP_FAV: "Y",
  };
  console.log(initialData);
  const [suppInfo, setSuppInfo] = useState(initialData);
  const [suppStatus, setSuppStatus] = useState(0);
  const CLIENT_SUPPLIER_ID = localStorage.getItem("CLIENT_SUPPLIER_ID");
  console.log(CLIENT_SUPPLIER_ID);
  // const CLIENT_SUPPLIER_ID = 1;
  // const StatusOptions = [
  //   { value: "Y", label: "Active" },
  //   { value: "N", label: "Inactive" },DropDownInput
  // ];
  const [task, setTask] = useState({});
  console.log(suppInfo);
  useEffect(() => {
    makeApiCall({
      mod: "Location",
      action: "get-countries",
      payload: { CURRENT_PAGE: "", SEARCH_KEYWORD: "" },
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        const countries = response.XscData.COUNTRY_NAMES;
        if (Array.isArray(countries)) {
          setCountries(
            countries.map((country) => ({
              value: country.ID,
              label: country.COUNTRY,
            }))
          );
        }
      })
      .catch(console.error);

    // Fetch supplier details if an ID is present
    if (CLIENT_SUPPLIER_ID) {
      makeApiCall({
        mod: "Supplier",
        action: "get-supplier-detail",
        payload: { CLIENT_SUPPLIER_ID },
        headers: { Authorization: `Bearer ${token}` },
      })
        .then((res) => {
          console.log(res);
          const supplierInfo = res.XscData.INFO;
          setSuppInfo(supplierInfo);
          console.log(res.XscData.INFO, "supp-info");
          setSelectedCountry(supplierInfo.COUNTRY_ID || "");
          setSelectedState(supplierInfo.STATE_ID || "");
          setSelectedCity(supplierInfo.CITY_ID || "");
          setSuppStatus(supplierInfo.IS_ACTIVE === 1 ? "active" : "inactive");

          localStorage.setItem("company_id", res.XscData.INFO.COMPANY_ID);
        })
        .catch(console.error);
    }
  }, [CLIENT_SUPPLIER_ID]);
  console.log(suppInfo, "Supp Info");
  console.log(selectedCity);
  console.log(selectedCountry);
  console.log(selectedState);
  console.log(suppStatus);

  useEffect(() => {
    if (selectedCountry) {
      makeApiCall({
        mod: "Location",
        action: "get-states",
        payload: {
          COUNTRY_ID: selectedCountry,
          CURRENT_PAGE: "",
          SEARCH_KEYWORD: "",
        },
        headers: { Authorization: `Bearer ${token}` },
      })
        .then((res) => {
          const states = res.XscData.STATE_NAMES;
          if (Array.isArray(states)) {
            const mappedRows = states.map((state) => ({
              value: state.ID,
              label: state.STATE,
            }));
            setStates(mappedRows);
            console.log(mappedRows);
          }
        })
        .catch(console.error);
    } else {
      setStates([]);
      setSelectedState("");
      setCities([]);
      setSelectedCity("");
    }
  }, [selectedCountry]);

  useEffect(() => {
    if (selectedState) {
      makeApiCall({
        mod: "Location",
        action: "get-cities",
        payload: {
          DISTRICT_ID: selectedState,
          CURRENT_PAGE: "",
          SEARCH_KEYWORD: "",
        },
        headers: { Authorization: `Bearer ${token}` },
      })
        .then((res) => {
          const cities = res.XscData.CITY_NAMES;
          if (Array.isArray(cities)) {
            const mappedRows = cities.map((city) => ({
              value: city.ID,
              label: city.CITY,
            }));
            setCities(mappedRows);
          }
        })
        .catch(console.error);
    } else {
      setCities([]);
      setSelectedCity("");
    }
  }, [selectedState]);

  const handleCountryChange = (event) => {
    const value = event.target.value;
    setSelectedCountry(value);
    setSuppInfo((prev) => ({ ...prev, COUNTRY_ID: value }));
  };

  const handleStateChange = (event) => {
    const value = event.target.value;
    setSelectedState(value);
    setSuppInfo((prev) => ({ ...prev, STATE_ID: value }));
  };

  const handleCityChange = (event) => {
    const value = event.target.value;
    setSelectedCity(value);
    setSuppInfo((prev) => ({ ...prev, CITY_ID: value }));
  };

  const handleStatus = (event) => {
    const value = event.target.value;
    console.log("Dropdown value:", value);

    const statusMap = {
      active: 1,
      inactive: 0,
    };

    const mappedValue = statusMap[value] !== undefined ? statusMap[value] : 0;

    // Set the mapped status value
    setSuppStatus(mappedValue);

    // Update suppInfo with the new mapped value
    setSuppInfo((prev) => ({ ...prev, mappedValue: value }));

    console.log("Mapped status value:", mappedValue);
  };
  const StatusOptions = [
    { value: "1", label: "Active" },
    { value: "0", label: "Inactive" },
  ];
  const navigate = useNavigate();

  const checkRequiredFields = () => {
    let missingFields = [];
    if (!suppInfo.SUPP_NAME) missingFields.push("Supplier Name");
    if (!suppInfo.REG_NUMBER) missingFields.push("Registration Number");
    if (!suppInfo.EMAIL) missingFields.push("Email Address");
    if (!suppInfo.PHONE_NUMBER) missingFields.push("Contact Number");

    return missingFields;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    const emailRegex =
      /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9]))+$/;
    if (!suppInfo.SUPP_NAME) {
      toast.error("Please enter Supplier Name", {
        position: "bottom-center",
      });
      return;
    }
    if (!suppInfo.EMAIL) {
      toast.error("Please enter Email Address", {
        position: "bottom-center",
      });
      return
    } else if (!emailRegex.test(suppInfo.EMAIL)) {
      toast.error("Please enter a correct Email Address", {
        position: "bottom-center",
      });
      return
    }
     
   
    if (!suppInfo.PHONE_NUMBER) {
      toast.error("Please enter Phone Number", {
        position: "bottom-center",
      });
      return;
    }
    if (!suppInfo.COUNTRY_ID) {
      toast.error("Please select country", {
        position: "bottom-center",
      });
      return;
    }
    if (!suppInfo.STATE_ID) {
      toast.error("Please select state/province", {
        position: "bottom-center",
      });
      return;
    }
    if (!suppInfo.CITY_ID) {
      toast.error("Please select city", {
        position: "bottom-center",
      });
      return;
    }
    if (!suppInfo.ADDR_1) {
      toast.error("Please enter Address line 1", {
        position: "bottom-center",
      });
      return;
    }
// if(!image){
//   toast.error("Please upload a image",{
//     position:"bottom-center"
//   })
//   return
// }
   

    const payload = {
      CLIENT_SUPPLIER_ID: CLIENT_SUPPLIER_ID,
      NAME: suppInfo.SUPP_NAME,
      REG_NUMBER: suppInfo.REG_NUMBER,
      EMAIL: suppInfo.EMAIL,
      CONTACT_NUMBER: suppInfo.PHONE_NUMBER,
      ALT_CONTACT_NUMBER: suppInfo.ALTERNATE_CONTACT_NUMBER,
      FAX: suppInfo.FAX,
      TAX: suppInfo.TAX_NUMBER,
      WEBSITE: suppInfo.WEBSITE,
      APP_CLIENT_ID: localStorage.getItem("CLIENT_ID"),
      ADDR_1: suppInfo.ADDR_1,
      ADDR_2: suppInfo.ADDR_2,
      COUNTRY_ID: suppInfo.COUNTRY_ID,
      STATE_ID: suppInfo.STATE_ID,
      STATE: suppInfo.STATE,
      DISTRICT_ID: suppInfo.DISTRICT_ID,
      CITY_ID: suppInfo.CITY_ID,
      CITY: suppInfo.CITY,
      POSTAL_CODE: suppInfo.POSTAL_CODE,
      IS_SUPP_FAV: suppInfo.IS_SUPP_FAV,
      IS_ACTIVE: suppInfo.IS_ACTIVE,
    };

    console.log(payload,"qwertyuiop")
    const payload1 = {
      COMPANY_ID: localStorage.getItem("company_id"),
    };
    console.log("this is company id", payload1.COMPANY_ID);

    const formData = new FormData();
    formData.append("file", image);
    formData.append("mod", "Company");
    formData.append("action", "change-company-logo");
    formData.append("payload", JSON.stringify(payload1));

    console.log("image we are uploaded", formData.get("image"));
    axios({
      method: "post",
      url: process.env.REACT_APP_API_URL,
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        console.log(payload);
        console.log(res, "supplier image edited");
        if (res.XscStatus === 1) {
          toast.success("image uploaded", {
            position: "bottom-center",
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });

    try {
      const response = await makeApiCall({
        mod: "Supplier",
        action: "update-supplier",
        payload: payload,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log(payload, "payload");
      if (response && response.XscMessage) {
        console.log(response);
        console.log(response.XscMessage, "edit sup");
        console.log("STATE ID:", suppInfo.STATE_ID);
        console.log("STATE Name:", suppInfo.STATE);
      } else {
        console.log("Supplier updated successfully");
      }
      if (response.XscStatus === 1) {
        toast.success("Supplier updated successfully", {
          position: "bottom-center",
        });
        setFavoriteCount((prevCount) => {
          return suppInfo.IS_SUPP_FAV === "Y" ? prevCount + 1 : prevCount - 1;
        });
        setTimeout(() => {
          navigate("/suppliers");
        }, 1000);
      }
    } catch (error) {
      console.error("Error updating Supplier:", error);
      toast.error("Failed to update Supplier. Please try again.");
    }
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const phoneRegex = /^\d*$/;

    if (name === "PHONE_NUMBER" && !phoneRegex.test(value)) {
      setSuppInfo({
        ...suppInfo,
        [name]: suppInfo[name],
      });
    } else {
      setSuppInfo({
        ...suppInfo,
        [name]: value,
      });
    }
    console.log(name);
  };

  const handleFileChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setFile(URL.createObjectURL(e.target.files[0]));
    } else {
      console.error("No file selected");
    }
  };

  const handleChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setFile(URL.createObjectURL(e.target.files[0]));
      setImage(e.target.files[0]);
      setFilename(e.target.files[0].name);
    } else {
      console.error("No file selected");
    }
  };
  const SUPPLIER_NUM = localStorage.getItem("CLIENT_SUPPLIER_NUM");

  const API_URL = process.env.REACT_APP_API_URL;

  return (
    <div id="supp_bg">
      <div id="suppliers_topbar_bg" className="">
        <div className="container d-flex justify-content-between align-items-center">
          <div id="header_name" className="d-flex ">
            <div>
              <a href="/suppliers">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M7.82843 11.0009H20V13.0009H7.82843L13.1924 18.3648L11.7782 19.779L4 12.0009L11.7782 4.22266L13.1924 5.63687L7.82843 11.0009Z"
                    fill="var(--onSurface)"
                  />
                  <path
                    d="M7.82843 11.0009H20V13.0009H7.82843L13.1924 18.3648L11.7782 19.779L4 12.0009L11.7782 4.22266L13.1924 5.63687L7.82843 11.0009Z"
                    fill="black"
                    fill-opacity="0.2"
                  />
                  <path
                    d="M7.82843 11.0009H20V13.0009H7.82843L13.1924 18.3648L11.7782 19.779L4 12.0009L11.7782 4.22266L13.1924 5.63687L7.82843 11.0009Z"
                    fill="black"
                    fill-opacity="0.2"
                  />
                  <path
                    d="M7.82843 11.0009H20V13.0009H7.82843L13.1924 18.3648L11.7782 19.779L4 12.0009L11.7782 4.22266L13.1924 5.63687L7.82843 11.0009Z"
                    fill="black"
                    fill-opacity="0.2"
                  />
                  <path
                    d="M7.82843 11.0009H20V13.0009H7.82843L13.1924 18.3648L11.7782 19.779L4 12.0009L11.7782 4.22266L13.1924 5.63687L7.82843 11.0009Z"
                    fill="black"
                    fill-opacity="0.2"
                  />
                </svg>
              </a>
            </div>

            <span className="ps-2">
              {" "}
              Edit Supplier
              <div id="edit_supplier_id">{suppInfo.SUPP_NUM || "N/A"}</div>
            </span>
          </div>
        </div>
      </div>
      <div className="container">
        <div id="add_supplier_bg">
          <form className="row">
            <div className="col-12">
              <label
                style={{ width: "380px" }}
                htmlFor="file-input"
                className="d-flex align-items-center"
                id=""
              >
                <div id="upload_img_bg" style={{ cursor: "pointer" }}>
                  {suppInfo.LOGO !== null ? (
                    file ? (
                      <img src={file} alt="Uploaded file" />
                    ) : (
                      <img
                        src={API_URL + `media/${suppInfo.LOGO}`}
                        alt="Supplier logo"
                      />
                    )
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="40"
                      height="40"
                      viewBox="0 0 40 40"
                      fill="none"
                    >
                      <path
                        d="M34.9987 25V30H39.9987V33.3333H34.9987V38.3333H31.6654V33.3333H26.6654V30H31.6654V25H34.9987ZM35.0124 5C35.9254 5 36.6654 5.74158 36.6654 6.65567V21.6667H33.332V8.33333H6.66536V31.665L23.332 15L28.332 20V24.715L23.332 19.714L11.377 31.6667H23.332V35H4.98503C4.07211 35 3.33203 34.2585 3.33203 33.3443V6.65567C3.33203 5.74127 4.09088 5 4.98503 5H35.0124ZM13.332 11.6667C15.173 11.6667 16.6654 13.159 16.6654 15C16.6654 16.841 15.173 18.3333 13.332 18.3333C11.4911 18.3333 9.9987 16.841 9.9987 15C9.9987 13.159 11.4911 11.6667 13.332 11.6667Z"
                        fill="#5A5892"
                      />
                    </svg>
                  )}
                </div>
                <div id="upload_img_text">
                  {/* {fileName}{" "} */}
                  {/* {file  ? (
                    <div id="change_img_text">Change</div>
                  ) : (
                    <>
                      Upload Supplier Logo
                      <div id="img_size">It should be 400 by 400 pixels</div>
                      <div id="upload_img_button">Upload</div>
                    </>
                  )} */}
                  <>
                    Upload Supplier Logo
                    <div id="img_size">It should be 400 by 400 pixels</div>
                    <div id="upload_img_button">
                      {file ? "Change" : "Upload"}
                    </div>
                  </>
                </div>
              </label>
              <TextInputWithAll
                name="file-input"
                type="file"
                onChange={handleChange}
                id="file-input"
                style={{ display: "none" }}
                maxLength={1}
                accept="image/*"
              />
            </div>

            <div className="col-5" id="add_supplier_input">
              <div className="position-relative" style={{ width: "380px" }}>
                <label>
                  Supplier Name <span style={{ color: "#950F27" }}>*</span>
                </label>
                <br />
                <TextInputWithAll
                  type="text"
                  onChange={handleInputChange}
                  maxLength={maxChars}
                  value={suppInfo.SUPP_NAME}
                  name="SUPP_NAME"
                />{" "}
                <div
                  id="remaining_characters"
                  className="position-absolute transalate-middle-x "
                >
                  {suppInfo.SUPP_NAME.length}/50
                </div>
              </div>{" "}
              <div>
                {/* <label>Phone Number</label> */}
                {/* <br /> */}
                {/* <input type="text" /> */}
                <div>
                  <label>
                    Phone Number <span style={{ color: "#950F27" }}>*</span>
                  </label>
                  <br />
                  {/* <input type="text" /> */}
                  <div id="phone_input">
                    {" "}
                    {/* <PhoneInput
                    international
                    defaultCountry="CN"
                    value={suppInfo.contactNumber}
                    name="contactNumber"
                    onChange={handleNumber}
                  /> */}
                    <TextInput
                      type=""
                      value={suppInfo.PHONE_NUMBER}
                      name="PHONE_NUMBER"
                      onChange={handleInputChange}
                      pattern="^[0-9]+$"
                    />
                  </div>
                </div>
              </div>
              <div className="position-relative" style={{ width: "380px" }}>
                <label>Registration Number</label>
                <br />
                <TextInput
                  type="text"
                  value={suppInfo.REG_NUMBER}
                  name="REG_NUMBER"
                  onChange={handleInputChange}
                />{" "}
              </div>
              <div>
                <label>
                  Address line 1 <span style={{ color: "#950F27" }}>*</span>
                </label>
                <br />
                <TextInput
                  type="text"
                  name="ADDR_1"
                  onChange={handleInputChange}
                  value={suppInfo.ADDR_1}
                />
              </div>
              <DropDownInput
                options={states}
                id="selectCountry"
                value={selectedState}
                name="state"
                onChange={handleStateChange}
                label={
                  <div>
                    State/Province <span style={{ color: "#950F27" }}>*</span>
                  </div>
                }
                placeholder="Select State/Province"
                required
              />
              <div>
                <label>Zip/Postal Code</label>
                <br />
                <TextInput
                  type="text"
                  value={suppInfo.POSTAL_CODE}
                  name="POSTAL_CODE"
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <label>Fax No</label>
                <br />
                <TextInput
                  name="fax"
                  type="text"
                  value={suppInfo.FAX}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="col-5" id="add_supplier_input">
              <div className="position-relative" style={{ width: "380px" }}>
                <label>
                  Email Address <span style={{ color: "#950F27" }}>*</span>
                </label>
                <br />
                <TextInput
                  type="email"
                  value={suppInfo.EMAIL}
                  name="EMAIL"
                  onChange={handleInputChange}
                />{" "}
              </div>

              <div>
                <label>Website</label>
                <br />
                <TextInput
                  type="text"
                  onChange={handleInputChange}
                  name="WEBSITE"
                  value={suppInfo.WEBSITE}
                />
              </div>

              <DropDownInput
                tag={<span style={{ color: "#950F27" }}>*</span>}
                options={countries}
                id="selectCountry"
                value={selectedCountry}
                name="country"
                onChange={handleCountryChange}
                label="Country"
                placeholder="Select Country"
                required
              />
              <div>
                <label>Address line 2</label>
                <br />
                <TextInput
                  type="text"
                  value={suppInfo.ADDR_2}
                  name="ADDR_2"
                  onChange={handleInputChange}
                />
              </div>
              <DropDownInput
                options={cities}
                id="selectCountry"
                value={selectedCity}
                name="city"
                onChange={handleCityChange}
                label={
                  <div>
                    City <span style={{ color: "#950F27" }}>*</span>
                  </div>
                }
                placeholder="Select City"
                required
              />
              <div>
                <label>Tax No</label>
                <br />
                <TextInput
                  type="text"
                  value={suppInfo.TAX_NUMBER}
                  onChange={handleInputChange}
                  name="TAX_NUMBER"
                />
              </div>
              {/* <DropDownInput
                options={["active", "inactive"]}
                id="selectCountry"
                value={suppInfo.mappedValue} 
                name="suppStatus"
                onChange={handleStatus} 
                label="Supplier Status"
                placeholder="Select status"
                required
              />   
               <p>Selected Status: {suppStatus.STATUS}</p> */}

              <DropDownInput
                options={StatusOptions}
                id="selectCountry"
                label="Supplier Status"
                placeholder="Select Status"
                name="STATUS"
                value={suppInfo.IS_ACTIVE === null ? "1" : suppInfo.IS_ACTIVE} // Ensures correct display
                onChange={(e) => {
                const newStatus = e.target.value; // Get selected value
                 setSuppInfo((prev) => ({
                   ...prev,
                IS_ACTIVE: newStatus, // Update IS_ACTIVE in state
                 }));
                 }}
                />
            </div>
          </form>
        </div>
      </div>
      <div id="add_supplier_footer" className="">
        <div>
          <div id="add_supplier_buttons" className="container">
            {" "}
            <div id="delete_supplier_button" onClick={() => setShow(true)}>
              Delete Supplier
            </div>
            <DeleteSupplier show={show} onHide={() => setShow(false)} />
            <div
              className="d-flex align-items-center justify-content-right "
              style={{ gap: "12px" }}
            >
              <a href="/suppliers" className="text-decoration-none">
                <div id="cancel">Cancel</div>
              </a>
              <a href="/suppliers" className="text-decoration-none">
                <div id="submitsupplier" onClick={handleSubmit}>
                  Update Supplier
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer style={{ width: "max-content" }} />
    </div>
  );
}

export default EditSupplier;
const DropDownStatus = ({
  options,
  id,
  label,
  placeholder,
  value,
  name,
  onChange,
}) => (
  <div>
    <label htmlFor={id}>{label}</label>
    <select id={id} name={name} value={value} onChange={onChange}>
      <option value="" disabled>
        {placeholder}
      </option>
      {options.map((option) => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </select>
  </div>
);
