import React from "react";
import { Modal } from "react-bootstrap";
import { useNavigate, useSearchParams } from 'react-router-dom';
import { makeApiCall } from '../../StaticComponents/API';
import { toast } from 'react-toastify';


function DeleteContainer(props) {

  let [searchParams] = useSearchParams();
  let token = localStorage.getItem("token");

  let APP_CLIENT_ID = localStorage.getItem("CLIENT_ID");
  let CONTAINER_ID = searchParams.get("id");
  console.log(APP_CLIENT_ID);
  console.log(CONTAINER_ID);
  const navigate = useNavigate("");
  const DeleteContainer = () => {
    const payload = {
      APP_CLIENT_ID: APP_CLIENT_ID,
      CONTAINER_ID:CONTAINER_ID,
    };
    makeApiCall({
      mod: "Shipping",
      action: "delete-container",
      payload: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((res) => {
      console.log(res, "deleted");

      setTimeout(() => {
        toast.success("Container Deleted Successfully!");
        props.onHide();
        setTimeout(() => {
        
          navigate("/shipping");
          
        }, 1000);
      }, 1000);
    });
  };

  return (
    <div>
      <Modal {...props}>
        <div>
          <div id="delete_Supplier">Delete Container</div>
          <div id="delete_supplier_content">
            Are you sure you want to delete this Particular Container? <br />
            It cannot be undone.
          </div>
          <div id="delete_footer">
            <div id="delete_cancel" onClick={()=>props.onHide()}>Cancel</div>
            <div id="delete_submit" onClick={DeleteContainer}>Delete Permanently</div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default DeleteContainer;
