import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../../Styles/ReceivePayment.css";
import { DataGrid } from "@mui/x-data-grid";
import AddNewPayment from "./AddNewPayment";
import { makeApiCall } from "../../../StaticComponents/API";
import EditPayment from "./EditPayment";
import ViewPayment from "./ViewPayment";

export default function PaySupplier({ show, onHide }) {
  const [addNew, setAddNew] = useState(false);
  const [edit, setEdit] = useState(false);
  const [view, setView] = useState(false);
  const [rows, setRows] = useState([]);
  const [pageSize, setPageSize] = useState(20);
  const [currencySymbol, setCurrencySymbol] = useState("");
  const [invoiceDetails, setInvoiceDetails] = useState({
    invoiceNo: "",
    invoiceAmount: "",
    dueAmount: "",
    currencySymbol:"",
  });
  let token = localStorage.getItem("token");
  const invoiceID = localStorage.getItem("INVOICE_ID");
  useEffect(() => {
    const payload = { INVOICE_ID: invoiceID };
    makeApiCall({
      mod: "Supplier",
      action: "pay_supplier_history",
      payload: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        console.log("request for paysuppliers", payload);
        console.log("pay suppliers in orders records fetched", response);
        if (response.XscData && response.XscData.INVOICE_DETAILS.length > 0) {
          const invoice = response.XscData.INVOICE_DETAILS[0];
          setInvoiceDetails({
            invoiceNo: invoice.INVOICE_ID,
            invoiceAmount: invoice.INVOICE_AMOUNT,
            dueAmount: invoice.DUE_AMOUNT,
          });
        }
        if (response.XscData && response.XscData.PAYMENT_DETAILS) {
          const paymentRows = response.XscData.PAYMENT_DETAILS.map(
            (payment, index) => ({
              PAYMENT_ID: index + 1 || "N/A",
              id: payment.SUPP_ORDER_AP_ID || "N/A", 
              PAYMENT_TYPE: payment.PAYMENT_TYPE,
              TRANSACTION_NO: payment.TXN_ID || "N/A",
              AMOUNT_SETTLED: `${payment.INVOICE_CURRENCY_SYMBOL || ""}${payment.AMOUNT_SETTLED}`,
              MM_FILE:payment.PAYMENT_FILE,
              OPERATING_CURRENCY_SYMBOL:payment.INVOICE_CURRENCY_SYMBOL
            })
          );
          setRows(paymentRows);
          setCurrencySymbol(response.XscData.INVOICE_DETAILS[0].INVOICE_CURRENCY_SYMBOL);
          console.log(paymentRows);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [invoiceID,show]);

  const columns = [
    {
      field: "PAYMENT_ID",
      headerName: "Payment No",
      width: 130,
      cellClassName: "sticky-column",
      align: "left",
    },
    {
      field: "PAYMENT_TYPE",
      headerName: "Payment Type",
      width: 155,
      editable: false,
      align: "left",
    },
    {
      field: "TRANSACTION_NO",
      headerName: "Transaction No",
      width: 162,
      editable: false,
      align: "left",
    },
    {
      field: "AMOUNT_SETTLED",
      headerName: "Amount Settled",
      width: 164,
      editable: false,
      align: "left",
    },
    {
      field: "ACTION",
      width: 104,
      editable: false,
      sortable: false,
      align: "left",
      renderHeader: () => <div></div>,
      renderCell: (params) => (
        <div
          className="d-flex flex-row align-items-center"
          style={{ gap: 15, paddingTop: 6 }}
        >
          <div
            className="d-flex flex-column align-items-center"
            style={{ gap: 6 }}
          >
            <div
              className="d-flex flex-row align-items-center"
              style={{ cursor: params.row.MM_FILE ? "pointer" : "default", width:"50px" }}
              onClick={() => params.row.MM_FILE && handleView(params.row.MM_FILE)}
            >
              {params.row.MM_FILE && (
                <>
                  <h2 id="view_file">View</h2>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M10.6688 6.27614L4.93109 12.0139L3.98828 11.0711L9.72601 5.33333H4.66883V4H12.0021V11.3333H10.6688V6.27614Z"
                      fill="#635BFF"
                    />
                  </svg>
                </>
              )}
            </div>
          </div>
          <svg
            onClick={() => handleEdit(params.row.id)}
            style={{ cursor: "pointer" }}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M6.41421 15.8895L16.5563 5.74738L15.1421 4.33316L5 14.4753V15.8895H6.41421ZM7.24264 17.8895H3V13.6468L14.435 2.21184C14.8256 1.82132 15.4587 1.82132 15.8492 2.21184L18.6777 5.04027C19.0682 5.43079 19.0682 6.06395 18.6777 6.45447L7.24264 17.8895ZM3 19.8895H21V21.8895H3V19.8895Z"
              fill="#1B1B24"
            />
          </svg>
        </div>
      ),
    }
    
  ];
  console.log(invoiceDetails.dueAmount);
  const handleAddNew = () => {
    setAddNew(true);
    onHide();
  };

  const handleEdit = (value) => {
    setEdit(true);
    onHide();
    console.log("INVOICE AP ID",value)
    localStorage.setItem("INV_AP_ID",value)
  };
const API_URL=process.env.REACT_APP_API_URL
  const handleView = (fileName) => {
    const fileUrl = `${API_URL}media/${fileName}`; 
    window.open(fileUrl, '_blank');
 
  };

  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        centered
        size="lg"
        style={{ width: "100%" }}
        className="custom-modal"
      >
        <Modal.Body>
          <div id="header">
            <h2 id="receive_payment">Pay Supplier</h2>
          </div>
          <div id="body">
            <div id="invoice">
              Invoice No:{" "}
              <span id="invoice_no"> {invoiceDetails.invoiceNo}</span>
            </div>
            <div id="invoice">
              Invoice Amount:{" "}
              <span id="invoice_no"> {currencySymbol}{invoiceDetails.invoiceAmount}</span>
            </div>
            <div id="invoice">
              Due Amount:{" "}
              <span id="invoice_no">{currencySymbol}{invoiceDetails.dueAmount}</span>
            </div>
            <div
              style={{
                height: 331,
                width: "100%",
                marginBottom: 20,
                marginTop: 20,
              }}
            >
              <DataGrid
                rows={rows}
                columns={columns}
                pageSize={pageSize}
                rowsPerPageOptions={[10, 20, 50]}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                disableSelectionOnClick
                hideFooterPagination
                hideFooterRowCount
                hideFooter
                hideFooterSelectedRowCount
              />
            </div>
            <div className="d-flex flex-row gap-1">
            {parseFloat(invoiceDetails.dueAmount) > 0 && (
              <>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M11 11V5H13V11H19V13H13V19H11V13H5V11H11Z"
                  fill="#635BFF"
                />
              </svg>{" "}
              <h2 id="add_payment" onClick={handleAddNew}>
                Add New Payment
              </h2>
              </>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <AddNewPayment show={addNew} onHide={() => setAddNew(false)} />
      <EditPayment show={edit} onHide={() => setEdit(false)} />
      <ViewPayment show={view} onHide={() => setView(false)} />
    </>
  );
}
