import { useState } from "react";
import Filter from "../../StaticComponents/Filter";
import SearchBar from "../../StaticComponents/SearchBar";
import "../../Styles/ReportComponents.css";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { Dropdown } from "react-bootstrap";
import DatePickerModal from "./DatePickerModal";
import AccountReceivableTable from "./AccountReceivableTable";
import AccountReceivableFilter from "./AccountReceivableFilter";
import { makeApiCall } from "../../StaticComponents/API";
import { useEffect } from "react";
export default function AccountReceivable() {
  const [modalShow, setModalShow] = useState(false);
  const [searchkeyword, setsearchkeyword] = useState("");
  const onSearch = (value) => {
    setsearchkeyword(value);
  };
  const [totalcustomers, settotalcustomers] = useState();
  const [pendingrec, setpendingrec] = useState();
  const APP_CLIENT_ID = localStorage.getItem("CLIENT_ID");
  const token = localStorage.getItem("token");
  const payload = {
    APP_CLIENT_ID: APP_CLIENT_ID,
    SEARCH_KEYWORD: "",
    CURRENT_PAGE: 1,
  };
  const [results, setresults] = useState(0);
  useEffect(() => {
    makeApiCall({
      mod: "Report",
      action: "account_receivable_list",
      payload: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        console.log("request", payload);
        console.log("account receivable records fetched", response); // Check the response here
        settotalcustomers(response.XscData.TOTAL_CUSTOMERS);
        setpendingrec(
          `${response.XscData.PENDING_RECEIVABLE.CURRENCY_SYMBOL}${response.XscData.PENDING_RECEIVABLE.ACCOUNT_RECEIVABLE}` ||
            0
        );
        setresults(response.XscData.REPORT_LIST_COUNT);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [APP_CLIENT_ID]);
  const [notify, setnotify] = useState(0);
  const onNotify = (value) => {
    setnotify(value);
  };
  return (
    <div id="report_bg">
      <div className="container">
        <div id="report_overview_heading" className="row">
          <div className="col-md-4">
            <div id="total_stock">
              Total Customers
              <div id="total_count">{totalcustomers}</div>
            </div>
          </div>
          <div className="col-md-4">
            <div id="total_stock">
              Pending Receivables
              <div id="total_count">{pendingrec}</div>
            </div>
          </div>
        </div>
        <div className="d-flex" id="invFilters">
          <SearchBar onSearch={onSearch} />
          <div onClick={() => setModalShow(true)} style={{ cursor: "pointer" }}>
            <Filter notify={notify} />
          </div>
          <AccountReceivableFilter
            show={modalShow}
            onHide={() => setModalShow(false)}
            onNotify={onNotify}
          />
          <Dropdown>
            <Dropdown.Toggle
              style={{
                marginLeft: "12px",
                width: "301px",
                height: "40px",
                backgroundColor: "var(--onSurfaceLowest)",
                color: "var(--onSurface)",
                border: "1px solid var(--outline1)",
                borderRadius: "var(--cr---3)",
              }}
            >
              Date
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <DatePickerModal />
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div id="report_tab_bg">
          <h2 className="totoal_1000_results">{results} Results</h2>
          <AccountReceivableTable searchkeyword={searchkeyword} />
        </div>
      </div>
    </div>
  );
}
