import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { makeStyles } from "@material-ui/styles";
import SupplierPurchaseHistory from "./SupplierPurchaseHistory";
import { useNavigate } from "react-router-dom";
import po_img from "../../../assets/po_img.png";
import { makeApiCall } from "../../../StaticComponents/API";
import { useSearchParams } from "react-router-dom";
import nodataimage3 from "../../../assets/no_data_image_3.png";

const useStyles = makeStyles({
  row: {
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "var(--size-140) !important",
  },
});

export default function SupplierPriceHistoryTable({ searchQuery }) {
  const API_URL=process.env.REACT_APP_API_URL;

  const [modalShow, setModalShow] = useState(false);
  const [page, setpage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const APP_CLIENT_ID = localStorage.getItem("CLIENT_ID");
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  const CLIENT_SUPP_ID = localStorage.getItem("CLIENT_SUPPLIER_ID");

  const classes = useStyles();
  console.log(searchQuery, "search");
  const payload = {
    APP_CLIENT_ID: localStorage.getItem("CLIENT_ID"),
    CLIENT_SUPP_ID: CLIENT_SUPP_ID,
    SEARCH_KEYWORD: searchQuery,
  };

  const columns = [
    {
      field: "SUPP_P_NUMBER",
      headerName: "Sup.Prod.ID",
      width: 150,
      cellClassName: "sticky-column",
      align: "center",
    },
    {
      field: "mnx_id",
      headerName: "Mnx.Prod.ID",
      width: 200,
      cellClassName: "sticky-column",
      align: "center",
    },
    {
      field: "PRODUCT_NAME",
      headerName: "Product Name",
      width: 275,
      editable: false,
    },
    {
      field: "PICTURE",
      headerName: "Picture",
      width: 150,
      editable: false,
      color: "#f5f2ff",
      renderCell: (params) => {
        const image= params.row.PICTURE ? `${API_URL}media/${params.row.PICTURE}`:po_img
        return (
          <div id="po_pic_bg">
            <img src={image} />
          </div>
        );
      },
    },
    {
      field: "SUPPLIER_NAME",
      headerName: "Supplier Name",
      width: 250,
      editable: false,
    },
    {
      field: "LOCATION",
      headerName: "Location",
      width: 150,
      editable: false,
    },
    {
      field: "PRICE",
      headerName: "Price",
      width: 125,
      editable: false,
    },
    {
      field: "PH",
      headerName: "PH",
      width: 100,
      editable: false,
      renderCell: (params) => {
        return <svg
        style={{ cursor: "pointer" }}
        onClick={(e) => {
          localStorage.setItem("PRODUCT_ID",params.row.id)
          e.stopPropagation();
          setModalShow(true);
        }}
        width="18"
        height="20"
        viewBox="0 0 18 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14 0V2H17.0066C17.5552 2 18 2.44495 18 2.9934V19.0066C18 19.5552 17.5551 20 17.0066 20H0.9934C0.44476 20 0 19.5551 0 19.0066V2.9934C0 2.44476 0.44495 2 0.9934 2H4V0H14ZM4 4H2V18H16V4H14V6H4V4ZM6 14V16H4V14H6ZM6 11V13H4V11H6ZM6 8V10H4V8H6ZM12 2H6V4H12V2Z"
          fill="#635BFF"
        />
      </svg>
      },
    },
  ];



  const initialRows = [
    // {
    //   id: "F-1671",
    //   mnx_id: "M0121",
    //   PRODUCT_NAME: "12PCS MIRROR FASTE...",
    //   SUPPLIER_NAME: "BNS Clothes Service",
    //   LOCATION: "China, Yun",
    //   PRICE: "¥1.20",
    // },
  ];

  const [rows, setRows] = useState(initialRows);

 

  useEffect(() => {
    makeApiCall({
      mod: "Supplier",
      action: "supp_price_history",
      payload: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        console.log("request", payload);
        console.log("Price history list fetched successfully!", response); // Check the response here
        const price = response.XscData.ORDER_STATUS_FILTER;

        if (Array.isArray(price)) {
          const mappedRows = price.map((priceHistory) => ({
            id:priceHistory.P_ID,
            SUPP_P_NUMBER: priceHistory.SUPP_P_NUMBER || "N/A",
            mnx_id: priceHistory.CLIENT_P_NUMBER || "N/A",
            PRODUCT_NAME: priceHistory.PROD_DESC || "N/A",
            SUPPLIER_NAME: priceHistory.NAME || "N/A",
            LOCATION: priceHistory.LOCATION || "N/A",
            PRICE:
              priceHistory.PRICE != null && priceHistory.PRICE !== ""
                ? `${priceHistory.CURRENCY} ${priceHistory.PRICE}`
                : "N/A",
            PICTURE: priceHistory.P_IMAGE,
          }));
          setRows(mappedRows);
        } else {
          console.error("Suppliers data is not an array or is missing");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [searchQuery, page, pageSize]);

  return (
    <>
      <div style={{ height: 600, width: "100%", marginTop: "20px" }}>
        <DataGrid
          localeText={{
            noRowsLabel: (
              <div className="no_rows_css">
                <img src={nodataimage3} alt="" />
                <div id="no_data_found_text">
                  You don’t have Price History for this Supplier
                </div>
              </div>
            ),
          }}
          rows={rows}
          columns={columns}
          rowHeight={80}
          pageSize={pageSize}
          rowsPerPageOptions={[10, 20, 50]}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          disableSelectionOnClick
          classes={{ row: classes.row }}
        />
      </div>

      <SupplierPurchaseHistory
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
}
