import React, { useEffect, useState } from "react";
import { DataGrid, GridOverlay } from "@mui/x-data-grid";
import { makeStyles } from "@material-ui/styles";
import excelDownload from "../../assets/excelDownload.png";
import pdfDownload from "../../assets/pdfDownload.png";
import { makeApiCall } from "../../StaticComponents/API";
import { Tooltip } from "@material-ui/core";
import noDataImage from "../../assets/no_result_img.png";
import nodataimage from "../../assets/no_data_image_2.png";

const useStyles = makeStyles({
  header: {
    "& .MuiDataGrid-columnHeaderTitle": {
      fontWeight: "bold",
    },
    "& .MuiDataGrid-menuIcon": {
      display: "none",
    },
  },

  row: {
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "var(--size-140) !important",
  },

  headerDownloadPdf: {
    minWidth: "235px !important",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontSize: "14px",
    paddingLeft: "100px !important",
  },

  headerDownloadExcel: {
    minWidth: "158px !important",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontSize: "14px",
  },

  headerSupplierSelected: {
    minWidth: "173px !important",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontSize: "14px",
    marginLeft: "-70px",
  },

  headerSuppliersSelected: {
    minWidth: "173px !important",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontSize: "14px",
    marginLeft: "135px",
  },

  padding: {
    paddingLeft: "100px !important",
  },

  border: {
    "& .MuiCheckbox-root": {
      color: "var(--onSurface)",
    },
  },
});

export default function ReportSupplierTable(props) {
  const API_URL = process.env.REACT_APP_API_URL;
  const [pageSize, setPageSize] = useState(10);
  const [columns, setColumns] = useState([]);
  const [selection, setSelection] = useState([]);
  const [page, setpage] = useState(0);
  const handlepagechange = (newpage) => {
    setpage(newpage);
  };
  const initialrows = [];
  const classes = useStyles();
  const [rows, setrows] = useState(initialrows);
  const [total, settotal] = useState();

  const initialColumns = [
    {
      field: "SUP_NUM",
      headerName: "Sup.ID",
      width: 250,
      cellClassName: classes.padding,
      headerClassName: classes.padding,
      headerAlign: "left",
    },
    {
      field: "SUPPLIER_NAME",
      headerName: "Supplier Name",
      width: 250,
      editable: false,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "EMAIL",
      headerName: "Email",
      width: 250,
      editable: false,
      color: "#f5f2ff",
      headerAlign: "left",
    },
    {
      field: "PHONE_NUMBER",
      headerName: "Phone Number",
      width: 200,
      editable: false,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "LOCATION",
      headerName: "Location",
      width: 170,
      editable: false,
      align: "left",
      headerAlign: "left",
    },
  ];

  const additionalColumns = [
    {
      field: "SUP_NUM",
      headerName: "",
      width: 250,
      cellClassName: classes.padding,
      headerClassName: classes.headerDownloadPdf,
      renderHeader: () => (
        <div
          className="d-flex flex-row align-items-center"
          style={{
            gap: "8px",
            width: 250,
            height: "20px",
            display: "flex",
            alignItems: "left",
            cursor: "pointer",
          }}
          onClick={handlepdfdownload}
        >
          <div>
            <img src={pdfDownload} />
          </div>
          <span style={{ color: "#635bff" }} id="dwn_text">
            Download Pdf
          </span>
        </div>
      ),
      sortable: false,
    },
    {
      field: "SUPPLIER_NAME",
      headerName: "",
      width: 200,
      editable: false,
      align: "left",
      headerClassName: classes.headerDownloadExcel,
      renderHeader: () => (
        <div
          className="d-flex flex-row align-items-center"
          style={{
            gap: "8px",
            width: 250,
            height: "20px",
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
          }}
          onClick={handleexceldownload}
        >
          <div>
            <img src={excelDownload} />
          </div>
          <span style={{ color: "#635bff" }} id="dwn_text">
            Download Excel
          </span>
        </div>
      ),
      sortable: false,
    },
    {
      field: "EMAIL",
      headerName: "Email",
      width: 250,
      editable: false,
      color: "#f5f2ff",
      headerAlign: "left",
      renderHeader: () => <div></div>,
      sortable: false,
    },
    {
      field: "PHONE_NUMBER",
      headerName: "Phone Number",
      width: 200,
      editable: false,
      align: "left",
      headerAlign: "left",
      renderHeader: () => (
        <Tooltip title={`${selection.length} Suppliers Selected`}>
          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              marginRight: "20px",
            }}
          >
            {selection.length} Suppliers Selected
          </div>
        </Tooltip>
      ),
      sortable: false,
    },
    {
      field: "LOCATION",
      headerName: "Location",
      editable: false,
      align: "left",
      headerClassName: classes.headerSuppliersSelected,
      headerAlign: "left",
      renderHeader: () => <div></div>,
      sortable: false,
    },
  ];

  const handleSelectionChange = (newSelection) => {
    setSelection(newSelection);
  };

  useEffect(() => {
    if (selection.length > 0) {
      setColumns(additionalColumns);
    } else {
      setColumns(initialColumns);
    }
  }, [selection]);

  const APP_CLIENT_ID = localStorage.getItem("CLIENT_ID");
  const token = localStorage.getItem("token");
  const payload = {
    APP_CLIENT_ID: APP_CLIENT_ID,
    CURRENT_PAGE: page + 1,
    SEARCH_KEYWORD: props.searchkeyword,
  };
  useEffect(() => {
    makeApiCall({
      mod: "Report",
      action: "supplier_report",
      payload: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        console.log("request", payload);
        console.log("supplier report records fetched", response);
        const suppliers = response.XscData.SUPPLIER_DETAILS;
        props.setvalues(
          response.XscData.TOTAL_SUPPLIERS,
          response.XscData.REGULAR_SUPPLIERS,
          response.XscData.FAVOURITE_SUPPLIERS
        );
        if (Array.isArray(suppliers)) {
          const mappedRows = suppliers.map((supplier) => ({
            id: supplier.CLIENT_SUPPLIER_ID,
            SUP_NUM: supplier.SUPP_NUM,
            SUPPLIER_NAME: supplier.SUPPLIER_NAME,
            EMAIL: supplier.EMAIL,
            PHONE_NUMBER: supplier.PHONE_NUMBER,
            LOCATION: supplier.LOCATION,
          }));
          setrows(mappedRows);
        }else{
          setrows([]);
        }
        settotal(response.XscData.TOTAL_SUPPLIERS);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [page, props.searchkeyword]);

  const handlepdfdownload = () => {
    const payload1 = {
      APP_CLIENT_ID: APP_CLIENT_ID,
      CLIENT_SUPP_ID: selection,
    };
    makeApiCall({
      mod: "Report",
      action: "generate-suppliers-pdf",
      payload: payload1,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        console.log("request", payload1);
        console.log("supplier report pdf fetched", response);

        if (response.XscStatus === 1) {
          const url = `${API_URL}/${response.XscData.URL}`;
          fetch(url)
            .then((response) => response.blob())
            .then((blob) => {
              const link = document.createElement("a");
              link.href = URL.createObjectURL(blob);
              link.download = "report.pdf";
              link.click();
            });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleexceldownload = () => {
    const payload1 = {
      APP_CLIENT_ID: APP_CLIENT_ID,
      CLIENT_SUPP_ID: selection,
    };
    makeApiCall({
      mod: "Report",
      action: "generate-suppliers-excel",
      payload: payload1,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        console.log("request", payload1);
        console.log("supplier report excel fetched", response);

        if (response.XscStatus === 1) {
          const url = `${API_URL}/${response.XscData.URL}`;
          fetch(url)
            .then((response) => response.blob())
            .then((blob) => {
              const link = document.createElement("a");
              link.href = URL.createObjectURL(blob);
              link.download = "report.xlsx";
              link.click();
            });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  console.log("rows selected", selection);
  console.log("rows", rows);
  const CustomNoRowsOverlay = ({ searchkeyword }) => (
    <GridOverlay>
      <div className="no_rows_css" style={{ textAlign: "center" }}>
        <img
          src={searchkeyword ? noDataImage : nodataimage}
          alt="No Data"
          style={{ width: "103.26px", height: "100px" }}
        />
        <div
          id="no_data_found_text"
          style={{
            fontFamily: "Inter",
            fontWeight: 600,
            fontSize: "22px",
          }}
        >
          {searchkeyword
            ? "No results found in the list."
            : "No reports have been generated yet."}
        </div>
      </div>
    </GridOverlay>
  );
  return (
    <div
      style={{
        height: 600,
        width: "100%",
        marginTop: "20px",
        marginBottom: "40px",
      }}
    >
      <DataGrid
        localeText={{
          noRowsLabel: (
            <div className="no_rows_css" style={{ textAlign: "center" }}>
              <img
                src={props.searchkeyword ? noDataImage : nodataimage}
                alt="No Data"
                style={{ width: "103.26px", height: "100px" }}
              />
              <div
                id="no_data_found_text"
                style={{
                  fontFamily: "Inter",
                  fontWeight: 600,
                  fontSize: "22px",
                }}
              >
                {props.searchkeyword
                  ? "No results found in the list."
                  : "No reports have generated yet."}
              </div>
            </div>
          ),
        }}
        rows={rows}
        columns={columns}
        pageSize={pageSize}
        rowCount={total}
        rowsPerPageOptions={[10, 20, 50]}
        page={page}
        pagination
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        classes={{ columnHeader: classes.header, row: classes.row }}
        checkboxSelection
        onSelectionModelChange={(newSelection) =>
          handleSelectionChange(newSelection)
        }
        onPageChange={handlepagechange}
       
      />
    </div>
  );
}
