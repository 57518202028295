// TimestampToDate.js
import React from "react";

const TimestampToDate = ({ timestamp }) => {
  const date = new Date(timestamp * 1000); 
  const day = date.getDate();
  const month = date.toLocaleString("en-US", { month: "short" });
  const year = date.getFullYear();

  const formattedDate = `${day}-${month}-${year}`;

  return (
    <div>
      {formattedDate}
    </div>
  );
};

export default TimestampToDate;
