import React, { useEffect, useState } from "react";
import logo from "../../../assets/Manex Logo.png";
import { DataGrid } from "@mui/x-data-grid";
import po_img from "../../../assets/po_img.png";
import "react-datepicker/dist/react-datepicker.css";
import { makeStyles } from "@material-ui/styles";
import "../../../Styles/CreatePI.css";
import { makeApiCall } from "../../../StaticComponents/API";
import { useSearchParams } from "react-router-dom";

import num2words from "num2words";

const useStyles = makeStyles({
  subheadingRow: {
    textAlign: "center",
    fontWeight: "bold",
    minHeight: "32px !important",
    maxHeight: "32px !important",
    "& .MuiDataGrid-cell": {
      border: "none !important",
      lineHeight: "32px !important",
      minHeight: "32px !important",
      maxHeight: "32px !important",
    },
  },

  subheadingCell: {
    width: "100%",
    border: "0.5px solid var(--outlineVariant) !important",
    lineHeight: "32px !important",
    minHeight: "32px !important",
    maxHeight: "32px !important",
  },

  padding: { padding: "0px !important" },
});

export default function ViewInvoice() {
  const [poNumber, setPoNumber] = useState("");

  const classes = useStyles();

  let token = localStorage.getItem("token");
  let [searchParams] = useSearchParams();

  const [viewInvoiceInfo, setViewInvoiceInfo] = React.useState({
    customerData: "",
    clientData: "",
    shippingDetails: "",
    summaryDetails: "",
  });

  let clientCustID = localStorage.getItem("CLIENT_CUST_ID");
  let CUSTOMER_INVOICE_ID = localStorage.getItem("CUST_INV_ID");

  // const CUSTOMER_INVOICE_ID = searchParams.get("id");

  console.log(CUSTOMER_INVOICE_ID, "cus_id");

  function convertUnixTimestampToDate(unixTimestamp) {
    const date = new Date(unixTimestamp * 1000);
    const formattedDate = `${String(date.getDate()).padStart(2, '0')}-${String(date.getMonth() + 1).padStart(2, '0')}-${date.getFullYear()}`;
    return formattedDate;
}

  const getData = () => {
    const payload = {
      CLIENT_CUST_ID: clientCustID,
      CUSTOMER_INVOICE_ID: CUSTOMER_INVOICE_ID,
    };

    makeApiCall({
      mod: "CustomerInvoice",
      action: "view_cust_invoice_details",
      payload: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        console.log(response, "response");

        if (response.XscStatus === 1 && response.XscData) {
          const {
            SUMMARY_DETAILS,
            CUSTOMER_DETAILS,
            CLIENT_DATA,
            INVOICE_DETAILS,
            SHIPPING_DETAILS,
          } = response.XscData;

          const mappedRows = INVOICE_DETAILS.map((invoice) => ({
            id: invoice.CLIENT_PROD_NO,
            MNX_ID: invoice.CLIENT_PROD_NO,
            CUST_ITEM_ID: invoice.CUST_PROD_NO,
            DESCRIPTION: invoice.DESCRIPTION,
            TOTAL_QTY: invoice.TOTAL_QTY,
            RMB: `${invoice.CURRENCY_SYMBOL}${invoice.PRICE_PER_ITEM}`,
            TTL_RMB: `${invoice.CURRENCY_SYMBOL}${invoice.TOTAL_COST.toFixed(2)}`,
          }));

          setViewInvoiceInfo({
            customerData: CLIENT_DATA,
            clientData: CUSTOMER_DETAILS,
            shippingDetails: SHIPPING_DETAILS,
            summaryDetails: SUMMARY_DETAILS,
          });
          setRows(mappedRows);
        } else {
          console.log(response.XscMessage || "Failed to fetch data");
        }
      })
      .catch((error) => {
        console.error("API call failed: ", error);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const columns = [
    {
      field: "MNX_ID",
      headerName: "Mnx. Item ID",
      width: 200,
    },
    {
      field: "CUST_ITEM_ID",
      headerName: "Sup. Item ID",
      width: 200,
    },
    {
      field: "DESCRIPTION",
      headerName: "Description Goods",
      width: 275,
    },
    {
      field: "TOTAL_QTY",
      headerName: "TTL QTY",
      width: 150,
    },
    {
      field: "RMB",
      headerName: "RMB",
      width: 150,
    },
    {
      field: "TTL_RMB",
      headerName: "Total RMB",
      width: 130,
      cellClassName: "po_input",
    },
  ];

  const initialRows = [];

  const convertAmountToText = (amount) => {
    if (typeof amount !== "number") {
      return "AMOUNT NOT AVAILABLE";
    }

    const [whole, fraction] = amount.toFixed(2).split("."); // Split into dollars and cents
    const wholeText = num2words(whole).toUpperCase(); // Convert whole number to text
    const fractionText = num2words(fraction).toUpperCase(); // Convert cents to text

    return `${wholeText} DOLLARS AND ${fractionText} CENTS`;
  };
  const [rows, setRows] = useState(initialRows);

  return (
    <div>
      <div id="suppliers_topbar_bg" className="">
        <div className="container d-flex justify-content-between align-items-center">
          <div className="d-flex flex-column " style={{ gap: "4px" }}>
            <div id="header_name" className="d-flex ">
              <div>
                <a href="/customers/customer-info">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M7.82843 11.0009H20V13.0009H7.82843L13.1924 18.3648L11.7782 19.779L4 12.0009L11.7782 4.22266L13.1924 5.63687L7.82843 11.0009Z"
                      fill="var(--onSurface)"
                    />
                    <path
                      d="M7.82843 11.0009H20V13.0009H7.82843L13.1924 18.3648L11.7782 19.779L4 12.0009L11.7782 4.22266L13.1924 5.63687L7.82843 11.0009Z"
                      fill="black"
                      fill-opacity="0.2"
                    />
                    <path
                      d="M7.82843 11.0009H20V13.0009H7.82843L13.1924 18.3648L11.7782 19.779L4 12.0009L11.7782 4.22266L13.1924 5.63687L7.82843 11.0009Z"
                      fill="black"
                      fill-opacity="0.2"
                    />
                    <path
                      d="M7.82843 11.0009H20V13.0009H7.82843L13.1924 18.3648L11.7782 19.779L4 12.0009L11.7782 4.22266L13.1924 5.63687L7.82843 11.0009Z"
                      fill="black"
                      fill-opacity="0.2"
                    />
                    <path
                      d="M7.82843 11.0009H20V13.0009H7.82843L13.1924 18.3648L11.7782 19.779L4 12.0009L11.7782 4.22266L13.1924 5.63687L7.82843 11.0009Z"
                      fill="black"
                      fill-opacity="0.2"
                    />
                  </svg>
                </a>
              </div>

              <span className="ps-2">
                {" "}
                View Invoice
                <div id="edit_supplier_id">
                  {viewInvoiceInfo.summaryDetails.CUST_INV_NUM || "N/A"}
                </div>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div id="create_po_bg">
        <div className="container">
          <div id="po_logo" align="center">
            <img src={logo} alt="logo" />
            <div id="po_header_text">{viewInvoiceInfo.customerData.NAME}</div>
          </div>
          <div className="row" id="about_po_header">
            <div className="col-6">
              <div id="po_supplier_text">Supplier</div>
              <div id="po_supplier_name">
                {viewInvoiceInfo.customerData.NAME || "N/A"}
              </div>
              <div id="po_supplier_address">
                {viewInvoiceInfo.customerData.ADDR_1 || "N/A"}
                <br />
                {viewInvoiceInfo.customerData.ADDR_2}
              </div>
              <div id="po_supplier_ph">
                TEL:{" "}
                <span id="po_supplier_no">
                  {" "}
                  {viewInvoiceInfo.customerData.CONTACT_NUMBER || "N/A"}
                </span>
              </div>
              <div id="po_supplier_ph">
                FAX:{" "}
                <span id="po_supplier_no">
                  {" "}
                  {viewInvoiceInfo.customerData.FAX || "N/A"}
                </span>
              </div>
              <div id="po_supplier_ph">
                Invoice No:{" "}
                <span id="po_supplier_no">
                  {viewInvoiceInfo.summaryDetails.CUST_INV_NUM || "N/A"}
                </span>
              </div>
              <div id="po_supplier_ph">
                Date:{" "}{convertUnixTimestampToDate(viewInvoiceInfo.summaryDetails.CREATED_ON)}
                {/* <span id="po_supplier_no"><TimestampTo timestamp={viewInvoiceInfo.summaryDetails.CREATED_ON ||"N/A"}/></span> */}
              </div>
            </div>
            <div className="col-6 " id="about_po_left_bg" align="end">
              <div id="content_wrapper">
                {" "}
                <div id="po_supplier_text">Client</div>
                <div id="po_supplier_name">
                  {viewInvoiceInfo.clientData.NAME || "N/A"}
                </div>
                <div id="po_supplier_address">
                  {viewInvoiceInfo.clientData.ADDR_1 || "N/A"}
                  <br />
                  {viewInvoiceInfo.clientData.ADDR_2}
                </div>
                <div id="po_supplier_ph">
                  TEL:{" "}
                  <span id="po_supplier_no">
                    {viewInvoiceInfo.clientData.CONTACT_NUMBER || "N/A"}
                  </span>
                </div>
                <div id="po_supplier_ph">
                  FAX:{" "}
                  <span id="po_supplier_no">
                    {viewInvoiceInfo.clientData.FAX || "N/A"}
                  </span>
                </div>
                <div id="po_supplier_ph">
                  Customer PO No:{" "}
                  <span id="po_supplier_no">
                    {" "}
                    {viewInvoiceInfo.summaryDetails.CUST_PO_NUM || "N/A"}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              maxHeight: "fit-content",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <DataGrid
              rows={rows}
              columns={columns}
              hideFooterPagination={true}
              hideFooter={true}
              autoHeight
              rowHeight={80}
              showCellRightBorder
            />
          </div>
          <div
            style={{
              borderBottom: "0.5px solid var(--outlineVariant)",
            }}
          >
            <div className="row">
              <div className="col-5"></div>
              <div className="col-7">
                <div
                  className="d-flex flex-column"
                  style={{
                    gap: 40,
                    borderBottom: "0.5px solid var(--outlineVariant)",
                    marginTop: 100,
                  }}
                >
                  <div className="d-flex flex-row" style={{}}>
                    <div style={{width:"380px"}}>
                    <h2 id="total_cost_text">
                      Total Cost in{" "}
                      {viewInvoiceInfo.summaryDetails.OPERATING_CURRENCY ||
                        "RMB"}
                    </h2>
                    </div>
                    <div>
                    <p id="total_cost">
                      {viewInvoiceInfo.summaryDetails
                        .OPERATING_CURRENCY_SYMBOL || ""}
                      {viewInvoiceInfo.summaryDetails.TOTAL_GOODS_AMOUNT ||
                        "N/A"}
                    </p>
                    </div>
                  </div>
                  <div className="d-flex flex-row" style={{ }}>
                    <div style={{width:"380px"}}>
                    <h2 id="total_cost_text">
                      Total Cost in{" "}
                      {viewInvoiceInfo.summaryDetails.INVOICE_CURRENCY || "USD"}{" "}
                      *{" "}
                      {viewInvoiceInfo.summaryDetails.INVOICE_CURRENCY_RATE ||
                        "0.14"}
                    </h2>
                    </div>
                    <div>
                    <p id="total_cost">
                      {viewInvoiceInfo?.summaryDetails?.INVOICE_CURRENCY_SYMBOL
                        ? viewInvoiceInfo.summaryDetails.INVOICE_CURRENCY_SYMBOL
                        : ""}

                      {viewInvoiceInfo?.summaryDetails?.TOTAL_GOODS_AMOUNT &&
                      viewInvoiceInfo?.summaryDetails?.INVOICE_CURRENCY_RATE
                        ? (
                            viewInvoiceInfo.summaryDetails.TOTAL_GOODS_AMOUNT *
                            viewInvoiceInfo.summaryDetails.INVOICE_CURRENCY_RATE
                          ).toFixed(2)
                        : "N/A"}
                    </p>
                    </div>
                  </div>
                  <div className="d-flex flex-row" style={{}} >
                    <div style={{width:"380px"}}>
                    <h2 id="total_cost_text">Agent Commission {`(${viewInvoiceInfo.summaryDetails.AGENTS_COMMISION_PERCENTAGE} %)`}</h2>
                    </div>
                    <div>
                    <p id="total_cost">
                      {viewInvoiceInfo.summaryDetails.INVOICE_CURRENCY_SYMBOL ||
                        " "}
                      {((viewInvoiceInfo.summaryDetails.AGENTS_COMMISION_PERCENTAGE) * (viewInvoiceInfo.summaryDetails.TOTAL_GOODS_AMOUNT * viewInvoiceInfo.summaryDetails.INVOICE_CURRENCY_RATE)/100).toFixed(2) || "N/A"}
                    </p>
                    </div>
                  </div>
                  <div
                    className="d-flex flex-row"
                    style={{ marginBottom: 28 }}
                  > 
                  <div style={{width:"380px"}}>
                    <h2 id="total_cost_text" style={{ width: 264 }}>
                      Inland Transportation: {viewInvoiceInfo.summaryDetails.ALIAS_NAME}
                    </h2>
                    </div>
                    <div>
                    <p id="total_cost">
                      {viewInvoiceInfo.summaryDetails.INVOICE_CURRENCY_SYMBOL ||
                        ""}
                      {viewInvoiceInfo.summaryDetails.SHIPPING_COST || "N/A"}
                    </p>
                    </div>
                  </div>
                </div>

                <div
                  className="d-flex flex-column"
                  style={{
                    gap: 20,
                    margin: "20px 72px 80px 0px",
                  }}
                >
                  <div className="d-flex flex-row" style={{  }}>
                  <div style={{width:"380px"}}>
                    <h2 id="total_cost_text">Total Value</h2>
                    </div>
                    <div>
                    <h2 id="total_cost" style={{ fontWeight: 600 }}>
                      {viewInvoiceInfo.summaryDetails.INVOICE_CURRENCY_SYMBOL ||
                        ""}
                      {viewInvoiceInfo.summaryDetails.TOTAL_INV_AMOUNT || "N/A"}
                    </h2>
                    </div>
                  </div>
                  <div
                    className="d-flex flex-row align-items-center"
                    style={{ gap: 13 }}
                  >
                    <h2 id="total_cost_text" style={{ width: 524 }}>
                      SAY “{""}
                      {viewInvoiceInfo.summaryDetails.INVOICE_CURRENCY || " "}{" "}
                      {convertAmountToText(
                        viewInvoiceInfo.summaryDetails.TOTAL_INV_AMOUNT || "N/A"
                      )}
                      ” ONLY
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="d-flex flex-column gap-4"
            style={{
              marginTop: 44,
            }}
          >
            <div className="d-flex flex-row align-items-center gap-2">
              <div
                style={{
                  width: 5,
                  height: 40,
                  background: "var(--primaryContainer, #635bff)",
                }}
              ></div>
              <h2 id="shipping_details">Delivery Info</h2>
            </div>
            <div
              className="d-flex flex-column"
              style={{
                gap: 12,
                marginLeft: 17,
                marginBottom: 356,
              }}
            >
              <div className="d-flex flex-row gap-2">
                <strong>Delivery Date:</strong>{" "}
                {convertUnixTimestampToDate(viewInvoiceInfo.shippingDetails.DELIVERY_DATE) || "N/A"}
              </div>
              <div className="d-flex flex-row gap-2">
                <strong>Vessel Name:</strong>
                {viewInvoiceInfo.shippingDetails.VESSEL_NAME || "N/A"}
              </div>
              <div className="d-flex flex-row gap-2">
                <strong>Sailing Date:</strong>{" "}
                {convertUnixTimestampToDate(viewInvoiceInfo.shippingDetails.SAILING_DATE) || "N/A"}
              </div>
              <div className="d-flex flex-row gap-2">
                <strong>Payment Type:</strong>{" "}
                {viewInvoiceInfo.shippingDetails.PAYMENT_TYPE || "N/A"}
              </div>
              <div className="d-flex flex-row gap-2">
                <strong>Container No:</strong>
                {viewInvoiceInfo.shippingDetails.CONTAINER_NUM || "N/A"}
              </div>
              <div className="d-flex flex-row gap-2">
                <strong>Seal No:</strong>{" "}
                {viewInvoiceInfo.shippingDetails.SEAL_NUM || "N/A"}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
