import React, { useEffect, useState } from "react";
import SearchBar from "../../StaticComponents/SearchBar";
import SupplierTable from "../Supplier/SuppliersTable";
import { DataGrid } from "@mui/x-data-grid";
import ContainerDetail from "./ContainerDetail";
import { makeApiCall } from "../../StaticComponents/API";
import DataGridSkeleton from "../../StaticComponents/SkeletonLoading";
import noDataImage from "../../assets/Frame.svg"

function ContainerDashboard({ id }) {
  const [pageSize, setPageSize] = React.useState(10); // Default page size
  const [page, setpage] = useState(0);
  const [modalShow, setModalShow] = React.useState(false);
  const [containers, setContainers] = React.useState(false);
  const [selectedContainerId, setSelectedContainerId] = React.useState(null);
  const [total, settotal] = React.useState(0);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [noResults, setNoResults] = useState(false);


  const lengthOptions = [
    { value: "MTR", label: "m" },
    { value: "CM", label: "cm" },
    { value: "IN", label: "in" },
    { value: "FEET", label: "feet" },
    { value: "MM", label: "mm" },
  ];

  const weightOptions = [
    { value: "GM", label: "g" },
    { value: "KG", label: "Kg" },
    { value: "LBS", label: "lbs" },
  ];

  const volumeOptions = [
    { value: "CB_CM", label: "cm³" },
    { value: "CB_MTR", label: "m³" },
    { value: "CB_FEET", label: "feet³" },
    { value: "CB_MM", label: "mm³" },
    { value: "CB_IN", label: "in³" },
  ];

  const handlepagechange = (newpage) => {
    setpage(newpage);
  };
  const handlepagesizechange = (num) => {
    setPageSize(num);
    setpage(0);
  };
  const handleSearch = (query) => {
    setSearchQuery(query);
    setpage(0); // Reset page to 0 on search query change
  };
  console.log("page pagesize", page, pageSize);
  const payload = {
    APP_CLIENT_ID: localStorage.getItem("CLIENT_ID"),
    CURRENT_PAGE: page + 1,
    ITEM_PER_PAGE: pageSize,
    SEARCH_KEYWORD: searchQuery,
  };

  let token = localStorage.getItem("token");

  const getLabelFromValue = (value, options) => {
    const option = options.find((option) => option.value === value);
    return option ? option.label : "NA";
  };

  const columns = [
    {
      field: "ALIAS",
      headerName: "Alias",
      width: 200,
      height: 80,
      cellClassName: "sticky-column",
    },
    {
      field: "LENGTH",
      headerName: "Length",
      width: 150,
      editable: false,
      align: "left",
      height: 80,
      renderCell: (params) => params.value || "NA",
    },
    {
      field: "WIDTH",
      headerName: "Width",
      width: 150,
      editable: false,
      align: "left",
      height: 80,
      renderCell: (params) => params.value || "NA",
    },

    {
      field: "HEIGHT",
      headerName: "Height",
      type: "number",
      width: 150,
      editable: false,
      align: "left",
      height: 80,
      renderCell: (params) => params.value || "NA",
    },
    {
      field: "CAPACITY",
      headerName: "Capacity",
      width: 150,
      editable: false,
      align: "left",
      height: 80,
      renderCell: (params) => params.value || "NA",
    },
    {
      field: "TARE_WEIGHT",
      headerName: "Tare Weight",
      width: 150,
      editable: false,
      align: "left",
      height: 80,
      renderCell: (params) => params.value || "NA",
    },
    {
      field: "MAX_CARGO_WEIGHT",
      headerName: "Max Cargo Weight",
      width: 150,
      height: 80,
      editable: false,
      align: "left",
      //   headerClassName: "more_svg",
      renderCell: (params) => params.value,
    },
    {
      field: "EDIT",
      headerName: "",
      width: 80,
      height: 80,
      editable: false,
      align: "center",
      headerClassName: "more_svg",
      renderCell: (params) => createEditIcon(params.row.id),
    },
  ];

  const createEditIcon = (id) => (
    <a href={`/shipping/edit-container?id=${id}`}>
      <svg
        onClick={(e) => e.stopPropagation()}
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M5 18.89H6.41421L15.7279 9.57628L14.3137 8.16207L5 17.4758V18.89ZM21 20.89H3V16.6473L16.435 3.21232C16.8256 2.8218 17.4587 2.8218 17.8492 3.21232L20.6777 6.04075C21.0682 6.43127 21.0682 7.06444 20.6777 7.45496L9.24264 18.89H21V20.89ZM15.7279 6.74786L17.1421 8.16207L18.5563 6.74786L17.1421 5.33364L15.7279 6.74786Z"
          fill="var(--onSurfaceVariant)"
        />
      </svg>
    </a>
  );

  const initialRows = [];

  const [rows, setRows] = React.useState(initialRows);

  //   const handleFav = (id) => {
  //     setRows((prevRows) =>
  //       prevRows.map((row) =>
  //         row.id === id ? { ...row, FAVOURITE: !row.FAVOURITE } : row
  //       )
  //     );
  //   };

  const rowsWithIcons = rows.map((row) => ({
    ...row,
    // FAVOURITE: createFavIcon(row.FAVOURITE, () => handleFav(row.id)),
    EDIT: createEditIcon(row.id),
  }));

  useEffect(() => {

    makeApiCall({
      mod: "Shipping",
      action: "get-container-list",
      payload: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        console.log("request", payload);
        console.log("container records fetched", response);
        const containerItem = response.XscData.CONTAINER_DETAIL;

        if (Array.isArray(containerItem)) {
          const mappedRows = containerItem.map((itemCont) => ({
            id: itemCont.ID,
            ALIAS: itemCont.ALIAS_NAME,
            LENGTH: itemCont.LENGTH
              ? `${itemCont.LENGTH} ${getLabelFromValue(
                  itemCont.SIZE_UNIT,
                  lengthOptions
                )}`
              : "NA",
            WIDTH: itemCont.WIDTH
              ? `${itemCont.WIDTH} ${getLabelFromValue(
                  itemCont.SIZE_UNIT,
                  lengthOptions
                )}`
              : "NA",
            HEIGHT: itemCont.HEIGHT
              ? `${itemCont.HEIGHT} ${getLabelFromValue(
                  itemCont.SIZE_UNIT,
                  lengthOptions
                )}`
              : "NA",
            CAPACITY: itemCont.VOLUME
              ? `${itemCont.VOLUME} ${getLabelFromValue(
                  itemCont.VOLUME_UNIT,
                  volumeOptions
                )}`
              : "NA",
            TARE_WEIGHT: itemCont.TARE_WT
              ? `${itemCont.TARE_WT} ${getLabelFromValue(
                  itemCont.WEIGHT_UNIT,
                  weightOptions
                )}`
              : "NA",
            MAX_CARGO_WEIGHT: itemCont.MAX_WEIGHT
              ? `${itemCont.MAX_WEIGHT} ${getLabelFromValue(
                  itemCont.WEIGHT_UNIT,
                  weightOptions
                )}`
              : "NA",
          }));
          settotal(response.XscData.TOTAL_CONTAINER);
          setRows(mappedRows);
        } else {
          console.error("Containers data is not an array or is missing");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id, token, searchQuery, page, pageSize]);

  const handleRowClick = (params) => {
    setSelectedContainerId(params.row.id);
    setModalShow(true);
  };


  function CustomNoRowsOverlay() {
    if (loading) {
        return null; 
    }
    if (noResults) {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                }}
            >
                <img
                    src={noDataImage}
                    alt="No data found"
                    style={{ width: "200px", marginRight: "10px" }}
                />
                <div>No Data Found</div>
            </div>
        );
    }
    return null; 
}

  return (
    <div>
      <div className="container">
        <div
          className="d-flex justify-content-between"
          id="supp_filters"
          style={{ marginTop: "44px" }}
        >
          <div className="d-flex">
            <div>
              <SearchBar onSearch={handleSearch} />
            </div>
          </div>
          <a href="/shipping/add-container">
            <div id="create_po_button">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M11 11V5H13V11H19V13H13V19H11V13H5V11H11Z"
                  fill="#F7F7F7"
                />
              </svg>
              Add New Container
            </div>
          </a>
        </div>
        <div
          style={{
            height: 600,
            width: "100%",
            marginBottom: "80px",
            marginTop: "20px",
          }}
        >
          {loading ? (
            <DataGridSkeleton
              columns={columns}
              rowCount={pageSize}
              rows={rowsWithIcons}
            />
          ) : rows.length === 0 ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
              }}
            >
              <img
                src={noDataImage}
                alt="No data"
                style={{ width: "150px", height: "auto", marginBottom: "20px" }}
              />
              <p>No result found in the list</p>
            </div>
          ) : (
            <DataGrid
              onRowClick={handleRowClick}
              rows={rowsWithIcons}
              columns={columns}
              pageSize={pageSize}
              getRowId={(row) => row.id}
              pagination
              paginationMode="server"
              rowCount={total}
              rowsPerPageOptions={
                // [10, 20, 50]
                rows.length < 50
                  ? [10, 20, 50] // Default options
                  : rows.length > 20
                  ? [10, 20, rows.length] // Adjust if less than 50
                  : rows.length > 10
                  ? [10, rows.length] // Adjust if less than 20
                  : [rows.length] // Show all rows if less than 10
              }
              onPageChange={handlepagechange}
              onPageSizeChange={handlepagesizechange}
              loading={loading}
              disableSelectionOnClick
              components={{
                NoRowsOverlay: CustomNoRowsOverlay,
              }}
            />
          )}
          <ContainerDetail
            show={modalShow}
            onHide={() => setModalShow(false)}
            id={selectedContainerId}
          />
        </div>
      </div>
    </div>
  );
}
export default ContainerDashboard;
