import React, { useEffect, useState } from 'react'
import CustomerHeader from './CustomerHeader'
import CustomerDashboard from './CustomerDashboard'

const CustomerInfoDashboardLayout = () => {

  const [custActiveTab, setCustActiveTab] = useState(
    localStorage.getItem("CustActiveTab") === ""
      ? "Overview"
      : localStorage.getItem("CustActiveTab")
  );
 
  const handleTabChange = (tab) => {
    localStorage.setItem("CustActiveTab", tab);
    setCustActiveTab(tab);
  };
  return (
    <div>
        <CustomerHeader activeTab={custActiveTab} onTabChange={handleTabChange} />
        <CustomerDashboard activeTab={custActiveTab} onTabChange={handleTabChange} />
  </div>
  )
}

export default CustomerInfoDashboardLayout;