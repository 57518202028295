import React, { useEffect, useRef, useState, useCallback } from "react";
import logo from "../../../assets/Manex Logo.png";
import { DataGrid } from "@mui/x-data-grid";
import po_img from "../../../assets/po_img.png";
import { Button } from "react-bootstrap";
import "../../../Styles/CreatePI.css";
import SearchPI from "./SearchPI.js";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CircleSpinner } from "react-spinners-kit";
import { makeStyles } from "@material-ui/styles";
import CustomerPurchaseHistory from "./CustomerPurchaseHistory.js";
import TextInputWithAll from "../../Inputs/TextInputWithAll.js";
import TextInput from "../../Inputs/TextInput.js";
import DropDownInputWithoutLabel from "../../Inputs/DropDownInputWithoutLabel.js";
import { makeApiCall } from "../../../StaticComponents/API.js";
import debounce from "lodash/debounce";
import { useNavigate, useSearchParams } from "react-router-dom";
import DropDownInput from "../../Inputs/DropDownInput.js";
import { toast, ToastContainer } from "react-toastify";
import FormatCurrency from "../../../StaticComponents/FormatCurrency.js";
import USDCurrency from "../../../StaticComponents/USDCurrency.js";
import axios from "axios";
import { ToWords } from "to-words";
import Default_image from "../../../assets/Product_Package.png";

const useStyles = makeStyles({
  subheadingRow: {
    textAlign: "center",
    fontWeight: "bold",
    minHeight: "32px !important",
    maxHeight: "32px !important",
    "& .MuiDataGrid-cell": {
      border: "none !important",
      lineHeight: "32px !important",
      minHeight: "32px !important",
      maxHeight: "32px !important",
    },
  },

  subheadingCell: {
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    paddingLeft: 10,
    width: "100%",
    border: "0.5px solid var(--outlineVariant) !important",
    lineHeight: "24px !important",
    minHeight: "32px !important",
    maxHeight: "32px !important",
  },

  padding: { padding: "0px !important" },
});

export default function CreatePI() {
  let token = localStorage.getItem("token");
  const API_URL=process.env.REACT_APP_API_URL;
  const [startDate, setStartDate] = useState(new Date());
  const [searchShow, setSearchShow] = useState(false);
  const [historyShow, setHistoryShow] = useState(false);
  const [poNumber, setPoNumber] = useState("");
  const [file, setFile] = useState();
  const [file2, setFile2] = useState();
  const [fileName, setFileName] = useState("");
  const [optionShow, setOptionShow] = useState(false);
  const [added,setAdded]=useState(0)
  const [optionPosition, setOptionPosition] = useState({ top: 0, left: 0 });
  const idRef = useRef(null);
  const [currency, setcurrency] = useState([]);
  const [currencyID, setCurrencyID] = useState("");
  const [ccurrencyID, setccurrencyID] = useState();
  const [currencyLabel, setcurrencyLabel] = useState("");
  const [currencyFactory, setcurrencyFactory] = useState(0);
  const [usd_value, setusd_value] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const fileInputRef = useRef(null);
  const uploadTimeoutRef = useRef(null);
  const [commission, setCommission] = useState(0);
  const [transportCost, setTransportCost] = useState(0);
  const [selectedItemDetails, setSelectedItemDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  let APP_CLIENT_ID = localStorage.getItem("CLIENT_ID");
  const [containers, setcontainers] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [searchKeyword, setSearchKeyword] = useState("");
  const [agentCommission, setagentCommission] = useState(0);
  const [CommissionValue, setCommissionValue] = useState(0);
  const [itemValue, setItemValue] = useState([]);
  const [productid, setproductid] = useState();
  const [finalAmount, setfinalAmount] = useState(0);
  const CLIENT_SUPPLIER_ID = localStorage.getItem("CLIENT_SUPPLIER_ID");
  const [itemSuggestions, setItemSuggestions] = useState({
    mnxItemId: [],
    custItemId: [],
  });
  const [idSuggestions, setidsuggestions] = useState({
    mnxItemId: [],
    custItemId: [],
  });
  const [custInvId, setCustInvId] = useState();
  const [clientNum, setclientNum] = useState();
  const [clicked, setclicked] = useState(false);
  const [activeRow, setActiveRow] = useState("");
  const [CustomerName, setCustomerName] = useState("");
  const [Unit, setUnit] = useState("KG");
  const [operatingCurrency, setOperatingCurrency] = useState("");
  const [operatingCurrencySymbol, setOperatingCurrencySymbol] = useState("");
  const [rows, setRows] = useState([]);
  const [CurrencySymbol,setCurrencySymbol]=useState("")

  const initialRows1=[	{
    id: "",
    id_desc: "Total",
    SUP_ITEM_ID: "",
    TOTAL_QTY: "",
    PICTURE: "",
    CTN_QTY: "",
    INNER: "",
    CTN: "",
    DESCRIPTION: "",
    PRICE: "",
    TOTAL_AMOUNT: 0,
    BARCODE: "",
    DUN_BARCODE: "",
    PACKING: "",
    CBM: "",
    TTL_CBM: 0,
    WEIGHT: "",
    TTL_WEIGHT: 0,
  },]
  
  useEffect(()=>{
    if(rows.length===0){
      setAdded(0)
    }
  },[rows])

  const addTransportCost = (e) => {
    const value = e.target.value;
    if (value === "" || /^-?\d*\.?\d*$/.test(value)) {
      if (value === "" || value === "-") {
        setTransportCost("0");
      } else if (value === "0" || value === "0.") {
        setTransportCost(value);
      } else {
        setTransportCost(value.replace(/^0+/, ""));
      }
    }
    else {
      setTransportCost(0);
    }
  };

  const handleSelectedId = (selectedId) => {
    console.log(selectedId);
    setCustInvId(selectedId);
  };

  const towords = new ToWords();
  const [words, setwords] = useState("");

  useEffect(() => {

    if (finalAmount === "" || isNaN(finalAmount)) {
      setwords("");
    } else {
      setwords(towords.convert(parseFloat(finalAmount.toFixed(2))));
    }
  }, [finalAmount]);
  useEffect(() => {
    const updatedValue =
      usd_value + CommissionValue + parseFloat(transportCost);
    if (updatedValue === "") {
      setfinalAmount("");
    }
    setfinalAmount(updatedValue);
    console.log(finalAmount);
    console.log(transportCost);
    console.log(typeof transportCost);
  }, [usd_value, CommissionValue, transportCost]);
  const WeightOptions = [
    { value: "KG", label: "KG" },
    { value: "GM", label: "GM" },
    { value: "LBS", label: "LBS" },
  ];

  const handleCustomerChange = (event) => {
    const value = event.target.value;
    setCustomerName(value);
    searchCustomer(value);
  };

  const agent_Commission = (value) => {
    const floatvalue = parseFloat(value);
  if (value === "" || /^-?\d*\.?\d*$/.test(value)) {
    if (value === "" || value === "-") {
      setCommission("0");
    } else if (value === "0" || value === "0.") {
      setCommission(value); 
    } else {
      if (value.startsWith('0') && value.length > 1 && value[1] !== '.') {
        setCommission(value.replace(/^0+/, "")); 
      } else {
        setCommission(value); 
      }
    }
  }
  setagentCommission(floatvalue);
  setCommissionValue((floatvalue * usd_value) / 100);
  console.log("hihihih");
};
  console.log("Commission value", CommissionValue);

  const searchCustomer = useCallback(
    debounce(async (value) => {
      if (value.length < 1) {
        setSuggestions([]);
        return;
      }

      try {
        const payload = { SEARCH_KEYWORD: value, APP_CLIENT_ID: APP_CLIENT_ID };
        console.log("Payload:", payload);
        const res = await makeApiCall({
          mod: "Customer",
          action: "search_customer",
          payload: payload,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        console.log("API response:", res); // Log the API response to check the data
        setSuggestions(res.XscData?.CUST_NAME_LIST || []);
      } catch (error) {
        console.error("Error fetching suggestions:", error);
        setSuggestions([]);
      }
    }, 300),
    []
  );
  const [itemDescription, setItemDescription] = useState({});
  useEffect(() => {
    const payload = { SEARCH_KEYWORD: "" };
    makeApiCall({
      mod: "Orders",
      action: "list_currency_unit",
      payload: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => {
      console.log("Currency convertor ", response);
      const currency = response.XscData || [];
      const mappedRows = currency.map((cur) => ({
        value: cur.CURRENCY_ID,
        label: cur.CURRENCY,
        symbol: cur.CURRENCY_SYMBOL,
      }));
      setcurrency(mappedRows);
      if (mappedRows.length > 0) {
        // setSelectedCurrency(mappedRows[0].label); // Set default selection
        // setCurrencyID(mappedRows[0].value); // Set default currency ID
      }
    });
  }, []);
  const [selectedCurrency, setselectedCurrency] = useState();
  const [currencySymbol, setcurrencySymbol] = useState("");
  const handleCurrencyChange = (e) => {
    const selectedValue = e.target.value;
    setCurrencyID(selectedValue);
    const foundCurrency = currency.find((c) => c.value == selectedValue);
    console.log("SKELFHGVHGVHGV", foundCurrency);
    if (foundCurrency) {
      setselectedCurrency(foundCurrency);
      setcurrencyLabel(foundCurrency.label);
      setcurrencySymbol(foundCurrency.symbol);
    }
  };
  const [createCusPi, setCreateCusPi] = React.useState({
    customerData: "",
    clientData: "",
  });
  console.log("COUBBNTRY ID", currencyID);
  const [convert_rate, setconvert_rate] = useState(0);

  let [searchParams] = useSearchParams();
  const ID = searchParams.get("id");
  const inputRefs = useRef({});

  const [clientData, setClientData] = useState({
    xscdata: "",
  });
  // const [custData, setCustData] = useState([])
  const [custData, setCustData] = useState({
    xscdata: "",
  });
  useEffect(() => {
    const payload = {
      APP_CLIENT_ID: APP_CLIENT_ID,
    };
    makeApiCall({
      mod: "Shipping",
      action: "get-container-list",
      payload: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => {
      console.log("payload", payload);
      console.log("response for container list", response);
      const containers = response.XscData.CONTAINER_DETAIL;
      console.log(containers);
      if (Array.isArray(containers) && containers.length > 0) {
        const mappedRows = containers.map((container) => ({
          value: container.ID,
          label: container.ALIAS_NAME,
        }));
        setcontainers(mappedRows);
      }
    });
  }, []);
  const PaymentOptions = [
    { value: "1", label: "T/T" },
    { value: "2", label: "T/T" },
  ];

  const [invoiceItems, setInvoiceItems] = useState([]);
  const [filteredInvoiceItems, setFilteredInvoiceItems] = useState([]);

  const searchItem = useCallback(
    debounce(async (value, type, rowId) => {
      if (value.length < 1) {
        setItemSuggestions((prev) => ({
          ...prev,
          mnxItemId: [],
          custItemId: [],
        }));
        setFilteredInvoiceItems(invoiceItems); // Reset to full invoice items if no search value
        return;
      }

      try {
        let payload = {
          SEARCH_KEYWORD: "",
          APP_CLIENT_ID: "",
        };
        let action = "";

        if (type === "mnxItemId") {
          payload = { SEARCH_KEYWORD: value, APP_CLIENT_ID: APP_CLIENT_ID };
        } else if (type === "custItemId") {
          payload = { SEARCH_KEYWORD: value, CLIENT_CUST_ID: CLIENT_CUST_ID };
        }

        if (type === "mnxItemId") {
          action = "search_prod_by_client_item_num";
        } else if (type === "custItemId") {
          action = "search_prod_by_cust_item_num";
        }

        const res = await makeApiCall({
          mod: "CustomerPI",
          action: action,
          payload: payload,
          headers: { Authorization: `Bearer ${token}` },
        });
        console.log(res);
        const itemList = res.XscData.ITEM_LIST || [];
        setItemSuggestions((prev) => ({
          ...prev,
          [type]: itemList.map((item) => ({
            id: item.PRODUCT_ID,
            itemNo: item.ITEM_NO,
            name: item.DESCRIPTION,
          })),
        }));

        if (type === "mnxItemId") {
          const selectedItem = itemList.find((item) => item.ITEM_NO === value);
          if (selectedItem) {
            updateAllFields(selectedItem, rowId);
          }
        }
        if (type === "custItemId") {
          const selectedItem = itemList.find((item) => item.ITEM_NO === value);
          if (selectedItem) {
            updateAllFields(selectedItem, rowId);
          }
        }

        // Filter invoice items based on the search result
        const filteredItems = invoiceItems.filter(
          (item) =>
            item.MNX_ID.includes(value) || item.CUST_ITEM_ID.includes(value)
        );
        setFilteredInvoiceItems(filteredItems);
      } catch (error) {
        console.error("Error fetching item suggestions:", error);
        setItemSuggestions((prev) => ({
          ...prev,
          mnxItemId: [],
          custItemId: [],
        }));
        // setidsuggestions((prev) => ({
        //   ...prev,
        //   mnxItemId: [],
        //   custItemId: [],
        // }));
        setFilteredInvoiceItems(invoiceItems); // Reset to full invoice items in case of error
      }
    }, 300),
    [APP_CLIENT_ID, token, invoiceItems]
  );

  const updateAllFields = (selectedItem, rowId) => {
    const { DESCRIPTION, PRICE, CUST_ITEM_ID, MNX_ID } = selectedItem;

    setItemDescription((prev) => ({ ...prev, [rowId]: DESCRIPTION }));
    setItemValue((prev) => ({
      ...prev,
      [rowId]: MNX_ID,
    }));
    setFilteredInvoiceItems((prevItems) =>
      prevItems.map((item) =>
        item.MNX_ID === MNX_ID ? { ...item, DESCRIPTION, PRICE } : item
      )
    );
  };

  useEffect(() => {
    makeApiCall({
      mod: "CustomerPI",
      action: "get_items_from_cust_invoice_id",
      payload: { CUST_INV_ID: custInvId },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        console.log(response);
        const customersPI = response.XscData.ITEMS;
        if (Array.isArray(customersPI)) {
          const mappedRows = customersPI.map((customer) => ({
            // id: customer.CLIENT_ITEM_NUM,
            MNX_ID: customer.CLIENT_ITEM_NUM,
            CUST_ITEM_ID: customer.CUST_ITEM_NUM,
            PICTURE: customer.MM_FILE_NAME,
            CTN_QTY: customer.QTY_PER_CTN,
            INNER: customer.P_INNER,
            PRODUCT_ID: customer.PRODUCT_ID,
            CTN: customer.TOTAL_CTN,
            DESCRIPTION: customer.DESCRIPTION,
            PRICE: customer.PRICE,
            BARCODE: customer.CUST_BAR_CODE,
            DUN_BARCODE: customer.CUST_DUN_BAR_CODE,
            PACKING: customer.PACKING,
            CBM: customer.CBM,
            WEIGHT: customer.WEIGHT,
            MM_FILE_NAME: customer.MM_FILE_NAME,
          }));

          const existingRows = rows.map(row => ({
            MNX_ID: row.MNX_ID,
            CUST_ITEM_ID: row.CUST_ITEM_ID,
          }));
  
          const newRowsToAdd = mappedRows.filter((newRow) => 
            !existingRows.some(existingRow =>
              existingRow.MNX_ID === newRow.MNX_ID && existingRow.CUST_ITEM_ID === newRow.CUST_ITEM_ID
            )
          );

          const existingIds = Object.keys(idSuggestions.mnxItemId).map(Number);
          const existingCustIds = Object.keys(idSuggestions.custItemId).map(Number);
          const maxId = Math.max(...existingIds, ...existingCustIds, 0);
          const newMnxItemId = {};
          const newCustItemId = {};

           newRowsToAdd.forEach((row, index) => {
             const id = (maxId + index + 1).toString(); 
             newMnxItemId[id] = row.MNX_ID;
             newCustItemId[id] = row.CUST_ITEM_ID;
          setAdded((prevAdded) => prevAdded + 1);
        });

        setidsuggestions((prev) => ({
          ...prev,
          mnxItemId: {
            ...prev.mnxItemId,
            ...newMnxItemId,
          },
          custItemId: {
            ...prev.custItemId,
            ...newCustItemId,
          },
        }));
       setRows((prevRows) => {
          if (prevRows.length === 0 || prevRows[0].MNX_ID === "") {
            return [
              ...newRowsToAdd.map((row, index) => ({
                ...row,
                id: (maxId + index + 1).toString(), 
              })),
            ];
          } else {
            return [
              ...prevRows,
              ...newRowsToAdd.map((row, index) => ({
                ...row,
                id: (prevRows.length + index + 1).toString(), 
              })),
            ];
          }
        });
          setFilteredInvoiceItems((prevFiltered) => [
            ...prevFiltered,
            ...mappedRows,
          ]);
         
          // setItemSuggestions((prev) => ({
          //   ...prev,
          //   mnxItemId: [],
          //   custItemId: [],
          // }));
        } else {
          console.error("Customers data is not an array or is missing");
        }
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, [custInvId]);

  const handleSuggestionClick = (suggestion, rowId) => {
    handleItemChange(
      { target: { value: suggestion.itemNo } },
      rowId,
      "mnxItemId"
    );

    setItemSuggestions((prev) => ({
      ...prev,
      mnxItemId: [],
      custItemId: [],
    }));
    setidsuggestions((prev) => ({
      ...prev,
      mnxItemId: [],
      custItemId: [],
    }));

    if (inputRefs.current[rowId]) {
      inputRefs.current[rowId].focus();
    }
  };

  console.log(idSuggestions);
  const handleItemChange = (event, rowId, type) => {
    const value = event.target.value;
    setItemValue((prev) => ({ ...prev, [rowId]: value }));
    searchItem(value, type, rowId);
    setActiveRow(value);
    setclicked(false);
    setActiveRow(rowId);
      setidsuggestions((prev) => ({
        ...prev,
        mnxItemId: {
          ...prev.mnxItemId,
          [rowId]: event.target.value,
        },
      }));
  };

  const handleItemChange1 = (event, rowId, type) => {
    const value = event.target.value;
    console.log("CUST ITEM ID", value);
    setItemValue((prev) => ({ ...prev, [rowId]: value }));
    console.log(value, type, rowId);
    searchItem(value, type, rowId);
    setActiveRow(value);
    setclicked(false);
    setActiveRow(rowId);
    setidsuggestions((prev) => ({
      ...prev,
      custItemId: {
        ...prev.custItemId,
        [rowId]: event.target.value,
      },
    }));
    setRows((prevRows) => 
      prevRows.map(row => 
          row.id === rowId ? { ...row, CUST_ITEM_ID: value } : row
      )
     );
  };

  useEffect(() => {
    console.log("Current Item Suggestions:", itemSuggestions);
  }, [itemSuggestions]);
  const classes = useStyles();

 
  const [userInput, setUserInput] = useState({});
  const handleUserInput = (event, rowId) => {
    const { name, value } = event.target;

    setUserInput((prev) => ({
      ...prev,
      [rowId]: { ...prev[rowId], [name]: value },
    }));
  };
  const TypeOptions = [
    { value: "CASH", label: "CASH" },
    { value: "ONLINE", label: "ONLINE" },
    { value: "T/T", label: "T/T" },
    { value: "CARD", label: "CARD" },
    { value: "OTHERS", label: "OTHERS" },
  ];

  const getPiData = (id, productid, CLIENT_ITEM_NUM) => {
    const payload = {
      PRODUCT_ID: productid,
      CLIENT_CUST_ID: CLIENT_CUST_ID,
    };
    console.log(payload);
    console.log("client item number", CLIENT_ITEM_NUM);

    makeApiCall({
      mod: "Products",
      action: "get_product_details_for_pi",
      payload: payload,
      headers: { Authorization: `Bearer ${token}` },
    }).then((res) => {
      console.log(res, "response");

      const item = res.XscData.PRODUCT_DETAILS;
      if (!item) {
        console.error("No product details found");
        return;
      }

      const mappedItem = {
        id: id.toString(),
        MNX_ID: item.CLIENT_ITEM_NUM,
        CUST_ITEM_ID: item.CUST_ITEM_NUM || "",
        SUP_ITEM_ID: item.SUPP_ITEM_NUM,
        TOTAL_QTY: "",
        PICTURE: "",
        CTN_QTY: item.QTY_PER_CTN || "",
        INNER: "",
        CTN: "",
        DESCRIPTION: item.DESCRIPTION || "",
        PRICE: item.PRICE || "",
        PH: "",
        TOTAL_AMOUNT: "",
        BARCODE:
          item.CUST_BAR_CODE ||
          item.SUPP_BAR_CODE ||
          item.CLIENT_BAR_CODE ||
          "",
        DUN_BARCODE:
          item.CUST_DUN_BAR_CODE ||
          item.SUPP_DUN_BAR_CODE ||
          item.CLIENT_DUN_BAR_CODE ||
          "",
        PACKING: item.PACKING || "",
        CBM: item.CBM || "",
        TTL_CBM: "",
        WEIGHT: item.WEIGHT || "",
        TTL_WEIGHT: "",
        DEL: "",
        PRODUCT_ID: productid,
        CURRENCY_SYMBOL: item.OPERATING_CURRENCY_SYMBOL,
        MM_FILE_NAME:item.MM_FILE_NAME,
      };
      setCurrencySymbol(item.OPERATING_CURRENCY_SYMBOL);
      const existingRowIndex = rows.findIndex(
        (row) =>
          row.MNX_ID === mappedItem.MNX_ID &&
          row.CUST_ITEM_ID === mappedItem.CUST_ITEM_ID
      );
      if(existingRowIndex === -1 ){
        setidsuggestions((prev) => ({
          ...prev,
          mnxItemId: {
            ...prev.mnxItemId,
            [id]: item.CLIENT_ITEM_NUM,
          },
          custItemId: {
            ...prev.custItemId,
            [id]: item.CUST_ITEM_NUM,
          },
        }));
        
        setRows((prevRows) => {
          const rowIndex = prevRows.findIndex((row) => row.id === id);
          console.log("Updating Row ID:", id);
          console.log("Current Rows State:", prevRows);
          if (rowIndex !== -1) {
            // Update existing row
            const updatedRows = [...prevRows];
            updatedRows[rowIndex] = { ...updatedRows[rowIndex], ...mappedItem };
            return updatedRows;
          } else {
            // Add new row
            // return [...prevRows, mappedItem];
            const updatedRows = [...prevRows];
            updatedRows[updatedRows.length - 1] = {
              ...updatedRows[updatedRows.length - 1],
              ...mappedItem,
            };
            return updatedRows;
          }
        });
        setAdded((prevAdded) => prevAdded + 1);
      }
      else{
        const lastIndex = rows.length 
        deleteRow(lastIndex);
        return ;
      }      
    });
  };
  console.log(idSuggestions);

  const toZero = (value) => {
    const num = parseFloat(value);
    return isNaN(num) || value === '' ? 0 : num;
  };

  let calculatedRows = [];
  if (Array.isArray(rows) && rows.length > 0) {
    calculatedRows = rows.map((row) => ({
      ...row,
      TOTAL_QTY: toZero(row.CTN) * toZero(row.CTN_QTY),
    }));
  }
  
  let calculatedRows1 = [];
  if (Array.isArray(calculatedRows) && calculatedRows.length > 0) {
    calculatedRows1 = calculatedRows.map((row) => ({
      ...row,
      TOTAL_AMOUNT: toZero(row.TOTAL_QTY) * toZero(row.PRICE),
      TTL_CBM: toZero(row.TOTAL_QTY) * toZero(row.CBM),
      TTL_WEIGHT: toZero(row.TOTAL_QTY) * toZero(row.WEIGHT),
    }));
  }
  // const totalTTL_RMB = calculatedRows1.reduce((acc, row) => {
  //   return acc + (row.TOTAL_AMOUNT || 0); // Add each TTL_RMB, handle undefined with || 0
  // }, 0);
  const totalTTL_RMB = calculatedRows1.reduce(
    (acc, row) => acc + toZero(row.TOTAL_AMOUNT),
    0
  );
  const totalQTY=calculatedRows1.reduce(
    (acc, row) => acc + toZero(row.TOTAL_QTY),
    0
  )
  const totalCBMAllRows = calculatedRows1.reduce(
    (acc, row) => acc + toZero(row.TTL_CBM),
    0
  );
  const totalWeightAllRows = calculatedRows1.reduce(
    (acc, row) => acc + toZero(row.TTL_WEIGHT),
    0
  );
  const CTNAllRows = calculatedRows1.reduce((acc, row) => acc + toZero(row.CTN), 0);

  const TotalRows= added>=1? [...calculatedRows1,...initialRows1]:[...calculatedRows1]
  const resultRows = TotalRows.map((row) => {
    if (row.id_desc === "Total") {
      return {
        ...row,
        id: "total_ctn",
        TOTAL_QTY:totalQTY,
        TOTAL_AMOUNT: totalTTL_RMB,
        CTN: CTNAllRows,
        TTL_CBM: totalCBMAllRows,
        TTL_WEIGHT: totalWeightAllRows,
      };
    }
    return row;
  });
  console.log(calculatedRows1);
  console.log(resultRows);
  console.log(added);
  // const resultRows = calculatedRows1.map((row) => {
  //   if (row.id_desc === "Total") {
  //     return {
  //       ...row,
  //       TOTAL_AMOUNT: totalTTL_RMB,
  //       CTN: CTNAllRows,
  //       TTL_CBM: totalCBMAllRows,
  //       TTL_WEIGHT: totalWeightAllRows,
  //     };
  //   }
  //   return row;
  // });

  const handlePH = (id) => {
    console.log(id);
    localStorage.setItem("PRODUCT_ID", id);
    setHistoryShow(true);
  };

  const currency_convertor = (e) => {
    const value = e.target.value;
    const val = parseFloat(e.target.value);
    // setcurrencyFactory(Number(e.target.value));
    if (value === "" || /^-?\d*\.?\d*$/.test(value)) {
      if (value === "") {
        setcurrencyFactory("0"); 
      } else if (value === "0" || value === "0.") {
        setcurrencyFactory(value);
      } else {
        if (value.startsWith('0') && value.length > 1 && value[1] !== '.') {
          setcurrencyFactory(value.replace(/^0+/, "")); 
        } else {
          setcurrencyFactory(value); 
        }
      }
    }
    setconvert_rate(val);
    setusd_value(totalTTL_RMB * e.target.value);
  };

  const columns = [
    {
      field: "MNX_ID",
      headerName: "Mnx. Item ID",
      width: 148,
      cellClassName: "po_input",
      align: "center",
      renderCell: (params) => {
        const inputValue = idSuggestions.mnxItemId[params.row.id];
        const suggestions = itemSuggestions.mnxItemId || [];
        return params.row.id_desc === "Total" ? null : (
          <div style={{ position: "relative" }}>
            <input
              type="text"
              value={inputValue}
              onChange={(e) => handleItemChange(e, params.row.id, "mnxItemId")}
              ref={(el) => (inputRefs.current[params.row.id] = el)}
              style={{ width: "100%", padding: "8px", boxSizing: "border-box" }}
            />
            {activeRow === params.row.id &&
              !clicked &&
              suggestions.length > 0 && (
                <div className="suggestions-dropdown">
                  {suggestions.map((suggestion) => (
                    <div
                      key={suggestion.id}
                      className="suggestion-item"
                      onClick={() => {
                        setproductid(suggestion.id);
                        setclientNum(suggestion.itemNo);
                        handleInputChange(
                          { target: { value: suggestion.itemNo } },
                          params.row.id,
                          "id"
                        );
                        setidsuggestions((prev) => ({
                          ...prev,
                          mnxItemId: {
                            ...prev.mnxItemId, 
                            [params.row.id]: suggestion.itemNo, 
                          },
                        }));
                        setItemSuggestions((prev) => ({
                          ...prev,
                          mnxItemId: [],
                          custItemId: [],
                        }));
                        if (inputRefs.current[params.row.id]) {
                          inputRefs.current[params.row.id].focus();
                        }
                        setclicked(true);
                        getPiData(
                          params.row.id,
                          suggestion.id,
                          suggestion.itemNo
                        );
                      }}
                    >
                      {suggestion.itemNo}
                    </div>
                  ))}
                 
                </div>
              )}
          </div>
        );
      },
    },
    {
      field: "CUST_ITEM_ID",
      headerName: "Cust. Item ID",
      width: 148,
      cellClassName: "po_input",
      align: "center",
      renderCell: (params) => {
        const inputValue =
          idSuggestions.custItemId[params.row.id] ; 
        const suggestions = itemSuggestions.custItemId || [];
        return params.row.id_desc === "Total" ? null : (
          <div style={{ position: "relative" }}>
            <input
              type="text"
              value={inputValue}
              onChange={(e) =>
                handleItemChange1(e, params.row.id, "custItemId")
              } 
              ref={(el) => (inputRefs.current[params.row.id] = el)}
              style={{ width: "100%", padding: "8px", boxSizing: "border-box" }}
            />
            {activeRow === params.row.id &&
              !clicked &&
              suggestions.length > 0 && (
                <div className="suggestions-dropdown">
                  {suggestions.map((suggestion) => (
                    <div
                      key={suggestion.id}
                      className="suggestion-item"
                      onClick={() => {
                        setproductid(suggestion.id);
                        setclientNum(suggestion.itemNo);
                        handleInputChange(
                          { target: { value: suggestion.itemNo } },
                          params.row.id,
                          "custItemId"
                        );

                        setidsuggestions((prev) => ({
                          ...prev,
                          custItemId: {
                            ...prev.custItemId,
                            [params.row.id]: suggestion.itemNo,
                          },
                        }));

                        setItemSuggestions((prev) => ({
                          ...prev,
                          custItemId: [],
                        }));

                        if (inputRefs.current[params.row.id]) {
                          inputRefs.current[params.row.id].focus();
                        }
                        setclicked(true);
                        getPiData(
                          params.row.id,
                          suggestion.id,
                          suggestion.itemNo
                        );
                      }}
                    >
                      {suggestion.itemNo}
                    </div>
                  ))}
             
                </div>
              )}
          </div>
        );
      },
    },
    {
      field: "TOTAL_QTY",
      headerName: "Total QTY",
      width: 125,
      align: "left",
      renderCell: (params) => {
        return params.row.id_desc === "Total" ? (
          <div className={classes.subheadingCell} >{params.value}</div>
        ) : (
          <div>
            {params.value}
          </div>
        );
      },
    },
    {
      field: "PICTURE",
      headerName: "Picture",
      width: 120,
      align: "center",
      renderCell: (params) => {
        const imageUrl = params.row.MM_FILE_NAME ? `${API_URL}/media/${params.row.MM_FILE_NAME}` : Default_image;
        return params.row.id_desc === "Total" ? null : (
          <div id="po_pic_bg">
           <img
          src={imageUrl}
          alt="Product Image"
          style={{ width: "100%", height: "auto" }}
        />
          </div>
        );
      },
    },
    {
      field: "CTN_QTY",
      headerName: "CTN/QTY",
      width: 148,
      align: "center",
    },
    {
      field: "INNER",
      headerName: "Inner",
      width: 148,
      align: "center",
      cellClassName: "po_input",
      renderCell: (params) => {
        return params.row.id_desc === "Total" ? null : (
          <TextInput
            type="text"
            value={params.value}
            onChange={(e) => handleNumberChange(e, params.row.id, "INNER")}
          />
        );
      },
    },
    {
      field: "CTN",
      headerName: "CTN",
      width: 148,
      type: "number",
      align: "left",
      cellClassName: "po_input",
      renderCell: (params) => {
        return params.row.id_desc === "Total" ? (
          <div
            className={classes.subheadingCell}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "left",
              paddingRight: "6px",
            }}
          >
            {params.value}
          </div>
        ) : (
          <TextInput
            type="text"
            value={params.value}
            onChange={(e) => handleInputChange1(e, params.row.id, "CTN")}
          />
        );
      },
    },
    {
      field: "DESCRIPTION",
      headerName: "Description",
      width: 275,
      align: "center",
      cellClassName: "pi_input",
      renderCell: (params) => {
        return params.row.id_desc === "Total" ? null : (
          <TextInput
            type="text"
            value={params.value}
            onChange={(e) =>
              handleInputChange1(e, params.row.id, "DESCRIPTION")
            }
          />
        );
      },
    },
    {
      field: "PRICE",
      headerName: "Price",
      width: 130,
      align: "center",
      cellClassName: "po_input",
      renderCell: (params) => {
        return params.row.id_desc === "Total" ? null : (
          <div className="d-flex" style={{position:"relative"}}>
               <TextInput
               style={{paddingLeft:"30px"}}
               type="text"
               value={params.value}
               onChange={(e) => {
                 const value=e.target.value;
                //  if (/^\d*$/.test(value)) {
                   handleInputChange1(e, params.row.id, "PRICE")
                //  }
                 }}
          />
          <div style={{position:"absolute",left:"12px"}}>{params.row.CURRENCY_SYMBOL}</div>
          </div>
       
        );
      },
    },
    {
      field: "PH",
      headerName: "PH",
      width: 80,
      align: "center",
      renderCell: (params) => {
        let productId = params.row.PRODUCT_ID;
        return params.row.id_desc === "Total" ? null : (
          <svg
            style={{ cursor: "pointer" }}
            onClick={() => handlePH(productId)}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M17 2V4H20.0066C20.5552 4 21 4.44495 21 4.9934V21.0066C21 21.5552 20.5551 22 20.0066 22H3.9934C3.44476 22 3 21.5551 3 21.0066V4.9934C3 4.44476 3.44495 4 3.9934 4H7V2H17ZM7 6H5V20H19V6H17V8H7V6ZM9 16V18H7V16H9ZM9 13V15H7V13H9ZM9 10V12H7V10H9ZM15 4H9V6H15V4Z"
              fill="var(--primaryContainer)"
            />
          </svg>
        );
      },
    },
    {
      field: "TOTAL_AMOUNT",
      headerName: "Total Amount",
      width: 150,
      align: "left",
      renderCell: (params) => {
        return params.row.id_desc === "Total" ? (
          <div className={classes.subheadingCell}>{CurrencySymbol} {parseFloat(params.value).toFixed(2)}</div>
        ) : (<div> {params.row.CURRENCY_SYMBOL} {parseFloat(params.value).toFixed(2)}</div>);
      },
    },
    {
      field: "BARCODE",
      headerName: "Barcode",
      width: 275,
      align: "center",
      cellClassName: "pi_input",
      renderCell: (params) => {
        return params.row.id_desc === "Total" ? null : (
          <TextInput
            type="text"
            value={params.value}
            onChange={(e) => handleInputChange1(e, params.row.id, "BARCODE")}
          />
        );
      },
    },
    {
      field: "DUN_BARCODE",
      headerName: "Dun Barcode",
      width: 275,
      align: "center",
      cellClassName: "pi_input",
      renderCell: (params) => {
        return params.row.id_desc === "Total" ? null : (
          <TextInput
            type="text"
            value={params.value}
            onChange={(e) =>
              handleInputChange1(e, params.row.id, "DUN_BARCODE")
            }
          />
        );
      },
    },
    {
      field: "PACKING",
      headerName: "Packing",
      width: 275,
      align: "center",
      cellClassName: "pi_input",
      renderCell: (params) => {
        return params.row.id_desc === "Total" ? null : (
          <TextInput
            type="text"
            value={params.value}
            onChange={(e) => handleInputChange1(e, params.row.id, "PACKING")}
          />
        );
      },
    },
    {
      field: "CBM",
      headerName: "CBM",
      width: 130,
      align: "center",
      cellClassName: "po_input",
      renderCell: (params) => {
        return params.row.id_desc === "Total" ? null : (
          <TextInput
            type="text"
            value={params.value}
            onChange={(e) => handleInputChange1(e, params.row.id, "CBM")}
          />
        );
      },
    },
    {
      field: "TTL_CBM",
      headerName: "TTL CBM",
      width: 130,
      align: "left",
      renderCell: (params) => {
        return params.row.id_desc === "Total" ? (
          <div className={classes.subheadingCell}>{parseFloat(params.value).toFixed(4)}</div>
        ) : <div>{params.value.toFixed(4)}</div>;
      },
    },
    {
      field: "WEIGHT",
      headerName: "Weight",
      width: 250,
      align: "center",
      cellClassName: "po_input",
      renderCell: (params) => {
        return params.row.id_desc === "Total" ? null : (
          <div className="position-relative d-flex gap-0" id="dp_with_op">
            <TextInput
              type="text"
              id="weight_dp_down"
              value={params.value}
              onChange={(e) => handleInputChange1(e, params.row.id, "WEIGHT")}
            />
            <div>
              <select
                id="cust_div1"
                value={Unit}
                onChange={(e) => setUnit(e.target.value)}
              >
                <option value="" disabled>
                  select unit
                </option>
                {WeightOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
            <div
              style={{ top: 26, right: 0, paddingRight: 0 }}
              id="remaining_characters"
              className="position-absolute"
            ></div>
          </div>
        );
      },
    },
    {
      field: "TTL_WEIGHT",
      headerName: "TTL Weight",
      width: 145,
      align: "left",
      renderCell: (params) => {
        return params.row.id_desc === "Total" ? (
          <div className={classes.subheadingCell}>{params.value.toFixed(2)} {Unit}</div>
        ) : <div>{params.value.toFixed(2)} {Unit}</div>;
      },
    },
    {
      field: "DEL",
      headerName: " ",
      width: 60,
      align: "center",
      sortable: false,
      renderCell: (params) => {
        // Check if the row is not the "Total" row
        if (params.row.id_desc === "Total") return null;

        return (
          <div
            onClick={() => deleteRow(params.row.id)} // Call deleteRow function on click
            style={{ cursor: "pointer" }} // Change cursor to pointer
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M17 6H22V8H20V21C20 21.5523 19.5523 22 19 22H5C4.44772 22 4 21.5523 4 21V8H2V6H7V3C7 2.44772 7.44772 2 8 2H16C16.5523 2 17 2.44772 17 3V6ZM18 8H6V20H18V8ZM9 11H11V17H9V11ZM13 11H15V17H13V11ZM9 4V6H15V4H9Z"
                fill="var(--error)"
              />
            </svg>
          </div>
        );
      },
    },
  ];

  const [selectedContainer, setselectedContainer] = useState("");
  const handleContainerchange = (e) => {
    setselectedContainer(e.target.value);
  };
  const ITEMS = calculatedRows1.map((row) => ({
    CUST_ITEM_NUM: row.CUST_ITEM_ID,
    PRODUCT_ID: row.PRODUCT_ID,
    QTY_PER_CTN: row.CTN_QTY,
    INNER: row.INNER,
    DESCRIPTION: row.DESCRIPTION,
    PRICE_PER_ITEM: row.PRICE,
    BAR_CODE: row.BARCODE,
    DUN_BAR_CODE: row.DUN_BARCODE,
    PACKING: row.PACKING,
    CBM: row.CBM,
    WEIGHT: row.WEIGHT,
    WEIGHT_UNIT: Unit,
    TOTAL_QTY: row.TOTAL_QTY,
    TOTAL_CTN: row.CTN,
  }));
  const handleInputChange = (event, rowId, field) => {
    const value = event.target.value;
    setclientNum(value);
    setItemValue((prev) => ({ ...prev, [rowId]: clientNum }));
    //searchItem(value, field, rowId); // Trigger search with rowId
  };
  const handleInputChange1 = (event, rowID, field) => {
    const value = event.target.value;
    setRows((prevRows) =>
      prevRows.map((row) =>
        row.id === rowID ? { ...row, [field]: value } : row
      )
    );
  };

  const handleNumberChange = (event, rowId, field) => {
    const value = event.target.value;
    if (value === "" || !isNaN(value)) {
      handleInputChange1({ target: { value } }, rowId, field);
    }
  };

  const handleAddNewItem = () => {
    setAdded((prevadded)=> prevadded+1)
    const newRow = {
      id: rows.length + 1,
      CTN_QTY: "",
      PICTURE: "",
      PRODUCT_NAME: "",
      TOTAL_QTY: "",
      PCS: "",
      INN: "",
      RMB: "",
      PH: "",
      DEL: "",
    };
    setRows([...rows, newRow]);
  };

  const deleteRow = (rowId) => {
    const updatedRows = rows.filter((row) => row.id !== rowId);
    const updatedMnxItemId = { ...idSuggestions.mnxItemId };
    const updatedCustItemId = { ...idSuggestions.custItemId };
    delete updatedMnxItemId[rowId];
    delete updatedCustItemId[rowId];
    const reIndexedMnxItemId = {};
    const reIndexedCustItemId = {};
    let index = 1;
    for (const key in updatedMnxItemId) {
      if (updatedMnxItemId.hasOwnProperty(key)) {
        reIndexedMnxItemId[index++] = updatedMnxItemId[key];
      }
    }
    index = 1;
    for (const key in updatedCustItemId) {
      if (updatedCustItemId.hasOwnProperty(key)) {
        reIndexedCustItemId[index++] = updatedCustItemId[key];
      }
    }
    setidsuggestions({
      mnxItemId: reIndexedMnxItemId,
      custItemId: reIndexedCustItemId,
    });
    const newRows = updatedRows.map((row, idx) => {
      return {
        ...row,
        mnxItemId: reIndexedMnxItemId[idx + 1] || null, 
        custItemId: reIndexedCustItemId[idx + 1] || null, 
      };
    });
    setRows(newRows);
  };
  
  
  const handleClick = (e, status) => {
    const rect = e.target.getBoundingClientRect();
    setOptionPosition({
      top: rect.bottom + window.scrollY,
      left: rect.left + window.scrollX,
    });
    setFileName(e.target.value);
    setOptionShow(true);
  };

  const handleClickOutside = (event) => {
    if (idRef.current && !idRef.current.contains(event.target)) {
      setOptionShow(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setIsLoading(true);
      console.log("Selected file:", e.target.files[0]);
      console.log("Selected file:", e.target.files[0].lastModifiedDate);
      setFileName(e.target.files[0].name);

      uploadTimeoutRef.current = setTimeout(() => {
        setFile(URL.createObjectURL(e.target.files[0]));
        const FileUrl = e.target.files[0];
        setFile2(FileUrl);
        setFileName(e.target.files[0].name);
        setIsLoading(false);
      }, 2000);
    } else {
      console.error("No file selected");
    }
  };

  const handleImportCancel = () => {
    setFile(null);
    setFileName("");
    setIsLoading(false);

    if (uploadTimeoutRef.current) {
      clearTimeout(uploadTimeoutRef.current);
      uploadTimeoutRef.current = null;
    }

    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }

    console.log("File upload canceled");
  };

  const getRowClassName = (params) => {
    return params.row.id_desc === "Total" ? classes.subheadingRow : "";
  };

  const getCellClassName = (params) => {
    return params.row.id_desc === "Total" ? classes.padding : "";
  };

  const CLIENT_CUST_ID = localStorage.getItem("CLIENT_CUST_ID")

  const getData = () => {
    const payload = {
      CLIENT_CUST_ID: CLIENT_CUST_ID,
    };

    makeApiCall({
      mod: "Customer",
      action: "get_client_cust_details",
      payload: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((res) => {
      console.log(res, "customer-reports");
      setCreateCusPi({
        customerData: res.XscData.CLIENT_DATA,
        clientData: res.XscData.CUST_DATA,
      });
    
      setOperatingCurrency(res.XscData.CLIENT_DATA.OPERATING_CURRENCY);
      setOperatingCurrencySymbol(
        res.XscData.CLIENT_DATA.OPERATING_CURRENCY_SYMBOL
      );
     
    });
  };

  useEffect(() => {
    getData();
  }, []);

  const navigate = useNavigate();

  const handleCreatePI = (e) => {
    e.preventDefault();

    const payload = {
      APP_CLIENT_ID: APP_CLIENT_ID,
      CLIENT_CUST_ID: CLIENT_CUST_ID,
      TOTAL_GOODS_AMOUNT: totalTTL_RMB,
      CUST_PO_NUM: poNumber,
      AGENTS_COMMISION_PERCENTAGE: parseFloat(commission),
      INVOICE_CURRENCY: currencyID,
      INVOICE_CURRENCY_RATE: convert_rate,
      SHIPPING_COST: parseFloat(transportCost),
      INLAND_TRANS_TYPE: selectedContainer,
      PRODUCTS: [...ITEMS],
    };

    const fieldNames = {
      CUST_PO_NUM: "Enter Customer PO Number",
      INVOICE_CURRENCY: "Select Currency",
      INVOICE_CURRENCY_RATE: " Enter Currency Exchange Rate",
      AGENTS_COMMISION_PERCENTAGE: "Enter Agent's Commission Percentage",
      INLAND_TRANS_TYPE: "Select Inland Transport Type",
      SHIPPING_COST: " Enter Inland Transport Cost",
      PRODUCTS: "Products",
    };

    const itemFieldNames = {
      CUST_ITEM_NUM: "Customer Item Id",
      PRODUCT_ID: "Product ID",
      QTY_PER_CTN: "Quantity per Carton",
      INNER: "Inner",
      DESCRIPTION: "Description",
      PRICE_PER_ITEM: "Price per Item",
      BAR_CODE: "Bar Code",
      DUN_BAR_CODE: "DUN Bar Code",
      PACKING: "Packing",
      CBM: "CBM",
      WEIGHT: "Weight",
      WEIGHT_UNIT: "Select Weight Unit",
      TOTAL_QTY: "Total Quantity",
      TOTAL_CTN: "Total Cartons",
    };
    console.log(payload);
    const validatePayload = (payload) => {
      for (const [field, displayName] of Object.entries(fieldNames)) {
        if (!payload[field]) {
          return `${displayName}`; 
        }
      }
      if (!Array.isArray(payload.PRODUCTS) || payload.PRODUCTS.length === 0) {
        return "Please add item to the PRODUCTS list.";
      }

      for (let index = 0; index < payload.PRODUCTS.length; index++) {
        const item = payload.PRODUCTS[index];
        for (const [field, displayName] of Object.entries(itemFieldNames)) {
          if (!item[field]) {
            return `Item ${index + 1}: ${displayName} is required.`;
          }
        }
      }

      return null;
    };

    const error = validatePayload(payload);
    if (error) {
      toast.error(error, {
        position: "bottom-center",
      });
      return;
    }

    const formdata = new FormData();

    formdata.append("mod", "CustomerPI");
    formdata.append("action", "create_cust_pi");
    formdata.append("payload", JSON.stringify(payload));
    formdata.append("file", file);
    axios({
      method: "post",
      url: process.env.REACT_APP_API_URL,
      data: formdata,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => {
      console.log("PI CREATED BY SUCCESSFULLY<", response);
      console.log(payload, "pi_payload");
      if (response.data.XscStatus === 1) {
        toast.success("PI created successfully", {
          position: "bottom-center",
        });
        setTimeout(() => {
          localStorage.setItem("CUST_PI_ID", response.data.XscData.CUST_PI_ID);
          localStorage.setItem("CLIENT_CUST_ID", CLIENT_CUST_ID);
          navigate("/customers/customer-info");
        }, 1000);
      } else {
        toast.error("Please fill all the fields", {
          position: "bottom-center",
        });
      }
    });
  };

  console.log("TTL_RMB", totalTTL_RMB);
  console.log("ROWS", calculatedRows1);

  console.log("ID suggeastions", idSuggestions);
  
  return (
    <div>
      <div id="suppliers_topbar_bg">
        <div className="container d-flex justify-content-between align-items-center">
          <div className="">
            <div
              id="dashboard_sub_header"
              className=" d-flex align-items-center"
              style={{ gap: "16px" }}
            >
              <a
                href="/customers/customer-info"
                className="text-decoration-none"
              >
                {" "}
                <span id="cancel_po_button">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M10.5859 12.0001L2.79297 4.20718L4.20718 2.79297L12.0001 10.5858L19.793 2.79297L21.2072 4.20718L13.4143 12.0001L21.2072 19.7929L19.793 21.2072L12.0001 13.4143L4.20718 21.2072L2.79297 19.7929L10.5859 12.0001Z"
                      fill="var(--onSurface)"
                    />
                    <path
                      d="M10.5859 12.0001L2.79297 4.20718L4.20718 2.79297L12.0001 10.5858L19.793 2.79297L21.2072 4.20718L13.4143 12.0001L21.2072 19.7929L19.793 21.2072L12.0001 13.4143L4.20718 21.2072L2.79297 19.7929L10.5859 12.0001Z"
                      fill="black"
                      fill-opacity="0.2"
                    />
                    <path
                      d="M10.5859 12.0001L2.79297 4.20718L4.20718 2.79297L12.0001 10.5858L19.793 2.79297L21.2072 4.20718L13.4143 12.0001L21.2072 19.7929L19.793 21.2072L12.0001 13.4143L4.20718 21.2072L2.79297 19.7929L10.5859 12.0001Z"
                      fill="black"
                      fill-opacity="0.2"
                    />
                    <path
                      d="M10.5859 12.0001L2.79297 4.20718L4.20718 2.79297L12.0001 10.5858L19.793 2.79297L21.2072 4.20718L13.4143 12.0001L21.2072 19.7929L19.793 21.2072L12.0001 13.4143L4.20718 21.2072L2.79297 19.7929L10.5859 12.0001Z"
                      fill="black"
                      fill-opacity="0.2"
                    />
                    <path
                      d="M10.5859 12.0001L2.79297 4.20718L4.20718 2.79297L12.0001 10.5858L19.793 2.79297L21.2072 4.20718L13.4143 12.0001L21.2072 19.7929L19.793 21.2072L12.0001 13.4143L4.20718 21.2072L2.79297 19.7929L10.5859 12.0001Z"
                      fill="black"
                      fill-opacity="0.2"
                    />
                  </svg>
                </span>{" "}
              </a>
              Create Proforma Invoice
            </div>
          </div>
        </div>
      </div>
      <div id="create_po_bg">
        <div className="container">
          <div
            className=""
            style={{
              borderBottom: "0.5px solid #C7C4D8",
              paddingBottom: "40px",
            }}
          >
            <div
              className="d-flex"
              style={{ justifyContent: "space-between" }}
              id="about_po_header1"
            >
              <Button
                id="search_previous_invoice"
                onClick={() => setSearchShow(true)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M18.031 16.6168L22.3137 20.8995L20.8995 22.3137L16.6168 18.031C15.0769 19.263 13.124 20 11 20C6.032 20 2 15.968 2 11C2 6.032 6.032 2 11 2C15.968 2 20 6.032 20 11C20 13.124 19.263 15.0769 18.031 16.6168ZM16.0247 15.8748C17.2475 14.6146 18 12.8956 18 11C18 7.1325 14.8675 4 11 4C7.1325 4 4 7.1325 4 11C4 14.8675 7.1325 18 11 18C12.8956 18 14.6146 17.2475 15.8748 16.0247L16.0247 15.8748Z"
                    fill="white"
                  />
                </svg>
                <span id="search_text">Search Previous Invoice</span>
              </Button>
              <SearchPI
                onSelect={handleSelectedId}
                show={searchShow}
                onHide={() => setSearchShow(false)}
              />
              <div
                className="d-flex flex-column align-items-start"
                style={{
                  margin: "48px 0px 0px 0px",
                  gap: 24,
                }}
              >
                <div
                  className="d-flex flex-row align-items-center"
                  style={{
                    gap: 12,
                  }}
                >
                  <div
                    style={{
                      width: 8,
                      height: 44,
                      flexShrink: 0,
                      background: "var(--Schemes-Tertiary-Container, #ba3cbb)",
                    }}
                  ></div>
                  <span id="customer_po_text">Customer PO File</span>
                </div>

                <div className="d-flex flex-column align-items-start">
                  <p id="upload_text">Upload PO file</p>
                  {isLoading ? (
                    <div id="upload_pi_file">
                      <div>
                        <div id="about_po_header">
                          <span
                            className="d-flex align-items-center"
                            style={{ gap: "4px" }}
                          >
                            <CircleSpinner
                              size={20}
                              loading={isLoading}
                              color="var(--primaryContainer)"
                            />
                            Uploading Your File
                          </span>
                          <div>{fileName}</div>
                        </div>
                      </div>
                      <div
                        id="file_upload_cancel"
                        onClick={handleImportCancel}
                        style={{
                          marginTop: 8,
                        }}
                      >
                        Cancel
                      </div>
                    </div>
                  ) : (
                    <div id="import_supplier_bg">
                      {file ? (
                        <div>
                          <div
                            id="upload_pi_file"
                            style={{
                              border: "none",
                            }}
                            className="d-flex flex-row align-items-center gap-2"
                          >
                            <div id="upload_file_text">
                              <span
                                className="d-flex align-items-center"
                                style={{
                                  gap: "4px",
                                }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                >
                                  <path
                                    d="M5 4H15V8H19V20H5V4ZM3.9985 2C3.44749 2 3 2.44405 3 2.9918V21.0082C3 21.5447 3.44476 22 3.9934 22H20.0066C20.5551 22 21 21.5489 21 20.9925L20.9997 7L16 2H3.9985ZM10.4999 7.5C10.4999 9.07749 10.0442 10.9373 9.27493 12.6534C8.50287 14.3757 7.46143 15.8502 6.37524 16.7191L7.55464 18.3321C10.4821 16.3804 13.7233 15.0421 16.8585 15.49L17.3162 13.5513C14.6435 12.6604 12.4999 9.98994 12.4999 7.5H10.4999ZM11.0999 13.4716C11.3673 12.8752 11.6042 12.2563 11.8037 11.6285C12.2753 12.3531 12.8553 13.0182 13.5101 13.5953C12.5283 13.7711 11.5665 14.0596 10.6352 14.4276C10.7999 14.1143 10.9551 13.7948 11.0999 13.4716Z"
                                    fill="#1B1B24"
                                  />
                                  <path
                                    d="M5 4H15V8H19V20H5V4ZM3.9985 2C3.44749 2 3 2.44405 3 2.9918V21.0082C3 21.5447 3.44476 22 3.9934 22H20.0066C20.5551 22 21 21.5489 21 20.9925L20.9997 7L16 2H3.9985ZM10.4999 7.5C10.4999 9.07749 10.0442 10.9373 9.27493 12.6534C8.50287 14.3757 7.46143 15.8502 6.37524 16.7191L7.55464 18.3321C10.4821 16.3804 13.7233 15.0421 16.8585 15.49L17.3162 13.5513C14.6435 12.6604 12.4999 9.98994 12.4999 7.5H10.4999ZM11.0999 13.4716C11.3673 12.8752 11.6042 12.2563 11.8037 11.6285C12.2753 12.3531 12.8553 13.0182 13.5101 13.5953C12.5283 13.7711 11.5665 14.0596 10.6352 14.4276C10.7999 14.1143 10.9551 13.7948 11.0999 13.4716Z"
                                    fill="black"
                                    fill-opacity="0.2"
                                  />
                                  <path
                                    d="M5 4H15V8H19V20H5V4ZM3.9985 2C3.44749 2 3 2.44405 3 2.9918V21.0082C3 21.5447 3.44476 22 3.9934 22H20.0066C20.5551 22 21 21.5489 21 20.9925L20.9997 7L16 2H3.9985ZM10.4999 7.5C10.4999 9.07749 10.0442 10.9373 9.27493 12.6534C8.50287 14.3757 7.46143 15.8502 6.37524 16.7191L7.55464 18.3321C10.4821 16.3804 13.7233 15.0421 16.8585 15.49L17.3162 13.5513C14.6435 12.6604 12.4999 9.98994 12.4999 7.5H10.4999ZM11.0999 13.4716C11.3673 12.8752 11.6042 12.2563 11.8037 11.6285C12.2753 12.3531 12.8553 13.0182 13.5101 13.5953C12.5283 13.7711 11.5665 14.0596 10.6352 14.4276C10.7999 14.1143 10.9551 13.7948 11.0999 13.4716Z"
                                    fill="black"
                                    fill-opacity="0.2"
                                  />
                                  <path
                                    d="M5 4H15V8H19V20H5V4ZM3.9985 2C3.44749 2 3 2.44405 3 2.9918V21.0082C3 21.5447 3.44476 22 3.9934 22H20.0066C20.5551 22 21 21.5489 21 20.9925L20.9997 7L16 2H3.9985ZM10.4999 7.5C10.4999 9.07749 10.0442 10.9373 9.27493 12.6534C8.50287 14.3757 7.46143 15.8502 6.37524 16.7191L7.55464 18.3321C10.4821 16.3804 13.7233 15.0421 16.8585 15.49L17.3162 13.5513C14.6435 12.6604 12.4999 9.98994 12.4999 7.5H10.4999ZM11.0999 13.4716C11.3673 12.8752 11.6042 12.2563 11.8037 11.6285C12.2753 12.3531 12.8553 13.0182 13.5101 13.5953C12.5283 13.7711 11.5665 14.0596 10.6352 14.4276C10.7999 14.1143 10.9551 13.7948 11.0999 13.4716Z"
                                    fill="black"
                                    fill-opacity="0.2"
                                  />
                                  <path
                                    d="M5 4H15V8H19V20H5V4ZM3.9985 2C3.44749 2 3 2.44405 3 2.9918V21.0082C3 21.5447 3.44476 22 3.9934 22H20.0066C20.5551 22 21 21.5489 21 20.9925L20.9997 7L16 2H3.9985ZM10.4999 7.5C10.4999 9.07749 10.0442 10.9373 9.27493 12.6534C8.50287 14.3757 7.46143 15.8502 6.37524 16.7191L7.55464 18.3321C10.4821 16.3804 13.7233 15.0421 16.8585 15.49L17.3162 13.5513C14.6435 12.6604 12.4999 9.98994 12.4999 7.5H10.4999ZM11.0999 13.4716C11.3673 12.8752 11.6042 12.2563 11.8037 11.6285C12.2753 12.3531 12.8553 13.0182 13.5101 13.5953C12.5283 13.7711 11.5665 14.0596 10.6352 14.4276C10.7999 14.1143 10.9551 13.7948 11.0999 13.4716Z"
                                    fill="black"
                                    fill-opacity="0.2"
                                  />
                                </svg>
                                {fileName}
                              </span>
                            </div>
                            <div
                              onClick={handleImportCancel}
                              style={{
                                cursor: "pointer",
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                              >
                                <path
                                  d="M8.00045 7.05767L11.3003 3.75781L12.2431 4.70062L8.94325 8.00047L12.2431 11.3003L11.3003 12.2431L8.00045 8.94327L4.70063 12.2431L3.75781 11.3003L7.05765 8.00047L3.75781 4.70062L4.70063 3.75781L8.00045 7.05767Z"
                                  fill="#950F27"
                                />
                              </svg>
                            </div>
                            <a
                              id="view_cons_file"
                              href={file2}
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{
                                width: 98,
                              }}
                            >
                              <h2 id="view">View</h2>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <path
                                  d="M16.0052 9.41421L7.39858 18.0208L5.98438 16.6066L14.591 8H7.00519V6H18.0052V17H16.0052V9.41421Z"
                                  fill="#635BFF"
                                />
                              </svg>
                            </a>
                          </div>
                        </div>
                      ) : (
                        <div className="col-12">
                          <label
                            htmlFor="file-input"
                            className="d-flex align-items-center justify-content-center flex-column"
                            id="upload_pi_file"
                            style={{
                              cursor: "pointer",
                              marginTop: 8,
                            }}
                          >
                            <div id="upload_csv_text">
                              {fileName && fileName}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <g opacity="0.4">
                                  <path
                                    d="M12 12.5858L16.2426 16.8284L14.8284 18.2426L13 16.415V22H11V16.413L9.17157 18.2426L7.75736 16.8284L12 12.5858ZM12 2C15.5934 2 18.5544 4.70761 18.9541 8.19395C21.2858 8.83154 23 10.9656 23 13.5C23 16.3688 20.8036 18.7246 18.0006 18.9776L18 17C18 13.6863 15.3137 11 12 11C8.7616 11 6.12243 13.5656 6.00414 16.7751L6 17L6.00039 18.9776C3.19696 18.7252 1 16.3692 1 13.5C1 10.9656 2.71424 8.83154 5.04648 8.19411C5.44561 4.70761 8.40661 2 12 2Z"
                                    fill="#635BFF"
                                  />
                                </g>
                              </svg>
                            </div>
                          </label>
                          <TextInputWithAll
                            name="file-input"
                            type="file"
                            onChange={handleChange}
                            id="file-input"
                            ref={fileInputRef}
                            style={{
                              display: "none",
                            }}
                          />
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div id="po_logo" align="center">
            <img src={logo} alt="logo" />
            <div id="po_header_text">MANEX EXPORTS (H.K.) LTD.</div>
          </div>
          <div className="row" id="about_po_header">
            <div className="col-6">
              <div id="po_supplier_text">Supplier</div>
              <div id="po_supplier_name">
                {/* MANEX Exports (H.K) LTD */}
                {createCusPi.customerData.NAME}
              </div>
              <div id="po_supplier_address">
                {/* 福田三区73栋1单元201(ROOM NO. 201, GATE NO. 1,
								BUILDING NO 73, CENTRAL HONGKONG, FUTIAN 3RD
								AREA, YIWUCITY ,ZHEJINAG CHINA) */}
                {createCusPi.customerData.ADDR_1}, {createCusPi.customerData.CITY},{" "}
                {createCusPi.customerData.COUNTRY}
              </div>
              <div id="po_supplier_ph">
                TEL:{" "}
                <span id="po_supplier_no">
                  {" "}
                  {createCusPi.customerData.CONTACT_NUMBER}
                </span>
              </div>
              <div id="po_supplier_ph">
                FAX: <span id="po_supplier_no"> {createCusPi.customerData.FAX}</span>
              </div>
              <div id="po_supplier_ph">
                Proforma Invoice No:{" "}
                {/* while creating PI Proforma Invoice No will be empty */}
                <span id="po_supplier_no">{""}</span>
              </div>
              <div id="po_supplier_ph">
                <div
                  style={{
                    width: "320px",
                    marginBottom: "40px",
                    marginTop: "20px",
                  }}
                  className="d-flex align-items-center"
                >
                  Date:
                  <DatePicker
                    id="cust_date_div"
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    dateFormat="dd-MMMM-yyyy"
                  />
                  {/* <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
								<path d="M6.0026 0.667969V2.0013H10.0026V0.667969H11.3359V2.0013H14.0026C14.3708 2.0013 14.6693 2.29978 14.6693 2.66797V13.3346C14.6693 13.7028 14.3708 14.0013 14.0026 14.0013H2.0026C1.63442 14.0013 1.33594 13.7028 1.33594 13.3346V2.66797C1.33594 2.29978 1.63442 2.0013 2.0026 2.0013H4.66927V0.667969H6.0026ZM13.3359 7.33463H2.66927V12.668H13.3359V7.33463ZM5.33594 8.66797V10.0013H4.0026V8.66797H5.33594ZM8.66927 8.66797V10.0013H7.33594V8.66797H8.66927ZM12.0026 8.66797V10.0013H10.6693V8.66797H12.0026ZM4.66927 3.33464H2.66927V6.0013H13.3359V3.33464H11.3359V4.66797H10.0026V3.33464H6.0026V4.66797H4.66927V3.33464Z" fill="#635BFF"/>
							  </svg> */}
                </div>
              </div>
            </div>
            <div className="col-6 " id="about_po_left_bg" align="end">
              <div id="content_wrapper">
                {" "}
                <div id="po_supplier_text">Client</div>
                <div id="po_supplier_name">{createCusPi.clientData.NAME}</div>
                <div id="po_supplier_address">
                  {/* ROOM 301, KAM ON BUILDING, 176A QUEEN'S ROAD
									CENTRAL HONGKONG. */}
                  {createCusPi.clientData.ADDR_1}, {createCusPi.clientData.CITY},{" "}
                  {createCusPi.clientData.COUNTRY}
                </div>
                <div id="po_supplier_ph">
                  TEL:{" "}
                  <span id="po_supplier_no">
                    {" "}
                    {createCusPi.clientData.CONTACT_NUMBER}
                  </span>
                </div>
                <div id="po_supplier_ph">
                  FAX:{" "}
                  <span id="po_supplier_no"> {createCusPi.clientData.FAX}</span>
                </div>
                <div
                  id="po_supplier_ph"
                  className="d-flex flex-row align-items-center"
                  style={{ gap: "12px" }}
                >
                  Customer PO Number:{" "}
                  <TextInput
                    value={poNumber}
                    id="cust_div"
                    onChange={(e) => setPoNumber(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              maxHeight: "fit-content",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <DataGrid
              localeText={{
                noRowsLabel: (
                  <div className="no_rows_css">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM11 7H13V9H11V7ZM11 11H13V17H11V11Z"
                        fill="#535260"
                      />
                    </svg>
                    {/* Enter & Select customer name & start adding your item */}
                    No Rows Available, either fetch from previous invoice or Add
                    New Item
                  </div>
                ),
              }}
              rows={resultRows}
              columns={columns}
              hideFooterPagination={true}
              hideFooter={true}
              autoHeight
              rowHeight={80}
              showCellRightBorder
              getRowClassName={getRowClassName}
              getCellClassName={getCellClassName}
            />
            {/* {inputValue && (
              <div className="suggestions-dropdown">
                {suggestions.map((suggestion, index) => (
                  <div
                    key={index}
                    className="suggestion-item"
                    onClick={() => handleSuggestionSelect(suggestion)}
                  >
                    {suggestion}
                  </div>
                ))}
              </div>
            )} */}
          </div>
          {optionShow && (
            <div
              className="options-dropdown ellipsisOption"
              style={{
                top: optionPosition.top,
                left: optionPosition.left,
              }}
            >
              <div
                className="option"
                style={{
                  textDecoration: "none",
                  color: "#535260",
                  cursor: "pointer",
                }}
              >
                Pay Supplier
              </div>
            </div>
          )}
          <div id="add_new" style={{ border: "none" }}>
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M11 11V5H13V11H19V13H13V19H11V13H5V11H11Z"
                  fill="#635BFF"
                />
              </svg>
            </div>
            <div onClick={handleAddNewItem} style={{ cursor: "pointer" }}>
              Add New Items
            </div>
          </div>
          <div className="row">
            <div className="col-5"></div>
            <div className="col-7">
              <div
                className="d-flex flex-column"
                style={{
                  gap: 20,
                  borderBottom: "0.5px solid var(--outlineVariant)",
                  marginTop: 40,
                }}
              >
                <div className="d-flex flex-row" style={{ gap: 151 }}>
                  <h2 id="total_cost_text">
                    Total Cost in {operatingCurrency}
                  </h2>
                  <p id="total_cost">
                    {" "}
                    {operatingCurrencySymbol}
                    {totalTTL_RMB}
                  </p>
                </div>
                <div
                  className="po_input position-relative d-flex align-items-center"
                  style={{ marginBottom: 28 }}
                >
                  <h2 id="total_cost_text" style={{ width: 164 }}>
                    Convert & Ex.Rate
                  </h2>
                  <DropDownInputWithoutLabel
                    options={currency}
                    value={currencyID}
                    onChange={handleCurrencyChange}
                    id="cust_div"
                    style={{
                      width: 180,
                      margin: 0,
                      padding: "12px 16px",
                      marginLeft: 13,
                    }}
                    placeholder="Select Currency"
                  />
                  <TextInputWithAll
                    style={{ width: 98, paddingRight: 0, margin: 0 }}
                    className="po_input"
                    value={currencyFactory}
                    onChange={currency_convertor}
                  />
                </div>
                <div className="d-flex flex-row" style={{ gap: 151 }}>
                  <h2 id="total_cost_text">Total Cost in {currencyLabel}</h2>
                  <p id="total_cost">
                    {/* {`${selectedCurrency?.symbol}`}{" "}
                    <USDCurrency amount={usd_value} /> */}
                    {currencySymbol} {usd_value.toFixed(2)}
                  </p>
                </div>
                <div
                  className="d-flex flex-row align-items-center"
                  style={{ gap: 106 }}
                >
                  <div
                    className="po_input position-relative d-flex justify-content-center align-items-center"
                    style={{ gap: 32 }}
                  >
                    <h2 id="total_cost_text" style={{ width: 145 }}>
                      Agent Commission
                    </h2>
                    <TextInputWithAll
                      style={{ width: 100, paddingRight: 25 }}
                      value={commission}
                      onChange={(e) => agent_Commission(e.target.value)}
                    />{" "}
                    <div
                      style={{ top: 19, right: "-20px" }}
                      id="remaining_characters"
                      className="position-absolute translate-middle-x "
                    >
                      %
                    </div>
                  </div>
                  {/* <div ><input id="agent_edit_div"/></div> */}
                  <p id="total_cost" style={{ margin: 0 }}>
                    {/* <USDCurrency amount={CommissionValue} /> */}
                    {currencySymbol} {CommissionValue.toFixed(2)}
                  </p>
                </div>
                <div
                  className="po_input position-relative d-flex align-items-center"
                  style={{ marginBottom: 28, position:"relative" }}
                >
                  <h2 id="total_cost_text" style={{ width: 164 }}>
                    Inland Transportation
                  </h2>
                  <DropDownInputWithoutLabel
                    options={containers}
                    value={selectedContainer}
                    onChange={handleContainerchange}
                    id="cust_div4"
                    style={{
                      width: 180,
                      margin: 0,
                      padding: "12px 16px",
                      marginLeft: 13,
                    }}
                    placeholder="Select Transportation"
                  />
                  <div style={{position:"relative", width: 98}}>
                  <input
                    style={{ width: 98, paddingRight: 0, margin: 0, paddingLeft:"24px" }}
                    className="po_input"
                    value={transportCost}
                    // onChange={(e) => {
                    //   const inputValue = e.target.value;
                    //   setTransportCost(inputValue);
                    // }}
                    onChange={addTransportCost}
                  />
                  <div className="" style={{position:"absolute", left:"8%", top:"25%"}}>{currencySymbol}</div>
                  </div>
                </div>
              </div>

              <div
                className="d-flex flex-column"
                style={{
                  gap: 20,
                  margin: "20px 72px 80px 0px",
                }}
              >
                <div className="d-flex flex-row" style={{ gap: 151 }}>
                  <h2 id="total_cost_text">Total Value</h2>
                  <h2 id="total_cost" style={{ fontWeight: 600 }}>
                    {/* <USDCurrency amount={finalAmount.toFixed(2)} /> */}
                    {`${currencySymbol}${finalAmount.toFixed(2)}`}
                  </h2>
                </div>
                <div
                  className="d-flex flex-row align-items-center"
                  style={{ gap: 13 }}
                >
                  <h2 id="total_cost_text" style={{ width: 524 }}>
                    SAY "{currencyLabel} {words}" ONLY
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="po_footer" className="pi_footer">
          <div id="add_supplier_buttons" className="container">
            {" "}
            <a
              href="/customers/customer-info"
              onClick={() => localStorage.setItem("activeTab", "Orders")}
              className="text-decoration-none"
            >
              {" "}
              <div id="cancel">Cancel</div>
            </a>
            <div
              className="d-flex align-items-center justify-content-right "
              style={{ gap: "12px" }}
            >
              <a
                href="/customers/customer-info"
                className="text-decoration-none"
              >
                <div id="submitsupplier" onClick={handleCreatePI}>
                  Create PI
                </div>
              </a>
            </div>
          </div>
        </div>
        <ToastContainer
          position="bottom-center"
          style={{ width: "max-content" }}
        />
        <CustomerPurchaseHistory
          show={historyShow}
          onHide={() => setHistoryShow(false)}
        />
      </div>
    </div>
  );
}
