import React from "react";
import "../../Styles/TableDetails.css";

export default function CTNSInfo({ prodInfo }) {
	const getValueOrZero = (value) => (value !== null && value !== undefined ? value : '0');

  const unitMapping = {
    MTR: "m",
    CM: "cm",
    IN: "in",
    FEET: "ft",
    MM: "mm",
    GM: "g",
    KG: "kg",
    LBS: "lbs",
    CB_CM: "cm³",
    CB_MTR: "m³",
    CB_FEET: "ft³",
    CB_MM: "mm³",
    CB_IN: "in³",
  };

  const convertUnit = (unit) => {
    return unitMapping[unit] || unit;
  };
  return (
    <>
      <div style={{ marginLeft: "0px", width: "", marginRight: "" }}>
        <div
          className="d-flex"
          style={{
            flexDirection: "row",
            marginTop: "44px",
            gap: "20px",
          }}
        >
          <div id="line"></div>
          <h2 id="productImgDet">CTNS Info</h2>
        </div>

        <div style={{ width: "100%", marginBottom: "80px" }}>
          <table style={{ width: "100%" }}>
            <tr>
              <th>
                <span id="key">Packaging Type</span>
              </th>
              <td>
                <span id="value">{`${getValueOrZero(
                  prodInfo.product_details.PACKING
                )}`}</span>
              </td>
            </tr>
            <tr>
              <th>
                <span id="key">CTN Type</span>
              </th>
              <td>
                <span id="value">{`${getValueOrZero(
                  prodInfo.product_details.CTN_PKG_TYPE
                )}`}</span>
              </td>
            </tr>
            <tr>
              <th>
                <span id="key">Length</span>
              </th>
              <td>
                <span id="value">{`${getValueOrZero(
                  prodInfo.product_details.PROD_LENGTH
                )} ${convertUnit(
                  prodInfo.product_details.PROD_SIZE_UNIT
                )}`}</span>
              </td>
            </tr>
            <tr>
              <th>
                <span id="key">Width</span>
              </th>
              <td>
                <span id="value">{`${getValueOrZero(
                  prodInfo.product_details.CTN_WIDTH
                )} ${convertUnit(
                  prodInfo.product_details.CTN_SIZE_UNIT
                )}`}</span>
              </td>
            </tr>
            <tr>
              <th>
                <span id="key">Height</span>
              </th>
              <td>
                <span id="value">{`${getValueOrZero(
                  prodInfo.product_details.CTN_HEIGHT
                )} ${convertUnit(
                  prodInfo.product_details.CTN_SIZE_UNIT
                )}`}</span>
              </td>
            </tr>
            <tr>
              <th>
                <span id="key">Volume</span>
              </th>
              <td>
                <span id="value">{`${getValueOrZero(
                  prodInfo.product_details.CTN_VOLUME
                )} ${convertUnit(
                  prodInfo.product_details.CTN_VOLUME_UNIT
                )}`}</span>
              </td>
            </tr>
            <tr>
              <th>
                <span id="key">Net Weight</span>
              </th>
              <td>
                <span id="value">{`${getValueOrZero(
                  prodInfo.product_details.CTN_NET_WT
                )} ${convertUnit(prodInfo.product_details.CTN_WT_UNIT)}`}</span>
              </td>
            </tr>
            <tr>
              <th>
                <span id="key">Sup.DUN Barcode</span>
              </th>
              <td>
                <span id="value">{`${getValueOrZero(
                  prodInfo.product_details.SUPP_DUN_BAR_CODE
                )}`}</span>
              </td>
            </tr>
            <tr>
              <th>
                <span id="key">Mnx.DUN Barcode</span>
              </th>
              <td>
                <span id="value">{`${getValueOrZero(
                  prodInfo.product_details.CLIENT_DUN_BAR_CODE
                )}`}</span>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </>
  );
}
