import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
// import SearchBar from "../../../StaticComponents/SearchBar";
import { DataGrid } from "@mui/x-data-grid";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Checkbox,
} from "@material-ui/core";
import { makeApiCall } from "../../../StaticComponents/API";
import { useSearchParams } from "react-router-dom";
import SearchBar from "../../../StaticComponents/SearchBar";

export default function SearchPI(props) {
  const [rows, setRows] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const token = localStorage.getItem("token");
  const [selectedRowId, setSelectedRowId] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [total, settotal] = useState(0);
  const columns = [
    {
      field: "Select",
      headerName: "",
      width: 50,
      align: "center",
      renderCell: (params) => (
        <FormControl>
          <RadioGroup
            aria-labelledby="radio-buttons-group-label"
            name="radio-buttons-group"
            value={selectedRowId === params.row.id ? params.row.id : ""}
            onChange={() => setSelectedRowId(params.row.id)} 
          >
            <FormControlLabel
              value={params.row.id}
              control={<Radio />}
              label="" 
            />
          </RadioGroup>
        </FormControl>
     
      ),
    },
    {
      field: "inv_id",
      headerName: "Invoice No",
      width: 125,
      align: "center",
    },
    {
      field: "TOTAL_ITEM",
      headerName: "Total Item",
      width: 125,
      align: "center",
    },
    {
      field: "TOTAL_CTNS",
      headerName: "Total CTNS",
      width: 134,
      align: "center",
    },
    {
      field: "TOTAL_AMOUNT",
      headerName: "Total Amount",
      width: 149,
      type: "number",
      align: "center",
    },
    {
      field: "CREATED_ON",
      headerName: "Created On",
      width: 141,
      align: "center",
    },
  ];

  const searchProducts = (value) => {
    // const term = e.target.value.trim();
    setSearchTerm(value);
    console.log(searchTerm);
  };
  console.log("Hello");
  console.log(searchTerm);

  // const handleRowSelect = (id) => {
  //   setSelectedRowId((prevSelected) =>
  //     prevSelected.includes(id)
  //       ? prevSelected.filter((rowId) => rowId !== id) // Deselect
  //       : [...prevSelected, id] // Select
  //   );
  // };


  const handleConfirmSelection = () => {
   console.log(selectedRowId);
    props.onSelect(selectedRowId); 
    props.onHide(); 
    setSelectedRowId("");
  };
   console.log(selectedRowId);
  // const SearchBar = ({ OnChange }) => {
  //   return <input type="text" placeholder="Search..." onChange={OnChange} />;
  // };


  useEffect(() => {
    const payload = {
      APP_CLIENT_ID: localStorage.getItem("CLIENT_ID"),
      SEARCH_KEYWORD: searchTerm,
      CURRENT_PAGE: page + 1,
      ITEMS_PER_PAGE: pageSize,
    };
 
    makeApiCall({
      mod: "CustomerPI",
      action: "select_customer_invoice_for_pi",
      payload: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log("Making API call with payload:", payload);
      console.log("inv_list", response);
      const invoiceList = response.XscData?.INV_LIST || [];
      console.log("Invoice List:", invoiceList); // Log the invoice list
      settotal(response.XscData.INV_COUNT);
      if (Array.isArray(invoiceList) && invoiceList.length > 0) {
        const mappedRows = invoiceList.map((invoice) => ({
          id: invoice.CUST_INV_ID,
          inv_id: invoice.CUST_INV_NUM,
          TOTAL_ITEM: invoice.TOTAL_QTY,
          TOTAL_CTNS: invoice.TOTAL_CTN,
          TOTAL_AMOUNT: invoice.TOTAL_AMOUNT,
          CREATED_ON: invoice.CREATED_ON,
        }));
        setRows(mappedRows);
      } else {
        console.error("No invoices found or data is not an array");
        setRows([]);
      }
    })
    .catch((error) => {
      console.error("Error fetching invoices:", error);
      setRows([]);
    });

  }, [searchTerm, page, pageSize])

  return (
    <div>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div id="search_po_bg">
          <div id="search_po_text">Select an Invoice</div>
          <div>
            <SearchBar onSearch={searchProducts} />
          </div>
          <div id="po_table"
				   	style={{
					   	height: 700,
					  	width: "100%",
					  	marginBottom: "10px",
					  	position: "relative",
				  		overflow: "hidden",
					}}>
            <DataGrid
             rows={rows}
             rowHeight={80}
             columns={columns}
             pageSize={pageSize}
             rowCount={total}
             page={page}
             pagination
             paginationMode="server"
             rowsPerPageOptions={[10, 20, 50]} 
             onPageSizeChange={(newPageSize) =>{
               setPageSize(newPageSize);
               setPage(0)
              }
             }
             onPageChange={(newPage) => setPage(newPage)}
             disableSelectionOnClick
            />
          </div>
        </div>
        <div id="search_po_footer">
          <div id="cancel" onClick={props.onHide}>
            Cancel
          </div>
          <div id="submitsupplier" onClick={handleConfirmSelection}>Confirm Selection</div>
        </div>
      </Modal>
    </div>
  );
}

