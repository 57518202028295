import React from "react";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { makeApiCall } from "../../StaticComponents/API";

function DeleteSupplier(props) {
  let token = localStorage.getItem("token");
  const CLIENT_SUPPLIER_ID = localStorage.getItem("CLIENT_SUPPLIER_ID");

  const navigate = useNavigate("");
  const DeleteSupplier = () => {
    const payload = {
      CLIENT_SUPPLIER_ID: CLIENT_SUPPLIER_ID,
    };

    console.log(payload, "Payload ID check");

    makeApiCall({
      mod: "Supplier",
      action: "delete-supplier",
      payload: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((res) => {
      console.log(res, "deleted");

      setTimeout(() => {
        toast.success("Supplier Deleted Successfully!");
        props.onHide();
        setTimeout(() => {
          navigate("/suppliers");
        }, 1000);
      }, 1000);
    });
  };
  return (
    <div>
      <Modal {...props}>
        <div>
          <div id="delete_Supplier">Delete Supplier</div>
          <div id="delete_supplier_content">
            Are you sure you want to delete this Particular Supplier? <br />
            It cannot be undone.
          </div>
          <div id="delete_footer">
            <div id="delete_cancel" onClick={() => props.onHide()}>
              Cancel
            </div>
            <div id="delete_submit" onClick={DeleteSupplier}>
              Delete Permanently
            </div>
          </div>
        </div>
      </Modal>
      <ToastContainer position="bottom-center" />
    </div>
  );
}

export default DeleteSupplier;
