import React from "react";
import PerformaInvoice from "./PerformaInvoice.js";
import Invoice from "./Invoice.js";
import Shipping from "./Shipping.js";

export default function OrdersDashboard({ activeTab }) {
	return (
		<div>
			{activeTab == "PI" && <PerformaInvoice />}
			{activeTab == "I" && <Invoice />}
			{activeTab == "S&H" && <Shipping />}
		</div>
	);
}
