import React, { useEffect, useRef, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { makeStyles } from "@material-ui/styles";
import { Dropdown } from "react-bootstrap";
import excelDownload from "../../assets/excelDownload.png";
import pdfDownload from "../../assets/pdfDownload.png";
import ReceivePayment from "./ReceivePayment";
import { makeApiCall } from "../../StaticComponents/API";
import noDataImage from "../../assets/no_result_img.png";
import nodataimage from "../../assets/no_data_image_2.png";

const useStyles = makeStyles({
  header: {
    "& .MuiDataGrid-columnHeaderTitle": {
      fontWeight: "bold",
    },
    "& .MuiDataGrid-menuIcon": {
      display: "none",
    },

    "& .MuiCheckbox-root": {
      color: "var(--onSurface)",
    },
  },

  row: {
    "& .MuiCheckbox-root": {
      color: "var(--onSurface)",
    },

    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "var(--size-140) !important",
  },

  headerDownloadPdf: {
    minWidth: "218px !important",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontSize: "14px",
    paddingLeft: "75px !important",
  },

  headerDownloadExcel: {
    minWidth: "158px !important",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontSize: "14px",
  },

  headerARSelected: {
    minWidth: "173px !important",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontSize: "14px",
    marginLeft: "-70px",
  },

  padding: {
    paddingLeft: "75px !important",
  },
});

export default function AccountReceivableTable(props) {
  const API_URL = process.env.REACT_APP_API_URL;
  const [pageSize, setPageSize] = useState(10);
  const [optionShow, setOptionShow] = useState(false);
  const [optionPosition, setOptionPosition] = useState({ top: 0, left: -100 });
  const [columns, setColumns] = useState([]);
  const [selection, setSelection] = useState([]);
  const ellipsisRef = useRef(null);
  const [currentStatus, setCurrentStatus] = useState("");
  const [show, setShow] = useState(false);
  const initialrows = [];
  const [rows, setrows] = useState(initialrows);
  const [total, settotal] = useState();
  const [page, setpage] = useState(0);
  const handlepagechange = (newpage) => {
    setpage(newpage);
  };
  const [invoiceCurrencyName, setInvoiceCurrencyName] = useState("");
  const [invoiceCurrencySymbol, setInvoiceCurrencySymbol] = useState("");

  const STATUS_CLASS_MAP = {
    Unpaid: "invoice_reject_bg",
    Paid: "invoice_completed_status_bg",
  };

  const getPaymentStatus = (paymentStatus) => {
    if (paymentStatus === 0 || paymentStatus === null) return "Unpaid";
    if (paymentStatus === 1) return "Paid";
  };

  const handleEllipsisClick = (e, status, params, INVOICE_ID) => {
    const rect = e.target.getBoundingClientRect();
    setOptionPosition({
      top: rect.bottom + window.scrollY,
      left: rect.left + window.scrollX,
    });
    setOptionShow(true);
    setCurrentStatus(status);
    console.log(params.row.id);
    localStorage.setItem("CUST_INVOICE_ID", params.row.id);
  };

  const handleClickOutside = (event) => {
    if (ellipsisRef.current && !ellipsisRef.current.contains(event.target)) {
      setOptionShow(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const classes = useStyles();

  const initialColumns = [
    {
      field: "CUST_NUM",
      headerName: "Cust.ID",
      width: 200,
      cellClassName: classes.padding,
      headerAlign: "left",
      headerClassName: classes.padding,
    },
    {
      field: "CUSTOMER_NAME",
      headerName: "Customer Name",
      width: 169,
      editable: false,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "POID",
      headerName: "Cust.PO-No",
      width: 136,
      editable: false,
      align: "left",
      color: "#f5f2ff",
      headerAlign: "left",
    },
    {
      field: "INVOICE_ID",
      headerName: "Invoice No",
      width: 175,
      editable: false,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "TOTAL_AMOUNT",
      headerName: "Total Amount",
      width: 151,
      editable: false,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "PENDING_AMOUNT",
      headerName: "Pending Amount",
      width: 173,
      editable: false,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "PAYMENT_STATUS",
      headerName: "Payment Status",
      width: 141,
      editable: false,
      sortable: false,
      align: "left",
      headerAlign: "left",
      renderCell: (params) => {
        const paymentStatus = params.row.PAYMENT_STATUS;
        const paymentStatusText = getPaymentStatus(paymentStatus);
        const className = STATUS_CLASS_MAP[paymentStatusText] || "";
        return <div className={className}>{paymentStatusText}</div>;
      },
    },
    {
      field: "VERTICAL_ELLIPSIS",
      width: 25,
      editable: false,
      align: "left",
      sortable: false,
      renderHeader: () => <div></div>,
      renderCell: (params) => {
        const paymentStatus = params.row.PAYMENT_STATUS;
        let ID = params.row.ID;
        const paymentStatusText = getPaymentStatus(paymentStatus);
        if (paymentStatus === 1) {
          return <div></div>;
        }
        return (
          <div
            style={{
              cursor: "pointer",
              width: "30px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            onClick={(e) =>
              handleEllipsisClick(e, paymentStatusText, params, ID)
            }
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="4"
              height="18"
              viewBox="0 0 4 18"
              fill="none"
            >
              <path
                d="M2 0C1.175 0 0.5 0.675 0.5 1.5C0.5 2.325 1.175 3 2 3C2.825 3 3.5 2.325 3.5 1.5C3.5 0.675 2.825 0 2 0ZM2 15C1.175 15 0.5 15.675 0.5 16.5C0.5 17.325 1.175 18 2 18C2.825 18 3.5 17.325 3.5 16.5C3.5 15.675 2.825 15 2 15ZM2 7.5C1.175 7.5 0.5 8.175 0.5 9C0.5 9.825 1.175 10.5 2 10.5C2.825 10.5 3.5 9.825 3.5 9C3.5 8.175 2.825 7.5 2 7.5Z"
                fill="#5D6B82"
              />
            </svg>
          </div>
        );
      },
    },
  ];

  const additionalColumns = [
    {
      field: "CUST_NUM",
      headerName: "",
      width: 200,
      cellClassName: "sticky-column",
      headerClassName: classes.headerDownloadPdf,
      renderHeader: () => (
        <div
          className="d-flex flex-row align-items-center"
          style={{
            gap: "8px",
            height: "20px",
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
          }}
          onClick={handlepdfdownload}
        >
          <div>
            <img src={pdfDownload} />
          </div>
          <span style={{ color: "#635bff" }} id="dwn_text">
            Download Pdf
          </span>
        </div>
      ),
      sortable: false,
      cellClassName: classes.padding,
    },
    {
      field: "CUSTOMER_NAME",
      headerName: "",
      width: 169,
      editable: false,
      align: "left",
      headerClassName: classes.headerDownloadExcel,
      renderHeader: () => (
        <div
          className="d-flex flex-row align-items-center"
          style={{
            gap: "8px",
            height: "20px",
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
          }}
          onClick={handleexceldownload}
        >
          <div>
            <img src={excelDownload} />
          </div>
          <span style={{ color: "#635bff" }} id="dwn_text">
            Download Excel
          </span>
        </div>
      ),
      sortable: false,
    },
    {
      field: "POID",
      headerName: "Cust.PO-ID",
      width: 136,
      editable: false,
      align: "left",
      color: "#f5f2ff",
      headerAlign: "left",
      renderHeader: () => <div></div>,
      sortable: false,
    },
    {
      field: "INVOICE_ID",
      headerName: "Invoice No",
      width: 175,
      editable: false,
      align: "left",
      headerAlign: "left",
      renderHeader: () => <div></div>,
      sortable: false,
    },
    {
      field: "TOTAL_AMOUNT",
      headerName: "Total Amount",
      width: 151,
      editable: false,
      align: "left",
      headerAlign: "left",
      renderHeader: () => <div></div>,
      sortable: false,
    },
    {
      field: "PENDING_AMOUNT",
      headerName: "Pending Amount",
      width: 173,
      editable: false,
      align: "left",
      headerAlign: "left",
      renderHeader: () => <div></div>,
      sortable: false,
    },
    {
      field: "PAYMENT_STATUS",
      headerName: "Payment Status",
      width: 166,
      editable: false,
      sortable: false,
      align: "left",
      headerAlign: "left",
      renderHeader: () => <div></div>,
      renderCell: (params) => {
        const paymentStatus = params.row.PAYMENT_STATUS;
        const paymentStatusText = getPaymentStatus(paymentStatus);
        const className = STATUS_CLASS_MAP[paymentStatusText] || "";
        return <div className={className}>{paymentStatusText}</div>;
      },
    },
    {
      field: "VERTICAL_ELLIPSIS",
      width: 25,
      editable: false,
      align: "left",
      sortable: false,
      headerClassName: classes.headerARSelected,
      headerAlign: "left",
      renderHeader: () => <div>{selection.length} AR Selected</div>,
      renderCell: (params) => {
        const paymentStatus = params.row.PAYMENT_STATUS;
        let ID = params.row.ID;
        const paymentStatusText = getPaymentStatus(paymentStatus);
        if (paymentStatus === 1) {
          return <div></div>;
        }
        return (
          <div
            style={{
              cursor: "pointer",
              width: "30px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            onClick={(e) => handleEllipsisClick(e, paymentStatusText, params)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="4"
              height="18"
              viewBox="0 0 4 18"
              fill="none"
            >
              <path
                d="M2 0C1.175 0 0.5 0.675 0.5 1.5C0.5 2.325 1.175 3 2 3C2.825 3 3.5 2.325 3.5 1.5C3.5 0.675 2.825 0 2 0ZM2 15C1.175 15 0.5 15.675 0.5 16.5C0.5 17.325 1.175 18 2 18C2.825 18 3.5 17.325 3.5 16.5C3.5 15.675 2.825 15 2 15ZM2 7.5C1.175 7.5 0.5 8.175 0.5 9C0.5 9.825 1.175 10.5 2 10.5C2.825 10.5 3.5 9.825 3.5 9C3.5 8.175 2.825 7.5 2 7.5Z"
                fill="#5D6B82"
              />
            </svg>
          </div>
        );
      },
    },
  ];

  const APP_CLIENT_ID = localStorage.getItem("CLIENT_ID");
  const token = localStorage.getItem("token");

  console.log(page);

  useEffect(() => {
    const payload = {
      APP_CLIENT_ID: APP_CLIENT_ID,
      SEARCH_KEYWORD: props.searchkeyword,
      CURRENT_PAGE: page + 1,
      ITEM_PER_PAGE: pageSize,
    };
    console.log(page, pageSize);
    makeApiCall({
      mod: "Report",
      action: "account_receivable_list",
      payload: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        console.log("request", payload);
        console.log("account receivable records fetched", response); // Check the response here
        const reports = response.XscData.REPORT_LIST;

        if (Array.isArray(reports)) {
          const mappedRows = reports.map((report) => ({
            id: report.INVOICE_ID || "N/A",
            CUST_NUM: report.CUST_ID || "N/A",
            CUSTOMER_NAME: report.CUSTOMER_NAME || "N/A",
            POID: report.CUST_PO_NUM || "N/A",
            INVOICE_ID: report.INVOICE_NO || "N/A",
            PAYMENT_STATUS: report.PAYMENT_STATUS,
            PENDING_AMOUNT:
              report.PENDING_AMOUNT != null
                ? report.PENDING_AMOUNT
                  ? `${report.INVOICE_CURRENCY_SYMBOL}${report.PENDING_AMOUNT}`
                  : "0"
                : "0",
            TOTAL_AMOUNT:
              report.TOTAL_AMOUNT != null
                ? report.TOTAL_AMOUNT
                  ? `${report.INVOICE_CURRENCY_SYMBOL}${report.TOTAL_AMOUNT}`
                  : "0"
                : "0",
          }));
          setrows(mappedRows);
        }else{
          setrows([]);
        }
        settotal(response.XscData.REPORT_LIST_COUNT);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [APP_CLIENT_ID, props.searchkeyword, page, pageSize]);

  const handlepdfdownload = () => {
    const payload1 = {
      APP_CLIENT_ID: APP_CLIENT_ID,
      CLIENT_INV_ID: selection,
    };
    makeApiCall({
      mod: "Report",
      action: "generate-AR-pdf",
      payload: payload1,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        console.log("request", payload1);
        console.log("AR report pdf fetched", response);
        if (response.XscStatus === 1) {
          const url = `${API_URL}/${response.XscData.URL}`;
          fetch(url)
            .then((response) => response.blob())
            .then((blob) => {
              const link = document.createElement("a");
              link.href = URL.createObjectURL(blob);
              link.download = "account_receivalble_report.pdf";
              link.click();
            });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleexceldownload = () => {
    const payload1 = {
      APP_CLIENT_ID: APP_CLIENT_ID,
      CLIENT_CUST_ID: selection,
    };
    makeApiCall({
      mod: "Report",
      action: "generate-AR-excel",
      payload: payload1,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        console.log("request", payload1);
        console.log("AR report excel fetched", response); // Check the response here

        if (response.XscStatus === 1) {
          const url = `${API_URL}/${response.XscData.URL}`;
          fetch(url)
            .then((response) => response.blob())
            .then((blob) => {
              const link = document.createElement("a");
              link.href = URL.createObjectURL(blob);
              link.download = "account_receivalble_report.xlsx"; // Set the filename here
              link.click();
            });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  console.log("rows selected", selection);

  const handleSelectionChange = (newSelection) => {
    setSelection(newSelection);
  };

  useEffect(() => {
    if (selection.length > 0) {
      setColumns(additionalColumns);
    } else {
      setColumns(initialColumns);
    }
  }, [selection]);
  const handleShow = () => {
    setShow(true);
  };
  return (
    <>
      <div
        style={{
          height: 600,
          width: "100%",
          marginTop: "20px",
          marginBottom: "40px",
        }}
      >
        <DataGrid
          localeText={{
            noRowsLabel: (
              <div className="no_rows_css" style={{ textAlign: "center" }}>
                <img
                  src={props.searchkeyword ? noDataImage : nodataimage}
                  alt=""
                  style={{ width: "103.26px", height: "100px" }}
                />
                <div
                  id="no_data_found_text"
                  style={{
                    fontFamily: "Inter",
                    fontWeight: 600,
                    fontSize: "22px",
                  }}
                >
                  {props.searchkeyword
                    ? "No results found in the list."
                    : "No reports have generated yet"}
                </div>
              </div>
            ),
          }}
          rows={rows}
          columns={columns}
          pageSize={pageSize}
          page={page}
          onPageChange={handlepagechange}
          pagination
          paginationMode="server"
          rowCount={total}
          rowsPerPageOptions={[10, 20, 50]}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          disableSelectionOnClick
          classes={{ columnHeader: classes.header, row: classes.row }}
          checkboxSelection
          onSelectionModelChange={(newSelection) =>
            handleSelectionChange(newSelection)
          }
        />
      </div>
      {optionShow && (
        <div
          className="options-dropdown ellipsisOption"
          style={{
            top: optionPosition.top,
            left: optionPosition.left - 200,
          }}
        >
          {currentStatus === "Unpaid" && (
            <div
              className="d-flex flex-column align-items-start gap-2"
              ref={ellipsisRef}
            >
              <a
                id="dropdown_options"
                onClick={handleShow}
                style={{
                  cursor: "pointer",
                  textDecoration: "none",
                  color: "#535260",
                }}
              >
                Receive Payment
              </a>
            </div>
          )}
        </div>
      )}
      <ReceivePayment show={show} onHide={() => setShow(false)}/>
    </>
  );
}
