import React from "react";
import "../../Styles/SupplierInfo.css";

const ShippingHeader = ({ activeTab, onTabChange }) => {
  return (
    <div>
     
     <div
        id="suppliers_topbar_bg"
        style={{ paddingLeft: "0", paddingRight: "0" }}
        >
         <div className="container d-flex justify-content-between align-items-center">
          <div id="dashboard_header"> S&H Details</div>
            
        </div>
      </div>
     
      <div id="supplier_menu_bg">
        <div className="container">
          <div id="supplier_menus">
            {" "}
            <div
              style={{
                color: activeTab === "container" && "var(--primaryContainer)",
                fontSize: activeTab === "container" && "14px",
                fontWeight: activeTab === "container" && "600",
              }}
              id="supplier_header_menu"
              onClick={() => onTabChange("container")}
            >
              Container Details
              {activeTab === "container" && (
                <div
                  id="supplier_active_tab"
                  className="position-absolute  bottom-0 start-50 translate-middle-x"
                ></div>
              )}
            </div>
            <div
              style={{
                color: activeTab === "carton" && "var(--primaryContainer)",
                fontSize: activeTab === "carton" && "14px",
                fontWeight: activeTab === "carton" && "600",
              }}
              id="supplier_header_menu"
              onClick={() => onTabChange("carton")}
            >
              Carton Details
              {activeTab === "carton" && (
                <div
                  id="supplier_active_tab"
                  className="position-absolute  bottom-0 start-50 translate-middle-x"
                ></div>
              )}
            </div>
        
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShippingHeader;
