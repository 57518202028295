// src/components/Inputs/DropDownWithInput.js

import React from 'react';
import PropTypes from 'prop-types';
// import './DropDownWithInput.css'; // Assuming you've created this CSS file for styling

const DropDownWithInput = ({ options, inputProps, selectProps }) => {
  return (
    <div className="packaging_in">
      <input  {...inputProps} autoComplete='off'/>
      <select {...selectProps}>
        {options.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

DropDownWithInput.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  inputProps: PropTypes.object,
  selectProps: PropTypes.object,
};

DropDownWithInput.defaultProps = {
  inputProps: {},
  selectProps: {},
};

export default DropDownWithInput;
