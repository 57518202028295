import React, { useEffect, useState } from "react";
import logo from "../../../assets/Manex Logo.png";
import { DataGrid } from "@mui/x-data-grid";
import po_img from "../../../assets/po_img.png";
import { makeStyles } from "@material-ui/styles";
import { makeApiCall } from "../../../StaticComponents/API";
import { useSearchParams } from "react-router-dom";
import TimestampToDate from "../../../StaticComponents/DateFormat";
import default_image from "../../../assets/Product_Package.png";
const useStyles = makeStyles({
  subheadingRow: {
    textAlign: "center",
    fontWeight: "bold",
    minHeight: "32px !important",
    maxHeight: "32px !important",
    "& .MuiDataGrid-cell": {
      border: "none !important",
      lineHeight: "32px !important",
      minHeight: "32px !important",
      maxHeight: "32px !important",
    },
  },

  subheadingCell: {
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    paddingLeft: 10,
    width: "100%",
    border: "0.5px solid var(--outlineVariant) !important",
    lineHeight: "24px !important",
    minHeight: "32px !important",
    maxHeight: "32px !important",
  },

  padding: { padding: "0px !important" },
});

export default function ViewPO() {
  const API_URL = process.env.REACT_APP_API_URL;
  const classes = useStyles();
  const [Weight_Unit,setWeight_Unit]=useState("")
  const token = localStorage.getItem("token");
  const [PO, setPO] = useState();
  const [deliveryData, setDeliveryData] = useState({});
  const [supplier, setsupplier] = useState({});
  const [client, setclient] = useState({});
  const [timeStamp, setTimeStamp] = useState();
  const [deliverydate, setdeliverydate] = useState(null);
  function convertUnixTimestampToDate(unixTimestamp) {
    const date = new Date(unixTimestamp * 1000);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }
  const CLIENT_SUPPLIER_ID = localStorage.getItem("CLIENT_SUPPLIER_ID");
  const WeightOptions = [
    { value: "KG", label: "Kgs" },
    { value: "GM", label: "Gms" },
    { value: "LBS", label: "lbs" },
  ];
  function getLabelByValue(value) {
    const option = WeightOptions.find((opt) => opt.value === value);
    return option ? option.label : null;
  }
  const [PONUM, setPONUM] = useState();
  const [createon, setcreateon] = useState(0);
  const [CurrencySymbol, setCurrencySymbol] = useState("");
  const columns = [
    {
      field: "client_id",
      headerName: "Mnx. Item Id",
      width: 148,
      align: "center",
    },
    {
      field: "SUP_ITEM_ID",
      headerName: "Sup. Item Id",
      width: 148,
      align: "center",
    },
    {
      field: "TOTAL_QTY",
      headerName: "Total QTY",
      width: 150,
      align: "center",
    },
    {
      field: "PICTURE",
      headerName: "Picture",
      width: 120,
      align: "center",
      renderCell: (params) => {
        const imageUrl = params.row.MM_FILE_NAME
          ? `${API_URL}/media/${params.row.MM_FILE_NAME}`
          : default_image;
        return params.row.id_desc === "Total" ? null : (
          <div id="po_pic_bg">
            <img
              src={imageUrl}
              alt="Product Image"
              style={{ width: "100%", height: "auto" }}
            />
          </div>
        );
      },
    },
    {
      field: "CTN_QTY",
      headerName: "CTN/QTY",
      width: 150,

      align: "center",
    },
    {
      field: "INNER",
      headerName: "Inner",
      width: 150,
      align: "center",
    },
    {
      field: "CTN",
      headerName: "CTN",
      width: 150,
      renderCell: (params) => {
        return params.row.id_desc === "Total" ? (
          <div
            className={classes.subheadingCell}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              paddingRight: "6px",
            }}
          >
            {params.value}
          </div>
        ) : null;
      },
    },
    {
      field: "DESCRIPTION",
      headerName: "Description",
      width: 320,
      align: "center",
    },
    {
      field: "PRICE",
      headerName: "Price",
      width: 180,
	  renderCell: (params)=>{
		return <div >{params.row.CURRENCY_SYMBOL} {params.value}</div>
	  }
    },
    {
      field: "TOTAL_AMOUNT",
      headerName: "Total Amount",
      width: 160,
      align: "center",
      renderCell: (params) => {
        return params.row.id_desc === "Total" ? (
          <div
            className={classes.subheadingCell}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              alignContent: "center",
              paddingRight: "6px",
            }}
          >
            {CurrencySymbol}
            {params.value}
          </div>
        ) : (
          <div>
            {params.row.CURRENCY_SYMBOL}
            {params.value}
          </div>
        );
      },
    },
    {
      field: "BARCODE",
      headerName: "Barcode",
      width: 320,
      align: "center",
    },
    {
      field: "DUN_BARCODE",
      headerName: "Dun Barcode",
      width: 320,
      align: "center",
    },
    {
      field: "PACKING",
      headerName: "Packing",
      width: 320,
      align: "center",
    },
    {
      field: "CBM",
      headerName: "CBM",
      width: 130,
      align: "center",
    },
    {
      field: "TTL_CBM",
      headerName: "TTL CBM",
      width: 130,
      align: "center",
      renderCell: (params) => {
        return params.row.id_desc === "Total" ? (
          <div
            className={classes.subheadingCell}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              paddingRight: "6px",
            }}
          >
            {params.value}
          </div>
        ) : (
          <div>{params.value.toFixed(4)}</div>
        );
      },
    },
    {
      field: "WEIGHT",
      headerName: "Weight",
      width: 240,
      renderCell:(params)=>{
        return <div className="d-flex">{params.value} {getLabelByValue(params.row.WEIGHT_UNIT)}</div>
      }
    },
    {
      field: "TTL_WEIGHT",
      headerName: "TTL Weight",
      width: 148,
      align: "center",
      renderCell: (params) => {
        const WEIGHT_UNIT=params.row.WEIGHT_UNIT
        return params.row.id_desc === "Total" ? (
          <div
            className={classes.subheadingCell}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              paddingRight: "6px",
            }}
          >
            {params.value} {getLabelByValue(Weight_Unit)}
          </div>
        ) : (<div className="d-flex">{params.value} {getLabelByValue(params.row.WEIGHT_UNIT)}</div>);
      },
    },
  ];
  const [searchparams] = useSearchParams();
  const id = searchparams.get("id");
  const poid = searchparams.get("poid");
  console.log(poid, "poid");
  const initialRows = [
    {
      id: "",
      id_desc: "Total",
      SUP_ITEM_ID: "",
      TOTAL_QTY: "",
      PICTURE: "",
      CTN_QTY: "",
      INNER: "",
      CTN: 0,
      DESCRIPTION: "",
      PRICE: "",
      TOTAL_AMOUNT: 0,
      BARCODE: "",
      DUN_BARCODE: "",
      PACKING: "",
      CBM: "",
      TTL_CBM: 0,
      WEIGHT: "",
      TTL_WEIGHT: 0,
    },
  ];
  const POID = localStorage.getItem("SUPP_PO_ID");
  useEffect(() => {
    const payload = { CLIENT_SUPP_ID: CLIENT_SUPPLIER_ID, SUPP_PO_ID: POID };
    makeApiCall({
      mod: "SupplierPO",
      action: "view_supp_po",
      payload: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        console.log("view po payload", payload);
        console.log("response for the api view po", response);
        setPO(response.XscData.PO_DETAILS);
        setsupplier(response.XscData.SUPP_DATA);
        setclient(response.XscData.CLIENT_DATA);
        const suppliersPO = response.XscData.PO_DETAILS;
        setCurrencySymbol(suppliersPO[0].CURRENCY_SYMBOL);
        setPONUM(response.XscData.DELIVERY_DATA.PO_NUM);
        setDeliveryData(response.XscData.DELIVERY_DATA);
        setdeliverydate(
          convertUnixTimestampToDate(
            response.XscData.DELIVERY_DATA.DELIVERY_DATE
          )
        );
        setcreateon(
          convertUnixTimestampToDate(response.XscData.DELIVERY_DATA.PO_DATE)
        );
        if (Array.isArray(suppliersPO) && suppliersPO.length > 0) {
          const mappedRows = suppliersPO.map((PO) => ({
            id: PO.PRODUCT_ID,
            client_id: PO.CLIENT_ITEM_NUM,
            SUP_ITEM_ID: PO.SUPP_ITEM_NUM,
            TOTAL_QTY: PO.TOTAL_QTY,
            PICTURE: 30,
            CTN_QTY: PO.QTY_PER_CTN,
            INNER: PO.P_INNER || "N/A",
            CTN: PO.TOTAL_CTN,
            DESCRIPTION: PO.DESCRIPTION,
            PRICE: PO.PRICE,
            TOTAL_AMOUNT: "¥7,459.20",
            BARCODE: PO.CUST_BAR_CODE || PO.SUPP_BAR_CODE || PO.CLIENT_BAR_CODE,
            DUN_BARCODE:
              PO.CUST_DUN_BAR_CODE ||
              PO.SUPP_DUN_BAR_CODE ||
              PO.CLIENT_DUN_BAR_CODE,
            PACKING: PO.PACKING,
            CBM: PO.CBM,
            TTL_CBM: "0.73",
            WEIGHT: PO.WEIGHT,
            TTL_WEIGHT: "1,024.80",
            CURRENCY_SYMBOL: PO.CURRENCY_SYMBOL,
            WEIGHT_UNIT:PO.WEIGHT_UNIT
          }));
          const finalRows = [...mappedRows, ...initialRows];
          setRows(finalRows);
        }

        setcreateon(
          convertUnixTimestampToDate(response.XscData.DELIVERY_DATA.PO_DATE)
        );
        setWeight_Unit(suppliersPO[0].WEIGHT_UNIT)
        if (Array.isArray(suppliersPO) && suppliersPO.length > 0) {
          const mappedRows = suppliersPO.map((PO) => ({
            id: PO.PRODUCT_ID,
            client_id: PO.CLIENT_ITEM_NUM,
            SUP_ITEM_ID: PO.SUPP_ITEM_NUM,
            TOTAL_QTY: PO.TOTAL_QTY,
            PICTURE: 30,
            CTN_QTY: PO.QTY_PER_CTN,
            INNER: PO.P_INNER,
            CTN: PO.TOTAL_CTN,
            DESCRIPTION: PO.DESCRIPTION,
            PRICE: PO.PRICE,
            TOTAL_AMOUNT: "¥7,459.20",
            BARCODE: PO.CUST_BAR_CODE || PO.SUPP_BAR_CODE || PO.CLIENT_BAR_CODE,
            DUN_BARCODE:
              PO.CUST_DUN_BAR_CODE ||
              PO.SUPP_DUN_BAR_CODE ||
              PO.CLIENT_DUN_BAR_CODE,
            PACKING: PO.PACKING,
            CBM: PO.CBM,
            TTL_CBM: "0.73",
            WEIGHT: PO.WEIGHT,
            TTL_WEIGHT: "1,024.80",
            MM_FILE_NAME: PO.MM_FILE_NAME,
            CURRENCY_SYMBOL: PO.CURRENCY_SYMBOL,
            WEIGHT_UNIT:PO.WEIGHT_UNIT
          }));
          const finalRows = [...mappedRows, ...initialRows];
          setRows(finalRows);
        }
      })
      .catch((error) => {
        console.log("error");
      });
  }, []);
  let calculatedRows = [];
  const [rows, setRows] = useState();
  if (Array.isArray(rows) && rows.length > 0) {
    calculatedRows = rows.map((row) => ({
      ...row,
      TOTAL_AMOUNT: row.TOTAL_QTY * row.PRICE,
      TTL_WEIGHT: row.TOTAL_QTY * row.WEIGHT,
      TTL_CBM: row.CTN * row.CBM,
      // Calculate TOTAL_AMOUNT based on TOTAL_QTY and PRICE
    }));
  }
  const totalAmountAllRows = calculatedRows.reduce(
    (acc, row) => acc + row.TOTAL_AMOUNT,
    0
  );
  const totalCBMAllRows = calculatedRows.reduce(
    (acc, row) => acc + row.TTL_CBM,
    0
  );
  const totalWeightAllRows = calculatedRows.reduce(
    (acc, row) => acc + row.TTL_WEIGHT,
    0
  );
  const CTNAllRows = calculatedRows.reduce((acc, row) => acc + row.CTN, 0);
  const resultRows = calculatedRows.map((row) => {
    if (row.id_desc === "Total") {
      return {
        ...row,
        TOTAL_AMOUNT: totalAmountAllRows,
        CTN: CTNAllRows,
        TTL_CBM: totalCBMAllRows,
        TTL_WEIGHT: totalWeightAllRows,
      };
    }
    return row;
  });

  const getRowClassName = (params) => {
    return params.row.id_desc === "Total" ? classes.subheadingRow : "";
  };

  const getCellClassName = (params) => {
    return params.row.id_desc === "Total" ? classes.padding : "";
  };
  console.log("PO details", PO);
  console.log("supplier data", supplier);
  console.log("calculated rows", calculatedRows);
  console.log("client data", client);
  console.log("amount", totalWeightAllRows);
  console.log("date format", createon);
  console.log(deliveryData.DELIVERY_DATE);

  // const timestamp = setDeliveryData.DELIVERY_DATE;

  return (
    <div>
      <div id="suppliers_topbar_bg" className="">
        <div className="container d-flex justify-content-between align-items-center">
          <div id="header_name" className="d-flex ">
            <div>
              <a href="/suppliers/supplier-info">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M7.82843 11.0009H20V13.0009H7.82843L13.1924 18.3648L11.7782 19.779L4 12.0009L11.7782 4.22266L13.1924 5.63687L7.82843 11.0009Z"
                    fill="var(--onSurface)"
                  />
                  <path
                    d="M7.82843 11.0009H20V13.0009H7.82843L13.1924 18.3648L11.7782 19.779L4 12.0009L11.7782 4.22266L13.1924 5.63687L7.82843 11.0009Z"
                    fill="black"
                    fill-opacity="0.2"
                  />
                  <path
                    d="M7.82843 11.0009H20V13.0009H7.82843L13.1924 18.3648L11.7782 19.779L4 12.0009L11.7782 4.22266L13.1924 5.63687L7.82843 11.0009Z"
                    fill="black"
                    fill-opacity="0.2"
                  />
                  <path
                    d="M7.82843 11.0009H20V13.0009H7.82843L13.1924 18.3648L11.7782 19.779L4 12.0009L11.7782 4.22266L13.1924 5.63687L7.82843 11.0009Z"
                    fill="black"
                    fill-opacity="0.2"
                  />
                  <path
                    d="M7.82843 11.0009H20V13.0009H7.82843L13.1924 18.3648L11.7782 19.779L4 12.0009L11.7782 4.22266L13.1924 5.63687L7.82843 11.0009Z"
                    fill="black"
                    fill-opacity="0.2"
                  />
                </svg>
              </a>
            </div>

            <span className="ps-2">
              {" "}
              View Purchase Order
              <div id="edit_supplier_id">{PONUM || "N/A"}</div>
            </span>
          </div>
        </div>
      </div>
      <div id="create_po_bg">
        <div className="container">
          <div id="po_logo" align="center">
            <img src={logo} alt="logo" />
            <div id="po_header_text">{client.NAME || "N/A"}</div>
          </div>
          <div className="row" id="about_po_header">
            <div className="col-6">
              <div id="po_supplier_text">Supplier</div>
              <div id="po_supplier_name">{supplier.NAME || null}</div>
              <div id="po_supplier_address">
                {`${supplier.ADDR_1},${supplier.ADDR_2}`}
              </div>
              <div id="po_supplier_ph">
                TEL: <span id="po_supplier_no"> {supplier.CONTACT_NUMBER}</span>
              </div>
              <div id="po_supplier_ph">
                FAX: <span id="po_supplier_no">{supplier.FAX}</span>
              </div>
              <div id="po_supplier_ph">
                Purchase Order No: <span id="po_supplier_no">{PONUM}</span>
              </div>
              <div id="po_supplier_ph">
                Date: <span id="po_supplier_no"> {createon}</span>
              </div>
            </div>
            <div className="col-6 " id="about_po_left_bg" align="end">
              <div id="content_wrapper">
                {" "}
                <div id="po_supplier_text">Order By</div>
                <div id="po_supplier_name">{client.NAME || "N/A"}</div>
                <div id="po_supplier_address">
                  {`${client.ADDR_1},${client.ADDR_2}`}
                </div>
                <div id="po_supplier_ph">
                  TEL: <span id="po_supplier_no"> {client.CONTACT_NUMBER}</span>
                </div>
                <div id="po_supplier_ph">
                  FAX: <span id="po_supplier_no"> {client.FAX}</span>
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              // height: "200px",
              maxHeight: "fit-content",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <DataGrid
              rows={resultRows}
              columns={columns.map((col) => ({ ...col, filterable: false, sortable: false }))}
              hideFooterPagination={true}
              hideFooter={true}
              autoHeight
              rowHeight={80}
              showCellRightBorder
              getCellClassName={getCellClassName}
              getRowClassName={getRowClassName}
			  disableColumnMenu={true}  
              disableColumnFilter={true}
              sortingOrder={[]}
            />
          </div>

          <div className="row" id="about_markings">
            <div className="col-6" id="marking_text_left_bg">
              <div id="correct_marking">
                The correct markings are as follows: (both sides)
              </div>
              <div style={{ whiteSpace: "pre-line" }}>
                {deliveryData.SHIPPING_MARKS}
              </div>
              {/* <div>LAFAYETTE</div>
							<div>HUNTER TOOLS</div>
							<div>DESTINATION: COLON FREE ZONE</div>
							<div>P/I.NO : F-1670 -货号</div>
							<div>C/NO: </div>
							<div>MADE IN CHINA</div> */}
            </div>
            <div className="col-6 " id="about_po_left_bg" align="end">
              <div id="marking_text_left_bg">
                <div id="correct_marking">
                  Side marks are as follows: (Both sides)
                </div>
                <div>DESCRIPTION: 品名</div>
                <div>REF. CODE: 货号</div>
                <div>BARCODE: 条形码</div>
                <div>QUANTITY: 包装数</div>
                <div>WEIGHT: 此处填入重量 KGS</div>
                <div>MEASUREMENT: 此处填体积</div>
              </div>
            </div>
          </div>
          <div id="po_terms_condtions">
            <div id="po_terms_title">Terms & Conditions</div>
            <div>
              EACH CARTON SHOULD BE STRONG EXPORT CARTON WITH DOUBLE STRAP
              WITHOUT CHINESE WRITING, THE SHIPPING MARK SHOULD BE STICKED ON
              BOTH SIDE.箱子使用牢固无中文外贸箱, 并带有打包带, 唛头贴四方.
              (正唛两张, 侧唛两张)
            </div>
            <div>
              IF THE SHIPPMENT GOODS ARE NOT ACCORDING TO OUR REQUIRMENT, WE
              HAVE THE RIGHT TO REJECT THE GOODS 如不按我方要求生产货物,
              我方将有权要求产家反工或拒收货物.
            </div>
            <div>
              THE ERROR QUANTITY FOR EACH ITEM MUST BE WITHIN 10 PIECES.
              每款货误差数量必须在10个之内。
            </div>
            <div className="terms-row">
              THE PACKING , QUANTITY PER CARTON AND QUANTITY PER INNER BOX
              SHOULD SAME AS PER ORDER CONFIRMED.
            </div>

            <div id="po_del_date" style={{ display: "flex", gap: "12px" }}>
              <div id="po_del_date">交货期 Delivery date:</div>{" "}
              <div id="po_del_date">{deliverydate}</div>
            </div>

            <div
              className="po_del_date"
              style={{ display: "flex", gap: "12px" }}
            >
              <div id="po_del_date"> 交货地址 Ware house add: </div>
              <div id="po_del_date">{deliveryData.WAREHOUSE_ADDR}</div>
            </div>

            <div
              className="po_del_date"
              style={{ display: "flex", gap: "12px" }}
            >
              <div id="po_del_date">付款方式(PAYMENT TERMS):</div>
              <div id="po_del_date">{deliveryData.PAYMENT_TYPE}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
