import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate, useSearchParams } from "react-router-dom";
import po_img from "../../assets/po_img.png";
import { makeApiCall } from "../../StaticComponents/API";
import ProductViewPH from "./ProductViewPH";
import { PropaneSharp } from "@mui/icons-material";
import default_image from "../../assets/Product_Package.png";
import "../../Styles/search_products.css";
const ProductTable = (props) => {
  const API_URL = process.env.REACT_APP_API_URL;
  const [modalShow, setModalShow] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const CLIENT_ID = localStorage.getItem("CLIENT_ID");
  const [total, setTotal] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [rows, setRows] = useState([]);
  const [totalItem, settotalItem] = useState(0);
  const [searchParams] = useSearchParams();
  const searchKeyword = searchParams.get("search") || "";

  const columns = [
    {
      field: "SUP_ID",
      headerName: "Sup.Prod.ID",
      width: 150,
      cellClassName: "sticky-column",
      headerAlign: "left",
    },
    {
      field: "MNX_ID",
      headerName: "Mnx.Prod.ID",
      width: 150,
      cellClassName: "sticky-column",
      headerAlign: "left",
    },
    {
      field: "PRODUCT_NAME",
      headerName: "Product Name",
      width: 250,
      editable: false,
      align: "left",
    },
    {
      field: "PICTURE",
      headerName: "Picture",
      width: 125,
      editable: false,
      align: "left",
      color: "#f5f2ff",
      renderCell: (params) => {
        const imageUrl = params.row.MM_FILE_NAME
          ? `${API_URL}/media/${params.row.MM_FILE_NAME}`
          : default_image;
        return (
          <div
            style={{ width: "56px", height: "56px" }}
            className="search_products_img_download"
          >
            <img src={imageUrl} className="search_p_img_for_img"></img>
          </div>
        );
      },
    },
    {
      field: "SUPPLIER_NAME",
      headerName: "Supplier Name",
      width: 250,
      editable: false,
      align: "left",
    },

    {
      field: "LOCATION",
      headerName: "Location",
      width: 150,
      editable: false,
      align: "left",
    },
    {
      field: "PRICE",
      headerName: "Price",
      width: 125,
      editable: false,
      align: "left",
      renderCell: (params) => `¥${params.value}`,
    },

    {
      field: "PH",
      headerName: "PH",
      width: 75,
      editable: false,
      align: "left",
      renderCell: (params) => purchaseHistoryIcon(params.row.id),
    },
  ];
  const purchaseHistoryIcon = () => (
    <svg
      style={{ cursor: "pointer" }}
      onClick={(e) => {
        e.stopPropagation();
        setModalShow(true);
      }}
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 0V2H17.0066C17.5552 2 18 2.44495 18 2.9934V19.0066C18 19.5552 17.5551 20 17.0066 20H0.9934C0.44476 20 0 19.5551 0 19.0066V2.9934C0 2.44476 0.44495 2 0.9934 2H4V0H14ZM4 4H2V18H16V4H14V6H4V4ZM6 14V16H4V14H6ZM6 11V13H4V11H6ZM6 8V10H4V8H6ZM12 2H6V4H12V2Z"
        fill="#635BFF"
      />
    </svg>
  );
  const [page, setpage] = useState(0);
  const handlepagechange = (newpage) => {
    setpage(newpage);
  };
  const [searchparams, setsearchparams] = useSearchParams();
  const id = searchparams.get("PRODUCT_ID");
  // const id = searchparams.get("id");
  console.log("id", id);

  useEffect(() => {
    setpage(0);
  }, [searchKeyword]);

  useEffect(() => {
    const payload = {
      CURRENT_PAGE: page + 1,
      APP_CLIENT_ID: CLIENT_ID,
      FILTER: {
        PRICE: {
          MAX: 100000000,
          MIN: 0,
        },
        STATUS: 1,
      },
      SEARCH_KEYWORD: searchKeyword,
    };

    makeApiCall({
      mod: "Products",
      action: "list_all_products",
      payload: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        console.log("API Response:", response); // Log the response
        console.log("Making API call with payload:", payload);
        const productList = response.XscData.PRODUCTS;
        if (response.XscStatus === 0) {
          setRows([]);
        }
        settotalItem(response.XscData.TOTAL_ITEM.COUNT);
        if (Array.isArray(productList)) {
          const mappedRows = productList.map((product) => ({
            id: product.PROD_ID,
            CLIENT_SUPPLIER_ID: product.CLIENT_SUPP_ID,
            SUP_ID: product.SUPP_PROD_NUM,
            MNX_ID: product.CLIENT_PROD_NUM,
            PRODUCT_NAME: product.PRODUCT_NAME,
            PICTURE: product.MM_FILE_NAME,
            SUPPLIER_NAME: product.SUPPLIER_NAME,
            PRICE: product.PRICE,
            LOCATION: product.LOCATION,
            MM_FILE_NAME: product.MM_FILE_NAME,
          }));
          setRows(mappedRows);
        } else {
          console.error("Product data is not an array or is missing");
        }
      })
      .catch((err) => {});
  }, [searchKeyword, page]);
  useEffect(() => {
    setpage(0);
  }, [searchKeyword]);
  console.log("Fetching products with search keyword:", props.searchkeyword);

  const handleRowClick = (params) => {
    const PROD_ID = params.id;
    const CLIENT_SUPPLIER_ID = params.CLIENT_SUPPLIER_ID;
    if (PROD_ID) {
      localStorage.setItem("PRODUCT_ID", PROD_ID);
      localStorage.setItem("CLIENT_SUPPLIER_ID", CLIENT_SUPPLIER_ID);
      navigate(`/suppliers/supplier-info/product-info`);
    } else {
      console.error("Product ID not found in the row data");
    }

    localStorage.setItem("PROD_ID", PROD_ID);
    navigate(`/suppliers/supplier-info/product-info?id=${PROD_ID}`);
  };

  return (
    <>
      <div style={{ height: 600, width: "100%", marginTop: "20px" }}>
        <DataGrid
          onRowClick={(params) => {
            console.log("Row clicked params:", params);
            handleRowClick(params.row);
          }}
          rows={rows}
          columns={columns}
          rowCount={totalItem}
          rowHeight={80}
          pageSize={pageSize}
          rowsPerPageOptions={[10, 20, 50]}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          disableSelectionOnClick
          pagination
          paginationMode="server"
          page={page}
          onPageChange={handlepagechange}
        />
      </div>

      <ProductViewPH show={modalShow} onHide={() => setModalShow(false)} />
    </>
  );
};

export default ProductTable;
