import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { makeStyles } from "@material-ui/styles";
import excelDownload from "../../assets/excelDownload.png";
import pdfDownload from "../../assets/pdfDownload.png";
import { makeApiCall } from "../../StaticComponents/API";
import { useNavigate } from "react-router-dom";
import noDataImage from "../../assets/no_result_img.png";
import nodataimage from "../../assets/no_data_image_2.png";

const useStyles = makeStyles({
  header: {
    "& .MuiDataGrid-columnHeaderTitle": {
      fontWeight: "bold",
    },

    "& .MuiDataGrid-menuIcon": {
      display: "none",
    },
  },

  row: {
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "var(--size-140) !important",
  },

  headerDownloadPdf: {
    minWidth: "235px !important",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontSize: "14px",
    paddingLeft: "100px !important",
  },

  headerDownloadExcel: {
    minWidth: "158px !important",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontSize: "14px",
  },

  headerCustomersSelected: {
    minWidth: "173px !important",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontSize: "14px",
    marginLeft: "135px",
  },

  padding: {
    paddingLeft: "100px !important",
  },

  border: {
    "& .MuiCheckbox-root": {
      color: "var(--onSurface)",
    },
  },
});

export default function ReportCustomerTable(props) {
  const [pageSize, setPageSize] = useState(10);
  const [columns, setColumns] = useState([]);
  const [selection, setSelection] = useState([]);
  const [rowcountno, setrowcountno] = useState(0);
  const [page, setPage] = useState(0);
  const handlepagechange = (newpage) => {
    setPage(newpage);
  };
  let token = localStorage.getItem("token");

  const API_URL = process.env.REACT_APP_API_URL;
  const handlepdfdownload = () => {
    const payload1 = {
      APP_CLIENT_ID: localStorage.getItem("CLIENT_ID"),
      CLIENT_CUST_ID: selection,
    };
    makeApiCall({
      mod: "Report",
      action: "generate-customers-pdf",
      payload: payload1,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        console.log("request", payload1);
        console.log("customer report pdf fetched", response); // Check the response here

        if (response.XscStatus === 1) {
          const url = `${API_URL}/${response.XscData.URL}`;
          fetch(url)
            .then((response) => response.blob())
            .then((blob) => {
              const link = document.createElement("a");
              link.href = URL.createObjectURL(blob);
              link.download = "report.pdf"; // Set the filename here
              link.click();
            });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleexceldownload = () => {
    const payload1 = {
      APP_CLIENT_ID: localStorage.getItem("CLIENT_ID"),
      CLIENT_CUST_ID: selection,
    };
    makeApiCall({
      mod: "Report",
      action: "generate-customers-excel",
      payload: payload1,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        console.log("request", payload1);
        console.log("customer report excel fetched", response); // Check the response here

        if (response.XscStatus === 1) {
          const url = `${API_URL}/${response.XscData.URL}`;
          fetch(url)
            .then((response) => response.blob())
            .then((blob) => {
              const link = document.createElement("a");
              link.href = URL.createObjectURL(blob);
              link.download = "report.xlsx"; // Set the filename here
              link.click();
            });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  

  const classes = useStyles();

  const navigate = useNavigate();

  const payload11 = {
    APP_CLIENT_ID: localStorage.getItem("CLIENT_ID"),
    SEARCH_KEYWORD: props.searchkeyword,
  };

  const initialRows = [];

  const [rows, setRows] = React.useState(initialRows);

  const initialColumns = [
    {
      field: "CUST_NUM",
      headerName: "Cust.ID",
      width: 250,
      cellClassName: "sticky-column",
      headerClassName: classes.padding,
      headerAlign: "left",
      cellClassName: classes.padding,
    },
    {
      field: "CUSTOMER_NAME",
      headerName: "Customer Name",
      width: 225,
      editable: false,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "EMAIL",
      headerName: "Email",
      width: 275,
      editable: false,
      align: "left",
      color: "#f5f2ff",
      headerAlign: "left",
    },
    {
      field: "PHONE_NUMBER",
      headerName: "Phone Number",
      width: 250,
      editable: false,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "LOCATION",
      headerName: "Location",
      width: 117,
      editable: false,
      align: "left",
      headerAlign: "left",
    },
  ];

  const additionalColumns = [
    {
      field: "CUST_NUM",
      width: 250,
      cellClassName: "sticky-column",
      headerClassName: classes.headerDownloadPdf,
      renderHeader: () => (
        <div
          className="d-flex flex-row align-items-center"
          style={{ gap: "8px", cursor: "pointer" }}
          onClick={handlepdfdownload}
        >
          <div>
            <img src={pdfDownload} alt="Download PDF" />
          </div>
          <span style={{ color: "#635bff", cursor: "pointer" }} id="dwn_text">
            Download Pdf
          </span>
        </div>
      ),
      sortable: false,
      cellClassName: classes.padding,
    },
    {
      field: "CUSTOMER_NAME",
      width: 225,
      editable: false,
      align: "left",
      headerClassName: classes.headerDownloadExcel,
      renderHeader: () => (
        <div
          className="d-flex flex-row align-items-center"
          style={{ gap: "8px", cursor: "pointer" }}
          onClick={handleexceldownload}
        >
          <div>
            <img src={excelDownload} />
          </div>
          <span style={{ color: "#635bff" }} id="dwn_text">
            Download Excel
          </span>
        </div>
      ),
      sortable: false,
    },
    {
      field: "EMAIL",
      width: 275,
      editable: false,
      align: "left",
      color: "#f5f2ff",
      headerAlign: "left",
      renderHeader: () => <div></div>,
      sortable: false,
    },
    {
      field: "PHONE_NUMBER",
      width: 250,
      editable: false,
      align: "left",
      headerAlign: "left",
      renderHeader: () => <div></div>,
      sortable: false,
    },
    {
      field: "LOCATION",
      width: 117,
      editable: false,
      align: "left",
      headerClassName: classes.headerCustomersSelected,
      headerAlign: "left",
      renderHeader: () => <div>{selection.length} Customers Selected</div>,
      sortable: false,
    },
  ];

  const handleSelectionChange = (newSelection) => {
    if (newSelection.length === rowcountno) {
      const allRowIds = rows.map((row) => row.id);

      setSelection(allRowIds);
    } else {
      setSelection(newSelection);
    }
  };
  console.log(selection, "selection");
  useEffect(() => {
    if (selection.length > 0) {
      setColumns(additionalColumns);
    } else {
      setColumns(initialColumns);
    }
  }, [selection]);

  useEffect(() => {
    const payload = {
      APP_CLIENT_ID: localStorage.getItem("CLIENT_ID"),
      SEARCH_KEYWORD: props.searchkeyword,
      CURRENT_PAGE: page + 1,
    };
    makeApiCall({
      mod: "Report",
      action: "customer-report",
      payload: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        console.log("request", payload);
        console.log("customer list fetched", response);
        const customerReport = response.XscData.CUSTOMER_DETAILS;

        if (Array.isArray(customerReport) && customerReport.length > 0) {
          const mappedRows = customerReport.map((customer) => ({
            id: customer.CLIENT_CUST_ID,
            CUST_NUM: customer.CUST_NUM,
            CUSTOMER_NAME: customer.CUSTOMER_NAME,
            EMAIL: customer.EMAIL,
            PHONE_NUMBER: customer.CONTACT_NUMBER,
            LOCATION: customer.LOCATION,
          }));
          setRows(mappedRows);
        } else {
          setRows([]); // Set rows to an empty array if no data is found
        }
        
      })
      .catch((error) => {
        console.log(error);
      });
  }, [page, props.searchkeyword]);
  console.log("rows selected", rows);
  return (
    <>
      <div
        style={{
          height: 600,
          width: "100%",
          marginTop: "20px",
          marginBottom: "40px",
        }}
      >
        <DataGrid
         localeText={{
          noRowsLabel: (
            <div className="no_rows_css" style={{ textAlign: "center" }}>
              <img
                src={props.searchkeyword ? noDataImage : nodataimage}
                alt=""
                style={{ width: "103.26px", height: "100px" }}
              />
              <div
                id="no_data_found_text"
                style={{
                  fontFamily: "Inter",
                  fontWeight: 600,
                  fontSize: "22px",
                }}
              >
                {props.searchkeyword
                  ? "No results found in the list."
                  : "No reports have generated yet"}
              </div>
            </div>
          ),
        }}
        rows={rows}
        columns={columns}
        page={page}
        pageSize={pageSize}
        rowCount={rowcountno}
        onPageChange={handlepagechange}
        rowsPerPageOptions={[10, 20, 50]}
        pagination
        paginationMode="server"
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        disableSelectionOnClick
        classes={{ columnHeader: classes.header, row: classes.row }}
        checkboxSelection
        onSelectionModelChange={(newSelection) =>
          handleSelectionChange(newSelection)
        }
        />
      </div>
    </>
  );
}
