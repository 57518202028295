import React from "react";
import PurchaseOrder from "./PurchaseOrder";
import Quotations from "./Quotations";
import Invoice from "./Invoice";

function OrdersDashboard({ activeTab }) {
  return (
    <div>
      {activeTab == "PO" && <PurchaseOrder  />}
      {/* {activeTab == "QO" && <Quotations />} */}
      {/* {activeTab == "PI" && <div>PI</div>} */}
      {activeTab == "I" && <Invoice/>}
    </div>
  );
}

export default OrdersDashboard;
