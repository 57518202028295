import React, { useEffect, useState } from "react";
import "../../../Styles/PurchaseHistory.css";
import ProductImage from "../../../assets/product_image.png";
import { makeStyles } from "@material-ui/styles";
import { DataGrid } from "@mui/x-data-grid";
import po_img from "../../../assets/product_image.png";
import { Modal } from "react-bootstrap";
import { makeApiCall } from "../../../StaticComponents/API";
import { useSearchParams } from "react-router-dom";

const useStyles = makeStyles({
  modalContent: {
    overflow: "hidden",
  },
  header: {
    "& .MuiDataGrid-columnHeaderTitle": { fontWeight: "bold" },
    "& .MuiDataGrid-menuIcon": { display: "none" },
  },

  row: {
    "& .MuiDataGrid-cell--textLeft": { textAlign: "center !important" },
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "var(--size-140) !important",
  },

  headerId: {
    minWidth: "115px !important",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontSize: "14px",
  },

  headerProductName: {
    minWidth: "185px !important",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontSize: "14px",
  },

  headerSupplier: {
    minWidth: "180px !important",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontSize: "14px",
  },

  headerDate: {
    minWidth: "146px !important",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontSize: "14px",
  },

  headerPrice: {
    minWidth: "93px !important",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontSize: "14px",
  },
  dataGrid: {
    overflow: "hidden",
    "& .MuiDataGrid-viewport": {
      overflow: "hidden",
    },
    "& .MuiDataGrid-window": {
      overflow: "hidden",
    },
  },
});

const PurchaseHistory = (props) => {
  const [pageSize, setPageSize] = useState(10);
  const [total, setTotal] = useState(0);
  const [totalQty, setTotalQty] = useState(0);
  const [manexProdId, setManexProdId] = useState("");
  const [suppProdId, setSuppProdId] = useState("");
  const [perPrice, setPerPrice] = useState(0);
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [image, setImage] = useState(0);
  const [loading, setLoading] = useState(false);
  let [searchParams] = useSearchParams();

  const classes = useStyles();
  let token = localStorage.getItem("token");
  const API_URL = process.env.REACT_APP_API_URL;
  const prod_id = localStorage.getItem("PRODUCT_ID");
  const client_supp_id = localStorage.getItem("CLIENT_SUPPLIER_ID");
  console.log(client_supp_id);

  const handlepagechange = (newpage) => {
    setPage(newpage);
  };
  const handlepagesizechange = (num) => {
    setPageSize(num);
    setPage(0);
  };
  function convertTimestampToDate(timestamp) {
    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
    const day = String(date.getDate()).padStart(2, "0");

    return `${day}-${month}-${year}`;
  }
  const columns = [
    {
      field: "id",
      headerName: "Order No",
      width: 113,
      cellClassName: "sticky-column",
      headerClassName: classes.headerId,
    },
    {
      field: "PRODUCT_NAME",
      headerName: "Product Name",
      width: 185,
      editable: false,
      align: "left",
      headerClassName: classes.headerProductName,
    },
    {
      field: "SUPPLIER",
      headerName: "Supplier",
      width: 180,
      editable: false,
      align: "left",
      headerClassName: classes.headerSupplier,
    },
    {
      field: "DATE",
      headerName: "On This Date",
      width: 146,
      editable: false,
      align: "left",
      headerClassName: classes.headerDate,
    },
    {
      field: "PRICE",
      headerName: "Price",
      width: 93,
      editable: false,
      align: "left",
      headerClassName: classes.headerPrice,
    },
  ];

  const image2 = API_URL + `/media/${image}`;
  const onImageErr = (e) => {
    e.target.src = po_img;
  };

  const copyIcon = () => (
    <svg
      style={{ cursor: "pointer" }}
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 0V2H17.0066C17.5552 2 18 2.44495 18 2.9934V19.0066C18 19.5552 17.5551 20 17.0066 20H0.9934C0.44476 20 0 19.5551 0 19.0066V2.9934C0 2.44476 0.44495 2 0.9934 2H4V0H14ZM4 4H2V18H16V4H14V6H4V4ZM6 14V16H4V14H6ZM6 11V13H4V11H6ZM6 8V10H4V8H6ZM12 2H6V4H12V2Z"
        fill="#635BFF"
      />
    </svg>
  );
  console.log(prod_id);

  function convertUnixTimestampToDate(unixTimestamp) {
    const date = new Date(unixTimestamp * 1000);
    const formattedDate = `${String(date.getDate()).padStart(2, "0")}-${String(
      date.getMonth() + 1
    ).padStart(2, "0")}-${date.getFullYear()}`;
    return formattedDate;
  }
  useEffect(() => {
    const payload = {
      CLIENT_SUPPLIER_ID: client_supp_id,
      P_ID: prod_id,
      CURRENT_PAGE: page + 1,
      ITEM_PER_PAGE: pageSize,
    };
    makeApiCall({
      mod: "Supplier",
      action: "view-purchase-history",
      payload: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        console.log("PAYLOAD PH", payload);
        console.log("view-purchase-history fetched", response);
        const orderDetails = response.XscData.ORDER_DETAILS;
        if (Array.isArray(orderDetails)) {
          const mappedRows = orderDetails.map((orderDetail) => ({
            id: orderDetail.ORDER_NO || "N/A",
            ORDER_NO: orderDetail.ORDER_NO ? orderDetail.ORDER_NO : "N/A",
            PRODUCT_NAME: orderDetail.PRODUCT_NAME
              ? orderDetail.PRODUCT_NAME
              : "N/A",
            SUPPLIER: orderDetail.SUPPLIER ? orderDetail.SUPPLIER : "N/A",
            PRICE: `${orderDetail.CURRENCY_SYMBOL} ${orderDetail.PRICE}` || "0",
            DATE: convertUnixTimestampToDate(orderDetail.ON_THIS_DATE) || "N/A",
          }));
          setTotal(response.XscData.TOTAL_PO);
          setTotalQty(response.XscData.QTY_IN_INVENTORY);
          setSuppProdId(response.XscData.SUPP_PROD_ID);
          setManexProdId(response.XscData.MNX_PROD_ID);
          setPerPrice(response.XscData.PER_PRICE);
          setImage(response.XscData.PRODUCT_IMAGE);
          setRows(mappedRows);
        } else {
          console.error(
            "View Purchase History data is not an array or is missing"
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [page, pageSize, prod_id, props.show]);

  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="filter_text"
          >
            View Purchase History
          </Modal.Title>
        </Modal.Header>

        <div className="historyContent">
          <div className="div">
            <img src={image2} className="productImage" onError={onImageErr} />
            <hr className="verticalBreaker" />

            <div className="productId">
              <h2 id="id">{suppProdId ? suppProdId : "N/A"}</h2>
              <p id="text">Sup.Prod.ID</p>
            </div>
            <hr className="verticalBreaker" />

            <div className="productId">
              <h2 id="id">{manexProdId ? manexProdId : "N/A"}</h2>
              <p id="text">Mnx.Prod.ID</p>
            </div>
            <hr className="verticalBreaker" />

            <div className="productId">
              <h2 id="id">{perPrice ? `¥${perPrice}` : 0}</h2>
              <p id="text">Per Piece</p>
            </div>

            <hr className="verticalBreaker" />

            <div className="productId">
              <h2 id="id">{totalQty ? totalQty : 0}</h2>
              <p id="text">Qty. in Inventory</p>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              height: 600,
              width: 736,
              marginBottom: "80px",
              alignSelf: "center",
              alignItems: "center",
              justifyContent: "center",
            }}
            className={classes.modalContent}
          >
            {/* {rows.length > 0 ? ( */}
            <DataGrid
              rows={rows}
              columns={columns}
              pageSize={pageSize}
              pagination
              paginationMode="server"
              rowCount={total}
              rowsPerPageOptions={
                rows.length < 50
                  ? [10, 20, 50] // Default options
                  : rows.length > 20
                  ? [10, 20, rows.length] // Adjust if less than 50
                  : rows.length > 10
                  ? [10, rows.length] // Adjust if less than 20
                  : [rows.length] // Show all rows if less than 10
              }
              onPageChange={handlepagechange}
              onPageSizeChange={handlepagesizechange}
              loading={loading}
              disableSelectionOnClick
              classes={{
                columnHeader: classes.header,
                row: classes.row,
              }}
              className={classes.dataGrid}
            />
            {/* ) */}
            {/* :
            // ( <div>No Purchase History available</div>) */}
            {/* } */}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default PurchaseHistory;
