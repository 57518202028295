import React from 'react'
import CustomerInfo from './CustomerInfo'
import OrderDashboardLayout from './Orders/OrderDashboardLayout.js'
import CustomerReportPage from './CustomerReportPage.js'
import CustomerPriceHistory from './CustomerPriceHistory.js'

const CustomerDashboard = ({ activeTab, onTabChange }) => {
  return (
    <div>
      <div>{activeTab === "Overview" && <CustomerInfo />}</div>
      <div>{activeTab === "Orders" && <OrderDashboardLayout />}</div>
      <div>{activeTab === "Report" && <CustomerReportPage />}</div>
      <div>{activeTab === "PriceHistory" && <CustomerPriceHistory />}</div>
    </div>
  )
}

export default CustomerDashboard