import React, { useState } from "react";

function Filter(props) {

  return (
    <div>
      <div id="filter_bg">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M10 18H14V16H10V18ZM3 6V8H21V6H3ZM6 13H18V11H6V13Z"
            fill="var(--onSurface)"
          />
        </svg>
        Filter {props.notify>0 && <div id="selected_fil_counts">{props.notify}</div>}
      </div>
    </div>
  );
}

export default Filter;
