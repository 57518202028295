import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import po_img from "../../../assets/po_img.png";
import { makeStyles } from "@material-ui/styles";
import "../../../Styles/UploadInvoice.css";
import { useNavigate, useSearchParams } from "react-router-dom";
import { makeApiCall } from "../../../StaticComponents/API";

import num2words from "num2words";

const useStyles = makeStyles({
  subheadingRow: {
    textAlign: "center",
    fontWeight: "bold",
    minHeight: "32px !important",
    maxHeight: "32px !important",
    "& .MuiDataGrid-cell": {
      border: "none !important",
      lineHeight: "32px !important",
      minHeight: "32px !important",
      maxHeight: "32px !important",
    },
  },

  subheadingCell: {
    width: "100%",
    border: "0.5px solid var(--outlineVariant) !important",
    lineHeight: "32px !important",
    minHeight: "32px !important",
    maxHeight: "32px !important",
  },

  padding: { padding: "0px !important" },
});

function convertUnixTimestampToDate(unixTimestamp) {
  const date = new Date(unixTimestamp * 1000);
  const formattedDate = `${String(date.getDate()).padStart(2, "0")}-${String(
    date.getMonth() + 1
  ).padStart(2, "0")}-${date.getFullYear()}`;
  return formattedDate;
}


function ViewSupplierInvoice() {
  const [optionShow, setOptionShow] = useState(false);
  const [optionPosition, setOptionPosition] = useState({ top: 0, left: 0 });

  const classes = useStyles();

  const columns = [
    {
      field: "MNX_ID",
      headerName: "Mnx. Item ID",
      width: 200,
      align: "left",
    },
    {
      field: "SUP_ITEM_ID",
      headerName: "Sup. Item ID",
      width: 200,
      align: "left",
    },
    {
      field: "DESCRIPTION",
      headerName: "Description Goods",
      width: 250,
      align: "left",
    },
    {
      field: "TTL_QTY",
      headerName: "TTL QTY",
      width: 200,
      align: "left",
    },
    {
      field: "RMB",
      headerName: "RMB",
      width: 200,
      align: "left",
      cellClassName: "po_input",
    },
    {
      field: "TTL_RMB",
      headerName: "TTL RMB",
      width: 200,
      align: "left",
    },
  ];

  const initialRows = [];

  const [rows, setRows] = useState(initialRows);
  const navigate = useNavigate();
  const getRowClassName = (params) => {
    return params.row.id_desc === "Total" ? classes.subheadingRow : "";
  };

  const handleBack = () => {
    navigate("/suppliers/supplier-info");
  };
  const getCellClassName = (params) => {
    return params.row.id_desc === "Total" ? classes.padding : "";
  };

  const [invoiceDetail, setInvoiceDetail] = useState({
    xscdata: "",
    delieryDetails: "",
  });

  const convertAmountToText = (amount) => {
    if (typeof amount !== "number") {
      return "AMOUNT NOT AVAILABLE";
    }

    const [whole, fraction] = amount.toFixed(2).split("."); // Split into dollars and cents
    const wholeText = num2words(whole).toUpperCase(); // Convert whole number to text
    const fractionText = num2words(fraction).toUpperCase(); // Convert cents to text

    return `${wholeText} DOLLARS AND ${fractionText} CENTS`;
  };

  const CLIENT_SUPPLIER_ID = localStorage.getItem("CLIENT_SUPPLIER_ID");

  let token = localStorage.getItem("token");

  let [searchParams] = useSearchParams();

  const INVOICE_ID = searchParams.get("id");

  const getData = () => {
    const payload = {
      INVOICE_ID: INVOICE_ID,
      CLIENT_SUPPLIER_ID: CLIENT_SUPPLIER_ID,
    };
    makeApiCall({
      mod: "SupplierInvoice",
      action: "view_supp_invoice",
      payload: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((res) => {
      console.log(res, "view_supp_invoice");
      const invoiceItems = res.XscData.INV_ITEMS;
      if (Array.isArray(invoiceItems)) {
        const mappedRows = invoiceItems.map((itemcounts) => ({
          id: itemcounts.CLIENT_PROD_ID,
          MNX_ID: itemcounts.CLIENT_PROD_ID,
          SUP_ITEM_ID: itemcounts.SUPP_PROD_ID,
          DESCRIPTION: itemcounts.DESCRIPTION ||"N/A",
          TTL_QTY: itemcounts.TOTAL_QTY || 0,
          RMB: `${itemcounts.CURRENCY_SYMBOL}${ itemcounts.PRICE_PER_ITEM ||"0"}`,
          TTL_RMB: `${itemcounts.CURRENCY_SYMBOL}${ itemcounts.TOTAL_COST || "0"}`,
        }));
        setRows(mappedRows);
        setInvoiceDetail({
          xscdata: res.XscData.SUMMARY,
          delieryDetails: res.XscData.ADDITIONAL_DETAILS,
        });
      } else {
        console.error("Supplier Invoice has no Data");
      }
    });
  };

  useEffect(() => {
    getData();
  }, []);


  return (
    <>
      <div
        style={{ minHeight: "100vh", position: "relative" }}
        className="container"
      >
        <div
          id="suppliers_topbar_bg"
          className=""
          style={{ position: "relative" }}
        >
          <div className="d-flex align-items-center" style={{ gap: "8px" }}>
            <div id="info">
              <h2 id="header_name" style={{ margin: 0 }}>
                <svg
                  style={{ cursor: "pointer" }}
                  onClick={handleBack}
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.82843 11.0009H20V13.0009H7.82843L13.1924 18.3648L11.7782 19.779L4 12.0009L11.7782 4.22266L13.1924 5.63687L7.82843 11.0009Z"
                    fill="var(--onSurface)"
                  />
                  <path
                    d="M7.82843 11.0009H20V13.0009H7.82843L13.1924 18.3648L11.7782 19.779L4 12.0009L11.7782 4.22266L13.1924 5.63687L7.82843 11.0009Z"
                    fill="var(--onSurface)"
                    fill-opacity="0.2"
                  />
                  <path
                    d="M7.82843 11.0009H20V13.0009H7.82843L13.1924 18.3648L11.7782 19.779L4 12.0009L11.7782 4.22266L13.1924 5.63687L7.82843 11.0009Z"
                    fill="var(--onSurface)"
                    fill-opacity="0.2"
                  />
                  <path
                    d="M7.82843 11.0009H20V13.0009H7.82843L13.1924 18.3648L11.7782 19.779L4 12.0009L11.7782 4.22266L13.1924 5.63687L7.82843 11.0009Z"
                    fill="var(--onSurface)"
                    fill-opacity="0.2"
                  />
                  <path
                    d="M7.82843 11.0009H20V13.0009H7.82843L13.1924 18.3648L11.7782 19.779L4 12.0009L11.7782 4.22266L13.1924 5.63687L7.82843 11.0009Z"
                    fill="var(--onSurface)"
                    fill-opacity="0.2"
                  />
                </svg>{" "}
                View Invoice
              </h2>
              <p id="header_text" style={{ marginLeft: 32 }}>
                {invoiceDetail.xscdata.SUPP_INV_NUM || "N/A"}
              </p>
            </div>
            {/* <div id="dashboard_header">Inventory</div> */}
          </div>
        </div>
        <div id="create_po_bg">
          <div className="container"></div>
          <div
            className=""
            style={{
              maxHeight: "fit-content",
              width: "100%",
              marginTop: "60px",
            }}
          >
            <DataGrid
              rows={rows}
              columns={columns}
              hideFooterPagination={true}
              hideFooter={true}
              autoHeight
              rowHeight={80}
              showCellRightBorder
              getRowClassName={getRowClassName}
              getCellClassName={getCellClassName}
            />
          </div>
          {optionShow && (
            <div
              className="options-dropdown ellipsisOption"
              style={{
                top: optionPosition.top,
                left: optionPosition.left,
              }}
            >
              <div
                className="option"
                style={{
                  textDecoration: "none",
                  color: "#535260",
                  cursor: "pointer",
                }}
              >
                Pay Supplier
              </div>
            </div>
          )}
          <div
            className=""
            style={{
              borderBottom: "0.5px solid var(--outlineVariant)",
            }}
          >
            <div className="row container">
              <div className="col-6"></div>
              <div className="col-6">
                <div
                  className="d-flex flex-column"
                  style={{
                    gap: 40,
                    borderBottom: "0.5px solid var(--outlineVariant)",
                    marginTop: 100,
                  }}
                >
                  <div className="d-flex flex-row" style={{ gap: 151 }}>
                    <h2 id="total_cost_text">
                      Total Cost in{" "}
                      {invoiceDetail.xscdata.OPERATING_CURRENCY || "RMB"}
                    </h2>
                    <p id="total_cost">
                      {invoiceDetail.xscdata.OPERATING_CURRENCY_SYMBOL || ""}
                      {invoiceDetail.xscdata.TOTAL_COST || "N/A"}
                    </p>
                  </div>
                  <div className="d-flex flex-row" style={{ gap: 151 }}>
                    <h2 id="total_cost_text">
                      Total Cost in{" "}
                      {invoiceDetail.xscdata?.INVOICE_CURRENCY || "USD"} *{" "}
                      {invoiceDetail.xscdata.INVOICE_CURRENCY_RATE || "0.14"}
                    </h2>
                    <p id="total_cost">
                      {invoiceDetail?.xscdata?.INVOICE_CURRENCY_SYMBOL
                        ? invoiceDetail.xscdata.INVOICE_CURRENCY_SYMBOL
                        : ""}

                      {invoiceDetail?.xscdata?.TOTAL_COST &&
                      invoiceDetail?.xscdata?.INVOICE_CURRENCY_RATE
                        ? (
                            invoiceDetail.xscdata.TOTAL_COST *
                            invoiceDetail.xscdata.INVOICE_CURRENCY_RATE
                          ).toFixed(2)
                        : "N/A"}
                    </p>
                  </div>
                  <div className="d-flex flex-row" style={{ gap: 151 }} F>
                    <h2 id="total_cost_text">
                      Agent Commission (
                      {invoiceDetail.xscdata.AGENTS_COMMISION_PERCENTAGE + "%"})
                    </h2>
                    <p id="total_cost">
                      {invoiceDetail.xscdata.INVOICE_CURRENCY_SYMBOL || " "}
                      {(invoiceDetail.xscdata.AGENTS_COMMISION_PERCENTAGE /
                        100) *
                        invoiceDetail.xscdata.TOTAL_COST || "N/A"}
                    </p>
                  </div>
                  <div
                    className="d-flex flex-row"
                    style={{ gap: 119, marginBottom: 28 }}
                  >
                    <h2 id="total_cost_text" style={{ width: 264 }}>
                      Inland Transportation:{" "}
                      {invoiceDetail.xscdata.INLAND_TRANS || "N/A"}
                    </h2>
                    <p id="total_cost">
                      {invoiceDetail.xscdata.INVOICE_CURRENCY_SYMBOL || ""}
                      {invoiceDetail.xscdata.SHIPPING_COST || "N/A"}
                    </p>
                  </div>
                </div>

                <div
                  className="d-flex flex-column"
                  style={{
                    gap: 20,
                    margin: "20px 72px 80px 0px",
                  }}
                >
                  <div className="d-flex flex-row" style={{ gap: 151 }}>
                    <h2 id="total_cost_text">Total Value</h2>
                    <h2 id="total_cost" style={{ fontWeight: 600 }}>
                      {invoiceDetail.xscdata.INVOICE_CURRENCY_SYMBOL || ""}
                      {invoiceDetail.xscdata.TOTAL_INVOICE_VALUE || "N/A"}
                    </h2>
                  </div>
                  <div
                    className="d-flex flex-row align-items-center"
                    style={{ gap: 13 }}
                  >
                    <h2 id="total_cost_text" style={{ width: 524 }}>
                      SAY “{invoiceDetail.xscdata.OPERATING_CURRENCY || " "}{" "}
                      {convertAmountToText(
                        invoiceDetail.xscdata.TOTAL_INVOICE_VALUE || "N/A"
                      )}
                      ” ONLY
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="d-flex flex-column gap-4"
            style={{
              marginTop: 44,
            }}
          >
            <div className="d-flex flex-row align-items-center gap-2 ">
              <div
                style={{
                  width: 8,
                  height: 44,
                  background: "var(--Schemes-Primary-Container, #635BFF)",
                }}
              ></div>
              <h2 id="shipping_details">Delivery Info</h2>
            </div>
            <div>
              Delivery Date: {convertUnixTimestampToDate(invoiceDetail.delieryDetails.DELIVERY_DATE)}
            </div>

            <div>
              Vessel Name: {invoiceDetail.delieryDetails.VESSEL_NAME || "N/A"}
            </div>
            <div>
              Sailing Date: {convertUnixTimestampToDate(invoiceDetail.delieryDetails.SAILING_DATE)}
            </div>
            <div>
              Payment Type: {invoiceDetail.delieryDetails.PAYMENT_TYPE || "N/A"}
            </div>
            <div></div>
          </div>

          <div
            className="d-flex flex-column gap-4"
            style={{
              marginTop: 44,
            }}
          >
            <div className="d-flex flex-row align-items-center gap-2 ">
              <div
                style={{
                  width: 8,
                  height: 44,
                  background: "var(--Schemes-Primary-Container, #635BFF)",
                }}
              ></div>
              <h2 id="shipping_details">Supplier Invoice File</h2>
            </div>
            <div
              className="d-flex align-items-center"
              style={{ gap: "30px", marginBottom: 48 }}
            >
              <div className="d-flex justify-content-center gap-2">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M5 4H15V8H19V20H5V4ZM3.9985 2C3.44749 2 3 2.44405 3 2.9918V21.0082C3 21.5447 3.44476 22 3.9934 22H20.0066C20.5551 22 21 21.5489 21 20.9925L20.9997 7L16 2H3.9985ZM10.4999 7.5C10.4999 9.07749 10.0442 10.9373 9.27493 12.6534C8.50287 14.3757 7.46143 15.8502 6.37524 16.7191L7.55464 18.3321C10.4821 16.3804 13.7233 15.0421 16.8585 15.49L17.3162 13.5513C14.6435 12.6604 12.4999 9.98994 12.4999 7.5H10.4999ZM11.0999 13.4716C11.3673 12.8752 11.6042 12.2563 11.8037 11.6285C12.2753 12.3531 12.8553 13.0182 13.5101 13.5953C12.5283 13.7711 11.5665 14.0596 10.6352 14.4276C10.7999 14.1143 10.9551 13.7948 11.0999 13.4716Z"
                      fill="#1B1B24"
                    />
                    <path
                      d="M5 4H15V8H19V20H5V4ZM3.9985 2C3.44749 2 3 2.44405 3 2.9918V21.0082C3 21.5447 3.44476 22 3.9934 22H20.0066C20.5551 22 21 21.5489 21 20.9925L20.9997 7L16 2H3.9985ZM10.4999 7.5C10.4999 9.07749 10.0442 10.9373 9.27493 12.6534C8.50287 14.3757 7.46143 15.8502 6.37524 16.7191L7.55464 18.3321C10.4821 16.3804 13.7233 15.0421 16.8585 15.49L17.3162 13.5513C14.6435 12.6604 12.4999 9.98994 12.4999 7.5H10.4999ZM11.0999 13.4716C11.3673 12.8752 11.6042 12.2563 11.8037 11.6285C12.2753 12.3531 12.8553 13.0182 13.5101 13.5953C12.5283 13.7711 11.5665 14.0596 10.6352 14.4276C10.7999 14.1143 10.9551 13.7948 11.0999 13.4716Z"
                      fill="black"
                      fill-opacity="0.2"
                    />
                    <path
                      d="M5 4H15V8H19V20H5V4ZM3.9985 2C3.44749 2 3 2.44405 3 2.9918V21.0082C3 21.5447 3.44476 22 3.9934 22H20.0066C20.5551 22 21 21.5489 21 20.9925L20.9997 7L16 2H3.9985ZM10.4999 7.5C10.4999 9.07749 10.0442 10.9373 9.27493 12.6534C8.50287 14.3757 7.46143 15.8502 6.37524 16.7191L7.55464 18.3321C10.4821 16.3804 13.7233 15.0421 16.8585 15.49L17.3162 13.5513C14.6435 12.6604 12.4999 9.98994 12.4999 7.5H10.4999ZM11.0999 13.4716C11.3673 12.8752 11.6042 12.2563 11.8037 11.6285C12.2753 12.3531 12.8553 13.0182 13.5101 13.5953C12.5283 13.7711 11.5665 14.0596 10.6352 14.4276C10.7999 14.1143 10.9551 13.7948 11.0999 13.4716Z"
                      fill="black"
                      fill-opacity="0.2"
                    />
                    <path
                      d="M5 4H15V8H19V20H5V4ZM3.9985 2C3.44749 2 3 2.44405 3 2.9918V21.0082C3 21.5447 3.44476 22 3.9934 22H20.0066C20.5551 22 21 21.5489 21 20.9925L20.9997 7L16 2H3.9985ZM10.4999 7.5C10.4999 9.07749 10.0442 10.9373 9.27493 12.6534C8.50287 14.3757 7.46143 15.8502 6.37524 16.7191L7.55464 18.3321C10.4821 16.3804 13.7233 15.0421 16.8585 15.49L17.3162 13.5513C14.6435 12.6604 12.4999 9.98994 12.4999 7.5H10.4999ZM11.0999 13.4716C11.3673 12.8752 11.6042 12.2563 11.8037 11.6285C12.2753 12.3531 12.8553 13.0182 13.5101 13.5953C12.5283 13.7711 11.5665 14.0596 10.6352 14.4276C10.7999 14.1143 10.9551 13.7948 11.0999 13.4716Z"
                      fill="black"
                      fill-opacity="0.2"
                    />
                    <path
                      d="M5 4H15V8H19V20H5V4ZM3.9985 2C3.44749 2 3 2.44405 3 2.9918V21.0082C3 21.5447 3.44476 22 3.9934 22H20.0066C20.5551 22 21 21.5489 21 20.9925L20.9997 7L16 2H3.9985ZM10.4999 7.5C10.4999 9.07749 10.0442 10.9373 9.27493 12.6534C8.50287 14.3757 7.46143 15.8502 6.37524 16.7191L7.55464 18.3321C10.4821 16.3804 13.7233 15.0421 16.8585 15.49L17.3162 13.5513C14.6435 12.6604 12.4999 9.98994 12.4999 7.5H10.4999ZM11.0999 13.4716C11.3673 12.8752 11.6042 12.2563 11.8037 11.6285C12.2753 12.3531 12.8553 13.0182 13.5101 13.5953C12.5283 13.7711 11.5665 14.0596 10.6352 14.4276C10.7999 14.1143 10.9551 13.7948 11.0999 13.4716Z"
                      fill="black"
                      fill-opacity="0.2"
                    />
                  </svg>
                </div>
                <div>{invoiceDetail.xscdata.SUPP_INV_MM || "N/A"}</div>
              </div>
              {invoiceDetail.xscdata.SUPP_INV_MM ? (
                <div>
                  {" "}
                  <div
                    className="d-flex align-items-center justify-content-right "
                    style={{ gap: "12px" }}
                  >
                    <a
                      href={
                        process.env.REACT_APP_API_URL +
                        "media/" +
                        invoiceDetail.xscdata.SUPP_INV_MM
                      }
                      target="_blank"
                      className="text-decoration-none"
                    >
                      <div id="preview_packaging">
                        View{" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M16.0052 9.41421L7.39858 18.0208L5.98438 16.6066L14.591 8H7.00519V6H18.0052V17H16.0052V9.41421Z"
                            fill="#635BFF"
                          />
                        </svg>
                      </div>
                    </a>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
      {/* <div id="po_footer" className="pi_footer">
        <div id="add_supplier_buttons" className="container">
          {" "}
          <a href="/customers/customer-info" className="text-decoration-none">
            {" "}
            <div id="cancel">Cancel</div>
          </a>
          <div
            className="d-flex align-items-center justify-content-right "
            style={{ gap: "12px" }}
          >
            <a href="/suppliers/supplier-info" className="text-decoration-none">
              <div id="submitsupplier">Confirm Invoice</div>
            </a>
          </div>
        </div>
      </div> */}
    </>
  );
}

export default ViewSupplierInvoice;
