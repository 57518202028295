import React, { useEffect, useState } from "react";
import SearchBar from "../../StaticComponents/SearchBar";
import { DataGrid } from "@mui/x-data-grid";
import CartonDetail from "./CartonDetail";
import { makeApiCall } from "../../StaticComponents/API";
import { useNavigate } from "react-router-dom";
import DataGridSkeleton from "../../StaticComponents/SkeletonLoading";
import noDataImage from "../../assets/Frame.svg"
function CartonDashboard({ id }) {
  const [pageSize, setPageSize] = React.useState(10); // Default page size
  const [page, setpage] = useState(0);
  const [modalShow, setModalShow] = React.useState(false);
  const [total, settotal] = React.useState(0);
  const [loading, setLoading] = useState(false);
  const [cartoonList, setCartoonList] = React.useState([]);
  const [selectedCartonId, setSelectedCartonId] = React.useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [noResults, setNoResults] = useState(false);

  const navigate = useNavigate();

  const lengthOptions = [
    { value: "MTR", label: "m" },
    { value: "CM", label: "cm" },
    { value: "IN", label: "in" },
    { value: "FEET", label: "feet" },
    { value: "MM", label: "mm" },
  ];

  const weightOptions = [
    { value: "GM", label: "g" },
    { value: "KG", label: "Kg" },
    { value: "LBS", label: "lbs" },
  ];

  const volumeOptions = [
    { value: "CB_CM", label: "cm³" },
    { value: "CB_MTR", label: "m³" },
    { value: "CB_FEET", label: "feet³" },
    { value: "CB_MM", label: "mm³" },
    { value: "CB_IN", label: "in³" },
  ];

  const getLabelFromValue = (value, options) => {
    const option = options.find((option) => option.value === value);
    return option ? option.label : "NA";
  };

  const handlepagechange = (newpage) => {
    setpage(newpage);
  };
  const handlepagesizechange = (num) => {
    setPageSize(num);
    setpage(0);
  };
  const handleSearch = (query) => {
    setSearchQuery(query);
    setpage(0); // Reset page to 0 on search query change
  };
  console.log("page pagesize", page, pageSize);
  const payload = {
    APP_CLIENT_ID: localStorage.getItem("CLIENT_ID"),
    CURRENT_PAGE: page + 1,
    ITEM_PER_PAGE: pageSize,
    SEARCH_KEYWORD: searchQuery,
  };

  let token = localStorage.getItem("token");

  const columns = [
    {
      field: "ALIAS",
      headerName: "Alias",
      width: 180,
      height: 80,
      cellClassName: "sticky-column",
    },
    {
      field: "LENGTH",
      headerName: "Length",
      width: 150,
      editable: false,
      align: "left",
      height: 80,
      renderCell: (params) => params.value || "NA",
    },
    {
      field: "WIDTH",
      headerName: "Width",
      width: 150,
      editable: false,
      align: "left",
      height: 80,
      renderCell: (params) => params.value || "NA",
    },

    {
      field: "HEIGHT",
      headerName: "Height",
      type: "number",
      width: 150,
      editable: false,
      align: "left",
      height: 80,
      renderCell: (params) => params.value || "NA",
    },
    {
      field: "CAPACITY",
      headerName: "Capacity",
      width: 150,
      editable: false,
      align: "left",
      height: 80,
      renderCell: (params) => params.value || "NA",
    },
    // {
    //   field: "TARE_WEIGHT",
    //   headerName: "Tare Weight",
    //   width: 150,
    //   editable: false,
    //   align: "left",
    //   height: 80,
    // },
    {
      field: "MAX_CARGO_WEIGHT",
      headerName: "Max Cargo Weight",
      width: 190,
      height: 80,
      editable: false,
      align: "left",
      //   headerClassName: "more_svg",
      renderCell: (params) => params.value || "NA",
    },
    {
      field: "EDIT",
      headerName: "",
      width: 50,
      height: 80,
      editable: false,
      align: "center",
      headerClassName: "more_svg",
      renderCell: (params) => createEditIcon(params.row.id),
    },
  ];

  const createEditIcon = (id) => (
    <a href={`/shipping/edit-carton?id=${id}`}>
      <svg
        onClick={(e) => e.stopPropagation()}
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M5 18.89H6.41421L15.7279 9.57628L14.3137 8.16207L5 17.4758V18.89ZM21 20.89H3V16.6473L16.435 3.21232C16.8256 2.8218 17.4587 2.8218 17.8492 3.21232L20.6777 6.04075C21.0682 6.43127 21.0682 7.06444 20.6777 7.45496L9.24264 18.89H21V20.89ZM15.7279 6.74786L17.1421 8.16207L18.5563 6.74786L17.1421 5.33364L15.7279 6.74786Z"
          fill="var(--onSurfaceVariant)"
        />
      </svg>
    </a>
  );

  const initialRows = [];

  const [rows, setRows] = React.useState(initialRows);

  //   const handleFav = (id) => {
  //     setRows((prevRows) =>
  //       prevRows.map((row) =>
  //         row.id === id ? { ...row, FAVOURITE: !row.FAVOURITE } : row
  //       )
  //     );
  //   };

  const rowsWithIcons = rows.map((row) => ({
    ...row,
    // FAVOURITE: createFavIcon(row.FAVOURITE, () => handleFav(row.id)),
    EDIT: createEditIcon(row.id),
  }));

  useEffect(() => {

    makeApiCall({
      mod: "Shipping",
      action: "get-ctn-list",
      payload: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        console.log("request", payload);
        console.log("cartoon list fetched", response);

        const cartoon = response.XscData.CTN_LIST;

        if (searchQuery && (!Array.isArray(cartoon) || cartoon.length === 0)) {
          setRows([]);
          setNoResults(true); // Show "No data found" image
        } else if (Array.isArray(cartoon) && cartoon.length > 0) {
          const mappedRows = cartoon.map((cartoons) => ({
            id: cartoons.ID,
            ALIAS: cartoons.ALIAS_NAME,
            LENGTH: cartoons.LENGTH
              ? `${cartoons.LENGTH} ${getLabelFromValue(
                  cartoons.SIZE_UNIT,
                  lengthOptions
                )}`
              : "NA",
            WIDTH: cartoons.WIDTH
              ? `${cartoons.WIDTH} ${getLabelFromValue(
                  cartoons.SIZE_UNIT,
                  lengthOptions
                )}`
              : "NA",
            HEIGHT: cartoons.HEIGHT
              ? `${cartoons.HEIGHT} ${getLabelFromValue(
                  cartoons.SIZE_UNIT,
                  lengthOptions
                )}`
              : "NA",
            CAPACITY: cartoons.CAPACITY
              ? `${cartoons.CAPACITY} ${getLabelFromValue(
                  cartoons.CAPACITY_UNIT,
                  volumeOptions
                )}`
              : "NA",
            MAX_CARGO_WEIGHT: cartoons.MAX_CARTON_WEIGHT
              ? `${cartoons.MAX_CARTON_WEIGHT} ${getLabelFromValue(
                  cartoons.MAX_CARTON_WEIGHT_UNIT,
                  weightOptions
                )}`
              : "NA",
            // IS_SUPP_FAV: createFavIcon(cartoons.IS_SUPP_FAV, (e) =>
            //   handleFav(e, cartoons.CLIENT_SUPPLIER_ID)
            // ),
            EDIT: createEditIcon(cartoons.ID),
          }));
          console.log(cartoon.ID);
          settotal(response.XscData.TOTAL_CTN);
          setRows(mappedRows); // Set rows after mapping
          setNoResults(mappedRows.length === 0);
        } else {
          console.error("Suppliers data is not an array or is missing");
          setNoResults(true);
        }
      })
      .catch((error) => {
        console.log(error);
        setRows([]); 
        setNoResults(true);
      })
      .finally(() => {
        setLoading(false); // Stop loading after API call completes
      });
  }, [id, token, searchQuery, page, pageSize]);
  console.log();

  const cartoonEditIcon = (id) => (
    <a href={`/cartoonList/edit-cartoon?id=${id}`}></a>
  );
  const handleRowClick = (params) => {
    setSelectedCartonId(params.row.id);
    setModalShow(true);
  };
  {
    console.log(noResults, "nor");
  }
  function CustomNoRowsOverlay() {
    if (loading) {
        return null; // Don't show overlay if loading
    }
    if (noResults) {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                }}
            >
                <img
                    src={noDataImage}
                    alt="No data found"
                    style={{ width: "200px", marginRight: "10px" }}
                />
                <div>No Data Found</div>
            </div>
        );
    }
    return null; // Return null if there are results
}


  return (
    <div>
      <div className="container">
        <div
          className="d-flex justify-content-between"
          id="supp_filters"
          style={{ marginTop: "44px" }}
        >
          <div className="d-flex">
            <div>
              <SearchBar onSearch={handleSearch} />
            </div>
          </div>
          <a href="/shipping/add-carton">
            <div id="create_po_button">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M11 11V5H13V11H19V13H13V19H11V13H5V11H11Z"
                  fill="#F7F7F7"
                />
              </svg>
              Add New Carton
            </div>
          </a>
        </div>
        <div
          style={{
            height: 600,
            width: "100%",
            marginBottom: "80px",
            marginTop: "20px",
            cursor: "pointer",
          }}
        >{loading ? (
          <DataGridSkeleton
            columns={columns}
            rowCount={pageSize}
            rows={rowsWithIcons}
          />
        ) : rows.length === 0 ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
            }}
          >
            <img
              src={noDataImage}
              alt="No data"
              style={{ width: "150px", height: "auto", marginBottom: "20px" }}
            />
            <p>No result found in the list</p>
          </div>
        ) : (
          <DataGrid
            onRowClick={handleRowClick}
            rows={rowsWithIcons}
            columns={columns}
            pageSize={pageSize}
            getRowId={(row) => row.id}
            pagination
            paginationMode="server"
            rowCount={total}
            rowsPerPageOptions={
              // [10, 20, 50]
              rowsWithIcons.length < 50
                ? [10, 20, 50] // Default options
                : rowsWithIcons.length > 20
                ? [10, 20, rowsWithIcons.length] // Adjust if less than 50
                : rowsWithIcons.length > 10
                ? [10, rowsWithIcons.length] // Adjust if less than 20
                : [rowsWithIcons.length] // Show all rows if less than 10
            }
            onPageChange={handlepagechange}
            onPageSizeChange={handlepagesizechange}
            loading={loading}
            disableSelectionOnClick
            components={{
              NoRowsOverlay: CustomNoRowsOverlay,
            }}
          />
        )}
          <CartonDetail
            show={modalShow}
            onHide={() => setModalShow(false)}
            id={selectedCartonId}
          />
        </div>
      </div>
    </div>
  );
}
export default CartonDashboard;
