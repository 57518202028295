import React, { useEffect, useRef, useState } from "react";
import SearchBar from "../../../StaticComponents/SearchBar";
import Filter from "../../../StaticComponents/Filter";
import SupplierFilter from "../SupplierFilter";
import "../../../Styles/PurchaseOrder.css";
import { DataGrid } from "@mui/x-data-grid";
import { Dropdown } from "react-bootstrap";
import OrderFilter from "./OrderFilter";
import { useSearchParams } from "react-router-dom";
import { makeApiCall } from "../../../StaticComponents/API";
import TimestampToDate from "../../../StaticComponents/DateFormat";
import { LocalTaxi } from "@mui/icons-material";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import nodataimage from "../../../assets/no-data-file.png";

function PurchaseOrder() {
  const APP_CLIENT_ID = localStorage.getItem("CLIENT_ID");
  const CLIENT_SUPPLIER_ID = localStorage.getItem("CLIENT_SUPPLIER_ID");
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const [modalShow, setModalShow] = useState(false);
  const [page, setpage] = useState(0);
  const handlepagechange = (newpage) => {
    setpage(newpage);
  };
  const API_URL = process.env.REACT_APP_API_URL;
  const [pageSize, setPageSize] = React.useState(10); // Default page size
  const [searchQuery, setSearchQuery] = useState("");
  const [optionShow, setOptionShow] = useState(false);
  const [optionPosition, setOptionPosition] = useState({ top: 0, left: 0 });
  const [currentStatus, setCurrentStatus] = useState("");
  const ellipsisRef = useRef(null);
  const [orderID, setorderID] = useState();
  const [notify, setnotify] = useState(0);
  const [rowCount, setrowCount] = useState(0);
  const [filter,setfilters]=useState([])
  const onfilter=(value)=>{
    setfilters(value)
  }
  const onNotify = (value) => {
    setnotify(value);
  };
  const handleEllipsisClick = (e, status, orderID, orderNo) => {
    const rect = e.currentTarget.getBoundingClientRect();
    setOptionPosition({
      top: rect.bottom + window.scrollY,
      left: rect.left + window.scrollX,
    });
    console.log(status, "status");
    setOptionShow(true);
    setCurrentStatus(status); // Set the current status
    setorderID(orderID);
    localStorage.setItem("SUPP_PO_ID", orderID);
    localStorage.setItem("PO_NUM", orderNo);
  };

  const handleClickOutside = (event) => {
    if (ellipsisRef.current && !ellipsisRef.current.contains(event.target)) {
      setOptionShow(false);
    }
  };

  const STATUS_CLASS_MAP = {
    "Pending Approval": "invoice_pending_approval_bg", // Class for pending approval
    Rejected: "invoice_reject_bg", // Class for rejected
    Approved: "invoice_completed_status_bg", // Class for completed
  };

  const getOrderStatus = (status) => {
    if (status === null) return "Pending Approval";
    if (status === 0) return "Rejected";
    if (status === 1) return "Approved";
    // return "Pending Approval";
  };

  const handleSearch = (query) => {
    setSearchQuery(query);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const getPOtext = (status) => {
    switch (status) {
      case 1:
        return "approved";
      case 0:
        return "rejected";
      default:
        return "Pending Approval";
    }
  };

  const handlePOPDF = () => {
    const payload = {
      CLIENT_SUPP_ID: CLIENT_SUPPLIER_ID,
      SUPP_PO_ID: orderID,
      APP_CLIENT_ID: APP_CLIENT_ID,
    };
    makeApiCall({
      mod: "Supplier",
      action: "purchase_order_pdf",
      payload: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => {
      console.log("apyload of po pdf", payload);
      console.log("PO PDF SUCCESS ", response);
      if (response.XscStatus === 1) {
        const url = `${API_URL}/${response.XscData.URL}`;
        fetch(url)
          .then((response) => response.blob())
          .then((blob) => {
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = "purchase_order.pdf"; // Set the filename here
            link.click();
          });
      }
    });
  };
  const handlePOXL = () => {
    const payload = {
      CLIENT_SUPP_ID: CLIENT_SUPPLIER_ID,
      SUPP_PO_ID: orderID,
      APP_CLIENT_ID: APP_CLIENT_ID,
    };
    makeApiCall({
      mod: "Supplier",
      action: "purchase_order_excel",
      payload: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => {
      console.log("apyload of po xcel", payload);
      console.log("PO XL SUCCESS ", response);
      if (response.XscStatus === 1) {
        const url = `${API_URL}/${response.XscData.URL}`;
        fetch(url)
          .then((response) => response.blob())
          .then((blob) => {
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = "purchase_order.xlsx"; // Set the filename here
            link.click();
          });
      }
    });
  };

  const columns = [
    {
      field: "Order_No",
      headerName: "Order No",
      width: 150,
      cellClassName: "sticky-column",
    },
    {
      field: "PO_FILE",
      headerName: "PO File",
      width: 170,
      editable: false,
      align: "left",
      renderCell: (params) => {
        return (
          <a
            id="po_view_button"
            style={{ cursor: "pointer" }}
            onClick={() => {
              localStorage.setItem("SUPP_PO_ID", params.row.id);
              navigate("/suppliers/view-po");
            }}
          >
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M5 4H15V8H19V20H5V4ZM3.9985 2C3.44749 2 3 2.44405 3 2.9918V21.0082C3 21.5447 3.44476 22 3.9934 22H20.0066C20.5551 22 21 21.5489 21 20.9925L20.9997 7L16 2H3.9985ZM10.4999 7.5C10.4999 9.07749 10.0442 10.9373 9.27493 12.6534C8.50287 14.3757 7.46143 15.8502 6.37524 16.7191L7.55464 18.3321C10.4821 16.3804 13.7233 15.0421 16.8585 15.49L17.3162 13.5513C14.6435 12.6604 12.4999 9.98994 12.4999 7.5H10.4999ZM11.0999 13.4716C11.3673 12.8752 11.6042 12.2563 11.8037 11.6285C12.2753 12.3531 12.8553 13.0182 13.5101 13.5953C12.5283 13.7711 11.5665 14.0596 10.6352 14.4276C10.7999 14.1143 10.9551 13.7948 11.0999 13.4716Z"
                  fill="var(--primaryContainer)"
                />
              </svg>
            </div>
            <div>View</div>
          </a>
        );
      },
    },
    {
      field: "TOTAL_CTNS",
      headerName: "Total CTNS",
      width: 170,
      editable: false,
      align: "left",
    },

    {
      field: "TOTAL_AMOUNT",
      headerName: "Total Amount",
      type: "number",
      width: 250,
      editable: false,
      align: "left",
    },
    {
      field: "CREATED_ON",
      headerName: "Created On",
      width: 170,
      editable: false,
      align: "left",
      renderCell: (params) => {
        return <TimestampToDate timestamp={params.value} />;
      },
    },
    {
      field: "status",
      headerName: "Status",
      width: 200,
      renderCell: (params) => {
        const status = params.row.status;
        const orderStatusText = getOrderStatus(status);
        const className = STATUS_CLASS_MAP[orderStatusText] || "";
        return <div className={className}>{orderStatusText}</div>;
      },
    },
    // {
    //   field: "ACTION",
    //   headerName: "ACTION",
    //   width: 150,
    //   editable: false,
    //   align: "left",
    //   // headerClassName: "more_svg",
    //   renderCell: (params) => {
    //     let orderStatus = params.row.STATUS.props.children;
    //     return (
    //       <div
    //         style={{ cursor: "pointer" }}
    //         onClick={(e) => handleEllipsisClick(e, orderStatus)}
    //       >
    //         {orderStatus === "Rejected" ||
    //         orderStatus === "New" ||
    //         orderStatus === "Waiting Quotations"
    //           ? ""
    //           : params.value}
    //       </div>
    //     );
    //   },
    // },
    {
      field: "ACTION",
      headerName: "ACTION",
      width: 150,
      editable: false,
      align: "left",
      // headerClassName: "more_svg",
      renderCell: (params) => {
        let orderStatus = params.row.status;
        let orderID = params.row.id;
        let orderNo = params.row.Order_No;
        let status = getOrderStatus(orderStatus);
        if (orderStatus === 0) {
          return <div></div>;
        }
        return (
          <div
            style={{ cursor: "pointer" }}
            onClick={(e) => handleEllipsisClick(e, status, orderID, orderNo)}
          >
            {orderStatus === "Rejected" ||
            orderStatus === "New" ||
            orderStatus === "Waiting Quotations"
              ? ""
              : params.value}
          </div>
        );
      },
    },
  ];

  const initialRows = [
    // {
    //   id: "OR122",
    //   PO_FILE: (
    //     <div id="po_view_button">
    //       <div>
    //         <svg
    //           xmlns="http://www.w3.org/2000/svg"
    //           width="24"
    //           height="24"
    //           viewBox="0 0 24 24"
    //           fill="none"
    //         >
    //           <path
    //             d="M5 4H15V8H19V20H5V4ZM3.9985 2C3.44749 2 3 2.44405 3 2.9918V21.0082C3 21.5447 3.44476 22 3.9934 22H20.0066C20.5551 22 21 21.5489 21 20.9925L20.9997 7L16 2H3.9985ZM10.4999 7.5C10.4999 9.07749 10.0442 10.9373 9.27493 12.6534C8.50287 14.3757 7.46143 15.8502 6.37524 16.7191L7.55464 18.3321C10.4821 16.3804 13.7233 15.0421 16.8585 15.49L17.3162 13.5513C14.6435 12.6604 12.4999 9.98994 12.4999 7.5H10.4999ZM11.0999 13.4716C11.3673 12.8752 11.6042 12.2563 11.8037 11.6285C12.2753 12.3531 12.8553 13.0182 13.5101 13.5953C12.5283 13.7711 11.5665 14.0596 10.6352 14.4276C10.7999 14.1143 10.9551 13.7948 11.0999 13.4716Z"
    //             fill="var(--primaryContainer)"
    //           />
    //         </svg>
    //       </div>
    //       <div>View</div>
    //     </div>
    //   ),
  ];

  const [rows, setRows] = React.useState(initialRows);
  const [searchkeyword, setsearchkeyword] = useState("");

  useEffect(() => {
    const payload = {
      CLIENT_SUPPLIER_ID: CLIENT_SUPPLIER_ID,
      CURRENT_PAGE: page + 1,
      ITEM_PER_PAGE: pageSize,
      SEARCH_KEYWORD: searchkeyword,
      FILTER: { MANAGER_APPROVAL_STATUS: filter.orderStatusIds },
    };
    makeApiCall({
      mod: "SupplierPO",
      action: "supplier_po_list",
      payload: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => {
      console.log("PAYLOAD", payload);
      console.log(("supplier po  po list", response));
      console.log(payload, "popat");
      const purchaseorderlist = response.XscData.PO_LIST;
      setrowCount(response.XscData.TOTAL_PO_COUNT.COUNT);
   
      if (Array.isArray(purchaseorderlist) && purchaseorderlist.length>=0) {
        const  mappedRows = purchaseorderlist.map((orders) => ({
          id: orders.SUPP_PO_ID,
          Order_No: orders.ORDER_NUM,
          TOTAL_CTNS: orders.TOTAL_CTN,
          TOTAL_AMOUNT: `${orders.CURRENCY_SYMBOL}${
            orders.TOTAL_AMOUNT || "0"
          }`,

          CREATED_ON: orders.CREATED_ON,
          status: orders.MANAGER_APPROVAL_STATUS,
          ACTION: (
            <Dropdown className="table_more" style={{ border: "none" }}>
              <Dropdown.Toggle
                id="dropdown-basic"
                style={{
                  backgroundColor: "white",
                  color: "#5D6B82",
                  fontSize: "1rem",
                  fontWeight: "400",
                  lineHeight: "1.5rem",
                  border: "none",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="4"
                  height="18"
                  viewBox="0 0 4 18"
                  fill="none"
                >
                  <path
                    d="M2 0C1.175 0 0.5 0.675 0.5 1.5C0.5 2.325 1.175 3 2 3C2.825 3 3.5 2.325 3.5 1.5C3.5 0.675 2.825 0 2 0ZM2 15C1.175 15 0.5 15.675 0.5 16.5C0.5 17.325 1.175 18 2 18C2.825 18 3.5 17.325 3.5 16.5C3.5 15.675 2.825 15 2 15ZM2 7.5C1.175 7.5 0.5 8.175 0.5 9C0.5 9.825 1.175 10.5 2 10.5C2.825 10.5 3.5 9.825 3.5 9C3.5 8.175 2.825 7.5 2 7.5Z"
                    fill="#5D6B82"
                  />
                </svg>
              </Dropdown.Toggle>
            </Dropdown>
          ),
        }));
        setRows(mappedRows);
      }
    });
  }, [page, pageSize, searchkeyword,filter]);
  const ApprovePO = () => {
    const payload = { SUPPLIER_PO_ID: orderID, APPROVAL_STATUS: 1 };
    makeApiCall({
      mod: "SupplierPO",
      action: "approve_reject_po",
      payload: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => {
      console.log("payload approve ", payload);
      console.log("PO approved successfully", response);
      if (response.XscStatus == 1) {
        toast.success("Purchase Order Approved", {
          position: "bottom-center",
        });
      } else {
        toast.error(response.XscMessage, {
          position: "bottom-center",
        });
      }
    });
  };
  const RejectPO = () => {
    const payload = { SUPPLIER_PO_ID: orderID, APPROVAL_STATUS: 0 };

    makeApiCall({
      mod: "SupplierPO",
      action: "approve_reject_po",
      payload: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => {
      console.log("PO rejected successfully", response);
    });
  };
  console.log(rows);

  const onSearch = (value) => {
    setsearchkeyword(value);
  };
  console.log(currentStatus, "cir");
  return (
    <div>
      <div className="container">
        <div>
          <div id="po_heading">Purchase Order</div>
          <div
            className="d-flex justify-content-between"
            id="supp_filters"
            style={{ marginTop: "20px" }}
          >
            <div className="d-flex">
              <div>
                <SearchBar onSearch={handleSearch} />
              </div>
              <div
                onClick={() => setModalShow(true)}
                style={{ cursor: "pointer" }}
              >
                <Filter notify={notify} />
              </div>
              <OrderFilter
                show={modalShow}
                onHide={() => setModalShow(false)}
                onNotify={onNotify}
                onfilter={onfilter}
              />
            </div>
            <a href={`/suppliers/create-po`}>
              <div id="create_po_button">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M11 11V5H13V11H19V13H13V19H11V13H5V11H11Z"
                    fill="#F7F7F7"
                  />
                </svg>
                Create PO
              </div>
            </a>
          </div>
        </div>
        <div
          id="po_table"
          style={{
            height: 600,
            width: "100%",
            marginBottom: "80px",
            position: "relative",
            overflow: "hidden",
          }}
        >
          <DataGrid
            localeText={{
              noRowsLabel: (
                <div className="no_rows_css">
                  <img src={nodataimage} alt="" />
                  <div id="no_data_found_text">
                    You don’t have Purchase Orders for this supplier yet
                  </div>

                  <div
                    id="create_po_button"
                    onClick={() => navigate("/customers/create-pi")}
                    style={{ marginTop: "28px" }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M11 11V5H13V11H19V13H13V19H11V13H5V11H11Z"
                        fill="#F7F7F7"
                      />
                    </svg>
                    Create PO
                  </div>
                </div>
              ),
            }}
            style={{ position: "relative", overflow: "hidden" }}
            rows={rows}
            columns={columns}
            pageSize={pageSize}
            rowCount={rowCount}
            page={page}
            pagination
            paginationMode="server"
            rowsPerPageOptions={[10, 20, 50]} // Options for number of rows per page
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            onPageChange={handlepagechange}
            // checkboxSelection
            disableSelectionOnClick
          />
        </div>
        {optionShow && (
          <div
            className="options-dropdown ellipsisOption"
            ref={ellipsisRef}
            style={{
              top: optionPosition.top,
              left: optionPosition.left,
            }}
          >
            {currentStatus === "Pending Approval" && (
              <div className="d-flex flex-column align-items-start gap-2">
                <a
                  id="dropdown_options"
                  href="/suppliers/edit-po"
                  style={{
                    textDecoration: "none",
                    color: "#535260",
                    cursor: "pointer",
                  }}
                >
                  Modify PO
                </a>
                <a
                  id="dropdown_options"
                  href="/suppliers/view-po"
                  style={{
                    textDecoration: "none",
                    color: "#535260",
                    cursor: "pointer",
                  }}
                >
                  View PO
                </a>
                <a
                  id="dropdown_options"
                  style={{
                    textDecoration: "none",
                    color: "#535260",
                    cursor: "pointer",
                  }}
                  onClick={ApprovePO}
                >
                  Approve
                </a>
                <a
                  id="dropdown_options"
                  style={{
                    textDecoration: "none",
                    color: "#535260",
                    cursor: "pointer",
                  }}
                  onClick={RejectPO}
                >
                  Reject
                </a>
              </div>
            )}
            {currentStatus === "Approved" && (
              <div className="d-flex flex-column align-items-start gap-2">
                <a
                  id="dropdown_options"
                  href={`/suppliers/view-po?id=${CLIENT_SUPPLIER_ID}`}
                  onClick={() => localStorage.setItem("SUPP_PO_ID", orderID)}
                  style={{ textDecoration: "none", color: "#535260" }}
                >
                  View PO
                </a>
                <a
                  id="dropdown_options"
                  style={{
                    textDecoration: "none",
                    color: "#535260",
                    cursor: "pointer",
                  }}
                  className="d-flex flex-row align-items-center gap-2"
                  onClick={handlePOXL}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M8.8 8.00016L10.6667 10.6668H9.06667L8 9.14303L6.93333 10.6668H5.33333L7.2 8.00016L5.33333 5.3335H6.93333L8 6.8573L9.06667 5.3335H10V2.66683H3.33333V13.3335H12.6667V5.3335H10.6667L8.8 8.00016ZM2 1.9947C2 1.62953 2.29833 1.3335 2.66567 1.3335H10.6667L13.9998 4.66683L14 13.9952C14 14.3661 13.7034 14.6668 13.3377 14.6668H2.66227C2.29651 14.6668 2 14.3633 2 14.0056V1.9947Z"
                      fill="#635BFF"
                    />
                  </svg>
                  Download Excel
                </a>
                <a
                  id="dropdown_options"
                  style={{
                    textDecoration: "none",
                    color: "#535260",
                    cursor: "pointer",
                  }}
                  className="d-flex flex-row align-items-center gap-2"
                  onClick={handlePOPDF}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M3.33333 2.66683H10V5.3335H12.6667V13.3335H3.33333V2.66683ZM2.66567 1.3335C2.29833 1.3335 2 1.62953 2 1.9947V14.0056C2 14.3633 2.29651 14.6668 2.66227 14.6668H13.3377C13.7034 14.6668 14 14.3661 14 13.9952L13.9998 4.66683L10.6667 1.3335H2.66567ZM6.99993 5.00016C6.99993 6.05182 6.69613 7.2917 6.18329 8.43576C5.66858 9.58396 4.97429 10.567 4.25016 11.1462L5.03643 12.2216C6.98807 10.9204 9.14887 10.0282 11.239 10.3268L11.5441 9.03436C9.76233 8.44043 8.33327 6.66012 8.33327 5.00016H6.99993ZM7.39993 8.98123C7.5782 8.58363 7.73613 8.17103 7.86913 7.7525C8.18353 8.23556 8.5702 8.67896 9.00673 9.0637C8.3522 9.1809 7.711 9.37323 7.09013 9.61856C7.19993 9.4097 7.3034 9.1967 7.39993 8.98123Z"
                      fill="#635BFF"
                    />
                  </svg>
                  Download Pdf
                </a>
              </div>
            )}
            <ToastContainer
              position="bottom-center"
              style={{ width: "max-content" }}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default PurchaseOrder;
