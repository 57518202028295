import React, { useEffect, useState } from "react";
import ShippingHeader from "./ShippingHeader";
import ShippingDashboard from "./ShippingDashboard";
// import SupplierHeader from "./SupplierHeader";
// import SupplierDashboard from "./SupplierDashboard";

function SHDashboardLayout() {
  const [activeTab, setActiveTab] = useState(
    localStorage.getItem("shippingTab") === ""
      ? "container"
      : localStorage.getItem("shippingTab")
  );

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };
  useEffect(() => {
    localStorage.setItem("shippingTab", "");
  });
  return (
    <div>
      <ShippingHeader activeTab={activeTab} onTabChange={handleTabChange} />
      <ShippingDashboard activeTab={activeTab} onTabChange={handleTabChange} />
    </div>
  );
}
export default SHDashboardLayout;
