import React, { useEffect, useState, useRef } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { makeStyles } from "@material-ui/styles";
import SHFileUpload from "../../assets/SHFileUpload.png";
import excelDownload from "../../assets/excelDownload.png";
import pdfDownload from "../../assets/pdfDownload.png";
import { makeApiCall } from "../../StaticComponents/API";
import "../../Styles/shippingreports.css";
import TimestampToDate from "../../StaticComponents/DateFormat";
import noDataImage from "../../assets/no_result_img.png";
import nodataimage from "../../assets/no_data_image_2.png";
const useStyles = makeStyles({
  header: {
    "& .MuiDataGrid-columnHeaderTitle": {
      fontWeight: "bold",
    },
    "& .MuiDataGrid-menuIcon": {
      display: "none",
    },
  },

  row: {
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "var(--size-140) !important",
  },

  headerDownloadPdf: {
    minWidth: "160px !important",
    maxWidth: "160px !important",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontSize: "14px",
    paddingLeft: "25px !important",
  },

  headerDownloadExcel: {
    minWidth: "158px !important",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontSize: "14px",
  },

  headerShippingSelected: {
    minWidth: "173px !important",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontSize: "14px",
    marginLeft: "0px",
  },

  padding: {
    paddingLeft: "25px !important",
  },

  border: {
    "& .MuiCheckbox-root": {
      color: "var(--onSurface)",
    },
  },
});

export default function ShippingHistoryTable(props) {
  const [pageSize, setPageSize] = useState(10);
  const [columns, setColumns] = useState([]);
  const [selection, setSelection] = useState([]);
  const [page, setpage] = useState(0);
  const handlepagechange = (newpage) => {
    setpage(newpage);
  };
  const [optionShow, setOptionShow] = useState(false);
  const [optionPosition, setOptionPosition] = useState({ top: 0, left: 0 });
  const classes = useStyles();
  const APP_CLIENT_ID = localStorage.getItem("CLIENT_ID");
  const token = localStorage.getItem("token");
  const initialrows = [];

  const [rows, setrows] = useState(initialrows);
  const [total, settotal] = useState(20);
  const API_URL = process.env.REACT_APP_API_URL;
  const ellipsisRef = useRef(null);
  const [invoices, setinvoices] = useState([]);

  const initialColumns = [
    {
      field: "CUST_NUM",
      headerName: "Cust.ID",
      width: 133,
      cellClassName: classes.padding,
      headerClassName: classes.padding,
      headerAlign: "left",
    },
    {
      field: "CUSTOMER_NAME",
      headerName: "Cust.Name",
      width: 134,
      editable: false,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "POID",
      headerName: "Cust.PO-ID",
      width: 136,
      editable: false,
      align: "left",
      color: "#f5f2ff",
      headerAlign: "left",
    },
    {
      field: "INVOICE_COUNT",
      headerName: "No.of.Invoice",
      width: 175,
      editable: false,
      align: "left",
      headerAlign: "left",
      renderCell: (params) => {
        return (
          <div className="d-flex" id="shipping_reports_change">
            {params.value}
            <div>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={(e) =>
                  handleEllipsisClick(e, params.row.id, params.row.INVOICES)
                }
                style={{
                  cursor: "pointer",
                }}
              >
                <path
                  d="M7.9987 14.6666C4.3168 14.6666 1.33203 11.6818 1.33203 7.99998C1.33203 4.31808 4.3168 1.33331 7.9987 1.33331C11.6806 1.33331 14.6654 4.31808 14.6654 7.99998C14.6654 11.6818 11.6806 14.6666 7.9987 14.6666ZM7.9987 13.3333C10.9442 13.3333 13.332 10.9455 13.332 7.99998C13.332 5.05446 10.9442 2.66665 7.9987 2.66665C5.05318 2.66665 2.66536 5.05446 2.66536 7.99998C2.66536 10.9455 5.05318 13.3333 7.9987 13.3333ZM7.33203 4.66665H8.66536V5.99998H7.33203V4.66665ZM7.33203 7.33331H8.66536V11.3333H7.33203V7.33331Z"
                  fill="#635BFF"
                />
              </svg>
            </div>
          </div>
        );
      },
    },
    {
      field: "SH_FILE",
      headerName: "S&H File",
      width: 114,
      editable: false,
      align: "left",
      renderCell: (params) => (
        <a href="/view-shipping-history">
          <div
            className="d-flex align-items-center"
            onClick={() => {localStorage.setItem("SHIPMENT_ID", params.row.id)
              localStorage.setItem("CLIENT_CUST_ID",params.row.CLIENT_CUST_ID)
            }}
          >
            {params.value}{" "}
            <span
              style={{
                color:
                  "var(--color-sys-light-primary-container, var(--Schemes-Primary-Container, #635BFF))",
                fontFamily: "Inter",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "20px",
                textDecorationLine: "underline",
              }}
            >
              {" "}
              View
            </span>
          </div>
        </a>
      ),
    },
    {
      field: "CONTAINER_NO",
      headerName: "Container No",
      width: 175,
      editable: false,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "DELIVERY_DATE",
      headerName: "Exp.Delivery Date",
      width: 181,
      editable: false,
      align: "left",
      headerAlign: "left",
      renderCell: (params) => {
        return <TimestampToDate timestamp={params.value} />;
      },
    },
    {
      field: "STATUS",
      headerName: "Status",
      width: 149,
      editable: false,
      align: "left",
      headerAlign: "left",
      sortable: false,
      renderCell: (params) => {
        const commonStyle = {
          width: "auto",
          height: "24px",
          borderRadius: "16px",
          color: "#68622c",
          fontFamily: "Inter",
          fontSize: "14px",
          fontStyle: "normal",
          fontWeight: 500,
          lineHeight: "20px",
          padding: "2px 12px",
        };

        const status = params.value; // Get the status value

        const getStatusStyle = (status) => {
          switch (status) {
            case null:
              return { background: "#fffff", color: "#000000" };
            case "Not Yet Shipped":
              return { background: "#e69292", color: "#683A3A" };
            case "Delivered":
              return { background: "#a7e592", color: "#3A6C29" };
            case "In Transit":
              return { background: "#e6de92", color: "#68622C" };
            default:
              return { background: "#ffffff", color: "#000000" };
          }
        };

        // Determine the display value and style
        const displayValue = status === null ? "N/A" : status;

        return (
          <div
            style={{
              ...commonStyle,
              ...getStatusStyle(status), // Apply the status styles
            }}
          >
            {displayValue}
          </div>
        );
      },
    },
  ];

  const getShippingtext = (status) => {
    switch (status) {
      case 0:
        return "a";
      case 1:
        return "b";
      default:
        return "Unknown";
    }
  };

  const additionalColumns = [
    {
      field: "CUST_NUM",
      headerName: "",
      width: 133,
      cellClassName: classes.padding,
      headerClassName: classes.headerDownloadPdf,
      renderHeader: () => (
        <div
          className="d-flex flex-row align-items-center"
          style={{
            gap: "8px",
            height: "20px",
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
          }}
          onClick={handlepdfdownload}
        >
          <div>
            <img src={pdfDownload} />
          </div>
          <span style={{ color: "#635bff" }} id="dwn_text">
            Download Pdf
          </span>
        </div>
      ),
      sortable: false,
    },
    {
      field: "CUSTOMER_NAME",
      headerName: "",
      width: 134,
      editable: false,
      align: "left",
      headerClassName: classes.headerDownloadExcel,
      renderHeader: () => (
        <div
          className="d-flex flex-row align-items-center"
          style={{
            gap: "8px",
            height: "20px",
            display: "flex",
            alignItems: "center",
            height: "20px",
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
          }}
          onClick={handleexceldownload}
        >
          <div>
            <img src={excelDownload} />
          </div>
          <span style={{ color: "#635bff" }} id="dwn_text">
            Download Excel
          </span>
        </div>
      ),
      sortable: false,
    },
    {
      field: "POID",
      headerName: "Cust.PO-ID",
      width: 136,
      editable: false,
      align: "left",
      color: "#f5f2ff",
      headerAlign: "left",
      renderHeader: () => <div></div>,
      sortable: false,
    },
    {
      field: "INVOICE_COUNT",
      headerName: "No.of.Invoice",
      width: 175,
      editable: false,
      align: "left",
      headerAlign: "left",
      sortable:false,
      renderHeader:()=> <div></div>,
      renderCell: (params) => {
        return (
          <div className="d-flex" id="shipping_reports_change">
            {params.value}
            <div>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={(e) =>
                  handleEllipsisClick(e, params.row.id, params.row.INVOICES)
                }
                style={{
                  cursor: "pointer",
                }}
              >
                <path
                  d="M7.9987 14.6666C4.3168 14.6666 1.33203 11.6818 1.33203 7.99998C1.33203 4.31808 4.3168 1.33331 7.9987 1.33331C11.6806 1.33331 14.6654 4.31808 14.6654 7.99998C14.6654 11.6818 11.6806 14.6666 7.9987 14.6666ZM7.9987 13.3333C10.9442 13.3333 13.332 10.9455 13.332 7.99998C13.332 5.05446 10.9442 2.66665 7.9987 2.66665C5.05318 2.66665 2.66536 5.05446 2.66536 7.99998C2.66536 10.9455 5.05318 13.3333 7.9987 13.3333ZM7.33203 4.66665H8.66536V5.99998H7.33203V4.66665ZM7.33203 7.33331H8.66536V11.3333H7.33203V7.33331Z"
                  fill="#635BFF"
                />
              </svg>
            </div>
          </div>
        );
      },
    },
    {
      field: "SH_FILE",
      headerName: "S&H File",
      width: 114,
      editable: false,
      align: "left",
      sortable:false,
      renderHeader:()=> <div></div>,
      renderCell: (params) => (
        <a href="/view-shipping-history">
          <div
            className="d-flex align-items-center"
            onClick={() => {localStorage.setItem("SHIPMENT_ID", params.row.id)
              localStorage.setItem("CLIENT_CUST_ID",params.row.CLIENT_CUST_ID)
            }}
          >
            {params.value}{" "}
            <span
              style={{
                color:
                  "var(--color-sys-light-primary-container, var(--Schemes-Primary-Container, #635BFF))",
                fontFamily: "Inter",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "20px",
                textDecorationLine: "underline",
              }}
            >
              {" "}
              View
            </span>
          </div>
        </a>
      ),
    },
    {
      field: "CONTAINER_NO",
      headerName: "Container No",
      width: 175,
      editable: false,
      align: "left",
      headerAlign: "left",
      renderHeader: () => <div></div>,
      sortable: false,
    },
    {
      field: "DELIVERY_DATE",
      headerName: "Exp.Delivery Date",
      width: 181,
      editable: false,
      align: "left",
      headerAlign: "left",
      renderHeader: () => <div></div>,
      sortable: false,
    },
    {
      field: "STATUS",
      headerName: "Status",
      width: 149,
      editable: false,
      align: "left",
      sortable: false,
      renderCell: (params) => {
        const commonStyle = {
          width: "auto",
          height: "24px",
          borderRadius: "16px",
          color: "#68622c",
          fontFamily: "Inter",
          fontSize: "14px",
          fontStyle: "normal",
          fontWeight: 500,
          lineHeight: "20px",
          padding: "2px 12px",
        };

        const getStatusStyle = (status) => {
          switch (status) {
            case "In Transit":
              return {
                background: "#e6de92",
              };
            case "Delivered":
              return {
                background: "#a7e592",
              };
            default:
              return {
                background: "#e69292",
              };
          }
        };

        return (
          <div
            style={{
              ...commonStyle,
              ...getStatusStyle(params.value),
            }}
          >
            {params.value}
          </div>
        );
      },
      headerClassName: classes.headerShippingSelected,
      headerAlign: "left",
      renderHeader: () => <div>{selection.length} S&H Selected</div>,
      sortable: false,
    },
  ];

  const handleSelectionChange = (newSelection) => {
    setSelection(newSelection);
  };

  useEffect(() => {
    if (selection.length > 0) {
      setColumns(additionalColumns);
    } else {
      setColumns(initialColumns);
    }
  }, [selection]);

  const shFile = () => (
    <img
      src={SHFileUpload}
      alt="Upload File"
      style={{ width: "24px", height: "24px" }}
    />
  );

  const rowsWithFileIcon = rows.map((row) => ({
    ...row,
    SH_FILE: shFile(),
  }));

  useEffect(() => {
    const payload = {
      APP_CLIENT_ID: APP_CLIENT_ID,
      SEARCH_KEYWORD: props.searchkeyword,
      CURRENT_PAGE: page + 1,
    };
    makeApiCall({
      mod: "Report",
      action: "shipping-history",
      payload: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        console.log("request", payload);
        console.log("SHIPPING HISTORY records fetched", response); // Check the response here
        const shippings = response.XscData.SHIPMENT_DETAILS;

        if (Array.isArray(shippings)) {
          const mappedRows = shippings.map((shipping) => ({
            id: shipping.SHIPMENT_ID || "N/A",
            CUST_NUM: shipping.CUST_NUM || "N/A",
            CUSTOMER_NAME: shipping.CUSTOMER_NAME || "N/A",
            POID: shipping.CUST_PO_ID || "N/A",
            INVOICE_COUNT: shipping.NO_OF_INVOICE || "N/A",
            CONTAINER_NO: shipping.CONTAINER_NUM || "N/A",
            DELIVERY_DATE: shipping.EXPECTED_DELIVERY_DATE || "N/A",
            STATUS: shipping.DELIVERY_STATUS,
            INVOICES: shipping.INVOICES,
            CLIENT_CUST_ID:shipping.CLIENT_CUST_ID 
          }));
          setrows(mappedRows);
          console.log("mapped rows", mappedRows);
        }
        // settotal(response.XscData.TOTAL_SHIPPED_ORDERS);
        const RESULTS = response.XscData.TOTAL_SHIPPED_ORDERS;
        props.setvalues(RESULTS);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [props.searchkeyword, APP_CLIENT_ID, page]);

  const handlepdfdownload = () => {
    const payload1 = {
      APP_CLIENT_ID: APP_CLIENT_ID,
      CUST_SHIP_ID: selection,
    };
    makeApiCall({
      mod: "Report",
      action: "generate_shipping_history_pdf",
      payload: payload1,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        console.log("request", payload1);
        console.log("shipping history report pdf fetched", response); // Check the response here

        if (response.XscStatus === 1) {
          const url = `${API_URL}/${response.XscData.URL}`;
          fetch(url)
            .then((response) => response.blob())
            .then((blob) => {
              const link = document.createElement("a");
              link.href = URL.createObjectURL(blob);
              link.download = "report.pdf"; // Set the filename here
              link.click();
            });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleexceldownload = () => {
    const payload1 = {
      APP_CLIENT_ID: APP_CLIENT_ID,
      CUST_SHIP_ID: selection,
    };
    makeApiCall({
      mod: "Report",
      action: "generate_shipping_history_excel",
      payload: payload1,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        console.log("request", payload1);
        console.log("Shipping history report excel fetched", response); // Check the response here

        if (response.XscStatus === 1) {
          const url = `${API_URL}/${response.XscData.URL}`;
          fetch(url)
            .then((response) => response.blob())
            .then((blob) => {
              const link = document.createElement("a");
              link.href = URL.createObjectURL(blob);
              link.download = "report.xlsx"; // Set the filename here
              link.click();
            });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleEllipsisClick = (e, rowID, data) => {
    const rect = e.currentTarget.getBoundingClientRect();
    setOptionPosition({
      top: rect.bottom + window.scrollY,
      left: rect.left + window.scrollX,
    });

    setOptionShow(true);

    setinvoices(data);
  };

  const handleClickOutside = (event) => {
    if (ellipsisRef.current && !ellipsisRef.current.contains(event.target)) {
      setOptionShow(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  console.log("searchkeyworddddddddd", props.searchkeyword);
  console.log("selection", selection);
  console.log("ROWSS", rows);
  console.log("INVOICES in general ", invoices);
  return (
    <>
      <div
        style={{
          height: 600,
          width: "100%",
          marginTop: "20px",
          marginBottom: "40px",
        }}
        className={classes.border}
      >
        <DataGrid
         localeText={{
          noRowsLabel: (
            <div className="no_rows_css" style={{ textAlign: "center" }}>
              <img
                src={props.searchkeyword ? noDataImage : nodataimage}
                alt=""
                style={{ width: "103.26px", height: "100px" }}
              />
              <div
                id="no_data_found_text"
                style={{
                  fontFamily: "Inter",
                  fontWeight: 600,
                  fontSize: "22px",
                }}
              >
                {props.searchkeyword
                  ? "No results found in the list."
                  : "No reports have generated yet"}
              </div>
            </div>
          ),
        }}
          rows={rowsWithFileIcon}
          columns={columns}
          page={page}
          pagination
          paginationMode="server"
          pageSize={pageSize}
          rowsPerPageOptions={[10, 20, 50]}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          disableSelectionOnClick
          classes={{ columnHeader: classes.header, row: classes.row }}
          checkboxSelection
          onSelectionModelChange={(newSelection) =>
            handleSelectionChange(newSelection)
          }
        />
        {optionShow && (
          <div
            className="options-dropdown ellipsisOption"
            id="dropdown_with_ship"
            ref={ellipsisRef}
            style={{
              top: optionPosition.top,
              left: optionPosition.left,
            }}
          >
            {Array.isArray(invoices) && invoices.length > 0 ? (
              invoices.map((invoice) => (
                <div key={invoice.INVOICE_NUM} className="invoice-item">
                  {invoice.INVOICE_NUM}
                </div>
              ))
            ) : (
              <div>No invoices available</div>
            )}

            <div class="triangle-left"></div>
          </div>
        )}
      </div>
    </>
  );
}
