import { Modal } from "react-bootstrap";
import "../../../Styles/PurchaseHistory.css";
import ProductImage from "../../../assets/product_image.png";
import { makeStyles } from "@material-ui/styles";
import { DataGrid } from "@mui/x-data-grid";
import { useState, useEffect } from "react";
import po_img from "../../../assets/po_img.png";
import { makeApiCall } from "../../../StaticComponents/API";
import { useSearchParams } from "react-router-dom";

const useStyles = makeStyles({
  header: {
    "& .MuiDataGrid-columnHeaderTitle": { fontWeight: "bold" },
    "& .MuiDataGrid-menuIcon": { display: "none" },
  },

  row: {
    "& .MuiDataGrid-cell--textLeft": { textAlign: "center !important" },
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "var(--size-140) !important",
  },

  headerId: {
    minWidth: "115px !important",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontSize: "14px",
  },

  headerProductName: {
    minWidth: "185px !important",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontSize: "14px",
  },

  headerSupplier: {
    minWidth: "180px !important",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontSize: "14px",
  },

  headerDate: {
    minWidth: "146px !important",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontSize: "14px",
  },

  headerPrice: {
    minWidth: "93px !important",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontSize: "14px",
  },
});

export default function CustomerPurchaseHistory(props) {
  const [pageSize, setPageSize] = useState(10);

  const classes = useStyles();
  let CLIENT_SUPPLIER_ID = localStorage.getItem("CLIENT_SUPPLIER_ID");
  let prodId = localStorage.getItem("PRODUCT_ID");
  const [page, setpage] = useState(0);

  console.log(prodId, "id");
  function convertUnixTimestampToDate(unixTimestamp) {
    const date = new Date(unixTimestamp * 1000);
    const formattedDate = `${String(date.getDate()).padStart(2, "0")}-${String(
      date.getMonth() + 1
    ).padStart(2, "0")}-${date.getFullYear()}`;
    return formattedDate;
  }

  const [PHData, setPHData] = useState({
    xscdata: "",
  });
  const columns = [
    {
      field: "inv_id",
      headerName: "Invoice No",
      width: 150,
      cellClassName: "sticky-column",
      headerClassName: classes.headerId,
    },
    {
      field: "PRODUCT_NAME",
      headerName: "Product Name",
      width: 185,
      editable: false,
      align: "left",
      headerClassName: classes.headerProductName,
    },
    {
      field: "SUPPLIER",
      headerName: "Supplier",
      width: 180,
      editable: false,
      align: "left",
      headerClassName: classes.headerSupplier,
    },
    {
      field: "DATE",
      headerName: "On This Date",
      width: 146,
      editable: false,
      align: "left",
      headerClassName: classes.headerDate,
    },
    {
      field: "PRICE",
      headerName: "Price",
      width: 93,
      editable: false,
      align: "left",
      headerClassName: classes.headerPrice,
    },
  ];

  // const picture = () => (
  //   <div id="po_pic_bg">
  //     <img src={po_img} />
  //   </div>
  // );

  const copyIcon = () => (
    <svg
      style={{ cursor: "pointer" }}
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 0V2H17.0066C17.5552 2 18 2.44495 18 2.9934V19.0066C18 19.5552 17.5551 20 17.0066 20H0.9934C0.44476 20 0 19.5551 0 19.0066V2.9934C0 2.44476 0.44495 2 0.9934 2H4V0H14ZM4 4H2V18H16V4H14V6H4V4ZM6 14V16H4V14H6ZM6 11V13H4V11H6ZM6 8V10H4V8H6ZM12 2H6V4H12V2Z"
        fill="#635BFF"
      />
    </svg>
  );

  const API_URL = process.env.REACT_APP_API_URL;

  const Image_URL = PHData.xscdata.PRODUCT_IMAGE
    ? `${API_URL}/media/${PHData.xscdata.PRODUCT_IMAGE}`
    : po_img;

  const initialRows = [
    //   {
    // 	id: "I091",
    // 	PRODUCT_NAME: "12PCS MIRROR FASTE...",
    // 	SUPPLIER: "BNS Clothes Service",
    // 	DATE: "16-July-2024",
    // 	PRICE: "\u00a5 1.20",
    //   },
  ];

  const [rows, setRows] = useState([]);
  let token = localStorage.getItem("token");
  function safeStringify(obj, replacer, spaces) {
    const cache = new Set();
    return JSON.stringify(
      obj,
      (key, value) => {
        if (typeof value === "object" && value !== null) {
          if (cache.has(value)) {
            return; // Duplicate reference found, discard key
          }
          cache.add(value); // Store value in cache
        }
        return value;
      },
      spaces
    );
  }

  useEffect(() => {
    makeApiCall({
      mod: "Customer",
      action: "view-purchase-history",
      payload: {
        CLIENT_CUST_ID: localStorage.getItem("CLIENT_CUST_ID"),
        P_ID: prodId,
        CURRENT_PAGE: page + 1,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        const supplierPH = response.XscData.INVOICE_DETAILS;

        console.log(safeStringify(supplierPH, null, 2), "CUST PH");

        console.log(supplierPH, "CUST ph");
        if (Array.isArray(supplierPH)) {
          const mappedRows = supplierPH.map((supplier, id) => ({
            id: supplier.ID,
            inv_id: supplier.INVOICE_NO,
            PRODUCT_NAME: supplier.PRODUCT_NAME,
            SUPPLIER: supplier.CUSTOMER,
            DATE: convertUnixTimestampToDate(supplier.ON_THIS_DATE) || "N/A",
            PRICE: `${supplier.CURRENCY_SYMBOL} ${supplier.PRICE}` || "N/A",
          }));
          setRows(mappedRows);
        } else {
          console.error("suppliers PH data is not an array or is missing");
        }
        setPHData({
          xscdata: response.XscData,
        });
      })

      .catch((error) => console.error("Error fetching data:", error));
  }, [prodId]);

  const rowsWithIcons = rows.map((row) => ({
    ...row,
    // PICTURE: picture(),
    PH: copyIcon(),
  }));

  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="filter_text"
          >
            View Purchase History
          </Modal.Title>
        </Modal.Header>

        <div className="historyContent">
          <div className="div">
            <img src={Image_URL} className="productImage" />
            <hr className="verticalBreaker" />
            <div className="productId">
              <h2 id="id">{PHData.xscdata.CUST_PROD_ID}</h2>
              <p id="text">Cust.Prod.ID</p>
            </div>
            <hr className="verticalBreaker" />
            <div className="productId">
              <h2 id="id">{PHData.xscdata.MNX_PROD_ID}</h2>
              <p id="text">Mnx.Prod.ID</p>
            </div>
            <hr className="verticalBreaker" />
            <div className="productId">
              <h2 id="id">
                {"\u00a5 "}
                {PHData.xscdata.PER_PRICE}
              </h2>
              <p id="text">Per Piece</p>
            </div>
            <hr className="verticalBreaker" />
            <div className="productId">
              <h2 id="id">{PHData.xscdata.QTY_IN_INVENTORY}</h2>
              <p id="text">Qty. in Inventory</p>
            </div>
          </div>

          <div
            style={{
              height: 600,
              width: 736,
              marginBottom: "80px",
              alignSelf: "center",
              justifyContent: "center",
            }}
          >
            <DataGrid
              rows={rows}
              columns={columns}
              pageSize={pageSize}
              rowsPerPageOptions={[10, 20, 50]}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              disableSelectionOnClick
              classes={{
                columnHeader: classes.header,
                row: classes.row,
              }}
            />
          </div>
        </div>
      </Modal>
    </>
  );
}
