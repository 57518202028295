import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { makeStyles } from "@material-ui/styles";
import CustomerPurchaseHistory from "./CustomerPurchaseHistory";
import { useNavigate, useSearchParams } from "react-router-dom";
import po_img from "../../../assets/po_img.png";
import { makeApiCall } from "../../../StaticComponents/API";
import defaultImage from "../../../assets/Product_Package.png";

const useStyles = makeStyles({
  row: {
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "var(--size-140) !important",
  },
});

export default function CustomerPriceHistoryTable({ id, searchQuery }) {
  const [modalShow, setModalShow] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const API_URL = process.env.REACT_APP_API_URL;
  const [prodId, setProdID] = useState(null);
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL;
  const [selectedProductId, setSelectedProductId] = useState(null);
  const [selectedClientCustId, setSelectedClientCustId] =
    useState(null);
  const classes = useStyles();

  const columns = [
    {
      field: "CUST_PRODUCT_NO",
      headerName: "Cust.Prod.ID",
      width: 150,
      cellClassName: "sticky-column",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "MNX_ID",
      headerName: "Mnx.Prod.ID",
      width: 150,
      cellClassName: "sticky-column",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "PRODUCT_NAME",
      headerName: "Product Name",
      width: 250,
      editable: false,
    },
    {
      field: "PICTURE",
      headerName: "Picture",
      width: 130,
      editable: false,
      color: "#f5f2ff",
      renderCell: (params) => {
        const Image_URL = params.row.PICTURE
          ? `${API_URL}media/${params.row.PICTURE}`
          : po_img;
        return (
          <div id="po_pic_bg">
            <img src={Image_URL} />
          </div>
        );
      },
    },
    {
      field: "SUPPLIER_NAME",
      headerName: "Supplier Name",
      width: 250,
      editable: false,
    },
    {
      field: "LOCATION",
      headerName: "Location",
      width: 150,
      editable: false,
    },
    {
      field: "PRICE",
      headerName: "Price",
      width: 125,
      editable: false,
    },
    {
      field: "PH",
      headerName: "PH",
      width: 75,
      editable: false,
      align: "left",
      renderCell: (params) => (
        <div
          onClick={(e) => {
            e.stopPropagation();
            const productId = params.row.id;
            const CLIENT_SUPPLIER_ID = params.row.clientSupplierID;

            localStorage.setItem("CLIENT_SUPPLIER_ID", CLIENT_SUPPLIER_ID);
            localStorage.setItem("PRODUCT_ID", productId);

            setSelectedProductId(productId);
            setSelectedClientCustId(CLIENT_CUST_ID);
            setModalShow(true);
            console.log("Selected IDs:", productId, CLIENT_CUST_ID);
          }}
        >
          {purchaseHistoryIcon()}
        </div>
      ),
    },
  ];

  // const picture = () => (
  //   <div id="po_pic_bg">
  //     <img src={po_img} />
  //   </div>
  // );

  const purchaseHistoryIcon = () => (
    <svg
      style={{ cursor: "pointer" }}
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 0V2H17.0066C17.5552 2 18 2.44495 18 2.9934V19.0066C18 19.5552 17.5551 20 17.0066 20H0.9934C0.44476 20 0 19.5551 0 19.0066V2.9934C0 2.44476 0.44495 2 0.9934 2H4V0H14ZM4 4H2V18H16V4H14V6H4V4ZM6 14V16H4V14H6ZM6 11V13H4V11H6ZM6 8V10H4V8H6ZM12 2H6V4H12V2Z"
        fill="#635BFF"
      />
    </svg>
  );
  const initialRows = [
    // {
    //   id: "F-1671",
    //   MNX_ID: "M-1671",
    //   PRODUCT_NAME: "12PCS MIRROR FASTE...",
    //   SUPPLIER_NAME: "BNS Clothes Service",
    //   LOCATION: "China, Yun",
    //   PRICE: "¥1.20",
    // },
  ];

  const [rows, setRows] = useState(initialRows);

  const rowsWithIcons = rows.map((row) => ({
    ...row,
    // PICTURE: picture(),
    PH: purchaseHistoryIcon(),
  }));

  const [customerpriceHistory, setCustomerpriceHistory] = useState({
    xscdata: "",
  });

  let [searchParams] = useSearchParams();
  let token = localStorage.getItem("token");
  const CLIENT_CUST_ID = localStorage.getItem("CLIENT_CUST_ID");

  const getData = () => {
    const payload = {
      CLIENT_CUST_ID: CLIENT_CUST_ID,
      SEARCH_KEYWORD: searchQuery,
    };
    makeApiCall({
      mod: "Customer",
      action: "list_cust_price_history",
      payload: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((res) => {
      console.log(res, "list-cust-price-history");
      const priceHistoryitems = res.XscData.CUST_LIST;
      if (Array.isArray(priceHistoryitems)) {
        const mappedRows = priceHistoryitems.map((itemcounts) => {
          console.log("PRODUCT_ID:", itemcounts.PRODUCT_ID); // Debugging

          return {
            id: itemcounts.PRODUCT_ID,
            CUST_PRODUCT_NO: itemcounts.CUST_PRODUCT_NO,
            MNX_ID: itemcounts.MANEX_PRODUCT_NO,
            PRODUCT_NAME: itemcounts.PRODUCT_NAME,
            SUPPLIER_NAME: itemcounts.SUPPLIER_NAME,
            LOCATION: itemcounts.LOCATION,
            PRICE: itemcounts.PER_PRICE
              ? `${itemcounts.OPERATING_CURRENCY_SYMBOL} ${itemcounts.PER_PRICE}`
              : 0,
            PICTURE: itemcounts.PICTURE,
          };
        });
        setRows(mappedRows);
        setCustomerpriceHistory({
          xscdata: res.XscData,
        });
      } else {
        console.error("Customer Price History has no Data");
      }
    });
  };

  useEffect(() => {
    getData(searchQuery);
  }, [searchQuery, id]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleRowClick = (params) => {
    setProdID(params.row.id);
  };

  return (
    <>
      <div style={{ height: 600, width: "100%", marginTop: "20px" }}>
        <DataGrid
          onRowClick={handleRowClick} // Trigger the row click
          rows={rowsWithIcons}
          columns={columns}
          rowHeight={80}
          pageSize={pageSize}
          rowsPerPageOptions={[10, 20, 50]}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          disableSelectionOnClick
          classes={{ row: classes.row }}
        />
      </div>

      <CustomerPurchaseHistory
        show={modalShow}
        onHide={() => setModalShow(false)}
        prodId={prodId} // Pass the selected product ID
        CLIENT_CUST_ID={CLIENT_CUST_ID}
      />
       <CustomerPurchaseHistory
        show={modalShow}
        onHide={() => setModalShow(false)}
        productId={selectedProductId}
        clientCustId={selectedClientCustId}
      />
    </>
  );
}
