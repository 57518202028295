import React, { useState, useCallback, useEffect, useRef } from "react";
import logo from "../../../assets/Manex Logo.png";
import debounce from "lodash/debounce"; // You'll need lodash for debouncing
import { DataGrid } from "@mui/x-data-grid";
import { Modal } from "react-bootstrap";
import po_img from "../../../assets/po_img.png";
import SearchPO from "./SearchPO";
import PurchaseHistory from "../Products/PurchaseHistory";
import { makeStyles } from "@material-ui/styles";
import TextInput from "../../Inputs/TextInput";
import TextInputWithAll from "../../Inputs/TextInputWithAll";
import DropDownInputWithoutLabel from "../../Inputs/DropDownInputWithoutLabel";
import DropDownInput from "../../Inputs/DropDownInput";
import { makeApiCall } from "../../../StaticComponents/API";
import "../../../Styles/CreatePO.css";
import { useLocation, useSearchParams } from "react-router-dom";
import { Autocomplete, TextField } from "@mui/material";
import "../../../Styles/createPOnew.css";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import default_image from "../../../assets/Product_Package.png";
import SupplierPurchaseHistory from "../PriceHistory/SupplierPurchaseHistory";
import FormatCurrency from "../../../StaticComponents/FormatCurrency";
import USDCurrency from "../../../StaticComponents/USDCurrency";
// import { useLocation } from "react-router-dom";
import Stack from "@mui/material/Stack";
const useStyles = makeStyles({
  subheadingRow: {
    textAlign: "center",
    fontWeight: "bold",
    minHeight: "32px !important",
    maxHeight: "32px !important",
    "& .MuiDataGrid-cell": {
      border: "none !important",
      lineHeight: "32px !important",
      minHeight: "32px !important",
      maxHeight: "32px !important",
      zIndex: 10,
    },
  },

  subheadingCell: {
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    paddingLeft: 10,
    width: "100%",
    border: "0.5px solid var(--outlineVariant) !important",
    lineHeight: "24px !important",
    minHeight: "32px !important",
    maxHeight: "32px !important",
    zIndex: 10,
    display: "flex",
    justifyContent: "center",
  },

  padding: { padding: "0px !important" },
});

function PreviewPO(props) {
  const API_URL = process.env.REACT_APP_API_URL;
  const [searchShow, setSearchShow] = useState(false);
  const [show, setShow] = useState(false);
  const [customerName, setCustomerName] = useState("");
  const [productid, setproductid] = useState();
  const [suggestions, setSuggestions] = useState([]);
  const [productdetails, setproductdetails] = useState();
  const [Unit, setUnit] = useState("KG");
  const [optionShow, setOptionShow] = useState(false);
  const [optionPosition, setOptionPosition] = useState({ top: 0, left: 0 });
  const [currentStatus, setCurrentStatus] = useState("");
  const ellipsisRef = useRef(null);
  const navigate = useNavigate();
  const [shippingMarks, setshippingMarks] = useState(null);
  const [delivaeryDate, setdeliveryDate] = useState(null);
  const [Warehouse, setWarehouse] = useState(null);
  const [paymentType, setpaymenyType] = useState(null);
  const [SUPP_PO_ID, setSUPP_PO_ID] = useState([]);
  const initialRows = {};

  const [rows, setRows] = useState([]);
  const [payload, setPayload] = useState();

  const paymentMethods = [
    { label: "T/T", value: "T/T" },
    { label: "CASH", value: "CASH" },
    { label: "CARD", value: "CARD" },
    { label: "ONLINE", value: "ONLINE" },
    { label: "OTHERS", value: "OTHERS" },
  ];
  function convertUnixToDate(unixTimestamp) {
    const date = new Date(unixTimestamp * 1000);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }

  const inputRefs = useRef({});

  const CLIENT_SUPPLIER_ID = localStorage.getItem("CLIENT_SUPPLIER_ID");
  let token = localStorage.getItem("token");
  const APP_CLIENT_ID = localStorage.getItem("CLIENT_ID");
  let [searchParams] = useSearchParams();
  // const ID = searchParams.get("id");
  // const ID = 133;
  const [createPoInfo, setCreatePoInfo] = React.useState({
    supplierdata: "",
    clientData: "",
  });

  const [invoiceItems, setInvoiceItems] = useState([]);
  const [clientNum, setclientNum] = useState();
  const [activeRow, setActiveRow] = useState("");
  const handlefocus = (id) => {
    setActiveRow(id);
  };

  const [selectedProductId, setSelectedProductId] = useState(null);
  const [itemDescription, setItemDescription] = useState({});
  const [itemValue, setItemValue] = useState({});
  function convertToUnixTimestamp(date) {
    return Math.floor(new Date(date).getTime() / 1000);
  }
  const WeightOptions = [
    { value: "KG", label: "Kgs" },
    { value: "GM", label: "grams" },
    { value: "LBS", label: "lbs" },
  ];

  const CountryOptions = [
    { value: "1", label: "China" },
    { value: "2", label: "China" },
  ];

  const classes = useStyles();

  const location = useLocation();
  const { selectedPOId } = location.state || {};
  const [poDetails, setPoDetails] = useState([]);

  console.log("ROWS", rows);
  let calculatedRows = [];
  if (Array.isArray(rows) && rows.length > 0) {
    calculatedRows = rows.map((row) => ({
      ...row,
      TOTAL_QTY: row.CTN * row.CTN_QTY,
    }));
  }
  let calculatedRows1 = [];
  if (Array.isArray(rows) && rows.length > 0) {
    calculatedRows1 = calculatedRows.map((row) => ({
      ...row,

      TTL_WEIGHT: parseFloat((row.TOTAL_QTY * row.WEIGHT).toFixed(2)),
      TTL_CBM: row.TOTAL_QTY * row.CBM,
      TOTAL_AMOUNT: row.TOTAL_QTY * row.PRICE,
    }));
  }
  const totalAmountAllRows = calculatedRows1.reduce(
    (acc, row) => acc + row.TOTAL_AMOUNT,
    0
  );
  const totalCBMAllRows = calculatedRows1.reduce(
    (acc, row) => acc + row.TTL_CBM,
    0
  );
  const totalWeightAllRows = calculatedRows1.reduce(
    (acc, row) => acc + row.TTL_WEIGHT,
    0
  );
  const CTNAllRows = calculatedRows1.reduce((acc, row) => acc + row.CTN, 0);

  // calculatedRows1.push(rows)
  const resultRows = calculatedRows1.map((row) => {
    if (row.id_desc === "Total") {
      return {
        ...row,
        id: "total_ctn",
        TOTAL_AMOUNT: totalAmountAllRows,
        CTN: CTNAllRows,
        TTL_CBM: totalCBMAllRows,
        TTL_WEIGHT: totalWeightAllRows,
      };
    }
    return row;
  });
  const columns = [
    {
      field: "MNX_ID",
      headerName: "Mnx. Item ID",
      width: 148,
      cellClassName: "po_input",
      align: "center",
      renderCell: (params) => {
        return params.row.id_desc === "Total" ? null : (
          <div style={{ position: "relative" }}>
            <input
              type="text"
              value={params.value}
              //  onChange={(e) => handleItemChange(e, params.row.id, "mnxItemId")}
              ref={(el) => (inputRefs.current[params.row.id] = el)}
              style={{ width: "100%", padding: "8px", boxSizing: "border-box" }}
            />
          </div>
        );
      },
    },

    {
      field: "SUP_ITEM_ID",
      headerName: "Sup. Item Id",
      width: 148,
      cellClassName: "po_input",
      align: "center",
      renderCell: (params) => {
        return params.row.id_desc === "Total" ? null : (
          <div style={{ position: "relative" }}>
            <input
              type="text"
              value={params.value}
              ref={(el) => (inputRefs.current[params.row.id] = el)}
              style={{ width: "100%", padding: "8px", boxSizing: "border-box" }}
            />
          </div>
        );
      },
    },

    {
      field: "TOTAL_QTY",
      headerName: "Total QTY",
      width: 150,
      align: "center",
      renderCell: (params) => {
        return params.row.id_desc === "Total" ? (
          <div className={classes.subheadingCell}>{params.value}</div>
        ) : (
          <div>{params.value}</div>
        );
      },
    },
    {
      field: "PICTURE",
      headerName: "Picture",
      width: 120,
      align: "center",
      renderCell: (params) => {
        const imageUrl = params.row.MM_FILE_NAME
          ? `${API_URL}/media/${params.row.MM_FILE_NAME}`
          : default_image;
        return params.row.id_desc === "Total" ? null : (
          <div id="po_pic_bg">
            <img
              src={imageUrl}
              alt="Product Image"
              style={{ width: "100%", height: "auto" }}
            />
          </div>
        );
      },
    },
    {
      field: "CTN_QTY",
      headerName: "CTN/QTY",
      width: 150,

      align: "center",
    },
    {
      field: "INNER",
      headerName: "Inner",
      width: 150,
      align: "center",
      cellClassName: "po_input",
      renderCell: (params) => {
        return params.row.id_desc === "Total" ? null : (
          <TextInput
            type="text"
            value={params.value}
            onChange={(e) => {
              const inputValue = e.target.value;
              if (/^\d*$/.test(inputValue)) {
                handleInputChange1(e, params.row.id, "INNER");
              }
            }}
          />
        );
      },
    },
    {
      field: "CTN",
      headerName: "CTN",
      width: 150,
      cellClassName: "po_input",
      align: "center",
      renderCell: (params) => {
        return params.row.id_desc === "Total" ? (
          <div className={classes.subheadingCell}>{params.value}</div>
        ) : (
          <TextInput
            type="text"
            value={params.value}
            onChange={(e) => handleInputChange1(e, params.row.id, "CTN")}
          />
        );
      },
    },
    {
      field: "DESCRIPTION",
      headerName: "Description",
      width: 320,
      cellClassName: "po_input",
      align: "center",
      renderCell: (params) => {
        return params.row.id_desc === "Total" ? null : (
          <TextInput
            type="text"
            value={params.value}
            onChange={(e) =>
              handleInputChange1(e, params.row.id, "DESCRIPTION")
            }
          />
        );
      },
    },
    {
      field: "PRICE",
      headerName: "Price",
      width: 180,
      align: "center",
      cellClassName: "po_input",
      renderCell: (params) => {
        return params.row.id_desc === "Total" ? null : (
          <TextInput
            type="text"
            value={params.value}
            onChange={(e) => handleInputChange1(e, params.row.id, "PRICE")}
          />
        );
      },
    },
    {
      field: "TOTAL_AMOUNT",
      headerName: "Total Amount",
      width: 160,
      align: "center",
      renderCell: (params) => {
        return params.row.id_desc === "Total" ? (
          <div className={classes.subheadingCell}>{params.value}</div>
        ) : (
          <div>
            {params.row.CURRENCY_SYMBOL}
            <USDCurrency amount={params.value} />
          </div>
        );
      },
    },
    {
      field: "BARCODE",
      headerName: "Barcode",
      width: 320,
      cellClassName: "po_input",
      align: "center",
      renderCell: (params) => {
        return params.row.id_desc === "Total" ? null : (
          <TextInput
            type="text"
            value={params.value}
            onChange={(e) => handleInputChange1(e, params.row.id, "BARCODE")}
          />
        );
      },
    },
    {
      field: "DUN_BARCODE",
      headerName: "Dun Barcode",
      width: 320,
      cellClassName: "po_input",
      align: "center",
      renderCell: (params) => {
        return params.row.id_desc === "Total" ? null : (
          <TextInput
            type="text"
            value={params.value}
            onChange={(e) =>
              handleInputChange1(e, params.row.id, "DUN_BARCODE")
            }
          />
        );
      },
    },
    {
      field: "PACKING",
      headerName: "Packing",
      width: 320,
      cellClassName: "po_input",
      align: "center",
      renderCell: (params) => {
        return params.row.id_desc === "Total" ? null : (
          <TextInput
            type="text"
            value={params.value}
            onChange={(e) => handleInputChange1(e, params.row.id, "PACKING")}
          />
        );
      },
    },
    {
      field: "CBM",
      headerName: "CBM",
      width: 130,
      cellClassName: "po_input",
      align: "center",
      renderCell: (params) => {
        return params.row.id_desc === "Total" ? null : (
          <TextInput
            type="text"
            value={params.value}
            onChange={(e) => handleInputChange1(e, params.row.id, "CBM")}
          />
        );
      },
    },
    {
      field: "TTL_CBM",
      headerName: "TTL CBM",
      width: 130,
      align: "center",
      renderCell: (params) => {
        return params.row.id_desc === "Total" ? (
          <div className={classes.subheadingCell}>{params.value}</div>
        ) : <div >{params.value.toFixed(4)}</div>;
      },
    },
    {
      field: "WEIGHT",
      headerName: "Weight",
      width: 240,
      cellClassName: "po_input",
      align: "center",
      renderCell: (params) => {
        return params.row.id_desc === "Total" ? null : (
          <div className="position-relative d-flex gap-0" id="dp_with_op">
            <TextInput
              type="text"
              id="weight_dp_down"
              value={params.value}
              onChange={(e) => handleInputChange1(e, params.row.id, "WEIGHT")}
            />
            <div>
              <select
                id="cust_div1"
                value={Unit}
                onChange={(e) => setUnit(e.target.value)}
              >
                <option value="" disabled>
                  select unit
                </option>
                {WeightOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
            <div
              style={{ top: 26, right: 0, paddingRight: 0 }}
              id="remaining_characters"
              className="position-absolute"
            ></div>
          </div>
        );
      },
    },
    {
      field: "TTL_WEIGHT",
      headerName: "TTL Weight",
      width: 148,
      align: "center",
      renderCell: (params) => {
        return params.row.id_desc === "Total" ? (
          <div className={classes.subheadingCell}>{params.value}</div>
        ) : null;
      },
    },
    {
      field: "DEL",
      headerName: " ",
      width: 60,
      align: "center",
      sortable: false,
      renderCell: (params) => {
        // Check if the row is not the "Total" row
        if (params.row.id_desc === "Total") return null;

        return (
          <div
            onClick={() => deleteRow(params.row.id)} // Call deleteRow function on click
            style={{ cursor: "pointer" }} // Change cursor to pointer
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M17 6H22V8H20V21C20 21.5523 19.5523 22 19 22H5C4.44772 22 4 21.5523 4 21V8H2V6H7V3C7 2.44772 7.44772 2 8 2H16C16.5523 2 17 2.44772 17 3V6ZM18 8H6V20H18V8ZM9 11H11V17H9V11ZM13 11H15V17H13V11ZM9 4V6H15V4H9Z"
                fill="var(--error)"
              />
            </svg>
          </div>
        );
      },
    },
  ];

  const ITEMS = calculatedRows1.map((row) => ({
    // CLIENT_P_NUMBER: row.MNX_ID,
    //  SUPP_P_NUMBER: row.SUP_ITEM_ID,
    PRODUCT_ID: row.PRODUCT_ID,
    //  QTY_PER_CTN: row.CTN_QTY,
    INNER: 5,
    P_DESCRIPTION: row.DESCRIPTION,
    PRICE_PER_ITEM: row.PRICE,
    BARCODE: row.BARCODE,
    DUN_BARCODE: row.DUN_BARCODE,
    PACKAGING: row.PACKING,
    CBM: row.CBM,
    WEIGHT: row.WEIGHT,
    WEIGHT_UNIT: Unit,
    TOTAL_QTY: row.TOTAL_QTY,
    TOTAL_CTN: row.CTN,
  }));
  const handleInputChange = (event, rowId, field) => {
    const value = event.target.value;
    setclientNum(value);
    setItemValue((prev) => ({ ...prev, [rowId]: clientNum }));
    //searchItem(value, field, rowId); // Trigger search with rowId
  };
  const handleInputChange1 = (event, rowID, field) => {
    const value = event.target.value;
    setRows((prevRows) =>
      prevRows.map((row) =>
        row.id === rowID ? { ...row, [field]: value } : row
      )
    );
  };
  const searchCustomer = useCallback(
    debounce(async (value) => {
      if (value.length < 1) {
        setSuggestions([]);
        return;
      }

      try {
        const payload = { SEARCH_KEYWORD: value, APP_CLIENT_ID: APP_CLIENT_ID };
        console.log("Payload:", payload);
        const res = await makeApiCall({
          mod: "Customer",
          action: "search_customer",
          payload: payload,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        console.log("API response:", res); // Log the API response to check the data
        setSuggestions(res.XscData?.CUST_NAME_LIST || []);
      } catch (error) {
        console.error("Error fetching suggestions:", error);
        setSuggestions([]);
      }
    }, 300),
    []
  );

  const [customerID, setcustomerID] = useState(null);
  const handleCustomerChange = (event) => {
    const value = event.target.value;
    setCustomerName(value);
    searchCustomer(value);
    if (value === "") {
      setcustomerID(null);
    }
  };

  const getData = () => {
    const payload = {
      CLIENT_SUPP_ID: CLIENT_SUPPLIER_ID,
    };

    makeApiCall({
      mod: "SupplierPO",
      action: "get-client-supp-details",
      payload: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((res) => {
      console.log(res, "create-po-info");
      setCreatePoInfo({
        clientData: res.XscData.CLIENT_DATA,
        supplierdata: res.XscData.SUPP_DATA,
      });
    });
  };

  useEffect(() => {
    getData();
  }, []);

  const getRowClassName = (params) => {
    return params.row.id_desc === "Total" ? classes.subheadingRow : "";
  };

  const getCellClassName = (params) => {
    return params.row.id_desc === "Total" ? classes.padding : "";
  };

  const deleteRow = (rowId) => {
    const updatedRows = rows.filter((row) => row.id !== rowId);
    setRows(updatedRows);
  };

  console.log("PAYLOAD IN PREVIEW", props.payload);
  return (
    <div>
      <Modal
        show={props.show}
        onHide={props.onHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div id="suppliers_topbar_bg" className="">
          <div className="container d-flex justify-content-between align-items-center">
            <div id="header_name" className="d-flex ">
              <div>
                <a onClick={props.onHide}>
                  <span id="cancel_po_button">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M10.5859 12.0001L2.79297 4.20718L4.20718 2.79297L12.0001 10.5858L19.793 2.79297L21.2072 4.20718L13.4143 12.0001L21.2072 19.7929L19.793 21.2072L12.0001 13.4143L4.20718 21.2072L2.79297 19.7929L10.5859 12.0001Z"
                        fill="var(--onSurface)"
                      />
                      <path
                        d="M10.5859 12.0001L2.79297 4.20718L4.20718 2.79297L12.0001 10.5858L19.793 2.79297L21.2072 4.20718L13.4143 12.0001L21.2072 19.7929L19.793 21.2072L12.0001 13.4143L4.20718 21.2072L2.79297 19.7929L10.5859 12.0001Z"
                        fill="black"
                        fill-opacity="0.2"
                      />
                      <path
                        d="M10.5859 12.0001L2.79297 4.20718L4.20718 2.79297L12.0001 10.5858L19.793 2.79297L21.2072 4.20718L13.4143 12.0001L21.2072 19.7929L19.793 21.2072L12.0001 13.4143L4.20718 21.2072L2.79297 19.7929L10.5859 12.0001Z"
                        fill="black"
                        fill-opacity="0.2"
                      />
                      <path
                        d="M10.5859 12.0001L2.79297 4.20718L4.20718 2.79297L12.0001 10.5858L19.793 2.79297L21.2072 4.20718L13.4143 12.0001L21.2072 19.7929L19.793 21.2072L12.0001 13.4143L4.20718 21.2072L2.79297 19.7929L10.5859 12.0001Z"
                        fill="black"
                        fill-opacity="0.2"
                      />
                      <path
                        d="M10.5859 12.0001L2.79297 4.20718L4.20718 2.79297L12.0001 10.5858L19.793 2.79297L21.2072 4.20718L13.4143 12.0001L21.2072 19.7929L19.793 21.2072L12.0001 13.4143L4.20718 21.2072L2.79297 19.7929L10.5859 12.0001Z"
                        fill="black"
                        fill-opacity="0.2"
                      />
                    </svg>
                  </span>{" "}
                </a>
              </div>

              <span className="ps-2  d-flex align-items-center">
                {" "}
                Preview Purchase Order
                {/* <div id="edit_supplier_id">MNXS-2024-0001</div> */}
              </span>
            </div>
          </div>
        </div>
        <div id="create_po_bg">
          <div className="container">
            <div id="po_logo" align="center">
              <img src={logo} alt="logo" />
              <div id="po_header_text">{createPoInfo.clientData.NAME}</div>
            </div>
            <div className="row" id="about_po_header">
              <div className="col-6">
                <div id="po_supplier_text">Supplier</div>
                <div id="po_supplier_name">
                  {createPoInfo.supplierdata.NAME}
                </div>
                <div id="po_supplier_address">
                  {`${createPoInfo.supplierdata.ADDR_1},${createPoInfo.supplierdata.ADDR_2}`}
                </div>
                <div id="po_supplier_ph">
                  TEL:{" "}
                  <span id="po_supplier_no">
                    {createPoInfo.supplierdata.CONTACT_NUMBER}
                  </span>
                </div>
                <div id="po_supplier_ph">
                  FAX:{" "}
                  <span id="po_supplier_no">
                    {createPoInfo.supplierdata.FAX}
                  </span>
                </div>
                <div id="po_supplier_ph">
                  Purchase Order No: <span id="po_supplier_no"></span>
                </div>
                <div id="po_supplier_ph" className="d-flex align-items-center">
                  Date:
                  <span id="po_supplier_no">
                    <TextInputWithAll
                      type="date"
                      className="date_input"
                      value={props.payload.CREATED}
                    />
                  </span>
                </div>
              </div>
              <div className="col-6 " id="about_po_left_bg" align="end">
                <div id="content_wrapper">
                  {" "}
                  <div id="po_supplier_text">Order By</div>
                  <div id="po_supplier_name">
                    {createPoInfo.clientData.NAME}
                  </div>
                  <div id="po_supplier_address">
                    {`${createPoInfo.clientData.ADDR_1},${createPoInfo.clientData.ADDR_2}`}
                  </div>
                  <div id="po_supplier_ph">
                    TEL:{" "}
                    <span id="po_supplier_no">
                      {createPoInfo.clientData.CONTACT_NUMBER}
                    </span>
                  </div>
                  <div id="po_supplier_ph">
                    FAX:{" "}
                    <span id="po_supplier_no">
                      {createPoInfo.clientData.FAX}
                    </span>
                  </div>
                  <div
                    id="po_supplier_ph"
                    className="d-flex align-items-center"
                  >
                    Customer:{" "}
                    <TextInputWithAll
                      id="cust_div"
                      type="text"
                      placeholder="search customer"
                      value={props.payload.CUSTOMER_Name}
                    />
                    <div>
                      {suggestions.length > 0 && (
                        <div className="suggestions-dropdown">
                          {suggestions.map((suggestion) => (
                            <div
                              key={suggestion.CLIENT_CUST_ID}
                              className="suggestion-item"
                              onClick={() => {
                                setCustomerName(suggestion);
                                setSuggestions([]);
                                setcustomerID(suggestion.CLIENT_CUST_ID);
                              }}
                            >
                              {suggestion.NAME}
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <SearchPO show={searchShow} onHide={() => setSearchShow(false)} />
            <div
              style={{
                //minHeight: "100px",
                maxHeight: "fit-content",
                width: "100%",
                marginTop: "20px",
                zIndex: 1,
              }}
            >
              <DataGrid
                // components={{
                //   NoRowsOverlay: () => (
                //     <Stack height="100%" alignItems="center" justifyContent="center" alignContent="center">
                //       No rows in DataGrid
                //     </Stack>
                //   ),
                //   NoResultsOverlay: () => (
                //     <Stack height="100%" alignItems="center" justifyContent="center">
                //       Local filter returns no result
                //     </Stack>
                //   )
                // }}
                localeText={{
                  noRowsLabel: (
                    <div className="no_rows_css">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM11 7H13V9H11V7ZM11 11H13V17H11V11Z"
                          fill="#535260"
                        />
                      </svg>
                      Enter & Select customer name & start adding your item
                    </div>
                  ),
                }}
                rows={props.payload.rows}
                columns={columns.map((col) => ({ ...col, filterable: false, sortable: false }))}
                hideFooterPagination={true}
                hideFooter={true}
                autoHeight
                rowHeight={80}
                showCellRightBorder
                getCellClassName={getCellClassName}
                getRowClassName={getRowClassName}
                disableColumnMenu={true}  
                disableColumnFilter={true}
                sortingOrder={[]}
              />
            </div>

            <div
              className="d-flex flex-column gap-4"
              style={{
                marginTop: 45,
                paddingBottom: 44,
                borderBottom: "0.5px solid var(--outlineVariant) !important",
              }}
            >
              <div className="d-flex flex-row align-items-center gap-2">
                <div
                  style={{
                    width: 8,
                    height: 44,
                    background: "var(--tertiaryContainer, #BA3CBB)",
                  }}
                ></div>
                <h2 id="shipping_details">Shipping Marks</h2>
              </div>
              <textarea
                name="postContent"
                className="p-3"
                id="text_container_123"
                value={props.payload.SHIPPING_MARKS}
                //  onChange={(e) => setshippingMarks(e.target.value)}
              />
            </div>
            <div id="po_terms_condtions">
              <div id="po_terms_title">Terms & Conditions</div>
              <div>
                EACH CARTON SHOULD BE STRONG EXPORT CARTON WITH DOUBLE STRAP
                WITHOUT CHINESE WRITING, THE SHIPPING MARK SHOULD BE STICKED ON
                BOTH SIDE.箱子使用牢固无中文外贸箱, 并带有打包带, 唛头贴四方.
                (正唛两张, 侧唛两张)
              </div>
              <div>
                IF THE SHIPPMENT GOODS ARE NOT ACCORDING TO OUR REQUIRMENT, WE
                HAVE THE RIGHT TO REJECT THE GOODS 如不按我方要求生产货物,
                我方将有权要求产家反工或拒收货物.
              </div>
              <div>
                THE ERROR QUANTITY FOR EACH ITEM MUST BE WITHIN 10 PIECES.
                每款货误差数量必须在10个之内。
              </div>
              <div>
                THE PACKING , QUANTITY PER CARTON AND QUANTITY PER INNER BOX
                SHOULD SAME AS PER ORDER CONFIRMED.
              </div>
              <div
                className="d-flex create_po_deliverydate"
                style={{ marginTop: "44px" }}
              >
                <div id="po_del_date">交货期 Delivery date:</div>
                <div id="po_supplier_ph" className="d-flex align-items-center">
                  <span id="po_supplier_no">
                    <TextInputWithAll
                      type="date"
                      className="date_input"
                      value={convertUnixToDate(props.payload.DELIVERY_DATE)}
                    />
                  </span>
                </div>
              </div>
              <div className="create_po_deliverydate">
                <div id="po_del_date">交货地址 Ware house add:</div>
                <div id="po_supplier_ph" className="d-flex align-items-center">
                  <span id="po_supplier_no">
                    <TextInputWithAll
                      type="text"
                      className="date_input"
                      value={props.payload.WAREHOUSE_ADDR}
                      //  onChange={(e) => setWarehouse(e.target.value)}
                    />
                  </span>
                </div>
              </div>
              <div className="create_po_deliverydate">
                <div id="po_del_date">付款方式(PAYMENT TERMS):</div>
                <div id="po_supplier_ph" className="d-flex align-items-center">
                  <span id="po_supplier_no1">
                    <DropDownInputWithoutLabel
                      options={paymentMethods}
                      value={props.payload.PAYMENT_TYPE}
                      // onChange={(e) => setpaymenyType(e.target.value)}
                      id="cust_div2"
                      style={{
                        width: 208,
                        margin: 0,
                      }}
                      placeholder="Select Payment Mode"
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
          <PurchaseHistory show={show} onHide={() => setShow(false)} />
        </div>
      </Modal>
    </div>
  );
}
//href="/suppliers/preview-po"
export default PreviewPO;
