import React, { useEffect, useRef, useState } from "react";
import "../../Styles/ImportSupplier.css";
import { CircleSpinner } from "react-spinners-kit";
import SupplierTable from "./SuppliersTable";
import sample_file_supplier from "../../assets/supplierSample.csv";
import TextInputWithAll from "../Inputs/TextInputWithAll";
import { makeApiCall } from "../../StaticComponents/API";
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";
import DataGridSkeleton from "../../StaticComponents/SkeletonLoading";
import ImportedData from "./ImportedTable";
import { toast, ToastContainer } from "react-toastify";
function ImportSupplier() {
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const fileInputRef = useRef(null);
  const uploadTimeoutRef = useRef(null);
  const [show, setShow] = useState(false);
  const [importData, setimportData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [responseData, setResponseData] = useState([]);
  const [message, setMessage] = useState("");

  const token = localStorage.getItem("token");
  const APP_CLIENT_ID = localStorage.getItem("CLIENT_ID");

  const downloadTemplate = () => {
    makeApiCall({
      mod: "BulkImports",
      action: "get-import-supplier-template",
      payload: {},
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        const mainData = res.XscData;
        if (mainData && mainData.TEMPLATE_URL) {
          const downloadUrl =
            process.env.REACT_APP_API_URL + mainData.TEMPLATE_URL;

          // Create a temporary anchor element and trigger the download
          const a = document.createElement("a");
          a.href = downloadUrl;
          a.download = "supplier-template.csv"; // You can set a file name here
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        } else {
          console.error("TEMPLATE_URL is not available in the response.");
        }
      })
      .catch((err) => {
        console.error("Error downloading file:", err);
      });
  };
  const handleChange = (e) => {
    setIsLoading(true);
    if (e.target.files && e.target.files.length > 0) {
      setIsLoading(true);
      const selectedFile = e.target.files[0];
      console.log("Selected file:", selectedFile);
      console.log(
        "Selected file last modified date:",
        selectedFile.lastModifiedDate
      );

      setFile(URL.createObjectURL(e.target.files[0]));
      setFileName(e.target.files[0].name);
      setIsLoading(false);

      const payload = { APP_CLIENT_ID: APP_CLIENT_ID };

      const formData = new FormData();
      formData.append("file", selectedFile); // Directly append the file
      formData.append("mod", "BulkImports");
      formData.append("action", "get_bulk_excel_data");
      formData.append("payload", JSON.stringify(payload));

      axios({
        method: "post",
        url: process.env.REACT_APP_API_URL,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      }).then((res) => {
        console.log(res, "response");
        setIsLoading(false);
        res.data.XscStatus === 0 && toast.error("Invalid Fields");
        const importedData = res.data.XscData.LIST;
        setResponseData(res.data.XscData.LIST);
        if (Array.isArray(importedData)) {
          const mappedRows = importedData.map((supplier, idx) => ({
            id: idx,

            SUPPLIER_NAME: supplier.COMPANY_NAME,
            EMAIL: supplier.EMAIL,
            CONTACT_NUMBER: supplier.CONTACT_NO,
            ADDRESS: supplier.ADDR_LINE_1,
            WEBSITE: supplier.WEBSITE,
          }));
          setimportData(mappedRows); // Set rows after mapping
        } else {
          console.error("Suppliers data is not an array or is missing");
        }
      });
    } else {
      console.error("No file selected");
    }
  };
  useEffect(() => {
    console.log(importData, "importData after state update"); // Ensure it contains the correct data
  }, [importData]);
  const handleImportCancel = () => {
    setFile(null);
    setFileName("");
    setIsLoading(false);

    // Clear the upload timeout if it exists
    if (uploadTimeoutRef.current) {
      clearTimeout(uploadTimeoutRef.current);
      uploadTimeoutRef.current = null;
    }

    // Reset the file input
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }

    console.log("File upload canceled");
  };
  const submitImportData = () => {
    const payload = {
      APP_CLIENT_ID: APP_CLIENT_ID,
      LIST: responseData,
    };
    makeApiCall({
      mod: "BulkImports",
      action: "add_supplier_bulk",
      payload: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((res) => {
      toast(res.XscMessage);
      setShow(true);
      setMessage(res.XscMessage);
    });
  };
  return (
    <div id="supp_bg" style={{ height: "100vh" }}>
      <div
        id="suppliers_topbar_bg"
        style={{ paddingLeft: "0", paddingRight: "0" }}
      >
        <div className="container d-flex justify-content-between align-items-center">
          <div id="dashboard_sub_header">Import Supplier</div>
        </div>
      </div>
      <div>
        {isLoading ? (
          <div id="while_uploding_bg" className="container">
            <div>
              <div id="upload_file_text">
                <span
                  className="d-flex align-items-center "
                  style={{ gap: "4px" }}
                >
                  <CircleSpinner
                    size={20}
                    loading={isLoading}
                    color="var(--primaryContainer)"
                  />{" "}
                  Uploading Your File
                </span>{" "}
                <div id="uploaded_file_name">{fileName}</div>
              </div>
            </div>
            <div id="file_upload_cancel" onClick={handleImportCancel}>
              Cancel
            </div>
          </div>
        ) : (
          <div id="import_supplier_bg" className="container">
            {file ? (
              <div>
                {show && (
                  <div id="while_importing_bg" className=" mx-3 me-3 " >
                    <div className="d-flex">
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="48"
                          height="48"
                          viewBox="0 0 48 48"
                          fill="none"
                        >
                          <path
                            d="M24 44C12.9543 44 4 35.0456 4 24C4 12.9543 12.9543 4 24 4C35.0456 4 44 12.9543 44 24C44 35.0456 35.0456 44 24 44ZM22.0052 32L36.1474 17.8579L33.319 15.0294L22.0052 26.3432L16.3484 20.6862L13.52 23.5148L22.0052 32Z"
                            fill="var(--successColorContainer)"
                          />
                        </svg>
                      </div>
                      <div id="upload_file_text" style={{ marginLeft: "8px"}}>
                        <span
                          className="d-flex align-items-center "
                          style={{ gap: "4px" }}
                        >
                          Import Successful
                        </span>{" "}
                        <div id="uploaded_file_name">{message}</div>
                      </div>
                    </div>
                  </div>
                )}
                {!show && (
                  <div>
                    <div className="container">
                      <div id="while_uploding_bg">
                        <div>
                          <div></div>
                          <div id="upload_file_text">
                            <span
                              className="d-flex align-items-center "
                              style={{ gap: "4px" }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <path
                                  d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM11.0026 16L18.0737 8.92893L16.6595 7.51472L11.0026 13.1716L8.17421 10.3431L6.75999 11.7574L11.0026 16Z"
                                  fill="#42832D"
                                />
                              </svg>{" "}
                              File uploaded Successfully
                            </span>{" "}
                            <div
                              id="uploaded_file_name"
                              style={{ marginLeft: "28px" }}
                            >
                              {fileName}
                            </div>
                          </div>
                        </div>
                        <div
                          id="file_upload_cancel"
                          onClick={handleImportCancel}
                          style={{ cursor: "pointer" }}
                        >
                          Remove
                        </div>
                      </div>
                      <div id="import_supplier_table">
                        <div
                          style={{
                            height: 600,
                            width: "100%",
                            marginBottom: "80px",
                          }}
                        >
                          {isLoading ? (
                            <DataGridSkeleton rows={importData} />
                          ) : (
                            <ImportedData
                              importData={importData}
                              isLoading={isLoading}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    <div id="add_supplier_footer" className="">
                      <div id="add_supplier_buttons" className="container">
                        <div></div>

                        <div
                          className="d-flex align-items-center justify-content-right "
                          style={{ gap: "12px" }}
                        >
                          <div id="cancel" onClick={handleImportCancel}>Cancel</div>
                          <div id="submitsupplier" onClick={submitImportData}>
                            Import Supplier
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div className="col-12 container">
                <label
                  htmlFor="file-input"
                  className="d-flex align-items-center justify-content-center flex-column"
                  id="file-input-label"
                >
                  <div style={{ cursor: "pointer" }}>
                    <svg
                      className="my-svg"
                      xmlns="http://www.w3.org/2000/svg"
                      width="60"
                      height="60"
                      viewBox="0 0 60 60"
                      fill="none"
                    >
                      <g opacity="0.4">
                        <path
                          d="M30 31.4645L40.6065 42.071L37.071 45.6065L32.5 41.0375V55H27.5V41.0325L22.9289 45.6065L19.3934 42.071L30 31.4645ZM30 5C38.9835 5 46.386 11.769 47.3853 20.4849C53.2145 22.0788 57.5 27.414 57.5 33.75C57.5 40.922 52.009 46.8115 45.0015 47.444L45 42.5C45 34.2157 38.2842 27.5 30 27.5C21.904 27.5 15.3061 33.914 15.0103 41.9378L15 42.5L15.001 47.444C7.9924 46.813 2.5 40.923 2.5 33.75C2.5 27.414 6.7856 22.0788 12.6162 20.4853C13.614 11.769 21.0165 5 30 5Z"
                          fill="var(--primaryContainer)"
                        />
                      </g>
                    </svg>
                  </div>
                  <div id="upload_csv_text">
                    {file && fileName}

                    <>
                      Upload
                      <div id="select_csv_file">Select your file(.xlsx)</div>
                    </>
                  </div>
                </label>
                <TextInputWithAll
                  name="file-input"
                  type="file"
                  onChange={handleChange}
                  id="file-input"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  maxLength={1}
                  accept=".xlsx"
                />

                <div id="view_sample_file" onClick={downloadTemplate}>
                  <a
                    className="d-flex align-items-center"
                    // href={sample_file_supplier}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M3 19H21V21H3V19ZM13 13.1716L19.0711 7.1005L20.4853 8.51472L12 17L3.51472 8.51472L4.92893 7.1005L11 13.1716V2H13V13.1716Z"
                        fill="var(--primaryContainer)"
                      />
                    </svg>
                    <div className="ps-2">Download Sample File</div>
                  </a>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      <ToastContainer
        position="bottom-center"
        style={{ width: "max-content" }}
      />
    </div>
  );
}
export default ImportSupplier;
