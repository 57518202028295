import React, { useEffect } from "react";
import "../Styles/Home.css";
import { Doughnut } from "react-chartjs-2";
import "chart.js/auto";
import { useNavigate } from "react-router-dom";
import { makeApiCall } from "../StaticComponents/API";

function Home() {
  let APP_CLIENT_ID = localStorage.getItem("CLIENT_ID");
  const token = localStorage.getItem("token");

  const navigate = useNavigate();
  useEffect(() => {
    if (!token) {
      navigate("/");
    }
  }, [navigate]);

  const options = {
    plugins: {
      tooltip: {
        enabled: false,
      },
      legend: {
        display: false, // Hide the default legend
        data: false,
      },
    },
  };

  const [dashboardDetails, setDashboardDetails] = React.useState({
    xscdata: "",
  });

  const getData = () => {
    const payload = {
      APP_CLIENT_ID: APP_CLIENT_ID,
    };

    makeApiCall({
      mod: "Dashboard",
      action: "display-dashboard",
      payload: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((res) => {
      console.log(res, "display-dashboard");
      setDashboardDetails({
        xscdata: res.XscData,
      });
    });
  };

  useEffect(() => {
    getData();
  }, [APP_CLIENT_ID, token]);
  const Chartdata = [
    dashboardDetails.xscdata.IN_STOCK,
    dashboardDetails.xscdata.TO_SHIP,
    dashboardDetails.xscdata.TO_RECEIVE,
  ];
  const defaultColors = ["#3CD856", "#FA5A7D", "#BF83FF"];
  const greyColor = "#D3D3D3"; // Grey color for 0 values

  const backgroundColor = Chartdata.map((value, index) =>
    value == 0 ? greyColor : defaultColors[index]
  );

  const hoverBackgroundColor = backgroundColor;
  const data = {
    labels: [
      <div className="d-flex col">
        <div style={{ color: "#1B1B24", fontWeight: "600", fontSize: "14px" }}>
          In Stock:
        </div>
        <div
          style={{
            color: "#535260",
            fontWeight: "400",
            fontSize: "14px",
            marginLeft: "3px",
          }}
        >
          {dashboardDetails.xscdata.IN_STOCK}
        </div>
      </div>,
      <div className="d-flex col">
        <div style={{ color: "#1B1B24", fontWeight: "600", fontSize: "14px" }}>
          To Ship:
        </div>
        <div
          style={{
            color: "#535260",
            fontWeight: "400",
            fontSize: "14px",
            marginLeft: "3px",
          }}
        >
          {dashboardDetails.xscdata.TO_SHIP}
        </div>
      </div>,
      <div className="d-flex col">
        <div style={{ color: "#1B1B24", fontWeight: "600", fontSize: "14px" }}>
          To Receive:
        </div>
        <div
          style={{
            color: "#535260",
            fontWeight: "400",
            fontSize: "14px",
            marginLeft: "3px",
          }}
        >
          {dashboardDetails.xscdata.TO_RECEIVE}
        </div>
      </div>,
    ],
    datasets: [
      {
        data: Chartdata,
        backgroundColor: backgroundColor,
        hoverBackgroundColor: hoverBackgroundColor,
      },
    ],
  };

  // Concatenate labels with their corresponding data
  const concatenatedData = data.labels.map((label, index) => {
    return `${label}: ${data.datasets[0].data[index]}`;
  });

  console.log(concatenatedData);

  return (
    <div>
      <div
        id="suppliers_topbar_bg"
        style={{ paddingLeft: "0", paddingRight: "0" }}
      >
        <div className="container d-flex justify-content-between align-items-center">
          <div id="dashboard_header" className="d-flex ">
            Dashboard
          </div>
        </div>
      </div>
      <div className="container">
        <div id="home_header_tabs_bg" className="row">
          <div className="col-md-4">
            <a href="/customers">
              <div id="home_header_single_bg">
                <div>
                  <div id="home_total_text">Total Customers</div>
                  <div id="home_total_value">
                    {dashboardDetails.xscdata.TOTAL_CUSTOMERS
                      ? dashboardDetails.xscdata.TOTAL_CUSTOMERS
                      : 0}
                  </div>
                </div>
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M13.1685 12.0007L8.21875 7.05093L9.63297 5.63672L15.9969 12.0007L9.63297 18.3646L8.21875 16.9504L13.1685 12.0007Z"
                      fill="#635BFF"
                    />
                  </svg>
                </div>
              </div>
            </a>
          </div>
          <div className="col-md-4">
            {" "}
            <a
              href="/all-orders"
              onClick={() =>
                localStorage.setItem("activeTab", "CustomersOrders")
              }
            >
              <div id="home_header_single_bg">
                <div>
                  <div id="home_total_text">Customers Orders</div>
                  <div id="home_total_value">
                    {dashboardDetails.xscdata.CUSTOMER_ORDERS
                      ? dashboardDetails.xscdata.CUSTOMER_ORDERS
                      : 0}
                  </div>
                </div>

                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M13.1685 12.0007L8.21875 7.05093L9.63297 5.63672L15.9969 12.0007L9.63297 18.3646L8.21875 16.9504L13.1685 12.0007Z"
                      fill="#635BFF"
                    />
                  </svg>
                </div>
              </div>
            </a>
          </div>
          <div className="col-md-4">
            <a
              href="/reports"
              onClick={() =>
                localStorage.setItem("activeTab", "Account Receivable")
              }
            >
              {" "}
              <div id="home_header_single_bg">
                <div>
                  <div id="home_total_text">Accounts Receivable</div>
                  <div id="home_total_value">
                    {dashboardDetails.xscdata.ACCOUNT_RECEIVABLE
                      ? `¥${dashboardDetails.xscdata.ACCOUNT_RECEIVABLE}`
                      : 0}
                  </div>
                </div>
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M13.1685 12.0007L8.21875 7.05093L9.63297 5.63672L15.9969 12.0007L9.63297 18.3646L8.21875 16.9504L13.1685 12.0007Z"
                      fill="#635BFF"
                    />
                  </svg>
                </div>
              </div>
            </a>
          </div>
          <div className="col-md-4">
            {" "}
            <a href="/suppliers">
              <div id="home_header_single_bg">
                <div>
                  <div id="home_total_text">Total Supplier</div>
                  <div id="home_total_value">
                    {dashboardDetails.xscdata.TOTAL_SUPPLIERS
                      ? dashboardDetails.xscdata.TOTAL_SUPPLIERS
                      : 0}
                  </div>
                </div>
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M13.1685 12.0007L8.21875 7.05093L9.63297 5.63672L15.9969 12.0007L9.63297 18.3646L8.21875 16.9504L13.1685 12.0007Z"
                      fill="#635BFF"
                    />
                  </svg>
                </div>
              </div>
            </a>
          </div>
          <div className="col-md-4">
            <a
              href="/all-orders"
              onClick={() =>
                localStorage.setItem("activeTab", "SuppliersOrders")
              }
            >
              <div id="home_header_single_bg">
                <div>
                  <div id="home_total_text">Supplier Orders</div>
                  <div id="home_total_value">
                    {dashboardDetails.xscdata.SUPPLIER_ORDERS
                      ? dashboardDetails.xscdata.SUPPLIER_ORDERS
                      : 0}
                  </div>
                </div>
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M13.1685 12.0007L8.21875 7.05093L9.63297 5.63672L15.9969 12.0007L9.63297 18.3646L8.21875 16.9504L13.1685 12.0007Z"
                      fill="#635BFF"
                    />
                  </svg>
                </div>
              </div>
            </a>
          </div>
          <div className="col-md-4">
            <a
              href="/reports"
              onClick={() =>
                localStorage.setItem("activeTab", "Account Payable")
              }
            >
              <div id="home_header_single_bg">
                <div>
                  <div id="home_total_text">Accounts Payable</div>
                  <div id="home_total_value">
                    {dashboardDetails.xscdata.ACCOUNT_PAYABLE
                      ? `¥${dashboardDetails.xscdata.ACCOUNT_PAYABLE}`
                      : 0}
                  </div>
                </div>
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M13.1685 12.0007L8.21875 7.05093L9.63297 5.63672L15.9969 12.0007L9.63297 18.3646L8.21875 16.9504L13.1685 12.0007Z"
                      fill="#635BFF"
                    />
                  </svg>
                </div>
              </div>
            </a>
          </div>
          <div className="col-md-4">
            <a href="/inventory">
              <div id="home_header_inventory_bg">
                {" "}
                <div id="home_header_inventory_inner_bg">
                  <div>
                    <div id="home_total_text">Inventory</div>
                    <div id="home_total_value">
                      {dashboardDetails.xscdata.INVENTORY
                        ? dashboardDetails.xscdata.INVENTORY
                        : 0}
                    </div>
                  </div>
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M13.1685 12.0007L8.21875 7.05093L9.63297 5.63672L15.9969 12.0007L9.63297 18.3646L8.21875 16.9504L13.1685 12.0007Z"
                        fill="#635BFF"
                      />
                    </svg>
                  </div>
                </div>
                <div id="chart-container">
                  <Doughnut data={data} options={options} />
                </div>
                <div id="chart-legend">
                  {data.labels.map((label, index) => (
                    <div key={index} className="legend-item">
                      <div
                        className="legend-color"
                        style={{
                          backgroundColor:
                            data.datasets[0].backgroundColor[index],
                        }}
                      ></div>
                      <br />
                      <div className="legend-label">{label}</div>
                    </div>
                  ))}
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
