import React from "react";
import "../Styles/Reports.css";

export default function Reports({ activeTab, onTabChange }) {
	const list = [
		"Customer",
		"Suppliers",
		"Account Receivable",
		"Account Payable",
		"Shipping History",
		"Inventory",
	];

	return (
		<div>
			<div
        id="suppliers_topbar_bg"
        style={{ paddingLeft: "0", paddingRight: "0" }}
        >
         <div className="container d-flex justify-content-between align-items-center">
          <div id="dashboard_header">Report</div>
            
        </div>
      </div>
			<div id="report_menu_bg">
				<div className="container">
					<div id="report_menus">
						{" "}
						{list.map((item, index) => (
							<div
								key={index}
								style={{
									color:
										activeTab === item &&
										"var(--primaryContainer)",
									fontSize: activeTab === item && "14px",
									fontWeight: activeTab === item && "600",
								}}
								id="report_header_menu"
								onClick={() => onTabChange(item)}
							>
								{item}
								{activeTab === item && (
									<div
										id="report_active_tab"
										className="position-absolute  bottom-0 start-50 translate-middle-x"
									></div>
								)}
							</div>
						))}
					</div>
				</div>
			</div>
		</div>
	);
}
