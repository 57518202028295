import React from "react";

function Users() {
  return (
    <div>
      <div>Users</div>
    </div>
  );
}

export default Users;
