import React from "react";
import manex_logo from "../assets/Manex Logo.png";
import "../Styles/LinkExpire.css";
import LinkExpireImg from "../assets/LinkExpire.png";

const LinkExpire = () => {
  return (
    <div id="login_bg" align="center">
      <div className="LEMain">
        <div>
          <img src={manex_logo} alt="Manex Logo" id="MLogo"></img>
        </div>
        <div id="LEImg">
          <img src={LinkExpireImg} alt="Link Expire Image" id="LEimg"></img>
        </div>
        <div id="LEuprtext">
          Opps! This link is not valid
          <br /> anymore
        </div>
        <div id="LElwrtext">Request for the reset password link again</div>
        <a
          href="/forget-password"
          className="text-decoration-none"
          id="login_text_button"
        >
          {" "}
          Go back to reset password
        </a>
      </div>
    </div>
  );
};

export default LinkExpire;
