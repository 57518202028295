import React from "react";
import ReportCustomer from "./ReportCustomer";
import ReportSupplier from "./ReportSupplier";
import AccountReceivable from "./AccountReceivable";
import AccountPayable from "./AccountPayable";
import ShippingHistory from "./ShippingHistory";
import ReportInventory from "./ReportInventory";

export default function ReportsDashboard({ activeTab, onTabChange }) {
	return (
		<div>
			<div>{activeTab === "Customer" && <ReportCustomer />}</div>
			<div>{activeTab === "Suppliers" && <ReportSupplier />}</div>
			<div>
				{activeTab === "Account Receivable" && <AccountReceivable />}
			</div>
			<div>{activeTab === "Account Payable" && <AccountPayable />}</div>
			<div>{activeTab === "Shipping History" && <ShippingHistory />}</div>
			<div>{activeTab === "Inventory" && <ReportInventory />}</div>
		</div>
	);
}
