import React, { useState } from "react";
import "../../../Styles/AddProductC.css";
import productUploadImg from "../../../assets/Text Field.png";
import cancelbutton from "../../../assets/Frame.png";
import PhoneInput from "react-phone-number-input/input";
import DeleteProduct from "./DeleteProduct";
import TextInputWithAll from "../../Inputs/TextInputWithAll";
import TextInput from "../../Inputs/TextInput";
import DropDownWithInput from "../../Inputs/DropDownWithInput";
import { makeApiCall } from "../../../StaticComponents/API";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import axios from "axios";
import DropDownInput from "../../Inputs/DropDownInput";

const AddProductC = () => {
  const token = localStorage.getItem("token");
  const [files, setFiles] = useState([]);
  const [fileName, setFileName] = useState("");
  const [noOfImages, setNoOfImages] = useState(0);
  const [image, setImage] = useState([]);
  const navigate = useNavigate();
  const [searchparams, setsearchparams] = useSearchParams();
  const CLIENT_SUPP_ID = localStorage.getItem("CLIENT_SUPPLIER_ID");
  const [charCount, setCharCount] = useState(0);
  const [ctnTypes, setCtnTypes] = useState([]);
  const [selectedCtnType, setSelectedCtnType] = useState("");
  const maxCharCount = 100;
  const [errors, setErrors] = useState({});

  const [invalidFields, setInvalidFields] = useState({});
  const lengthOptions = [
    { value: "MTR", label: "m" },
    { value: "CM", label: "cm" },
    { value: "IN", label: "in" },
    { value: "FEET", label: "feet" },
    { value: "MM", label: "mm" },
  ];

  const weightOptions = [
    { value: "GM", label: "g" },
    { value: "KG", label: "Kg" },
    { value: "LBS", label: "lbs" },
  ];

  const volumeOptions = [
    { value: "CB_CM", label: "cm³" },
    { value: "CB_MTR", label: "m³" },
    { value: "CB_FEET", label: "feet³" },
    { value: "CB_MM", label: "mm³" },
    { value: "CB_IN", label: "in³" },
  ];

  const initialvalues = {
    CLIENT_SUPPLIER_ID: CLIENT_SUPP_ID,
    APP_CLIENT_ID: localStorage.getItem("CLIENT_ID"),
    SUPPLIER_PROD_NUM: "",
    CLIENT_PROD_NUM: "",
    SUPP_P_BAR_CODE: "",
    CLIENT_P_BAR_CODE: "",
    SUPP_DUN_BAR_CODE: "",
    CLIENT_DUN_BAR_CODE: "",
    P_DESC: "",
    P_MATERIAL: "",
    P_COLOR: "",
    PRICE_PER_PCS: "",
    P_LENGTH: "",
    P_WIDTH: "",
    P_HEIGHT: "",
    SIZE_UNIT: "MTR",
    P_WT: "",
    P_WT_UNIT: "KG",
    VOLUME: "",
    VOLUME_UNIT: "CB_MTR",
    P_PKG_TYPE: "",
    CTN_ID: "",
    C_PKG_TYPE: "",
    QTY_PER_CTN: "",
    CTN_NET_WT: "",
    CTN_GROSS_WT: "",
    CTN_WT_UNIT: "KG",
    PRIMARY_IMAGE_INDEX: 1,
  };

  const [data, setdata] = useState(initialvalues);

  const APP_CLIENT_ID = localStorage.getItem("CLIENT_ID");

  useEffect(() => {
    const payload = {
      APP_CLIENT_ID: APP_CLIENT_ID,
    };
    makeApiCall({
      mod: "Shipping",
      action: "get-ctn-list",
      payload: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        console.log(response);
        if (Array.isArray(response.XscData.CTN_LIST)) {
          setCtnTypes(
            response.XscData.CTN_LIST.map((type) => ({
              ID: type.ID, // Adjust based on actual response structure
              ALIAS_NAME: type.ALIAS_NAME, // Adjust based on actual response structure
            }))
          );
        } else {
          console.error(
            "Unexpected data format for CTN types:",
            response.XscMessage
          );
        }
      })
      .catch((error) => {
        console.error("Error fetching CTN types:", error);
      });
  }, []);

  useEffect(() => {
    setCharCount(data.P_DESC.length);
  }, [data.P_DESC]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Define a regex for numeric validation
    const numberRegex = /^\d*\.?\d*$/;

    // List of fields that should only accept numeric values
    const numericFields = [
      "SUPP_DUN_BAR_CODE",
      "CLIENT_DUN_BAR_CODE",
      "PRICE_PER_PCS",
      "QTY_PER_CTN",
      "P_LENGTH",
      "P_WIDTH",
      "P_HEIGHT",
      "VOLUME",
      "CTN_NET_WT",
      "CTN_GROSS_WT",
      "SUPP_P_BAR_CODE",
      "CLIENT_P_BAR_CODE",
    ];

    let newErrors = { ...errors };
    // Check if the field is in the list of numeric fields
    if (name === "SUPPLIER_PROD_NUM") {
      if (value.trim() !== "") {
        setErrors((prevErrors) => ({
          ...prevErrors,
          SUPPLIER_PROD_NUM: "",
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          SUPPLIER_PROD_NUM: "please enter product id",
        }));
      }
    }

    if (name === "SUPP_DUN_BAR_CODE") {
      if (value.trim() !== "") {
        setErrors((prevErrors) => ({
          ...prevErrors,
          SUPP_P_BAR_CODE: "",
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          SUPP_P_BAR_CODE: "please enter supplier dun barcode",
        }));
      }
    }

    if (name === "CLIENT_PROD_NUM") {
      if (value.trim() !== "") {
        setErrors((prevErrors) => ({
          ...prevErrors,
          CLIENT_PROD_NUM: "",
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          CLIENT_PROD_NUM: "please enter manex id",
        }));
      }
    }

    if (name === "CLIENT_P_BAR_CODE") {
      if (value.trim() !== "") {
        setErrors((prevErrors) => ({
          ...prevErrors,
          CLIENT_P_BAR_CODE: "",
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          CLIENT_P_BAR_CODE: "please enter manex barcode number",
        }));
      }
    }

    if (name === "P_DESC") {
      if (value.trim() !== "") {
        setErrors((prevErrors) => ({
          ...prevErrors,
          P_DESC: "",
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          P_DESC: "please enter product description",
        }));
      }
    }

    if (name === "PRICE_PER_PCS") {
      if (value.trim() !== "") {
        setErrors((prevErrors) => ({
          ...prevErrors,
          PRICE_PER_PCS: "",
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          PRICE_PER_PCS: "please enter value",
        }));
      }
    }
    if (name === "QTY_PER_CTN") {
      if (value.trim() !== "") {
        setErrors((prevErrors) => ({
          ...prevErrors,
          QTY_PER_CTN: "",
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          QTY_PER_CTN: "please enter value",
        }));
      }
    }

    if (name === "P_PKG_TYPE") {
      if (value.trim() !== "") {
        setErrors((prevErrors) => ({
          ...prevErrors,
          P_PKG_TYPE: "",
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          P_PKG_TYPE: "please enter packing",
        }));
      }
    }

    if (name === "P_LENGTH") {
      if (value.trim() !== "") {
        setErrors((prevErrors) => ({
          ...prevErrors,
          P_LENGTH: "",
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          P_LENGTH: "please enter length",
        }));
      }
    }

    if (name === "P_WIDTH") {
      if (value.trim() !== "") {
        setErrors((prevErrors) => ({
          ...prevErrors,
          P_WIDTH: "",
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          P_WIDTH: "please enter width",
        }));
      }
    }

    if (name === "P_HEIGHT") {
      if (value.trim() !== "") {
        setErrors((prevErrors) => ({
          ...prevErrors,
          P_HEIGHT: "",
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          P_HEIGHT: "please enter height",
        }));
      }
    }

    if (name === "P_COLOR") {
      if (value.trim() !== "") {
        setErrors((prevErrors) => ({
          ...prevErrors,
          P_COLOR: "",
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          P_COLOR: "please enter color",
        }));
      }
    }

    if (name === "P_MATERIAL") {
      if (value.trim() !== "") {
        setErrors((prevErrors) => ({
          ...prevErrors,
          P_MATERIAL: "",
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          P_MATERIAL: "please enter material",
        }));
      }
    }

    if (name === "C_PKG_TYPE") {
      if (value.trim() !== "") {
        setErrors((prevErrors) => ({
          ...prevErrors,
          C_PKG_TYPE: "",
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          C_PKG_TYPE: "please enter package type",
        }));
      }
    }

    if (name === "CTN_ID") {
      if (value.trim() !== "") {
        setErrors((prevErrors) => ({
          ...prevErrors,
          CTN_ID: "",
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          CTN_ID: "please enter CTN id",
        }));
      }
    }

    if (name === "CTN_NET_WT") {
      if (value.trim() !== "") {
        setErrors((prevErrors) => ({
          ...prevErrors,
          CTN_NET_WT: "",
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          CTN_NET_WT: "please enter net weight",
        }));
      }
    }

    if (name === "CTN_GROSS_WT") {
      if (value.trim() !== "") {
        setErrors((prevErrors) => ({
          ...prevErrors,
          CTN_GROSS_WT: "",
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          CTN_GROSS_WT: "please enter gross weight",
        }));
      }
    }

    if (name === "SUPP_DUN_BAR_CODE") {
      if (value.trim() !== "") {
        setErrors((prevErrors) => ({
          ...prevErrors,
          SUPP_DUN_BAR_CODE: "",
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          SUPP_DUN_BAR_CODE: "please enter supplier dun barcode",
        }));
      }
    }

    if (name === "CLIENT_DUN_BAR_CODE") {
      if (value.trim() !== "") {
        setErrors((prevErrors) => ({
          ...prevErrors,
          CLIENT_DUN_BAR_CODE: "",
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          CLIENT_DUN_BAR_CODE: "please enter manex dun barcode",
        }));
      }
    }

    // if (name === "SUPPLIER_PROD_NUM") {
    //   if (!value) {
    //     newErrors.SUPPLIER_PROD_NUM = "please enter supplier product id.";
    //   } else {
    //     delete newErrors.SUPPLIER_PROD_NUM;
    //   }
    // }

    if (numericFields.includes(name)) {
      e.preventDefault();

      if (numberRegex.test(value)) {
        setdata({
          ...data,
          [name]: value,
        });
      }
      setErrors(newErrors);

      return;
    }

    if (name === "P_DESC") {
      if (value.length <= maxCharCount) {
        setdata((prevData) => ({ ...prevData, [name]: value }));
        setCharCount(value.length);
      }
      return;
    }

    setdata({
      ...data,
      [name]: value,
    });
  };

  const handleSelectChange = (event) => {
    const { name, value } = event.target;
    setdata((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSelectChangeCTNTYPE = (event) => {
    const { name, value } = event.target;
    setdata((prevData) => ({
      ...prevData,
      CTN_ID: value,
    }));
  };

  const handleChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const selectedFile = e.target.files[0];
      setFiles((prevFiles) => [
        ...prevFiles,
        URL.createObjectURL(selectedFile),
      ]);
      setFileName(selectedFile.name);
      setImage((previmages) => [...previmages, selectedFile]);
    } else {
      console.error("No file selected");
    }
    console.log(files);
  };

  useEffect(() => {
    console.log("Updated images container:", image);
  }, [image]);
  const handleDeleteImage = (e, indexToDelete) => {
    setFiles(files.filter((_, index) => index !== indexToDelete));
    setImage(files.filter((_, index) => index !== indexToDelete));
    e.stopPropagation();
  };

  console.log(data.CTN_ID);
  const existingProductIDs = {
    SUPPLIER_PROD_NUM: ['SUPP001', 'SUPP002', 'SUPP003'], 
    CLIENT_PROD_NUM: ['MNX234', 'MNX235', 'MNX236'],      
  };

  const checkForExistingIDs = (supplierId, clientId) => {
    const errors = [];
    
    if (existingProductIDs.SUPPLIER_PROD_NUM.includes(supplierId)) {
      errors.push('Supplier Product ID already exists!');
    }
    
    if (existingProductIDs.CLIENT_PROD_NUM.includes(clientId)) {
      errors.push('Client Product ID already exists!');
    }
    
    return errors;
  };


  const handleSubmit = (e) => {
    // e.preventDefault();
    const newErrors = {};
    const supplierProdNum = data.SUPPLIER_PROD_NUM;
    const clientProdNum = data.CLIENT_PROD_NUM;

    const existingIDErrors = checkForExistingIDs(supplierProdNum, clientProdNum);
    existingIDErrors.forEach(error => toast.error(error, { position: "bottom-center" }));

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    const payload = {
      CLIENT_SUPPLIER_ID: data.CLIENT_SUPPLIER_ID,
      APP_CLIENT_ID: data.APP_CLIENT_ID,
      SUPPLIER_PROD_NUM: data.SUPPLIER_PROD_NUM,
      CLIENT_PROD_NUM: data.CLIENT_PROD_NUM,
      SUPP_P_BAR_CODE: data.SUPP_P_BAR_CODE,
      CLIENT_P_BAR_CODE: data.CLIENT_P_BAR_CODE,
      SUPP_DUN_BAR_CODE: data.SUPP_DUN_BAR_CODE,
      CLIENT_DUN_BAR_CODE: data.CLIENT_DUN_BAR_CODE,
      P_DESC: data.P_DESC,
      P_MATERIAL: data.P_MATERIAL,
      P_COLOR: data.P_COLOR,
      PRICE_PER_PCS: data.PRICE_PER_PCS,
      P_LENGTH: data.P_LENGTH,
      P_WIDTH: data.P_WIDTH,
      P_HEIGHT: data.P_HEIGHT,
      SIZE_UNIT: data.SIZE_UNIT,
      P_WT:
        data.CTN_NET_WT && data.QTY_PER_CTN
          ? parseFloat(data.CTN_NET_WT) / parseFloat(data.QTY_PER_CTN)
          : 0,
      P_WT_UNIT: data.P_WT_UNIT,
      VOLUME: data.VOLUME,
      VOLUME_UNIT: data.VOLUME_UNIT,
      P_PKG_TYPE: data.P_PKG_TYPE,
      CTN_ID: data.CTN_ID,
      C_PKG_TYPE: data.C_PKG_TYPE,
      QTY_PER_CTN: data.QTY_PER_CTN,
      CTN_NET_WT: data.CTN_NET_WT,
      CTN_GROSS_WT: data.CTN_GROSS_WT,
      CTN_WT_UNIT: data.CTN_WT_UNIT,
      PRIMARY_IMAGE_INDEX: data.PRIMARY_IMAGE_INDEX,
    };
    const API_URL = process.env.REACT_APP_API_URL;
    console.log("Payload", payload);
    // console.log("pwt", data.P_WT);
    // console.log("pctn", data.CTN_NET_WT, data.QTY_PER_CTN);

    // Function to check for missing required fields
    const checkRequiredFields = (payload) => {
      let missingFields = [];
      let isValid = true;

      if (!payload.CLIENT_SUPPLIER_ID) {
        missingFields.push("Client Supplier ID");
        isValid = false;
      }
      if (!payload.APP_CLIENT_ID) {
        missingFields.push("App Client ID");
        isValid = false;
      }
      if (!payload.SUPPLIER_PROD_NUM) {
        missingFields.push("Supplier Product Number");
        isValid = false;
      }

      if (!payload.SUPP_P_BAR_CODE) {
        missingFields.push("Supplier Product Bar Code");
        isValid = false;
      }
      if (!payload.CLIENT_SUPPLIER_ID) missingFields.push("Client Supplier ID");
      if (!payload.APP_CLIENT_ID) missingFields.push("App Client ID");
      if (!payload.SUPPLIER_PROD_NUM)
        missingFields.push("Supplier Product Number");
      if (!payload.CLIENT_PROD_NUM) missingFields.push("Client Product Number");
      if (!payload.SUPP_P_BAR_CODE)
        missingFields.push("Supplier Product Bar Code");
      if (!payload.CLIENT_P_BAR_CODE)
        missingFields.push("Client Product Bar Code");
      if (!payload.SUPP_DUN_BAR_CODE)
        missingFields.push("Supplier DUN Bar Code");
      if (!payload.CLIENT_DUN_BAR_CODE)
        missingFields.push("Client DUN Bar Code");
      if (!payload.P_DESC) missingFields.push("Product Description");

      if (
        payload.PRICE_PER_PCS === undefined ||
        payload.PRICE_PER_PCS === null ||
        payload.PRICE_PER_PCS === ""
      )
        missingFields.push("Price Per Piece");
      if (!payload.SIZE_UNIT) missingFields.push("Size Unit");
      if (payload.P_WT === undefined || payload.P_WT === null)
        missingFields.push("Product Weight");
      if (!payload.P_WT_UNIT) missingFields.push("Weight Unit");
      if (payload.VOLUME === undefined || payload.VOLUME === null)
        missingFields.push("Volume");
      if (!payload.VOLUME_UNIT) missingFields.push("Volume Unit");
      if (!payload.P_PKG_TYPE) missingFields.push("Package Type");
      if (!payload.CTN_ID) missingFields.push("Carton ID");
      if (!payload.C_PKG_TYPE) missingFields.push("Carton Package Type");
      if (payload.QTY_PER_CTN === undefined || payload.QTY_PER_CTN === null)
        missingFields.push("Quantity Per Carton");
      if (payload.CTN_NET_WT === undefined || payload.CTN_NET_WT === null)
        missingFields.push("Carton Net Weight");
      if (payload.CTN_GROSS_WT === undefined || payload.CTN_GROSS_WT === null)
        missingFields.push("Carton Gross Weight");
      if (!payload.CTN_WT_UNIT) missingFields.push("Carton Weight Unit");
      if (!payload.PRIMARY_IMAGE_INDEX)
        missingFields.push("Primary Image Index");

      return missingFields;
    };

    const missingFields = checkRequiredFields(payload);
    console.log("Missing Fields:", missingFields);

    if (missingFields.length > 0 || files.length == 0) {
      if (files.length === 0) {
        toast.error("Please select a product image", {
          position: "bottom-center",
        });
      } else if (files.length != 0) {
        if (missingFields.length > 0) {
          const errorMessage = `Please fill all the fields`;
          toast.error(errorMessage, {
            position: "bottom-center",
          });
        }
      }
    } else {
      const formdata = new FormData();
      formdata.append("mod", "Products");
      formdata.append("action", "add-product");
      formdata.append("payload", JSON.stringify(payload));
      image.forEach((file) => {
        formdata.append("files", file);
      });

      axios({
        method: "post",
        //  url: process.env.REACT_APP_API_URL,
        url: API_URL,
        data: formdata,
        headers: {
          "Content-Type": `multipart/form-data boundary=${formdata._boundary}`,
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {
          console.log(res, "Product added successfully");
          if (res.data.XscStatus === 1) {
            localStorage.setItem("PRODUCT_ID", res.data.XscData.PRODUCT_ID);
            toast.success(res.data.XscMessage);
            localStorage.setItem("PRODUCT_ID", res.data.XscData.PRODUCT_ID);
            navigate("/suppliers/supplier-info/product-info");
            localStorage.setItem("activeTab", "Products");
            console.log("uplasaoed chefkksndkfns");
          }
        })
        .catch((res) => {
          toast.error(res.XscMessage);
        });
    }
  };

  const handleCancel = () => {
    navigate("/suppliers/products");
  };

  return (
    <div id="prod_bg">
      <ToastContainer />
      <div
        id="suppliers_topbar_bg"
        style={{ paddingLeft: "0", paddingRight: "0" }}
      >
        <div className="container d-flex justify-content-between align-items-center">
          <div id="dashboard_sub_header">Add New Product</div>
        </div>
      </div>

      <div className="container">
        <div className="divider_heading " align="left">
          <div className="divider"></div>
          <div className="heading_images">Product Images</div>
        </div>

        <div className="images_upload_Container">
          <div className="col-12">
            <div className=" d-flex img-container-123 ">
              <div
                className="images_upload_Container"
                style={{
                  display: "flex",
                  flexDirection: "row", // Ensures images are in a row
                  flexWrap: "wrap", // Allows wrapping if there are many images
                }}
              >
                {files.length > 0 ? (
                  files.map((file, index) => (
                    <div
                      key={index}
                      id="product_upload_img_bg"
                      style={{
                        cursor: "pointer",
                        marginTop: 24,
                        marginLeft: index === 0 ? 5 : 30,
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          gap: "10px",
                          overflow: "hidden",
                        }}
                        className="image_upload_container1"
                      >
                        <div
                          className="image_container_upload"
                          onClick={(e) => handleDeleteImage(e, index)}
                          style={{
                            width: "28px",
                            height: "28px",
                            flexShrink: 0,
                            backgroundColor:
                              "var(--Schemes-On-Surface, #1B1B24)",
                            opacity: "0.5",
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                          >
                            <path
                              d="M8.00045 7.05767L11.3003 3.75781L12.2431 4.70062L8.94325 8.00047L12.2431 11.3003L11.3003 12.2431L8.00045 8.94327L4.70063 12.2431L3.75781 11.3003L7.05765 8.00047L3.75781 4.70062L4.70063 3.75781L8.00045 7.05767Z"
                              fill="white"
                            />
                          </svg>
                        </div>
                        <img
                          className="img_class_names"
                          src={file}
                          alt={`Uploaded file ${index + 1}`}
                          style={{ width: "145px" }}
                        />
                      </div>
                    </div>
                  ))
                ) : (
                  <div
                    id="product_upload_img_bg"
                    style={{
                      cursor: "pointer",
                      marginTop: 24,
                      marginLeft: 10,
                    }}
                  >
                    <div style={{ display: "flex", gap: "10px" }}>
                      <label
                        style={{ width: "40px" }}
                        htmlFor="file-input"
                        className="d-flex align-items-center"
                        id=""
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="40"
                          height="40"
                          viewBox="0 0 40 40"
                          fill="none"
                        >
                          <path
                            d="M34.9987 25V30H39.9987V33.3333H34.9987V38.3333H31.6654V33.3333H26.6654V30H31.6654V25H34.9987ZM35.0124 5C35.9254 5 36.6654 5.74158 36.6654 6.65567V21.6667H33.332V8.33333H6.66536V31.665L23.332 15L28.332 20V24.715L23.332 19.714L11.377 31.6667H23.332V35H4.98503C4.07211 35 3.33203 34.2585 3.33203 33.3443V6.65567C3.33203 5.74127 4.09088 5 4.98503 5H35.0124ZM13.332 11.6667C15.173 11.6667 16.6654 13.159 16.6654 15C16.6654 16.841 15.173 18.3333 13.332 18.3333C11.4911 18.3333 9.9987 16.841 9.9987 15C9.9987 13.159 11.4911 11.6667 13.332 11.6667Z"
                            fill="#5A5892"
                          />
                        </svg>
                      </label>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <TextInputWithAll
              name="file-input"
              type="file"
              onChange={handleChange}
              id="file-input"
              style={{ display: "none" }}
              accept="image/*"
              multiple
            />
          </div>

          <div className="add_more_images" align="left">
            {files.length >= 5 ? (
              <div></div>
            ) : (
              <div className="add_more_img124345">
                <svg
                  className="inner_add_svg"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M11 11V5H13V11H19V13H13V19H11V13H5V11H11Z"
                    fill="#635BFF"
                  />
                </svg>
                <label htmlFor="file-input" className="add_images_text">
                  Add More Images
                </label>
              </div>
            )}
          </div>

          <div className="select_primary_image">
            <label>Select Primary Image</label>
            <br />
            <select
              id="selectimage"
              name="PRIMARY_IMAGE_INDEX"
              value={data.PRIMARY_IMAGE_INDEX}
              onChange={handleSelectChange}
            >
              <option>Select Image</option>
              {files.map((file, index) => (
                <option key={index} value={index + 1}>
                  Image {index + 1}{" "}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="product_details_divider"></div>

        <div className="">
          <div className="divider_heading" align="left">
            <div className="divider"></div>
            <div className="heading_images">Product Details</div>
          </div>

          <form className="row product_details">
            <div className="col-5" id="add_supplier_input">
              <div className="position-relative" style={{ width: "380px" }}>
                <label>
                  Supplier Product ID<span style={{ color: "#950F27" }}>*</span>
                </label>
                <br />
                <TextInput
                  type="text"
                  name="SUPPLIER_PROD_NUM"
                  value={data.SUPPLIER_PROD_NUM}
                  onChange={handleInputChange}
                  autoComplete="off"
                />

                <div
                  id="remaining_characters"
                  className="position-absolute transalate-middle-x"
                >
                  {/* {charCount}/50 */}
                </div>
              </div>
              <div className="position-relative" style={{ width: "380px" }}>
                <label>
                  Manex Product ID<span style={{ color: "#950F27" }}>*</span>
                </label>
                <br />
                <TextInput
                  type="text"
                  name="CLIENT_PROD_NUM"
                  value={data.CLIENT_PROD_NUM}
                  onChange={handleInputChange}
                  autoComplete="off"
                  className={errors.CLIENT_PROD_NUM ? "error-input" : ""}
                />{" "}
                {errors.CLIENT_PROD_NUM && (
                  <span style={{ color: "red" }}>{errors.CLIENT_PROD_NUM}</span>
                )}
                {!data.CLIENT_PROD_NUM ? (
                  <div
                    id="remaining_characters"
                    style={{
                      fontWeight: 500,
                      color: "var(--primaryContainer, #635BFF)",
                    }}
                  >
                    Generate
                  </div>
                ) : (
                  <div id="remaining_characters">
                    <svg
                      onClick={() => {
                        handleInputChange({
                          target: { name: "CLIENT_PROD_NUM", value: "" },
                        });
                      }}
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 10.5858L9.17157 7.75736L7.75736 9.17157L10.5858 12L7.75736 14.8284L9.17157 16.2426L12 13.4142L14.8284 16.2426L16.2426 14.8284L13.4142 12L16.2426 9.17157L14.8284 7.75736L12 10.5858Z"
                        fill="#535260"
                      />
                    </svg>
                  </div>
                )}
              </div>{" "}
              <div
                className="position-relative pos123pos"
                style={{ width: "380px" }}
              >
                <label>
                  Product Description<span style={{ color: "#950F27" }}>*</span>
                </label>
                <br />
                <TextInput
                  // className="d8884215685"
                  type="text"
                  name="P_DESC"
                  value={data.P_DESC}
                  onChange={handleInputChange}
                  autoComplete="off"
                />
                <div id="remaining_characters" className="d9380549810">
                  {charCount}/{maxCharCount}
                </div>
              </div>
              <div>
                <label>
                  Pieces Per CTN<span style={{ color: "#950F27" }}>*</span>
                </label>
                <br />
                <TextInput
                  type="text"
                  name="QTY_PER_CTN"
                  value={data.QTY_PER_CTN}
                  onChange={handleInputChange}
                  autoComplete="off"
                  pattern="^[0-9]+$"
                />{" "}
              </div>
              <div className=" position-relative" style={{ width: "380px" }}>
                <label>
                  Product Length<span style={{ color: "#950F27" }}>*</span>
                </label>
                <br />
                <DropDownWithInput
                  options={lengthOptions}
                  inputProps={{
                    className: "packaging_input",
                    style: {
                      width: "310px",
                      marginRight: "0px",
                      border: "none",
                      background: "transparent",
                      marginTop: "0px",
                    },
                    type: "tel",
                    name: "P_LENGTH",
                    value: data.P_LENGTH,
                    onChange: handleInputChange,
                    autoComplete: "off",
                  }}
                  selectProps={{
                    className: "selectpackageing_type",
                    style: {
                      width: "55px",
                      border: "none",
                      background: "transparent",
                      outline: "none",
                      paddingLeft: "0px",
                    },
                    name: "SIZE_UNIT",
                    value: data.SIZE_UNIT,
                    onChange: handleSelectChange,
                  }}
                />
              </div>
              <div className="position-relative" style={{ width: "380px" }}>
                <label>
                  Product Height<span style={{ color: "#950F27" }}>*</span>
                </label>
                <br />
                <DropDownWithInput
                  options={lengthOptions}
                  inputProps={{
                    className: "packaging_input",
                    style: {
                      width: "310px",
                      marginRight: "0px",
                      border: "none",
                      background: "transparent",
                      marginTop: "0px",
                    },
                    pattern: /^[0-9]+$/,
                    name: "P_HEIGHT",
                    value: data.P_HEIGHT,
                    // onChange: (e) => {
                    //   const phoneRegex = /^[0-9]*$/; // Adjust regex for numbers only
                    //   if (phoneRegex.test(e.target.value)) {
                    //     handleInputChange(e); // Call your existing handler for valid input
                    //   }
                    // },
                    onChange: handleInputChange,
                    autoComplete: "off",
                  }}
                  selectProps={{
                    className: "selectpackageing_type",
                    style: {
                      width: "55px",
                      border: "none",
                      background: "transparent",
                      outline: "none",
                      paddingLeft: "0px",
                    },
                    name: "SIZE_UNIT",
                    value: data.SIZE_UNIT,
                    onChange: handleSelectChange,
                  }}
                />
              </div>
              <div>
                <label>Product Color</label>
                <br />
                <TextInput
                  type="text"
                  name="P_COLOR"
                  value={data.P_COLOR}
                  onChange={handleInputChange}
                  autoComplete="off"
                />
              </div>
            </div>
            <div className="col-5" id="add_supplier_input">
              <div>
                <label>
                  Supplier Product Barcode Number
                  <span style={{ color: "#950F27" }}>*</span>
                </label>
                <br />
                <TextInput
                  type="text"
                  name="SUPP_P_BAR_CODE"
                  value={data.SUPP_P_BAR_CODE}
                  onChange={handleInputChange}
                  autoComplete="off"
                  style={{
                    border: errors.SUPP_P_BAR_CODE
                      ? "2px solid red"
                      : "1px solid #ccc",
                  }}
                />
              </div>
              <div>
                <label>
                  Manex Product Barcode Number
                  <span style={{ color: "#950F27" }}>*</span>
                </label>
                <br />
                <TextInput
                  type="text"
                  name="CLIENT_P_BAR_CODE"
                  value={data.CLIENT_P_BAR_CODE}
                  onChange={handleInputChange}
                  autoComplete="off"
                />
              </div>
              <div className="position-relative" style={{ width: "380px" }}>
                <label>
                  Product Price Per Piece
                  <span style={{ color: "#950F27" }}>*</span>
                </label>
                <br />
                <TextInputWithAll
                  type="text"
                  style={{ paddingLeft: 30 }}
                  pattern="/^[0-9]+$/"
                  name="PRICE_PER_PCS"
                  value={data.PRICE_PER_PCS}
                  onChange={handleInputChange}
                  autoComplete="off"
                />

                <div
                  id="remaining_characters"
                  className="position-absolute translate-middle-x"
                  style={{
                    fontWeight: 500,
                    left: 9,
                    paddingLeft: 16,
                    paddingRight: 0,
                    width: 9,
                  }}
                >
                  ¥
                </div>
              </div>
              <div>
                <label>
                  Packaging<span style={{ color: "#950F27" }}>*</span>
                </label>
                <br />

                <input
                  type="text"
                  name="P_PKG_TYPE"
                  value={data.P_PKG_TYPE}
                  onChange={handleSelectChange}
                  autoComplete="off"
                />
              </div>
              <div className="position-relative" style={{ width: "380px" }}>
                <label>
                  Product Width<span style={{ color: "#950F27" }}>*</span>
                </label>
                <br />
                <DropDownWithInput
                  options={lengthOptions}
                  inputProps={{
                    className: "packaging_input",
                    style: {
                      width: "310px",
                      marginRight: "0px",
                      border: "none",
                      background: "transparent",
                      marginTop: "0px",
                    },
                    name: "P_WIDTH",
                    value: data.P_WIDTH,
                    // onChange: (e) => {
                    //   const phoneRegex = /^[0-9]*$/; // Adjust regex for numbers only
                    //   if (phoneRegex.test(e.target.value)) {
                    //     handleInputChange(e); // Call your existing handler for valid input
                    //   }
                    // },
                    onChange: handleInputChange,
                    autoComplete: "off",
                  }}
                  selectProps={{
                    className: "selectpackageing_type",
                    style: {
                      width: "55px",
                      border: "none",
                      background: "transparent",
                      outline: "none",
                      paddingLeft: "0px",
                    },
                    name: "SIZE_UNIT",
                    value: data.SIZE_UNIT,
                    onChange: handleSelectChange,
                  }}
                />
              </div>
              <div className="position-relative" style={{ width: "380px" }}>
                <label>Product Volume</label>
                <br />
                <DropDownWithInput
                  options={volumeOptions}
                  inputProps={{
                    className: "packaging_input",
                    style: {
                      width: "310px",
                      marginRight: "0px",
                      border: "none",
                      background: "transparent",
                      marginTop: "0px",
                    },
                    name: "VOLUME",
                    value: data.VOLUME,
                    // onChange: (e) => {
                    //   const phoneRegex = /^[0-9]*$/; // Adjust regex for numbers only
                    //   if (phoneRegex.test(e.target.value)) {
                    //     handleInputChange(e); // Call your existing handler for valid input
                    //   }
                    // },
                    onChange: handleInputChange,
                    autoComplete: "off",
                  }}
                  selectProps={{
                    className: "selectpackageing_type",
                    style: {
                      width: "60px",
                      border: "none",
                      background: "transparent",
                      outline: "none",
                      paddingLeft: "0px",
                    },
                  }}
                />
              </div>
              <div>
                <label>Product Material</label>
                <br />
                <TextInput
                  type="text"
                  name="P_MATERIAL"
                  value={data.P_MATERIAL}
                  onChange={handleInputChange}
                  autoComplete="off"
                />
              </div>
            </div>
          </form>

          <div className="product_ctns_details_divider"></div>
        </div>

        <div style={{ paddingBottom: 60 }}>
          <div className="divider_heading" align="left">
            <div className="divider"></div>
            <div className="heading_images">CTNS Details</div>
          </div>

          <form className="row product_details">
            <div className="col-5" id="add_supplier_input">
              <div className="position-relative" style={{ width: "380px" }}>
                <label>Package Type</label>
                <br />
                <input
                  type="text"
                  name="C_PKG_TYPE"
                  value={data.C_PKG_TYPE}
                  onChange={handleInputChange}
                  autoComplete="off"
                />
              </div>
              <div className="position-relative" style={{ width: "380px" }}>
                <label>
                  Net Weight<span style={{ color: "#950F27" }}>*</span>
                </label>
                <br />
                <DropDownWithInput
                  options={weightOptions}
                  inputProps={{
                    className: "packaging_input",
                    style: {
                      width: "315px",
                      marginRight: "0px",
                      border: "none",
                      background: "transparent",
                      marginTop: "0px",
                    },
                    name: "CTN_NET_WT",
                    value: data.CTN_NET_WT,
                    onChange: handleInputChange,
                    autoComplete: "off",
                  }}
                  selectProps={{
                    className: "selectpackageing_type",
                    style: {
                      width: "50px",
                      border: "none",
                      background: "transparent",
                      outline: "none",
                      paddingLeft: "0px",
                    },
                    name: "CTN_WT_UNIT",
                    value: data.CTN_WT_UNIT,
                    onChange: handleSelectChange,
                  }}
                />
              </div>
              <div>
                <label>
                  Supplier DUN Barcode Number
                  <span style={{ color: "#950F27" }}>*</span>
                </label>
                <br />
                <TextInput
                  type="text"
                  name="SUPP_DUN_BAR_CODE"
                  value={data.SUPP_DUN_BAR_CODE}
                  onChange={handleInputChange}
                  autoComplete="off"
                />
              </div>
            </div>
            <div className="col-5" id="add_supplier_input">
              <div>
                <label>
                  CTN Type <span style={{ color: "#950F27" }}>*</span>
                </label>
                <br />
                <select
                  name="CTN_ID"
                  value={data.CTN_ID}
                  // value=""
                  onChange={handleSelectChangeCTNTYPE}
                  style={{
                    width: "380px",
                    height: "48px",
                    border: "1px solid var(--Palettes-Neutral-90, #E5E1E6",
                    borderRadius: "8px",
                    marginTop: "6px",
                    padding: "10px",
                  }}
                >
                  <option value="">Select CTN Type</option>
                  {ctnTypes.map((type) => (
                    <option key={type.ID} value={type.ID}>
                      {type.ALIAS_NAME}
                    </option>
                  ))}
                </select>
              </div>
              <div className="position-relative" style={{ width: "380px" }}>
                <label>
                  Gross Weight<span style={{ color: "#950F27" }}>*</span>
                </label>
                <br />
                <DropDownWithInput
                  options={weightOptions}
                  inputProps={{
                    className: "packaging_input",
                    style: {
                      width: "315px",
                      marginRight: "0px",
                      border: "none",
                      background: "transparent",
                      marginTop: "0px",
                    },
                    name: "CTN_GROSS_WT",
                    value: data.CTN_GROSS_WT,
                    onChange: handleInputChange,
                    autoComplete: "off",
                  }}
                  selectProps={{
                    className: "selectpackageing_type",
                    style: {
                      width: "50px",
                      border: "none",
                      background: "transparent",
                      outline: "none",
                      paddingLeft: "0px",
                    },
                    name: "CTN_WT_UNIT",
                    value: data.CTN_WT_UNIT,
                    onChange: handleSelectChange,
                  }}
                />
              </div>
              <div>
                <label>
                  Manex DUN Barcode Number
                  <span style={{ color: "#950F27" }}>*</span>
                </label>
                <br />
                <TextInput
                  type="text"
                  name="CLIENT_DUN_BAR_CODE"
                  value={data.CLIENT_DUN_BAR_CODE}
                  onChange={handleInputChange}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className="product_footer">
        <div className="prod_footer_btns">
          <a
            href="/suppliers/supplier-info"
            onClick={() => localStorage.setItem("activeTab", "Products")}
          >
            <button id="cancel">Cancel</button>
          </a>

          <button className="add_btn" onClick={handleSubmit}>
            Add Product
          </button>
        </div>
      </div>
      <ToastContainer
        position="bottom-center"
        style={{ width: "max-content" }}
      />
    </div>
  );
};

export default AddProductC;
