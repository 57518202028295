import React, { useEffect, useState, useRef } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { makeStyles } from "@material-ui/styles";
import "../../../Styles/AccountPayableTable.css";
import excelDownload from "../../../assets/excelDownload.png";
import pdfDownload from "../../../assets/pdfDownload.png";
import { makeApiCall } from "../../../StaticComponents/API";
import { useSearchParams } from "react-router-dom";
import CurrencyFormatter from "../../../StaticComponents/FormatNumber";
import TimestampToDate from "../../../StaticComponents/DateFormat";
import nodataimage2 from "../../../assets/no_data_image_2.png";
const useStyles = makeStyles({
  header: {
    "& .MuiDataGrid-columnHeaderTitle": {
      fontWeight: "bold",
    },
    "& .MuiDataGrid-menuIcon": {
      display: "none",
    },

    "& .MuiCheckbox-root": {
      color: "var(--onSurface)",
    },
  },

  row: {
    "& .MuiCheckbox-root": {
      color: "var(--onSurface)",
    },

    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "var(--size-140) !important",
  },

  headerDownloadPdf: {
    minWidth: "218px !important",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontSize: "14px",
    paddingLeft: "75px !important",
  },

  headerDownloadExcel: {
    minWidth: "158px !important",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontSize: "14px",
  },

  headerARSelected: {
    minWidth: "173px !important",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontSize: "14px",
    marginLeft: "-70px",
  },

  padding: {
    paddingLeft: "75px !important",
  },
});

export default function SupplierReportTable(props) {
  const API_URL = process.env.REACT_APP_API_URL;
  const APP_CLIENT_ID = localStorage.getItem("CLIENT_ID");
  const [pageSize, setPageSize] = useState(10);
  const [page, setpage] = useState(0);
  const handlepagechange = (newpage) => {
    setpage(newpage);
  };
  const [optionShow, setOptionShow] = useState(false);
  const [optionPosition, setOptionPosition] = useState({ top: 0, left: 0 });
  const ellipsisRef = useRef(null);
  const [currentStatus, setCurrentStatus] = useState("");
  const [columns, setColumns] = useState([]);
  const [show, setShow] = useState(false);
  const [selection, setSelection] = useState([]);
  const [rowCount, setrowCount] = useState(0);
  let [searchParams] = useSearchParams();
  const [dueAmount,setdueAmount]=useState(0)
  const CLIENT_SUPPLIER_ID = localStorage.getItem("CLIENT_SUPPLIER_ID");
  const payload = {
    CLIENT_SUPPLIER_ID: CLIENT_SUPPLIER_ID,
    APP_CLIENT_ID: APP_CLIENT_ID,
  };

  let token = localStorage.getItem("token");

  const handleEllipsisClick = (e, status) => {
    const rect = e.target.getBoundingClientRect();
    setOptionPosition({
      top: rect.bottom + window.scrollY,
      left: rect.left + window.scrollX,
    });
    setOptionShow(true);
    setCurrentStatus(status);
  };

  const classes = useStyles();

  const initialColumns = [
    {
      field: "INVOICE_ID",
      headerName: "Order No",
      width: 120,
      height: 80,
      cellClassName: "sticky-column",
    },
    {
      field: "TOTAL_ITEM",
      headerName: "Total Item",
      width: 150,
      editable: false,
    },
    {
      field: "TOTAL_CTNS",
      headerName: "Total CTNS",
      width: 150,
      editable: false,
    },
    {
      field: "CREATED_ON",
      headerName: "Created On",
      width: 175,
      renderCell: (params) => <TimestampToDate timestamp={params.value} />,
      editable: false,
    },
    {
      field: "TOTAL_AMOUNT",
      headerName: "Total Amount",
      width: 175,
      // renderCell: (params) => <CurrencyFormatter amount={params.value} />,
      editable: false,
    },
    {
      field: "PENDING_AMOUNT",
      headerName: "Pending Amount",
      width: 200,
      editable: false,
    },
    {
      field: "PAYMENT_STATUS",
      headerName: "Payment Status",
      width: 166,
      editable: false,
      renderCell: (e) => (
        <div
          className="paymentStatus"
          style={{
            color: `${
              e.row.PAYMENT_STATUS === "Unpaid" ? "#683a3a" : "#3a6c29"
            }`,
            background: `${
              e.row.PAYMENT_STATUS === "Unpaid" ? "#e69292" : "#a7e592"
            }`,
          }}
        >
         {e.row.PAYMENT_STATUS}
        </div>
      ),
    },
  ];

  const additionalColumns = [
    {
      field: "INVOICE_ID",
      headerName: "",
      width: 150,
      cellClassName: classes.padding,
      headerClassName: classes.headerDownloadPdf,
      align: "left",
      renderHeader: () => (
        <div
          className="d-flex flex-row align-items-center p-0 m-0"
          style={{
            gap: "8px",
            // width: 200,
            height: "20px",
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            paddingLeft: "0px !important",
          }}
          onClick={handlepdfdownload}
        >
          <div>
            <img src={pdfDownload} alt="Download PDF" />
          </div>
          <span style={{ color: "#635bff" }} id="dwn_text">
            Download Pdf
          </span>
        </div>
      ),
      sortable: false,
    },
    {
      field: "TOTAL_ITEM",
      headerName: "",
      width: 120,
      editable: false,
      align: "left",
      headerClassName: classes.headerDownloadExcel,
      renderHeader: () => (
        <div
          className="d-flex flex-row align-items-center"
          style={{
            gap: "8px",
            // width: 200,
            height: "20px",
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
          }}
          onClick={handleexceldownload}
        >
          <div>
            <img src={excelDownload} alt="Download Excel" />
          </div>
          <span style={{ color: "#635bff" }} id="dwn_text">
            Download Excel
          </span>
        </div>
      ),
      sortable: false,
    },
    {
      field: "TOTAL_CTNS",
      headerName: "",
      width: 150,
      editable: false,
      headerAlign: "left",
      renderHeader: () => <div></div>,
      sortable: false,
    },
    {
      field: "CREATED_ON",
      headerName: "",
      width: 175,
      editable: false,
      headerAlign: "left",
      renderHeader: () => <div></div>,
      renderCell: (params) => <TimestampToDate timestamp={params.value} />,
      sortable: false,
    },
    {
      field: "TOTAL_AMOUNT",
      headerName: "",
      width: 175,
      editable: false,
      headerAlign: "left",
      renderHeader: () => <div></div>,
      // renderCell: (params) => <CurrencyFormatter amount={params.value} />,
      sortable: false,
    },
    {
      field: "PENDING_AMOUNT",
      headerName: "",
      width: 200,
      editable: false,
      headerAlign: "left",
      renderHeader: () => <div></div>,
      sortable: false,
    },
    {
      field: "PAYMENT_STATUS",
      headerName: "Payment Status",
      width: 166,
      editable: false,
      sortable:false,
      renderHeader: () => <div></div>,
    },

    {
      field: "VERTICAL_ELLIPSIS",
      width: 25,
      editable: false,
      align: "left",
      sortable: false,
      headerClassName: classes.headerARSelected,
      headerAlign: "left",
      renderHeader: () => <div></div>,
      renderCell: (params) => (
        <div
          style={{ cursor: "pointer" }}
          ref={ellipsisRef}
          onClick={(e) => handleEllipsisClick(e, params.row.PAYMENT_STATUS)}
        >
          {params.row.PAYMENT_STATUS === "Unpaid" ? params.value : ""}
        </div>
      ),
    },
  ];

  const [rows, setRows] = React.useState([]);
 
  const handleSelectionChange = (newSelection) => {
    setSelection(newSelection);
  };
  useEffect(() => {
    const payload = {
      APP_CLIENT_ID: APP_CLIENT_ID,
      SEARCH_KEYWORD: props.searchQuery,
      CURRENT_PAGE: page + 1,
      ITEM_PER_PAGE: pageSize,
      CLIENT_SUPPLIER_ID: CLIENT_SUPPLIER_ID,
    };

    console.log("Request Payload:", payload);

    makeApiCall({
      mod: "Supplier",
      action: "suppliers_report",
      payload: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        console.log("API Response:", response);
        setrowCount(response.XscData.TOTAL_RECORDS);
        console.log(props.searchQuery);
        console.log(payload);
        const reportItem = response?.XscData?.ORDER_LIST;
        if (Array.isArray(reportItem) && reportItem.length > 0) {
          const mappedRows = reportItem.map((item) => ({
            id: item.INVOICE_ID,
            INVOICE_ID: item.ORDER_NUMBER,
            TOTAL_ITEM: item.TOTAL_ITEM,
            TOTAL_CTNS: item.TOTAL_CTN,
            CREATED_ON: item.CREATED_ON,
            TOTAL_AMOUNT: `${item.INVOICE_CURRENCY_SYMBOL}${item.TOTAL_AMOUNT}`,
            PENDING_AMOUNT: `${item.INVOICE_CURRENCY_SYMBOL}${item.DUE_AMOUNT}`,
            PAYMENT_STATUS: item.PAYMENT_STATUS === 1 ? "Paid" : "Unpaid",
          }));
          setRows(mappedRows);
          const totalorders = response.XscData.TOTAL_ORDERS;
          const dueAmount=response.XscData.DUE_AMOUNT
          setdueAmount(response.XscData.DUE_AMOUNT)
          props.onUpdateTotals(totalorders, dueAmount);
        } else {
          setRows([]);
          console.error("Reports data is empty or invalid.");
        }
      })
      .catch((error) => {
        console.error("Error fetching report data:", error);
      });
  }, [APP_CLIENT_ID, props.searchQuery, props.onUpdateTotals, pageSize, page]);

  const handleFileDownload = (url, fileName) => {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = fileName;
        link.click();
      })
      .catch((error) => console.error("Error downloading file:", error));
  };

  const handlepdfdownload = () => {
    const payload = {
      APP_CLIENT_ID: APP_CLIENT_ID,
      CLIENT_SUPP_INV_ID: selection,
      CLIENT_SUPP_ID: CLIENT_SUPPLIER_ID,
    };
    console.log(payload, "payload");
    makeApiCall({
      mod: "Report",
      action: "generate_supplier_order_report_pdf",
      payload: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        console.log("AR report pdf fetched", response);
        if (response.XscStatus === 1) {
          const url = `${API_URL}/${response.XscData.URL}`;
          handleFileDownload(url, "supplier_report.pdf");
        }
      })
      .catch((error) => {
        console.error("Error fetching PDF:", error);
      });
  };

  const handleexceldownload = () => {
    const payload1 = {
      APP_CLIENT_ID: APP_CLIENT_ID,
      CLIENT_SUPP_INV_ID: selection,
      CLIENT_SUPP_ID: CLIENT_SUPPLIER_ID,
    };
    console.log(selection, "id");
    makeApiCall({
      mod: "Report",
      action: "generate_supplier_order_report_excel",
      payload: payload1,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        console.log("request", payload1);
        console.log("AR report excel fetched", response);

        if (response.XscStatus === 1) {
          const url = `${API_URL}/${response.XscData.URL}`;
          fetch(url)
            .then((response) => response.blob())
            .then((blob) => {
              const link = document.createElement("a");
              link.href = URL.createObjectURL(blob);
              link.download = "supplier_report.xlsx";
              link.click();
            });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  console.log("rows selected", selection);

  useEffect(() => {
    if (selection.length > 0) {
      setColumns(additionalColumns);
    } else {
      setColumns(initialColumns);
    }
  }, [selection]);

  return (
    <>
      <div className="ap_dataGrid">
        <DataGrid
          localeText={{
            noRowsLabel: (
              <div className="no_rows_css">
                <img src={nodataimage2} alt="" />
                <div id="no_data_found_text">No reports have generated yet</div>
              </div>
            ),
          }}
          rows={rows}
          columns={columns}
          pageSize={pageSize}
          rowCount={rowCount}
          rowsPerPageOptions={[10, 20, 50]}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          onPageChange={handlepagechange}
          disableSelectionOnClick
          classes={{ columnHeader: classes.header, row: classes.row }}
          checkboxSelection
          onSelectionModelChange={(newSelection) =>
            handleSelectionChange(newSelection)
          }
        />
      </div>
    </>
  );
}
