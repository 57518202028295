import { DataGrid } from "@mui/x-data-grid";
import React from "react";

function ImportProductTable({ importProductData, isLoading }) {
  const columns = [
    {
      field: "PRODUCT_ID",
      headerName: "Product ID",
      width: 180,
      editable: false,
      align: "left",
      height: 80,
    },
    {
      field: "SUPPLIER_PRODUCT_ID",
      headerName: "Supplier Product ID",
      width: 250,
      editable: false,
      align: "left",
      height: 80,
    },
    {
      field: "PRODUCT_DESCRIPTION",
      headerName: "Product Description",
      type: "text",
      width: 250,
      editable: false,
      align: "left",
      height: 80,
    },
    {
      field: "PRODUCT_PRICE_PER_PIECE",
      headerName: "Product Price Per Piece",
      width: 250,
      editable: false,
      align: "left",
      height: 80,
    },
    {
      field: "PIECES_PER_CTN",
      headerName: "Pieces Per CTN",
      width: 250,
      editable: false,
      align: "left",
      height: 80,
    },
  ];
  console.log(importProductData, "import");
  return (
    <div
      style={{
        height: 600,
        width: "100%",
        marginBottom: "80px",
      }}
    >
      <DataGrid
        rows={importProductData}
        columns={columns}
        pagination
        paginationMode="server"
        // rowCount={50}
        rowsPerPageOptions={
          // [10, 20, 50]
          importProductData.length < 50
            ? [10, 20, 50] // Default options
            : importProductData.length > 20
            ? [10, 20, importProductData.length] // Adjust if less than 50
            : importProductData.length > 10
            ? [10, importProductData.length] // Adjust if less than 20
            : [importProductData.length] // Show all rows if less than 10
        }
        // onPageChange={handlepagechange}
        // onPageSizeChange={handlepagesizechange}
        loading={isLoading}
        disableSelectionOnClick
        style={{ cursor: "pointer" }}
      />
    </div>
  );
}

export default ImportProductTable;
