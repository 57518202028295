import React, { useEffect, useState } from "react";
import logo from "../assets/Manex Logo.png";
import "../Styles/Dashboard.css";
import DashboardMenu from "./DashboardMenu";
import Sidebar from "./DashboardMenu";
import { Link, Outlet, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import PersonAdd from "@mui/icons-material/PersonAdd";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import VpnKeyOutlinedIcon from "@mui/icons-material/VpnKeyOutlined";
import TextInputWithAll from "./Inputs/TextInputWithAll";

function DashboardLayout({ children }) {
  // const [menuShow, setMenuShow] = useState(false); // Start with the Offcanvas hidden
  // const [showFullMenu, setShowFullMenu] = useState(true); // Start with the full menu shown

  // const handleMenu = () => {
  //   // if (menuShow) {
  //   //   setShowFullMenu(false); // Toggle between showing full menu and only logos
  //   // } else {
  //   //   setMenuShow(true); // Show the Offcanvas if it's currently hidden
  //   //   setShowFullMenu(true); // Ensure the full menu is shown initially
  //   // }
  //   setMenuShow(!menuShow);
  //   setShowFullMenu(!showFullMenu)
  // };

  // console.log(menuShow, showFullMenu,"hiii")
  // const handleClose = () => setMenuShow(!menuShow);
  // const handleFullMenuClose = () => setShowFullMenu(!showFullMenu);
  const [isOpen, setIsOpen] = useState(true);
  const toggle = () => setIsOpen(!isOpen);
  const [activeTab, setActiveTab] = useState("home");
  const navigate = useNavigate();
  // useEffect(() => {
  //   if (!localStorage.getItem('token')) {
  //     navigate("/")
  //   }
  // }, [])
  const [showDropdown, setShowDropdown] = useState(false);
  const onTabChange = (tab) => {
    setActiveTab(tab);
  };
  const [anchorEl, setAnchorEl] = React.useState("");
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleProfile = () => {
    navigate("/profile");
  };
  const searchProducts = (e) => {
    const searchTerm = e.target.value;
    if (searchTerm) {
        navigate(`/search-products?search=${searchTerm}`);
    } else {
        navigate("/dashboard");
    }
};

  // window.document.querySelector("body").onclick(null)
  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/");
  };
  const handleChangePassword = () => {
    navigate("/changepassword");
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const lineStyle = {
    border: "none",
    height: "1px",
    width: "100%",
    backgroundColor: "#F3EFFA",
  };
  const [firstName, setFirstName] = useState(
    localStorage.getItem("FIRST_NAME") || ""
  );
  const [email, setEmail] = useState(localStorage.getItem("EMAIL") || "");
  const [profilePicture, setProfilePicture] = useState(
    localStorage.getItem("PROFILE_IMAGE") || ""
  );

  return (
    <>
      <div>
        <div id="db_header_bg">
          <div id="header_left_items">
            {" "}
            <div style={{ cursor: "pointer" }} onClick={toggle}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M3 4H21V6H3V4ZM3 11H21V13H3V11ZM3 18H21V20H3V18Z"
                  fill="var(--onSurface)"
                />
              </svg>
            </div>
            <div id="db_logo">
              <Link to="/dashboard">
                <img src={logo} alt="" />
              </Link>
            </div>
          </div>
          <div id="header_right_items">
            <div id="db_search_bar_bg">
              <div id="">
                <TextInputWithAll
                  className=""
                  placeholder="Search Products"
                  onChange={searchProducts}
                />
              </div>
              <div id="search_icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M12.0203 11.0778L14.8755 13.933L13.9327 14.8758L11.0775 12.0206C10.0509 12.842 8.74901 13.3333 7.33301 13.3333C4.02101 13.3333 1.33301 10.6453 1.33301 7.33331C1.33301 4.02131 4.02101 1.33331 7.33301 1.33331C10.645 1.33331 13.333 4.02131 13.333 7.33331C13.333 8.74931 12.8417 10.0512 12.0203 11.0778ZM10.6828 10.5832C11.498 9.74305 11.9997 8.59705 11.9997 7.33331C11.9997 4.75498 9.91134 2.66665 7.33301 2.66665C4.75467 2.66665 2.66634 4.75498 2.66634 7.33331C2.66634 9.91165 4.75467 12 7.33301 12C8.59674 12 9.74274 11.4983 10.5829 10.6831L10.6828 10.5832Z"
                    fill="var(--onSurface)"
                  />
                </svg>
              </div>
            </div>

            <div id="db_search_bar_bg">
              <a href="/all-orders">
                <div className="orders_div">
                  <svg
                    className="orders_svg"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M13.3333 14.6667H2.66667C2.29848 14.6667 2 14.3682 2 14V2.00004C2 1.63185 2.29848 1.33337 2.66667 1.33337H13.3333C13.7015 1.33337 14 1.63185 14 2.00004V14C14 14.3682 13.7015 14.6667 13.3333 14.6667ZM12.6667 13.3334V2.66671H3.33333V13.3334H12.6667ZM5.33333 4.66671H10.6667V6.00004H5.33333V4.66671ZM5.33333 7.33337H10.6667V8.66671H5.33333V7.33337ZM5.33333 10H8.66667V11.3334H5.33333V10Z"
                      fill="#1B1B24"
                    />
                    <path
                      d="M13.3333 14.6667H2.66667C2.29848 14.6667 2 14.3682 2 14V2.00004C2 1.63185 2.29848 1.33337 2.66667 1.33337H13.3333C13.7015 1.33337 14 1.63185 14 2.00004V14C14 14.3682 13.7015 14.6667 13.3333 14.6667ZM12.6667 13.3334V2.66671H3.33333V13.3334H12.6667ZM5.33333 4.66671H10.6667V6.00004H5.33333V4.66671ZM5.33333 7.33337H10.6667V8.66671H5.33333V7.33337ZM5.33333 10H8.66667V11.3334H5.33333V10Z"
                      fill="black"
                      fill-opacity="0.2"
                    />
                    <path
                      d="M13.3333 14.6667H2.66667C2.29848 14.6667 2 14.3682 2 14V2.00004C2 1.63185 2.29848 1.33337 2.66667 1.33337H13.3333C13.7015 1.33337 14 1.63185 14 2.00004V14C14 14.3682 13.7015 14.6667 13.3333 14.6667ZM12.6667 13.3334V2.66671H3.33333V13.3334H12.6667ZM5.33333 4.66671H10.6667V6.00004H5.33333V4.66671ZM5.33333 7.33337H10.6667V8.66671H5.33333V7.33337ZM5.33333 10H8.66667V11.3334H5.33333V10Z"
                      fill="black"
                      fill-opacity="0.2"
                    />
                    <path
                      d="M13.3333 14.6667H2.66667C2.29848 14.6667 2 14.3682 2 14V2.00004C2 1.63185 2.29848 1.33337 2.66667 1.33337H13.3333C13.7015 1.33337 14 1.63185 14 2.00004V14C14 14.3682 13.7015 14.6667 13.3333 14.6667ZM12.6667 13.3334V2.66671H3.33333V13.3334H12.6667ZM5.33333 4.66671H10.6667V6.00004H5.33333V4.66671ZM5.33333 7.33337H10.6667V8.66671H5.33333V7.33337ZM5.33333 10H8.66667V11.3334H5.33333V10Z"
                      fill="black"
                      fill-opacity="0.2"
                    />
                    <path
                      d="M13.3333 14.6667H2.66667C2.29848 14.6667 2 14.3682 2 14V2.00004C2 1.63185 2.29848 1.33337 2.66667 1.33337H13.3333C13.7015 1.33337 14 1.63185 14 2.00004V14C14 14.3682 13.7015 14.6667 13.3333 14.6667ZM12.6667 13.3334V2.66671H3.33333V13.3334H12.6667ZM5.33333 4.66671H10.6667V6.00004H5.33333V4.66671ZM5.33333 7.33337H10.6667V8.66671H5.33333V7.33337ZM5.33333 10H8.66667V11.3334H5.33333V10Z"
                      fill="black"
                      fill-opacity="0.2"
                    />
                  </svg>
                  <div className="all_orders_text">All Orders</div>
                </div>
              </a>
            </div>

            {/* <div id="db_header_menus">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M8.68637 4.00008L11.293 1.39348C11.6835 1.00295 12.3167 1.00295 12.7072 1.39348L15.3138 4.00008H19.0001C19.5524 4.00008 20.0001 4.4478 20.0001 5.00008V8.68637L22.6067 11.293C22.9972 11.6835 22.9972 12.3167 22.6067 12.7072L20.0001 15.3138V19.0001C20.0001 19.5524 19.5524 20.0001 19.0001 20.0001H15.3138L12.7072 22.6067C12.3167 22.9972 11.6835 22.9972 11.293 22.6067L8.68637 20.0001H5.00008C4.4478 20.0001 4.00008 19.5524 4.00008 19.0001V15.3138L1.39348 12.7072C1.00295 12.3167 1.00295 11.6835 1.39348 11.293L4.00008 8.68637V5.00008C4.00008 4.4478 4.4478 4.00008 5.00008 4.00008H8.68637ZM6.00008 6.00008V9.5148L3.5148 12.0001L6.00008 14.4854V18.0001H9.5148L12.0001 20.4854L14.4854 18.0001H18.0001V14.4854L20.4854 12.0001L18.0001 9.5148V6.00008H14.4854L12.0001 3.5148L9.5148 6.00008H6.00008ZM12.0001 16.0001C9.79094 16.0001 8.00008 14.2092 8.00008 12.0001C8.00008 9.79094 9.79094 8.00008 12.0001 8.00008C14.2092 8.00008 16.0001 9.79094 16.0001 12.0001C16.0001 14.2092 14.2092 16.0001 12.0001 16.0001ZM12.0001 14.0001C13.1047 14.0001 14.0001 13.1047 14.0001 12.0001C14.0001 10.8955 13.1047 10.0001 12.0001 10.0001C10.8955 10.0001 10.0001 10.8955 10.0001 12.0001C10.0001 13.1047 10.8955 14.0001 12.0001 14.0001Z"
                  fill="var(--onSurface)"
                />
              </svg>
            </div>
            <div id="db_header_menus">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M22 20H2V18H3V11.0314C3 6.04348 7.02944 2 12 2C16.9706 2 21 6.04348 21 11.0314V18H22V20ZM5 18H19V11.0314C19 7.14806 15.866 4 12 4C8.13401 4 5 7.14806 5 11.0314V18ZM9.5 21H14.5C14.5 22.3807 13.3807 23.5 12 23.5C10.6193 23.5 9.5 22.3807 9.5 21Z"
                  fill="var(--onSurface)"
                />
              </svg>
            </div> */}
            {/* <div >Y</div> */}
            <div
              sx={{ display: "flex", alignItems: "start", textAlign: "left" }}
            >
              <Box>
                <Tooltip title="Profile">
                  <IconButton
                    onClick={handleClick}
                    size="small"
                    // sx={{ ml: 2 }}
                    aria-controls={open ? "profile" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                  >
                    <Avatar id="db_profile_bg_main">
                    <img
                        src={`${process.env.REACT_APP_API_URL}/media/${profilePicture}`}
                        alt="Profile"
                        onError={(e) => {
                          e.target.onerror = null; // Prevent infinite loop if fallback also has an error
                          e.target.style.display = "none"; // Hide broken image
                          const fallbackDiv = document.createElement("div");
                          fallbackDiv.className = "profile-initial";
                          fallbackDiv.innerText = email.charAt(0).toUpperCase();
                          e.target.parentNode.appendChild(fallbackDiv);
                        }}

                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                      />
                    </Avatar>
                  </IconButton>
                </Tooltip>
              </Box>
              <Menu
                anchorEl={anchorEl}
                id="profile"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&::before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                <MenuItem id="dropdown_menu" onClick={handleProfile}>
                  {/* <div id="db_profile_bg"> */}
                    <div  id="db_profile_bg" >
                      <img
                        src={`${process.env.REACT_APP_API_URL}/media/${profilePicture}`}
                        alt="Profile"
                        onError={(e) => {
                          e.target.onerror = null; // Prevent infinite loop if fallback also has an error
                          e.target.style.display = "none"; // Hide broken image
                          const fallbackDiv = document.createElement("div");
                          fallbackDiv.className = "profile-initial";
                          fallbackDiv.innerText = email.charAt(0).toUpperCase();
                          e.target.parentNode.appendChild(fallbackDiv);
                          
                        }}

                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                      />
                    {/* </div> */}
                  </div>

                  {/* <div id="db_profile_bg">
                    {profilePicture !== null ? (
                   <div>
                    <img
                        src={`${process.env.REACT_APP_API_URL}/media/${profilePicture}`}
                        alt="Profile"
                      />
                   </div>
                    ) : (
                      <div className="profile-initial">
                      {email.charAt(1).toUpperCase()}
                    </div>
                    )}
                  </div> */}
                  <div id="name_div">{firstName}</div>

                  <div id="email_div">{email}</div>
                </MenuItem>

                <Divider />
                <div className="option-section">
                  <div
                    className="section-change-password"
                    onClick={handleChangePassword}
                  >
                    <div>
                      <svg
                        className="option-image"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M10.7577 11.8281L18.6066 3.97919L20.0208 5.3934L18.6066 6.80761L21.0815 9.28249L19.6673 10.6967L17.1924 8.22183L15.7782 9.63604L17.8995 11.7574L16.4853 13.1716L14.364 11.0503L12.1719 13.2423C13.4581 15.1837 13.246 17.8251 11.5355 19.5355C9.58291 21.4882 6.41709 21.4882 4.46447 19.5355C2.51184 17.5829 2.51184 14.4171 4.46447 12.4645C6.17493 10.754 8.81633 10.5419 10.7577 11.8281ZM10.1213 18.1213C11.2929 16.9497 11.2929 15.0503 10.1213 13.8787C8.94975 12.7071 7.05025 12.7071 5.87868 13.8787C4.70711 15.0503 4.70711 16.9497 5.87868 18.1213C7.05025 19.2929 8.94975 19.2929 10.1213 18.1213Z"
                          fill="#535260"
                        />
                      </svg>
                    </div>
                    <div className="option-text">Change Password</div>
                  </div>
                  <hr style={lineStyle}></hr>
                  <div className="section-logout" onClick={handleLogout}>
                    <div>
                      <svg
                        className="option-image"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M5 11H13V13H5V16L0 12L5 8V11ZM3.99927 18H6.70835C8.11862 19.2447 9.97111 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C9.97111 4 8.11862 4.75527 6.70835 6H3.99927C5.82368 3.57111 8.72836 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C8.72836 22 5.82368 20.4289 3.99927 18Z"
                          fill="#535260"
                        />
                      </svg>
                    </div>
                    <div className="option-text">Logout</div>
                  </div>
                  {/* <MenuItem onClick={handleChangePassword}>
                  <ListItemIcon>
                    <VpnKeyOutlinedIcon fontSize="small" />
                  </ListItemIcon>
                  Change Password
                </MenuItem>
                <MenuItem onClick={handleLogout}>
                  <ListItemIcon>
                    <Logout fontSize="small" />
                  </ListItemIcon>
                  Logout
                </MenuItem> */}
                </div>
              </Menu>
            </div>
          </div>
        </div>
        <div className="d-flex w-100">
          {" "}
          <div>
            <Sidebar toggle={toggle} data={isOpen} />
          </div>
          <div style={{ width: "100%" }}>{children}</div>
        </div>
      </div>
    </>
  );
}

export default DashboardLayout;
