import { Button, Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../Styles/AddPayment.css";
import { useEffect, useRef, useState, useSyncExternalStore } from "react";
import DatePicker from "react-datepicker";
import { CircleSpinner } from "react-spinners-kit";
import TextInputWithAll from "../Inputs/TextInputWithAll";
import DropDownInputWithoutLabel from "../Inputs/DropDownInputWithoutLabel";
import { makeApiCall } from "../../StaticComponents/API.js";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";

export default function EditPayment({ show, onHide }) {
  const [upload,setupload]=useState(false)
  const [paidAmount, setPaidAmount] = useState();
  const [transactionNo, setTransactionNo] = useState();
  const [paymentMethod, setPaymentMethod] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const fileInputRef = useRef(null);
  const uploadTimeoutRef = useRef(null);
  const [invoiceDetails, setInvoiceDetails] = useState({
    invoiceNo: "",
    invoiceAmount: "",
    dueAmount: "",
  });

  let token = localStorage.getItem("token");
  const invoiceID = localStorage.getItem("INVOICE_ID");
  const INV_AP_ID = localStorage.getItem("INV_AP_ID");
  const PaymentOptions = [
    { value: "CASH", label: "CASH" },
    { value: "ONLINE", label: "ONLINE" },
    { value: "CARD", label: "CARD" },
    { value: "T/T", label: "T/T" },
    { value: "OTHERS", label: "OTHERS" },
  ];

  useEffect(() => {
    const payload = { SUPP_INVOICE_ID: invoiceID, INV_AP_ID: INV_AP_ID };
    makeApiCall({
      mod: "Supplier",
      action: "pay_supplier_display",
      payload: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        console.log("request for EDIT PAYMENT", payload);
        console.log("Edit payment records fetched", response);
        if (response.XscStatus === 1) {
          const invoice = response.XscData.INVOICE_DETAILS;
          setInvoiceDetails({
            invoiceNo: invoice.INVOICE_ID,
            invoiceAmount: `¥${invoice.INVOICE_AMOUNT}`,
            dueAmount: `¥${invoice.DUE_AMOUNT}`,
          });
        }
        if (response.XscStatus === 1) {
          const transaction = response.XscData.TRANSACTION_DETAILS;
          setPaidAmount(transaction.AMOUNT_PAID);
          setTransactionNo(transaction.TRANSACTION_NO);
          setPaymentMethod(transaction.PAYMENT_METHOD);
          setStartDate(transaction.DATE_PAID);

          setFile(transaction.MM_FILE_NAME);
          setFileName(transaction.MM_FILE_NAME || "");
          console.log("transacton", response.XscData.TRANSACTION_DETAILS);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [show]);
  const API_URL=process.env.REACT_APP_API_URL
  const handleView = (e) => {
 
    if(upload===false){
      if (file) {
     
        const fileUrl = `${API_URL}media/${file}`; 
        window.open(fileUrl, '_blank');
      }
    }else{
      const fileUrl=URL.createObjectURL(file)
      window.open(fileUrl, '_blank');
    }
  };
  const handleamtChange = (e) => {
    const value = e.target.value;
    if (value === "" || /^\d+(\.\d{0,2})?$/.test(value)) {
      setPaidAmount(value);
    } else {
      return;
    }
  };

  const handletransnochange = (e) => {
    const value = e.target.value;
    if (value === "" || /^\d+$/.test(value)) {
      setTransactionNo(value);
    } else {
      return;
    }
  };

  const handleChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setIsLoading(true);
      console.log("Selected file:", e.target.files[0]);
      console.log("Selected file:", e.target.files[0].lastModifiedDate);
      setFileName(e.target.files[0].name);

      uploadTimeoutRef.current = setTimeout(() => {
        setFile(e.target.files[0]);
        setFileName(e.target.files[0].name);
        setIsLoading(false);
      }, 2000); 
    } else {
      console.error("No file selected");
    }
  };

  const handleImportCancel = () => {
    setFile(null);
    setFileName("");
    setIsLoading(false);

    if (uploadTimeoutRef.current) {
      clearTimeout(uploadTimeoutRef.current);
      uploadTimeoutRef.current = null;
    }

    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }

    console.log("File upload canceled");
  };

  console.log(paidAmount);
  const handleSubmit = () => {
    const amountPaid = parseFloat(paidAmount);
    const transactionId = parseInt(transactionNo);
    const dueAmount = parseFloat(
      invoiceDetails.dueAmount.replace("¥", "").replace(/,/g, "")
    );
    const payload = {
      INVOICE_ID: invoiceID,
      INV_AP_ID: INV_AP_ID,
      AMOUNT_PAID: amountPaid,
      PAYMENT_METHOD: paymentMethod,
      TRANSACTION_ID: transactionId,
    };

    if (!amountPaid || isNaN(amountPaid)) {
      toast.error("Please enter a valid amount.", {
        position: "bottom-center",
      });
      return;
    }

    if (amountPaid > dueAmount) {
      toast.error(
        "Error: Paid amount must be less than or equal to due amount.",
        {
          position: "bottom-center",
        }
      );
      return;
    }

    if (!paymentMethod) {
      toast.error("Please select a payment method.", {
        position: "bottom-center",
      });
      return;
    }
    if(!file){
        toast.error('Please upload a payment file.', {
          position: "bottom-center",
        });
        return;
      }
    console.log(payload);
    const formData = new FormData();
    formData.append("mod", "Supplier");
    formData.append("action", "pay_supplier_insert");
    formData.append("payload", JSON.stringify(payload));
    if (file) {
      formData.append("file", file);
    }
    console.log(formData.get("payload"));
    console.log(formData.get("file"));
    axios({
      method: "post",
      url: process.env.REACT_APP_API_URL,
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        console.log(res, "Edit Payment Added");
        if (res.data.XscStatus === 1) {
          toast.success("Edit Payment Added Successfully", {
            position: "bottom-center",
          });
          setTimeout(() => {
            localStorage.setItem("activeTab","Account Payable")
            window.location.reload();
            onHide();
          }, 1000);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

 
  return (
    <Modal
      show={show}
      onHide={onHide}
      centered
      className="add-custom-modal"
      size="lg"
    >
      <Modal.Body>
        <h2
          id="receive_payment"
          style={{ paddingLeft: "20px", paddingTop: "20px" }}
        >
          Pay Supplier
        </h2>
        <div
          style={{
            height: 700,
            width: "100%",
            marginBottom: "2px",
            paddingLeft: "20px",
            paddingRight: "20px",
          }}
        >
          <table>
            <tr>
              <th id="th">
                <span id="table_key">Invoice No</span>
              </th>
              <td id="td">
                <span id="table_value"> {invoiceDetails.invoiceNo}</span>
              </td>
            </tr>
            <tr>
              <th id="th">
                <span id="table_key">Invoice Amount</span>
              </th>
              <td id="td">
                <span id="table_value"> {invoiceDetails.invoiceAmount}</span>
              </td>
            </tr>
            <tr>
              <th id="th">
                <span id="table_key">Due Amount</span>
              </th>
              <td id="td">
                <span id="table_value"> {invoiceDetails.dueAmount}</span>
              </td>
            </tr>
            <tr>
              <th id="th">
                <span id="table_key">Paid Amount</span>
              </th>
              <td id="td">
                <span id="table_value">
                  <TextInputWithAll
                    style={{ width: 323, margin: 0 }}
                    value={paidAmount}
                    id="cust_div"
                    onChange={handleamtChange}
                  />
                </span>
              </td>
            </tr>
            <tr>
              <th id="th">
                <span id="table_key">Transaction No</span>
              </th>
              <td id="td">
                <span id="table_value">
                  <TextInputWithAll
                    style={{ width: 323, margin: 0 }}
                    value={transactionNo}
                    id="cust_div"
                    onChange={handletransnochange}
                  />
                </span>
              </td>
            </tr>
            <tr>
              <th id="th">
                <span id="table_key">Payment Type</span>
              </th>
              <td id="td">
                <DropDownInputWithoutLabel
                  options={PaymentOptions}
                  id="cust_div"
                  value={paymentMethod}
                  onChange={(e) => {
                    console.log("Selected payment method:", e);
                    setPaymentMethod(e.target.value);
                  }}
                  style={{ width: 323, margin: 0, padding: "12px 16px" }}
                  placeholder="Select Status"
                />
              </td>
            </tr>
            <tr>
              <th id="th">
                <span id="table_key">Payment Date</span>
              </th>
              <td id="td">
                <span id="table_value">
                  <DatePicker
                    id="cust_date"
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    dateFormat="dd-MMMM-yyyy"
                    style={{ width: 323, margin: 0 }}
                  />
                </span>
              </td>
            </tr>
            <tr>
              <th id="th">
                <span id="table_key">Upload Payment File</span>
              </th>
              <td id="td">
                <div className="d-flex flex-row align-items-start gap-2">
                  {isLoading ? (
                    <div id="upload_pi_file" style={{ width: 323 }}>
                      <div
                        className="d-flex flex-row align-items-center"
                        style={{ gap: 120 }}
                      >
                        <div id="upload_file_text">
                          <span
                            className="d-flex align-items-center"
                            style={{ gap: "4px" }}
                          >
                            <CircleSpinner
                              size={20}
                              loading={isLoading}
                              color="var(--primaryContainer)"
                            />
                            Uploading Your File
                          </span>
                          <div>{fileName}</div>
                        </div>
                        <svg
                          style={{
                            cursor: "pointer",
                          }}
                          onClick={handleImportCancel}
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                        >
                          <path
                            d="M8.00045 7.05767L11.3003 3.75781L12.2431 4.70062L8.94325 8.00047L12.2431 11.3003L11.3003 12.2431L8.00045 8.94327L4.70063 12.2431L3.75781 11.3003L7.05765 8.00047L3.75781 4.70062L4.70063 3.75781L8.00045 7.05767Z"
                            fill="#950F27"
                          />
                        </svg>
                      </div>
                    </div>
                  ) : (
                    <div id="import_supplier_bg">
                      {file ? (
                        <div>
                          <span id="table_value">
                            <div id="uploaded_file" style={{ gap: 110 }}>
                              <div
                                className="d-flex flex-row align-items-center gap-1"
                                style={{
                                  marginLeft: 16,
                                }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  viewBox="0 0 16 16"
                                  fill="none"
                                >
                                  <path
                                    d="M3.33333 2.66634H10V5.33301H12.6667V13.333H3.33333V2.66634ZM2.66567 1.33301C2.29833 1.33301 2 1.62904 2 1.99421V14.0051C2 14.3628 2.29651 14.6663 2.66227 14.6663H13.3377C13.7034 14.6663 14 14.3656 14 13.9947L13.9998 4.66634L10.6667 1.33301H2.66567ZM6.99993 4.99967C6.99993 6.05133 6.69613 7.29121 6.18329 8.43527C5.66858 9.58347 4.97429 10.5665 4.25016 11.1457L5.03643 12.2211C6.98807 10.9199 9.14887 10.0277 11.239 10.3263L11.5441 9.03387C9.76233 8.43994 8.33327 6.65963 8.33327 4.99967H6.99993ZM7.39993 8.98074C7.5782 8.58314 7.73613 8.17054 7.86913 7.75201C8.18353 8.23507 8.5702 8.67847 9.00673 9.06321C8.3522 9.18041 7.711 9.37274 7.09013 9.61807C7.19993 9.40921 7.3034 9.19621 7.39993 8.98074Z"
                                    fill="#1B1B24"
                                  />
                                  <path
                                    d="M3.33333 2.66634H10V5.33301H12.6667V13.333H3.33333V2.66634ZM2.66567 1.33301C2.29833 1.33301 2 1.62904 2 1.99421V14.0051C2 14.3628 2.29651 14.6663 2.66227 14.6663H13.3377C13.7034 14.6663 14 14.3656 14 13.9947L13.9998 4.66634L10.6667 1.33301H2.66567ZM6.99993 4.99967C6.99993 6.05133 6.69613 7.29121 6.18329 8.43527C5.66858 9.58347 4.97429 10.5665 4.25016 11.1457L5.03643 12.2211C6.98807 10.9199 9.14887 10.0277 11.239 10.3263L11.5441 9.03387C9.76233 8.43994 8.33327 6.65963 8.33327 4.99967H6.99993ZM7.39993 8.98074C7.5782 8.58314 7.73613 8.17054 7.86913 7.75201C8.18353 8.23507 8.5702 8.67847 9.00673 9.06321C8.3522 9.18041 7.711 9.37274 7.09013 9.61807C7.19993 9.40921 7.3034 9.19621 7.39993 8.98074Z"
                                    fill="black"
                                    fill-opacity="0.2"
                                  />
                                  <path
                                    d="M3.33333 2.66634H10V5.33301H12.6667V13.333H3.33333V2.66634ZM2.66567 1.33301C2.29833 1.33301 2 1.62904 2 1.99421V14.0051C2 14.3628 2.29651 14.6663 2.66227 14.6663H13.3377C13.7034 14.6663 14 14.3656 14 13.9947L13.9998 4.66634L10.6667 1.33301H2.66567ZM6.99993 4.99967C6.99993 6.05133 6.69613 7.29121 6.18329 8.43527C5.66858 9.58347 4.97429 10.5665 4.25016 11.1457L5.03643 12.2211C6.98807 10.9199 9.14887 10.0277 11.239 10.3263L11.5441 9.03387C9.76233 8.43994 8.33327 6.65963 8.33327 4.99967H6.99993ZM7.39993 8.98074C7.5782 8.58314 7.73613 8.17054 7.86913 7.75201C8.18353 8.23507 8.5702 8.67847 9.00673 9.06321C8.3522 9.18041 7.711 9.37274 7.09013 9.61807C7.19993 9.40921 7.3034 9.19621 7.39993 8.98074Z"
                                    fill="black"
                                    fill-opacity="0.2"
                                  />
                                  <path
                                    d="M3.33333 2.66634H10V5.33301H12.6667V13.333H3.33333V2.66634ZM2.66567 1.33301C2.29833 1.33301 2 1.62904 2 1.99421V14.0051C2 14.3628 2.29651 14.6663 2.66227 14.6663H13.3377C13.7034 14.6663 14 14.3656 14 13.9947L13.9998 4.66634L10.6667 1.33301H2.66567ZM6.99993 4.99967C6.99993 6.05133 6.69613 7.29121 6.18329 8.43527C5.66858 9.58347 4.97429 10.5665 4.25016 11.1457L5.03643 12.2211C6.98807 10.9199 9.14887 10.0277 11.239 10.3263L11.5441 9.03387C9.76233 8.43994 8.33327 6.65963 8.33327 4.99967H6.99993ZM7.39993 8.98074C7.5782 8.58314 7.73613 8.17054 7.86913 7.75201C8.18353 8.23507 8.5702 8.67847 9.00673 9.06321C8.3522 9.18041 7.711 9.37274 7.09013 9.61807C7.19993 9.40921 7.3034 9.19621 7.39993 8.98074Z"
                                    fill="black"
                                    fill-opacity="0.2"
                                  />
                                  <path
                                    d="M3.33333 2.66634H10V5.33301H12.6667V13.333H3.33333V2.66634ZM2.66567 1.33301C2.29833 1.33301 2 1.62904 2 1.99421V14.0051C2 14.3628 2.29651 14.6663 2.66227 14.6663H13.3377C13.7034 14.6663 14 14.3656 14 13.9947L13.9998 4.66634L10.6667 1.33301H2.66567ZM6.99993 4.99967C6.99993 6.05133 6.69613 7.29121 6.18329 8.43527C5.66858 9.58347 4.97429 10.5665 4.25016 11.1457L5.03643 12.2211C6.98807 10.9199 9.14887 10.0277 11.239 10.3263L11.5441 9.03387C9.76233 8.43994 8.33327 6.65963 8.33327 4.99967H6.99993ZM7.39993 8.98074C7.5782 8.58314 7.73613 8.17054 7.86913 7.75201C8.18353 8.23507 8.5702 8.67847 9.00673 9.06321C8.3522 9.18041 7.711 9.37274 7.09013 9.61807C7.19993 9.40921 7.3034 9.19621 7.39993 8.98074Z"
                                    fill="black"
                                    fill-opacity="0.2"
                                  />
                                </svg>
                                <p id="file_name">{fileName}</p>
                                <svg
                                  style={{
                                    cursor: "pointer",
                                  }}
                                  onClick={handleImportCancel}
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  viewBox="0 0 16 16"
                                  fill="none"
                                >
                                  <path
                                    d="M8.00045 7.05767L11.3003 3.75781L12.2431 4.70062L8.94325 8.00047L12.2431 11.3003L11.3003 12.2431L8.00045 8.94327L4.70063 12.2431L3.75781 11.3003L7.05765 8.00047L3.75781 4.70062L4.70063 3.75781L8.00045 7.05767Z"
                                    fill="#950F27"
                                  />
                                </svg>
                              </div>
                              <Button id="viewFile" onClick={handleView}>
                                <h2 id="view_text">View</h2>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  viewBox="0 0 16 16"
                                  fill="none"
                                >
                                  <path
                                    d="M10.6688 6.27614L4.93109 12.0139L3.98828 11.0711L9.72601 5.33333H4.66883V4H12.0021V11.3333H10.6688V6.27614Z"
                                    fill="#635BFF"
                                  />
                                </svg>
                              </Button>
                            </div>
                          </span>
                        </div>
                      ) : (
                        <div className="col-12">
                          <label
                            htmlFor="file-input"
                            className="d-flex align-items-center justify-content-center flex-column"
                            id="upload_pi_file"
                            style={{
                              width: 323,
                              cursor: "pointer",
                            }}
                          >
                            <div id="upload_csv_text">
                              {fileName && fileName}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <g opacity="0.4">
                                  <path
                                    d="M12 12.5858L16.2426 16.8284L14.8284 18.2426L13 16.415V22H11V16.413L9.17157 18.2426L7.75736 16.8284L12 12.5858ZM12 2C15.5934 2 18.5544 4.70761 18.9541 8.19395C21.2858 8.83154 23 10.9656 23 13.5C23 16.3688 20.8036 18.7246 18.0006 18.9776L18 17C18 13.6863 15.3137 11 12 11C8.7616 11 6.12243 13.5656 6.00414 16.7751L6 17L6.00039 18.9776C3.19696 18.7252 1 16.3692 1 13.5C1 10.9656 2.71424 8.83154 5.04648 8.19411C5.44561 4.70761 8.40661 2 12 2Z"
                                    fill="#635BFF"
                                  />
                                </g>
                              </svg>
                            </div>
                          </label>
                          <TextInputWithAll
                            name="file-input"
                            type="file"
                            onChange={handleChange}
                            id="file-input"
                            ref={fileInputRef}
                            style={{
                              display: "none",
                            }}
                            // accept=".csv"
                          />
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </td>
            </tr>
          </table>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div id="po_footer" className="pi_footer" style={{ border: "none" }}>
          <div id="add_supplier_buttons" className="container">
            {" "}
            <a
             
              className="text-decoration-none"
            onClick={onHide}
            >
              {" "}
              <div id="cancel">Cancel</div>
            </a>
            <div
              className="d-flex align-items-center justify-content-right "
              style={{ gap: "12px" }}
            >
              <a
                className="text-decoration-none"
              >
                <div id="submitsupplier" onClick={handleSubmit}>
                  Submit
                </div>
              </a>
            </div>
          </div>
        </div>
      </Modal.Footer>
      <ToastContainer position="bottom-center" />
    </Modal>
  );
}
