import React, { useEffect, useRef, useState } from "react";
import Filter from "../../StaticComponents/Filter";
import SearchBar from "../../StaticComponents/SearchBar";
import "../../Styles/CustomersOrdersTable.css";
import { DataGrid } from "@mui/x-data-grid";
import { Dropdown } from "react-bootstrap";
import CustomerOrderFilter from "./CustomerOrderFilter";
import DatePickerModal from "../Reports/DatePickerModal";
import { makeApiCall } from "../../StaticComponents/API";
import { useSearchParams } from "react-router-dom";
// import OrderFilter from "./OrderFilter";

const CustomersOrdersTable = () => {
  const APP_CLIENT_ID = localStorage.getItem("CLIENT_ID");
  let [searchParams] = useSearchParams();

  const [modalShow, setModalShow] = useState(false);

  const [pageSize, setPageSize] = React.useState(10); // Default page size
  const [page, setpage] = useState(0);
  const [optionShow, setOptionShow] = useState(false);
  const [optionPosition, setOptionPosition] = useState({ top: 0, left: 0 });
  const [currentStatus, setCurrentStatus] = useState("");
  const ellipsisRef = useRef(null);
  const CLIENT_CUST_ID = searchParams.get("id");
  const [total, setTotal] = React.useState(0);
  const [searchkeyword, setsearchkeyword] = useState("");
  const [supplierorders, setsupplierorders] = useState(0);
  localStorage.setItem("activeTab", "CustomersOrders");
  const onSearch = (value) => {
    setsearchkeyword(value);
  };
  const handlepagechange = (newpage) => {
    setpage(newpage);
  };
  const handleEllipsisClick = (e, status) => {
    const rect = e.currentTarget.getBoundingClientRect();
    setOptionPosition({
      top: rect.bottom + window.scrollY,
      left: rect.left + window.scrollX,
    });
    setOptionShow(true);
    setCurrentStatus(status); // Set the current status
  };
  const [notify, setnotify] = useState("");
  const onNotify = (value) => {
    setnotify(value);
  };

  const handleClickOutside = (event) => {
    if (ellipsisRef.current && !ellipsisRef.current.contains(event.target)) {
      setOptionShow(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const token = localStorage.getItem("token");
  const [customerordersDetails, setCustomerorderstDetails] = React.useState({
    xscdata: "",
  });

  const fetchCustomersOrders = () => {
    const payload = {
      APP_CLIENT_ID: APP_CLIENT_ID,
      CURRENT_PAGE: page + 1,
      ITEM_PER_PAGE: pageSize,
      SEARCH_KEYWORD: searchkeyword,
    };

    makeApiCall({
      mod: "Orders",
      action: "customer_orders_list",
      payload: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        setCustomerorderstDetails({
          xscdata: response.XscData,
        });
        console.log(response);
        const customerOrders = response.XscData.CUSTOMER_PO_DETAILS;
        if (Array.isArray(customerOrders)) {
          const mappedRows = customerOrders.map((order) => {
            let currentStatus;

            // Conditional logic for CURRENT_STATUS
            if (order.CURRENT_ORDER_STAGE === "Invoice") {
              if (order.INV_APPROVAL_STATUS == null) {
                currentStatus = "Pending Approval";
              } else if (order.INV_APPROVAL_STATUS === 0) {
                currentStatus = "Rejected";
              } else if (order.INV_APPROVAL_STATUS === 1) {
                if (order.PAYMENT_STATUS === 0) {
                  currentStatus = "Receive Payment";
                } else currentStatus = "Completed";
              }
            } else if (order.CURRENT_ORDER_STAGE === "Proforma Invoice") {
              if (order.PI_APPROVAL_STATUS === null) {
                currentStatus = "Pending Approval";
              } else if (order.PI_APPROVAL_STATUS === 0) {
                currentStatus = "Rejected";
              } else currentStatus = "Approved";
            } else {
              currentStatus = "";
            }
            return {
              id: order.PO_ID,
              cust_id: order.CUST_PO_ID,
              EMAIL: order.EMAIL,
              CUSTOMER_NAME: order.CUSTOMER_NAME,
              LOCATION: order.LOCATION,
              CURRENT_ORDER_STAGE: order.CURRENT_ORDER_STAGE,
              CURRENT_STATUS: currentStatus,
            };
          });
          setTotal(response.XscData.CUSTOMER_ORDERS);
          // setReceivedPo(response.XscData.TODAY_RECEIVED_PO)
          setRows(mappedRows); // Set rows after mapping
        } else {
          console.error("Product data is not an array or is missing");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    fetchCustomersOrders(); // Fetch products when the component mounts or searchTerm changes
  }, [searchkeyword, page]);
  const STATUS_CLASS_MAP = {
    "Pending Approval": "invoice_pending_approval_bg",
    Rejected: "invoice_reject_bg",
    Completed: "invoice_completed_status_bg", // Class for completed
    Approved: "invoice_completed_status_bg",
    "Receive Payment": "invoice_payment_due_bg", // Class for payment due
  };

  const columns = [
    {
      field: "cust_id",
      headerName: "Cust.ID",
      width: 150,
      cellClassName: "sticky-column",
    },
    {
      field: "CUSTOMER_NAME",
      headerName: "Customer Name",
      width: 210,
      cellClassName: "sticky-column",
    },
    {
      field: "EMAIL",
      headerName: "Email",
      width: 200,
      editable: false,
      align: "left",
    },

    {
      field: "LOCATION",
      headerName: "Location",
      type: "number",
      width: 150,
      editable: false,
      align: "left",
    },
    {
      field: "CURRENT_ORDER_STAGE",
      headerName: "Current Order Stage",
      width: 200,
      editable: false,
      align: "left",
    },
    {
      field: "CURRENT_STATUS",
      headerName: "Current Status",
      width: 200,
      editable: false,
      align: "left",
      renderCell: (e) => {
        // const status = e.value === "Receive Payment";
        const className = STATUS_CLASS_MAP[e.value] || "";
        return (
          <div
            className={className}
            // style={{
            //   color: isReceivePayment ? "#3A6C29" : "#68622C", // Text color
            //   background: isReceivePayment ? "#A7E592" : "#E6DE92", // Background color
            // }}
          >
            {e.value}
          </div>
        );
      },
    },
  ];

  const initialRows = [];

  const [rows, setRows] = React.useState(initialRows);

  const rowsWithIcons = rows.map((row) => ({
    ...row,
    // STATUS: createFavIcon(row.STATUS, () => handleFav(row.id)),
    // ACTION: createEditIcon(),
  }));
  return (
    <div id="supp_bg">
      <div className="container">
        {" "}
        <div className="row" id="sup_overview_heading">
          <div className="col-md-4">
            <div id="supp_title">
              Today - Received PO
              <div id="suppliers_count">
                {customerordersDetails.xscdata.TODAY_INVOICE_SENT}
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div id="supp_title">
              Today - Invoice Sent
              <div id="suppliers_count">
                {customerordersDetails.xscdata.TODAY_PO_RECEIVED}
              </div>
            </div>
          </div>
        </div>
        <div id="supp_filters">
          <div className="d-flex">
            <div>
              <SearchBar onSearch={onSearch} />
            </div>
            <div
              onClick={() => setModalShow(true)}
              style={{ cursor: "pointer" }}
            >
              <Filter notify={notify} />
            </div>
            <CustomerOrderFilter
              show={modalShow}
              onHide={() => setModalShow(false)}
              onNotify={onNotify}
            />

            <div style={{ cursor: "pointer" }}>
              <Dropdown>
                <Dropdown.Toggle
                  style={{
                    marginLeft: "12px",
                    width: "301px",
                    height: "40px",
                    backgroundColor: "var(--onSurfaceLowest)",
                    color: "var(--onSurface)",
                    border: "1px solid var(--outline1)",
                    borderRadius: "var(--cr---3)",
                  }}
                >
                  Today
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <DatePickerModal />
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>

          <div
            id="po_table"
            style={{
              height: 600,
              width: "100%",
              marginBottom: "80px",
              position: "relative",
              overflow: "hidden",
            }}
          >
            <DataGrid
              style={{ position: "relative", overflow: "hidden" }}
              rows={rowsWithIcons}
              columns={columns}
              pageSize={pageSize}
              page={page}
              onPageChange={handlepagechange}
              rowsPerPageOptions={[10, 20, 50]} // Options for number of rows per page
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              // checkboxSelection
              disableSelectionOnClick
              rowCount={total}
              pagination
              paginationMode="server"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomersOrdersTable;
