import React, { useEffect, useState } from "react";
import "../../Styles/AddSupplier.css";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { makeApiCall } from "../../StaticComponents/API";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import DropDownInput from "../Inputs/DropDownInput";
import TextInput from "../Inputs/TextInput";
import TextInputWithAll from "../Inputs/TextInputWithAll";
import { filePdfIcon } from "@progress/kendo-svg-icons";
import axios from "axios";

function AddSupplier() {
  const maxChars = 50;
  const [inputValue, setInputValue] = useState("");
  const [suppNameCharCount, setSuppNameCharCount] = useState(0);
  const [file, setFile] = useState();
  const [value, setValue] = useState();
  const [fileName, setFileName] = useState("");
  const [imageFile, setImageFile] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [states, setStates] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedCities, setSelectedCities] = useState([]);
  const [image, setImage] = useState(null);

  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");

  const initialValues = {
    supplierName: "",
    email: "",
    contactNumber: "",
    address1: "",
    address2: "",
    website: "",
    regNo: "",
    fax: "",
    country: "",
    city: "",
    state: "",
    postalCode: "",
    tax: "",
    suppStatus: "1",
  };
  const [data, setData] = useState(initialValues);

  const navigate = useNavigate();
  let token = localStorage.getItem("token");
  let clientID = localStorage.getItem("CLIENT_ID");

  const handleChangeStatus = (e) => {
    const { name, value } = e.target;

    // Convert 'Active' to 1 and 'Inactive' to 0
    const convertedValue =
      value === "Active" ? 1 : value === "Inactive" ? 0 : value;

    setData({
      ...data,
      [name]: convertedValue,
    });
    console.log(name);
  };

  const handleChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      console.log("Selected file:", e.target.files[0]);
      console.log("Selected file:", e.target.files[0].lastModifiedDate);
      setFile(URL.createObjectURL(e.target.files[0]));
      setImage(e.target.files[0]);
      setFileName(e.target.files[0].name);
    } else {
      console.error("No file selected");
    }
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    const phoneRegex = /^\d*$/;

    if (name === "contactNumber" && !phoneRegex.test(value)) {
      setData({
        ...data,
        [name]: data[name],
      });
    } else {
      setData({
        ...data,
        [name]: value,
      });
    }
    console.log(name);
  };

  const handleSuppNameInputChange = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
    if (name === "NAME") {
      setSuppNameCharCount(value.length);
    }
  };

  const handleCancel = () => {
    navigate(-1);
  };

  const handleStateChange = (event) => {
    const value = event.target.value;
    setSelectedState(value);
    setData({
      ...data,
      state: value,
    });
    makeApiCall({
      mod: "Location",
      action: "get-districts",
      payload: {
        STATE_ID: value,
        CURRENT_PAGE: "",
        SEARCH_KEYWORD: "",
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((res) => {
      console.log(res, value, "districts");
      const districts = res.XscData.DISTRICT_NAMES;
      if (Array.isArray(districts)) {
        const mappedRows = districts.map((district) => ({
          value: district.ID,
          // label: district.STATE,
        }));
        makeApiCall({
          mod: "Location",
          action: "get-cities",
          payload: {
            DISTRICT_ID: value,
            CURRENT_PAGE: "",
            SEARCH_KEYWORD: "",
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }).then((res) => {
          console.log(res, value, "cities");
          setCities(mappedRows);
          const cities = res.XscData.CITY_NAMES;
          if (Array.isArray(cities)) {
            const mappedRows = cities.map((city) => ({
              value: city.ID,
              label: city.CITY,
            }));

            setCities(mappedRows);
          }
          console.log(cities, "cities");
        });
      } else {
        console.error("Suppliers data is not an array or is missing");
      }
    });
  };

  useEffect(() => {
    const payload = { CURRENT_PAGE: "", SEARCH_KEYWORD: "" };

    makeApiCall({
      mod: "Location",
      action: "get-countries",
      payload: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        console.log("countries fetched", response);
        const countries = response.XscData.COUNTRY_NAMES;
        // console.log(countries)

        if (Array.isArray(countries)) {
          const mappedRows = countries.map((country) => ({
            value: country.ID,
            label: country.COUNTRY,
          }));

          setCountries(mappedRows);
        } else {
          console.error("Suppliers data is not an array or is missing");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  const handleCountryChange = (event) => {
    const value = event.target.value;
    setSelectedCountry(value);
    setData({
      ...data,
      country: value,
    });
    makeApiCall({
      mod: "Location",
      action: "get-states",
      payload: {
        COUNTRY_ID: value,
        CURRENT_PAGE: "",
        SEARCH_KEYWORD: "",
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((res) => {
      console.log(res, value, "states");
      const states = res.XscData.STATE_NAMES;
      if (Array.isArray(states)) {
        const mappedRows = states.map((state) => ({
          value: state.ID,
          label: state.STATE,
        }));

        setStates(mappedRows);
      } else {
        console.error("Suppliers data is not an array or is missing");
      }
    });
  };
  const handleCityChange = (event) => {
    const value = event.target.value;
    setSelectedCities(value);
    setData({
      ...data,
      city: value,
    });
  };

  // const handleSubmit = (e) => {
  //   e.preventDefault();

  //   const emailRegex = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9]))+$/;

  //   if (!emailRegex.test(data.email)) {
  //     toast.error("Enter correct email!!");
  //   }

  const payload = {
    NAME: data.supplierName,
    REG_NUMBER: data.regNo,
    EMAIL: data.email,
    CONTACT_NUMBER: data.contactNumber,
    ALT_CONTACT_NUMBER: "",
    TAX:data.tax,
    FAX: data.fax,
    WEBSITE: data.website,
    APP_CLIENT_ID: clientID,
    ADDR_1: data.address1,
    ADDR_2: data.address2,
    COUNTRY_ID: data.country,
    STATE_ID: data.state,
    DISTRICT_ID: data.state,
    CITY_ID: data.city,
    POSTAL_CODE: data.postalCode,
    IS_SUPP_FAV: "N",
    STATUS: data.suppStatus,
  };

  console.log(payload);

  //   makeApiCall({
  //     mod: "Supplier",
  //     action: "add-supplier",
  //     payload: payload,
  //     headers: {
  //       Authorization: `Bearer ${token}`,
  //     },
  //   })
  //     .then((response) => {
  //       console.log("supplier added ", response);
  //       console.log(missingFields, "mis");
  //   if (missingFields.length > 0) {
  //     const errorMessage = `Please fill in the following fields: ${missingFields.join(
  //       ", "
  //     )}`;
  //     toast.error(errorMessage);
  //   } else {
  //     // All fields are filled, handle the response
  //     response.XscStatus !== 0
  //       ? toast.success(response.XscMessage) && navigate("/suppliers")
  //       : toast.error(response.XscMessage);
  //   }
  // })
  // .catch((error) => {
  //   console.log(error);
  // });
  // };
  const formData = new FormData();
  formData.append("file", image);
  formData.append("mod", "Supplier");
  formData.append("action", "add-supplier");
  formData.append("payload", JSON.stringify(payload));

  console.log(image, "file");
  console.log(formData, "formdata");
  const handleSubmit = () => {
    const checkRequiredFields = () => {
      let missingFields = [];

      if (!payload.NAME) missingFields.push("Supplier Name");
      if (!payload.EMAIL) missingFields.push("Email Address");
      if (!payload.CONTACT_NUMBER) missingFields.push("Phone Number");
      if (!payload.COUNTRY_ID) missingFields.push("Country");
      if (!payload.ADDR_1) missingFields.push("Address line 1");
      if (!payload.DISTRICT_ID) missingFields.push("State/Province");
      if (!payload.CITY_ID) missingFields.push("City");
      //if (!payload.REG_NUMBER) missingFields.push("Registration Number");
      //if (!payload.POSTAL_CODE) missingFields.push("Postal Code");
      //if (!payload.WEBSITE) missingFields.push("Website");

      return missingFields;
    };
    const missingFields = checkRequiredFields(payload);
    console.log(formData, "formdata");
    axios({
      method: "post",
      url: process.env.REACT_APP_API_URL,
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    })
   
      .then((res) => {
        console.log(res, "supplier-added");
        if (missingFields.length > 0) {
          const errorMessage = `Please fill in the following fields: ${missingFields.join(
            ", "
          )}`;
          toast.error(errorMessage);
        } else {
          // All fields are filled, handle the response
          res.data.XscStatus !== 0
            ? toast.success(res.data.XscMessage) && navigate("/suppliers")
            : toast.error(res.data.XscMessage);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div id="supp_bg">
      <div
        id="suppliers_topbar_bg"
        style={{ paddingLeft: "0", paddingRight: "0" }}
      >
        <div className="container d-flex justify-content-between align-items-center">
          <div id="dashboard_sub_header">Add New Supplier</div>
        </div>
      </div>
      <div className="container">
        <div id="add_supplier_bg">
          <form className="row">
            <div className="col-12">
              <label
                style={{ width: "380px" }}
                htmlFor="file-input"
                className="d-flex align-items-center"
                id=""
              >
                <div id="upload_img_bg" style={{ cursor: "pointer" }}>
                  {file ? (
                    <img src={file} alt="Uploaded file" />
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="40"
                      height="40"
                      viewBox="0 0 40 40"
                      fill="none"
                    >
                      <path
                        d="M34.9987 25V30H39.9987V33.3333H34.9987V38.3333H31.6654V33.3333H26.6654V30H31.6654V25H34.9987ZM35.0124 5C35.9254 5 36.6654 5.74158 36.6654 6.65567V21.6667H33.332V8.33333H6.66536V31.665L23.332 15L28.332 20V24.715L23.332 19.714L11.377 31.6667H23.332V35H4.98503C4.07211 35 3.33203 34.2585 3.33203 33.3443V6.65567C3.33203 5.74127 4.09088 5 4.98503 5H35.0124ZM13.332 11.6667C15.173 11.6667 16.6654 13.159 16.6654 15C16.6654 16.841 15.173 18.3333 13.332 18.3333C11.4911 18.3333 9.9987 16.841 9.9987 15C9.9987 13.159 11.4911 11.6667 13.332 11.6667Z"
                        fill="#5A5892"
                      />
                    </svg>
                  )}
                </div>
                <div id="upload_img_text">
                  <>
                    Upload Supplier Logo
                    <div id="img_size">It should be 400 by 400 pixels</div>
                    <div id="upload_img_button">
                      {file ? "Change" : "Upload"}
                    </div>
                  </>
                </div>
              </label>
              <TextInputWithAll
                name="file-input"
                type="file"
                onChange={handleChange}
                id="file-input"
                style={{ display: "none" }}
                maxLength={1}
                accept="image/*"
              />
            </div>

            <div className="col-5" id="add_supplier_input">
              <div className="position-relative" style={{ width: "380px" }}>
                <label>Supplier Name <span style={{color: "#950F27"}}>*</span></label>
                <br />
                <TextInputWithAll
                  type="text"
                  onChange={handleSuppNameInputChange}
                  maxLength={maxChars}
                  value={data.supplierName}
                  name="supplierName"
                  required
                />{" "}
                <div
                  id="remaining_characters"
                  className="position-absolute transalate-middle-x "
                >
                  {data.supplierName.length}/{maxChars}
                </div>
              </div>{" "}
              <div>
                <label>Phone Number <span style={{color: "#950F27"}}>*</span></label>
                <br />
                {/* <input type="text" /> */}
                <div id="phone_input">
                  <TextInput
                    type=""
                    value={data.contactNumber}
                    name="contactNumber"
                    onChange={handleInputChange}
                    required
                  />
                </div>
              </div>
              <div className="position-relative" style={{ width: "380px" }}>
                <label>Registration Number</label>
                <br />

                <TextInput
                  type="text"
                  value={data.regNo}
                  name="regNo"
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div>
                <label>Address line 1 <span style={{color: "#950F27"}}>*</span></label>
                <br />
                <TextInput
                  type="text"
                  value={data.address1}
                  name="address1"
                  onChange={handleInputChange}
                  required
                />
              </div>
              <DropDownInput
                options={states}
                id="selectCountry"
                value={selectedState}
                name="state"
                onChange={handleStateChange}
                label={<div>State/Province <span style={{color: "#950F27"}}>*</span></div>}
                placeholder="Select State/Province"
                required
              />
              <div>
                <label>Zip/Postal Code</label>
                <br />
                <TextInput
                  type="text"
                  value={data.postalCode}
                  name="postalCode"
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div>
                <label>Fax No</label>
                <br />
                <TextInput
                  type="text"
                  value={data.fax}
                  name="fax"
                  onChange={handleInputChange}
                  required
                />
              </div>
            </div>
            <div className="col-5" id="add_supplier_input">
              <div className="position-relative" style={{ width: "380px" }}>
                <label>Email Address <span style={{color: "#950F27"}}>*</span></label>
                <br />
                <TextInput
                  type="email"
                  value={data.email}
                  name="email"
                  onChange={handleInputChange}
                  required
                />
              </div>

              <div>
                <label>Website</label>
                <br />
                <TextInput
                  type="text"
                  value={data.website}
                  name="website"
                  onChange={handleInputChange}
                  required
                />
              </div>

              <DropDownInput
                options={countries}
                id="selectCountry"
                value={selectedCountry}
                name="country"
                onChange={handleCountryChange}
                label={<div>Country <span style={{color: "#950F27"}}>*</span></div>}
                placeholder="Select Country"
                required
              />

              <div>
                <label>Address line 2</label>
                <br />
                <TextInput
                  type="text"
                  value={data.address2}
                  name="address2"
                  onChange={handleInputChange}
                  required
                />
              </div>

              <DropDownInput
                options={cities}
                id="selectCountry"
                value={selectedCities}
                name="city"
                onChange={handleCityChange}
                label={<div>City <span style={{color: "#950F27"}}>*</span></div>}
                placeholder="Select City"
                required
              />
              <div>
                <label>Tax No</label>
                <br />
                <TextInput
                  type="text"
                  value={data.tax}
                  name="tax"
                  onChange={handleInputChange}
                  required
                />
              </div>
            </div>
          </form>
        </div>
      </div>
      <div id="add_supplier_footer" className="">
        <div id="add_supplier_buttons" className="container">
          <div></div>
          <div
            className="d-flex align-items-center justify-content-right "
            style={{ gap: "12px" }}
          >
            <div id="cancel" onClick={handleCancel}>Cancel</div>
            <div id="submitsupplier" onClick={handleSubmit}>
               Add Supplier
            </div>
          </div>
        </div>
        <ToastContainer
          position="bottom-center"
          style={{ width: "max-content" }}
        />
      </div>
    </div>
  );
}

export default AddSupplier;
