import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { makeApiCall } from "../../StaticComponents/API";
// import Skeleton from "react-loading-skeleton";
import DataGridSkeleton from "../../StaticComponents/SkeletonLoading";
import noDataImage from "../../assets/Frame.svg";

export default function SupplierTable({
  searchQuery,
  notify,
  onFavoriteCountUpdate,
}) {
  const [pageSize, setPageSize] = React.useState(10); // Default page size
  const [page, setpage] = useState(0);
  const [modalShow, setModalShow] = React.useState(false);
  // const [page, setPage] = useState(0);
  const [rows, setRows] = React.useState([]);
  const navigate = useNavigate();
  // const [page,setpage]=React.useState(0);
  const [isLoading, setisLoading] = React.useState(false);
  const [total, settotal] = React.useState(0);
  const [loading, setLoading] = useState(false);
  const [favCount, setFavCount] = useState(0);

  console.log(notify);
  const handlepagechange = (newpage) => {
    setpage(newpage);
  };
  const handlepagesizechange = (num) => {
    setPageSize(num);
    setpage(0);
  };
  console.log("page pagesize", page, pageSize);
  const payload = {
    APP_CLIENT_ID: localStorage.getItem("CLIENT_ID"),
    CURRENT_PAGE: page + 1,
    ITEM_PER_PAGE: pageSize,
    SEARCH_KEYWORD: searchQuery,
  };

  let token = localStorage.getItem("token");
  const columns = [
    {
      field: "CUSTOMER_ID",
      headerName: "Cust.ID",
      width: 120,
      height: 80,
      cellClassName: "sticky-column",
    },
    {
      field: "CUSTOMER_NAME",
      headerName: "Customer Name",
      width: 270,
      editable: false,
      align: "left",
      height: 80,
    },
    {
      field: "EMAIL",
      headerName: "Email",
      width: 270,
      editable: false,
      align: "left",
      height: 80,
    },

    {
      field: "CONTACT_NUMBER",
      headerName: "Phone Number",
      type: "number",
      width: 250,
      editable: false,
      align: "left",
      height: 80,
    },
    {
      field: "LOCATION",
      headerName: "Location",
      width: 170,
      editable: false,
      align: "left",
      height: 80,
    },
    {
      field: "IS_CUST_FAV",
      headerName: " ",
      width: 80,
      editable: false,
      align: "center",
      headerClassName: "more_svg",
      renderCell: (params) =>
        createFavIcon(params.row.IS_CUST_FAV, (e) =>
          handleFav(e, params.row.id)
        ),
      height: 80,
    },
    {
      field: "EDIT",
      headerName: " ",
      width: 80,
      height: 80,
      editable: false,
      align: "center",
      headerClassName: "more_svg",
      renderCell: (params) => createEditIcon(params.row.id),
    },
  ];

  const fetchCustomers = async () => {
    setLoading(true);
    const payload = {
      APP_CLIENT_ID: localStorage.getItem("CLIENT_ID"),
      CURRENT_PAGE: page + 1,
      ITEM_PER_PAGE: pageSize,
      SEARCH_KEYWORD: searchQuery,
    };
    console.log(payload, "paylo");
    try {
      const response = await makeApiCall({
        mod: "Customer",
        action: "list-customer",
        payload: payload,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log(response, "response");
      const customers = response.XscData.CUSTOMER_LIST;

      if (Array.isArray(customers)) {
        const mappedRows = customers.map((customer) => {
          return {
            id: customer.CLIENT_CUST_ID,
            CUSTOMER_ID: customer.CUST_NUM,
            CUSTOMER_NAME: customer.CUSTOMER_NAME,
            EMAIL: customer.EMAIL,
            CONTACT_NUMBER: customer.CONTACT_NUMBER,
            LOCATION: customer.LOCATION,
            IS_CUST_FAV: customer.IS_CUST_FAV === "Y",
            EDIT: createEditIcon(customer.CLIENT_CUST_ID),
          };
        });

        settotal(response.XscData.TOTAL_RECORDS);
        setRows(mappedRows);

        // Initialize favorite count
        const initialFavCount = customers.filter(
          (customer) => customer.IS_CUST_FAV === "Y"
        ).length;
        setFavCount(initialFavCount);
      } else {
        console.error("customers data is not an array or is missing");
      }
    } catch (error) {
      console.error("Error fetching customers:", error);
    } finally {
      setLoading(false);
    }
  };

  // Clear local storage only on component unmount
  useEffect(() => {
    return () => {
      // localStorage.removeItem("CUST_ID");
      // localStorage.removeItem("CUST_NAME");
      // localStorage.removeItem("IS_CUST_FAV");
    };
  }, []);

  useEffect(() => {
    fetchCustomers();
  }, [page, searchQuery, pageSize]);

  const createEditIcon = (id) => {
    return (
      <a href={`/customers/edit-customer?id=${id}`}>
        <svg
          onClick={(e) => e.stopPropagation()}
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M5 18.89H6.41421L15.7279 9.57628L14.3137 8.16207L5 17.4758V18.89ZM21 20.89H3V16.6473L16.435 3.21232C16.8256 2.8218 17.4587 2.8218 17.8492 3.21232L20.6777 6.04075C21.0682 6.43127 21.0682 7.06444 20.6777 7.45496L9.24264 18.89H21V20.89ZM15.7279 6.74786L17.1421 8.16207L18.5563 6.74786L17.1421 5.33364L15.7279 6.74786Z"
            fill="var(--onSurfaceVariant)"
          />
        </svg>
      </a>
    );
  };

  const handleFav = (e, id) => {
    e.stopPropagation();
    const foundRow = rows.find((r) => r.id === id);
    if (!foundRow) return;

    const updatedFavStatus = !foundRow.IS_CUST_FAV;
    const payload = {
      CLIENT_CUST_ID: id,
      IS_FAVOURITE: updatedFavStatus ? "Y" : "N",
    };

    setRows((prevRows) =>
      prevRows.map((row) => {
        if (row.id === id) {
          localStorage.setItem(`IS_CUST_FAV`, updatedFavStatus ? "Y" : "N");
          return { ...row, IS_CUST_FAV: updatedFavStatus };
        }
        return row;
      })
    );

    setFavCount((prevCount) => {
      const newCount = updatedFavStatus
        ? prevCount + 1
        : Math.max(prevCount - 1, 0);
      onFavoriteCountUpdate(newCount);
      return newCount;
    });

    // Call API to persist the change
    makeApiCall({
      mod: "Customer",
      action: "mark-customer-favourite",
      payload: payload,
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        console.log("Favorite status updated", res);
      })
      .catch((error) => {
        console.error("API Error:", error);
        setRows((prevRows) =>
          prevRows.map((row) => {
            if (row.id === id) {
              return { ...row, IS_CUST_FAV: !updatedFavStatus }; // Revert to previous status
            }
            return row;
          })
        );
        setFavCount((prevCount) =>
          updatedFavStatus ? prevCount - 1 : prevCount + 1
        ); // Revert count
      });
  };

  const createFavIcon = (isFav, onClick) => (
    <svg
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      {isFav ? (
        <path
          d="M12.001 4.52853C14.35 2.42 17.98 2.49 20.2426 4.75736C22.5053 7.02472 22.583 10.637 20.4786 12.993L11.9999 21.485L3.52138 12.993C1.41705 10.637 1.49571 7.01901 3.75736 4.75736C6.02157 2.49315 9.64519 2.41687 12.001 4.52853Z"
          fill="#BA3CBB"
        />
      ) : (
        <path
          d="M12.001 4.52853C14.35 2.42 17.98 2.49 20.2426 4.75736C22.5053 7.02472 22.583 10.637 20.4786 12.993L11.9999 21.485L3.52138 12.993C1.41705 10.637 1.49571 7.01901 3.75736 4.75736C6.02157 2.49315 9.64519 2.41687 12.001 4.52853ZM18.827 6.1701C17.3279 4.66794 14.9076 4.60701 13.337 6.01687L12.0019 7.21524L10.6661 6.01781C9.09098 4.60597 6.67506 4.66808 5.17157 6.17157C3.68183 7.66131 3.60704 10.0473 4.97993 11.6232L11.9999 18.6543L19.0201 11.6232C20.3935 10.0467 20.319 7.66525 18.827 6.1701Z"
          fill="var(--onSurfaceVariant)"
        />
      )}
    </svg>
  );

  localStorage.setItem("CUST_ID", "");
  localStorage.setItem("CUST_NAME", "");
  localStorage.setItem("IS_CUST_FAV", "");

  localStorage.setItem("CLIENT_CUST_ID", "");
  const handleRowClick = (params) => {
    const id = params.row.id;
    localStorage.setItem("CLIENT_CUST_ID", params.row.id);
    localStorage.setItem("CUST_NUM", params.row.CUSTOMER_ID);
    localStorage.setItem("IS_CUST_FAV", params.row.IS_CUST_FAV ? "Y" : "N");
    localStorage.setItem("CUST_NAME", params.row.CUSTOMER_NAME);
    navigate(`/customers/customer-info`);
  };

  return (
    <div style={{ height: 600, width: "100%", marginTop: "20px" }}>
      <DataGrid
        localeText={{
          noRowsLabel: (
            <div className="no_rows_css" style={{ textAlign: "center" }}>
              <div
                id="no_data_found_text"
                style={{
                  fontFamily: "Inter",
                  fontWeight: 600,
                  fontSize: "22px",
                }}
              >
                {searchQuery ? (
                  <div style={{ textAlign: "center" }}>
                    <img
                      src={noDataImage}
                      alt="No Data"
                      style={{ width: "192px", height: "200px" }}
                    />

                    <p
                      style={{
                        fontWeight: 500,
                        fontSize: "22px",
                        lineHeight: "28px",
                        textAlign: "center",
                        marginTop: "20px",
                      }}
                    >
                      No results found in the list.
                    </p>
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "100%",
                    }}
                  >
                    <p style={{ fontWeight: "600", fontSize: "32px" }}>
                      Add a new customer
                    </p>
                    <p style={{ fontWeight: "400", fontSize: "16px" }}>
                      Start adding a customer. Once done, customers will be
                      listed here.
                    </p>
                    <div
                      className="text-decoration-none"
                      onClick={(e) => {
                        e.stopPropagation(); // Stop the row click event from triggering
                        navigate("/suppliers/add-supplier"); // Programmatically navigate to the link
                      }}
                    >
                      <button className="btn" id="add_supplier_button">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M11 11V5H13V11H19V13H13V19H11V13H5V11H11Z"
                            fill="#F7F7F7"
                          />
                        </svg>
                        Add customer
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          ),
        }}
        rows={rows}
        onRowClick={handleRowClick}
        columns={columns}
        pageSize={pageSize}
        page={page}
        pagination
        paginationMode="server"
        rowCount={total}
        rowsPerPageOptions={
          rows.length < 50
            ? [10, 20, 50]
            : rows.length > 20
            ? [10, 20, rows.length]
            : rows.length > 10
            ? [10, rows.length]
            : [rows.length]
        }
        onPageChange={handlepagechange}
        onPageSizeChange={handlepagesizechange}
        loading={loading}
        disableSelectionOnClick
        style={{ cursor: "pointer" }}
      />
    </div>
  );
}
