import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { makeStyles } from "@material-ui/styles";
import "../../../Styles/AccountPayableTable.css";
import excelDownload from "../../../assets/excelDownload.png";
import pdfDownload from "../../../assets/pdfDownload.png";
import { makeApiCall } from "../../../StaticComponents/API";
import { useSearchParams } from "react-router-dom";

const useStyles = makeStyles({
  row: {
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "var(--size-140) !important",
  },

  headerDownloadPdf: {
    minWidth: "218px !important",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontSize: "14px",
    paddingLeft: "75px !important",
  },

  headerDownloadExcel: {
    minWidth: "158px !important",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontSize: "14px",
  },

  headerOrdersSelected: {
    minWidth: "205px !important",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontSize: "14px",
    marginLeft: "-10px",
  },

  padding: { paddingLeft: "75px !important" },
});

export default function CustomerReportTable({searchQuery}) {

  const initialrows = [
    // {
    //   id: "MNXS-2024-0001",
    //   PO_ID: "U987",
    //   CUSTOMER_NAME: "Wang Lee",
    //   TOTAL_AMOUNT: "\u00a5 2,160.00",
    //   PENDING_AMOUNT: "\u00a5 160.00",
    //   PAYMENT_STATUS: "Unpaid",
    // },
  ];

  let [searchParams] = useSearchParams();
  const [rows, setrows] = useState(initialrows);
  const [total, settotal] = useState();
  // const CLIENT_CUST_ID = searchParams.get("id");
  let token = localStorage.getItem("token");
  const [page, setpage] = useState(0);

  const handlepagechange = (newpage) => {
    setpage(newpage);
  };
  const APP_CLIENT_ID = localStorage.getItem("CLIENT_ID");
  const CLIENT_CUST_ID = localStorage.getItem("CLIENT_CUST_ID");

  useEffect(() => {
    const payload = { APP_CLIENT_ID: APP_CLIENT_ID, CURRENT_PAGE: page + 1, CLIENT_CUST_ID: CLIENT_CUST_ID, SEARCH_KEYWORD:searchQuery};
    // const payload = { APP_CLIENT_ID: 1, CURRENT_PAGE:1 };

    makeApiCall({
      mod: "Customer",
      action: "customers-report",
      payload: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        console.log("request", payload);
        console.log("CUSTOMER report records fetched", response);

        const customers = response.XscData.INVOICE_DETAILS;
        if (Array.isArray(customers)) {
          const mappedRows = customers.map((customer) => ({

            id: customer.ID, 
            invoice_no: customer.INVOICE_NUM,
            PO_ID: customer.CUST_PO_NUM,
            CUSTOMER_NAME: customer.NAME,
            TOTAL_AMOUNT: `${customer.CURRENCY_SYMBOL
            } ${customer.TOTAL_AMOUNT}`,
            PENDING_AMOUNT: `${customer.CURRENCY_SYMBOL} ${customer.DUE_AMOUNT}`,
            PAYMENT_STATUS: customer.PAYMENT_STATUS === 0 ? "Unpaid" : "Paid",
          }));
          setrows(mappedRows);
        }
        settotal(response.XscData.TOTAL_RECORDS);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [searchQuery]);

  const [pageSize, setPageSize] = useState(10);
  const [columns, setColumns] = useState([]);
  const [selection, setSelection] = useState([]);
  const classes = useStyles();

  const initialColumns = [
    {
      field: "invoice_no",
      headerName: "Invoice No",
      width: 300,
      cellClassName: classes.padding,
      headerClassName: classes.padding,
      renderCell: (params) => <div>{params.value}</div>,
    },
    {
      field: "PO_ID",
      headerName: "Cust.PO-No",
      width: 150,
      editable: false,
    },
    {
      field: "CUSTOMER_NAME",
      headerName: "Customer Name",
      width: 200,
      editable: false,
    },
    {
      field: "TOTAL_AMOUNT",
      headerName: "Total Amount",
      width: 175,
      editable: false,
    },
    {
      field: "PENDING_AMOUNT",
      headerName: "Pending Amount",
      width: 200,
      editable: false,
    },
    {
      field: "PAYMENT_STATUS",
      headerName: "Payment Status",
      width: 166,
      editable: false,
      renderCell: (e) => (
        <div
          className="paymentStatus"
          style={{
            color: `${
              e.row.PAYMENT_STATUS === "Unpaid" ? "#683a3a" : "#3a6c29"
            }`,
            background: `${
              e.row.PAYMENT_STATUS === "Unpaid" ? "#e69292" : "#a7e592"
            }`,
          }}
        >
          {e.value}
        </div>
      ),
    },
  ];

  const additionalColumns = [
    {
      field: "invoice_no",
      headerName: "invoice_no",
      width: 300,
      cellClassName: classes.padding,
      headerClassName: classes.headerDownloadPdf,
      renderHeader: () => (
        <div
          className="d-flex flex-row align-items-left"
          style={{
            gap: "8px",
            width: 200,
            height: "20px",
            display: "flex",
            alignItems: "center",
          }}
          onClick={handlepdfdownload}
        >
          <div>
            <img src={pdfDownload} />
          </div>
          <span
            style={{ color: "#635bff", fontWeight: "600", fontSize: "14px" }}
            id="dwn_text"
          >
            Download Pdf
          </span>
        </div>
      ),
      sortable: false,
    },
    {
      field: "PO_ID",
      headerName: "",
      width: 150,
      editable: false,
      headerClassName: classes.headerDownloadExcel,
      renderHeader: () => (
        <div
          className="d-flex flex-row align-items-left"
          style={{
            gap: "8px",
            width: 200,
            height: "20px",
            display: "flex",
            alignItems: "center",
          }}
          onClick={handleexceldownload}
        >
          <div>
            <img src={excelDownload} />
          </div>
          <span style={{ color: "#635bff" }} id="dwn_text">
            Download Excel
          </span>
        </div>
      ),
      sortable: false,
    },
    {
      field: "CUSTOMER_NAME",
      width: 200,
      editable: false,
      renderHeader: () => <div></div>,
      sortable: false,
    },
    {
      field: "TOTAL_AMOUNT",
      width: 175,
      editable: false,
      renderHeader: () => <div></div>,
      sortable: false,
    },
    {
      field: "PENDING_AMOUNT",
      width: 200,
      editable: false,
      renderHeader: () => <div></div>,
      sortable: false,
    },
    {
      field: "PAYMENT_STATUS",
      headerName: "Payment Status",
      width: 166,
      editable: false,
      sortable: false,
      headerClassName: classes.headerOrdersSelected,
      renderHeader: () => <div>{selection.length} Orders Selected</div>,
      renderCell: (e) => (
        <div
          className="paymentStatus"
          style={{
            color: `${
              e.row.PAYMENT_STATUS === "Unpaid" ? "#683a3a" : "#3a6c29"
            }`,
            background: `${
              e.row.PAYMENT_STATUS === "Unpaid" ? "#e69292" : "#a7e592"
            }`,
          }}
        >
          {e.value}
        </div>
      ),
    },
  ];

  const handleSelectionChange = (newSelection) => {
    setSelection(newSelection);
  };

  useEffect(() => {
    if (selection.length > 0) {
      setColumns(additionalColumns);
    } else {
      setColumns(initialColumns);
    }
  }, [selection]);

  const API_URL = process.env.REACT_APP_API_URL;
  const handlepdfdownload = () => {
    const payload1 = {
      APP_CLIENT_ID: localStorage.getItem("CLIENT_ID"),
      CLIENT_CUST_ID: CLIENT_CUST_ID,
      CLIENT_CUST_INV_ID: selection,
    };
    makeApiCall({
      mod: "Report",
      action: "generate_customer_order_report_pdf",
      payload: payload1,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        console.log("request", payload1);
        console.log("customer screen report pdf fetched", response); // Check the response here

        if (response.XscStatus === 1) {
          const url = `${API_URL}/${response.XscData.URL}`;
          fetch(url)
            .then((response) => response.blob())
            .then((blob) => {
              const link = document.createElement("a");
              link.href = URL.createObjectURL(blob);
              link.download = "report.pdf"; // Set the filename here
              link.click();
            });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleexceldownload = () => {
    const payload1 = {
      APP_CLIENT_ID: localStorage.getItem("CLIENT_ID"),
      CLIENT_CUST_ID: CLIENT_CUST_ID,
      CLIENT_CUST_INV_ID: selection,
    };
    makeApiCall({
      mod: "Report",
      action: "generate_customer_order_report_excel",
      payload: payload1,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        console.log("request", payload1);
        console.log("customer screen report excel fetched", response); // Check the response here

        if (response.XscStatus === 1) {
          const url = `${API_URL}/${response.XscData.URL}`;
          fetch(url)
            .then((response) => response.blob())
            .then((blob) => {
              const link = document.createElement("a");
              link.href = URL.createObjectURL(blob);
              link.download = "report.xlsx"; // Set the filename here
              link.click();
            });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  console.log("rows selected", selection);

  return (
    <>
      <div className="ap_dataGrid">
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={pageSize}
          rowCount={total}
          rowsPerPageOptions={[10, 20, 50]}
          page={page}
          pagination
          paginationMode="server"
          onPageSizeChange={(newPageSize) => {setPageSize(newPageSize); setpage(0)}}
          classes={{ columnHeader: classes.header, row: classes.row }}
          checkboxSelection
          onSelectionModelChange={(newSelection) =>
            handleSelectionChange(newSelection)
          }
          onPageChange={(newPage) => setpage(newPage)}
        />
      </div>
    </>
  );
}
