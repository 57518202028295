import { useEffect, useState } from "react";
import Filter from "../../StaticComponents/Filter";
import SearchBar from "../../StaticComponents/SearchBar";
import "../../Styles/ReportComponents.css";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { Dropdown } from "react-bootstrap";
import DatePickerModal from "./DatePickerModal";
import AccountPayableTable from "./AccountPayableTable.js";
import AccountPayableFilter from "./AccountPayableFilter.js";
import { makeApiCall } from "../../StaticComponents/API.js";

export default function AccountPayable() {
  const [modalShow, setModalShow] = useState(false);
  const token = localStorage.getItem("token");
  const [totalsuppliers, settotalsuppliers] = useState(0);
  const [due, setdue] = useState(0);
  const [searchkeyword, setsearchkeyword] = useState("");
  const onSearch = (value) => {
    setsearchkeyword(value);
  };
  const [notify, setnotify] = useState(0);
  const onNotify = (value) => {
    setnotify(value);
  };
  const payload = {
    APP_CLIENT_ID: localStorage.getItem("CLIENT_ID"),
    SEARCH_KEYWORD: searchkeyword,
  };

  useEffect(() => {
    makeApiCall({
      mod: "Report",
      action: "account_payable_list",
      payload: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        console.log("request", payload);
        console.log("account payable records fetched", response); // Check the response here
        settotalsuppliers(response.XscData.TOTAL_SUPPLIERS);
        setdue(
          `${response.XscData.TOTAL_DUE_DETAILS.OPERATING_CURRENCY_SYMBOL} ${response.XscData.TOTAL_DUE_DETAILS.ACCOUNT_PAYABLE}` ||
            0
        );
      })
      .catch((error) => {
        console.log(error);
      });
  }, [localStorage.getItem("CLIENT_ID"), searchkeyword]);
  return (
    <div id="report_bg">
      <div className="container">
        <div id="report_overview_heading" className="row">
          <div className="col-md-4">
            <div id="total_stock">
              Total Suppliers
              <div id="total_count">{totalsuppliers}</div>
            </div>
          </div>
          <div className="col-md-4">
            <div id="total_stock">
              Due Payment
              <div id="total_count">{due}</div>
            </div>
          </div>
        </div>
        <div className="d-flex" id="invFilters">
          <SearchBar onSearch={onSearch} />
          <div onClick={() => setModalShow(true)} style={{ cursor: "pointer" }}>
            <Filter notify={notify} />
          </div>
          <AccountPayableFilter
            show={modalShow}
            onHide={() => setModalShow(false)}
            onNotify={onNotify}
          />
          <Dropdown>
            <Dropdown.Toggle
              style={{
                marginLeft: "12px",
                width: "301px",
                height: "40px",
                backgroundColor: "var(--onSurfaceLowest)",
                color: "var(--onSurface)",
                border: "1px solid var(--outline1)",
                borderRadius: "var(--cr---3)",
              }}
            >
              Date
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <DatePickerModal />
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div id="report_tab_bg">
          <h2 className="totoal_1000_results">{totalsuppliers} Results</h2>
          <AccountPayableTable searchkeyword={searchkeyword} />
        </div>
      </div>
    </div>
  );
}
