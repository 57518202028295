import React from 'react';
import { Modal } from "react-bootstrap";
import { useNavigate, useSearchParams } from 'react-router-dom';
import { makeApiCall } from '../../StaticComponents/API';
import { toast, ToastContainer } from 'react-toastify';

const DeleteCustomer = (props) => {
  let [searchParams] = useSearchParams();
  let token = localStorage.getItem("token");

  let CUST_NUM = searchParams.get("id");
  const navigate = useNavigate("");
  const DeleteCustomer = () => {
    const payload = {
      CLIENT_CUST_ID: CUST_NUM,
    };
    makeApiCall({
      mod: "Customer",
      action: "delete-customer",
      payload: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((res) => {
      console.log(res, "deleted");

      setTimeout(() => {
        toast.success("Customer Deleted Successfully!");
        props.onHide();
        setTimeout(() => {
        
          navigate("/customers");
          
        }, 1000);
      }, 1000);
    });
  };
  return (
    <div> <Modal {...props}>
    <div>
      <div id="delete_Supplier">Delete Customer</div>
      <div id="delete_supplier_content">
        Are you sure you want to delete this Particular Customer? <br />
        It cannot be undone.
      </div>
      <div id="delete_footer">
        <div id="delete_cancel" onClick={()=>props.onHide()}>Cancel</div>
        <div id="delete_submit" onClick={DeleteCustomer}>Delete Permanently</div>
        
      </div>
    </div>
  </Modal>
  <ToastContainer position='bottom-center'/>
  </div>
  )
}

export default DeleteCustomer