import React, { useEffect, useRef, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { makeStyles } from "@material-ui/styles";
import "../../Styles/AccountPayableTable.css";
import { Dropdown } from "react-bootstrap";
import excelDownload from "../../assets/excelDownload.png";
import pdfDownload from "../../assets/pdfDownload.png";
import { makeApiCall } from "../../StaticComponents/API";
import PaySupplier from "./PaySupplier";
import noDataImage from "../../assets/no_result_img.png";
import nodataimage from "../../assets/no_data_image_2.png";
const useStyles = makeStyles({
  header: {
    "& .MuiCheckbox-root": {
      color: "var(--onSurface)",
    },
  },

  row: {
    "& .MuiCheckbox-root": {
      color: "var(--onSurface)",
    },

    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "var(--size-140) !important",
  },

  headerDownloadPdf: {
    minWidth: "218px !important",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontSize: "14px",
    paddingLeft: "75px !important",
  },

  headerDownloadExcel: {
    minWidth: "158px !important",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontSize: "14px",
  },

  headerAPSelected: {
    minWidth: "205px !important",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontSize: "14px",
    marginLeft: "-10px",
  },

  padding: { paddingLeft: "75px !important" },

  border: {
    "& .MuiCheckbox-root": {
      color: "var(--onSurface)",
    },
  },
});

export default function AccountPayableTable({ searchkeyword }) {
  const [pageSize, setPageSize] = useState(10);

  const API_URL = process.env.REACT_APP_API_URL;
  const [optionShow, setOptionShow] = useState(false);
  const [optionPosition, setOptionPosition] = useState({ top: 0, left: 0 });
  const [columns, setColumns] = useState([]);
  const [selection, setSelection] = useState([]);
  const ellipsisRef = useRef(null);
  const [page, setpage] = useState(0);
  const [rowcount, setrowcount] = useState(0);
  const [show, setshow] = useState(false);
  useEffect(() => {
    localStorage.setItem("addshow", false);
    localStorage.setItem("editshow", false);
  }, []);
  let addshow = localStorage.getItem("addshow");
  let editshow = localStorage.getItem("editshow");

  const [invoiceID, setinvoiceID] = useState(null);
  const handleShow = () => {
    setshow(true);
  };
  const handlepagechange = (newpage) => {
    setpage(newpage);
  };
  const handleEllipsisClick = (e, status, invoiceID) => {
    const rect = e.target.getBoundingClientRect();
    setOptionPosition({
      top: rect.bottom + window.scrollY,
      left: rect.left + window.scrollX,
    });
    setOptionShow(true);
    localStorage.setItem("INVOICE_ID", invoiceID);
    setinvoiceID(invoiceID);
    console.log(invoiceID);
  };

  const handleClickOutside = (event) => {
    if (ellipsisRef.current && !ellipsisRef.current.contains(event.target)) {
      setOptionShow(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const STATUS_CLASS_MAP = {
    Unpaid: "invoice_reject_bg",
    Paid: "invoice_completed_status_bg",
  };

  const getPaymentStatus = (paymentStatus) => {
    if (paymentStatus === 0 || paymentStatus === null) return "Unpaid";
    if (paymentStatus === 1) return "Paid";
  };

  const initialrows = [];

  const classes = useStyles();

  const [rows, setrows] = useState(initialrows);
  const [total, settotal] = useState();

  const initialColumns = [
    {
      field: "SUP_ID",
      headerName: "Sup.ID",
      width: 225,
      cellClassName: classes.padding,
      headerAlign: "left",
      headerClassName: classes.padding,
    },
    {
      field: "SUPPLIER_NAME",
      headerName: "Supplier Name",
      width: 180,
      editable: false,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "INVOICE_ID",
      headerName: "Invoice No",
      width: 200,
      editable: false,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "TOTAL_AMOUNT",
      headerName: "Total Amount",
      width: 175,
      editable: false,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "PENDING_AMOUNT",
      headerName: "Pending Amount",
      width: 200,
      editable: false,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "PAYMENT_STATUS",
      headerName: "Payment Status",
      width: 166,
      editable: false,
      align: "left",
      headerAlign: "left",
      renderCell: (params) => {
        const paymentStatus = params.row.PAYMENT_STATUS;
        const paymentStatusText = getPaymentStatus(paymentStatus);
        const className = STATUS_CLASS_MAP[paymentStatusText] || "";
        return <div className={className}>{paymentStatusText}</div>;
      },
    },
    {
      field: "VERTICAL_ELLIPSIS",
      width: 25,
      editable: false,
      align: "left",
      sortable: false,
      renderHeader: () => <div></div>,
      renderCell: (params) => {
        const paymentStatus = params.row.PAYMENT_STATUS;
        let ID = params.row.ID;
        const paymentStatusText = getPaymentStatus(paymentStatus);
        if (paymentStatus === 1) {
          return <div></div>;
        }
        return (
          <div
            style={{
              cursor: "pointer",
              width: "30px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="4"
              height="18"
              viewBox="0 0 4 18"
              fill="none"
              onClick={(e) =>
                handleEllipsisClick(e, paymentStatusText, params.row.id)
              }
            >
              <path
                d="M2 0C1.175 0 0.5 0.675 0.5 1.5C0.5 2.325 1.175 3 2 3C2.825 3 3.5 2.325 3.5 1.5C3.5 0.675 2.825 0 2 0ZM2 15C1.175 15 0.5 15.675 0.5 16.5C0.5 17.325 1.175 18 2 18C2.825 18 3.5 17.325 3.5 16.5C3.5 15.675 2.825 15 2 15ZM2 7.5C1.175 7.5 0.5 8.175 0.5 9C0.5 9.825 1.175 10.5 2 10.5C2.825 10.5 3.5 9.825 3.5 9C3.5 8.175 2.825 7.5 2 7.5Z"
                fill="#5D6B82"
              />
            </svg>
          </div>
        );
      },
    },
  ];

  const additionalColumns = [
    {
      field: "SUP_ID",
      headerName: "",
      width: 225,
      cellClassName: classes.padding,
      headerClassName: classes.headerDownloadPdf,
      renderHeader: () => (
        <div
          className="d-flex flex-row align-items-center"
          style={{
            gap: "8px",
            width: 200,
            height: "20px",
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
          }}
          onClick={handlepdfdownload}
        >
          <div>
            <img src={pdfDownload} />
          </div>
          <span style={{ color: "#635bff" }} id="dwn_text">
            Download Pdf
          </span>
        </div>
      ),
      sortable: false,
    },
    {
      field: "SUPPLIER_NAME",
      headerName: "",
      width: 180,
      editable: false,
      align: "left",
      headerClassName: classes.headerDownloadExcel,
      renderHeader: () => (
        <div
          className="d-flex flex-row align-items-center"
          style={{
            gap: "8px",
            width: 200,
            height: "20px",
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
          }}
          onClick={handleexceldownload}
        >
          <div>
            <img src={excelDownload} />
          </div>
          <span style={{ color: "#635bff" }} id="dwn_text">
            Download Excel
          </span>
        </div>
      ),
      sortable: false,
    },
    {
      field: "INVOICE_ID",
      width: 200,
      editable: false,
      align: "left",
      headerAlign: "left",
      renderHeader: () => <div></div>,
      sortable: false,
    },
    {
      field: "TOTAL_AMOUNT",
      width: 175,
      editable: false,
      align: "left",
      headerAlign: "left",
      renderHeader: () => <div></div>,
      sortable: false,
    },
    {
      field: "PENDING_AMOUNT",
      width: 200,
      editable: false,
      align: "left",
      headerAlign: "left",
      renderHeader: () => <div></div>,
      sortable: false,
    },
    {
      field: "PAYMENT_STATUS",
      headerName: "Payment Status",
      width: 166,
      editable: false,
      sortable: false,
      align: "left",
      headerAlign: "left",
      renderHeader: () => <div></div>,
      renderCell: (params) => {
        const paymentStatus = params.row.PAYMENT_STATUS;
        const paymentStatusText = getPaymentStatus(paymentStatus);
        const className = STATUS_CLASS_MAP[paymentStatusText] || "";
        return <div className={className}>{paymentStatusText}</div>;
      },
    },

    {
      field: "VERTICAL_ELLIPSIS",
      width: 25,
      editable: false,
      align: "left",
      sortable: false,
      headerClassName: classes.headerAPSelected,
      renderHeader: () => <div>{selection.length} AP Selected</div>,
      renderCell: (params) => {
        const paymentStatus = params.row.PAYMENT_STATUS;
        let ID = params.row.ID;
        const paymentStatusText = getPaymentStatus(paymentStatus);
        if (paymentStatus === 1) {
          return <div></div>;
        }
        return (
          <div
            style={{
              cursor: "pointer",
              width: "30px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            onClick={(e) =>
              handleEllipsisClick(e, paymentStatusText, params.row.id)
            }
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="4"
              height="18"
              viewBox="0 0 4 18"
              fill="none"
            >
              <path
                d="M2 0C1.175 0 0.5 0.675 0.5 1.5C0.5 2.325 1.175 3 2 3C2.825 3 3.5 2.325 3.5 1.5C3.5 0.675 2.825 0 2 0ZM2 15C1.175 15 0.5 15.675 0.5 16.5C0.5 17.325 1.175 18 2 18C2.825 18 3.5 17.325 3.5 16.5C3.5 15.675 2.825 15 2 15ZM2 7.5C1.175 7.5 0.5 8.175 0.5 9C0.5 9.825 1.175 10.5 2 10.5C2.825 10.5 3.5 9.825 3.5 9C3.5 8.175 2.825 7.5 2 7.5Z"
                fill="#5D6B82"
              />
            </svg>
          </div>
        );
      },
    },
  ];

  const handleSelectionChange = (newSelection) => {
    setSelection(newSelection);
  };

  useEffect(() => {
    if (selection.length > 0) {
      setColumns(additionalColumns);
    } else {
      setColumns(initialColumns);
    }
  }, [selection]);

  const APP_CLIENT_ID = localStorage.getItem("CLIENT_ID");
  const token = localStorage.getItem("token");
  const payload = {
    APP_CLIENT_ID: APP_CLIENT_ID,
    SEARCH_KEYWORD: searchkeyword,
    CURRENT_PAGE: page + 1,
    ITEM_PER_PAGE: 10,
  };
  const [addShowState, setAddShowState] = useState(
    localStorage.getItem("addshow")
  );
  const [editShowState, setEditShowState] = useState(
    localStorage.getItem("editshow")
  );

  useEffect(() => {
    const handleStorageChange = () => {
      setAddShowState(localStorage.getItem("addshow"));
      setEditShowState(localStorage.getItem("editshow"));
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);
  useEffect(() => {
    makeApiCall({
      mod: "Report",
      action: "account_payable_list",
      payload: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        const suppliers = response.XscData.SUPPLIER_DETAILS;

        if (Array.isArray(suppliers) && suppliers.length > 0) {
          const mappedRows = suppliers.map((supplier) => ({
            id: supplier.ID || "N/A",
            SUP_ID: supplier.SUP_ID || "N/A",
            SUPPLIER_NAME: supplier.SUPPLIER_NAME || "N/A",
            INVOICE_ID: supplier.INVOICE_NO || "N/A",
            PAYMENT_STATUS: supplier.PAYMENT_STATUS,
            PENDING_AMOUNT:
              supplier.PENDING_AMOUNT != null
                ? `${supplier.INVOICE_CURRENCY_SYMBOL}${supplier.PENDING_AMOUNT}`
                : "0",
            TOTAL_AMOUNT:
              supplier.TOTAL_AMOUNT != null
                ? `${supplier.INVOICE_CURRENCY_SYMBOL}${supplier.TOTAL_AMOUNT}`
                : "0",
          }));

          setrows(mappedRows);
        } else {
          setrows([]); // Set rows to empty when no suppliers
        }

        setrowcount(response.XscData.TOTAL_SUPPLIERS || 0); // Ensure row count is updated
      })
      .catch((error) => {
        console.error(error);
        setrows([]); // Optionally set rows to empty on error as well
      });
  }, [APP_CLIENT_ID, searchkeyword, show]);
  console.log("rows selected", rows);

  console.log("ADD SHOW", localStorage.getItem("addshow"));
  const handlepdfdownload = () => {
    const payload1 = {
      APP_CLIENT_ID: APP_CLIENT_ID,
      CLIENT_SUPP_INV_ID: selection,
    };
    makeApiCall({
      mod: "Report",
      action: "generate-AP-pdf",
      payload: payload1,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        console.log("request", payload1);
        console.log("account payable  report pdf fetched", response); // Check the response here

        if (response.XscStatus === 1) {
          const url = `${API_URL}/${response.XscData.URL}`;
          fetch(url)
            .then((response) => response.blob())
            .then((blob) => {
              const link = document.createElement("a");
              link.href = URL.createObjectURL(blob);
              link.download = "selected_account_payable_report.pdf"; // Set the filename here
              link.click();
            });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleexceldownload = () => {
    const payload1 = {
      APP_CLIENT_ID: APP_CLIENT_ID,
      CLIENT_SUPP_INV_ID: selection,
    };
    makeApiCall({
      mod: "Report",
      action: "generate-AP-excel",
      payload: payload1,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        console.log("request", payload1);
        console.log("AP report excel fetched", response); // Check the response here

        if (response.XscStatus === 1) {
          const url = `${API_URL}/${response.XscData.URL}`;
          fetch(url)
            .then((response) => response.blob())
            .then((blob) => {
              const link = document.createElement("a");
              link.href = URL.createObjectURL(blob);
              link.download = "selected_account_payable_report.xlsx"; // Set the filename here
              link.click();
            });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  console.log("rows selected", selection);
  console.log("rows ", rows);
  return (
    <>
      <PaySupplier
        show={show}
        onHide={() => setshow(false)}
        invoiceID={invoiceID}
      />
      <div
        style={{
          height: 600,
          width: "100%",
          marginTop: "20px",
          marginBottom: "40px",
        }}
      >
        <DataGrid
          localeText={{
            noRowsLabel: (
              <div className="no_rows_css" style={{ textAlign: "center" }}>
                <img
                  src={searchkeyword ? noDataImage : nodataimage}
                  alt=""
                  style={{ width: "103.26px", height: "100px" }}
                />
                <div
                  id="no_data_found_text"
                  style={{
                    fontFamily: "Inter",
                    fontWeight: 600,
                    fontSize: "22px",
                  }}
                >
                  {searchkeyword
                    ? "No results found in the list."
                    : "No reports have generated yet"}
                </div>
              </div>
            ),
          }}
          rows={rows}
          rowCount={rowcount}
          columns={columns}
          pageSize={pageSize}
          page={page}
          pagination
          paginationMode="server"
          onPageChange={handlepagechange}
          rowsPerPageOptions={[10, 20, 50]}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          disableSelectionOnClick
          classes={{ columnHeader: classes.header, row: classes.row }}
          checkboxSelection
          onSelectionModelChange={handleSelectionChange}
        />
      </div>
      {optionShow && (
        <div
          className="options-dropdown ellipsisOption"
          style={{
            top: optionPosition.top,
            left: optionPosition.left - 190,
          }}
          ref={ellipsisRef}
          onClick={() => {
            handleShow();
          }}
        >
          <a
            id="dropdown_options"
            onClick={() => {
              handleShow();
            }}
            style={{
              textDecoration: "none",
              color: "#535260",
              cursor: "pointer",
            }}
          >
            Pay Supplier
          </a>
        </div>
      )}
    </>
  );
}
