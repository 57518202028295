import { useState, useEffect } from "react";
import { FormCheck, Modal } from "react-bootstrap";
import SearchBar from "../../StaticComponents/SearchBar";
import { makeApiCall } from "../../StaticComponents/API";
export default function AccountReceivableFilter(props) {
  const [customerName, setCustomerName] = useState(true);
  const [invoiceId, setInvoiceId] = useState(false);
  const [customers, setcustomers] = useState([]);
  const token = localStorage.getItem("token");
  const [searchkeyword, setsearchkeyword] = useState("");
  const onSearch = (value) => {
    setsearchkeyword(value);
  };
  const [selectedFilters, setSelectedFilters] = useState({
    // location: [],
    customerName: [],
  });

  const handlereset = () => {
    setSelectedFilters({
      location: [],
      customerName: [],
    });
  };
  const [filtercount, setfiltercount] = useState(0);

  useEffect(() => {
    let count = 0;
    if (selectedFilters.customerName.length > 0) {
      count += 1;
    }
    setfiltercount(count);
    props.onNotify(count);
  }, [selectedFilters]);

  const handleCustomerName = () => {
    setCustomerName(true);
    setInvoiceId(false);
  };
  const handleInvoiceId = () => {
    setCustomerName(false);
    setInvoiceId(true);
  };
  useEffect(() => {
    const payload = {
      APP_CLIENT_ID: localStorage.getItem("CLIENT_ID"),
      SEARCH_KEYWORD: searchkeyword,
      CURRENT_PAGE: 1,
    };
    makeApiCall({
      mod: "Customer",
      action: "customer-name-filter",
      payload: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        console.log("customer names filtered successfully", response);
        if (Array.isArray(response.XscData.CUSTOMER_LIST)) {
          setcustomers(response.XscData.CUSTOMER_LIST);
        } else {
          console.error("Unexpected data", response.XscData.CUSTOMER_LIST);
        }
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, [searchkeyword]);
  const handleCheckboxChange = (filterType, value) => {
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      [filterType]: prevFilters[filterType].includes(value)
        ? prevFilters[filterType].filter((item) => item !== value)
        : [...prevFilters[filterType], value],
    }));
  };
  const handleRemoveFilter = (filterType, value) => {
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      [filterType]: prevFilters[filterType].filter((item) => item !== value),
    }));
  };
  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="filter_text d-flex gap-3"
          >
            Filters
            {filtercount > 0 ? (
              <div>({filtercount} selected)</div>
            ) : (
              <div></div>
            )}
          </Modal.Title>
        </Modal.Header>

        <div id="selected_fil">
          {selectedFilters["customerName"].map((customer) => (
            <div id="selected_filters_bg">
              {customer}
              <svg
                onClick={() => handleRemoveFilter("customerName", customer)}
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  d="M7.99967 14.6654C4.31777 14.6654 1.33301 11.6806 1.33301 7.9987C1.33301 4.3168 4.31777 1.33203 7.99967 1.33203C11.6815 1.33203 14.6663 4.3168 14.6663 7.9987C14.6663 11.6806 11.6815 14.6654 7.99967 14.6654ZM7.99967 7.0559L6.11405 5.17027L5.17125 6.11308L7.05687 7.9987L5.17125 9.8843L6.11405 10.8271L7.99967 8.9415L9.88527 10.8271L10.8281 9.8843L8.94247 7.9987L10.8281 6.11308L9.88527 5.17027L7.99967 7.0559Z"
                  fill="#5D6B82"
                />
              </svg>
            </div>
          ))}
        </div>
        <div id="filter_content_bg">
          <div id="fil_side_menu_bg">
            <div id="fil_side_menu">
              <div className="position-relative d-flex align-items-center">
                {customerName && (
                  <div id="active_fil_indicator" className=""></div>
                )}
                <div
                  id="single_fil_menu"
                  onClick={handleCustomerName}
                  style={{
                    color: customerName && "var(--primaryContainer)",
                    fontWeight: customerName && "600",
                    cursor: "pointer",
                  }}
                >
                  Customer Name
                </div>
              </div>
            </div>
          </div>

          {customerName && (
            <div id="fil_content_bg">
              <SearchBar onSearch={onSearch} />
              {Array.isArray(customers) && customers.length > 0 ? (
                customers.map((customer) => (
                  <div
                    className="d-flex align-items-center justify-content-center"
                    id="fil_single_item"
                    key={customer.CLIENT_CUSTOMER_ID}
                  >
                    <label
                      className="checkbox d-flex align-items-center"
                      id="fil_single_item"
                    >
                      <FormCheck
                        type="checkbox"
                        className="checkbox"
                        checked={selectedFilters.customerName.includes(
                          customer.CUSTOMERS_NAME
                        )}
                        onChange={() =>
                          handleCheckboxChange(
                            "customerName",
                            customer.CUSTOMERS_NAME
                          )
                        }
                      ></FormCheck>
                      <div>{customer.CUSTOMERS_NAME}</div>
                    </label>
                  </div>
                ))
              ) : (
                <div>No Customer Available</div>
              )}
            </div>
          )}
        </div>

        <Modal.Footer>
          <button
            id="reset_btn"
            className="btn"
            onClick={() =>
              setSelectedFilters({
                invoiceId: [],
                customerName: [],
              })
            }
          >
            Reset{" "}
          </button>
          <button id="apply_btn" className="btn" onClick={() => props.onHide()}>
            Apply Filters
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
