import React, { useState, useEffect } from "react";
import "../../../Styles/ProductInfo.css";
import ImagesAndDetails from "../Products/ImagesAndDetails";
import ProductImageDetails from "../Products/ProductImageDetails";
import { useSearchParams } from "react-router-dom";
import CTNSInfo from "../Products/CTNSInfo";
import { useNavigate } from "react-router-dom";
import { makeApiCall } from "../../../StaticComponents/API";
import { ToastContainer } from "react-bootstrap";

const ProductInfo = () => {
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();
  const [prodInfo, setProdInfo] = useState({
    product_details: "",
    prod_media: "",
    total_stock: "",
    to_receive: "",
    to_ship: "",
  });
  let token = localStorage.getItem("token");
  const PRODUCT_ID = localStorage.getItem("PRODUCT_ID")
  const CLIENT_SUPPLIER_ID = localStorage.getItem("CLIENT_SUPPLIER_ID");
  const PROD_ID = localStorage.getItem("PROD_ID")
  console.log(CLIENT_SUPPLIER_ID, PROD_ID);
  const handleBack = () => {
    navigate(`/suppliers/supplier-info`);
  };
  

  useEffect(() => {
    // const payload = {
    //   CLIENT_SUPPLIER_ID: 1,
    //   PRODUCT_ID: 375,
    // };
    const payload={ CLIENT_SUPPLIER_ID: CLIENT_SUPPLIER_ID, PRODUCT_ID: PRODUCT_ID }
    makeApiCall({
      mod: "Products",
      action: "get-product-details",
      payload: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((res) => {
      console.log(res, "prod-info");
      console.log("payload",payload)
      setProdInfo({
        product_details: res.XscData.PRODUCT_DETAILS,
        prod_media: res.XscData.PROD_MEDIA,
        total_stock: res.XscData.TOTAL_STOCK,
        to_receive: res.XscData.TO_RECEIVE,   
        to_ship: res.XscData.TO_SHIP,
      });
    });
  }, []);

  const handleEdit = () => {
    // Store CLIENT_SUPPLIER_ID in localStorage before navigating
    localStorage.setItem("CLIENT_SUPPLIER_ID", CLIENT_SUPPLIER_ID);
    navigate(`/suppliers/supplier-info/product-info/edit-product`);
  };


  return (
    <div id="product_bg" className="">
      <div id="product_topbar_bg" className="" style={{}}>
        <div className="container d-flex justify-content-between">
          <div id="header_name" className="  d-flex">
            <div>
              <svg
              onClick={handleBack}
                style={{cursor:"pointer"}}
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M7.82843 11.0009H20V13.0009H7.82843L13.1924 18.3648L11.7782 19.779L4 12.0009L11.7782 4.22266L13.1924 5.63687L7.82843 11.0009Z"
                  fill="var(--onSurface)"
                />
                <path
                  d="M7.82843 11.0009H20V13.0009H7.82843L13.1924 18.3648L11.7782 19.779L4 12.0009L11.7782 4.22266L13.1924 5.63687L7.82843 11.0009Z"
                  fill="black"
                  fill-opacity="0.2"
                />
                <path
                  d="M7.82843 11.0009H20V13.0009H7.82843L13.1924 18.3648L11.7782 19.779L4 12.0009L11.7782 4.22266L13.1924 5.63687L7.82843 11.0009Z"
                  fill="black"
                  fill-opacity="0.2"
                />
                <path
                  d="M7.82843 11.0009H20V13.0009H7.82843L13.1924 18.3648L11.7782 19.779L4 12.0009L11.7782 4.22266L13.1924 5.63687L7.82843 11.0009Z"
                  fill="black"
                  fill-opacity="0.2"
                />
                <path
                  d="M7.82843 11.0009H20V13.0009H7.82843L13.1924 18.3648L11.7782 19.779L4 12.0009L11.7782 4.22266L13.1924 5.63687L7.82843 11.0009Z"
                  fill="black"
                  fill-opacity="0.2"
                />
              </svg>
            </div>

            <span className="ps-2">
              {" "}
              Product Info
              <div id="edit_supplier_id">{`${prodInfo.product_details.SUPP_PROD_NUM}, ${prodInfo.product_details.CLIENT_PROD_NUM}`}</div>
            </span>
          </div>

          <div className="" style={{ marginRight: "0px" }}>
           
              <svg
              onClick={handleEdit}
                style={{cursor:"pointer"}}
                className="edit_icon"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M5 18.89H6.41421L15.7279 9.57628L14.3137 8.16207L5 17.4758V18.89ZM21 20.89H3V16.6473L16.435 3.21232C16.8256 2.8218 17.4587 2.8218 17.8492 3.21232L20.6777 6.04075C21.0682 6.43127 21.0682 7.06444 20.6777 7.45496L9.24264 18.89H21V20.89ZM15.7279 6.74786L17.1421 8.16207L18.5563 6.74786L17.1421 5.33364L15.7279 6.74786Z"
                  fill="#535260"
                />
              </svg>
            
          </div>
        </div>
      </div>
      <div className="container">
        <ImagesAndDetails prodInfo={prodInfo} />
        <ProductImageDetails prodInfo={prodInfo} />
        <CTNSInfo prodInfo={prodInfo} />
      </div>
      <ToastContainer position="bottom-center" />
    </div>
  );
};

export default ProductInfo;
