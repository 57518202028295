import { useState, useEffect } from "react";
import TextInputWithAll from "../Inputs/TextInputWithAll";
import TextInput from "../Inputs/TextInput";
import { makeApiCall } from "../../StaticComponents/API";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import Error from "../../assets/Error.png";

const Profile = () => {
  const navigate = useNavigate();
  const [file, setFile] = useState();
  const [image, setImage] = useState(null);
  const [fileName, setFileName] = useState("");
  const [userId, setUserId] = useState(localStorage.getItem("USER_ID") || "");
  const [firstName, setFirstName] = useState(
    localStorage.getItem("FIRST_NAME") || ""
  );
  const [email, setEmail] = useState(localStorage.getItem("EMAIL") || "");
  const [middleName, setMiddleName] = useState(
    localStorage.getItem("MIDDLE_NAME") || ""
  );
  const [lastName, setLastName] = useState(
    localStorage.getItem("LAST_NAME") || ""
  );
  const [profilePicture, setProfilePicture] = useState(
    localStorage.getItem("PROFILE_IMAGE") || ""
  );
  const token = localStorage.getItem("token");
  let clientID = localStorage.getItem("CLIENT_ID");

  const reqData = {
    USER_ID: userId,
    FIRST_NAME: firstName,
    MIDDLE_NAME: middleName,
    LAST_NAME: lastName,
    EMAIL: email,
    profile_pic: profilePicture,
  };

  console.log("Payload", reqData);

  const formData = new FormData();
  formData.append("file", image);
  formData.append("mod", "Profile");
  formData.append("action", "update-profile");
  formData.append("payload", JSON.stringify(reqData));

  console.log(image, "file");
  console.log(formData, "formdata");

  const [data, setData] = useState(reqData);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };

  const handleCancel = (e) => {
    navigate("/dashboard");
  };
  const handleChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      console.log("Selected file:", e.target.files[0]);
      setFile(URL.createObjectURL(e.target.files[0]));
      setImage(e.target.files[0]);
      setFileName(e.target.files[0].name);
      // localStorage.setItem("PROFILE_IMAGE", fileName);
    } else {
      console.error("No file selected");
    }
  };

  const handleSubmit = (e) => {
    const checkRequiredFields = () => {
      let missingFields = [];
      if (!reqData.FIRST_NAME) missingFields.push("Enter your First Name");
      if (!reqData.EMAIL) missingFields.push("Enter your Email");

      return missingFields;
    };
    localStorage.setItem("FIRST_NAME", reqData.FIRST_NAME);
    localStorage.setItem("LAST_NAME", reqData.LAST_NAME);
    localStorage.setItem("MIDDLE_NAME", reqData.MIDDLE_NAME);
    localStorage.setItem("PROFILE_IMAGE", reqData.profile_pic);

    const missingFields = checkRequiredFields(reqData);

    console.log(formData, "formdata");

    axios({
      method: "post",
      url: "https://dev-api.manex.xcelcorp.com",
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        console.log(res, "profile updated successfully");
        if (missingFields.length > 0) {
          const errorMessage = `Please fill in the following fields: ${missingFields.join(
            ", "
          )}`;
          toast.error(errorMessage);
        } else {
          // All fields are filled, handle the response
          res.data.XscStatus !== 0
            ? toast.success(res.data.XscMessage) &&
              navigate("/dashboard")
            : toast.error(res.data.XscMessage);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div id="">
      <div
        id="suppliers_topbar_bg"
        style={{ paddingLeft: "0", paddingRight: "0" }}
      >
        <div className=" d-flex justify-content-between align-items-center">
          <div id="dashboard_header" style={{ paddingLeft: "72px" }}>
            {" "}
            Profile
          </div>
        </div>
      </div>
      <div className="container" style={{ paddingLeft: "72px" }}>
        <div id="add_profile_bg">
          <form>
            <div className="col-12">
              <label
                style={{ width: "380px" }}
                htmlFor="file-input"
                className="d-flex align-items-center"
                id=""
              >
                <div id="upload_img_bg" style={{ cursor: "pointer" }}>
                  {file ? (
                    // If a file is selected, show the file preview
                    <img
                      src={file}
                      alt="Uploaded file"
                      onError={(e) => {
                        e.target.onerror = null; // Prevent infinite loop in case of failure
                        setFile(null); // Reset file if the image is invalid
                      }}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  ) : profilePicture ? (
                    // If there's a profile picture from the server, display it
                    <img
                      src={`${process.env.REACT_APP_API_URL}/media/${profilePicture}`}
                      alt="Profile"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                      onError={(e) => {
                        e.target.onerror = null; // Prevent infinite loop in case of failure
                        setProfilePicture(null); // Reset profilePicture if the image fails to load
                      }}
                    />
                  ) : (
                    // Display the SVG if no image or file is present
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="40"
                      height="40"
                      viewBox="0 0 40 40"
                      fill="none"
                    >
                      <path
                        d="M34.9987 25V30H39.9987V33.3333H34.9987V38.3333H31.6654V33.3333H26.6654V30H31.6654V25H34.9987ZM35.0124 5C35.9254 5 36.6654 5.74158 36.6654 6.65567V21.6667H33.332V8.33333H6.66536V31.665L23.332 15L28.332 20V24.715L23.332 19.714L11.377 31.6667H23.332V35H4.98503C4.07211 35 3.33203 34.2585 3.33203 33.3443V6.65567C3.33203 5.74127 4.09088 5 4.98503 5H35.0124ZM13.332 11.6667C15.173 11.6667 16.6654 13.159 16.6654 15C16.6654 16.841 15.173 18.3333 13.332 18.3333C11.4911 18.3333 9.9987 16.841 9.9987 15C9.9987 13.159 11.4911 11.6667 13.332 11.6667Z"
                        fill="#5A5892"
                      />
                    </svg>
                  )}
                </div>

                <div id="upload_img_text">
                  {/* {fileName}{" "} */}
                  {/* {file  ? (
                    <div id="change_img_text">Change</div>
                  ) : (
                    <>
                      Upload Supplier Logo
                      <div id="img_size">It should be 400 by 400 pixels</div>
                      <div id="upload_img_button">Upload</div>
                    </>
                  )} */}
                  <>
                    Upload Profile Picture
                    <div id="img_size">It should be 400 by 400 pixels</div>
                    <div id="upload_img_button">
                      {file ? "Change" : "Upload"}
                    </div>
                  </>
                </div>
              </label>
              <TextInputWithAll
                name="file-input"
                type="file"
                onChange={handleChange}
                id="file-input"
                style={{ display: "none" }}
                maxLength={1}
                accept="image/*"
              />
            </div>

            <div className="row-5" id="add_supplier_input">
              <div className="position-relative" style={{ width: "100%" }}>
                <label>First Name*</label>
                <br />
                <TextInput
                  type="text"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  //   onChange={handleInputChange}
                  //   maxLength={maxChars}
                />
              </div>
            </div>

            <div className="row-5" id="add_supplier_input">
              <div className="position-relative" style={{ width: "100%" }}>
                <label>Middle Name</label>
                <br />
                <TextInput
                  type="text"
                  value={middleName}
                  onChange={(e) => setMiddleName(e.target.value)}
                />
              </div>
            </div>

            <div className="row-5" id="add_supplier_input">
              <div className="position-relative" style={{ width: "100%" }}>
                <label>Last Name</label>
                <br />
                <TextInput
                  type="text"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </div>
            </div>
            {/* <div className="row-6" id="add_supplier_input">
              <div className="position-relative" style={{ width: "100%" }}>
                <label>Email Address*</label>
                <br />
                <TextInput
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </div>  */}
          </form>
        </div>
      </div>
      <div id="add_supplier_footer" className="">
        <div id="add_supplier_buttons" className="container">
          <div></div>
          <div
            className="d-flex align-items-center justify-content-right "
            style={{ gap: "12px" }}
          >
            <div id="cancel" onClick={handleCancel}>
              Cancel{" "}
            </div>
            <div id="submitsupplier" type="submit" onClick={handleSubmit}>
              Update Profile
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
        position="bottom-center"
        style={{ width: "max-content" }}
      />
    </div>
  );
};

export default Profile;
