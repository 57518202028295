import React from 'react';

const USDCurrency = ({ amount }) => {
  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(amount);
  };

  return formatCurrency(amount);
};


export default USDCurrency;