import React, { useEffect, useState } from "react";
import SupplierHeader from "./SupplierHeader";
import SupplierDashboard from "./SupplierDashboard";

function SupplierInfoDashboardLayout() {
  const [activeTab, setActiveTab] = useState(
    localStorage.getItem("activeTab") === ""
      ? "Overview"
      : localStorage.getItem("activeTab")
  );

  const handleTabChange = (tab) => {
    localStorage.setItem("activeTab", tab);
    setActiveTab(tab);
  };
  return (
    <div>
      <SupplierHeader activeTab={activeTab} onTabChange={handleTabChange} />
      <SupplierDashboard activeTab={activeTab} onTabChange={handleTabChange} />
    </div>
  );
}
export default SupplierInfoDashboardLayout;
