import React, { useState } from 'react'
import ProductTable from './ProductTable';
import SearchBar from '../../StaticComponents/SearchBar';
import Filter from '../../StaticComponents/Filter';
import GlobalProductSearchFilter from './GlobalProductSearchFilter';
import default_image from "../../assets/Product_Package.png"
const SearchProducts = () => {

  const [modalShow, setModalShow] = useState(false);
const [searchkeyword,setsearchkeyword]=useState("")
const onSearch=(value)=>{
  setsearchkeyword(value)
}
  return (
    <div id="cust_bg" >
    <div
        id="suppliers_topbar_bg"
        style={{ paddingLeft: "0", paddingRight: "0" }}
        >
         <div className="container d-flex justify-content-between align-items-center">
          <div id="dashboard_header">Products</div>
            
        </div>
      </div>
    <div className="container">
      {" "}
      
      <div className="d-flex" id="supp_filters">
        <div>
          <SearchBar onSearch={onSearch} />
        </div>
        <div onClick={() => setModalShow(true)} style={{ cursor: "pointer" }}>
          <Filter />
        </div>
        <GlobalProductSearchFilter show={modalShow} onHide={() => setModalShow(false)} />
      </div>
      <div id="supp_tab_bg">
        <ProductTable searchkeyword={searchkeyword} />
      </div>
    </div>
  </div>
  )
}

export default SearchProducts