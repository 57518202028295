import React from "react";

const TextInputWithAll = ({
	type,
	value,
	name,
	onChange,
	className,
	placeholder,
	autoComplete,
	id,
	style,
	maxLength,
	accept,
	ref,
	min,
	max,
	tag
}) => {
	return (
		<div className="d-flex" style={{position:"relative"}}>
			<input
				type={type}
				value={value}
				name={name}
				onChange={onChange}
				className={className}
				placeholder={placeholder}
				autoComplete={autoComplete}
				id={id}
				style={style}
				maxLength={maxLength}
				accept={accept}
				ref={ref}
				min={min}
				max={max}
			/>
			<div style={{position:"absolute" ,left:"15px",top:"8px"}}>{tag}</div>
			
		</div>
	);
};

export default TextInputWithAll;
