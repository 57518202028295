import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { SvgIcon } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { makeApiCall } from "../../StaticComponents/API";
import noDataImage from "../../assets/Frame.svg";
import nodataImage from "../../assets/BigImage.png";
// import { useState } from "react";
import DataGridSkeleton from "../../StaticComponents/SkeletonLoading";

export default function SupplierTable({
  searchQuery,
  notify,
  onFavoriteCountUpdate,
  ...props
}) {
  const [pageSize, setPageSize] = React.useState(10); // Default page size
  const [page, setpage] = useState(0);
  const [modalShow, setModalShow] = React.useState(false);
  const [suppliers, setSuppliers] = React.useState([]);
  // const [page, setPage] = useState(0);
  const [rows, setRows] = React.useState([]);
  const navigate = useNavigate();
  // const [page,setpage]=React.useState(0);
  const [isLoading, setisLoading] = React.useState(false);
  const [total, settotal] = React.useState(0);
  const [loading, setLoading] = useState(false);
  const [favCount, setFavCount] = useState(0);

  console.log(notify);
  const handlepagechange = (newpage) => {
    setpage(newpage);
  };
  const handlepagesizechange = (num) => {
    setPageSize(num);
    setpage(0);
  };
  console.log("page pagesize", page, pageSize);
  const payload = {
    APP_CLIENT_ID: localStorage.getItem("CLIENT_ID"),
    CURRENT_PAGE: page + 1,
    ITEM_PER_PAGE: pageSize,
    SEARCH_KEYWORD: searchQuery,
  };

  let token = localStorage.getItem("token");
  const columns = [
    {
      field: "SUPPLIER_ID",
      headerName: "Sup.ID",
      width: 120,
      height: 80,
      cellClassName: "sticky-column",
    },
    {
      field: "SUPPLIER_NAME",
      headerName: "Supplier Name",
      width: 270,
      editable: false,
      align: "left",
      height: 80,
    },
    {
      field: "EMAIL",
      headerName: "Email",
      width: 270,
      editable: false,
      align: "left",
      height: 80,
    },

    {
      field: "CONTACT_NUMBER",
      headerName: "Phone Number",
      type: "number",
      width: 250,
      editable: false,
      align: "left",
      height: 80,
    },
    {
      field: "LOCATION",
      headerName: "Location",
      width: 170,
      editable: false,
      align: "left",
      height: 80,
    },
    {
      field: "IS_SUPP_FAV",
      headerName: " ",
      width: 80,
      editable: false,
      align: "center",
      headerClassName: "more_svg",
      renderCell: (params) =>
        createFavIcon(params.row.IS_SUPP_FAV, (e) =>
          handleFav(e, params.row.id)
        ),
      height: 80,
    },
    {
      field: "EDIT",
      headerName: " ",
      width: 80,
      height: 80,
      editable: false,
      align: "center",
      headerClassName: "more_svg",
      renderCell: (params) => createEditIcon(params),
    },
  ];

  const fetchSuppliers = async () => {
    setLoading(true);
    const payload = {
      APP_CLIENT_ID: localStorage.getItem("CLIENT_ID"),
      CURRENT_PAGE: page + 1,
      ITEM_PER_PAGE: pageSize,
      SEARCH_KEYWORD: searchQuery,
      FILTER: {
        CLIENT_SUPP_IDS: props.filters.supplierNameIds,
        LOCATIONS: props.filters.locationIds,
        SUPP_STATUS_IDS: props.filters.supplierStatusIds,
      },
    };
    try {
      const response = await makeApiCall({
        mod: "Supplier",
        action: "list-supplier",
        payload: payload,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log(response, "response");
      console.log(payload, "PAYLOAD");
      const suppliers = response.XscData.SUPPLIER_LIST;

      if (Array.isArray(suppliers)) {
        const mappedRows = suppliers.map((supplier) => ({
          id: supplier.CLIENT_SUPPLIER_ID,
          SUPPLIER_ID: supplier.SUPP_NUM,
          SUPPLIER_NAME: supplier.SUPPLIER_NAME,
          EMAIL: supplier.EMAIL,
          CONTACT_NUMBER: supplier.CONTACT_NUMBER,
          LOCATION: supplier.LOCATION,
          IS_SUPP_FAV: supplier.IS_SUPP_FAV === "Y",
          EDIT: createEditIcon(supplier.CLIENT_SUPPLIER_ID),
        }));

        settotal(response.XscData.TOTAL_RECORDS);
        setRows(mappedRows);

        // Initialize favorite count
        const initialFavCount = suppliers.filter(
          (supplier) => supplier.IS_SUPP_FAV === "Y"
        ).length;
        setFavCount(initialFavCount);
      } else {
        console.error("Suppliers data is not an array or is missing");
      }
    } catch (error) {
      console.error("Error fetching suppliers:", error);
    } finally {
      setLoading(false);
    }
  };
  const handleEdit = (params) => {
    console.log("hello");
    localStorage.setItem("CLIENT_SUPPLIER_ID", params.row.id);
    localStorage.setItem("CLIENT_SUPPLIER_NAME", params.row.SUPPLIER_NAME);
    localStorage.setItem("CLIENT_SUPPLIER_NUM", params.row.SUPPLIER_ID);
    navigate("/suppliers/edit-supplier");
  };
  useEffect(() => {
    setpage(0);
  }, [searchQuery]);

  useEffect(() => {
    fetchSuppliers();
  }, [page, searchQuery, pageSize,props.filters]);

  const createEditIcon = (params) => {
    console.log(params);
    return (
      <div>
        <svg
          onClick={(e) => {
            e.stopPropagation();
            handleEdit(params);
          }} // Prevents row click event
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M5 18.89H6.41421L15.7279 9.57628L14.3137 8.16207L5 17.4758V18.89ZM21 20.89H3V16.6473L16.435 3.21232C16.8256 2.8218 17.4587 2.8218 17.8492 3.21232L20.6777 6.04075C21.0682 6.43127 21.0682 7.06444 20.6777 7.45496L9.24264 18.89H21V20.89ZM15.7279 6.74786L17.1421 8.16207L18.5563 6.74786L17.1421 5.33364L15.7279 6.74786Z"
            fill="var(--onSurfaceVariant)"
          />
        </svg>
      </div>
    );
  };

  const handleFav = (e, id) => {
    e.stopPropagation();
    const foundRow = rows.find((r) => r.id === id);
    if (!foundRow) return;

    const updatedFavStatus = !foundRow.IS_SUPP_FAV;
    const payload = {
      CLIENT_SUPP_ID: id,
      IS_FAVOURITE: updatedFavStatus ? "Y" : "N",
    };

    // Optimistically update the favorite status
    setRows((prevRows) =>
      prevRows.map((row) => {
        if (row.id === id) {
          return { ...row, IS_SUPP_FAV: updatedFavStatus };
        }
        return row;
      })
    );

    // Update the favorite count optimistically
    setFavCount((prevCount) => {
      const newCount = updatedFavStatus
        ? prevCount + 1
        : Math.max(prevCount - 1, 0);
      onFavoriteCountUpdate(newCount); // Notify parent about the updated count
      return newCount;
    });

    // Call API to persist the change
    makeApiCall({
      mod: "Supplier",
      action: "mark-supplier-favourite",
      payload: payload,
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        console.log("Favorite status updated", res);
      })
      .catch((error) => {
        console.error("API Error:", error);
        setRows((prevRows) =>
          prevRows.map((row) => {
            if (row.id === id) {
              return { ...row, IS_SUPP_FAV: !updatedFavStatus };
            }
            return row;
          })
        );
        setFavCount((prevCount) =>
          updatedFavStatus ? prevCount - 1 : prevCount + 1
        ); // Revert count
      });
  };

  const createFavIcon = (isFav, onClick) => (
    <svg
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      {isFav ? (
        <path
          d="M12.001 4.52853C14.35 2.42 17.98 2.49 20.2426 4.75736C22.5053 7.02472 22.583 10.637 20.4786 12.993L11.9999 21.485L3.52138 12.993C1.41705 10.637 1.49571 7.01901 3.75736 4.75736C6.02157 2.49315 9.64519 2.41687 12.001 4.52853Z"
          fill="#BA3CBB"
        />
      ) : (
        <path
          d="M12.001 4.52853C14.35 2.42 17.98 2.49 20.2426 4.75736C22.5053 7.02472 22.583 10.637 20.4786 12.993L11.9999 21.485L3.52138 12.993C1.41705 10.637 1.49571 7.01901 3.75736 4.75736C6.02157 2.49315 9.64519 2.41687 12.001 4.52853ZM18.827 6.1701C17.3279 4.66794 14.9076 4.60701 13.337 6.01687L12.0019 7.21524L10.6661 6.01781C9.09098 4.60597 6.67506 4.66808 5.17157 6.17157C3.68183 7.66131 3.60704 10.0473 4.97993 11.6232L11.9999 18.6543L19.0201 11.6232C20.3935 10.0467 20.319 7.66525 18.827 6.1701Z"
          fill="var(--onSurfaceVariant)"
        />
      )}
    </svg>
  );

  localStorage.setItem("CLIENT_SUPPLIER_ID", "");
  localStorage.setItem("CLIENT_SUPPLIER_NAME", "");
  const handleRowClick = (params) => {
    // const id = params.row.id;

    navigate(`/suppliers/supplier-info`);
    localStorage.setItem("CLIENT_SUPPLIER_ID", params.row.id);
    localStorage.setItem("CLIENT_SUPPLIER_NAME", params.row.SUPPLIER_NAME);
    localStorage.setItem("CLIENT_SUPPLIER_NUM", params.row.SUPPLIER_ID);
  };

  return (
    <div style={{ height: 600, width: "100%", marginTop: "20px" }}>
      <DataGrid
        localeText={{
          noRowsLabel: (
            <div className="no_rows_css" style={{ textAlign: "center" }}>
              <div
                id="no_data_found_text"
                style={{
                  fontFamily: "Inter",
                  fontWeight: 600,
                  fontSize: "22px",
                }}
              >
                {searchQuery ? (
                  <div style={{ textAlign: "center" }}>
                    <img
                      src={noDataImage}
                      alt="No Data"
                      style={{ width: "192px", height: "200px" }}
                    />

                    <p
                      style={{
                        fontWeight: 500,
                        fontSize: "22px",
                        lineHeight: "28px",
                        textAlign: "center",
                        marginTop: "20px",
                      }}
                    >
                      No results found in the list.
                    </p>
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "100%",
                    }}
                  >
                    <p style={{ fontWeight: "600", fontSize: "32px" }}>
                      Add a new supplier
                    </p>
                    <p style={{ fontWeight: "400", fontSize: "16px" }}>
                      Start adding a supplier. Once done, suppliers will be
                      listed here.
                    </p>
                    <div
                      className="text-decoration-none"
                      onClick={(e) => {
                        e.stopPropagation(); // Stop the row click event from triggering
                        navigate("/suppliers/add-supplier"); // Programmatically navigate to the link
                      }}
                    >
                      <button className="btn" id="add_supplier_button">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M11 11V5H13V11H19V13H13V19H11V13H5V11H11Z"
                            fill="#F7F7F7"
                          />
                        </svg>
                        Add Supplier
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          ),
        }}
        rows={rows}
        onRowClick={handleRowClick}
        columns={columns}
        pageSize={pageSize}
        page={page}
        pagination
        paginationMode="server"
        rowCount={total}
        rowsPerPageOptions={
          rows.length < 50
            ? [10, 20, 50]
            : rows.length > 20
            ? [10, 20, rows.length]
            : rows.length > 10
            ? [10, rows.length]
            : [rows.length]
        }
        onPageChange={handlepagechange}
        onPageSizeChange={handlepagesizechange}
        loading={loading}
        disableSelectionOnClick
        style={{ cursor: "pointer" }}
      />
    </div>
  );
}
