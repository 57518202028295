import React, { useEffect, useState } from "react";
import { FormLabel, Modal } from "react-bootstrap";
import SearchBar from "../../../StaticComponents/SearchBar";
import { DataGrid } from "@mui/x-data-grid";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import { makeApiCall } from "../../../StaticComponents/API";
import "../../../Styles/CreatePO.css";
import { useSearchParams } from "react-router-dom";
function SearchPO(props) {
  const APP_CLIENT_ID = localStorage.getItem("CLIENT_ID");
  const token = localStorage.getItem("token");
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [total, settotal] = useState(0);
  const columns = [
    {
      field: "Select",
      headerName: "",
      width: 50,
      align: "center",
      sortable: false,
      renderCell: (params) => (
        <FormControl>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            name="radio-buttons-group"
            onChange={() => setSelectedPOId(params.row.id)}
            value={selectedPOId === params.row.id ? params.row.id : ""}
          >
            <FormControlLabel
              value={params.row.id}
              control={<Radio />}
              label=""
            />
          </RadioGroup>
        </FormControl>
      ),
      renderHeader: () => <div></div>,
    },
    {
      field: "orderid",
      headerName: "OrderID",
      width: 120,
      align: "center",
    },

    {
      field: "TOTAL_ITEM",
      headerName: "Total Item",
      width: 120,
      align: "center",
    },
    {
      field: "TOTAL_CTNS",
      headerName: "Total CTNS",
      width: 120,
      align: "center",
    },
    {
      field: "TOTAL_AMOUNT",
      headerName: "Total Amount",
      width: 150,
      type: "number",
      align: "center",
    },

    {
      field: "CREATED_ON",
      headerName: "Created On",
      width: 150,
      align: "center",
    },
  ];
  const [selectedPOId, setSelectedPOId] = useState(null);
  const [rows, setRows] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [searchkeyword, setsearchkeyword] = useState("");
  const CLIENT_SUPPLIER_ID = localStorage.getItem("CLIENT_SUPPLIER_ID");

  const onSearch = (value) => {
    setsearchkeyword(value);
    getData();
  };
  function convertTimestampToDate(timestamp) {
    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
    const day = String(date.getDate()).padStart(2, "0");

    return `${day}-${month}-${year}`;
  }
  const getData = () => {
    const payload = {
      CLIENT_SUPP_ID: CLIENT_SUPPLIER_ID,
      APP_CLIENT_ID: APP_CLIENT_ID,
      SEARCH_KEYWORD: searchkeyword,
      CURRENT_PAGE: page + 1,
      ITEMS_PER_PAGE: pageSize,
    };
    makeApiCall({
      mod: "Supplier",
      action: "select_po",
      payload: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        console.log("PAYLOAD FOR SELECT PO", payload);
        console.log("RESPONSE FOR SELECT PO", res);
        const PO = res.XscData.PURCHASE_ORDER_DETAILS;
        settotal(res.XscData.TOTAL_PO_PER_SUPP);
        if (Array.isArray(PO) && PO.length > 0) {
          const mappedRows = PO.map((row) => ({
            id: row.ORDER_ID,
            orderid: row.ORDER_NUM,
            TOTAL_ITEM: row.TOTAL_ITEMS,
            TOTAL_CTNS: row.TOTAL_CTNS,
            TOTAL_AMOUNT: row.TOTAL_AMOUNT || "N/A",
            CREATED_ON: row.CREATED_ON
              ? convertTimestampToDate(row.CREATED_ON)
              : "N/A",
          }));
          setRows(mappedRows);
        } else {
          setRows([]); // Clear rows if no data
        }
      })
      .catch((error) => {
        console.error("Error fetching PO details:", error);
      });
  };

  useEffect(() => {
    getData();
  }, [searchkeyword, props.show, page, pageSize]);
  useEffect(() => {
    setsearchkeyword("");
  }, [props.show]);
  const handleConfirmSelection = () => {
    console.log("PO SELECTED SUCCESSFULLY");

    props.onSelect(selectedRows);

    props.onHide();
    setSelectedRows([]);
    setSelectedPOId(null);
  };

  return (
    <div>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div id="search_po_bg">
          <div id="search_po_text">Select a PO</div>
          <div>
            <SearchBar onSearch={onSearch} />
          </div>
          <div
            style={{
              // height: "200px",
              maxHeight: "fit-content",
              //   width: "100%",
              marginTop: "20px",
            }}
          >
            <DataGrid
              rows={rows}
              columns={columns}
              rowHeight={80}
              autoHeight
              pageSize={pageSize}
              rowCount={total}
              page={page}
              pagination
              paginationMode="server"
              onSelectionModelChange={(newSelection) => {
                setSelectedRows(newSelection);
              }}
              rowsPerPageOptions={[10, 20, 50]}
              onPageSizeChange={(newPageSize) => {
                setPageSize(newPageSize);
                setPage(0);
              }}
              onPageChange={(newPage) => setPage(newPage)}
            />
          </div>
        </div>
        <div id="search_po_footer">
          <div
            id="cancel"
            onClick={() => {
              setSelectedRows([]);
              props.onHide();
              setSelectedPOId(null);
            }}
          >
            Cancel
          </div>
          <div id="submitsupplier" onClick={handleConfirmSelection}>
            Confirm Selection
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default SearchPO;
