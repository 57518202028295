import React, { useEffect, useState } from "react";
import SupplierHeader from "./SupplierHeader";
import SearchBar from "../../StaticComponents/SearchBar";
import Filter from "../../StaticComponents/Filter";
import "../../Styles/SupplierProductPage.css";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import prodImage from "../../assets/table-header.png";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Container } from "react-bootstrap";
import SupplierFilter from "./SupplierFilter";
import ProductFilter from "./Products/ProductFilter";
import product_package from "../../assets/Product_Package.png";

import {
  useNavigate,
  useParams,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import { makeApiCall } from "../../StaticComponents/API";

const SupplierProductPage = () => {
  const [modalShow, setModalShow] = useState(false);
  const navigate = useNavigate();

  const CLIENT_SUPPLIER_ID = localStorage.getItem("CLIENT_SUPPLIER_ID");
  const [notify, setnotify] = useState(0);
  const onNotify = (value) => {
    setnotify(value);
  };

  const token = localStorage.getItem("token");
  const CLIENT_ID = localStorage.getItem("CLIENT_ID");
  const [products, setProducts] = useState([]);
  const [currPage, setCurrPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [showText, setShowText] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const fetchProducts = () => {
    if (loading || !hasMore) return;

    setLoading(true);
    const payload = {
      CLIENT_SUPPLIER_ID: CLIENT_SUPPLIER_ID,
      CURRENT_PAGE: currPage,
      APP_CLIENT_ID: CLIENT_ID,
      SEARCH_KEYWORD: searchQuery,
      FILTER: {
        PRICE: {
          MAX: notify.ProductPrice
            ? Math.max(
                ...notify.ProductPrice.map((price) =>
                  parseFloat(price.replace(/[^\d.-]/g, ""))
                )
              )
            : undefined,
          MIN: notify.ProductPrice
            ? Math.min(
                ...notify.ProductPrice.map((price) =>
                  parseFloat(price.replace(/[^\d.-]/g, ""))
                )
              )
            : undefined,
        },
        STATUS:
          notify.ProductStatus && notify.ProductStatus.length > 0
            ? notify.ProductStatus[0]
            : undefined,
      },
    };
    console.log(payload);

    makeApiCall({
      mod: "Products",
      action: "list-supplier-product",
      payload: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        console.log(res, "list-supplier-products");
        console.log("payload", payload);
        if (res.XscData.PRODUCTS.length > 0) {
          setProducts((prevProducts) => [
            ...prevProducts,
            ...res.XscData.PRODUCTS,
          ]);
          setCurrPage((prevPage) => prevPage + 1);
        } else {
          setHasMore(false);
        }

        setLoading(false);
        setShowText(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
        setShowText(false);
      });
  };

  useEffect(() => {
    fetchProducts();
  }, [searchQuery, notify]);

  useEffect(() => {
    let debounceTimeout;

    const handleScroll = () => {
      clearTimeout(debounceTimeout);

      debounceTimeout = setTimeout(() => {
        if (
          window.innerHeight + window.scrollY >=
            document.body.offsetHeight - 500 &&
          !loading &&
          hasMore
        ) {
          fetchProducts();
        }
      }, 1000);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      clearTimeout(debounceTimeout);
    };
  }, [loading, hasMore]);

  const handleImageError = (e) => {
    e.target.src = product_package;
  };

  // console.log(id);
  // const handleProductClick = (product) => {
  //   const { PROD_ID } = product;
  //   localStorage.setItem("CLIENT_SUPPLIER_ID", id);
  //   navigate(`/suppliers/supplier-info/product-info?id=${PROD_ID}`); // Navigate to the product-info page with product ID
  // };
  const PROD_ID = localStorage.getItem("PROD_ID");

  const handleProductClick = (PROD_ID) => {
    // Ensure this is correct
    console.log("Navigating to Product Info with ID:", PROD_ID);
    if (PROD_ID) {
      localStorage.setItem("PRODUCT_ID", PROD_ID);
      navigate(`/suppliers/supplier-info/product-info`);
    } else {
      console.error("Product ID not found in the row data");
    }
  };

  const handleSearch = (query) => {
    setSearchQuery(query);
  };

  return (
    <div>
      <div className="container">
        <div>
          <div
            className="d-flex justify-content-between"
            id="supp_filters"
            style={{ marginTop: "20px" }}
          >
            <div className="d-flex">
              <div>
                <SearchBar onSearch={handleSearch} />
              </div>
              <div
                onClick={() => setModalShow(true)}
                style={{ cursor: "pointer" }}
              >
                <Filter
                  show={modalShow}
                  onHide={() => setModalShow(false)}
                  onNotify={onNotify}
                />
              </div>
              <ProductFilter
                show={modalShow}
                onHide={() => setModalShow(false)}
                onNotify={onNotify}
              />
            </div>
            <div id="supplier_header_buttons">
              <a href="/products/import-products">
                <button className="btn" id="import_supplier_button">
                  Import Products
                </button>
              </a>

              <a className="text-decoration-none">
                {" "}
                <button
                  className="btn"
                  id="add_supplier_button"
                  onClick={() => {
                    navigate(`/suppliers/add-product/`);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M11 11V5H13V11H19V13H13V19H11V13H5V11H11Z"
                      fill="#F7F7F7"
                    />
                  </svg>
                  Add Product
                </button>
              </a>
            </div>
          </div>
        </div>
        <div
          id="po_table"
          style={{
            // height: 600,
            width: "100%",
            marginBottom: "80px",
            position: "relative",
            overflow: "hidden",
          }}
        >
          <div id="supp_card_container" className="">
            <div id="supp_prod_card" className="">
              {/* <Container> */}
              <Row className="row">
                {products.map((product, index) => {
                  return (
                    // {loading ? <Skeleton count={5} /> : }
                    <Col lg={3} key={index}>
                      <div
                        className="col1"
                        onClick={() => handleProductClick(product.PROD_ID)}
                      >
                        {/* <div  className=" " href="/suppliers/supplier-info/product-info"> */}
                        <div className="inner_div_img">
                          <img
                            src={
                              process.env.REACT_APP_API_URL +
                              "media/" +
                              product.MM_FILE_NAME
                            }
                            onError={handleImageError}
                          />
                        </div>
                        <div className="inner_text_div">
                          <div className="inner_text_one">
                            {/* {loading ? (
                                  <Skeleton count={1} /> */}
                            {/* ) : */}
                            {product.SUPP_PROD_NUM === null
                              ? ""
                              : product.SUPP_PROD_NUM}

                            {product.SUPP_PROD_NUM !== null &&
                            product.CLIENT_PROD_NUM !== null
                              ? ","
                              : ""}
                            {product.CLIENT_PROD_NUM === null
                              ? ""
                              : product.CLIENT_PROD_NUM}
                          </div>
                          <div className="inner_text_two">
                            {product.PRODUCT_NAME}
                          </div>
                          <div className="inner_text_three">
                            {product.CURRENCY_SYMBOL}
                            {product.PRICE === null ? 0 : product.PRICE} |{" "}
                            {product.QTY === null ? 0 : product.QTY} - Qty Inv
                          </div>
                        </div>
                        {/* </a> */}
                      </div>
                      {/* <Skeleton count={1}></Skeleton> */}
                    </Col>
                  );
                })}
              </Row>
              {/* </Container> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SupplierProductPage;
