import React from "react";
import { Modal } from "react-bootstrap";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { makeApiCall } from "../../../StaticComponents/API";

const DeleteProduct = (props) => {
  let [searchParams] = useSearchParams();
  let token = localStorage.getItem("token");
  console.log(props.ProdID, "productid");
  let SUPPLIER_ID = localStorage.getItem("CLIENT_SUPPLIER_ID");
  const PRODUCT_ID = props.ProdID;
  const navigate = useNavigate("");
  const deleteProduct = () => {
    const payload = {
      CLIENT_SUPP_ID: SUPPLIER_ID,
      PROD_ID: PRODUCT_ID,
    };
    makeApiCall({
      mod: "Products",
      action: "delete_product",
      payload: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      console.log(res, "deleted");
      if (res.XscStatus === 1) {
        toast.success("Product Deleted Successfully!");
        props.onHide();
        localStorage.setItem("activeTab", "Products");
        navigate("/suppliers/supplier-info");
      } else {
        toast.error("Failed to delete product. Please try again.");
      }
    })
    .catch((error) => {
      console.error("Error deleting product:", error);
      toast.error("An error occurred while deleting the product.");
    });
  };
  

  return (
    <div>
      {" "}
      <Modal {...props}>
        <div>
          <div id="delete_Supplier">Delete Product</div>
          <div id="delete_supplier_content">
            Are you sure you want to delete this Particular Product? <br />
            It cannot be undone.
          </div>
          <div id="delete_footer">
            <div id="delete_cancel" onClick={() => props.onHide()}>
              Cancel
            </div>
            <div id="delete_submit" onClick={deleteProduct}>
              Delete Permanently
            </div>
          </div>
        </div>
      </Modal>
      <ToastContainer position="bottom-center" style={{width:"max-content"}} />
    </div>
  );
};

export default DeleteProduct;
