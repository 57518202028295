import React from 'react';

// const FormatCurrency = (props) => {
//   const formatCurrency = (amount, currency) => {
//     return new Intl.NumberFormat('en-US', {
//       style: 'currency',
//       currency: currency, // You can adjust this to the correct currency
//       minimumFractionDigits: 2,
//     }).format(amount);
//   };
// console.log("Fornat Currency in forat",props.country)
//   return formatCurrency(props.amount);
// };

const FormatCurrency = ({ amount, currency }) => {
  console.log(currency);
  if (!currency) {
    console.error("Currency code is missing");
    return amount; // Return the amount directly if no currency is provided
  }

  const formatCurrency = (amount, currency) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currency, // Ensure this is a valid currency code
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(amount);
  };

  return formatCurrency(amount, currency);
};

export default FormatCurrency;