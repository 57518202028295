import React, { useState } from "react";
import "../Styles/Inventory.css";
import SearchBar from "../StaticComponents/SearchBar";
import Filter from "../StaticComponents/Filter";
import InventoryFilter from "../Components/Inventory/InventoryFilter";
import InventoryTable from "../Components/Inventory/InventoryTable";

export default function Inventory() {
  const [modalShow, setModalShow] = useState(false);
  const [searchkeyword, setsearchkeyword] = useState("");
  const onSearch = (value) => {
    setsearchkeyword(value);
  };
  const [notify, setnotify] = useState(0);
  const onNotify = (value) => {
    setnotify(value);
  };
  const [stockproducts, setstockproduct] = useState(0);
  const [productstoship, setproductstoship] = useState(0);
  const [productstoreceive, setproductstoreceive] = useState(0);
  const onValue = (a, b, c) => {
    setstockproduct(a);
    setproductstoship(b);
    setproductstoreceive(c);
  };

  

  return (
    <div id="inv_bg">
      <div
        id="suppliers_topbar_bg"
        style={{ paddingLeft: "0", paddingRight: "0" }}
      >
        <div className="container d-flex justify-content-between align-items-center">
          <div id="dashboard_header">Inventory</div>
        </div>
      </div>

      <div className="container">
        <div id="inv_overview_heading">
          <div className="row w-100">
            <div className="col-4">
              <div id="totalStock">
                Total Stock Products
                <div id="totalCount">{stockproducts}</div>
              </div>
            </div>
            <div className="col-4">
              {" "}
              <div id="totalStock">
                Total Products To Ship
                <div id="totalCount">{productstoship}</div>
              </div>
            </div>
            <div className="col-4">
              {" "}
              <div id="totalStock">
                Total Products To Receive
                <div id="totalCount">{productstoreceive}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex" id="invFilters">
          <SearchBar onSearch={onSearch} />
          <div onClick={() => setModalShow(true)} style={{ cursor: "pointer" }}>
            <Filter notify={notify} />
          </div>
          <InventoryFilter
            show={modalShow}
            onHide={() => setModalShow(false)}
            onNotify={onNotify}
          />
        </div>
        <div id="inv_tab_bg">
          <InventoryTable searchkeyword={searchkeyword} onValue={onValue} />
        </div>
      </div>
    </div>
  );
}
