import React from 'react';

const CurrencyFormatter = ({ amount }) => {
  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'JPY', // You can adjust this to the correct currency
      minimumFractionDigits: 2,
    }).format(amount);
  };

  return <span>{formatCurrency(amount)}</span>;
};

export default CurrencyFormatter;
