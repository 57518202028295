import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import SearchBar from "../../../StaticComponents/SearchBar";
import { DataGrid } from "@mui/x-data-grid";
import { makeApiCall } from "../../../StaticComponents/API";

export default function SearchShipping(props) {
  const columns = [
    { field: "inv_id", headerName: "Invoice No", width: 125, align: "center" },
    {
      field: "TOTAL_ITEM",
      headerName: "Total Item",
      width: 125,
      align: "center",
    },
    {
      field: "TOTAL_CTNS",
      headerName: "Total CTNS",
      width: 134,
      align: "center",
    },
    {
      field: "TOTAL_AMOUNT",
      headerName: "Total Amount",
      width: 149,
      type: "number",
      align: "center",
    },
    {
      field: "CREATED_ON",
      headerName: "Created On",
      width: 141,
      align: "center",
    },
  ];

  const [rows, setRows] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const token = localStorage.getItem("token");
  const CLIENT_CUST_ID = localStorage.getItem("CLIENT_CUST_ID");
  function convertTimestampToDate(timestamp) {
    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
    const day = String(date.getDate()).padStart(2, "0");

    return `${day}-${month}-${year}`;
  }
  const getData = () => {
    const payload = {
      CLIENT_CUST_ID: CLIENT_CUST_ID,
      SEARCH_KEYWORD: "",
      CURRENT_PAGE: 1,
    };

    makeApiCall({
      mod: "Shipping",
      action: "select_customer_invoice_for_shipp",
      payload: payload,
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        const invoiceItems = res.XscData.INV_LIST;
        if (Array.isArray(invoiceItems)) {
          const mappedRows = invoiceItems.map((item) => ({
            id: item.ID,
            inv_id: item.CUST_INV_NUM,
            TOTAL_ITEM: item.TOTAL_QTY,
            TOTAL_CTNS: item.TOTAL_CTN,
            TOTAL_AMOUNT: item.TOTAL_AMOUNT,
            CREATED_ON: item.CREATED_ON
              ? convertTimestampToDate(item.CREATED_ON)
              : "N/A",
          }));
          setRows(mappedRows);
        } else {
          console.error("Select Invoice has No Data");
        }
      })
      .catch((error) => console.error("Error fetching data:", error));
  };

  useEffect(() => {
    getData();
  }, []);

  // const handleConfirmSelection = () => {
  //   if (selectedRows.length > 0) {
  //     const selectedIds = selectedRows
  //       .map((selectedId) => {
  //         const row = rows.find((row) => row.id === selectedId);
  //         return row ? row.id : null;
  //       })
  //       .filter(Boolean);

  //     console.log(selectedIds, "Selected Invoice IDs");
  //     props.onSelect(selectedIds);
  //     props.onHide();

  //     // setRows([]);
  //     setSelectedRows([]);
  //     setSearchQuery("");
  //   } else {
  //     alert("Please select at least one invoice.");
  //   }
  // };

  const handleConfirmSelection = () => {
    if (selectedRows.length > 0) {
      const selectedIds = selectedRows
        .map((selectedId) => {
          const row = rows.find((row) => row.id === selectedId);
          return row ? row.id : null;
        })
        .filter(Boolean);

      console.log(selectedIds, "Selected Invoice IDs");

      props.getTableData(selectedIds); // Call the function passed from props

      props.onSelect(selectedIds);
      props.onHide();

      setSelectedRows([]);
      setSearchQuery("");
    } else {
      alert("Please select at least one invoice.");
    }
  };

  const handleSearch = (query) => {
    setSearchQuery(query);
  };

  const filteredRows = rows.filter((row) => {
    return (
      row.inv_id.toString().toLowerCase().includes(searchQuery.toLowerCase()) ||
      row.TOTAL_ITEM.toString()
        .toLowerCase()
        .includes(searchQuery.toLowerCase()) ||
      row.TOTAL_CTNS.toString()
        .toLowerCase()
        .includes(searchQuery.toLowerCase()) ||
      row.TOTAL_AMOUNT.toString()
        .toLowerCase()
        .includes(searchQuery.toLowerCase()) ||
      row.CREATED_ON.toString()
        .toLowerCase()
        .includes(searchQuery.toLowerCase())
    );
  });

  return (
    <div>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div id="search_po_bg">
          <div id="search_po_text">Select Invoice</div>
          <div>
            <SearchBar onSearch={handleSearch} />
          </div>
          <div style={{ maxHeight: "fit-content", marginTop: "20px" }}>
            <DataGrid
              rows={filteredRows}
              columns={columns}
              hideFooterPagination
              hideFooter
              autoHeight
              rowHeight={80}
              disableSelectionOnClick
              checkboxSelection
              onSelectionModelChange={(newSelection) => {
                setSelectedRows(newSelection);
              }}
            />
          </div>
        </div>
        <div id="search_po_footer">
          <div id="cancel" onClick={props.onHide}>
            Cancel
          </div>
          <div id="submitsupplier" onClick={handleConfirmSelection}>
            Confirm Selection
          </div>
        </div>
      </Modal>
    </div>
  );
}
