import BigImage from "../../assets/BigImage.png";
import { useEffect, useState } from "react";
import PurchaseHistory from "./PurchaseHistory";
import "../../Styles/ImagesAndDetails.css";
import Product_Package from "../../assets/Product_Package.png"

const API_URL = process.env.REACT_APP_API_URL;

export default function ImagesAndDetails({ prodInfo }) {
  const [modalShow, setModalShow] = useState(false);
  const [bigImage, setBigImage] = useState(Product_Package);
  const [selectedImage, setSelectedImage] = useState(0);

  useEffect(() => {
    if (prodInfo.prod_media.length > 0) {
      // Find the default image
      const defaultImage = prodInfo.prod_media.find(
        (media) => media.IS_DEFAULT === "Y"
      );
      const images = prodInfo.prod_media.map(
        (media) => API_URL + `media/${media.PROD_MEDIA}`
      );
      if (defaultImage) {
        const defaultImageIndex = images.indexOf(
          API_URL + `media/${defaultImage.PROD_MEDIA}`
        );
        setBigImage(API_URL + `media/${defaultImage.PROD_MEDIA}`);
        setSelectedImage(defaultImageIndex);
      } else {
        // If no default image, set the first image as default
        setBigImage(images[0]);
        setSelectedImage(0);
      }
    }
  }, [prodInfo.prod_media]);

  const handleImageChange = (product_image, index) => {
    setBigImage(product_image);
    setSelectedImage(index);
  };

  const images =
    prodInfo.prod_media.length > 0
      ? prodInfo.prod_media.map(
          (media) => API_URL + `media/${media.PROD_MEDIA}`
        )
      : [Product_Package]; // Ensure prod_media is used
  const isSingleImage = images.length === 1;
  console.log(images);

  const getValueOrZero = (value) =>
    value !== null && value !== undefined ? value : "0";

  return (
    <>
      <div id="imagesAndDetails" style={{}}>
        {!isSingleImage && (
          <div id="smallImages" className="">
            <div style={{}}>
              {images.map((image, index) => (
                <div
                  key={index}
                  onClick={() => handleImageChange(image, index)}
                  id="productImage"
                  style={{
                    marginTop: index === 0 ? 0 : 10,
                    marginRight: 0,
                    border:
                      selectedImage === index
                        ? "2px solid #635bff"
                        : "0.5px solid #c7c4d8",
                    cursor: "pointer",
                  }}
                >
                  <img id="image" src={image} alt={`Thumbnail ${index}`} />
                </div>
              ))}
            </div>
          </div>
        )}

        <div id="bigImages">
          <img
            id="bigImage"
            src={isSingleImage ? images[0] : bigImage}
            style={{ opacity: 1 }}
          />
        </div>

        <div id="details">
          <p id="detailsId">{`${getValueOrZero(
            prodInfo.product_details.SUPP_PROD_NUM
          )}, ${getValueOrZero(prodInfo.product_details.CLIENT_PROD_NUM)}`}</p>

          <h2 id="productName">{prodInfo.product_details.DESCRIPTION}</h2>
          <hr id="breaker" />
          <div className="d-flex" style={{ flexDirection: "row" }}>
            <div id="pricePerPiece">
              <h2 id="price">
                {"\u00a5 "}
                {`${getValueOrZero(prodInfo.product_details.PRICE)}`}
              </h2>
              <div
                className="d-flex"
                style={{ flexDirection: "row", gap: "8px" }}
              >
                <p id="perPiece">Per Piece</p>
                <svg
                  onClick={() => setModalShow(true)}
                  style={{ cursor: "pointer" }}
                  width="18"
                  height="20"
                  viewBox="0 0 18 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14 0V2H17.0066C17.5552 2 18 2.44495 18 2.9934V19.0066C18 19.5552 17.5551 20 17.0066 20H0.9934C0.44476 20 0 19.5551 0 19.0066V2.9934C0 2.44476 0.44495 2 0.9934 2H4V0H14ZM4 4H2V18H16V4H14V6H4V4ZM6 14V16H4V14H6ZM6 11V13H4V11H6ZM6 8V10H4V8H6ZM12 2H6V4H12V2Z"
                    fill="#635BFF"
                  />
                </svg>
              </div>
              <hr
                id="verticalBreakerImages"
                style={{ margin: "-46px 0px 0px 77px" }}
              />
            </div>

            <div id="pricePerPiece" style={{ width: "var(--size-340)" }}>
              <h2 id="price" style={{ width: "var(--size-380)" }}>
                {`${getValueOrZero(prodInfo.total_stock)}`}
              </h2>
              <p id="perPiece" style={{ width: "var(--size-380)" }}>
                In Stock
              </p>
              <hr
                id="verticalBreakerImages"
                style={{ margin: "-46px 0px 0px 41px" }}
              />
            </div>

            <div id="pricePerPiece" style={{ width: "var(--size-340)" }}>
              <h2 id="price" style={{ width: "var(--size-380)" }}>
                {`${getValueOrZero(prodInfo.to_ship)}`}
              </h2>
              <p id="perPiece" style={{ width: "var(--size-380)" }}>
                To Ship
              </p>
              <hr
                id="verticalBreakerImages"
                style={{ margin: "-46px 0px 0px 41px" }}
              />
            </div>

            <div id="pricePerPiece" style={{ width: "84px" }}>
              <h2 id="price" style={{ width: "var(--size-380)" }}>
                {`${getValueOrZero(prodInfo.to_receive)}`}
              </h2>
              <p id="perPiece" style={{ width: "84px" }}>
                To Receive
              </p>
            </div>
          </div>
          <hr
            id="breaker"
            style={{
              margin: "var(--size-180) 36px 0px 36px",
            }}
          />

          <div
            className="d-flex"
            style={{ flexDirection: "column", height: "516px" }}
          >
            <div
              className="d-flex"
              style={{
                flexDirection: "row",
                marginTop: "12px",
              }}
            >
              <h2 id="productDet" style={{ marginRight: "111px" }}>
                PCS Per CTN
              </h2>
              <p id="value">{`${getValueOrZero(
                prodInfo.product_details.QTY_PER_CTN
              )}`}</p>
            </div>
            <div
              className="d-flex"
              style={{
                flexDirection: "row",
              }}
            >
              <h2 id="productDet" style={{ marginRight: "132px" }}>
                Packaging
              </h2>
              <p id="value" style={{ width: "180px" }}>{`${getValueOrZero(
                prodInfo.product_details.PACKING
              )}`}</p>
            </div>
            <div
              className="d-flex"
              style={{
                flexDirection: "row",
              }}
            >
              <h2 id="productDet" style={{ marginRight: "49px" }}>
                Sup.Product Barcode
              </h2>
              <p id="value">{`${getValueOrZero(
                prodInfo.product_details.SUPP_BAR_CODE
              )}`}</p>
            </div>
            <div
              className="d-flex"
              style={{
                flexDirection: "row",
              }}
            >
              <h2 id="productDet" style={{ marginRight: "46px" }}>
                Mnx.Product Barcode
              </h2>
              <p id="value">{`${getValueOrZero(
                prodInfo.product_details.CLIENT_BAR_CODE
              )}`}</p>
            </div>
            <div
              className="d-flex"
              style={{
                flexDirection: "row",
              }}
            >
              <h2 id="productDet" style={{ marginRight: "74px" }}>
                Sup.DUN Barcode
              </h2>
              <p id="value">{`${getValueOrZero(
                prodInfo.product_details.SUPP_DUN_BAR_CODE
              )}`}</p>
            </div>
            <div
              className="d-flex"
              style={{
                flexDirection: "row",
              }}
            >
              <h2 id="productDet" style={{ marginRight: "71px" }}>
                Mnx.DUN Barcode
              </h2>
              <p id="value">{`${getValueOrZero(
                prodInfo.product_details.CLIENT_DUN_BAR_CODE
              )}`}</p>
            </div>
          </div>
        </div>
      </div>

      <PurchaseHistory show={modalShow} onHide={() => setModalShow(false)} />
    </>
  );
}
