import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { FormCheck } from "react-bootstrap";
import "../../../Styles/SupplierFilter.css";
import SearchBar from "../../../StaticComponents/SearchBar";
import { makeApiCall } from "../../../StaticComponents/API";
import { useSearchParams } from "react-router-dom";

export default function InventoryFilter(props) {
  const [Status, setStatus] = useState(true);
	const [selectedFilters, setSelectedFilters] = useState({
	  orderStatus: [],
	  paymentStatus: [],
	});
	const [ordersStatus, setOrdersStatus] = useState(true);
	const [paymentsStatus, setPaymentsStatus] = useState(false);
	const [orderStatus, setOrderStatus] = useState([]);
	const [paymentStatus, setPaymentStatus] = useState([]);
	const [filtercount, setfiltercount] = useState(0);

  let token = localStorage.getItem("token");

  const APP_CLIENT_ID = localStorage.getItem("CLIENT_ID");
  const CLIENT_SUPP_ID = localStorage.getItem("CLIENT_SUPPLIER_ID");
  
	const handleOrderStatus = () => {
	  setPaymentsStatus(false);
	  setOrdersStatus(true);
	}
  
	const handlePaymentStatus = () => {
	  setPaymentsStatus(true);
	  setOrdersStatus(false);
	}
  
	const handleResetFilter = () => {
	  setSelectedFilters({
		orderStatus: [],
		paymentStatus: [],
	  });
	}
  
	useEffect(() => {
	  let count = 0;
	  if (selectedFilters.orderStatus.length > 0) {
		count += 1;
	  }
	  if (selectedFilters.paymentStatus.length > 0) {
		count += 1;
	  }
	  setfiltercount(count);
	  // props.onNotify(count);
	}, [selectedFilters]);

  useEffect(() => {
	  const payload = { };
	  makeApiCall({
		mod: "Filters",
		action: "order_status_filter_customer",
		payload: payload,
		headers: {
		  Authorization: `Bearer ${token}`,
		},
	  })
		.then((response) => {
      console.log(response)
		  console.log("orderrrrr status filtered successfully", response.XscData);
		  if (Array.isArray(response.XscData.ORDER_STATUS)) {
			setOrderStatus(response.XscData.ORDER_STATUS);
		  } else {
			console.error("Unexpected data", response.XscData.ORDER_STATUS);
		  }
		})
		.catch((error) => console.error("Error fetching data:", error));
	}, []);
  
	useEffect(() => {
	  const payload = {};
	  makeApiCall({
		mod: "Filters",
		action: "payment_status_filter",
		payload: payload,
		headers: {
		  Authorization: `Bearer ${token}`,
		},
	  })
		.then((response) => {
      console.log(response)
		  console.log(
			"paymenttttt status filtered successfully",
			response.XscData.PAYMENT_STATUS
		  );
		  if (Array.isArray(response.XscData.PAYMENT_STATUS)) {
			setPaymentStatus(response.XscData.PAYMENT_STATUS);
		  } else {
			console.error("Unexpected data", response.XscData.PAYMENT_STATUS);
		  }
		})
		.catch((error) => console.error("Error fetching data:", error));
	}, []);

  const handleCheckboxChange = (filterType, value) => {
    setSelectedFilters((prevFilters) => {
      if (filterType === "paymentStatus") {
        return {
          ...prevFilters,
          paymentStatus: prevFilters.paymentStatus.includes(value)
            ? prevFilters.paymentStatus.filter((item) => item !== value)
            : [value], 
          orderStatus: prevFilters.orderStatus, 
        };
      }
      return {
        ...prevFilters,
        [filterType]: prevFilters[filterType].includes(value)
          ? prevFilters[filterType].filter((item) => item !== value)
          : [...prevFilters[filterType], value],
      };
    });
	};
  
	const handleRemoveFilter = (filterType, value) => {
	  setSelectedFilters((prevFilters) => ({
		...prevFilters,
		[filterType]: prevFilters[filterType].filter((item) => item !== value),
	  }));
	};

  return (
	  <>
		<Modal
		  {...props}
		  size="lg"
		  aria-labelledby="contained-modal-title-vcenter"
		  centered
		>
		  <Modal.Header closeButton>
			<Modal.Title
			  id="contained-modal-title-vcenter"
			  className="filter_text"
			>
			 Filters{" "}
			  {filtercount > 0 ? (
				<div>({filtercount} selected)</div>
			  ) : (
				<div></div>
			  )}
			</Modal.Title>
		  </Modal.Header>
  
		  <div id="selected_fil">
		   <div className="selected-filters-bg d-flex" style={{paddingLeft:"12px"}}>
			{selectedFilters.orderStatus.length > 0 && selectedFilters.orderStatus.map((filter, index) =>(
			   <span key={index} className="filter-tag" style={{marginTop:"8px"}}>
			   {filter}
  
			   <svg
				 onClick={() => handleRemoveFilter("orderStatus", filter)}
				 className="remove-filter-btn"
				 xmlns="http://www.w3.org/2000/svg"
				 width="16"
				 height="16"
				 viewBox="0 0 16 16"
				 fill="none"
			   >
				 <path
				   d="M7.99967 14.6667C4.31777 14.6667 1.33301 11.6819 1.33301 7.99999C1.33301 4.31809 4.31777 1.33333 7.99967 1.33333C11.6815 1.33333 14.6663 4.31809 14.6663 7.99999C14.6663 11.6819 11.6815 14.6667 7.99967 14.6667ZM7.99967 7.05719L6.11405 5.17157L5.17125 6.11437L7.05687 7.99999L5.17125 9.88559L6.11405 10.8284L7.99967 8.94279L9.88527 10.8284L10.8281 9.88559L8.94247 7.99999L10.8281 6.11437L9.88527 5.17157L7.99967 7.05719Z"
				   fill="#535260"
				 />
			   </svg>
			 </span>
			))}      
			 {selectedFilters.paymentStatus.length > 0 && selectedFilters.paymentStatus.map((filter, index) =>(
			   <span key={index} className="filter-tag" style={{marginTop:"8px"}}>
			   {filter}
  
			   <svg
				 onClick={() => handleRemoveFilter("paymentStatus", filter)}
				 className="remove-filter-btn"
				 xmlns="http://www.w3.org/2000/svg"
				 width="16"
				 height="16"
				 viewBox="0 0 16 16"
				 fill="none"
			   >
				 <path
				   d="M7.99967 14.6667C4.31777 14.6667 1.33301 11.6819 1.33301 7.99999C1.33301 4.31809 4.31777 1.33333 7.99967 1.33333C11.6815 1.33333 14.6663 4.31809 14.6663 7.99999C14.6663 11.6819 11.6815 14.6667 7.99967 14.6667ZM7.99967 7.05719L6.11405 5.17157L5.17125 6.11437L7.05687 7.99999L5.17125 9.88559L6.11405 10.8284L7.99967 8.94279L9.88527 10.8284L10.8281 9.88559L8.94247 7.99999L10.8281 6.11437L9.88527 5.17157L7.99967 7.05719Z"
				   fill="#535260"
				 />
			   </svg>
			 </span>
			))}
			</div>
		  </div>
		  <div id="filter_content_bg">
			<div id="fil_side_menu_bg">
			  <div id="fil_side_menu">
				<div className="position-relative d-flex align-items-center">
				  {ordersStatus && (
					<div
					  id="active_fil_indicator"
					  className=""
					></div>
				  )}
				  <div
					id="single_fil_menu"
					onClick={handleOrderStatus}
					style={{
					  color: ordersStatus && "var(--primaryContainer)",
					  fontWeight: ordersStatus && "600",
					  cursor: "pointer",
					}}
				  >
					Order Status
				  </div>
				</div>
			  </div>
			  <div id="fil_side_menu">
				<div className="position-relative d-flex align-items-center">
				  {paymentsStatus && (
					<div
					  id="active_fil_indicator"
					  className=""
					></div>
				  )}
				  <div
					id="single_fil_menu"
					onClick={handlePaymentStatus}
					style={{
					  color: paymentsStatus && "var(--primaryContainer)",
					  fontWeight: paymentsStatus && "600",
					  cursor: "pointer",
					}}
				  >
					Payment Status
				  </div>
				</div>
			  </div>
			</div>
  
			{/* for Status */}
			{ordersStatus && (
			  <div id="fil_content_bg">
			  {/* <SearchBar onSearch={onSearch} /> */}
			  {Array.isArray(orderStatus) && orderStatus.length > 0 ? (
				orderStatus.map((orderstatus) => (
				  <div
					className="d-flex align-items-center justify-content-center"
					id="fil_single_item"
					key={orderstatus.STATUS}
					onClick={() =>
						handleCheckboxChange("orderStatus", orderstatus.TYPE)
					  }
				  >
					<div>
					  <FormCheck
						type="checkbox"
						className="checkbox"
						checked={selectedFilters.orderStatus.includes(
						  orderstatus.TYPE
						)}
						
					  ></FormCheck>
					</div>
					<div>{orderstatus.TYPE}</div>
				  </div>
				))
			  ) : (
				<div>No status available</div>
			  )}
			</div>
			)}
			{paymentsStatus && (
			  <div id="fil_content_bg">
			  {Array.isArray(paymentStatus) && paymentStatus.length > 0 ? (
				paymentStatus.map((paymentstatus) => (
				  <div
					className="d-flex align-items-center justify-content-center"
					id="fil_single_item"
					key={paymentstatus.STATUS}
					onClick={() =>
						handleCheckboxChange("paymentStatus", paymentstatus.TYPE)
					  }
				  >
					<div>
					  <FormCheck
						type="checkbox"
						className="checkbox"
						checked={selectedFilters.paymentStatus.includes(
						  paymentstatus.TYPE
						)}
						
					  ></FormCheck>
					</div>
					<div>{paymentstatus.TYPE}</div>
				  </div>
				))
			  ) : (
				<div>No Payment Status available</div>
			  )}
			</div>
			)}
		 
		  </div>
  
		  <Modal.Footer>
		  <button
			  id="reset_btn"
			  className="btn"
			  onClick={handleResetFilter }
			>
			  Reset{" "}
			</button>
			<button id="apply_btn" className="btn">
			  Apply Filters
			</button>
		  </Modal.Footer>
		</Modal>
	  </>
	);
}
