import { useState } from "react";
import Filter from "../../StaticComponents/Filter";
import SearchBar from "../../StaticComponents/SearchBar";
import "../../Styles/ReportComponents.css";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { Dropdown } from "react-bootstrap";
import DatePickerModal from "../Reports/DatePickerModal.js";
import SupplierReportTable from "./Reports/SupplierReportTable.js";
import SupplierReportFilter from "./Reports/SupplierReportFilter.js";

export default function SupplierReportPage() {
  const [modalShow, setModalShow] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedFilters, setSelectedFilters] = useState({
    paymentStatus: null,
  });
  const [totalOrders, setTotalOrders] = useState();
  const [dueAmount, setDueAmount] = useState();
  const handleSearch = (query) => {
    setSearchQuery(query);
  };

  const handleFiltersChange = (filters) => {
    setSelectedFilters(filters);
  };

  const updateTotals = (orders, amount) => {
    setTotalOrders(orders);
    setDueAmount(amount);
  };

  return (
    <div id="report_bg">
      <div className="container">
        <div id="report_overview_heading" className="row">
          <div className="col-md-6">
            <div id="total_stock">
              Total Orders
              <div id="total_count">{totalOrders || "N/A"}</div>
            </div>
          </div>

          <div className="col-md-6">
            <div id="total_stock">
              Due Payment
              <div id="total_count">
                {"\u00a5 " + dueAmount || "N/A"}
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex" id="invFilters">
          <SearchBar onSearch={handleSearch} />
          <div onClick={() => setModalShow(true)} style={{ cursor: "pointer" }}>
            <Filter />
          </div>
          <SupplierReportFilter
            show={modalShow}
            onHide={() => setModalShow(false)}
            onFiltersChange={handleFiltersChange}
          />
          <Dropdown>
            <Dropdown.Toggle
              style={{
                marginLeft: "12px",
                width: "301px",
                height: "40px",
                backgroundColor: "var(--onSurfaceLowest)",
                color: "var(--onSurface)",
                border: "1px solid var(--outline1)",
                borderRadius: "var(--cr---3)",
              }}
            >
              Date
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <DatePickerModal />
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div id="report_tab_bg">
          <SupplierReportTable
            searchQuery={searchQuery}
            selectedFilters={selectedFilters}
            onUpdateTotals={updateTotals}
          />
        </div>
      </div>
    </div>
  );
}
