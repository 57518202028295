import React, { useState, useEffect } from "react";
import "../../Styles/PurchaseHistory.css";
import ProductImage from "../../assets/product_image.png";
import { makeStyles } from "@material-ui/styles";
import { DataGrid } from "@mui/x-data-grid";
import po_img from "../../assets/po_img.png";
import { Modal } from "react-bootstrap";
import { makeApiCall } from "../../StaticComponents/API";
import { useSearchParams } from "react-router-dom";

const useStyles = makeStyles({
  modalContent: {
    overflow: "hidden", // Prevent scrollbar on modal content
  },
  header: {
    "& .MuiDataGrid-columnHeaderTitle": { fontWeight: "bold" },
    "& .MuiDataGrid-menuIcon": { display: "none" },
  },

  row: {
    "& .MuiDataGrid-cell--textLeft": { textAlign: "center !important" },
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "var(--size-140) !important",
  },

  headerId: {
    minWidth: "115px !important",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontSize: "14px",
  },

  headerProductName: {
    minWidth: "185px !important",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontSize: "14px",
  },

  headerSupplier: {
    minWidth: "180px !important",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontSize: "14px",
  },

  headerDate: {
    minWidth: "146px !important",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontSize: "14px",
  },

  headerPrice: {
    minWidth: "93px !important",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontSize: "14px",
  },
  dataGrid: {
    overflow: "hidden",
    "& .MuiDataGrid-viewport": {
      overflow: "hidden",
    },
    "& .MuiDataGrid-window": {
      overflow: "hidden",
    },
  },
});

const ProductViewPH = (props) => {
  const [pageSize, setPageSize] = useState(10);

  const classes = useStyles();

  const ID = props.id;
  const pId = props.pid;

  console.log(ID, pId, "id");

  const columns = [
    {
      field: "id",
      headerName: "Order No",
      width: 113,
      cellClassName: "sticky-column",
      headerClassName: classes.headerId,
    },
    {
      field: "PRODUCT_NAME",
      headerName: "Product Name",
      width: 185,
      editable: false,
      align: "left",
      headerClassName: classes.headerProductName,
    },
    {
      field: "SUPPLIER",
      headerName: "Supplier",
      width: 180,
      editable: false,
      align: "left",
      headerClassName: classes.headerSupplier,
    },
    {
      field: "DATE",
      headerName: "On This Date",
      width: 146,
      editable: false,
      align: "left",
      headerClassName: classes.headerDate,
    },
    {
      field: "PRICE",
      headerName: "Price",
      width: 93,
      editable: false,
      align: "left",
      headerClassName: classes.headerPrice,
    },
  ];

  const picture = () => (
    <div id="po_pic_bg">
      <img src={po_img} />
    </div>
  );

  const copyIcon = () => (
    <svg
      style={{ cursor: "pointer" }}
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 0V2H17.0066C17.5552 2 18 2.44495 18 2.9934V19.0066C18 19.5552 17.5551 20 17.0066 20H0.9934C0.44476 20 0 19.5551 0 19.0066V2.9934C0 2.44476 0.44495 2 0.9934 2H4V0H14ZM4 4H2V18H16V4H14V6H4V4ZM6 14V16H4V14H6ZM6 11V13H4V11H6ZM6 8V10H4V8H6ZM12 2H6V4H12V2Z"
        fill="#635BFF"
      />
    </svg>
  );

  const initialRows = [
    // {
    //   id: "I091",
    //   PRODUCT_NAME: "12PCS MIRROR FASTE...",
    //   SUPPLIER: "BNS Clothes Service",
    //   DATE: "16-July-2024",
    //   PRICE: "\u00a5 1.20",
    // }
  ];

  const [rows, setrows] = useState(initialRows);

  const rowsWithIcons = rows.map((row) => ({
    ...row,
    PICTURE: picture(),
    PH: copyIcon(),
  }));

  let token = localStorage.getItem("token");
  const [page, setpage] = useState(0);

  const [total, settotal] = useState();
  let [searchParams] = useSearchParams();

  const [purchaseInfo, setPurchaseInfo] = React.useState({
    xscdata: "",
  });

  useEffect(() => {
    if (ID && pId) {
      const payload = {
        CLIENT_SUPPLIER_ID: ID,
        P_ID: pId,
        CURRENT_PAGE: page + 1,
      };

      makeApiCall({
        mod: "Supplier",
        action: "view-purchase-history",
        payload: payload,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          console.log("request", payload);
          console.log("View Purchase History Listed Successfully", response);
          const history = response.XscData.ORDER_DETAILS;
          if (Array.isArray(history)) {
            const mappedRows = history.map((history) => ({
              id: history.ORDER_NO  || "N/A",
              PRODUCT_NAME: history.PRODUCT_NAME || "N/A",
              SUPPLIER: history.SUPPLIER  || "N/A",
              DATE: history.ON_THIS_DATE  || "N/A",
              PRICE: history.PRICE  || "0",
            }));

            setrows(mappedRows);
          }
          settotal(response.XscData.TOTAL_PO);
          setPurchaseInfo({
            xscdata: response.XscData,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [ID, pId]);

const handleImageError = (e) =>{
e.target.src = ProductImage
}

  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="filter_text"
          >
            View Purchase History
          </Modal.Title>
        </Modal.Header>

        <div className="historyContent">
          <div className="div">
            <img
              src={
                process.env.REACT_APP_API_URL +
                purchaseInfo.xscdata.PRODUCT_IMAGE
              }
              className="productImage"
              onError={handleImageError}
            />
            <hr className="verticalBreaker" />
            <div className="productId">
              <h2 id="id">{purchaseInfo.xscdata.SUPP_PROD_ID || "0"}  </h2>
              <p id="text">Sup.Prod.ID</p>
            </div>
            <hr className="verticalBreaker" />
            <div className="productId">
              <h2 id="id">{purchaseInfo.xscdata.MNX_PROD_ID || "0"}  </h2>
              <p id="text">Mnx.Prod.ID</p>
            </div>
            <hr className="verticalBreaker" />
            <div className="productId">
              <h2 id="id">
                {"\u00a5"}
                {purchaseInfo.xscdata.PRICE || "0"}  
              </h2>
              <p id="text">Per Piece</p>
            </div>
            <hr className="verticalBreaker" />
            <div className="productId">
              <h2 id="id">{purchaseInfo.xscdata.QTY_IN_INVENTORY || "0"}  </h2>
              <p id="text">Qty. in Inventory</p>
            </div>
          </div>

          <div
            style={{
              height: 600,
              width: 736,
              marginBottom: "80px",
              alignSelf: "center",
              justifyContent: "center",
            }}
            className={classes.modalContent}
          >
            <DataGrid
              rows={rowsWithIcons}
              columns={columns}
              pageSize={pageSize}
              rowCount={total}
              rowsPerPageOptions={[10, 20, 50]}
              page={page}
              pagination
              paginationMode="server"
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              classes={{ columnHeader: classes.header, row: classes.row }}
              onPageChange={(newPage) => setpage(newPage)}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ProductViewPH;
