import React from "react";
import { Modal } from "react-bootstrap";
import { useNavigate, useSearchParams } from "react-router-dom";
import { makeApiCall } from "../../StaticComponents/API";
import { toast } from "react-toastify";

function DeleteCarton(props) {
  let [searchParams] = useSearchParams();
  let token = localStorage.getItem("token");

  let APP_CLIENT_ID = localStorage.getItem("CLIENT_ID");
  let CTN_ID = searchParams.get("id");
  console.log(APP_CLIENT_ID);
  console.log(CTN_ID);
  const navigate = useNavigate("");
  const DeleteCarton = () => {
    const payload = {
      APP_CLIENT_ID: APP_CLIENT_ID,
      CTN_ID: CTN_ID,
    };
  
    makeApiCall({
      mod: "Shipping",
      action: "delete-ctn",
      payload: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((res) => {
      console.log(res, "deleted");
  
      if (res.XscStatus !== 0) {
        toast.success(res.XscMessage);
        localStorage.setItem("shippingTab", "carton");
        setTimeout(() => {
          navigate("/shipping");
          props.onHide();
        }, 1000); 
      } else {
        toast.error(res.XscMessage);
      }
    });
  };
  return (
    <div>
      <Modal {...props}>
        <div>
          <div id="delete_Supplier">Delete Carton</div>
          <div id="delete_supplier_content">
            Are you sure you want tso delete this Particular Container? <br />
            It cannot be undone.
          </div>
          <div id="delete_footer">
            <div id="delete_cancel" onClick={() => props.onHide()}>
              Cancel
            </div>
            {/* <a href="/shipping"> */}{" "}
            <div id="delete_submit" onClick={DeleteCarton}>
              Delete Permanently
            </div>
            {/* </a> */}
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default DeleteCarton;
