import React, { useEffect } from "react";
import DashboardLayout from "./DashboardLayout";
import { Outlet, useNavigate } from "react-router-dom";

function AuthenticateComponent() {
  let navigate = useNavigate("");
   const token = localStorage.getItem("token");
    if (!token) {
      // If no token, redirect to login
      console.log("No token found. Redirecting to login.");
      navigate("/"); // Redirect to login
      return null; // Stop further execution
    }
  return (
    <div>
      <div>
        <DashboardLayout>
          <Outlet />
        </DashboardLayout>
      </div>
    </div>
  );
}
export default AuthenticateComponent;
