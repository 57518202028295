import React, { useEffect, useRef, useState } from "react";

import Modal from "react-bootstrap/Modal";
import { FormCheck } from "react-bootstrap";

import SearchBar from "../../StaticComponents/SearchBar";
import TextInputWithAll from "../Inputs/TextInputWithAll";
import { makeApiCall } from "../../StaticComponents/API";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const GlobalProductSearchFilter = (props) => {
  const [Location, setLocation] = useState(true);
  const [SupplierName, setSupplierName] = useState(false);
  const [Price, setPrice] = useState(false);
  const [countries, setcountries] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("")
  const [searchkeyword,setsearchkeyword]=useState("")
  const [priceRange, setPriceRange] = useState({ min: 0, max: 0 });
  const onSearch=(value)=>{
    setsearchkeyword(value)
  }
  const [selectedFilters, setSelectedFilters] = useState({
    location: [],
    supplierName: [],
    ProductPrice: [],
  });
  let token = localStorage.getItem("token");

  const [filtercount, setfiltercount] = useState(0);
  const [suppliers, setSuppliers] = useState([]);
  const [notify, setnotify] = useState();

  const containerRef = useRef(null);

  const handleEvent = () => {
    setLocation(true);
    setSupplierName(false);
    setPrice(false);
    setsearchkeyword("")
  };
  const handleSupplierName = () => {
    setSupplierName(true);
    setLocation(false);
    setPrice(false);
    setsearchkeyword("")
  };
  const handlePrice = () => {
    setSupplierName(false);
    setLocation(false);
    setPrice(true);
    setsearchkeyword("")
  };

  useEffect(() => {
    let count = 0;
    if (selectedFilters.location.length > 0) {
      count += 1;
    }
    if (selectedFilters.supplierName.length > 0) {
      count += 1;
    }

    setfiltercount(count);
    // props.onNotify(count);
  }, [selectedFilters]);

  const fetchSuppliers = async (page, searchkeyword) => {
    setLoading(true);
    const payload = {
      APP_CLIENT_ID: localStorage.getItem("CLIENT_ID"),
      CURRENT_PAGE: page,
      ITEM_PER_PAGE: 10, // Adjust items per page if needed
      SEARCH_KEYWORD: searchkeyword, // Include the search term in payload
    };

    try {
      const response = await makeApiCall({
        mod: 'Supplier',
        action: 'supplier-name-filter',
        payload: payload,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response && response.XscData && response.XscData.SUPPLIER_LIST) {
        setSuppliers((prevSuppliers) => [
          // ...prevSuppliers,
          ...response.XscData.SUPPLIER_LIST,
        ]);

        // Check if there are more suppliers to load
        if (response.XscData.SUPPLIER_LIST.length < 10) {
          setHasMore(false);
        }
      } else {
        console.error('Unexpected data', response);
      }
    } catch (error) {
      console.error('Error fetching suppliers:', error);
    } finally {
      setLoading(false);
    }
  };
  const handleInputChange = (e, type) => {
    const value = e.target.value;
  
    // If input is empty, set the value to an empty string to avoid NaN
    if (value === "") {
      setPriceRange((prev) => ({
        ...prev,
        [type]: "",
      }));
    } else {
      const parsedValue = parseFloat(value);
      
      // Only update the value if it's a valid number
      if (!isNaN(parsedValue)) {
        setPriceRange((prev) => ({
          ...prev,
          [type]: parsedValue,
        }));
      }
    }
  };
  useEffect(() => {
    // Initial fetch of suppliers
    fetchSuppliers(currentPage,searchkeyword);
  }, [currentPage, searchkeyword]);

  const handleFilter = async (e) => {
    e.preventDefault();
    // Reset state for new search
    setSuppliers([]);
    setHasMore(true);
    setCurrentPage(1);
    fetchSuppliers(1, searchkeyword);
    props.onHide()
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };
  useEffect(() => {
    setSelectedFilters((prev) => ({
      ...prev,
      ProductPrice: [`¥${priceRange.min}`, `¥${priceRange.max}`],
    }));
  }, [priceRange]);
  useEffect(() => {
    // Debounced scroll handler
    let debounceTimeout;

    const handleScroll = () => {
      clearTimeout(debounceTimeout);

      debounceTimeout = setTimeout(() => {
        if (
          containerRef.current &&
          containerRef.current.scrollTop + containerRef.current.clientHeight >=
            containerRef.current.scrollHeight - 700 &&
          !loading &&
          hasMore
        ) {
          setCurrentPage((prevPage) => prevPage + 1);
        }
      }, 300); // Adjust the debounce time if needed
    };

    const container = containerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
      return () => container.removeEventListener('scroll', handleScroll);
    }
  }, [loading, hasMore]);


  const handleRemoveFilter = (filterType, value) => {
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      [filterType]: prevFilters[filterType].filter((item) => item !== value),
    }));
  };
  const handleCheckboxChange = (filterType, value) => {
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      [filterType]: prevFilters[filterType].includes(value)
        ? prevFilters[filterType].filter((item) => item !== value)
        : [...prevFilters[filterType], value],
    }));
  };

  
  useEffect(() => {
    const payload = { CURRENT_PAGE: "", SEARCH_KEYWORD: searchkeyword, ITEM_PER_PAGE: "" };
    makeApiCall({
      mod: "Location",
      action: "get-countries",
      payload: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        console.log(
          "Supplier names filtered successfully",
          response.XscData.SUPPLIER_LIST
        );
        if (Array.isArray(response.XscData.SUPPLIER_LIST)) {
          setSuppliers(response.XscData.SUPPLIER_LIST);
        } else {
          console.error("Unexpected data ", response.XscData.SUPPLIER_LIST);
          // "Location filtered successfully"
          // response.XscData.COUNTRY_NAMES
        }
        if (Array.isArray(response.XscData.COUNTRY_NAMES)) {
          setcountries(response.XscData.COUNTRY_NAMES);
        } else {
          console.error("Unexpected data", response.XscData.COUNTRY_LIST);
        }
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, [searchkeyword]);

  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="filter_text"
          >
            Filters{" "}
            {filtercount > 0 ? (
              <div>({filtercount} selected)</div>
            ) : (
              <div></div>
            )}
          </Modal.Title>
        </Modal.Header>

        <div id="selected_fil">
          <div className="selected-filters-tags">
          {selectedFilters.ProductPrice.length > 0 && (
             <span  className="filter-tag" >
                Price: {selectedFilters.ProductPrice.join(" - ")}
                <svg
                  onClick={() =>
                    setSelectedFilters((prev) => ({
                      ...prev,
                      ProductPrice: [],
                    }))
                  }
                  className="remove-filter-btn"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M7.99967 14.6667C4.31777 14.6667 1.33301 11.6819 1.33301 7.99999C1.33301 4.31809 4.31777 1.33333 7.99967 1.33333C11.6815 1.33333 14.6663 4.31809 14.6663 7.99999C14.6663 11.6819 11.6815 14.6667 7.99967 14.6667ZM7.99967 7.05719L6.11405 5.17157L5.17125 6.11437L7.05687 7.99999L5.17125 9.88559L6.11405 10.8284L7.99967 8.94279L9.88527 10.8284L10.8281 9.88559L8.94247 7.99999L10.8281 6.11437L9.88527 5.17157L7.99967 7.05719Z"
                    fill="#535260"
                  />
                </svg>
              </span>
            )}
            {selectedFilters.location.length > 0 &&
              selectedFilters.location.map((filter, index) => (
                <span key={index} className="filter-tag">
                  {filter}

                  <svg
                    onClick={() => handleRemoveFilter("location", filter)}
                    className="remove-filter-btn"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M7.99967 14.6667C4.31777 14.6667 1.33301 11.6819 1.33301 7.99999C1.33301 4.31809 4.31777 1.33333 7.99967 1.33333C11.6815 1.33333 14.6663 4.31809 14.6663 7.99999C14.6663 11.6819 11.6815 14.6667 7.99967 14.6667ZM7.99967 7.05719L6.11405 5.17157L5.17125 6.11437L7.05687 7.99999L5.17125 9.88559L6.11405 10.8284L7.99967 8.94279L9.88527 10.8284L10.8281 9.88559L8.94247 7.99999L10.8281 6.11437L9.88527 5.17157L7.99967 7.05719Z"
                      fill="#535260"
                    />
                  </svg>
                </span>
              ))}
            {selectedFilters.supplierName.length > 0 &&
              selectedFilters.supplierName.map((filter, index) => (
                <span key={index} className="filter-tag">
                  {filter}

                  <svg
                    onClick={() => handleRemoveFilter("supplierName", filter)}
                    className="remove-filter-btn"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M7.99967 14.6667C4.31777 14.6667 1.33301 11.6819 1.33301 7.99999C1.33301 4.31809 4.31777 1.33333 7.99967 1.33333C11.6815 1.33333 14.6663 4.31809 14.6663 7.99999C14.6663 11.6819 11.6815 14.6667 7.99967 14.6667ZM7.99967 7.05719L6.11405 5.17157L5.17125 6.11437L7.05687 7.99999L5.17125 9.88559L6.11405 10.8284L7.99967 8.94279L9.88527 10.8284L10.8281 9.88559L8.94247 7.99999L10.8281 6.11437L9.88527 5.17157L7.99967 7.05719Z"
                      fill="#535260"
                    />
                  </svg>
                </span>
              ))}
          </div>
        </div>
        <div id="filter_content_bg">
          <div id="fil_side_menu_bg">
            <div id="fil_side_menu">
              <div className="position-relative">
                {SupplierName && (
                  <div
                    id="active_fil_indicator"
                    className="translate-middle top-50 left-0"
                  ></div>
                )}
                <div
                  id="single_fil_menu"
                  onClick={handleSupplierName}
                  style={{
                    color: SupplierName && "var(--primaryContainer)",
                    fontWeight: SupplierName && "600",
                    cursor: "pointer",
                  }}
                >
                  Supplier Name
                </div>
              </div>
            </div>

            <div id="fil_side_menu">
              <div className="position-relative">
                {Location && (
                  <div
                    id="active_fil_indicator"
                    className="translate-middle top-50 left-0"
                  ></div>
                )}
                <div
                  id="single_fil_menu"
                  onClick={handleEvent}
                  style={{
                    color: Location && "var(--primaryContainer)",
                    fontWeight: Location && "600",
                    cursor: "pointer",
                  }}
                >
                  Location
                </div>
              </div>
            </div>
            <div id="fil_side_menu">
              <div className="position-relative">
                {Price && (
                  <div
                    id="active_fil_indicator"
                    className="translate-middle top-50 left-0"
                  ></div>
                )}
                <div
                  id="single_fil_menu"
                  onClick={handlePrice}
                  style={{
                    color: Price && "var(--primaryContainer)",
                    fontWeight: Price && "600",
                    cursor: "pointer",
                  }}
                >
                  Product Price
                </div>
              </div>
            </div>
          </div>

          {/* location */}
          {Location && (
            <div id="fil_content_bg">
              <SearchBar  onSearch={onSearch}/>

              {Array.isArray(countries) && countries.length > 0 ? (
                countries.map((country) => (
                  <div
                    className="d-flex align-items-center justify-content-center"
                    id="fil_single_item"
                    key={country.ID}
                  >
                    <label
                  className="checkbox d-flex align-items-center"
                  id="fil_single_item"
                >
                      <FormCheck
                        type="checkbox"
                        // id="default-checkbox"
                        className="checkbox"
                        checked={selectedFilters.location.includes(
                          country.COUNTRY
                        )}
                        onChange={() =>
                          handleCheckboxChange("location", country.COUNTRY)
                        }
                      ></FormCheck>
                    
                    <div>{country.COUNTRY}</div>
                    </label>
                  </div>
                ))
              ) : (
                <div>No Location Found</div>
              )}
            </div>
          )}
          {/* for Supplier Name */}
          {SupplierName && (
            <div id="fil_content_bg">
              <SearchBar  onSearch={onSearch} />
              {Array.isArray(suppliers) && suppliers.length > 0 ? (
                suppliers.map((supplier) => (
                  <div
                    key={supplier.CLIENT_SUPPLIER_ID}
                    className="d-flex align-items-center justify-content-center"
                    id="fil_single_item"
                  ><label
                  className="checkbox d-flex align-items-center"
                  id="fil_single_item"
                >
                    <FormCheck
                      type="checkbox"
                      className="checkbox"
                      checked={selectedFilters.supplierName.includes(
                        supplier.NAME
                      )}
                      onChange={() =>
                        handleCheckboxChange("supplierName", supplier.NAME)
                      }
                    />
                    <div>{supplier.NAME}</div>
                    </label>
                  </div>
                ))
              ) : (
                <div>No suppliers available</div>
              )}
            </div>
          )}
          {/* for price */}
          {Price && (
            <div id="fil_content_bg">
              <div
                className="d-flex align-items-center justify-content-center"
                id="fil_single_item"
              >
                {" "}
                <div>
                  {" "}
                  <div className="prod_price">
                    <div className="prod_price_left ">
                      <div className="prod_price_left_text ">
                        <div className="prod_price_left_text1">Min Price</div>
                        <div className="prod_price_left_text2">
                          ¥{" "}
                          <input
                            type="text"
                            value={priceRange.min}
                            onChange={(e) => handleInputChange(e, "min")}
                            className="price-input"
                            style={{
                              width: "172px",
                              height: "40px",
                              border: "none",
                              outline: "none",
                              paddingLeft: "0",

                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="divider_price"></div>
                    <div className="prod_price_right">
                      <div className="prod_price_left_text">
                        <div className="prod_price_left_text1">Max Price</div>
                        <div className="prod_price_left_text2">
                          ¥{" "}
                          <input
                            type="text"
                            value={priceRange.max}
                            onChange={(e) => handleInputChange(e, "max")}
                            className="price-input"
                            style={{
                              width: "172px",
                              height: "40px",
                              border: "none",
                              outline: "none",
                              paddingLeft: "0",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        <Modal.Footer>
          <button
            id="reset_btn"
            className="btn"
            onClick={() =>
              setSelectedFilters({
                location: [],
                supplierName: [],
                price: [],
              })
            }
          >
            Reset{" "}
          </button>
          <button id="apply_btn" className="btn" onClick={handleFilter}>
            Apply Filters
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default GlobalProductSearchFilter;
