import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { FormCheck } from "react-bootstrap";
import "../../Styles/SupplierFilter.css";
import SearchBar from "../../StaticComponents/SearchBar";
import { makeApiCall } from "../../StaticComponents/API";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useRef } from "react";

function SupplierFilter(props) {
  const lineStyle = {
    border: "none",
    height: "1px",
    width: "100%",
    backgroundColor: "#F3EFFA",
  };
  // State to track filter section visibility
  const [Location, setLocation] = useState(true);
  const [SupplierName, setSupplierName] = useState(false);
  const [SupplierType, setSupplierType] = useState(false);
  const [SupplierStatus, setSupplierStatus] = useState(false);

  const [countries, setcountries] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [suppliersTypes, setSuppliersTypes] = useState([]);
  const [suppliersStatus, setsSuppliersStatus] = useState([]);

  const [currentPage, setcurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [showText, setShowText] = useState(false);
  const containerRef = useRef(null);

  let token = localStorage.getItem("token");
  const navigate = useNavigate();
  const [searchkeyword, setsearchkeyword] = useState("");
  const onSearch = (value) => {
    currentPageRef.current = 1;
    setsearchkeyword(value);
    setHasMore(true);
    setSuppliers([]);
  };
  const [selectedFilters, setSelectedFilters] = useState({
    location: [],
    locationIds: [],
    supplierName: [],
    supplierNameIds: [],
    supplierType: [],
    supplierTypeIds: [],
    supplierStatus: [],
    supplierStatusIds: [],
  });
  const [filtercount, setfiltercount] = useState(0);

  // Handle filter section toggles
  const handleEvent = () => {
    setLocation(true);
    setSupplierName(false);
    setSupplierType(false);
    setSupplierStatus(false);
    setsearchkeyword("");
  };

  const handleSupplierStatus = () => {
    setSupplierType(false);
    setSupplierName(false);
    setLocation(false);
    setSupplierStatus(true);
    setsearchkeyword("");
  };

  const handleSupplierName = () => {
    setSupplierType(false);
    setSupplierName(true);
    setLocation(false);
    setSupplierStatus(false);
  };

  const handleType = () => {
    setSupplierStatus(false);
    setLocation(false);
    setSupplierType(true);
    setSupplierName(false);
  };

  useEffect(() => {
    let count = 0;
    if (selectedFilters.supplierStatus.length > 0) {
      count += 1;
    }
    if (selectedFilters.location.length > 0) {
      count += 1;
    }
    if (selectedFilters.supplierName.length > 0) {
      count += 1;
    }
    if (selectedFilters.supplierType.length > 0) {
      count += 1;
    }
    setfiltercount(count);
    props.onNotify(count);
  }, [selectedFilters]);

  useEffect(() => {
    // Initial fetch of supplier names
    // fetchSuppliers(currentPage);
    fetchSuppliers();
  }, [searchkeyword]);
  const handleFilterChange = (filterType, value) => {
    setSelectedFilters((prevFilters) => {
      const updatedFilter = prevFilters[filterType].includes(value)
        ? prevFilters[filterType].filter((item) => item !== value) // Remove value if already present
        : [...prevFilters[filterType], value]; // Add value if not present
  
      return { ...prevFilters, [filterType]: updatedFilter };
    });
  };
  
  const fetchSuppliers = () => {
    console.log("supplier name fetching function");
    // if (loading || !hasMore) return;

    setLoading(true);
    const payload = {
      APP_CLIENT_ID: localStorage.getItem("CLIENT_ID"),
      CURRENT_PAGE: currentPageRef.current,
      ITEM_PER_PAGE: 10,
      SEARCH_KEYWORD: searchkeyword,
    };
    if (props.show) {
      makeApiCall({
        mod: "Supplier",
        action: "supplier-name-filter",
        payload: payload,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {
          console.log("req", payload);
          console.log(res, "list-supplier-details");
          console.log("list supplier names", res.XscData.SUPPLIER_LIST);

          if (res.XscData.SUPPLIER_LIST.length > 0) {
            {
              searchkeyword.length > 0
                ? setSuppliers(res.XscData.SUPPLIER_LIST)
                : setSuppliers((previoussuppliers) => [
                    ...previoussuppliers,
                    ...res.XscData.SUPPLIER_LIST,
                  ]);
            }
            currentPageRef.current += 1;
            setcurrentPage((prevPage) => prevPage + 1);
            if (searchkeyword.length < 0) {
            }
          } else {
            setHasMore(false);
          }

          setLoading(false);
          setShowText(false); // Hide loading text after fetch is complete
        })
        .catch((err) => {
          console.error(err);
          setLoading(false);
          setShowText(false); // Hide loading text even on error
        });
    }
  };
  useEffect(() => {
    if (props.show) {
      fetchSuppliers();
    }
  }, [props.show]);
  const modalContent = document.querySelector("#filter_content_bg"); // Adjust the selector to match your scrollable container
  const loadingRef = useRef(loading);
  const hasMoreRef = useRef(hasMore);
  const currentPageRef = useRef(currentPage);
  useEffect(() => {
    loadingRef.current = loading;
    hasMoreRef.current = hasMore;
  }, [loading, hasMore]);

  useEffect(() => {
    let debounceTimeout;

    const handleScroll = () => {
      clearTimeout(debounceTimeout);

      debounceTimeout = setTimeout(() => {
        if (
          modalContent.scrollHeight - modalContent.scrollTop <=
            modalContent.clientHeight + 800 &&
          !loadingRef.current &&
          hasMoreRef.current
        ) {
          fetchSuppliers();
          console.log("9380549810");
        }
      }, 400); // Adjust debounce time if needed
    };

    if (modalContent) {
      modalContent.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (modalContent) {
        modalContent.removeEventListener("scroll", handleScroll);
      }
    };
  }, [modalContent]);

  useEffect(() => {
    const payload = {
      CURRENT_PAGE: "",
      SEARCH_KEYWORD: searchkeyword,
      ITEM_PER_PAGE: "",
    };
    if (props.show) {
      makeApiCall({
        mod: "Location",
        action: "get-countries",
        payload: payload,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          console.log(
            "countries names filtered successfully",
            response.XscData.COUNTRY_NAMES
          );

          if (Array.isArray(response.XscData.COUNTRY_NAMES)) {
            setcountries(response.XscData.COUNTRY_NAMES);
          } else {
            console.error("Unexpected data", response.XscData.COUNTRY_LIST);
          }
        })
        .catch((error) => console.error("Error fetching data:", error));
    }
  }, [searchkeyword, props.show]);

  useEffect(() => {
    if (props.show) {
      makeApiCall({
        mod: "Filters",
        action: "type-filter",
        Payload: { APP_CLIENT_ID: 1 },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          console.log(
            "Supplier TYPE filtered successfully",
            response.XscData.FAV_FILTER
          );
          if (Array.isArray(response.XscData.FAV_FILTER)) {
            setSuppliersTypes(response.XscData.FAV_FILTER);
          } else {
            console.error("Unexpected data ", response.XscData.FAV_FILTER);
          }
        })
        .catch((error) => console.error("Error fetching data:", error));
    }
  }, [props.show]);

  useEffect(() => {
    if (props.show) {
      makeApiCall({
        mod: "Filters",
        action: "status-filter",
        Payload: { APP_CLIENT_ID: 1 },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          console.log(
            "Supplier status filtered successfully",
            response.XscData.STATUS_FILTER
          );
          if (Array.isArray(response.XscData.STATUS_FILTER)) {
            setsSuppliersStatus(response.XscData.STATUS_FILTER);
          } else {
            console.error("Unexpected data ", response.XscData.STATUS_FILTER);
          }
        })
        .catch((error) => console.error("Error fetching data:", error));
    }
  }, [props.show]);

  // const handleCheckboxChange = (filterType, value) => {
  //   setSelectedFilters((prevFilters) => ({
  //     ...prevFilters,
  //     [filterType]: prevFilters[filterType].includes(value)
  //       ? prevFilters[filterType].filter((item) => item !== value)
  //       : [...prevFilters[filterType], value],
  //   }));
  // };
  const handleCheckboxChange = (filterType1,filterType2,value,id) => {
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      [filterType1]:
        filterType1 === "supplierType" || filterType1 === "supplierStatus"
          ? // If filterType is 'suppliertype' or 'supplierStatus', allow only one selection
            prevFilters[filterType1] === value
            ? []
            : [value]
          : // For other filterTypes, allow multiple selections
          prevFilters[filterType1].includes(value)
          ? prevFilters[filterType1].filter((item) => item !== value)
          : [...prevFilters[filterType1], value],
    }));
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      [filterType2]:
        filterType2 === "supplierTypeIds" || filterType2 === "supplierStatusIds"
          ? // If filterType is 'suppliertype' or 'supplierStatus', allow only one selection
            prevFilters[filterType2] === id
            ? []
            : [id]
          : // For other filterTypes, allow multiple selections
          prevFilters[filterType2].includes(id)
          ? prevFilters[filterType2].filter((item) => item !== id)
          : [...prevFilters[filterType2], id],
    }));
  };

  const handleCheckboxChangeCountry = (filterType, country) => {
    setSelectedFilters((prevFilters) => {
      const filterArray = prevFilters[filterType];
      
      const isAlreadySelected = filterArray.some(
        (item) => item.COUNTRY === country.COUNTRY
      );
      
      const updatedFilter = isAlreadySelected
        ? filterArray.filter((item) => item.COUNTRY !== country.COUNTRY) // Remove if already selected
        : [...filterArray, country]; // Add if not selected
  
      return { ...prevFilters, [filterType]: updatedFilter };
    });
  };
  const handleCheckboxChangeSupplier = (filterType, supplier) => {
    setSelectedFilters((prevFilters) => {
      const filterArray = prevFilters[filterType];
      
      const isAlreadySelected = filterArray.some(
        (item) => item.NAME === supplier.NAME
      );
      
      const updatedFilter = isAlreadySelected
        ? filterArray.filter((item) => item.NAME !== supplier.NAME) // Remove if already selected
        : [...filterArray, supplier]; // Add if not selected
  
      return { ...prevFilters, [filterType]: updatedFilter };
    });
  };
  const handleCheckboxChangeStatus = (filterType, status) => {
    setSelectedFilters((prevFilters) => {
      const filterArray = prevFilters[filterType];
      
      const isAlreadySelected = filterArray.some(
        (item) => item.TYPE === status.TYPE
      );
      
      const updatedFilter = isAlreadySelected
        ? filterArray.filter((item) => item.TYPE !== status.TYPE) // Remove if already selected
        : [...filterArray, status]; // Add if not selected
  
      return { ...prevFilters, [filterType]: updatedFilter };
    });
  };



  console.log(selectedFilters);

  const handleRemoveFilter = (filterType,filterKey,value,indexToremove) => {
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      [filterType]: prevFilters[filterType].filter((item) => item !== value),
      [filterKey]: prevFilters[filterKey].filter((_,index) => index !== indexToremove)
    }));
   
  };

  const handleApplyFilters = () => {
    //props.onNotify(selectedFilters);
    props.onValue(selectedFilters)
    props.onHide();
  };

  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="filter_text d-flex gap-3"
          >
            Filters
            {/* <hr style={lineStyle}></hr> */}
            {filtercount > 0 ? (
              <div>({filtercount} selected)</div>
            ) : (
              <div></div>
            )}
          </Modal.Title>
        </Modal.Header>
        <div id="selected_fil">
          <div
            className="selected-filters-bg d-flex"
            style={{ paddingLeft: "12px" }}
          >
            {selectedFilters.location.length > 0 &&
              selectedFilters.location.map((filter, index) => (
                <span
                  key={index}
                  className="filter-tag"
                  style={{ marginTop: "8px" }}
                >
                  {filter}

                  <svg
                    onClick={() => handleRemoveFilter("location","locationIds",filter,index)}
                    className="remove-filter-btn"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M7.99967 14.6667C4.31777 14.6667 1.33301 11.6819 1.33301 7.99999C1.33301 4.31809 4.31777 1.33333 7.99967 1.33333C11.6815 1.33333 14.6663 4.31809 14.6663 7.99999C14.6663 11.6819 11.6815 14.6667 7.99967 14.6667ZM7.99967 7.05719L6.11405 5.17157L5.17125 6.11437L7.05687 7.99999L5.17125 9.88559L6.11405 10.8284L7.99967 8.94279L9.88527 10.8284L10.8281 9.88559L8.94247 7.99999L10.8281 6.11437L9.88527 5.17157L7.99967 7.05719Z"
                      fill="#535260"
                    />
                  </svg>
                </span>
              ))}
            {selectedFilters.supplierName.length > 0 &&
              selectedFilters.supplierName.map((filter, index) => (
                <span
                  key={index}
                  className="filter-tag"
                  style={{ marginTop: "8px" }}
                >
                  {filter}

                  <svg
                    onClick={() => handleRemoveFilter("supplierName","supplierNameIds", filter,index)}
                    className="remove-filter-btn"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M7.99967 14.6667C4.31777 14.6667 1.33301 11.6819 1.33301 7.99999C1.33301 4.31809 4.31777 1.33333 7.99967 1.33333C11.6815 1.33333 14.6663 4.31809 14.6663 7.99999C14.6663 11.6819 11.6815 14.6667 7.99967 14.6667ZM7.99967 7.05719L6.11405 5.17157L5.17125 6.11437L7.05687 7.99999L5.17125 9.88559L6.11405 10.8284L7.99967 8.94279L9.88527 10.8284L10.8281 9.88559L8.94247 7.99999L10.8281 6.11437L9.88527 5.17157L7.99967 7.05719Z"
                      fill="#535260"
                    />
                  </svg>
                </span>
              ))}

            {selectedFilters.supplierType.length > 0 &&
              selectedFilters.supplierType.map((filter, index) => (
                <span
                  key={index}
                  className="filter-tag"
                  style={{ marginTop: "8px" }}
                >
                  {filter}
                  <svg
                    onClick={() => handleRemoveFilter("supplierType","supplierTypeIds",filter,index)}
                    className="remove-filter-btn"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M7.99967 14.6667C4.31777 14.6667 1.33301 11.6819 1.33301 7.99999C1.33301 4.31809 4.31777 1.33333 7.99967 1.33333C11.6815 1.33333 14.6663 4.31809 14.6663 7.99999C14.6663 11.6819 11.6815 14.6667 7.99967 14.6667ZM7.99967 7.05719L6.11405 5.17157L5.17125 6.11437L7.05687 7.99999L5.17125 9.88559L6.11405 10.8284L7.99967 8.94279L9.88527 10.8284L10.8281 9.88559L8.94247 7.99999L10.8281 6.11437L9.88527 5.17157L7.99967 7.05719Z"
                      fill="#535260"
                    />
                  </svg>
                </span>
              ))}
            {selectedFilters.supplierStatus.length > 0 &&
              selectedFilters.supplierStatus.map((filter, index) => (
                <span
                  key={index}
                  className="filter-tag"
                  style={{ marginTop: "8px" }}
                >
                  {filter}
                  <svg
                    onClick={() => handleRemoveFilter("supplierStatus","supplierStatusIds",filter,index)}
                    className="remove-filter-btn"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M7.99967 14.6667C4.31777 14.6667 1.33301 11.6819 1.33301 7.99999C1.33301 4.31809 4.31777 1.33333 7.99967 1.33333C11.6815 1.33333 14.6663 4.31809 14.6663 7.99999C14.6663 11.6819 11.6815 14.6667 7.99967 14.6667ZM7.99967 7.05719L6.11405 5.17157L5.17125 6.11437L7.05687 7.99999L5.17125 9.88559L6.11405 10.8284L7.99967 8.94279L9.88527 10.8284L10.8281 9.88559L8.94247 7.99999L10.8281 6.11437L9.88527 5.17157L7.99967 7.05719Z"
                      fill="#535260"
                    />
                  </svg>
                </span>
              ))}
          </div>
        </div>
        <div id="filter_content_bg">
          <div id="fil_side_menu_bg">
            {/* Side menu with filter categories */}
            <div id="fil_side_menu">
              <div className="position-relative d-flex align-items-center">
                {Location && <div id="active_fil_indicator" className=""></div>}
                <div
                  id="single_fil_menu"
                  onClick={handleEvent}
                  style={{
                    color: Location && "var(--primaryContainer)",
                    fontWeight: Location && "600",
                    cursor: "pointer",
                  }}
                >
                  Location
                </div>
              </div>
            </div>
            <div id="fil_side_menu">
              <div className="position-relative d-flex align-items-center">
                {SupplierName && (
                  <div id="active_fil_indicator" className=""></div>
                )}
                <div
                  id="single_fil_menu"
                  onClick={handleSupplierName}
                  style={{
                    color: SupplierName && "var(--primaryContainer)",
                    fontWeight: SupplierName && "600",
                    cursor: "pointer",
                  }}
                >
                  Supplier Name
                </div>
              </div>
            </div>
            <div
              id="fil_side_menu"
              onClick={handleType}
              style={{ cursor: "pointer" }}
            >
              <div className="position-relative d-flex align-items-center">
                {SupplierType && (
                  <div id="active_fil_indicator" className=""></div>
                )}
                <div
                  id="single_fil_menu"
                  style={{
                    color: SupplierType && "var(--primaryContainer)",
                    fontWeight: SupplierType && "600",
                  }}
                >
                  Supplier Type
                </div>
              </div>
            </div>
            <div
              id="fil_side_menu"
              onClick={handleSupplierStatus}
              style={{ cursor: "pointer" }}
            >
              <div className="position-relative d-flex align-items-center">
                {SupplierStatus && (
                  <div id="active_fil_indicator" className=""></div>
                )}
                <div
                  id="single_fil_menu"
                  style={{
                    color: SupplierStatus && "var(--primaryContainer)",
                    fontWeight: SupplierStatus && "600",
                  }}
                >
                  Supplier Status
                </div>
              </div>
            </div>
          </div>

          {Location && (
            <div id="fil_content_bg">
              <SearchBar onSearch={onSearch} />

              {Array.isArray(countries) && countries.length > 0 ? (
                countries.map((country) => (
                  <div key={country.ID}>
                    <label
                      className="checkbox d-flex align-items-center"
                      id="fil_single_item"
                    >
                      {" "}
                      <FormCheck
                        type="checkbox"
                        className="checkbox"
                        checked={selectedFilters.location.includes(
                          country.COUNTRY
                        )}
                        onChange={() =>
                          handleCheckboxChange("location","locationIds", country.COUNTRY,country.ID)
                        }
                      />
                      <div>{country.COUNTRY}</div>
                      {/* Add some spacing between the checkbox and text */}
                    </label>
                  </div>
                ))
              ) : (
                <div>No Location Found</div>
              )}
            </div>
          )}

          {/* for Supplier Name */}
          {SupplierName && (
            <div id="fil_content_bg">
              <SearchBar onSearch={onSearch} />

              {Array.isArray(suppliers) && suppliers.length > 0 ? (
                suppliers.map((supplier) => (
                  <div key={supplier.CLIENT_SUPPLIER_ID}>
                    <label
                      className="checkbox d-flex align-items-center"
                      id="fil_single_item"
                    >
                      <FormCheck
                        type="checkbox"
                        className="checkbox"
                        checked={selectedFilters.supplierName.includes(
                          supplier.NAME
                        )}
                        onChange={() =>
                          handleCheckboxChange("supplierName","supplierNameIds", supplier.NAME,supplier.CLIENT_SUPPLIER_ID)
                        }
                      />
                      <div>{supplier.NAME}</div>
                    </label>
                  </div>
                ))
              ) : (
                <div>No Suppliers Found</div>
              )}
            </div>
          )}

   
          {SupplierType && (
            <div id="fil_content_bg">
              {!SupplierType && <SearchBar />}
              {Array.isArray(suppliersTypes) && suppliersTypes.length > 0 ? (
                suppliersTypes.map((type) => (
                  <div
                    key={type.IS_FAV}
                    className="d-flex align-items-center justify-content-center"
                    id="fil_single_item"
                  >
                    <label
                      className="checkbox d-flex align-items-center"
                      id="fil_single_item"
                    >
                      <FormCheck
                        type="checkbox"
                        className="checkbox"
                        checked={selectedFilters.supplierType.includes(
                          type.TYPE
                        )}
                        onChange={() =>
                          handleCheckboxChange("supplierType","supplierTypeIds",type.TYPE,type.IS_FAV)
                        }
                      />
                      <div>{type.TYPE}</div>
                    </label>
                  </div>
                ))
              ) : (
                <div>No Suppliers Types Available</div>
              )}
            </div>
          )}

          {/* for Supplier Status */}
          {SupplierStatus && (
            <div id="fil_content_bg">
              {!SupplierStatus && <SearchBar />}
              {Array.isArray(suppliersStatus) && suppliersStatus.length > 0 ? (
                suppliersStatus.map((status) => (
                  <div
                    key={status.IS_ACTIVE}
                    className="d-flex align-items-center justify-content-center"
                    id="fil_single_item"
                  >
                    <label
                      className="checkbox d-flex align-items-center"
                      id="fil_single_item"
                    >
                      <FormCheck
                        type="checkbox"
                        className="checkbox"
                        checked={selectedFilters.supplierStatus.includes(
                          status.TYPE
                        )}
                        onChange={() =>
                          handleCheckboxChange("supplierStatus","supplierStatusIds", status.TYPE,status.IS_ACTIVE)
                        }
                      />
                      <div>{status.TYPE}</div>
                    </label>
                  </div>
                ))
              ) : (
                <div>No Suppliers Types Available</div>
              )}
            </div>
          )}
        </div>

        <Modal.Footer>
          <button
            id="reset_btn"
            className="btn"
            onClick={() =>
              setSelectedFilters({
                location: [],
                locationIds: [],
                supplierName: [],
                supplierNameIds: [],
                supplierType: [],
                supplierTypeIds: [],
                supplierStatus: [],
                supplierStatusIds: [],
              })
            }
          >
            Reset{" "}
          </button>
          <button id="apply_btn" className="btn" onClick={()=> handleApplyFilters()}>
            Apply Filters
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default SupplierFilter;
