import React, { useEffect, useRef, useState } from "react";
import SearchBar from "../../../StaticComponents/SearchBar.js";
import Filter from "../../../StaticComponents/Filter";
import "../../../Styles/PurchaseOrder.css";
import { DataGrid } from "@mui/x-data-grid";
import { Dropdown } from "react-bootstrap";
import InvoiceFilter from "./InvoiceFilter.js";
import ReceivePayment from "./ReceivePayment.js";
import excelDownload from "../../../assets/excelDownload.png";
import pdfDownload from "../../../assets/pdfDownload.png";
import { useNavigate, useSearchParams } from "react-router-dom";
import { makeApiCall } from "../../../StaticComponents/API.js";
import nodataimage from "../../../assets/no-data-file.png";

export default function Invoice() {
  const [modalShow, setModalShow] = useState(false);
  const [show, setShow] = useState(false);
  const [pageSize, setPageSize] = React.useState(10);
  const [optionShow, setOptionShow] = useState(false);
  const [optionPosition, setOptionPosition] = useState({ top: 0, left: 0 });
  const [invoiceStatus, setInvoiceStatus] = useState("");
  const ellipsisRef = useRef(null);
  const [page, setpage] = useState(0);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [selectedRow, setSelectedRow] = useState(null);
  const [total, setTotal] = useState(0);
  const [selectedId, setSelectedId] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const API_URL = process.env.REACT_APP_API_URL;
  const [invId, setInvID] = useState();

  const handleEllipsisClick = (e, invoiceStatus, paymentStatus, params) => {
    if (!params.row) return; // Ensure params.row is valid

    setSelectedRow(params.row);
    const rect = e.currentTarget.getBoundingClientRect();
    setOptionPosition({
      top: rect.bottom + window.scrollY,
      left: rect.left + window.scrollX,
    });
    setSelectedId(params.row.ID);
    setOptionShow(true);

    const invoiceStatusText = getInvoiceStatus(invoiceStatus, paymentStatus);
    setInvoiceStatus(invoiceStatusText);
    setInvID(params.row.ID);
    localStorage.setItem("INVOICE_NUM", params.row.id);
    localStorage.setItem("INVOICE_ID", params.row.ID);
    localStorage.setItem("CUST_INV_ID", params.row.ID);
    console.log(selectedId);
  };
  console.log(invId, "invid");
  console.log(selectedId, "sectedId");
  const handleClickOutside = (event) => {
    if (ellipsisRef.current && !ellipsisRef.current.contains(event.target)) {
      setOptionShow(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  let token = localStorage.getItem("token");

  const APP_CLIENT_ID = localStorage.getItem("CLIENT_ID");
  const CLIENT_CUST_ID = localStorage.getItem("CLIENT_CUST_ID");

  const PAYMENT_STATUS_MAP = {
    0: "Unpaid",
    1: "Paid",
  };

  const STATUS_CLASS_MAP = {
    "Pending Approval": "invoice_pending_approval_bg", // Class for pending approval
    Rejected: "invoice_reject_bg", // Class for rejected
    Completed: "invoice_completed_status_bg", // Class for completed
    Unpaid: "invoice_reject_bg", // Reuse rejected for unpaid
    Paid: "invoice_completed_status_bg", // Reuse completed for paid
    "Receive Payment": "invoice_payment_due_bg", // Class for payment due
  };

  const getInvoiceStatus = (invoiceStatus, paymentStatus) => {
    if (invoiceStatus === null && paymentStatus === 0)
      return "Pending Approval";
    if (invoiceStatus === null && paymentStatus === 1)
      return "Pending Approval";
    if (invoiceStatus === 0) return "Rejected";
    if (invoiceStatus === 1 && paymentStatus === 0) return "Receive Payment";
    if (invoiceStatus === 1 && paymentStatus === 1) return "Completed";

    return "Pending Approval";
  };

  const handleSearch = (query) => {
    setSearchQuery(query);
  };

  function convertUnixTimestampToDate(unixTimestamp) {
    const date = new Date(unixTimestamp * 1000);
    const formattedDate = `${String(date.getDate()).padStart(2, "0")}-${String(
      date.getMonth() + 1
    ).padStart(2, "0")}-${date.getFullYear()}`;
    return formattedDate;
  }

  const payload = {
    APP_CLIENT_ID: APP_CLIENT_ID,
    CLIENT_CUST_ID: CLIENT_CUST_ID,
    CURRENT_PAGE: page + 1,
    SEARCH_KEYWORD: searchQuery,
    ITEM_PER_PAGE: pageSize,
  };

  useEffect(() => {
    makeApiCall({
      mod: "Customer",
      action: "customer_order_list",
      payload: payload,
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        console.log(response);
        console.log(payload);
        const cust_inv = response.XscData.SUPPLIER_INVOICE;
        if (Array.isArray(cust_inv)) {
          const mappedRows = cust_inv.map((customer_invoice) => ({
            ID: customer_invoice.ID,
            id: customer_invoice.INVOICE_NO || "N/A",
            PO_ID: customer_invoice.CUST_PO_NUM || "N/A",
            TOTAL_ITEM: customer_invoice.TOTAL_QTY || "N/A",
            TOTAL_CTNS: customer_invoice.TOTAL_CTNS || "N/A",
            CREATED_ON: convertUnixTimestampToDate(
              customer_invoice.CREATED_ON || "N/A"
            ),
            TOTAL_AMOUNT: `${customer_invoice.CURRENCY_SYMBOL}${
              customer_invoice.TOTAL_AMOUNT || "0"
            }`,
            PENDING_AMOUNT: `${customer_invoice.CURRENCY_SYMBOL}${
              customer_invoice.DUE_AMOUNT || "0"
            }`,
            DELIVERY_DATE:
              convertUnixTimestampToDate(customer_invoice.CREATED_ON) || "0",
            PAYMENT_STATUS: customer_invoice.PAYMENT_STATUS,
            INVOICE_STATUS: customer_invoice.INVOICE_STATUS,

            ACTION: (
              <Dropdown className="table_more" style={{ border: "none" }}>
                <Dropdown.Toggle
                  id="dropdown-basic"
                  style={{
                    backgroundColor: "white",
                    color: "#5D6B82",
                    fontSize: "1rem",
                    fontWeight: "400",
                    lineHeight: "1.5rem",
                    border: "none",
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="4"
                    height="18"
                    viewBox="0 0 4 18"
                    fill="none"
                  >
                    <path
                      d="M2 0C1.175 0 0.5 0.675 0.5 1.5C0.5 2.325 1.175 3 2 3C2.825 3 3.5 2.325 3.5 1.5C3.5 0.675 2.825 0 2 0ZM2 15C1.175 15 0.5 15.675 0.5 16.5C0.5 17.325 1.175 18 2 18C2.825 18 3.5 17.325 3.5 16.5C3.5 15.675 2.825 15 2 15ZM2 7.5C1.175 7.5 0.5 8.175 0.5 9C0.5 9.825 1.175 10.5 2 10.5C2.825 10.5 3.5 9.825 3.5 9C3.5 8.175 2.825 7.5 2 7.5Z"
                      fill="#5D6B82"
                    />
                  </svg>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={(e, params) => handleViewInvoice(e, params)}
                  >
                    View Invoice
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            ),
          }));

          // settotal(response.XscData.TOTAL_CTN);
          setRows(mappedRows); // Set rows after mapping
          setTotal(response.XscData.TOTAL_ORDERS);
        } else {
          console.error("Suppliers data is not an array or is missing");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [page, pageSize, searchQuery]);

  const columns = [
    {
      field: "id",
      headerName: "Invoice No",
      width: 200,
      cellClassName: "sticky-column",
    },
    {
      field: "PO_ID",
      headerName: "Cust.PO-No",
      width: 160,
      editable: false,
      align: "left",
      renderCell: (params) => params.value,
    },
    {
      field: "TOTAL_ITEM",
      headerName: "Total Item",
      width: 150,
      editable: false,
      align: "left",
      renderCell: (params) => params.value,
    },
    {
      field: "TOTAL_CTNS",
      headerName: "Total CTNS",
      width: 150,
      editable: false,
      align: "left",
      renderCell: (params) => params.value,
    },
    {
      field: "CREATED_ON",
      headerName: "Created On",
      width: 160,
      editable: false,
      align: "left",
    },
    {
      field: "TOTAL_AMOUNT",
      headerName: "Total Amount",
      width: 170,
      editable: false,
      align: "left",
    },
    {
      field: "PENDING_AMOUNT",
      headerName: "Pending Amount",
      width: 180,
      editable: false,
      align: "left",
    },
    {
      field: "DELIVERY_DATE",
      headerName: "Delivery Date",
      width: 170,
      editable: false,
      align: "left",
    },
    {
      field: "PAYMENT_STATUS",
      headerName: "Payment Status",
      width: 180,
      editable: false,
      align: "left",
      headerAlign: "left",
      renderCell: (params) => {
        const statusValue = PAYMENT_STATUS_MAP[params.value]; // Get the mapped status text
        const className = STATUS_CLASS_MAP[statusValue] || ""; // Get the class based on the status

        return <div className={className}>{statusValue}</div>;
      },
    },
    {
      field: "INVOICE_STATUS",
      headerName: "Invoice Status",
      width: 200,
      editable: false,
      align: "left",
      renderCell: (params) => {
        const invoiceStatus = params.row.INVOICE_STATUS;
        const paymentStatus = params.row.PAYMENT_STATUS;

        const invoiceStatusText = getInvoiceStatus(
          invoiceStatus,
          paymentStatus
        );

        const className = STATUS_CLASS_MAP[invoiceStatusText] || "";

        return <div className={className}>{invoiceStatusText}</div>;
      },
    },
    {
      field: "ACTION",
      headerName: "Action",
      width: 150,
      editable: false,
      sortable: false,
      align: "left",
      renderCell: (params) => {
        const invoiceStatus = params.row.INVOICE_STATUS;
        const paymentStatus = params.row.PAYMENT_STATUS;
        let ID = params.row.ID;
        const invoiceNum = params.row.INVOICE_NO;
        if (invoiceStatus === 0) {
          return <div></div>;
        }
        return (
          <div
            style={{
              cursor: "pointer",
              width: "30px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            onClick={(e) =>
              handleEllipsisClick(
                e,
                invoiceStatus,
                paymentStatus,
                params,
                invoiceNum
              )
            }
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="4"
              height="18"
              viewBox="0 0 4 18"
              fill="none"
            >
              <path
                d="M2 0C1.175 0 0.5 0.675 0.5 1.5C0.5 2.325 1.175 3 2 3C2.825 3 3.5 2.325 3.5 1.5C3.5 0.675 2.825 0 2 0ZM2 15C1.175 15 0.5 15.675 0.5 16.5C0.5 17.325 1.175 18 2 18C2.825 18 3.5 17.325 3.5 16.5C3.5 15.675 2.825 15 2 15ZM2 7.5C1.175 7.5 0.5 8.175 0.5 9C0.5 9.825 1.175 10.5 2 10.5C2.825 10.5 3.5 9.825 3.5 9C3.5 8.175 2.825 7.5 2 7.5Z"
                fill="#5D6B82"
              />
            </svg>
          </div>
        );
      },
    },
  ];

  const initialRows = [];

  const [rows, setRows] = React.useState(initialRows);

  const handleShow = () => {
    setShow(true);
  };

  const custId = searchParams.get("id");
  const handleCreateInvoice = (params) => {
    navigate(`/customers/create-invoice`);
  };

  const handleApproveStatusChange = (row) => {
    const id = localStorage.getItem("ORDER_ID");
    console.log("Approving invoice with ID:", id);

    const payload = {
      CUST_INV_ID: id,
      APPROVAL_STATUS: 1,
    };

    console.log("Payload for approval:", payload);

    makeApiCall({
      mod: "CustomerInvoice",
      action: "approve-reject-invoice",
      payload: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        console.log("API Response for status update:", response);

        if (response?.XscStatus === 1) {
          setRows((prevRows) =>
            prevRows.map((r) => (r.id === id ? { ...r, INVOICE_STATUS: 1 } : r))
          );

          console.log("Status successfully updated");
        } else {
          console.error("Error in API response:", response);
        }
      })
      .catch((error) => console.error("Error updating status:", error));
  };

  const handleRejectStatusChange = (row) => {
    const id = localStorage.getItem("ORDER_ID");
    console.log("Rejecting invoice with ID:", id);

    const payload = {
      CUST_INV_ID: id,
      APPROVAL_STATUS: 0,
    };

    console.log("Payload for approval:", payload);

    makeApiCall({
      mod: "CustomerInvoice",
      action: "approve-reject-invoice",
      payload: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        console.log("API Response for status update:", response);

        if (response?.XscStatus === 1) {
          setRows(
            (prevRows) =>
              prevRows.map((r) =>
                r.id === id ? { ...r, INVOICE_STATUS: 0 } : r
              ) // Update row status
          );
          console.log("Status successfully updated");
        } else {
          console.error("Error in API response:", response);
        }
      })
      .catch((error) => console.error("Error updating status:", error));
  };

  const handleINExcel = () => {
    const payload = {
      CLIENT_CUST_ID: CLIENT_CUST_ID,
      CUST_INV_ID: selectedId,
      APP_CLIENT_ID: APP_CLIENT_ID,
    };
    makeApiCall({
      mod: "CustomerInvoice",
      action: "customer_invoice_excel",
      payload: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => {
      console.log("payload of invoice excel", payload);
      console.log("Invoice Excel SuccesS ", response);
      if (response.XscStatus === 1) {
        const url = `${API_URL}/${response.XscData.URL}`;
        fetch(url)
          .then((response) => response.blob())
          .then((blob) => {
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = "invoice.xlsx"; // Set the filename here
            link.click();
          });
      }
    });
  };

  const handleINVPDF = () => {
    const payload = {
      CLIENT_CUST_ID: CLIENT_CUST_ID,
      CUST_INV_ID: selectedId,
      APP_CLIENT_ID: APP_CLIENT_ID,
    };
    makeApiCall({
      mod: "CustomerInvoice",
      action: "customer_invoice_pdf",
      payload: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => {
      console.log("payload of invoice pdf", payload);
      console.log("Invoice PDF SUCCESS ", response);
      if (response.XscStatus === 1) {
        const url = `${API_URL}/${response.XscData.URL}`;
        fetch(url)
          .then((response) => response.blob())
          .then((blob) => {
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = "invoice.pdf"; // Set the filename here
            link.click();
          });
      }
    });
  };

  console.log(selectedId);

  const handleViewInvoice = (params) => {
    const id = invId;   
    navigate(`/customers/view-invoice`);
  };

  const handlemodifyInvoice = (params) => {
    const id = invId;
    navigate(`/customers/modify-invoice`);
  };

  return (
    <div>
      <div className="container">
        <div>
          <div id="po_heading">Invoice</div>
          <div
            className="d-flex justify-content-between"
            id="supp_filters"
            style={{ marginTop: "20px" }}
          >
            <div className="d-flex">
              <div>
                <SearchBar onSearch={handleSearch} />
              </div>
              <div
                onClick={() => setModalShow(true)}
                style={{ cursor: "pointer" }}
              >
                <Filter />
              </div>
              <InvoiceFilter
                show={modalShow}
                onHide={() => setModalShow(false)}
              />
            </div>
            {/* <a href="/customers/create-invoice"> */}
            <div
              id="create_po_button"
              onClick={handleCreateInvoice}
              style={{ cursor: "pointer" }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M11 11V5H13V11H19V13H13V19H11V13H5V11H11Z"
                  fill="#F7F7F7"
                />
              </svg>
              Create Invoice
            </div>
            {/* </a> */}
          </div>
        </div>
        <div
          id="po_table"
          style={{
            height: 600,
            width: "100%",
            marginBottom: "80px",
            position: "relative",
            overflow: "hidden",
          }}
        >
          <DataGrid
            localeText={{
              noRowsLabel: (
                <div
                  className="no_rows_css"
                  style={{
                    pointerEvents: "auto",
                    position: "relative",
                    zIndex: 10,
                  }}
                >
                  <img src={nodataimage} alt="" />
                  <div id="no_data_found_text">
                    You don’t have Invoices for this customer yet
                  </div>
                  <div
                    id="create_po_button"
                    onClick={(event) => {
                      event.stopPropagation(); // Prevent event bubbling
                      handleCreateInvoice();
                    }}
                    style={{ cursor: "pointer", marginTop: "28px" }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M11 11V5H13V11H19V13H13V19H11V13H5V11H11Z"
                        fill="#F7F7F7"
                      />
                    </svg>
                    Create Invoice
                  </div>
                </div>
              ),
            }}
            style={{ position: "relative", overflow: "hidden" }}
            rows={rows}
            columns={columns}
            pageSize={pageSize}
            rowCount={total}
            page={page}
            pagination
            paginationMode="server"
            rowsPerPageOptions={[10, 20, 50]}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            onPageChange={(newPage) => setpage(newPage)}
            disableSelectionOnClick
          />
        </div>
        {optionShow && (
          <div
            className="options-dropdown ellipsisOption"
            ref={ellipsisRef}
            style={{
              top: optionPosition.top,
              left: optionPosition.left,
            }}
          >
            {invoiceStatus === "Pending Approval" && (
              <div className="d-flex flex-column align-items-start gap-2">
                <div
                  id="dropdown_options"
                  onClick={handleViewInvoice} // Ensure selectedId is properly set
                  style={{
                    textDecoration: "none",
                    color: "#535260",
                    cursor: "pointer",
                  }}
                >
                  View Invoice
                </div>

                <a
                  id="dropdown_options"
                  onClick={handlemodifyInvoice}
                  style={{
                    textDecoration: "none",
                    color: "#535260",
                    cursor: "pointer",
                  }}
                >
                  Modify Invoice
                </a>
                <div
                  onClick={() => handleApproveStatusChange(selectedRow)}
                  id="dropdown_options"
                  href="/events/edit-event"
                  style={{
                    textDecoration: "none",
                    color: "#535260",
                    cursor: "pointer",
                    cursor: "pointer",
                  }}
                >
                  Approve
                </div>
                <div
                  onClick={() => handleRejectStatusChange(selectedRow)}
                  id="dropdown_options"
                  href="/events/edit-event"
                  style={{
                    textDecoration: "none",
                    color: "#535260",
                    cursor: "pointer",
                  }}
                >
                  Reject
                </div>
              </div>
            )}
            {invoiceStatus === "Completed" && (
              <div className="d-flex flex-column align-items-start gap-2">
                <div
                  id="dropdown_options"
                  onClick={handleShow}
                  style={{
                    cursor: "pointer",
                    textDecoration: "none",
                    color: "#535260",
                    cursor: "pointer",
                  }}
                >
                  Receive Payment
                </div>
                <div
                  id="dropdown_options"
                  onClick={handleViewInvoice} // Ensure selectedId is properly set
                  style={{
                    textDecoration: "none",
                    color: "#535260",
                    cursor: "pointer",
                  }}
                >
                  View Invoice
                </div>

                <a
                  id="dropdown_options"
                  // href="/events/edit-event"
                  style={{
                    textDecoration: "none",
                    color: "#535260",
                    cursor: "pointer",
                  }}
                  onClick={handleINExcel}
                  className="d-flex flex-row align-items-center gap-2"
                >
                  <div>
                    <img src={excelDownload} />
                  </div>
                  Download Excel
                </a>
                <a
                  id="dropdown_options"
                  // href="/events/edit-event"
                  style={{
                    textDecoration: "none",
                    color: "#535260",
                    cursor: "pointer",
                  }}
                  onClick={handleINVPDF}
                  className="d-flex flex-row align-items-center gap-2"
                >
                  <div>
                    <img src={pdfDownload} />
                  </div>
                  Download Pdf
                </a>
              </div>
            )}
            {invoiceStatus === "Receive Payment" && (
              <div className="d-flex flex-column align-items-start gap-2">
                <div
                  id="dropdown_options"
                  onClick={handleShow}
                  style={{
                    cursor: "pointer",
                    textDecoration: "none",
                    color: "#535260",
                    cursor: "pointer",
                  }}
                >
                  Receive Payment
                </div>
                <div
                  id="dropdown_options"
                  onClick={handleViewInvoice} // Ensure selectedId is properly set
                  style={{
                    textDecoration: "none",
                    color: "#535260",
                    cursor: "pointer",
                  }}
                >
                  View Invoice
                </div>

                <a
                  id="dropdown_options"
                  // href="/events/edit-event"
                  style={{
                    textDecoration: "none",
                    color: "#535260",
                    width: 200,
                    cursor: "pointer",
                  }}
                  onClick={handleINExcel}
                  className="d-flex flex-row align-items-center gap-2"
                >
                  <div>
                    <img src={excelDownload} />
                  </div>
                  Download Excel
                </a>
                <a
                  id="dropdown_options"
                  // href="/events/edit-event"
                  style={{
                    textDecoration: "none",
                    color: "#535260",
                    width: 200,
                    cursor: "pointer",
                  }}
                  onClick={handleINVPDF}
                  className="d-flex flex-row align-items-center gap-2"
                >
                  <div>
                    <img src={pdfDownload} />
                  </div>
                  Download Pdf
                </a>
              </div>
            )}
          </div>
        )}
      </div>
      <ReceivePayment show={show} onHide={() => setShow(false)} />
    </div>
  );
}
