import React, { useState, useEffect } from "react";
import "../Styles/Customers.css";
import SearchBar from "../StaticComponents/SearchBar";
import { makeApiCall } from "../StaticComponents/API";
import Filter from "../StaticComponents/Filter";
import CustomerTable from "../Components/Customers/CustomerTable";
import CustomerFilters from "../Components/Customers/CustomerFilters";

function Customers() {
  const [modalShow, setModalShow] = React.useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [favoriteCount, setFavoriteCount] = useState();

  const handleSearch = (query) => {
    setSearchQuery(query);
  };

  let APP_CLIENT_ID = localStorage.getItem("CLIENT_ID");

  let token = localStorage.getItem("token");

  const [customerDetails, setCustomerDetails] = React.useState({
    xscdata: "",
  });
  const [notify, setnotify] = useState();
  const onNotify = (value) => {
    setnotify(value);
  };
  const getData = () => {
    const payload = {
      // CURRENT_PAGE: "",
      // SEARCH_KEYWORD: "",
      // ITEMS_PER_PAGE: "",
      APP_CLIENT_ID: APP_CLIENT_ID,
    };

    makeApiCall({
      mod: "Customer",
      action: "list-customer",
      payload: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((res) => {
      console.log(res, "supplier-info");
      setCustomerDetails({
        xscdata: res.XscData,
      });
      setFavoriteCount(res.XscData.FAVOURITE_CUSTOMER);
    });
  };

  useEffect(() => {
    getData();
  }, []);

  const updateFavoriteCount = (newCount) => {
    setFavoriteCount(newCount);
  };


  return (
    <div id="cust_bg">
      <div
        id="customers_topbar_bg"
        style={{ paddingLeft: "0", paddingRight: "0" }}
      >
        <div className="container d-flex justify-content-between align-items-center">
          <div id="dashboard_header">Customer</div>
          <div id="supplier_header_buttons">
            <a href="/customers/import-customers">
              <button className="btn" id="import_supplier_button">
                Import Customer
              </button>
            </a>

            <a href="/customers/add-customer" className="text-decoration-none">
              {" "}
              <button className="btn" id="add_supplier_button">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M11 11V5H13V11H19V13H13V19H11V13H5V11H11Z"
                    fill="#F7F7F7"
                  />
                </svg>
                Add Customer
              </button>
            </a>
          </div>
        </div>
      </div>
      <div className="container">
        {" "}
        <div className="row" id="sup_overview_heading">
          <div className="col-md-4">
            <div id="supp_title">
              Total Customer
              <div id="customers_count">
                {customerDetails.xscdata.TOTAL_CUSTOMERS}
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div id="supp_title">
              Regular Customer
              <div id="customers_count">
                {customerDetails.xscdata.REGULAR_CUSTOMER}
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div id="supp_title">
              Favourite Customer
              <div id="customers_count">
                {favoriteCount}
              </div>
            </div>{" "}
          </div>
        </div>
        <div className="d-flex" id="supp_filters">
          <div>
            <SearchBar onSearch={handleSearch} />
          </div>
          <div onClick={() => setModalShow(true)} style={{ cursor: "pointer" }}>
            <Filter notify={notify} />
          </div>
          <CustomerFilters
            show={modalShow}
            onHide={() => setModalShow(false)}
            onNotify={onNotify}
          />
        </div>
        <div id="supp_tab_bg">
          <CustomerTable searchQuery={searchQuery}
            notify={notify}
            onFavoriteCountUpdate={updateFavoriteCount} />
        </div>
      </div>
    </div>
  );
}

export default Customers;
