import React, { useEffect, useState } from "react";
import "../../../Styles/AddProduct.css";
import productUploadImg from "../../../assets/Text Field.png";
import cancelbutton from "../../../assets/Frame.png";
import PhoneInput from "react-phone-number-input/input";
import "../../../Styles/EditProduct.css";
import DeleteProduct from "./DeleteProduct";
import DeleteImage from "./DeleteImage";
import TextInput from "../../Inputs/TextInput";
import TextInputWithAll from "../../Inputs/TextInputWithAll";
import { toast, ToastContainer } from "react-toastify";
import { makeApiCall } from "../../../StaticComponents/API";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import DropDownWithInput from "../../Inputs/DropDownWithInput";
import axios from "axios";
import DropDownInput from "../../Inputs/DropDownInput";

const EditProduct = () => {
  const maxChars = 50;
  const [files, setFiles] = useState([]);
  const [fileName, setFileName] = useState("");
  const [image, setImage] = useState([]);
  const [noOfImages, setNoOfImages] = useState(0);
  const [show, setShow] = useState(false);
  const [deleteshow, setDeleteShow] = useState(false);
  const [mnxId, setMnxId] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [charCount, setCharCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [status, setStatus] = useState("");
  const [Productmediaimage, setProductmedia] = useState([]);
  const [ctnTypes, setCtnTypes] = useState([]);
  const maxCharCount = 100;
  let [searchParams] = useSearchParams();
  const [deleteImage, setDeleteImage] = useState(false);

  const navigate = useNavigate();
  // Set initial values here
  const initialData = {
    CLIENT_BAR_CODE: "",
    CLIENT_COMPANY_ID: "",
    CLIENT_DUN_BAR_CODE: "",
    CLIENT_PROD_NUM: "",
    CLIENT_SUPP_ID: "",
    COLOUR: "",
    CTN_GROSS_WT: "",
    CTN_ID: "",
    CTN_NAME: "",
    CTN_NET_WT: "",
    CTN_PKG_TYPE: "",
    CTN_WT_UNIT: "",
    DESCRIPTION: "",
    MATERIAL: "",
    PACKING: "",
    PRICE: "",
    PRODUCT_ID: "",
    PROD_HEIGHT: "",
    PROD_LENGTH: "",
    PROD_SIZE_UNIT: "",
    PROD_VOLUME: "",
    PROD_VOLUME_UNIT: "",
    PROD_WIDTH: "",
    PROD_WT: "",
    PROD_WT_UNIT: "",
    QTY_PER_CTN: "",
    SUPP_BAR_CODE: "",
    SUPP_COMPANY_ID: "",
    SUPP_DUN_BAR_CODE: "",
    SUPP_PROD_NUM: "",
    IS_ACTIVE:""
  };

  const [data, setData] = useState(initialData);

  const lengthOptions = [
    { value: "MTR", label: "m" },
    { value: "CM", label: "cm" },
    { value: "IN", label: "in" },
    { value: "FEET", label: "feet" },
    { value: "MM", label: "mm" },
  ];

  const weightOptions = [
    { value: "GM", label: "g" },
    { value: "KG", label: "Kg" },
    { value: "LBS", label: "lbs" },
  ];

  const volumeOptions = [
    { value: "CB_CM", label: "cm³" },
    { value: "CB_MTR", label: "m³" },
    { value: "CB_FEET", label: "feet³" },
    { value: "CB_MM", label: "mm³" },
    { value: "CB_IN", label: "in³" },
  ];

  const { id } = useParams();
  const PRODUCT_ID = localStorage.getItem("PRODUCT_ID");
  console.log(PRODUCT_ID, "prod_idedit");
  const CLIENT_SUPPLIER_ID = localStorage.getItem("CLIENT_SUPPLIER_ID");
  let token = localStorage.getItem("token");

  const APP_CLIENT_ID = localStorage.getItem("CLIENT_ID");

  useEffect(() => {
    makeApiCall({
      mod: "Products",
      action: "get-product-details",
      payload: {
        CLIENT_SUPPLIER_ID: CLIENT_SUPPLIER_ID,
        PRODUCT_ID: PRODUCT_ID,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        console.log(response, "product-detail");
        if (response && response.XscData) {
          setData(response.XscData.PRODUCT_DETAILS);
          setLoading(false); // Set loading to false when data is fetched
          setProductmedia(response.XscData.PROD_MEDIA);
          const imagesarray = response.XscData.PROD_MEDIA;

          setFiles(imagesarray);
        }
      })
      .catch((error) => {
        console.error("Error fetching product details:", error);
        setError("Failed to fetch product details.");
        setLoading(false); // Set loading to false even if there's an error
      });
  }, [token]);

  useEffect(() => {
    const payload = {
      APP_CLIENT_ID: APP_CLIENT_ID,
    };
    makeApiCall({
      mod: "Shipping",
      action: "get-ctn-list",
      payload: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        console.log(response);
        if (Array.isArray(response.XscData.CTN_LIST)) {
          setCtnTypes(
            response.XscData.CTN_LIST.map((type) => ({
              ID: type.ID, // Adjust based on actual response structure
              ALIAS_NAME: type.ALIAS_NAME, // Adjust based on actual response structure
            }))
          );
        } else {
          console.error(
            "Unexpected data format for CTN types:",
            response.XscMessage
          );
        }
      })
      .catch((error) => {
        console.error("Error fetching CTN types:", error);
      });
  }, []);

  const handleSelectChangeCTNTYPE = (event) => {
    const { name, value } = event.target;
    setData((prevData) => ({
      ...prevData,
      CTN_ID: value,
    }));
  };

  console.log(data.CTN_ID);

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(data, "data");
    const payload = {
      CLIENT_SUPPLIER_ID: data.CLIENT_SUPP_ID,
      PRODUCT_ID: data.PRODUCT_ID,
      APP_CLIENT_ID: data.CLIENT_COMPANY_ID,
      SUPPLIER_PROD_NUM: data.SUPP_PROD_NUM,
      CLIENT_PROD_NUM: data.CLIENT_PROD_NUM,
      CLIENT_P_BAR_CODE: data.CLIENT_BAR_CODE,
      SUPP_P_BAR_CODE: data.SUPP_BAR_CODE,
      CLIENT_DUN_BAR_CODE: data.CLIENT_DUN_BAR_CODE,
      SUPP_DUN_BAR_CODE: data.SUPP_DUN_BAR_CODE,
      // CLIENT_PRODUCT_ID: data.CLIENT_PROD_NUM,
      P_DESC: data.DESCRIPTION,
      P_MATERIAL: data.MATERIAL,
      P_COLOR: data.COLOUR,
      PRICE_PER_PCS: data.PRICE,
      P_LENGTH: data.PROD_LENGTH,
      P_WIDTH: data.PROD_WIDTH,
      P_HEIGHT: data.PROD_HEIGHT,
      SIZE_UNIT: data.PROD_SIZE_UNIT,
      P_WT: data.PROD_WT,
      P_WT_UNIT: data.PROD_WT_UNIT,
      VOLUME: data.PROD_VOLUME,
      VOLUME_UNIT: data.PROD_VOLUME_UNIT,
      P_PKG_TYPE: data.PACKING,
      QTY_PER_CTN: data.QTY_PER_CTN,

      // CBM: data.PROD_VOLUME,
      // CBM_UNIT: data.PROD_VOLUME_UNIT,

      CTN_ID: data.CTN_ID,
      C_PKG_TYPE: data.CTN_PKG_TYPE,
      CTN_NET_WT: data.CTN_NET_WT,
      // CTN_NET_WT_UNIT: data.CTN_WT_UNIT,

      CTN_GROSS_WT: data.CTN_GROSS_WT,
      CTN_WT_UNIT: data.CTN_WT_UNIT,

      IS_ACTIVE: data.IS_ACTIVE,
    };

    const checkRequiredFields = (payload) => {
      let missingFields = [];

      // Adjust the field names and add checks
      if (!payload.CLIENT_SUPPLIER_ID) missingFields.push("Client Supplier ID");
      if (!payload.PRODUCT_ID) missingFields.push("Product ID");
      if (!payload.APP_CLIENT_ID) missingFields.push("App Client ID");
      if (!payload.SUPPLIER_PROD_NUM)
        missingFields.push("Supplier Product Num");
      if (!payload.CLIENT_PROD_NUM) missingFields.push("Client Product Num");
      if (!payload.CLIENT_P_BAR_CODE) missingFields.push("Client Bar Code");
      if (!payload.SUPP_P_BAR_CODE) missingFields.push("Supp Bar Code");
      if (!payload.CLIENT_DUN_BAR_CODE)
        missingFields.push("Client Dun Bar Code");
      if (!payload.SUPP_DUN_BAR_CODE) missingFields.push("Client Dun Bar Code");
      if (!payload.P_DESC) missingFields.push("Product Description");
      // if (!payload.P_MATERIAL) missingFields.push("Product Material");
      if (!payload.P_COLOR) missingFields.push("Product Color");
      if (payload.PRICE_PER_PCS === undefined || payload.PRICE_PER_PCS === null)
        if (!payload.P_LENGTH) missingFields.push("Product Length");
      if (!payload.P_WIDTH) missingFields.push("Product Width");
      if (!payload.P_HEIGHT) missingFields.push("Product Height");
      if (!payload.SIZE_UNIT) missingFields.push("Size Unit");
      if (payload.P_WT === undefined || payload.P_WT === null)
        missingFields.push("Product Weight");
      if (payload.P_WT_UNIT === undefined || payload.P_WT_UNIT === null)
        missingFields.push("Product Volume Unit");
      if (payload.VOLUME === undefined || payload.VOLUME === null)
        missingFields.push("Product Volume");
      if (payload.VOLUME_UNIT === undefined || payload.VOLUME === null)
        missingFields.push("Product Volume Unit");
      if (!payload.CTN_ID) missingFields.push("Carton ID");
      if (!payload.QTY_PER_CTN)
        if (!payload.C_PKG_TYPE) missingFields.push("Carton Package Type");
      if (!payload.CTN_NET_WT) missingFields.push("Carton Net Weight");
      if (payload.CTN_GROSS_WT === undefined || payload.CTN_GROSS_WT === null)
        if (!payload.CTN_WT_UNIT) missingFields.push("Gross Weight Unit");

      return missingFields;
    };

    const missingFields = checkRequiredFields(payload);

    if (missingFields.length > 0) {
      const errorMessage = `Please fill in the following fields: ${missingFields.join(
        ", "
      )}`;
      toast.error(errorMessage, {
        position: "bottom-center",
      });
    } else {
      const payload11 = {
        PRODUCT_ID: payload.PRODUCT_ID,
      };

      makeApiCall({
        mod: "Products",
        action: "update-product",
        payload: payload,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          console.log(response, "edit-detail");
          console.log(payload, "payload");
          if (response && response.XscData) {
            // setData(response.XscData.PRODUCT_DETAILS);
            setLoading(false); // Set loading to false when data is fetched
            setStatus(response.XscStatus);
            response.XscStatus == 1 &&
              toast.success("Product Updated Successfully!");
            localStorage.setItem("activeTab", "Products");

            navigate("/suppliers/supplier-info");
          }
        })
        .catch((error) => {
          console.error("Error fetching product details:", error);
          setError("Failed to fetch product details.");
          setLoading(false); // Set loading to false even if there's an error
        });
      // navigate("/suppliers/supplier-info/");
    }
  };

  useEffect(() => {
    setCharCount(data.DESCRIPTION.length);
  }, [data.DESCRIPTION]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Define a regex for numeric validation
    const numberRegex = /^[0-9]*\.?[0-9]*$/;

    // List of fields that should only accept numeric values
    const numericFields = [
      "SUPP_DUN_BAR_CODE",
      "CLIENT_DUN_BAR_CODE",
      "PRICE",
      "QTY_PER_CTN",
      "PROD_LENGTH",
      "PROD_WIDTH",
      "PROD_HEIGHT",
      "PROD_VOLUME",
      "CTN_NET_WT",
      "CTN_GROSS_WT",
      "CLIENT_BAR_CODE",
      "SUPP_BAR_CODE",
    ];

    // Check if the field is in the list of numeric fields
    if (numericFields.includes(name)) {
      // Allow empty string or valid number input
      if (!value || numberRegex.test(value)) {
        setData({
          ...data,
          [name]: value,
        });
      }
      // Ignore invalid input
      return;
    }

    if (name === "DESCRIPTION") {
      if (value.length <= maxCharCount) {
        setData((prevData) => ({ ...prevData, [name]: value }));
        setCharCount(value.length);
      }
      return;
    }

    setData({
      ...data,
      [name]: value,
    });
  };
  const [prodMMID, setprodMMID] = useState("");
  const handleDeleteImage = (e, indexToDelete) => {
    // Filter out the file from the `files` array
    setDeleteShow(true);
    // Get the `PROD_MM_ID` from the Productimage array using the index
    const imageToDelete = files[indexToDelete]; // Assuming `Productimage` array is available
    const prodMMID = imageToDelete.PROD_MM_ID;
    setprodMMID(prodMMID);
  };
  const StatusOptions = [
    { value: 1, label: "Active" },
    { value: 0, label: "Inactive" },
  ];
  const ImageOptions = [
    { value: "1", label: "Select Image" },
    { value: "2", label: "Image 1" },
    { value: "3", label: "Image 2" },
  ];

  const LengthOptions = [
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
  ];

  const HeightOptions = [
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
  ];

  const WidthOptions = [
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
  ];

  const VolumeOptions = [
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
  ];

  const PackagingOptions = [
    { value: "1", label: "Active" },
    { value: "2", label: "Inactive" },
  ];

  const WeightOptions = [
    { value: "1", label: "Active" },
    { value: "2", label: "Inactive" },
  ];

  const CTNOptions = [
    { value: "1", label: "2" },
    { value: "2", label: "3" },
  ];

  const handleFileChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const selectedFile = e.target.files[0];

      // Create a URL for the selected file and add it to the array of files
      // setFiles((prevFiles) => [
      //   ...prevFiles,
      //   URL.createObjectURL(selectedFile),
      // ]);
      setFileName(selectedFile.name);
      const payloadimage = {
        PRODUCT_ID: data.PRODUCT_ID,
      };
      setImage((prevImages) => {
        const updatedImages = [...prevImages, selectedFile];

        // Automatically set the primary image if it's the only one
        if (updatedImages.length === 1) {
          setData((prevData) => ({
            ...prevData,
            PRIMARY_IMAGE_INDEX: 0,
          }));
        }

        return updatedImages;
      });
      const formdata = new FormData();
      formdata.append("mod", "Products");
      formdata.append("action", "add-product-media");
      formdata.append("payload", JSON.stringify(payloadimage));
      formdata.append("files", selectedFile);

      axios({
        method: "post",
        url: process.env.REACT_APP_API_URL,
        // url:"http://192.168.1.107:5000/",
        // url:"http://192.168.1.105:5000/",
        data: formdata,
        headers: {
          "Content-Type": `multipart/form-data boundary=${formdata._boundary}`,
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {
          console.log(res, "Product media added successfully");
          window.location.reload();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handleSelectChange = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleImageChange = (e) => {
    const { name, value } = e.target; // Get the selected index
    const selectedImage = files[value]; // Get the corresponding image from `Productimage`
    console.log("image number", value);
    console.log("id", selectedImage.PROD_MM_ID);
    const Prod_mm_id = selectedImage.PROD_MM_ID;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    console.log("Product id", data.PRODUCT_ID);
    const payloadimage = {
      PRODUCT_ID: data.PRODUCT_ID,
      PRODUCT_MM_ID: Prod_mm_id,
    };
    const formdata = new FormData();
    formdata.append("mod", "Products");
    formdata.append("action", "set-product-media-default");
    formdata.append("payload", JSON.stringify(payloadimage));

    axios({
      method: "post",
      url: process.env.REACT_APP_API_URL,
      //  url:"http://192.168.1.107:5000/",
      data: formdata,
      headers: {
        "Content-Type": `multipart/form-data boundary=${formdata._boundary}`,
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        console.log(res, "Product set media successfully");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const API_URL = process.env.REACT_APP_API_URL;
  // console.log("image uploaded",image)
  // console.log("images we are upladong",image)
  // console.log("files uploaded",files)
  // console.log("images already uploading",Productmediaimage)
  const handleCancel = () => {
    navigate(-1);
    localStorage.setItem("activeTab", "Products");
  };

  return (
    <div id="prod_bg">
      <div
        id="suppliers_topbar_bg"
        style={{ paddingLeft: "0", paddingRight: "0" }}
      >
        <div className="container d-flex justify-content-between align-items-center ">
          <div id="header_name" className="d-flex ">
            <div className="">
              <svg
                onClick={handleCancel}
                style={{ cursor: "pointer" }}
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M7.82843 11.0009H20V13.0009H7.82843L13.1924 18.3648L11.7782 19.779L4 12.0009L11.7782 4.22266L13.1924 5.63687L7.82843 11.0009Z"
                  fill="var(--onSurface)"
                />
                <path
                  d="M7.82843 11.0009H20V13.0009H7.82843L13.1924 18.3648L11.7782 19.779L4 12.0009L11.7782 4.22266L13.1924 5.63687L7.82843 11.0009Z"
                  fill="black"
                  fill-opacity="0.2"
                />
                <path
                  d="M7.82843 11.0009H20V13.0009H7.82843L13.1924 18.3648L11.7782 19.779L4 12.0009L11.7782 4.22266L13.1924 5.63687L7.82843 11.0009Z"
                  fill="black"
                  fill-opacity="0.2"
                />
                <path
                  d="M7.82843 11.0009H20V13.0009H7.82843L13.1924 18.3648L11.7782 19.779L4 12.0009L11.7782 4.22266L13.1924 5.63687L7.82843 11.0009Z"
                  fill="black"
                  fill-opacity="0.2"
                />
                <path
                  d="M7.82843 11.0009H20V13.0009H7.82843L13.1924 18.3648L11.7782 19.779L4 12.0009L11.7782 4.22266L13.1924 5.63687L7.82843 11.0009Z"
                  fill="black"
                  fill-opacity="0.2"
                />
              </svg>
            </div>

            <span className="ps-2">
              {" "}
              <div className="inner_text1">Edit Product</div>
              <div className="inner_text2">
                {data.CLIENT_PROD_NUM || "N/A"},{data.SUPP_PROD_NUM || "N/A"}
              </div>
            </span>
          </div>
        </div>
      </div>

      {/* <SupplierHeader /> */}
      <div className="">
        <div className="images_upload_Container container   ">
          <div className="col-12">
            <div className=" d-flex img-container-123 ">
              <div
                className="images_upload_Container"
                style={{
                  display: "flex",
                  flexDirection: "row", // Ensures images are in a row
                  flexWrap: "wrap", // Allows wrapping if there are many images
                }}
              >
                {files.length > 0 ? (
                  files.map((file, index) => (
                    <div
                      key={index}
                      id="product_upload_img_bg"
                      style={{
                        cursor: "pointer",
                        marginTop: 24,
                        marginLeft: index === 0 ? 5 : 30,
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          gap: "10px",
                          overflow: "hidden",
                        }}
                        className="image_upload_container1"
                      >
                        <div
                          className="image_container_upload"
                          onClick={(e) => handleDeleteImage(e, index)}
                          style={{
                            width: "28px",
                            height: "28px",
                            flexShrink: 0,
                            backgroundColor:
                              "var(--Schemes-On-Surface, #1B1B24)",
                            opacity: "0.5",
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                          >
                            <path
                              d="M8.00045 7.05767L11.3003 3.75781L12.2431 4.70062L8.94325 8.00047L12.2431 11.3003L11.3003 12.2431L8.00045 8.94327L4.70063 12.2431L3.75781 11.3003L7.05765 8.00047L3.75781 4.70062L4.70063 3.75781L8.00045 7.05767Z"
                              fill="white"
                            />
                          </svg>
                        </div>
                        <img
                          className="img_class_names"
                          src={API_URL + `media/${file.PROD_MEDIA}`}
                          alt={" uploaded file"}
                          style={{ width: "145px" }}
                        />
                      </div>
                    </div>
                  ))
                ) : (
                  <div
                    id="product_upload_img_bg"
                    style={{
                      cursor: "pointer",
                      marginTop: 24,
                      marginLeft: 10,
                    }}
                  >
                    <div style={{ display: "flex", gap: "10px" }}>
                      <label
                        style={{ width: "40px" }}
                        htmlFor="file-input"
                        className="d-flex align-items-center"
                        id=""
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="40"
                          height="40"
                          viewBox="0 0 40 40"
                          fill="none"
                        >
                          <path
                            d="M34.9987 25V30H39.9987V33.3333H34.9987V38.3333H31.6654V33.3333H26.6654V30H31.6654V25H34.9987ZM35.0124 5C35.9254 5 36.6654 5.74158 36.6654 6.65567V21.6667H33.332V8.33333H6.66536V31.665L23.332 15L28.332 20V24.715L23.332 19.714L11.377 31.6667H23.332V35H4.98503C4.07211 35 3.33203 34.2585 3.33203 33.3443V6.65567C3.33203 5.74127 4.09088 5 4.98503 5H35.0124ZM13.332 11.6667C15.173 11.6667 16.6654 13.159 16.6654 15C16.6654 16.841 15.173 18.3333 13.332 18.3333C11.4911 18.3333 9.9987 16.841 9.9987 15C9.9987 13.159 11.4911 11.6667 13.332 11.6667Z"
                            fill="#5A5892"
                          />
                        </svg>
                      </label>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <TextInputWithAll
              name="file-input"
              type="file"
              onChange={handleFileChange}
              id="file-input"
              style={{ display: "none" }}
              accept="image/*"
              multiple
            />
          </div>

          <div className="add_more_images" align="left">
            {files.length >= 5 ? (
              <div></div>
            ) : (
              <div className="add_more_img124345">
                <svg
                  className="inner_add_svg"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M11 11V5H13V11H19V13H13V19H11V13H5V11H11Z"
                    fill="#635BFF"
                  />
                </svg>
                <label htmlFor="file-input" className="add_images_text">
                  Add More Images
                </label>
              </div>
            )}
          </div>

          <div className="select_primary_image">
            <label>Select Primary Image</label>
            <br />
            <select
              id="selectimage"
              name="PRIMARY_IMAGE_INDEX"
              value={data.PRIMARY_IMAGE_INDEX || ""}
              onChange={handleImageChange}
            >
              <option value="" disabled>
                Select Image
              </option>
              {files.map((file, index) => (
                <option key={index} value={index}>
                  Image {index + 1}{" "}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="product_details_divider"></div>

        <div className="container">
          <div className="divider_heading" align="left">
            <div className="divider"></div>
            <div className="heading_images">Product Details</div>
          </div>

          <form className="row product_details">
            <div className="col-5" id="add_supplier_input">
              <div className="position-relative" style={{ width: "380px" }}>
                <label>Supplier Product ID<span style={{ color: "#950F27" }}>*</span></label>
                <br />
                <TextInput
                  name="SUPP_PROD_NUM"
                  type="text"
                  value={data.SUPP_PROD_NUM}
                  onChange={handleInputChange}
                />{" "}
                <div
                  id="remaining_characters"
                  className="position-absolute transalate-middle-x "
                >
                  {/* {charCount}/50 */}
                </div>
              </div>{" "}
              <div className="position-relative" style={{ width: "380px" }}>
                <label>Manex Product ID<span style={{ color: "#950F27" }}>*</span></label>
                <br />
                <TextInput
                  name="mnxId"
                  type="text"
                  value={data.CLIENT_PROD_NUM}
                  onChange={(e) => setMnxId(e.target.value)}
                />{" "}
                <div
                  id="remaining_characters"
                  style={{
                    fontWeight: 500,
                    color: "var(--primaryContainer, #635BFF)",
                  }}
                >
                  {mnxId.length > 0 ? (
                    <svg
                      onClick={() => setMnxId("")}
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 10.5858L9.17157 7.75736L7.75736 9.17157L10.5858 12L7.75736 14.8284L9.17157 16.2426L12 13.4142L14.8284 16.2426L16.2426 14.8284L13.4142 12L16.2426 9.17157L14.8284 7.75736L12 10.5858Z"
                        fill="#535260"
                      />
                    </svg>
                  ) : (
                    "Generate"
                  )}
                </div>
              </div>{" "}
              <div className="position-relative" style={{ width: "380px" }}>
                <label>Product Description<span style={{ color: "#950F27" }}>*</span></label>
                <br />
                <TextInput
                  name="DESCRIPTION"
                  type="text"
                  value={data.DESCRIPTION}
                  className="d8884215685"
                  onChange={handleInputChange}
                />{" "}
                <div id="remaining_characters" className="d9380549810">
                  {charCount}/{maxCharCount}
                </div>
              </div>
              <div>
                <label>Pieces Per CTN<span style={{ color: "#950F27" }}>*</span></label>
                <br />
                <TextInput
                  name="QTY_PER_CTN"
                  type="text"
                  value={data.QTY_PER_CTN}
                  onChange={handleInputChange}
                />
              </div>
              <div className="position-relative" style={{ width: "380px" }}>
                <label>Product Length<span style={{ color: "#950F27" }}>*</span></label>
                <br />
                <DropDownWithInput
                  options={lengthOptions}
                  inputProps={{
                    className: "packaging_input",
                    name: "PROD_LENGTH",
                    value: data.PROD_LENGTH,
                    onChange: handleInputChange,
                    style: {
                      width: "330px",
                      marginRight: "0px",
                      border: "none",
                      background: "transparent",
                      marginTop: "0px",
                    },
                  }}
                  selectProps={{
                    className: "selectpackageing_type",
                    value: data.PROD_SIZE_UNIT,
                    onChange: handleSelectChange,
                    style: {
                      width: "45px",
                      border: "none",
                      background: "transparent",
                      outline: "none",
                      paddingLeft: "0px",
                    },
                  }}
                />
              </div>
              <div className="position-relative" style={{ width: "380px" }}>
                <label>Product Height<span style={{ color: "#950F27" }}>*</span></label>
                <br />
                <DropDownWithInput
                  options={lengthOptions}
                  inputProps={{
                    name: "PROD_HEIGHT",
                    className: "packaging_input",
                    value: data.PROD_HEIGHT,
                    onChange: handleInputChange,
                    style: {
                      width: "330px",
                      marginRight: "0px",
                      border: "none",
                      background: "transparent",
                      marginTop: "0px",
                    },
                  }}
                  selectProps={{
                    name: "PROD_SIZE_UNIT",
                    className: "selectpackageing_type",
                    value: data.PROD_SIZE_UNIT,
                    onChange: handleSelectChange,
                    style: {
                      width: "45px",
                      border: "none",
                      background: "transparent",
                      outline: "none",
                      paddingLeft: "0px",
                    },
                  }}
                />
              </div>
              <div>
                <label>Product Color</label>
                <br />
                <TextInput
                  name="COLOUR"
                  type="text"
                  value={data.COLOUR}
                  onChange={handleInputChange}
                />
              </div>
              <DropDownInput
                options={StatusOptions}
                id="selectCountry"
                label="Select Status"
                placeholder="Select Status"
                nama="STATUS"
                value={data.IS_ACTIVE === null ? "1" : data.IS_ACTIVE}
                onChange={(e) => {
                  const newStatus = e.target.value;
                   setData((prev) => ({
                     ...prev,
                      IS_ACTIVE: newStatus, 
                   }));
                   }}
              />
            </div>
            <div className="col-5" id="add_supplier_input">
              <div>
                <label>Supplier Product Barcode Number<span style={{ color: "#950F27" }}>*</span></label>
                <br />
                <TextInput
                  name="SUPP_BAR_CODE"
                  type="text"
                  value={data.SUPP_BAR_CODE}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <label>Manex Product Barcode Number<span style={{ color: "#950F27" }}>*</span></label>
                <br />
                <TextInput
                  name="CLIENT_BAR_CODE"
                  type="text"
                  value={data.CLIENT_BAR_CODE}
                  onChange={handleInputChange}
                />
              </div>
              <div className="position-relative" style={{ width: "380px" }}>
                <label>Product Price Per Piece<span style={{ color: "#950F27" }}>*</span></label>
                <br />
                <TextInputWithAll
                  name="PRICE"
                  type="text"
                  value={data.PRICE}
                  onChange={handleInputChange}
                  style={{ paddingLeft: 30 }}
                />
                <div
                  id="remaining_characters"
                  className="position-absolute translate-middle-x"
                  style={{
                    fontWeight: 500,
                    left: 9,
                    paddingLeft: 16,
                    paddingRight: 0,
                    width: 9,
                  }}
                >
                  ¥
                </div>
              </div>
              <div>
                <label>Packaging<span style={{ color: "#950F27" }}>*</span></label>
                <br />
                <TextInputWithAll
                  name="PACKING"
                  type="text"
                  id="selectpackageingtype"
                  value={data.PACKING}
                  onChange={handleInputChange}
                  // style={{ paddingLeft: 30 }}
                />
              </div>
              <div className="position-relative" style={{ width: "380px" }}>
                <label>Product Width<span style={{ color: "#950F27" }}>*</span></label>
                <br />
                <DropDownWithInput
                  options={lengthOptions}
                  inputProps={{
                    name: "PROD_WIDTH",
                    className: "packaging_input",
                    value: data.PROD_WIDTH,
                    onChange: handleInputChange,
                    style: {
                      width: "330px",
                      marginRight: "0px",
                      border: "none",
                      background: "transparent",
                      marginTop: "0px",
                    },
                  }}
                  selectProps={{
                    name: "PROD_SIZE_UNIT",
                    className: "selectpackageing_type",
                    value: data.PROD_SIZE_UNIT,
                    onChange: handleSelectChange,
                    style: {
                      width: "45px",
                      border: "none",
                      background: "transparent",
                      outline: "none",
                      paddingLeft: "0px",
                    },
                  }}
                />
              </div>
              <div className="position-relative" style={{ width: "380px" }}>
                <label>Product Volume</label>
                <br />
                <DropDownWithInput
                  options={volumeOptions}
                  inputProps={{
                    name: "PROD_VOLUME",
                    className: "packaging_input",
                    value: data.PROD_VOLUME,
                    onChange: handleInputChange,
                    style: {
                      width: "320px",
                      marginRight: "0px",
                      border: "none",
                      background: "transparent",
                      marginTop: "0px",
                    },
                  }}
                  selectProps={{
                    name: "PROD_VOLUME_UNIT",
                    className: "selectpackageing_type",
                    value: data.PROD_VOLUME_UNIT,
                    onChange: handleSelectChange,
                    style: {
                      width: "55px",
                      border: "none",
                      background: "transparent",
                      outline: "none",
                      paddingLeft: "0px",
                    },
                  }}
                />
              </div>
              <div>
                <label>Product Material</label>
                <br />
                <TextInput
                  name="MATERIAL"
                  type="text"
                  value={data.MATERIAL}
                  onChange={handleInputChange}
                />
              </div>
            </div>
          </form>

          <div className="product_ctns_details_divider"></div>
        </div>

        <div className="container" style={{ paddingBottom: 60 }}>
          <div className="divider_heading" align="left">
            <div className="divider"></div>
            <div className="heading_images">CTNS Details</div>
          </div>

          <form className="row product_details">
            <div className="col-5" id="add_supplier_input">
              <div className="position-relative" style={{ width: "380px" }}>
                <label>Package Type</label>
                <br />{" "}
                <TextInput
                  name="CTN_PKG_TYPE"
                  type="text"
                  value={data.CTN_PKG_TYPE}
                  onChange={handleInputChange}
                  id="selectpackageingtype"
                />
              </div>{" "}
              <div className="position-relative" style={{ width: "380px" }}>
                <label>Net Weight<span style={{ color: "#950F27" }}>*</span></label>
                <br />
                <DropDownWithInput
                  options={weightOptions}
                  inputProps={{
                    name: "CTN_NET_WT",
                    className: "packaging_input",
                    value: data.CTN_NET_WT,
                    onChange: handleInputChange,
                    style: {
                      width: "330px",
                      marginRight: "0px",
                      border: "none",
                      background: "transparent",
                      marginTop: "0px",
                    },
                  }}
                  selectProps={{
                    name: "CTN_WT_UNIT",
                    className: "selectpackageing_type",
                    value: data.CTN_WT_UNIT,
                    onChange: handleSelectChange,
                    style: {
                      width: "45px",
                      border: "none",
                      background: "transparent",
                      outline: "none",
                      paddingLeft: "0px",
                    },
                  }}
                />
              </div>
              <div>
                <label>Supplier DUN Barcode Number<span style={{ color: "#950F27" }}>*</span></label>
                <br />
                <TextInput
                  name="SUPP_DUN_BAR_CODE"
                  type="text"
                  value={data.SUPP_DUN_BAR_CODE}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="col-5" id="add_supplier_input">
              <div>
                <label>CTN Type<span style={{ color: "#950F27" }}>*</span></label>
                <br />
                <select
                  name="CTN_ID"
                  value={data.CTN_ID}
                  // value=""
                  onChange={handleSelectChangeCTNTYPE}
                  style={{
                    width: "380px",
                    height: "48px",
                    border: "1px solid var(--Palettes-Neutral-90, #E5E1E6",
                    borderRadius: "8px",
                    marginTop: "6px",
                    padding: "10px",
                  }}
                >
                  <option value="">Select CTN Type</option>
                  {ctnTypes.map((type) => (
                    <option key={type.ID} value={type.ID}>
                      {type.ALIAS_NAME}
                    </option>
                  ))}
                </select>
              </div>{" "}
              <div className="position-relative" style={{ width: "380px" }}>
                <label>Gross Weight<span style={{ color: "#950F27" }}>*</span></label>
                <br />
                <DropDownWithInput
                  options={weightOptions}
                  inputProps={{
                    name: "CTN_GROSS_WT",
                    className: "packaging_input",
                    value: data.CTN_GROSS_WT,
                    onChange: handleInputChange,
                    style: {
                      width: "330px",
                      marginRight: "0px",
                      border: "none",
                      background: "transparent",
                      marginTop: "0px",
                    },
                  }}
                  selectProps={{
                    name: "CTN_WT_UNIT",
                    className: "selectpackageing_type",
                    value: data.CTN_WT_UNIT,
                    onChange: handleSelectChange,
                    style: {
                      width: "45px",
                      border: "none",
                      background: "transparent",
                      outline: "none",
                      paddingLeft: "0px",
                    },
                  }}
                />
              </div>
              <div>
                <label>Manex DUN Barcode Number<span style={{ color: "#950F27" }}>*</span></label>
                <br />
                <TextInput
                  name="CLIENT_DUN_BAR_CODE"
                  type="text"
                  value={data.CLIENT_DUN_BAR_CODE}
                  onChange={handleInputChange}
                />
              </div>
            </div>
          </form>
        </div>

        {/* <div className="edit_product_footer">
          <div className="footer_btns">
            <div className="del_btn" onClick={() => setShow(true)}>
              <button style={{ padding: "12px" }}>Delete Supplier</button>
            </div>
            <DeleteProduct show={show} onHide={() => setShow(false)} />

            <div className="update_cancel_btn_div">
              <button className="cancel_btn">Cancel</button>
              <button className="update_btn" style={{ padding: "12px" }}>
                Update Product
              </button>
            </div>
          </div>
        </div> */}

        <div id="add_supplier_footer" className="">
          <div>
            <div id="add_supplier_buttons" className="container">
              {" "}
              <div id="delete_supplier_button" onClick={() => setShow(true)}>
                Delete Product
              </div>
              <DeleteProduct
                show={show}
                onHide={() => setShow(false)}
                ProdID={PRODUCT_ID}
              />
              <DeleteImage
                show={deleteshow}
                onHide={() => setDeleteShow(false)}
                prodMMID={prodMMID}
              />
              <div
                className="d-flex align-items-center justify-content-right "
                style={{ gap: "12px" }}
              >
                {/* <a
                  href="/suppliers/supplier-info/product-info"
                  className="text-decoration-none"
                >
                  <div id="cancel">Cancel</div>
                </a>
                 */}
                <button id="cancel" onClick={handleCancel}>
                  Cancel
                </button>
                <a
                  // href="/suppliers/supplier-info/product-info"
                  className="text-decoration-none"
                >
                  <div id="submitsupplier" onClick={handleSubmit}>
                    Update Product
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditProduct;
