import { useState, useEffect } from "react";
import Filter from "../../StaticComponents/Filter.js";
import SearchBar from "../../StaticComponents/SearchBar.js";
import "../../Styles/ReportComponents.css";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { Dropdown } from "react-bootstrap";
import DatePickerModal from "../Reports/DatePickerModal.js";
import CustomerReportTable from "./Reports/CustomerReportTable.js";
import CustomerReportFilter from "./Reports/CustomerReportFilter.js";
import { makeApiCall } from "../../StaticComponents/API.js";

export default function CustomerReportPage() {
  let token = localStorage.getItem("token");
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearch = (query) => {
    setSearchQuery(query);
  };


  const [modalShow, setModalShow] = useState(false);
  const [customerReportDetails, setCustomerReporttDetails] = useState({
    xscdata: "",
  });

  const [page, setpage] = useState(0);

  const [notify, setnotify] = useState();
  const onNotify = (value) => {
    setnotify(value);
  };
  const getData = () => {
    const payload = {
      APP_CLIENT_ID: localStorage.getItem("CLIENT_ID"),
      CURRENT_PAGE: page + 1,
      CLIENT_CUST_ID: localStorage.getItem("CLIENT_CUST_ID"),
    };

    makeApiCall({
      mod: "Customer",
      action: "customers-report",
      payload: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((res) => {
      console.log(res, "customer-reports");
      setCustomerReporttDetails({
        xscdata: res.XscData,
      });
    });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div id="report_bg">
      <div className="container">
        <div id="report_overview_heading" className="row">
          <div className="col-md-4">
            <div id="total_stock">
              Total Orders
              <div id="total_count">
                {customerReportDetails.xscdata.TOTAL_ORDERS}
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div id="total_stock">
              Pending Receivables
              <div id="total_count">
                {"\u00a5"}
                {customerReportDetails.xscdata.PENDING_RECEIVABLE}
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex" id="invFilters">
          <SearchBar onSearch={handleSearch}/>
          <div onClick={() => setModalShow(true)} style={{ cursor: "pointer" }}>
            <Filter />
          </div>
          <CustomerReportFilter
            show={modalShow}
            onHide={() => setModalShow(false)}
          />
          <Dropdown>
            <Dropdown.Toggle
              style={{
                marginLeft: "12px",
                width: "301px",
                height: "40px",
                backgroundColor: "var(--onSurfaceLowest)",
                color: "var(--onSurface)",
                border: "1px solid var(--outline1)",
                borderRadius: "var(--cr---3)",
              }}
            >
              Date
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <DatePickerModal />
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div id="report_tab_bg">
          <CustomerReportTable searchQuery={searchQuery} />
        </div>
      </div>
    </div>
  );
}
