import React, { useEffect, useRef, useState } from "react";
import SearchBar from "../../../StaticComponents/SearchBar.js";
import Filter from "../../../StaticComponents/Filter.js";
import "../../../Styles/PurchaseOrder.css";
import { DataGrid } from "@mui/x-data-grid";
import { Dropdown } from "react-bootstrap";
import PIFilter from "./PIFilter.js";
import excelDownload from "../../../assets/excelDownload.png";
import pdfDownload from "../../../assets/pdfDownload.png";
import { makeApiCall } from "../../../StaticComponents/API.js";
import { useNavigate } from "react-router-dom";
import nodataimage from "../../../assets/no-data-file.png";

export default function PerformaInvoice() {
  const [modalShow, setModalShow] = useState(false);

  const [pageSize, setPageSize] = React.useState(10);
  const [loading, setLoading] = useState(false);
  const [optionShow, setOptionShow] = useState(false);
  const [optionPosition, setOptionPosition] = useState({ top: 0, left: 0 });
  const [currentStatus, setCurrentStatus] = useState("");

  const [selectedId, setSelectedId] = useState(null); // New state to track selected row's ID
  const API_URL = process.env.REACT_APP_API_URL;

  const [page, setpage] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");

  const [total, settotal] = React.useState(0);
  const ellipsisRef = useRef(null);

  let token = localStorage.getItem("token");
  const APP_CLIENT_ID = localStorage.getItem("CLIENT_ID");
  const CLIENT_CUST_ID = localStorage.getItem("CLIENT_CUST_ID");

  const handleEllipsisClick = (e, orderStatusValue, id, num) => {
    // Determine the currentStatus based on ORDER_STATUS
    if (orderStatusValue === 1) {
      setCurrentStatus("Approved");
    } else if (orderStatusValue === null) {
      setCurrentStatus("Pending Approval");
    } else if (orderStatusValue === 0) {
      setCurrentStatus("Rejected");
    }

    const rect = e.currentTarget.getBoundingClientRect();
    setOptionPosition({
      top: rect.bottom + window.scrollY,
      left: rect.left + window.scrollX,
    });
    setOptionShow(true);
    setSelectedId(id);
    localStorage.setItem("CUST_PI_ID", id);
    localStorage.setItem("CUST_PO_NUM", num);
  };

  const handleStatusChange = (id, newStatus) => {
    const payload = { CUST_PI_ID: id, APPROVAL_STATUS: newStatus };

    makeApiCall({
      mod: "CustomerPI",
      action: "approve-reject-pi",
      payload: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        console.log("Status updated", response);
        // Update local state to reflect changes
        fetchProformaInvoices();
        // setRows((prevRows) =>
        //   prevRows.map((row) =>
        //     row.id === id ? { ...row, STATUS: newStatus } : row
        //   )
        // );
      })
      .catch((error) => console.error("Error updating status:", error));
  };

  //   const handleEllipsisClick = (e, orderStatus, id) => {
  // 	if (orderStatus === "Pending") {
  // 	  handleStatusChange(id, "Approved");
  // 	}
  //   };

  const handleClickOutside = (event) => {
    if (ellipsisRef.current && !ellipsisRef.current.contains(event.target)) {
      setOptionShow(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handlePIPDF = () => {
    const payload = {
      CLIENT_CUST_ID: CLIENT_CUST_ID,
      CUST_PI_ID: selectedId,
      APP_CLIENT_ID: APP_CLIENT_ID,
    };
    makeApiCall({
      mod: "CustomerPI",
      action: "proforma_invoice_pdf",
      payload: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => {
      console.log("apyload of pi pdf", payload);
      console.log("Pi PDF SUCCESS ", response);
      if (response.XscStatus === 1) {
        const url = `${API_URL}/${response.XscData.URL}`;
        fetch(url)
          .then((response) => response.blob())
          .then((blob) => {
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = "proforma_invoice.pdf"; // Set the filename here
            link.click();
          });
      }
    });
    setOptionShow(false);
  };

  const handlePIExcel = () => {
    const payload = {
      CLIENT_CUST_ID: CLIENT_CUST_ID,
      CUST_PI_ID: selectedId,
      APP_CLIENT_ID: APP_CLIENT_ID,
    };
    makeApiCall({
      mod: "CustomerPI",
      action: "proforma_invoice_excel",
      payload: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => {
      console.log("apyload of pi excel", payload);
      console.log("Pi Excel Success", response);
      if (response.XscStatus === 1) {
        const url = `${API_URL}/${response.XscData.URL}`;
        fetch(url)
          .then((response) => response.blob())
          .then((blob) => {
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = "proforma_invoice.xlsx"; // Set the filename here
            link.click();
          });
      }
    });
    setOptionShow(false);
  };

  const columns = [
    {
      field: "INV_NUM",
      headerName: "Invoice No",
      width: 180,
      cellClassName: "sticky-column",
    },
    {
      field: "PO_ID",
      headerName: "Cust.PO-No",
      width: 200,
      editable: false,
      align: "left",
      renderCell: (params) => params.value,
    },
    {
      field: "PI_FILE",
      headerName: "PI File",
      width: 180,
      editable: false,
      align: "left",
      renderCell: (params) => {
        const fileName = params.value;
        const handleClick = () => {
          localStorage.setItem("CUST_PI_ID", params.row.id);
          localStorage.setItem("CUST_PO_NUM", params.row.PO_ID);
          navigate(`/customers/view-pi`);
        };
        return (
          <div
            id="po_view_button"
            style={{ cursor: "pointer" }}
            onClick={() => handleClick()}
          >
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M5 4H15V8H19V20H5V4ZM3.9985 2C3.44749 2 3 2.44405 3 2.9918V21.0082C3 21.5447 3.44476 22 3.9934 22H20.0066C20.5551 22 21 21.5489 21 20.9925L20.9997 7L16 2H3.9985ZM10.4999 7.5C10.4999 9.07749 10.0442 10.9373 9.27493 12.6534C8.50287 14.3757 7.46143 15.8502 6.37524 16.7191L7.55464 18.3321C10.4821 16.3804 13.7233 15.0421 16.8585 15.49L17.3162 13.5513C14.6435 12.6604 12.4999 9.98994 12.4999 7.5H10.4999ZM11.0999 13.4716C11.3673 12.8752 11.6042 12.2563 11.8037 11.6285C12.2753 12.3531 12.8553 13.0182 13.5101 13.5953C12.5283 13.7711 11.5665 14.0596 10.6352 14.4276C10.7999 14.1143 10.9551 13.7948 11.0999 13.4716Z"
                  fill="var(--primaryContainer)"
                />
              </svg>
            </div>
            <div>View</div>
          </div>
        );
      },
    },
    {
      field: "TOTAL_CTNS",
      headerName: "Total CTNS",
      width: 180,
      editable: false,
      align: "left",
    },

    {
      field: "TOTAL_AMOUNT",
      headerName: "Total Amount",
      type: "number",
      width: 180,
      editable: false,
      align: "left",
    },
    {
      field: "CREATED_ON",
      headerName: "Created On",
      width: 180,
      editable: false,
      align: "left",
    },
    {
      field: "ORDER_STATUS",
      headerName: "Order Status",
      width: 180,
      editable: false,
      align: "left",
      renderCell: (params) => {
        const statusValue = PAYMENT_STATUS_MAP[params.value]; // Get the mapped status text
        const className = STATUS_CLASS_MAP[statusValue] || ""; // Get the class based on the status

        return <div className={className}>{statusValue}</div>;
      },
    },
    {
      field: "ACTION",
      headerName: "Action",
      width: 101,
      editable: false,
      sortable: false,
      align: "center",
      renderCell: (params) => {
        let orderStatusValue = params.row.ORDER_STATUS;
        let id = params.row.id; // Access the row ID here
        let num = params.row.PO_ID;
        if (orderStatusValue === 0) {
          return <div></div>;
        }
        return (
          <div
            style={{
              cursor: "pointer",
              width: "30px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            onClick={(e) => handleEllipsisClick(e, orderStatusValue, id, num)} // Pass id to handleEllipsisClick
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M2 0C1.175 0 0.5 0.675 0.5 1.5C0.5 2.325 1.175 3 2 3C2.825 3 3.5 2.325 3.5 1.5C3.5 0.675 2.825 0 2 0ZM2 15C1.175 15 0.5 15.675 0.5 16.5C0.5 17.325 1.175 18 2 18C2.825 18 3.5 17.325 3.5 16.5C3.5 15.675 2.825 15 2 15ZM2 7.5C1.175 7.5 0.5 8.175 0.5 9C0.5 9.825 1.175 10.5 2 10.5C2.825 10.5 3.5 9.825 3.5 9C3.5 8.175 2.825 7.5 2 7.5Z"
                fill="#5D6B82"
              />
            </svg>
          </div>
        );
      },
    },
  ];

  const PAYMENT_STATUS_MAP = {
    1: "Approved",
    0: "Rejected",
    null: "Pending Approval",
  };

  function convertUnixTimestampToDate(unixTimestamp) {
    const date = new Date(unixTimestamp * 1000);
    const formattedDate = `${String(date.getDate()).padStart(2, "0")}-${String(
      date.getMonth() + 1
    ).padStart(2, "0")}-${date.getFullYear()}`;
    return formattedDate;
  }

  const STATUS_CLASS_MAP = {
    "Pending Approval": "invoice_pending_approval_bg", // Class for pending approval
    Rejected: "invoice_reject_bg", // Class for rejected
    Approved: "invoice_completed_status_bg", // Class for completed
  };
  const payload = {
    CLIENT_CUST_ID: localStorage.getItem("CLIENT_CUST_ID"),
    SEARCH_KEYWORD: searchQuery,
    CURRENT_PAGE: page + 1,
    ITEM_PER_PAGE: pageSize,
  };
  const initialRows = [];
  const [rows, setRows] = React.useState(initialRows);

  const rowsWithIcons = rows.map((row) => ({
    ...row,
  }));
  const handlepagechange = (newpage) => {
    setpage(newpage);
  };
  const handlepagesizechange = (num) => {
    setPageSize(num);
    setpage(0);
  };
  const handleSearch = (query) => {
    setSearchQuery(query);
  };

  const fetchProformaInvoices = () => {
    makeApiCall({
      mod: "CustomerPI",
      action: "list_cust_pi",
      payload: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        console.log("request", payload);
        console.log("Proforma Invoice Fetched  Sucessfully", response);
        const pI = response.XscData.CUST_PI_LIST;

        if (Array.isArray(pI)) {
          const mappedRows = pI.map((proformaInvoice) => ({
            id: proformaInvoice.CUST_PI_ID,
            INV_NUM: proformaInvoice.CUST_PI_NUM || "NA",
            PO_ID: proformaInvoice.CUST_PO_NUM || "0",
            PI_FILE: proformaInvoice.MM_FILE_NAME,
            TOTAL_CTNS: proformaInvoice.TOATL_CTN || "0",
            TOTAL_AMOUNT: `${proformaInvoice.INVOICE_CURRENCY_SYMBOL}${
              proformaInvoice.TOTAL_AMOUNT || "0"
            }`,

            CREATED_ON:
              convertUnixTimestampToDate(proformaInvoice.CREATED_ON) || "0",

            ORDER_STATUS: proformaInvoice.ORDER_STATUS,

            ACTION: (
              <Dropdown className="table_more" style={{ border: "none" }}>
                <Dropdown.Toggle
                  id="dropdown-basic"
                  style={{
                    backgroundColor: "white",
                    color: "#5D6B82",
                    fontSize: "1rem",
                    fontWeight: "400",
                    lineHeight: "1.5rem",
                    border: "none",
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="4"
                    height="18"
                    viewBox="0 0 4 18"
                    fill="none"
                  >
                    <path
                      d="M2 0C1.175 0 0.5 0.675 0.5 1.5C0.5 2.325 1.175 3 2 3C2.825 3 3.5 2.325 3.5 1.5C3.5 0.675 2.825 0 2 0ZM2 15C1.175 15 0.5 15.675 0.5 16.5C0.5 17.325 1.175 18 2 18C2.825 18 3.5 17.325 3.5 16.5C3.5 15.675 2.825 15 2 15ZM2 7.5C1.175 7.5 0.5 8.175 0.5 9C0.5 9.825 1.175 10.5 2 10.5C2.825 10.5 3.5 9.825 3.5 9C3.5 8.175 2.825 7.5 2 7.5Z"
                      fill="#5D6B82"
                    />
                  </svg>
                </Dropdown.Toggle>
              </Dropdown>
            ),
            // IS_SUPP_FAV: createFavIcon(proformaInvoice.IS_SUPP_FAV, (e) =>
            //   handleFav(e, proformaInvoice.CLIENT_SUPPLIER_ID)
            // ),
          }));
          settotal(response.XscData.PI_COUNT);
          setRows(mappedRows); // Set rows after mapping
          // setPageSize(Math.min(10, cartoon.length));
        } else {
          console.error("Suppliers data is not an array or is missing");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchProformaInvoices();
  }, [searchQuery, page, pageSize]);

  let navigate = useNavigate();

  const handleViewPI = () => {
    navigate(`/customers/view-pi`);
  };

  return (
    <div>
      <div className="container">
        <div>
          <div id="po_heading">Proforma Invoice</div>
          <div
            className="d-flex justify-content-between"
            id="supp_filters"
            style={{ marginTop: "20px" }}
          >
            <div className="d-flex">
              <div>
                <SearchBar onSearch={handleSearch} />
              </div>
              <div
                onClick={() => setModalShow(true)}
                style={{ cursor: "pointer" }}
              >
                <Filter />
              </div>
              <PIFilter show={modalShow} onHide={() => setModalShow(false)} />
            </div>
            <a href="/customers/create-pi">
              <div id="create_po_button">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M11 11V5H13V11H19V13H13V19H11V13H5V11H11Z"
                    fill="#F7F7F7"
                  />
                </svg>
                Create PI
              </div>
            </a>
          </div>
        </div>
        <div
          id="po_table"
          style={{
            height: 600,
            width: "100%",
            marginBottom: "80px",
            position: "relative",
            overflow: "hidden",
          }}
        >
          <DataGrid
            localeText={{
              noRowsLabel: (
                <div className="no_rows_css">
                  <img src={nodataimage} alt="" />
                  <div id="no_data_found_text">
                    You don’t have Proforma Invoices for this customer yet
                  </div>

                  <div
                    id="create_po_button"
                    onClick={() => navigate("/customers/create-pi")}
                    style={{ marginTop: "28px" }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M11 11V5H13V11H19V13H13V19H11V13H5V11H11Z"
                        fill="#F7F7F7"
                      />
                    </svg>
                    Create PI
                  </div>
                </div>
              ),
            }}
            rows={rows}
            columns={columns}
            pageSize={pageSize}
            rowCount={total}
            rowsPerPageOptions={[10, 20, 50]}
            page={page}
            pagination
            paginationMode="server"
            onPageSizeChange={handlepagesizechange}
            onPageChange={handlepagechange}
          />
        </div>
        {optionShow && (
          <div
            className="options-dropdown ellipsisOption"
            ref={ellipsisRef}
            style={{
              top: optionPosition.top,
              left: optionPosition.left,
            }}
          >
            {currentStatus === "Pending Approval" && (
              <div className="d-flex flex-column align-items-start gap-2">
                <a
                  id="dropdown_options"
                  href="/customers/modify-pi"
                  style={{
                    textDecoration: "none",
                    color: "#535260",
                    cursor: "pointer",
                  }}
                >
                  Modify PI
                </a>
                <div
                  id="dropdown_options"
                  // href="/customers/view-pi"
                  onClick={handleViewPI}
                  style={{
                    textDecoration: "none",
                    color: "#535260",
                    cursor: "pointer",
                  }}
                >
                  View PI
                </div>
                <div
                  id="dropdown_options"
                  onClick={() => {
                    handleStatusChange(selectedId, 1);
                    setOptionShow(false);
                  }}
                  style={{
                    textDecoration: "none",
                    color: "#535260",
                    cursor: "pointer",
                  }}
                >
                  Approve
                </div>
                <div
                  id="dropdown_options"
                  onClick={() => {
                    handleStatusChange(selectedId, 0);
                    setOptionShow(false);
                  }}
                  style={{
                    textDecoration: "none",
                    color: "#535260",
                    cursor: "pointer",
                  }}
                >
                  Reject
                </div>
              </div>
            )}
            {currentStatus === "Approved" && (
              <div className="d-flex flex-column align-items-start gap-2">
                <div
                  id="dropdown_options"
                  onClick={handleViewPI}
                  style={{
                    textDecoration: "none",
                    color: "#535260",
                    cursor: "pointer",
                    cursor: "pointer",
                  }}
                >
                  View PI
                </div>
                <a
                  id="dropdown_options"
                  // href="/events/edit-event"
                  style={{
                    textDecoration: "none",
                    color: "#535260",
                    cursor: "pointer",
                  }}
                  onClick={handlePIExcel}
                  className="d-flex flex-row align-items-center gap-2"
                >
                  <div>
                    <img src={excelDownload} />
                  </div>
                  Download Excel
                </a>
                <a
                  id="dropdown_options"
                  // href="/events/edit-event"
                  style={{
                    textDecoration: "none",
                    color: "#535260",
                    cursor: "pointer",
                  }}
                  onClick={handlePIPDF}
                  className="d-flex flex-row align-items-center gap-2"
                >
                  <div>
                    <img src={pdfDownload} />
                  </div>
                  Download Pdf
                </a>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
