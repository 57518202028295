import React from "react";
import { Modal } from "react-bootstrap";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { makeApiCall } from "../../../StaticComponents/API";
import axios from "axios";
const DeleteImage = (props) => {
  let [searchParams] = useSearchParams();
  let token = localStorage.getItem("token");
  console.log(props.ProdID, "productid");
  let SUPPLIER_ID = localStorage.getItem("CLIENT_SUPPLIER_ID");
  const PRODUCT_ID = props.ProdID;
  const navigate = useNavigate("");


  const deleteProduct = () => {
    const payloadimage = {
        PRODUCT_MM_ID: props.prodMMID,
      };
    makeApiCall({
      mod: "Products",
      action: "remove-product-media",
      payload: payloadimage,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        console.log(res, "deleted");
        if (res.XscStatus === 1) {
          toast.success("Product Image Deleted Successfully!");
          props.onHide();
          setTimeout(()=>{
            window.location.reload();
          },400)
          
         // localStorage.setItem("activeTab", "Products");
        //  navigate("/suppliers/supplier-info");
        } else {
          toast.error("Failed to delete product. Please try again.");
          props.onHide()
        }
      })
      .catch((error) => {
        console.error("Error deleting product:", error);
        toast.error("An error occurred while deleting the product.");
      });
  };


  return (
    <div>
      {" "}
      <Modal {...props}>
        <div>
          <div id="delete_Supplier">Delete Image</div>
          <div id="delete_supplier_content">
            Are you sure you want to delete this Particular Image? <br />
            It cannot be undone.
          </div>
          <div id="delete_footer">
            <div id="delete_cancel" onClick={() => props.onHide()}>
              Cancel
            </div>
            <div id="delete_submit" onClick={deleteProduct}>
              Delete Permanently
            </div>
          </div>
        </div>
      </Modal>
      <ToastContainer
        position="bottom-center"
        style={{ width: "max-content" }}
      />
    </div>
  );
};

export default DeleteImage;
