import React from "react";
import "../../Styles/TableDetails.css";

export default function ProductImageDetails({prodInfo}) {
  const getValueOrZero = (value) => (value !== null && value !== undefined ? value : '0');

  const unitMapping = {
    MTR: "m",
    CM: "cm",
    IN: "in",
    FEET: "ft",
    MM: "mm",
    GM: "g",
    KG: "kg",
    LBS: "lbs",
    CB_CM: "cm³",
    CB_MTR: "m³",
    CB_FEET: "ft³",
    CB_MM: "mm³",
    CB_IN: "in³",
  };

  const convertUnit = (unit) => {
    return unitMapping[unit] || unit;
  };
  
	return (
		<>
			<div style={{paddingLeft:"0px",width:"", paddingRight:""}}> 
      <div
        className="d-flex"
        style={{
          flexDirection: "row",
          marginTop: "44px",
          gap: "20px",
        }}
        
      >
        <div id="line"></div>
        <h2 id="productImgDet">Product Images</h2>
      </div>

      <div style={{ width: "100%", marginBottom: "80px", }}>
        <table style={{width:"100%",paddingLeft:"72px",marginRight:"72px"}} >
        <tr>
            <th>
              <span id="key">Product Description</span>
            </th>
            <td>
              <span id="value">{`${getValueOrZero(prodInfo.product_details.DESCRIPTION)}`}</span>
            </td>
          </tr>
          <tr>
            <th>
              <span id="key">Sup.Product ID</span>
            </th>
            <td>
              <span id="value">{`${getValueOrZero(prodInfo.product_details.SUPP_PROD_NUM)}`}</span>
            </td>
          </tr>
          <tr>
            <th>
              <span id="key">Mnx.Product ID</span>
            </th>
            <td>
              <span id="value">{`${getValueOrZero(prodInfo.product_details.CLIENT_PROD_NUM)}`}</span>
            </td>
          </tr>
          <tr>
            <th>
              <span id="key">Sup.Product Barcode</span>
            </th>
            <td>
              <span id="value">{`${getValueOrZero(prodInfo.product_details.SUPP_BAR_CODE)}`}</span>
            </td>
          </tr>
          <tr>
            <th>
              <span id="key">Mnx.Product Barcode</span>
            </th>
            <td>
              <span id="value">{`${getValueOrZero(prodInfo.product_details.CLIENT_BAR_CODE)}`}</span>
            </td>
          </tr>
          <tr>
            <th>
              <span id="key">Product Price Per Piece</span>
            </th>
            <td>
              <span id="value">{`${getValueOrZero(prodInfo.product_details.PRICE)}`}</span>
            </td>
          </tr>
          <tr>
            <th>
              <span id="key">Pieces Per CTN</span>
            </th>
            <td>
              <span id="value">{`${getValueOrZero(prodInfo.product_details.QTY_PER_CTN)}`}</span>
            </td>
          </tr>
          <tr>
            <th>
              <span id="key">Packaging</span>
            </th>
            <td>
              <span id="value">{`${getValueOrZero(prodInfo.product_details.PACKING)}`}</span>
            </td>
          </tr>
          <tr>
              <th>
                <span id="key">Length</span>
              </th>
              <td>
                <span id="value">{`${getValueOrZero(
                  prodInfo.product_details.PROD_LENGTH
                )} ${convertUnit(
                  prodInfo.product_details.PROD_SIZE_UNIT
                )}`}</span>
              </td>
            </tr>
            <tr>
              <th>
                <span id="key">Width</span>
              </th>
              <td>
                <span id="value">{`${getValueOrZero(
                  prodInfo.product_details.PROD_WIDTH
                )} ${convertUnit(
                  prodInfo.product_details.PROD_SIZE_UNIT
                )}`}</span>
              </td>
            </tr>

            <tr>
              <th>
                <span id="key">Height</span>
              </th>
              <td>
                <span id="value">{`${getValueOrZero(
                  prodInfo.product_details.PROD_HEIGHT
                )} ${convertUnit(
                  prodInfo.product_details.PROD_SIZE_UNIT
                )}`}</span>
              </td>
            </tr>
            <tr>
              <th>
                <span id="key">Weight</span>
              </th>
              <td>
                <span id="value">{`${getValueOrZero(
                  prodInfo.product_details.PROD_WT
                )} ${convertUnit(
                  prodInfo.product_details.PROD_WT_UNIT
                )}`}</span>
              </td>
            </tr>
            <tr>
              <th>
                <span id="key">Volume</span>
              </th>
              <td>
                <span id="value">{`${getValueOrZero(
                  prodInfo.product_details.PROD_VOLUME
                )} ${convertUnit(
                  prodInfo.product_details.PROD_VOLUME_UNIT
                )}`}</span>
              </td>
            </tr>
       
          <tr>
            <th>
              <span id="key">Product Color</span>
            </th>
            <td>
              <span id="value">{`${getValueOrZero(prodInfo.product_details.COLOUR)}`}</span>
            </td>
          </tr>
          <tr>
            <th>
              <span id="key">Product Material</span>
            </th>
            <td>
              <span id="value">{`${getValueOrZero(prodInfo.product_details.MATERIAL)}`}</span>
            </td>
          </tr>
        </table>
      </div>
      </div>
		</>
	);
}
