import React, { useEffect, useState } from "react";
import "../../Styles/SupplierInfo.css";
import SupplierHeader from "./SupplierHeader";
import { useSearchParams } from "react-router-dom";
import { makeApiCall } from "../../StaticComponents/API";
import default_supp from "../../assets/default_supp.png";

const SupplierInfo = () => {
  let [searchParams] = useSearchParams();
  let token = localStorage.getItem("token");
  const CLIENT_SUPPLIER_ID = localStorage.getItem("CLIENT_SUPPLIER_ID");
  const [suppInfo, setSuppInfo] = useState({
    info: "",
    totalProducts: "",
    closedOrders: "",
    outstandingPayments: "",
    transaction: "",
  });
  useEffect(() => {
    const payload = {
      CLIENT_SUPPLIER_ID: CLIENT_SUPPLIER_ID,
    };
    makeApiCall({
      mod: "Supplier",
      action: "get-supplier-detail",
      payload: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((res) => {
      console.log(res, "supp-info");
      console.log(payload, "payload");
      setSuppInfo({
        info: res.XscData.INFO,
        totalProducts: res.XscData.ORDER_AND_PAYMENT_DETAILS.TOTAL_PRODUCT,
        closedOrders: res.XscData.ORDER_AND_PAYMENT_DETAILS.TOTAL_CLOSED_ORDERS,
        outstandingPayments:
          res.XscData.ORDER_AND_PAYMENT_DETAILS.OUTSTANDING_PAYMENT,
        transaction: res.XscData.ORDER_AND_PAYMENT_DETAILS.TOTAL_TRANSACTIONS,
      });
    });
  }, []);
  const timestamp = suppInfo.info.CREATED_ON;
  const TimestampToDate = ({ timestamp }) => {
    const date = new Date(suppInfo.info.CREATED_ON * 1000);
    // Extract the day, month, and year
    const day = date.getDate();
    const month = date.toLocaleString("en-US", { month: "short" }); // Full month name
    const year = date.getFullYear();

    // Format the date as '19-July-2024'
    const formattedDate = `${day}-${month}-${year}`;
    // const formattedTime = date.toLocaleTimeString();

    return (
      <div>
        <p>{formattedDate}</p>
      </div>
    );
  };
  const API_URL = process.env.REACT_APP_API_URL;

  const handleImageErr = (e) => {
    e.target.src = default_supp;
  };
  return (
    <div id="supp_info_container">
      {/* <SupplierHeader /> */}
      <div className="container">
        <div id="supp_info_main_container">
          <div id="supp_info_content">
            <div id="supp_info_details" align="left">
              <div id="supp_info_head">Supplier Info</div>

              <div id="supp_logo">
                <img
                  src={API_URL + `media/${suppInfo.info.LOGO}`}
                  onError={handleImageErr}
                />
              </div>
              <div id="supp_details_infos">
                <div id="supp_details_info">
                  <div id="supp_info_label">Supplier Name</div>
                  <div id="supp_info_value">
                    {suppInfo.info.SUPP_NAME === null
                      ? "N/A"
                      : suppInfo.info.SUPP_NAME}
                  </div>
                </div>

                <div id="supp_details_info">
                  <div id="supp_info_label">Email Address</div>
                  <div id="supp_info_value">
                    {suppInfo.info.EMAIL === null ? "N/A" : suppInfo.info.EMAIL}
                  </div>
                </div>
                <div id="supp_details_info">
                  <div id="supp_info_label">Phone Number</div>

                  {/* +{suppInfo.info.COUNTRY_ID}  */}
                  {suppInfo.info.PHONE_NUMBER === null
                    ? "N/A"
                    : suppInfo.info.PHONE_NUMBER}
                </div>

                <div id="supp_details_info">
                  <div id="supp_info_label">Website</div>
                  <div id="supp_info_value">
                    {suppInfo.info.WEBSITE === null
                      ? "N/A"
                      : suppInfo.info.WEBSITE}
                  </div>
                </div>
                <div id="supp_details_info">
                  <div id="supp_info_label">Registration Number</div>
                  <div id="supp_info_value">
                    {suppInfo.info.REG_NUMBER === null
                      ? "N/A"
                      : suppInfo.info.REG_NUMBER}
                  </div>
                </div>

                <div id="supp_details_info">
                  <div id="supp_info_label">Address</div>
                  <div id="supp_info_value">
                    {suppInfo.info.ADDR_1 === null
                      ? "N/A"
                      : suppInfo.info.ADDR_1}
                  </div>
                </div>
                <div id="supp_details_info">
                  <div id="supp_info_label">Country</div>
                  <div id="supp_info_value">
                    {suppInfo.info.COUNTRY === null
                      ? "N/A"
                      : suppInfo.info.COUNTRY}
                  </div>
                </div>
                <div id="supp_details_info">
                  <div id="supp_info_label">Province/State</div>
                  <div id="supp_info_value">
                    {suppInfo.info.STATE === null ? "N/A" : suppInfo.info.STATE}
                  </div>
                </div>
                <div id="supp_details_info">
                  <div id="supp_info_label">City</div>
                  <div id="supp_info_value">
                    {suppInfo.info.CITY === null ? "N/A" : suppInfo.info.CITY}
                  </div>
                </div>
                <div id="supp_details_info">
                  <div id="supp_info_label">Zip/Postal</div>
                  <div id="supp_info_value">
                    {suppInfo.info.POSTAL_CODE === null
                      ? "N/A"
                      : suppInfo.info.POSTAL_CODE}
                  </div>
                </div>
                <div id="supp_details_info">
                  <div id="supp_info_label">TAX No</div>
                  <div id="supp_info_value">
                    {suppInfo.info.TAX_NUMBER === null
                      ? "N/A"
                      : suppInfo.info.TAX_NUMBER}
                  </div>
                </div>
                <div id="supp_details_info">
                  <div id="supp_info_label">FAX No</div>
                  <div id="supp_info_value">{suppInfo.info.FAX}</div>
                </div>

                <div id="supp_details_info" style={{ marginBottom: "32px" }}>
                  <div id="supp_info_label">Created-On</div>
                  <div id="supp_info_value">
                    {" "}
                    <TimestampToDate timestamp={timestamp} />
                  </div>
                </div>
              </div>
            </div>
            <div id="supp_info_summary" align="left">
              <div id="supp_info_inner_div" align="left">
                <div id="supp_inner_left">
                  <div id="supp_inner_left_line"></div>
                  <div id="supp_inner_left_text">
                    <div id="inner_text_num">{suppInfo.totalProducts || 0}</div>
                    <div id="inner_text_text">Total Product Listed</div>
                  </div>
                </div>

                <div id="supp_inner_left">
                  <div id="supp_inner_left_line"></div>
                  <div id="supp_inner_left_text">
                    <div id="inner_text_num">{suppInfo.closedOrders || 0}</div>
                    <div id="inner_text_text">Total Closed Orders</div>
                  </div>
                </div>
              </div>

              <div id="supp_info_inner_div" align="left" style={{ gap: 53 }}>
                <div id="supp_inner_left">
                  <div id="supp_inner_left_line"></div>
                  <div id="supp_inner_left_text">
                    <div id="inner_text_num">
                      {suppInfo.outstandingPayments === null
                        ? 0
                        : suppInfo.info.OPERATING_CURRENCY_SYMBOL + suppInfo.outstandingPayments}
                    </div>
                    <div id="inner_text_text">Outstanding Payments</div>
                  </div>
                </div>

                <div id="supp_inner_left">
                  <div id="supp_inner_left_line"></div>
                  <div id="supp_inner_left_text">
                    <div id="inner_text_num">
                      {suppInfo.transaction == null
                        ? 0
                        : suppInfo.info.OPERATING_CURRENCY_SYMBOL +
                          suppInfo.transaction}
                    </div>
                    <div id="inner_text_text">Total Transaction</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SupplierInfo;
