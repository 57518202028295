import axios from "axios";
import qs from "qs";
import { useNavigate } from "react-router-dom";

export const makeApiCall = async ({
  mod,
  action,
  payload,
  responseType,
  headers = {},
}) => {
  const reqData = qs.stringify({
    mod,
    action,
    payload: JSON.stringify(payload),
  });
  // const navigate = useNavigate();

  try {
    const response = await axios({
      method: "POST",

      url: process.env.REACT_APP_API_URL,
      // url:"http://192.168.1.105:5000/",
      // url: "http://192.168.1.119:5000",

      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        ...headers,
      },
      maxBodyLength: Infinity,
      data: reqData,
      responseType: responseType,
    });

    console.log("API Call Response:", response);
    if (response.data.XscMessage === "Invalid or Expired Token") {
      // Handle token expiration or invalid token
      console.log("Token expired or invalid. Redirecting to login.");
      localStorage.removeItem("token"); // Remove token from storage
      // navigate("/"); // Navigate to login page
      return null; // Stop further execution
    }
    return response.data;
  } catch (error) {
    console.error("API Call failed:", error);
    throw error;
  }
};
