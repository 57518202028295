import React, { useEffect, useState } from "react";
import OrdersHeader from "./OrdersHeader";
import GlobalOrders from "./GlobalOrders";

const GlobalOrdersDashboardLayout = () => {
  const [activeTab, setActiveTab] = useState(
    localStorage.getItem("activeTab" ) === "" 
      ? "CustomersOrders"
      : localStorage.getItem("activeTab")
  );
  useEffect(() => {
    localStorage.setItem("activeTab", "");
  });

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div>
      <OrdersHeader activeTab={activeTab} onTabChange={handleTabChange} />
      <GlobalOrders activeTab={activeTab} onTabChange={handleTabChange} />
    </div>
  );
};

export default GlobalOrdersDashboardLayout;
