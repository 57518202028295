import React, { useEffect, useRef, useState } from "react";
import SearchBar from "../../../StaticComponents/SearchBar";
import Filter from "../../../StaticComponents/Filter";
import "../../../Styles/PurchaseOrder.css";
import { DataGrid } from "@mui/x-data-grid";
import { Dropdown, Toast } from "react-bootstrap";
import ShippingFilter from "./ShippingFilter.js";
import excelDownload from "../../../assets/excelDownload.png";
import pdfDownload from "../../../assets/pdfDownload.png";
import { makeApiCall } from "../../../StaticComponents/API.js";
import nodataimage from "../../../assets/no-data-file.png";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
export default function Shipping() {
  const [modalShow, setModalShow] = useState(false);

  const [pageSize, setPageSize] = React.useState(10);
  const [loading, setLoading] = useState(false);

  const [optionShow, setOptionShow] = useState(false);
  const [optionPosition, setOptionPosition] = useState({ top: 0, left: 0 });
  const [currentStatus, setCurrentStatus] = useState("");
  const ellipsisRef = useRef(null);
  const [page, setpage] = useState(0);
  const [total, settotal] = React.useState(0);
  const [selectedId, setSelectedId] = useState(null); // New state to track selected row's ID
  const [searchQuery, setSearchQuery] = useState("");
  let navigate = useNavigate();

  let token = localStorage.getItem("token");
  const API_URL = process.env.REACT_APP_API_URL;
  const APP_CLIENT_ID = localStorage.getItem("CLIENT_ID");
  const CLIENT_CUST_ID = localStorage.getItem("CLIENT_CUST_ID");

  const handleEllipsisClick = (e, orderStatusValue, id, shID) => {
    if (orderStatusValue === 1) {
      setCurrentStatus("Approved");
    } else if (orderStatusValue === null) {
      setCurrentStatus("Pending Approval");
    } else if (orderStatusValue === 0) {
      setCurrentStatus("Rejected");
    }

    const rect = e.currentTarget.getBoundingClientRect();
    setOptionPosition({
      top: rect.bottom + window.scrollY,
      left: rect.left + window.scrollX,
    });
    setOptionShow(true);
    setSelectedId(id);
    localStorage.setItem("SHIPMENT_ID", id);
    localStorage.setItem("SHIPMENT_NUM", shID);
  };

  const handleClickOutside = (event) => {
    if (ellipsisRef.current && !ellipsisRef.current.contains(event.target)) {
      setOptionShow(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSNHPDF = () => {
    const payload = {
      CLIENT_CUST_ID: CLIENT_CUST_ID,
      SHIPMENT_ID: selectedId,
      APP_CLIENT_ID: APP_CLIENT_ID,
    };
    makeApiCall({
      mod: "Shipping",
      action: "packaging_pdf",
      payload: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => {
      console.log("apyload of s&h pdf", payload);
      console.log("s&h PDF SUCCESS ", response);
      if (response.XscStatus === 1) {
        const url = `${API_URL}/${response.XscData.URL}`;
        fetch(url)
          .then((response) => response.blob())
          .then((blob) => {
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = "shipping_invoice.pdf"; // Set the filename here
            link.click();
          });
      }
    });
    setOptionShow(false);
  };

  const handleSNHExcel = () => {
    const payload = {
      CLIENT_CUST_ID: CLIENT_CUST_ID,
      SHIPMENT_ID: selectedId,
      APP_CLIENT_ID: APP_CLIENT_ID,
    };
    makeApiCall({
      mod: "Shipping",
      action: "packaging_excel",
      payload: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => {
      console.log("payload of s&h excel", payload);
      console.log("S & H excel SUCCESS ", response);
      if (response.XscStatus === 1) {
        const url = `${API_URL}/${response.XscData.URL}`;
        fetch(url)
          .then((response) => response.blob())
          .then((blob) => {
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = "shipping_invoice.xlsx";
            link.click();
          });
      }
    });
    setOptionShow(false);
  }

  const handleSearch = (query) => {
    setSearchQuery(query);
  };

  const payload = {
    CLIENT_CUST_ID: localStorage.getItem("CLIENT_CUST_ID"),
    SEARCH_KEYWORD: searchQuery,
    CURRENT_PAGE: page + 1,
  };
  const handleApprove = () => {
    const payload = {
      SHIPMENT_ID: localStorage.getItem("SHIPMENT_ID"),
      APPROVAL_STATUS: 1,
    };
    makeApiCall({
      mod: "Shipping",
      action: "approve-reject-shipment",
      payload: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => {
      console.log("PAYLOAD for Approve", payload);
      console.log("RESPONSE FOR APPROVE SH", response);
      if (response.XscStatus === 1) {
        toast.success(response.XscMessage, {
          position: "bottom-center",
        });
      } else {
        toast.error("Error in approving invoice", {
          position: "bottom-center",
        });
      }
    });
  };
  const handleReject = () => {
    const payload = {
      SHIPMENT_ID: localStorage.getItem("SHIPMENT_ID"),
      APPROVAL_STATUS: 0,
    };
    makeApiCall({
      mod: "Shipping",
      action: "approve-reject-shipment",
      payload: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => {
      console.log("PAYLOAD for reject", payload);
      console.log("RESPONSE FOR APPROVE SH", response);
      if (response.XscStatus === 1) {
        toast.success(response.XscMessage, {
          position: "bottom-center",
        });
      } else {
        toast.error("Error in rejecting invoice", {
          position: "bottom-center",
        });
      }
    });
  };

  useEffect(() => {
    makeApiCall({
      mod: "Shipping",
      action: "list_customer_shipping",
      payload: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        console.log("request", payload);
        console.log("Shipping History Fetched  Sucessfully", response);
        const shipping = response.XscData.CUST_SHIP_LIST;

        if (Array.isArray(shipping)) {
          const mappedRows = shipping.map((sH) => ({
            id: sH.SHIPMENT_ID,
            shID: sH.SHIPMENT_NUM,
            TOTAL_INVOICE: sH.NO_OF_INVOICE || "0",
            TOTAL_CTN: sH.TOTAL_CTN || "0",
            TOTAL_QUANTITY: sH.TOTAL_QTY || "0",
            TOTAL_CBM: sH.TOTAL_CBM || "0",
            TOTAL_NW: sH.TOTAL_NW || "0",
            TOTAL_GW: sH.TOTAL_GW || "0",
            STATUS: sH.S_AND_H_STATUS,

            ACTION: (
              <Dropdown className="table_more" style={{ border: "none" }}>
                <Dropdown.Toggle
                  id="dropdown-basic"
                  style={{
                    backgroundColor: "white",
                    color: "#5D6B82",
                    fontSize: "1rem",
                    fontWeight: "400",
                    lineHeight: "1.5rem",
                    border: "none",
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="4"
                    height="18"
                    viewBox="0 0 4 18"
                    fill="none"
                  >
                    <path
                      d="M2 0C1.175 0 0.5 0.675 0.5 1.5C0.5 2.325 1.175 3 2 3C2.825 3 3.5 2.325 3.5 1.5C3.5 0.675 2.825 0 2 0ZM2 15C1.175 15 0.5 15.675 0.5 16.5C0.5 17.325 1.175 18 2 18C2.825 18 3.5 17.325 3.5 16.5C3.5 15.675 2.825 15 2 15ZM2 7.5C1.175 7.5 0.5 8.175 0.5 9C0.5 9.825 1.175 10.5 2 10.5C2.825 10.5 3.5 9.825 3.5 9C3.5 8.175 2.825 7.5 2 7.5Z"
                      fill="#5D6B82"
                    />
                  </svg>
                </Dropdown.Toggle>
              </Dropdown>
            ),
            // IS_SUPP_FAV: createFavIcon(proformaInvoice.IS_SUPP_FAV, (e) =>
            //   handleFav(e, proformaInvoice.CLIENT_SUPPLIER_ID)
            // ),
          }));
          settotal(response.XscData.SHIPPING_COUNT);
          setRows(mappedRows); // Set rows after mapping
          // setPageSize(Math.min(10, cartoon.length));
        } else {
          console.error("Suppliers data is not an array or is missing");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [searchQuery, page, pageSize]);

  const columns = [
    {
      field: "shID",
      headerName: "S&H Id",
      width: 102,
      cellClassName: "sticky-column",
    },
    {
      field: "TOTAL_INVOICE",
      headerName: "No. of invoice",
      width: 152,
      editable: false,
      align: "left",
      renderCell: (params) => params.value,
    },
    {
      field: "TOTAL_CTN",
      headerName: "Total CTN",
      width: 125,
      editable: false,
      align: "left",
      renderCell: (params) => params.value,
    },
    {
      field: "TOTAL_QUANTITY",
      headerName: "Total Qty",
      width: 119,
      editable: false,
      align: "left",
      renderCell: (params) => params.value,
    },
    {
      field: "TOTAL_CBM",
      headerName: "Total CBM",
      width: 127,
      editable: false,
      align: "left",
    },
    {
      field: "TOTAL_NW",
      headerName: "Total N.W",
      width: 124,
      editable: false,
      align: "left",
    },
    {
      field: "TOTAL_GW",
      headerName: "Total G.W",
      width: 124,
      editable: false,
      align: "left",
    },
    {
      field: "STATUS",
      headerName: "S&H Status",
      width: 200,
      editable: false,
      align: "left",
      renderCell: (params) => {
        const statusValue = PAYMENT_STATUS_MAP[params.value]; // Get the mapped status text
        const className = STATUS_CLASS_MAP[statusValue] || ""; // Get the class based on the status

        return <div className={className}>{statusValue}</div>;
      },
    },
    {
      field: "ACTION",
      headerName: "Action",
      width: 101,
      editable: false,
      sortable: false,
      align: "center",
      renderCell: (params) => {
        let orderStatusValue = params.row.STATUS;
        let id = params.row.id;
        let shID = params.row.shID;
        console.log(orderStatusValue);
        if (orderStatusValue === 0) {
          return <div></div>;
        }
        return (
          <div
            style={{
              cursor: "pointer",
              width: "30px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            onClick={(e) => handleEllipsisClick(e, orderStatusValue, id, shID)} // Pass id to handleEllipsisClick
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M2 0C1.175 0 0.5 0.675 0.5 1.5C0.5 2.325 1.175 3 2 3C2.825 3 3.5 2.325 3.5 1.5C3.5 0.675 2.825 0 2 0ZM2 15C1.175 15 0.5 15.675 0.5 16.5C0.5 17.325 1.175 18 2 18C2.825 18 3.5 17.325 3.5 16.5C3.5 15.675 2.825 15 2 15ZM2 7.5C1.175 7.5 0.5 8.175 0.5 9C0.5 9.825 1.175 10.5 2 10.5C2.825 10.5 3.5 9.825 3.5 9C3.5 8.175 2.825 7.5 2 7.5Z"
                fill="#5D6B82"
              />
            </svg>
          </div>
        );
      },
    },
  ];

  const PAYMENT_STATUS_MAP = {
    1: "Approved",
    0: "Rejected",
    null: "Pending Approval",
  };

  const STATUS_CLASS_MAP = {
    "Pending Approval": "invoice_pending_approval_bg", // Class for pending approval
    Rejected: "invoice_reject_bg", // Class for rejected
    Approved: "invoice_completed_status_bg", // Class for completed
  };
  const initialRows = [];
  console.log(currentStatus);

  const [rows, setRows] = React.useState(initialRows);

  return (
    <div>
      <div className="container">
        <ToastContainer />
        <div>
          <div id="po_heading">Shipping</div>
          <div
            className="d-flex justify-content-between"
            id="supp_filters"
            style={{ marginTop: "20px" }}
          >
            <div className="d-flex">
              <div>
                <SearchBar onSearch={handleSearch} />
              </div>
              <div
                onClick={() => setModalShow(true)}
                style={{ cursor: "pointer" }}
              >
                <Filter />
              </div>
              <ShippingFilter
                show={modalShow}
                onHide={() => setModalShow(false)}
              />
            </div>
            <a href="/customers/create-shipping">
              <div id="create_po_button">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M11 11V5H13V11H19V13H13V19H11V13H5V11H11Z"
                    fill="#F7F7F7"
                  />
                </svg>
                Create S&H
              </div>
            </a>
          </div>
        </div>
        <div
          id="po_table"
          style={{
            height: 600,
            width: "100%",
            marginBottom: "80px",
            position: "relative",
            overflow: "hidden",
          }}
        >
          <DataGrid
            localeText={{
              noRowsLabel: (
                <div
                  className="no_rows_css"
                  style={{
                    pointerEvents: "auto",
                    position: "relative",
                    zIndex: 10,
                  }}
                >
                  <img src={nodataimage} alt="" />
                  <div id="no_data_found_text">
                    You don’t have S&H for this customer yet
                  </div>
                  <div
                    id="create_po_button"
                    onClick={(event) => {
                      event.stopPropagation(); // Prevent event bubbling
                      navigate("/customers/create-shipping");
                    }}
                    style={{ marginTop: "28px", cursor: "pointer" }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M11 11V5H13V11H19V13H13V19H11V13H5V11H11Z"
                        fill="#F7F7F7"
                      />
                    </svg>
                    Create S&H
                  </div>
                </div>
              ),
            }}
            style={{ position: "relative", overflow: "hidden" }}
            rows={rows}
            columns={columns}
            pageSize={pageSize}
            rowCount={total}
            page={page}
            pagination
            paginationMode="server"
            rowsPerPageOptions={[10, 20, 50]}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            onPageChange={(newPage) => setpage(newPage)}
            disableSelectionOnClick
          />
        </div>
        {optionShow &&
          (console.log("sudbj"),
          (
            <div
              ref={ellipsisRef}
              className="options-dropdown ellipsisOption"
              style={{
                top: optionPosition.top,
                left: optionPosition.left,
              }}
            >
              {currentStatus === "Pending Approval" &&
                (console.log(currentStatus),
                (
                  <div className="d-flex flex-column align-items-start gap-2">
                    <a
                      id="dropdown_options"
                      href="/customers/modify-shipping"
                      style={{
                        textDecoration: "none",
                        color: "#535260",
                      }}
                    >
                      Modify S&H
                    </a>
                    <a
                      id="dropdown_options"
                      href="/customers/view-shipping"
                      style={{
                        textDecoration: "none",
                        color: "#535260",
                      }}
                    >
                      View S&H
                    </a>
                    <a
                      id="dropdown_options"
                      onClick={handleApprove}
                      style={{
                        textDecoration: "none",
                        color: "#535260",
                      }}
                    >
                      Approve
                    </a>
                    <a
                      id="dropdown_options"
                      onClick={handleReject}
                      style={{
                        textDecoration: "none",
                        color: "#535260",
                      }}
                    >
                      Reject
                    </a>
                  </div>
                ))}
              {currentStatus === "Approved" &&
                (console.log(currentStatus),
                (
                  <div className="d-flex flex-column align-items-start gap-2">
                    <a
                      onClick={() =>
                        localStorage.setItem("SHIPPING_STATUS", currentStatus)
                      }
                      id="dropdown_options"
                      href="/customers/modify-shipping"
                      style={{
                        textDecoration: "none",
                        color: "#535260",
                        cursor: "pointer",
                      }}
                    >
                      Modify S&H
                    </a>
                    <a
                      id="dropdown_options"
                      href="/customers/view-shipping"
                      style={{
                        textDecoration: "none",
                        color: "#535260",
                        cursor: "pointer",
                      }}
                    >
                      View S&H
                    </a>
                    <a
                      id="dropdown_options"
                      // href="/events/edit-event"
                      style={{
                        textDecoration: "none",
                        color: "#535260",
                        width: 200,
                        cursor: "pointer",
                      }}
                      onClick={handleSNHExcel}
                      className="d-flex flex-row align-items-center gap-2"
                    >
                      <div>
                        <img src={excelDownload} />
                      </div>
                      Download Excel
                    </a>
                    <a
                      id="dropdown_options"
                      // href="/events/edit-event"
                      style={{
                        textDecoration: "none",
                        color: "#535260",
                        width: 200,
                        cursor: "pointer",
                      }}
                      onClick={handleSNHPDF}
                      className="d-flex flex-row align-items-center gap-2"
                    >
                      <div>
                        <img src={pdfDownload} />
                      </div>
                      Download Pdf
                    </a>
                  </div>
                ))}
            </div>
          ))}
      </div>
    </div>
  );
}
