import { DataGrid } from "@mui/x-data-grid";
import React from "react";

function ImportedData({ importData, isLoading }) {
  const columns = [
    {
      field: "SUPPLIER_NAME",
      headerName: "Supplier Name",
      width: 270,
      editable: false,
      align: "left",
      height: 80,
    },
    {
      field: "EMAIL",
      headerName: "Email",
      width: 270,
      editable: false,
      align: "left",
      height: 80,
    },
    {
      field: "CONTACT_NUMBER",
      headerName: "Phone Number",
      type: "number",
      width: 250,
      editable: false,
      align: "left",
      height: 80,
    },
    {
      field: "ADDRESS",
      headerName: "Address",
      width: 170,
      editable: false,
      align: "left",
      height: 80,
    },
    {
      field: "WEBSITE",
      headerName: "Website",
      width: 170,
      editable: false,
      align: "left",
      height: 80,
    },
  ];
  console.log(importData, "import");
  return (
    <div
      style={{
        height: 600,
        width: "100%",
        marginBottom: "80px",
      }}
    >
      <DataGrid
        rows={importData}
        columns={columns}
        pagination
        paginationMode="server"
        // rowCount={50}
        rowsPerPageOptions={
          // [10, 20, 50]
          importData.length < 50
            ? [10, 20, 50] // Default options
            : importData.length > 20
            ? [10, 20, importData.length] // Adjust if less than 50
            : importData.length > 10
            ? [10, importData.length] // Adjust if less than 20
            : [importData.length] // Show all rows if less than 10
        }
        // onPageChange={handlepagechange}
        // onPageSizeChange={handlepagesizechange}
        loading={isLoading}
        disableSelectionOnClick
        style={{ cursor: "pointer" }}
      />
    </div>
  );
}

export default ImportedData;
