import React, { useEffect, useState } from "react";

import Reports from "../../Pages/Reports";
import ReportsDashboard from "./ReportsDashboard";

export default function ReportsInfoHeader() {
  const [activeTab, setActiveTab] = useState(
    localStorage.getItem("activeTab") === ""
      ? "Customer"
      : localStorage.getItem("activeTab")
  );
  useEffect(() => {
    localStorage.setItem("activeTab", "");
  });
  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div style={{ backgroundColor: "var(--surfaceContainerLowest)" }}>
      <Reports activeTab={activeTab} onTabChange={handleTabChange} />
      <ReportsDashboard activeTab={activeTab} onTabChange={handleTabChange} />
    </div>
  );
}
