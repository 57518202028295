import React from "react";
import "../../Styles/SupplierInfo.css";
import { useNavigate } from "react-router-dom";

const SupplierHeader = ({ activeTab, onTabChange }) => {
  const SUPPLIER_NAME = localStorage.getItem("CLIENT_SUPPLIER_NAME");
  const SUPPLIER_NUM = localStorage.getItem("CLIENT_SUPPLIER_NUM");
  const navigate = useNavigate();

  const handleBack = () => {
    navigate("/suppliers")
    localStorage.setItem("orderTab", "");
    localStorage.setItem("activeTab", "")
  }
  return (
    <div>
      <div
        id="suppliers_topbar_bg"
        style={{ paddingLeft: "0", paddingRight: "0" }}
      >
        <div className="container d-flex justify-content-between align-items-center">
          <div id="header_name" className="d-flex ">
            <div>
              <div onClick={handleBack}> <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M7.82843 11.0009H20V13.0009H7.82843L13.1924 18.3648L11.7782 19.779L4 12.0009L11.7782 4.22266L13.1924 5.63687L7.82843 11.0009Z"
                  fill="var(--onSurface)"
                />
                <path
                  d="M7.82843 11.0009H20V13.0009H7.82843L13.1924 18.3648L11.7782 19.779L4 12.0009L11.7782 4.22266L13.1924 5.63687L7.82843 11.0009Z"
                  fill="black"
                  fill-opacity="0.2"
                />
                <path
                  d="M7.82843 11.0009H20V13.0009H7.82843L13.1924 18.3648L11.7782 19.779L4 12.0009L11.7782 4.22266L13.1924 5.63687L7.82843 11.0009Z"
                  fill="black"
                  fill-opacity="0.2"
                />
                <path
                  d="M7.82843 11.0009H20V13.0009H7.82843L13.1924 18.3648L11.7782 19.779L4 12.0009L11.7782 4.22266L13.1924 5.63687L7.82843 11.0009Z"
                  fill="black"
                  fill-opacity="0.2"
                />
                <path
                  d="M7.82843 11.0009H20V13.0009H7.82843L13.1924 18.3648L11.7782 19.779L4 12.0009L11.7782 4.22266L13.1924 5.63687L7.82843 11.0009Z"
                  fill="black"
                  fill-opacity="0.2"
                />
              </svg></div>
             
            </div>

            <span className="ps-2">
              {" "}
              {SUPPLIER_NAME}
              <div id="edit_supplier_id">{SUPPLIER_NUM}</div>
            </span>
          </div>
        </div>
      </div>
      <div id="supplier_menu_bg">
        <div className="container">
          <div id="supplier_menus">
            {" "}
            <div
              style={{
                color: activeTab === "Overview" && "var(--primaryContainer)",
                fontSize: activeTab === "Overview" && "14px",
                fontWeight: activeTab === "Overview" && "600",
              }}
              id="supplier_header_menu"
              onClick={() => onTabChange("Overview")}
            >
              Overview
              {activeTab === "Overview" && (
                <div
                  id="supplier_active_tab"
                  className="position-absolute  bottom-0 start-50 translate-middle-x"
                ></div>
              )}
            </div>
            <div
              style={{
                color: activeTab === "Products" && "var(--primaryContainer)",
                fontSize: activeTab === "Products" && "14px",
                fontWeight: activeTab === "Products" && "600",
              }}
              id="supplier_header_menu"
              onClick={() => onTabChange("Products")}
            >
              Products
              {activeTab === "Products" && (
                <div
                  id="supplier_active_tab"
                  className="position-absolute  bottom-0 start-50 translate-middle-x"
                ></div>
              )}
            </div>
            <div
              style={{
                color: activeTab === "Orders" && "var(--primaryContainer)",
                fontSize: activeTab === "Orders" && "14px",
                fontWeight: activeTab === "Orders" && "600",
              }}
              id="supplier_header_menu"
              onClick={() => onTabChange("Orders")}
            >
              Orders
              {activeTab === "Orders" && (
                <div
                  id="supplier_active_tab"
                  className="position-absolute  bottom-0 start-50 translate-middle-x"
                ></div>
              )}
            </div>
            <div
              style={{
                color: activeTab === "Report" && "var(--primaryContainer)",
                fontSize: activeTab === "Report" && "14px",
                fontWeight: activeTab === "Report" && "600",
              }}
              id="supplier_header_menu"
              onClick={() => onTabChange("Report")}
            >
              Report
              {activeTab === "Report" && (
                <div
                  id="supplier_active_tab"
                  className="position-absolute  bottom-0 start-50 translate-middle-x"
                ></div>
              )}
            </div>
            <div
              style={{
                color:
                  activeTab === "PriceHistory" && "var(--primaryContainer)",
                fontSize: activeTab === "PriceHistory" && "14px",
                fontWeight: activeTab === "PriceHistory" && "600",
              }}
              id="supplier_header_menu"
              onClick={() => onTabChange("PriceHistory")}
            >
              Price History
              {activeTab === "PriceHistory" && (
                <div
                  id="supplier_active_tab"
                  className="position-absolute  bottom-0 start-50 translate-middle-x"
                ></div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SupplierHeader;
