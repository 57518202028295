import React, { useEffect, useState, useRef } from "react";
import { DataGrid } from "@mui/x-data-grid";
import po_img from "../../../assets/po_img.png";
import { makeStyles } from "@material-ui/styles";
import "../../../Styles/UploadInvoice.css";
import TextInput from "../../Inputs/TextInput";
import TextInputWithAll from "../../Inputs/TextInputWithAll";
import DropDownInputWithoutLabel from "../../Inputs/DropDownInputWithoutLabel";
import DropDownInput from "../../Inputs/DropDownInput";
import { makeApiCall } from "../../../StaticComponents/API";
import CurrencyFormatter from "../../../StaticComponents/FormatNumber";
import FormatCurrency from "../../../StaticComponents/FormatCurrency";
import USDCurrency from "../../../StaticComponents/USDCurrency";
import { ToWords } from "to-words";
import axios from "axios";
import { Button } from "react-bootstrap";
import { CircleSpinner } from "react-spinners-kit";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles({
  // subheadingRow: {
  //   textalign: "left",
  //   fontWeight: "bold",
  //   minHeight: "32px !important",
  //   maxHeight: "32px !important",
  //   "& .MuiDataGrid-cell": {
  //     border: "none !important",
  //     lineHeight: "32px !important",
  //     minHeight: "32px !important",
  //     maxHeight: "32px !important",
  //   },
  // },

  // subheadingCell: {
  //   width: "100%",
  //   border: "0.5px solid var(--outlineVariant) !important",
  //   lineHeight: "32px !important",
  //   minHeight: "32px !important",
  //   maxHeight: "32px !important",
  // },

  padding: { padding: "0px !important" },
});
function UploadInvoice() {
  const CLIENT_SUPPLIER_ID = localStorage.getItem("CLIENT_SUPPLIER_ID");
  const APP_CLIENT_ID = localStorage.getItem("CLIENT_ID");
  const [optionShow, setOptionShow] = useState(false);
  const [optionPosition, setOptionPosition] = useState({ top: 0, left: 0 });
  const [commission, setCommission] = useState(0);
  const [transportType, setTransportType] = useState("");
  const [transportCost, setTransportCost] = useState(0);
  const [currencyFactory, setcurrencyFactory] = useState(0);
  const [orderNum, setorderNum] = useState("");
  const token = localStorage.getItem("token");
  const [suggestions, setsuggestions] = useState([]);
  const [PO_ID, setPO_ID] = useState("");
  const [purchaseOrder, setpurchaseOrder] = useState([]);
  const [containers, setcontainers] = useState([]);
  const [usd_value, setusd_value] = useState(0);
  const [commissionValue, setCommissionValue] = useState(0);
  const [currency, setcurrency] = useState([]);
  const [currencyID, setCurrencyID] = useState(null);
  const [selectedCurrency, setSelectedCurrency] = useState("");
  const [deliveryDate, setdeliveryDate] = useState("");
  const [shippingDate, setshippingDate] = useState("");
  const [vessel_Name, setvessel_Name] = useState("");
  const [paymentType, setpaymentType] = useState("");
  const [finalAmount, setfinalAmount] = useState(0);
  const [currencyLabel, setcurrencyLabel] = useState("");
  const [currencySymbol1,setcurrencySymbol1]=useState(null)
  const [currencyName1,setcurrencyName1]=useState("")
  const formatCurrency = (amount) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD", // You can adjust this to the correct currency
      minimumFractionDigits: 2,
    }).format(amount);
  };
  const navigate = useNavigate();
  const initialRows = [
    // {
    //   id: "1",
    //   MNX_ID: "F-1215",
    //   SUP_ITEM_ID: "S-1315",
    //   DESCRIPTION: "PIE DE AMIGO 10” X 12”...",
    //   TTL_QTY: "1440",
    //   RMB: "¥1.13",
    //   TTL_RMB: "¥2,500.00",
    // },
    // {
    //   id: "2",
    //   MNX_ID: "F-1216",
    //   SUP_ITEM_ID: "S-1316",
    //   DESCRIPTION: "PIE DE AMIGO 10” X 12”...",
    //   TTL_QTY: "1296",
    //   RMB: "¥1.31",
    //   TTL_RMB: "¥1,697.76",
    // },
  ];
  const [rows, setRows] = useState(initialRows);
  const [convert_rate, setconvert_rate] = useState(0);
  const currency_convertor = (e) => {
    const value = parseFloat(e.target.value);
    setcurrencyFactory(e.target.value);
    setconvert_rate(value);
    setusd_value(totalTTL_RMB * e.target.value);
  };

  useEffect(() => {
    console.log("iasjbiajsbidkjb");
    const updatedValue = usd_value + commissionValue + transportCost;
    setfinalAmount(updatedValue);
    console.log(finalAmount);
    console.log(transportCost);
    console.log(typeof transportCost);
  }, [usd_value, commissionValue, transportCost]);
  const addTransportCost = (e) => {
    const value = parseFloat(e.target.value);
    if (/^\d*\.?\d*$/.test(value)) {
      setTransportCost(value === "" ? 0 : parseFloat(value)); // Set to 0 if the input is empty
    } else {
      setTransportCost(0); // Reset to 0 if input is not valid
    }
  };
  function convertToUnixTimestamp(dateString) {
    const date = new Date(dateString);
    const unixTimestamp = Math.floor(date.getTime() / 1000);
    return unixTimestamp;
  }

  // Example usage
  const date = "2024-09-20"; // Example date string

  const towords = new ToWords();
  const [words, setwords] = useState("");
  useEffect(() => {
    setwords(towords.convert(finalAmount));
  }, [finalAmount]);

  const TypeOptions = [
   
    { value: "CASH", label: "CASH" },
    { value: "ONLINE", label: "ONLINE" },
    { value: "T/T", label: "T/T" },
    { value: "CARD", label: "CARD" },
    { value: "OTHERS", label: "OTHERS" },
  ];
  const handlePaymentChange = (e) => {
    const selectedValue = e.target.value === "" ? null : e.target.value;
    setpaymentType(selectedValue);
  };
  const [agentCommission, setagentCommission] = useState(0);
  const agent_Commission = (value) => {
    const floatvalue = parseFloat(value);
    setCommission(value);
    setagentCommission(floatvalue);
    setCommissionValue((value * usd_value) / 100);
  };

  const classes = useStyles();
  const handleOrderChange = (e) => {
    console.log(e.target.value);
    setorderNum(e.target.value);
    const payload = {
      SEARCH_KEYWORD: e.target.value,
      CLIENT_SUPPLIER_ID: CLIENT_SUPPLIER_ID,
    };
    makeApiCall({
      mod: "SupplierInvoice",
      action: "list_po_list",
      payload: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => {
      console.log("pauload", payload);
      console.log("response of po list", response);
      setsuggestions(response.XscData.PO_LIST);
    });
  };
  const getPO = (poid) => {
    const payload = {
      SUPP_PO_ID: poid,
      CLIENT_SUPP_ID: CLIENT_SUPPLIER_ID,
    };
    makeApiCall({
      mod: "SupplierPO",
      action: "view_supp_po",
      payload: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => {
      console.log("payload", payload);
      console.log("response", response);
      setpurchaseOrder(response.XscData.PO_DETAILS);
      const PurchaseOrder = response.XscData.PO_DETAILS;
      setcurrencySymbol1(response.XscData.PO_DETAILS[0].CURRENCY_SYMBOL)
      setcurrencyName1(response.XscData.PO_DETAILS[0].OPERATING_CURRENCY_NAME)
      if (Array.isArray(PurchaseOrder) && PurchaseOrder.length > 0) {
        const mappedRows = PurchaseOrder.map((orders) => ({
          id: orders.PRODUCT_ID,
          MNX_ID: orders.CLIENT_ITEM_NUM,
          SUP_ITEM_ID: orders.SUPP_ITEM_NUM,
          DESCRIPTION: orders.DESCRIPTION,
          TTL_QTY: orders.TOTAL_QTY,
          RMB: orders.PRICE,
          CURRENCY_SYMBOL:orders.CURRENCY_SYMBOL
        
        }));
        setRows(mappedRows);
      }
    });
  };
  const calculatedRows = rows.map((row) => ({
    ...row,
    TTL_RMB: row.TTL_QTY * row.RMB,
  }));

  const totalTTL_RMB = calculatedRows.reduce((acc, row) => {
    return acc + (row.TTL_RMB || 0); // Add each TTL_RMB, handle undefined with || 0
  }, 0); // Initial value of the accumulator is 0

  useEffect(() => {
    const payload = { SEARCH_KEYWORD: "" };
    makeApiCall({
      mod: "Orders",
      action: "list_currency_unit",
      payload: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => {
      console.log("Currency convertor ", response);
      const currency = response.XscData;
      const mappedRows = currency.map((cur) => ({
        value: cur.CURRENCY_ID,
        label: cur.CURRENCY,
        symbol: cur.CURRENCY_SYMBOL,
      }));
      setcurrency(mappedRows);
      if (mappedRows.length > 0) {
        setSelectedCurrency(mappedRows[0].label); // Set default selection
        setCurrencyID(mappedRows[0].value); // Set default currency ID
        setcurrencyLabel(mappedRows[0].label);
        setcurrencySymbol(mappedRows[0].symbol);
      }
    });
  }, []);
  const [currencySymbol, setcurrencySymbol] = useState("");
  const handleCurrencyChange = (e) => {
    // setCurrencyID(e.target.value);
    // setcurrencyLabel(e)
    const selectedValue = e.target.value;
    setCurrencyID(selectedValue);
    const foundCurrency = currency.find((c) => c.value == selectedValue);
    console.log("SKELFHGVHGVHGV", foundCurrency);
    if (foundCurrency) {
      setSelectedCurrency(foundCurrency);
      setcurrencyLabel(foundCurrency.label);
      setcurrencySymbol(foundCurrency.symbol);
      setCurrencyID(foundCurrency.value);
    }
  };

  const [file, setFile] = useState();
  const [fileName, setFileName] = useState("");
  const idRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const fileInputRef = useRef(null);
  const uploadTimeoutRef = useRef(null);
  const [fileToUpload, setfileToUpload] = useState(null);

  const handleChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setIsLoading(true);
      console.log("Selected file:", e.target.files[0]);
      console.log("Selected file:", e.target.files[0].lastModifiedDate);
      setFileName(e.target.files[0].name);

      uploadTimeoutRef.current = setTimeout(() => {
        setFile(URL.createObjectURL(e.target.files[0]));
        setFileName(e.target.files[0].name);
        setfileToUpload(e.target.files[0]);
        setIsLoading(false);
      }, 2000);
    } else {
      console.error("No file selected");
    }
  };
  const handleOpen = () => {
    // Assuming fileToUpload is the file object you want to display
    const url = URL.createObjectURL(fileToUpload); // Create a URL for the file
    if (url) {
      window.open(url, '_blank'); // Open the file URL in a new tab
    }
  };
  
  const handleImportCancel = () => {
    setFile(null);
    setFileName("");
    setIsLoading(false);
    setfileToUpload(null);
    if (uploadTimeoutRef.current) {
      clearTimeout(uploadTimeoutRef.current);
      uploadTimeoutRef.current = null;
    }

    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }

    console.log("File upload canceled");
  };
  const columns = [
    {
      field: "MNX_ID",
      headerName: "Mnx. Item ID",
      width: 190,
      align: "left",
    },
    {
      field: "SUP_ITEM_ID",
      headerName: "Sup. Item ID",
      width: 190,
      align: "left",
    },
    {
      field: "DESCRIPTION",
      headerName: "Description Goods",
      width: 230,
      align: "left",
    },
    {
      field: "TTL_QTY",
      headerName: "TTL QTY",
      width: 170,
      align: "left",
      renderCell: (params) => {
        return params.row.id_desc === "Total" ? (
          <div className={classes.subheadingCell}>{params.value}</div>
        ) : (
          params.value
        );
      },
    },
    {
      field: "RMB",
      headerName: "RMB",
      width: 140,
      align: "left",
      cellClassName: "po_input",
      renderCell: (params) => {
        return <div className="d-flex">{params.row.CURRENCY_SYMBOL}<USDCurrency amount={params.value} /></div>;
      },
    },
    {
      field: "TTL_RMB",
      headerName: "Total RMB",
      width: 140,
      align: "left",
      renderCell: (params) => {
        return params.row.id_desc === "Total" ? (
          <div className={classes.subheadingCell}>{params.value}</div>
        ) : (
          <div className="d-flex">{params.row.CURRENCY_SYMBOL}<USDCurrency amount={params.value} /></div>
        );
      },
    },
  ];
  useEffect(() => {
    const payload = {
      APP_CLIENT_ID: 1,
    };
    makeApiCall({
      mod: "Shipping",
      action: "get-container-list",
      payload: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => {
      console.log("response of the container list", response);
      const lists = response.XscData.CONTAINER_DETAIL;
      if (Array.isArray(lists) && lists.length > 0) {
        const mappedRows = lists.map((list) => ({
          value: list.ID,
          label: list.ALIAS_NAME,
        }));
       
        setcontainers(
          mappedRows);
      }
    });
  }, []);
  const [selectedContainer, setselectedContainer] = useState("");
  const handleContainerchange = (e) => {
    setselectedContainer(e.target.value);
  };
  const payload = {
    SUPP_PO_ID: PO_ID,
    PO_NUM: orderNum,
    TOTAL_GOODS_AMOUNT: totalTTL_RMB,
    INVOICE_CURRENCY: currencyID,
    INVOICE_CURRENCY_RATE: convert_rate,
    AGENTS_COMMISION_PERCENTAGE: agentCommission,
    INLAND_TRANS_TYPE: selectedContainer,
    SHIPPING_COST: transportCost,
    STATUS: 1,
    DESCRIPTION: "GOODS",
    DELIVERY_DATE: deliveryDate,
    SHIPPING_DATE: shippingDate,
    VESSEL_NAME: vessel_Name,
    PAYMENT_TYPE: paymentType,
    PAYMENT_STATUS: 1,
    APP_CLIENT_ID: 1,
  };
  const handleInvoiceUpload = () => {
   
    if (!PO_ID) {
      toast.error("Please Enter Order Number", {
        position: "bottom-center",
      });
      return; 
    }
    if (!orderNum) {
      toast.error("Please Enter Order Number", {
        position: "bottom-center",
      });
      return; 
    }
    if (!totalTTL_RMB ) {
      toast.error("Please Enter Total Amount", {
        position: "bottom-center",
      });
      return; 
    }
    if (!currencyID) {
      toast.error("Please Select Invoice Currency", {
        position: "bottom-center",
      });
      return; 
    }
    if(!convert_rate){
      toast.error("Please Enter Convert & Ex.Rate",{
        position:"bottom-center"
      })
      return;
    }
    // if(!agentCommission){
    //   toast.error("Please Enter Agent Commission",{
    //     position:"bottom-center"
    //   })
    //   return;
    // }
    if(!selectedContainer){
      toast.error("Please select a Transportation Type",{
        position:"bottom-center"
      })
      return;
    }
    if(!transportCost){
      toast.error("Please enter a Transportation Cost",{
        position:"bottom-center"
      })
      return;
    }
    if(!deliveryDate){
      toast.error("Please enter a Delivery Date",{
        position:"bottom-center"
      })
      return;
    }
    if(!shippingDate){
      toast.error("Please enter a Sailing Date",{
        position:"bottom-center"
      })
      return;
    }
    if(!vessel_Name){
      toast.error("Please enter a Vessel Name",{
        position:"bottom-center"
      })
      return;
    }
    if(!paymentType){
      toast.error("Please Select a payment type",{
        position:"bottom-center"
      })
      return;
    }
    if (fileToUpload === null) {
      toast.error("Please upload invoice file", {
        position: "bottom-center",
      });
      return
    }
   
    const payload = {
      SUPP_PO_ID: PO_ID,
      PO_NUM: orderNum,
      TOTAL_GOODS_AMOUNT: totalTTL_RMB,
      INVOICE_CURRENCY: currencyID,
      INVOICE_CURRENCY_RATE: convert_rate,
      AGENTS_COMMISION_PERCENTAGE: agentCommission,
      INLAND_TRANS_TYPE: selectedContainer,
      SHIPPING_COST: transportCost,
      STATUS: 1,
      DELIVERY_DATE: deliveryDate,
      SHIPPING_DATE: shippingDate,
      VESSEL_NAME: vessel_Name,
      PAYMENT_TYPE: paymentType,
      APP_CLIENT_ID: APP_CLIENT_ID,
    };
    const formdata = new FormData();
    formdata.append("mod", "SupplierInvoice");
    formdata.append("action", "create_supp_invoice");
    formdata.append("payload", JSON.stringify(payload));
    formdata.append("file", fileToUpload);

    axios({
      method: "post",
      url: process.env.REACT_APP_API_URL,
      data: formdata,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        console.log("update invoice successfull", response);
        console.log("payload", payload);
        if (response.data.XscStatus === 1) {
          toast.success("Invoice Uploaded", {
            position: "bottom-center",
          });
          setTimeout(() => {
            navigate(`/suppliers/supplier-info?id=${CLIENT_SUPPLIER_ID}`);
          }, 1000);
        }
        if (response.data.XscStatus === 0) {
          if (fileToUpload === null) {
            toast.error(response.data.XscMessage, {
              position: "bottom-center",
            });
          }
          else{
            toast.error(response.data.XscMessage,{
              position:"bottom-center"
            })
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleActiveTab = () => {
    localStorage.setItem("activeTab", "Orders");
    localStorage.setItem("orderTab", "I");
  };
  console.log("PAYLOAD",payload)
  return (
    <>
      <div style={{ minHeight: "100vh", position: "relative" }} className="">
        <div
          id="suppliers_topbar_bg"
          className=""
          style={{ position: "relative", paddingLeft: "0rem" }}
        >
          <div className="container d-flex justify-content-between align-items-center">
            <div
              id="dashboard_sub_header"
              className="d-flex align-items-center"
              style={{ gap: "16px" }}
            >
              <a
                href="/suppliers/supplier-info"
                className="text-decoration-none"
              >
                {" "}
                <span id="cancel_po_button">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M10.5859 12.0001L2.79297 4.20718L4.20718 2.79297L12.0001 10.5858L19.793 2.79297L21.2072 4.20718L13.4143 12.0001L21.2072 19.7929L19.793 21.2072L12.0001 13.4143L4.20718 21.2072L2.79297 19.7929L10.5859 12.0001Z"
                      fill="var(--onSurface)"
                    />
                    <path
                      d="M10.5859 12.0001L2.79297 4.20718L4.20718 2.79297L12.0001 10.5858L19.793 2.79297L21.2072 4.20718L13.4143 12.0001L21.2072 19.7929L19.793 21.2072L12.0001 13.4143L4.20718 21.2072L2.79297 19.7929L10.5859 12.0001Z"
                      fill="black"
                      fill-opacity="0.2"
                    />
                    <path
                      d="M10.5859 12.0001L2.79297 4.20718L4.20718 2.79297L12.0001 10.5858L19.793 2.79297L21.2072 4.20718L13.4143 12.0001L21.2072 19.7929L19.793 21.2072L12.0001 13.4143L4.20718 21.2072L2.79297 19.7929L10.5859 12.0001Z"
                      fill="black"
                      fill-opacity="0.2"
                    />
                    <path
                      d="M10.5859 12.0001L2.79297 4.20718L4.20718 2.79297L12.0001 10.5858L19.793 2.79297L21.2072 4.20718L13.4143 12.0001L21.2072 19.7929L19.793 21.2072L12.0001 13.4143L4.20718 21.2072L2.79297 19.7929L10.5859 12.0001Z"
                      fill="black"
                      fill-opacity="0.2"
                    />
                    <path
                      d="M10.5859 12.0001L2.79297 4.20718L4.20718 2.79297L12.0001 10.5858L19.793 2.79297L21.2072 4.20718L13.4143 12.0001L21.2072 19.7929L19.793 21.2072L12.0001 13.4143L4.20718 21.2072L2.79297 19.7929L10.5859 12.0001Z"
                      fill="black"
                      fill-opacity="0.2"
                    />
                  </svg>
                </span>{" "}
              </a>
              Upload Invoice
            </div>
          </div>
        </div>
        <div id="create_po_bg">
          <div className="container">
            <div className="row" id="about_po_header">
              <div className="col-6">
                <div id="qo_order_text">Order Number</div>

                <div id="qo_input_order">
                  <label>Enter Order Number</label>
                  <br />
                  <TextInput
                    value={orderNum}
                    onChange={(e) => handleOrderChange(e)}
                  />
                  {orderNum.length > 0 && (
                    <div className="wrong-symbol-keyword">
                      {" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        onClick={() => {
                          setorderNum("");
                          setRows([]);
                        }}
                      >
                        <path
                          d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 10.5858L9.17157 7.75736L7.75736 9.17157L10.5858 12L7.75736 14.8284L9.17157 16.2426L12 13.4142L14.8284 16.2426L16.2426 14.8284L13.4142 12L16.2426 9.17157L14.8284 7.75736L12 10.5858Z"
                          fill="#535260"
                        />
                      </svg>
                    </div>
                  )}

                  {Array.isArray(suggestions) && suggestions.length > 0 && (
                    <>
                      <div
                        className="suggestions-dropdown"
                        id="dropdownforuploadinvoice"
                      >
                        {suggestions.map((suggestion) => (
                          <div
                            key={suggestion.PO_ID}
                            className="suggestion-item"
                            onClick={() => {
                              // setCustomerName(suggestion);
                              // setSuggestions([]);
                              setPO_ID(suggestion.PO_ID);
                              setorderNum(suggestion.PO_NUM);
                              getPO(suggestion.PO_ID);
                              setsuggestions([]);
                            }}
                          >
                            {suggestion.PO_NUM}
                          </div>
                        ))}
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className="col-6 " id="about_po_left_bg" align="end">
                <div id="content_wrapper">
                  {" "}
                  <div id="qo_order_text">Invoice File</div>
                  <div id="upload_qo_text">Upload Invoice File</div>
                  {isLoading ? (
                    <div id="upload_pi_file">
                      <div className="">
                        <div id="about_po_header">
                          <span
                            className="d-flex align-items-center"
                            style={{ gap: "4px" }}
                          >
                            <CircleSpinner
                              size={20}
                              loading={isLoading}
                              color="var(--primaryContainer)"
                            />
                            Uploading Your File
                          </span>
                          <div>{fileName}</div>
                        </div>
                      </div>
                      <div
                        id="file_upload_cancel"
                        onClick={handleImportCancel}
                        style={{
                          marginTop: 8,
                        }}
                      >
                        Cancel
                      </div>
                    </div>
                  ) : (
                    <div id="import_supplier_bg">
                      {file ? (
                        <div>
                          <div
                            id="upload_pi_file"
                            style={{
                              border: "none",
                            }}
                            className="d-flex flex-row align-items-center gap-2"
                          >
                            <div id="upload_file_text " className=" mt-5">
                              <span
                                className="d-flex align-items-center"
                                style={{
                                  gap: "4px",
                                }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                >
                                  <path
                                    d="M5 4H15V8H19V20H5V4ZM3.9985 2C3.44749 2 3 2.44405 3 2.9918V21.0082C3 21.5447 3.44476 22 3.9934 22H20.0066C20.5551 22 21 21.5489 21 20.9925L20.9997 7L16 2H3.9985ZM10.4999 7.5C10.4999 9.07749 10.0442 10.9373 9.27493 12.6534C8.50287 14.3757 7.46143 15.8502 6.37524 16.7191L7.55464 18.3321C10.4821 16.3804 13.7233 15.0421 16.8585 15.49L17.3162 13.5513C14.6435 12.6604 12.4999 9.98994 12.4999 7.5H10.4999ZM11.0999 13.4716C11.3673 12.8752 11.6042 12.2563 11.8037 11.6285C12.2753 12.3531 12.8553 13.0182 13.5101 13.5953C12.5283 13.7711 11.5665 14.0596 10.6352 14.4276C10.7999 14.1143 10.9551 13.7948 11.0999 13.4716Z"
                                    fill="#1B1B24"
                                  />
                                  <path
                                    d="M5 4H15V8H19V20H5V4ZM3.9985 2C3.44749 2 3 2.44405 3 2.9918V21.0082C3 21.5447 3.44476 22 3.9934 22H20.0066C20.5551 22 21 21.5489 21 20.9925L20.9997 7L16 2H3.9985ZM10.4999 7.5C10.4999 9.07749 10.0442 10.9373 9.27493 12.6534C8.50287 14.3757 7.46143 15.8502 6.37524 16.7191L7.55464 18.3321C10.4821 16.3804 13.7233 15.0421 16.8585 15.49L17.3162 13.5513C14.6435 12.6604 12.4999 9.98994 12.4999 7.5H10.4999ZM11.0999 13.4716C11.3673 12.8752 11.6042 12.2563 11.8037 11.6285C12.2753 12.3531 12.8553 13.0182 13.5101 13.5953C12.5283 13.7711 11.5665 14.0596 10.6352 14.4276C10.7999 14.1143 10.9551 13.7948 11.0999 13.4716Z"
                                    fill="black"
                                    fill-opacity="0.2"
                                  />
                                  <path
                                    d="M5 4H15V8H19V20H5V4ZM3.9985 2C3.44749 2 3 2.44405 3 2.9918V21.0082C3 21.5447 3.44476 22 3.9934 22H20.0066C20.5551 22 21 21.5489 21 20.9925L20.9997 7L16 2H3.9985ZM10.4999 7.5C10.4999 9.07749 10.0442 10.9373 9.27493 12.6534C8.50287 14.3757 7.46143 15.8502 6.37524 16.7191L7.55464 18.3321C10.4821 16.3804 13.7233 15.0421 16.8585 15.49L17.3162 13.5513C14.6435 12.6604 12.4999 9.98994 12.4999 7.5H10.4999ZM11.0999 13.4716C11.3673 12.8752 11.6042 12.2563 11.8037 11.6285C12.2753 12.3531 12.8553 13.0182 13.5101 13.5953C12.5283 13.7711 11.5665 14.0596 10.6352 14.4276C10.7999 14.1143 10.9551 13.7948 11.0999 13.4716Z"
                                    fill="black"
                                    fill-opacity="0.2"
                                  />
                                  <path
                                    d="M5 4H15V8H19V20H5V4ZM3.9985 2C3.44749 2 3 2.44405 3 2.9918V21.0082C3 21.5447 3.44476 22 3.9934 22H20.0066C20.5551 22 21 21.5489 21 20.9925L20.9997 7L16 2H3.9985ZM10.4999 7.5C10.4999 9.07749 10.0442 10.9373 9.27493 12.6534C8.50287 14.3757 7.46143 15.8502 6.37524 16.7191L7.55464 18.3321C10.4821 16.3804 13.7233 15.0421 16.8585 15.49L17.3162 13.5513C14.6435 12.6604 12.4999 9.98994 12.4999 7.5H10.4999ZM11.0999 13.4716C11.3673 12.8752 11.6042 12.2563 11.8037 11.6285C12.2753 12.3531 12.8553 13.0182 13.5101 13.5953C12.5283 13.7711 11.5665 14.0596 10.6352 14.4276C10.7999 14.1143 10.9551 13.7948 11.0999 13.4716Z"
                                    fill="black"
                                    fill-opacity="0.2"
                                  />
                                  <path
                                    d="M5 4H15V8H19V20H5V4ZM3.9985 2C3.44749 2 3 2.44405 3 2.9918V21.0082C3 21.5447 3.44476 22 3.9934 22H20.0066C20.5551 22 21 21.5489 21 20.9925L20.9997 7L16 2H3.9985ZM10.4999 7.5C10.4999 9.07749 10.0442 10.9373 9.27493 12.6534C8.50287 14.3757 7.46143 15.8502 6.37524 16.7191L7.55464 18.3321C10.4821 16.3804 13.7233 15.0421 16.8585 15.49L17.3162 13.5513C14.6435 12.6604 12.4999 9.98994 12.4999 7.5H10.4999ZM11.0999 13.4716C11.3673 12.8752 11.6042 12.2563 11.8037 11.6285C12.2753 12.3531 12.8553 13.0182 13.5101 13.5953C12.5283 13.7711 11.5665 14.0596 10.6352 14.4276C10.7999 14.1143 10.9551 13.7948 11.0999 13.4716Z"
                                    fill="black"
                                    fill-opacity="0.2"
                                  />
                                </svg>
                                {fileName}
                              </span>
                            </div>
                            <div
                              onClick={handleImportCancel}
                              style={{
                                cursor: "pointer",
                              }}
                              className="mt-5"
                            >
                              <svg
                                onClick={() => setfileToUpload(null)}
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                              >
                                <path
                                  d="M8.00045 7.05767L11.3003 3.75781L12.2431 4.70062L8.94325 8.00047L12.2431 11.3003L11.3003 12.2431L8.00045 8.94327L4.70063 12.2431L3.75781 11.3003L7.05765 8.00047L3.75781 4.70062L4.70063 3.75781L8.00045 7.05767Z"
                                  fill="#950F27"
                                />
                              </svg>
                            </div>
                            <Button
                              id="view_cons_file"
                              style={{
                                width: 98,
                              }}
                              onClick={handleOpen} className="mt-5"
                            >
                              <h2 id="view">View</h2>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <path
                                  d="M16.0052 9.41421L7.39858 18.0208L5.98438 16.6066L14.591 8H7.00519V6H18.0052V17H16.0052V9.41421Z"
                                  fill="#635BFF"
                                />
                              </svg>
                            </Button>
                          </div>
                        </div>
                      ) : (
                        <div className="col-12">
                          <label
                            htmlFor="file-input"
                            className="d-flex align-items-center justify-content-center flex-column"
                            id="upload_pi_file"
                            style={{
                              cursor: "pointer",
                              marginTop: 8,
                            }}
                          >
                            <div id="upload_csv_text">
                              {fileName && fileName}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <g opacity="0.4">
                                  <path
                                    d="M12 12.5858L16.2426 16.8284L14.8284 18.2426L13 16.415V22H11V16.413L9.17157 18.2426L7.75736 16.8284L12 12.5858ZM12 2C15.5934 2 18.5544 4.70761 18.9541 8.19395C21.2858 8.83154 23 10.9656 23 13.5C23 16.3688 20.8036 18.7246 18.0006 18.9776L18 17C18 13.6863 15.3137 11 12 11C8.7616 11 6.12243 13.5656 6.00414 16.7751L6 17L6.00039 18.9776C3.19696 18.7252 1 16.3692 1 13.5C1 10.9656 2.71424 8.83154 5.04648 8.19411C5.44561 4.70761 8.40661 2 12 2Z"
                                    fill="#635BFF"
                                  />
                                </g>
                              </svg>
                            </div>
                          </label>
                          <TextInputWithAll
                            name="file-input"
                            type="file"
                            onChange={handleChange}
                            id="file-input"
                            ref={fileInputRef}
                            style={{
                              display: "none",
                            }}
                            // accept=".csv"
                          />
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div
            className="container"
            style={{
             // maxHeight: "fit-content",
              width: "100%",
              marginTop: isLoading ? "110px" : "50px",
           
            }}
          >
            <DataGrid
               style={{
                height: "auto", // Allows the grid height to automatically adjust based on rows
                width: "100%",  // Ensure it spans the full width
              }}
              rows={calculatedRows}
              columns={columns}
              hideFooterPagination={true}
              hideFooter={true}
              autoHeight
              rowHeight={80}
              showCellRightBorder
              // getRowClassName={getRowClassName}
              // getCellClassName={getCellClassName}
            />
          </div>
          {optionShow && (
            <div
              className="options-dropdown ellipsisOption"
              style={{
                top: optionPosition.top,
                left: optionPosition.left,
              }}
            >
              <div
                className="option"
                style={{
                  textDecoration: "none",
                  color: "#535260",
                  cursor: "pointer",
                }}
              >
                Pay Supplier
              </div>
            </div>
          )}
          <div
            style={{
              borderBottom: "0.5px solid var(--outlineVariant)",
            }}
          >
            <div className="row  p-0 m-0">
              <div className="col-6"></div>
              <div className="col-6">
                <div
                  className="d-flex flex-column"
                  style={{
                    gap: 40,
                    borderBottom: "0.5px solid var(--outlineVariant)",
                    marginTop: 44,
                  }}
                >
                  <div className="d-flex flex-row" style={{ gap: 151 }}>
                    <h2 id="total_cost_text">Total Cost in {currencyName1 || "N/A"}</h2>
                    <p id="total_cost">
                      {" "}
                      {currencySymbol1}<USDCurrency amount={totalTTL_RMB} />
                    </p>
                  </div>
                  <div
                    className="po_input position-relative d-flex align-items-center"
                    // style={{ marginBottom: 28 }}
                  >
                    <h2 id="total_cost_text" style={{ width: 164 }}>
                      Convert & Ex.Rate
                    </h2>
                    <DropDownInputWithoutLabel
                      options={currency}
                      value={currencyID}
                      onChange={handleCurrencyChange}
                      id="cust_div"
                      style={{
                        width: 180,
                        margin: 0,
                        padding: "12px 16px",
                        marginLeft: 13,
                      }}
                      placeholder="Select Transportation"
                    />
                    <TextInputWithAll
                      style={{ width: 98, paddingRight: 0, margin: 0 }}
                      className="po_input"
                      value={currencyFactory}
                      onChange={currency_convertor}
                    />
                  </div>
                  <div className="d-flex flex-row" style={{ gap: 151 }}>
                    <h2 id="total_cost_text">Total Cost in {currencyLabel}</h2>
                    <p id="total_cost">
                      {currencySymbol}
                      <USDCurrency amount={usd_value} />
                    </p>
                  </div>
                  <div
                    className="d-flex flex-row align-items-center"
                    style={{ gap: 106 }}
                  >
                    <div
                      className="po_input position-relative d-flex justify-content-center align-items-center"
                      style={{ gap: 32 }}
                    >
                      <h2 id="total_cost_text" style={{ width: 145 }}>
                        Agent Commission
                      </h2>
                      <TextInputWithAll
                        style={{ width: 100, paddingRight: 25 }}
                        value={commission}
                        onChange={(e) => {
                          const value = e.target.value;
                          // Allow only numbers (no negative or positive signs)
                          if (/^\d*\.?\d*$/.test(value)) {
                            agent_Commission(value);
                          }
                        }}
                      />{" "}
                      <div
                        style={{ top: 19, right: "-20px" }}
                        id="remaining_characters"
                        className="position-absolute translate-middle-x "
                      >%</div>
                    </div>
                    {/* <div ><input id="agent_edit_div"/></div> */}
                    <p id="total_cost" style={{ margin: 0 }}>
                      {currencySymbol}
                      <USDCurrency amount={commissionValue} />
                    </p>
                  </div>
                  <div
                    className="po_input position-relative d-flex align-items-center"
                    style={{ marginBottom: 28,position:"relative" }}
                  >
                    <h2 id="total_cost_text" style={{ width: 164 }}>
                      Inland Transportation
                    </h2>
                    <DropDownInputWithoutLabel
                      options={containers}
                      value={selectedContainer}
                      id="cust_div"
                      style={{
                        width: 180,
                        margin: 0,
                        padding: "12px 16px",
                        marginLeft: 13,
                      }}
                      onChange={handleContainerchange}
                      placeholder="Select Type"
                    />
                    <TextInputWithAll
                      style={{ width: 98, paddingRight: 0, margin: 0,paddingLeft:"40px" }}
                      className="po_input"
                      value={transportCost}
                      onChange={(e) => addTransportCost(e)}
                    />
                    <div style={{position:"absolute",left:"380px"}}>{currencySymbol}</div>
                  </div>
                </div>

                <div
                  className="d-flex flex-column"
                  style={{
                    gap: 20,
                    margin: "20px 72px 80px 0px",
                  }}
                >
                  <div className="d-flex flex-row" style={{ gap: 151 }}>
                    <h2 id="total_cost_text">Total Value</h2>
                    <h2 id="total_cost" style={{ fontWeight: 600 }}>
                      {currencySymbol}
                      <USDCurrency amount={finalAmount} />
                    </h2>
                  </div>
                  <div
                    className="d-flex flex-row align-items-center"
                    style={{ gap: 13 }}
                  >
                    <h2 id="total_cost_text" style={{ width: 524 }}>
                      SAY "{currencyLabel} {words}" ONLY
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="d-flex flex-column gap-4 container"
            style={{
              marginTop: 44,
            }}
          >
            <div className=" d-flex flex-row align-items-center gap-2">
              <div
                style={{
                  width: 8,
                  height: 44,
                  background: "var(--tertiaryContainer, #BA3CBB)",
                }}
              ></div>
              <h2 id="shipping_details">Delivery Info</h2>
            </div>
            <div className="d-flex flex-row" style={{ gap: "64px" }}>
              <div className="d-flex flex-column gap-2">
                <label>Delivery Date</label>
                <TextInputWithAll
                  type="date"
                  placeholder="Enter Delivery Date"
                  //value={e.target.value}
                  onChange={(e) =>
                    setdeliveryDate(convertToUnixTimestamp(e.target.value))
                  }
                />
              </div>
              <div className="d-flex flex-column gap-2">
                <label>Vessel Name</label>
                <TextInputWithAll
                  type="text"
                  placeholder="Enter Vessel Name"
                  className="date_without_symbol_id"
                  value={vessel_Name}
                  onChange={(e) => setvessel_Name(e.target.value)}
                />
              </div>
            </div>
            <div
              className="d-flex flex-row  "
              style={{ marginBottom: 108, gap: "64px" }}
            >
              <div className="d-flex flex-column gap-2">
                <label>Sailing Date</label>
                <TextInputWithAll
                  type="date"
                  // value={e.target.value}
                  onChange={(e) =>
                    setshippingDate(convertToUnixTimestamp(e.target.value))
                  }
                  placeholder="Enter Sailing Date"
                />
              </div>
              <DropDownInput
                label="Payment Type"
                id="select_status"
                options={TypeOptions}
                value={paymentType}
                placeholder="Select Type"
                onChange={handlePaymentChange}
              />
            </div>
          </div>
        </div>
      </div>
      <div id="po_footer" className="pi_footer">
        <div id="add_supplier_buttons" className="container">
          {" "}
          <a
            href="/suppliers/supplier-info"
            className="text-decoration-none"
            onClick={handleActiveTab}
          >
            {" "}
            <div id="cancel">Cancel</div>
          </a>
          <div
            className="d-flex align-items-center justify-content-right "
            style={{ gap: "12px" }}
          >
            <a className="text-decoration-none" onClick={handleInvoiceUpload}>
              <div id="submitsupplier">Confirm Invoice</div>
            </a>
            <ToastContainer
              position="bottom-center"
              style={{ width: "max-content" }}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default UploadInvoice;
