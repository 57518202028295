import React, { useState } from "react";
import SearchBar from "../../StaticComponents/SearchBar";
import Filter from "../../StaticComponents/Filter";
import "../../Styles/ReportComponents.css";
import ReportInventoryFilter from "./ReportInventoryFilter";
import ReportInventoryTable from "./ReportInventoryTable";

export default function ReportInventory() {
  const [modalShow, setModalShow] = useState(false);
  const [stockproducts, setstockproduct] = useState(0);
  const [productstoship, setproductstoship] = useState(0);
  const [productstoreceive, setproductstoreceive] = useState(0);
  const [totalResults, setTotalResults] = useState(0);
  const onValue = (a, b, c, d) => {
    setstockproduct(a);
    setproductstoship(b);
    setproductstoreceive(c);
    setTotalResults(d);
  };
  const [notify, setnotify] = useState(0);
  const handleNotify = (a) => {
    setnotify(a);
  };
  const [searchkeyword, setsearchkeyword] = useState("");
  const onSearch = (value) => {
    setsearchkeyword(value);
  };
  return (
    <div id="inv_bg">
      <div className="container">
        <div id="report_overview_heading" className="row">
          <div className="col-md-4">
            <div id="total_stock">
              Total Stock Products
              <div id="total_count">{stockproducts || 0}</div>
            </div>
          </div>
          <div className="col-md-4">
            <div id="total_stock">
              Total Products To Ship
              <div id="total_count">{productstoship || 0}</div>
            </div>
          </div>
          <div className="col-md-4">
            <div id="total_stock">
              Total Products To Receive
              <div id="total_count">{productstoreceive || 0}</div>
            </div>
          </div>
        </div>
        <div className="d-flex" id="invFilters" style={{ marginTop: "40px" }}>
          <SearchBar onSearch={onSearch} />
          <div onClick={() => setModalShow(true)} style={{ cursor: "pointer" }}>
            <Filter notify={notify} />
          </div>
          <ReportInventoryFilter
            show={modalShow}
            onHide={() => setModalShow(false)}
            handleNotify={handleNotify}
          />
        </div>
        <div id="report_tab_bg">
          <h2 className="totoal_1000_results">{totalResults || 0} Results</h2>
          <ReportInventoryTable
            onValue={onValue}
            searchkeyword={searchkeyword}
          />
        </div>
      </div>
    </div>
  );
}
