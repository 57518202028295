import React, { useEffect, useState, useRef } from "react";
import { DataGrid } from "@mui/x-data-grid";
import po_img from "../../../assets/po_img.png";
import { makeStyles } from "@material-ui/styles";
import "../../../Styles/UploadInvoice.css";
import TextInput from "../../Inputs/TextInput";
import TextInputWithAll from "../../Inputs/TextInputWithAll";
import DropDownInputWithoutLabel from "../../Inputs/DropDownInputWithoutLabel";
import DropDownInput from "../../Inputs/DropDownInput";
import { makeApiCall } from "../../../StaticComponents/API";
import CurrencyFormatter from "../../../StaticComponents/FormatNumber";
import FormatCurrency from "../../../StaticComponents/FormatCurrency";
import USDCurrency from "../../../StaticComponents/USDCurrency";
import { ToWords } from "to-words";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { CircleSpinner } from "react-spinners-kit";
import { Button } from "react-bootstrap";
import axios from "axios";
const useStyles = makeStyles({
  // subheadingRow: {
  //   textAlign: "center",
  //   fontWeight: "bold",
  //   minHeight: "32px !important",
  //   maxHeight: "32px !important",
  //   "& .MuiDataGrid-cell": {
  //     border: "none !important",
  //     lineHeight: "32px !important",
  //     minHeight: "32px !important",
  //     maxHeight: "32px !important",
  //   },
  // },

  // subheadingCell: {
  //   width: "100%",
  //   border: "0.5px solid var(--outlineVariant) !important",
  //   lineHeight: "32px !important",
  //   minHeight: "32px !important",
  //   maxHeight: "32px !important",
  // },

  padding: { padding: "0px !important" },
});
function ModifyInvoice() {
  const token = localStorage.getItem("token");
  const CLIENT_SUPPLIER_ID = localStorage.getItem("CLIENT_SUPPLIER_ID");
  const navigate = useNavigate();
  const [optionShow, setOptionShow] = useState(false);
  const [optionPosition, setOptionPosition] = useState({ top: 0, left: 0 });
  const [commission, setCommission] = useState("5");
  const [transportCost, setTransportCost] = useState(0);
  const [orderNum, setorderNum] = useState(localStorage.getItem("ORDER_NUM"));
  const [suggestions, setsuggestions] = useState([]);
  const [PO_ID, setPO_ID] = useState();
  const [purchaseOrder, setpurchaseOrder] = useState([]);
  const [currency, setcurrency] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState("");
  const [currencyID, setCurrencyID] = useState(null);
  const [currencyFactory, setcurrencyFactory] = useState(0);
  const [usd_value, setusd_value] = useState(0);
  const [convert_rate, setconvert_rate] = useState(0);
  const [commissionValue, setCommissionValue] = useState(0);
  const [agentCommission, setagentCommission] = useState(0);
  const [containers, setcontainers] = useState([]);
  const [finalAmount, setfinalAmount] = useState(0);
  const [deliveryDate, setdeliveryDate] = useState("");
  const [shippingDate, setshippingDate] = useState("");
  const [vessel_Name, setvessel_Name] = useState("");
  const [paymentType, setpaymentType] = useState("");
  const [paymentName, setpaymentName] = useState("");
  // setPO_ID(localStorage.getItem("ORDER_ID"));
  // setorderNum(localStorage.getItem("ORDER_NUM"));
  const [currencySymbol, setcurrencySymbol] = useState("$");
  const [SelectedCurrency, setselectedCurrency] = useState();
  const [currencyLabel, setcurrencyLabel] = useState("AFN");
  const [file, setFile] = useState();
  const [fileName, setFileName] = useState("");
  const idRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const fileInputRef = useRef(null);
  const uploadTimeoutRef = useRef(null);
  const [fileToUpload, setfileToUpload] = useState(null);
  const [container, setcontainer] = useState([]);
  const [SUPP_PO_ID, setSUPP_PO_ID] = useState();
  const [invoice_currency_id, setinvoice_currency_id] = useState();
  const [ccurrencyID, setccurrencyID] = useState();
  const [containerID, setcontainerID] = useState(2);
  const handleChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setIsLoading(true);
      console.log("Selected file:", e.target.files[0]);
      console.log("Selected file:", e.target.files[0].lastModifiedDate);
      setFileName(e.target.files[0].name);

      uploadTimeoutRef.current = setTimeout(() => {
        setFile(URL.createObjectURL(e.target.files[0]));
        setFileName(e.target.files[0].name);
        setfileToUpload(e.target.files[0]);
        setIsLoading(false);
      }, 2000);
    } else {
      console.error("No file selected");
    }
  };
  const addTransportCost = (e) => {
    const value = parseFloat(e.target.value);
    if (!isNaN(value)) {
      setTransportCost(value);
    } else {
      setTransportCost(0); // Reset to 0 if input is not valid
    }
  };
  const handleActiveTab = () => {
    localStorage.setItem("activeTab", "Orders");
    localStorage.setItem("orderTab", "I");
  };
  const handleImportCancel = () => {
    setFile(null);
    setFileName("");
    setIsLoading(false);
    setfileToUpload(null);
    if (uploadTimeoutRef.current) {
      clearTimeout(uploadTimeoutRef.current);
      uploadTimeoutRef.current = null;
    }

    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }

    console.log("File upload canceled");
  };
  function convertUnixTimestampToDate(unixTimestamp) {
    const date = new Date(unixTimestamp * 1000);
    const formattedDate = `${date.getFullYear()}-${String(
      date.getMonth() + 1
    ).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")}`;
    return formattedDate;
  }

  function convertToUnixTimestamp(dateString) {
    const date = new Date(dateString);
    const unixTimestamp = Math.floor(date.getTime() / 1000);
    return unixTimestamp;
  }
  const INVOICE_ID = localStorage.getItem("INVOICE_ID");
  const APP_CLIENT_ID = localStorage.getItem("CLIENT_ID")

  useEffect(() => {
    const payload = {
      INVOICE_ID: INVOICE_ID,
      CLIENT_SUPPLIER_ID: CLIENT_SUPPLIER_ID,
    };
    makeApiCall({
      mod: "SupplierInvoice",
      action: "view_supp_invoice",
      payload: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => {
      console.log("VIEW INVOICE", response);
      const PurchaseOrder = response.XscData.INV_ITEMS;
      if (Array.isArray(PurchaseOrder) && PurchaseOrder.length > 0) {
        const mappedRows = PurchaseOrder.map((orders) => ({
          id: orders.CLIENT_PROD_ID,
          MNX_ID: orders.CLIENT_PROD_ID,
          SUP_ITEM_ID: orders.SUPP_PROD_ID,
          DESCRIPTION: orders.DESCRIPTION,
          TTL_QTY: orders.TOTAL_QTY,
          RMB: orders.PRICE_PER_ITEM,
        }));
        setRows(mappedRows);
      }
      setdeliveryDate(
        convertUnixTimestampToDate(
          response.XscData.ADDITIONAL_DETAILS.DELIVERY_DATE
        )
      );
      setshippingDate(
        convertUnixTimestampToDate(
          response.XscData.ADDITIONAL_DETAILS.SAILING_DATE
        )
      );
      setvessel_Name(response.XscData.ADDITIONAL_DETAILS.VESSEL_NAME);
      setpaymentType(response.XscData.ADDITIONAL_DETAILS.PAYMENT_TYPE);
      setpaymentName(response.XscData.ADDITIONAL_DETAILS.PAYMENT_TYPE);
      setcurrencyFactory(response.XscData.SUMMARY.INVOICE_CURRENCY_RATE);
      setCommission(response.XscData.SUMMARY.AGENTS_COMMISION_PERCENTAGE);
      setTransportCost(response.XscData.SUMMARY.SHIPPING_COST);
      setfinalAmount(response.XscData.SUMMARY.TOTAL_INVOICE_VALUE);
      setorderNum(response.XscData.SUMMARY.SUPP_INV_NUM);
      setcurrencyLabel(response.XscData.SUMMARY.INVOICE_CURRENCY);
      setselectedCurrency(response.XscData.SUMMARY.INVOICE_CURRENCY);
      setcontainerID(response.XscData.SUMMARY.INLAND_TRANS_TYPE);
      setcurrencySymbol(response.XscData.SUMMARY.INVOICE_CURRENCY_SYMBOL);
      setfileToUpload(response.XscData.SUMMARY.SUPP_INV_MM);
      setFileName(response.XscData.SUMMARY.SUPP_INV_MM);
      setFile(response.XscData.SUMMARY.SUPP_INV_MM);
      setPO_ID(response.XscData.SUMMARY.SUPP_PO_ID);
      setccurrencyID(response.XscData.SUMMARY.INVOICE_CURRENCY_ID);
    });
  }, []);
  useEffect(() => {
    setusd_value(totalTTL_RMB * currencyFactory);
  });
  console.log("DELIVERY DATE", deliveryDate);

  const towords = new ToWords();
  const [words, setwords] = useState("");
  useEffect(() => {
    setwords(towords.convert(finalAmount));
  }, [finalAmount]);
  useEffect(() => {
    console.log("iasjbiajsbidkjb");
    const updatedValue =
      usd_value + commissionValue + parseFloat(transportCost);
    setfinalAmount(updatedValue);
    console.log(finalAmount);
    console.log(transportCost);
    console.log(typeof transportCost);
  }, [usd_value, commissionValue, transportCost]);
  // useEffect(() => {
  //   const payload = {
  //     SUPP_PO_ID: PO_ID,
  //     CLIENT_SUPP_ID: CLIENT_SUPPLIER_ID,
  //   };
  //   makeApiCall({
  //     mod: "SupplierPO",
  //     action: "view_supp_po",
  //     payload: payload,
  //     headers: {
  //       Authorization: `Bearer ${token}`,
  //     },
  //   }).then((response) => {
  //     console.log("payloaddd", payload);
  //     console.log("response initial", response);
  //     setpurchaseOrder(response.XscData.PO_DETAILS);
  //     const PurchaseOrder = response.XscData.PO_DETAILS;
  //     if (Array.isArray(PurchaseOrder) && PurchaseOrder.length > 0) {
  //       const mappedRows = PurchaseOrder.map((orders) => ({
  //         id: orders.PRODUCT_ID,
  //         MNX_ID: orders.CLIENT_ITEM_NUM,
  //         SUP_ITEM_ID: orders.SUPP_ITEM_NUM,
  //         DESCRIPTION: orders.DESCRIPTION,
  //         TTL_QTY: orders.TOTAL_QTY,
  //         RMB: orders.PRICE,
  //       }));
  //       setRows(mappedRows);
  //     }
  //   });
  // }, []);
  useEffect(() => {
    const payload = {
      APP_CLIENT_ID: APP_CLIENT_ID,
    };
    makeApiCall({
      mod: "Shipping",
      action: "get-container-list",
      payload: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => {
      console.log("response of the container list", response);
      const lists = response.XscData.CONTAINER_DETAIL;
      if (Array.isArray(lists) && lists.length > 0) {
        const mappedRows = lists.map((list) => ({
          value: list.ID,
          label: list.ALIAS_NAME,
        }));
        setcontainers(mappedRows);
      }
    });
  }, []);
  useEffect(() => {
    const value = parseFloat(commission);
    setCommissionValue((usd_value * commission) / 100);
  }, [commission, usd_value]);
  const agent_Commission = (value) => {
    const floatvalue = parseFloat(value);
    setCommission(value);
    setagentCommission(floatvalue);
    setCommissionValue((value * usd_value) / 100);
    console.log("agent commoission ");
  };
  useEffect(() => {
    const payload = { SEARCH_KEYWORD: "" };
    makeApiCall({
      mod: "Orders",
      action: "list_currency_unit",
      payload: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => {
      console.log("Currency convertor ", response);
      const currency = response.XscData;
      const mappedRows = currency.map((cur) => ({
        value: cur.CURRENCY_ID,
        label: cur.CURRENCY,
        symbol: cur.CURRENCY_SYMBOL,
      }));
      setcurrency(mappedRows);
      if (mappedRows.length > 0) {
        setSelectedCurrency(mappedRows[5].label); // Set default selection
        setCurrencyID(mappedRows[5].value); // Set default currency ID
      }
      console.log("CURRWNCY ID", ccurrencyID);
      console.log("MAPPED CURRENCY DATA", mappedRows);
    });
  }, []);
  const handleCurrencyChange = (e) => {
    const selectedValue = e.target.value;
    setCurrencyID(selectedValue);
    const foundCurrency = currency.find((c) => c.value == selectedValue);
    console.log("SKELFHGVHGVHGV", foundCurrency);
    if (foundCurrency) {
      setselectedCurrency(foundCurrency);
      setcurrencyLabel(foundCurrency.label);
      setcurrencySymbol(foundCurrency.symbol);
      setccurrencyID(foundCurrency.value);
    }
  };
  const handleContainerChange = (e) => {
    setcontainerID(e.target.value);
  };
  console.log("LABEL", currencyLabel);
  const currency_convertor = (e) => {
    const value = parseFloat(e.target.value);
    setcurrencyFactory(e.target.value);
    setconvert_rate(value);
    setusd_value(totalTTL_RMB * e.target.value);
  };
  const columns = [
    {
      field: "MNX_ID",
      headerName: "Mnx. Item ID",
      width: 200,
      align: "left",
    },
    {
      field: "SUP_ITEM_ID",
      headerName: "Sup. Item ID",
      width: 200,
      align: "left",
    },
    {
      field: "DESCRIPTION",
      headerName: "Description Text",
      width: 250,
      align: "left",
    },
    {
      field: "TTL_QTY",
      headerName: "Total QTY",
      width: 200,
      align: "left",
    },

    {
      field: "RMB",
      headerName: "RMB",
      width: 200,
      align: "left",
      renderCell: (params) => {
        return <CurrencyFormatter amount={params.value} />;
      },
    },
    {
      field: "TTL_RMB",
      headerName: "TTL RMB",
      width: 200,
      align: "left",
      renderCell: (params) => {
        return params.row.id_desc === "Total" ? (
          <div className={classes.subheadingCell}>{params.value}</div>
        ) : (
          <CurrencyFormatter amount={params.value} />
        );
      },
    },
  ];

  const initialRows = [
    // {
    //   id: "2",
    //   MNX_ID: "F-1216",
    //   SUP_ITEM_ID: "S-1316",
    //   TOTAL_QTY: 1008,
    //   // PICTURE: 30,
    //   // CTN_QTY: 18,
    //   // INNER: "N/A",
    //   // CTN: 56,
    //   DESCRIPTION: 'Concrete Nails 2.5"',
    //   // PRICE: "¥7.40",
    //   // PH: "Value 9",
    //   // TOTAL_AMOUNT: "¥7,459.20",
    //   // BARCODE: "7453011784828",
    //   // DUN_BARCODE: "17453011784825",
    //   // PACKING: "Colour Box",
    //   RMB: "0.0130",
    //   TTL_RMB: "0.73",
    //   // WEIGHT: "0.0130",
    //   // TTL_WEIGHT: "1,024.80",
    // },
  ];
  const [rows, setRows] = useState(initialRows);
  useEffect(() => {
    const payload = {
      CLIENT_SUPPLIER_ID: CLIENT_SUPPLIER_ID,
    };
    makeApiCall({
      mod: "SupplierInvoice",
      action: "list_po_list",
      payload: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => {
      console.log("pauload", payload);
      console.log("response of po list", response);
      setsuggestions(response.XscData.PO_LIST);
    });
  }, []);
  const handleOrderChange = (e) => {
    console.log(e.target.value);
    setorderNum(e.target.value);
    const payload = {
      SEARCH_KEYWORD: e.target.value,
      CLIENT_SUPPLIER_ID: CLIENT_SUPPLIER_ID,
    };
    makeApiCall({
      mod: "SupplierInvoice",
      action: "list_po_list",
      payload: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => {
      console.log("pauload", payload);
      console.log("response of po list", response);
      setsuggestions(response.XscData.PO_LIST);
    });
  };
  const getPO = (poid) => {
    const payload = {
      SUPP_PO_ID: poid,
      CLIENT_SUPP_ID: CLIENT_SUPPLIER_ID,
    };
    makeApiCall({
      mod: "SupplierPO",
      action: "view_supp_po",
      payload: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => {
      console.log("payload", payload);
      console.log("response", response);
      setpurchaseOrder(response.XscData.PO_DETAILS);
      const PurchaseOrder = response.XscData.PO_DETAILS;
      if (Array.isArray(PurchaseOrder) && PurchaseOrder.length > 0) {
        const mappedRows = PurchaseOrder.map((orders) => ({
          id: orders.PRODUCT_ID,
          MNX_ID: orders.CLIENT_ITEM_NUM,
          SUP_ITEM_ID: orders.SUPP_ITEM_NUM,
          DESCRIPTION: orders.DESCRIPTION,
          TTL_QTY: orders.TOTAL_QTY,
          RMB: orders.PRICE,
        }));
        setRows(mappedRows);
      }
    });
  };
  const calculatedRows = rows.map((row) => ({
    ...row,
    TTL_RMB: row.TTL_QTY * row.RMB,
  }));
  const totalTTL_RMB = calculatedRows.reduce((acc, row) => {
    return acc + (row.TTL_RMB || 0); // Add each TTL_RMB, handle undefined with || 0
  }, 0); // Initial value of the accumulator is 0
  const TransportationOptions = [
    { value: "1", label: "1 X 40FTHQ" },
    { value: "2", label: "1 X 40FTHQ11" },
  ];

  const TypeOptions = [
    { value: "CASH", label: "CASH" },
    { value: "ONLINE", label: "ONLINE" },
    { value: "T/T", label: "T/T" },
    { value: "CARD", label: "CARD" },
    { value: "OTHERS", label: "OTHERS" },
  ];

  const classes = useStyles();

  // const getRowClassName = (params) => {
  //   return params.row.id_desc === "Total" ? classes.subheadingRow : "";
  // };

  // const getCellClassName = (params) => {
  //   return params.row.id_desc === "Total" ? classes.padding : "";
  // };
  console.log("currency facotry", currencyFactory);
  console.log("USD value", usd_value);
  console.log("Commission value", commissionValue);
  console.log("transport cost ", transportCost);
  console.log("delivery date", deliveryDate);
  console.log("PO_ID", PO_ID);

  const handleModify = () => {
    console.log("Submit button clicked");
  
    // Step 1: Create the initial payload
    const payload = {
      INVOICE_ID: PO_ID,
      DELIVERY_DATE: deliveryDate,
      SHIPPING_DATE: shippingDate,
      PAYMENT_TYPE: paymentType,
      VESSEL_NAME: vessel_Name,
    };
  
    // Step 2: Check for required fields
    const checkRequiredFields = () => {
      let missingFields = [];
      
      if (!payload.DELIVERY_DATE) missingFields.push("Delivery Date");
      if (!payload.SHIPPING_DATE) missingFields.push("Sailing Date");
      if (!payload.VESSEL_NAME) missingFields.push("Vessel Name");
      if (!payload.PAYMENT_TYPE) missingFields.push("Payment Type");
  
      return missingFields;
    };
  
    const missingFields = checkRequiredFields();
  
    // Step 3: If there are missing fields, show a toast and return early
    if (missingFields.length > 0) {
      const errorMessage = `Please fill in the following fields: ${missingFields.join(", ")}`;
      toast.error(errorMessage);
      console.log("Missing Fields:", missingFields);
      return; // Exit the function early
    }
  
    // Step 4: Proceed with creating the full payload and making the API call
    const payload11 = {
      SUPP_INV_ID: INVOICE_ID,
      SUPP_PO_ID: PO_ID,
      DELIVERY_DATE: convertToUnixTimestamp(deliveryDate),
      SHIPPING_DATE: convertToUnixTimestamp(shippingDate),
      PAYMENT_TYPE: paymentType,
      VESSEL_NAME: vessel_Name,
      PO_NUM: orderNum,
      TOTAL_GOODS_AMOUNT: totalTTL_RMB,
      INVOICE_CURRENCY: ccurrencyID,
      INVOICE_CURRENCY_RATE: currencyFactory,
      AGENTS_COMMISION_PERCENTAGE: parseFloat(commission),
      INLAND_TRANS_TYPE: containerID,
      SHIPPING_COST: transportCost,
    };
    
    console.log("PAYLOAD !! 11", payload11);
    
    const formdata = new FormData();
    formdata.append("mod", "SupplierInvoice");
    formdata.append("action", "update_supp_invoice");
    formdata.append("payload", JSON.stringify(payload11));
    
    // Step 5: Make the API call
    axios({
      method: "post",
      url: process.env.REACT_APP_API_URL,
      data: formdata,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        console.log("API Response:", res);
        if (res.data.XscStatus !== 0) {
          toast.success(res.data.XscMessage);
          localStorage.setItem("activeTab", "Orders");
          localStorage.setItem("orderTab", "I");
          navigate("/suppliers/supplier-info");
        } else {
          toast.error(res.data.XscMessage);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  
  console.log("selected currency id", SelectedCurrency);
  console.log("Order Number", orderNum);
  return (
    <>
      <div
        style={{ minHeight: "100vh", position: "relative" }}
        className="container"
      >
        <div
          id="suppliers_topbar_bg"
          className="container"
          style={{ position: "relative", paddingLeft: "0" }}
        >
          <div className=" d-flex justify-content-between align-items-center">
            <div
              id="dashboard_sub_header"
              className="d-flex align-items-center"
              style={{ gap: "16px" }}
            >
              <a
                href="/suppliers/supplier-info"
                className="text-decoration-none"
              >
                {" "}
                <span id="cancel_po_button">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M10.5859 12.0001L2.79297 4.20718L4.20718 2.79297L12.0001 10.5858L19.793 2.79297L21.2072 4.20718L13.4143 12.0001L21.2072 19.7929L19.793 21.2072L12.0001 13.4143L4.20718 21.2072L2.79297 19.7929L10.5859 12.0001Z"
                      fill="var(--onSurface)"
                    />
                    <path
                      d="M10.5859 12.0001L2.79297 4.20718L4.20718 2.79297L12.0001 10.5858L19.793 2.79297L21.2072 4.20718L13.4143 12.0001L21.2072 19.7929L19.793 21.2072L12.0001 13.4143L4.20718 21.2072L2.79297 19.7929L10.5859 12.0001Z"
                      fill="black"
                      fill-opacity="0.2"
                    />
                    <path
                      d="M10.5859 12.0001L2.79297 4.20718L4.20718 2.79297L12.0001 10.5858L19.793 2.79297L21.2072 4.20718L13.4143 12.0001L21.2072 19.7929L19.793 21.2072L12.0001 13.4143L4.20718 21.2072L2.79297 19.7929L10.5859 12.0001Z"
                      fill="black"
                      fill-opacity="0.2"
                    />
                    <path
                      d="M10.5859 12.0001L2.79297 4.20718L4.20718 2.79297L12.0001 10.5858L19.793 2.79297L21.2072 4.20718L13.4143 12.0001L21.2072 19.7929L19.793 21.2072L12.0001 13.4143L4.20718 21.2072L2.79297 19.7929L10.5859 12.0001Z"
                      fill="black"
                      fill-opacity="0.2"
                    />
                    <path
                      d="M10.5859 12.0001L2.79297 4.20718L4.20718 2.79297L12.0001 10.5858L19.793 2.79297L21.2072 4.20718L13.4143 12.0001L21.2072 19.7929L19.793 21.2072L12.0001 13.4143L4.20718 21.2072L2.79297 19.7929L10.5859 12.0001Z"
                      fill="black"
                      fill-opacity="0.2"
                    />
                  </svg>
                </span>{" "}
              </a>
              Modify Invoice
            </div>
          </div>
        </div>
        <div id="create_po_bg">
          <div className="container">
            <div className="row" id="about_po_header">
              <div className="col-6">
                <div id="qo_order_text">Order Number</div>

                <div id="qo_input_order">
                  <label>Enter Order Number</label>
                  <br />
                  <TextInput
                    value={orderNum}
                    onChange={(e) => handleOrderChange(e)}
                  />
                  {orderNum.length > 0 && (
                    <div className="wrong-symbol-keyword">
                      {" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        onClick={() => {
                          setorderNum("");
                          setRows([]);
                        }}
                      >
                        <path
                          d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 10.5858L9.17157 7.75736L7.75736 9.17157L10.5858 12L7.75736 14.8284L9.17157 16.2426L12 13.4142L14.8284 16.2426L16.2426 14.8284L13.4142 12L16.2426 9.17157L14.8284 7.75736L12 10.5858Z"
                          fill="#535260"
                        />
                      </svg>
                    </div>
                  )}
                  {Array.isArray(suggestions) && suggestions.length > 0 && (
                    <div
                      className="suggestions-dropdown"
                      id="dropdownforuploadinvoice"
                    >
                      {suggestions.map((suggestion) => (
                        <div
                          key={suggestion.PO_ID}
                          className="suggestion-item"
                          onClick={() => {
                            // setCustomerName(suggestion);
                            // setSuggestions([]);
                            setPO_ID(suggestion.PO_ID);
                            setorderNum(suggestion.PO_NUM);
                            getPO(suggestion.PO_ID);
                            setsuggestions([]);
                          }}
                        >
                          {suggestion.PO_NUM}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-6 " id="about_po_left_bg" align="end">
                <div id="content_wrapper">
                  {" "}
                  <div id="qo_order_text">Invoice File</div>
                  <div id="upload_qo_text">Upload Invoice File</div>
                  {isLoading ? (
                    <div id="upload_pi_file">
                      <div className="">
                        <div id="about_po_header">
                          <span
                            className="d-flex align-items-center"
                            style={{ gap: "4px" }}
                          >
                            <CircleSpinner
                              size={20}
                              loading={isLoading}
                              color="var(--primaryContainer)"
                            />
                            Uploading Your File
                          </span>
                          <div>{fileName}</div>
                        </div>
                      </div>
                      <div
                        id="file_upload_cancel"
                        onClick={handleImportCancel}
                        style={{
                          marginTop: 8,
                        }}
                      >
                        Cancel
                      </div>
                    </div>
                  ) : (
                    <div id="import_supplier_bg">
                      {file ? (
                        <div>
                          <div
                            id="upload_pi_file"
                            style={{
                              border: "none",
                            }}
                            className="d-flex flex-row align-items-center gap-2"
                          >
                            <div id="upload_file_text">
                              <span
                                className="d-flex align-items-center"
                                style={{
                                  gap: "4px",
                                }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                >
                                  <path
                                    d="M5 4H15V8H19V20H5V4ZM3.9985 2C3.44749 2 3 2.44405 3 2.9918V21.0082C3 21.5447 3.44476 22 3.9934 22H20.0066C20.5551 22 21 21.5489 21 20.9925L20.9997 7L16 2H3.9985ZM10.4999 7.5C10.4999 9.07749 10.0442 10.9373 9.27493 12.6534C8.50287 14.3757 7.46143 15.8502 6.37524 16.7191L7.55464 18.3321C10.4821 16.3804 13.7233 15.0421 16.8585 15.49L17.3162 13.5513C14.6435 12.6604 12.4999 9.98994 12.4999 7.5H10.4999ZM11.0999 13.4716C11.3673 12.8752 11.6042 12.2563 11.8037 11.6285C12.2753 12.3531 12.8553 13.0182 13.5101 13.5953C12.5283 13.7711 11.5665 14.0596 10.6352 14.4276C10.7999 14.1143 10.9551 13.7948 11.0999 13.4716Z"
                                    fill="#1B1B24"
                                  />
                                  <path
                                    d="M5 4H15V8H19V20H5V4ZM3.9985 2C3.44749 2 3 2.44405 3 2.9918V21.0082C3 21.5447 3.44476 22 3.9934 22H20.0066C20.5551 22 21 21.5489 21 20.9925L20.9997 7L16 2H3.9985ZM10.4999 7.5C10.4999 9.07749 10.0442 10.9373 9.27493 12.6534C8.50287 14.3757 7.46143 15.8502 6.37524 16.7191L7.55464 18.3321C10.4821 16.3804 13.7233 15.0421 16.8585 15.49L17.3162 13.5513C14.6435 12.6604 12.4999 9.98994 12.4999 7.5H10.4999ZM11.0999 13.4716C11.3673 12.8752 11.6042 12.2563 11.8037 11.6285C12.2753 12.3531 12.8553 13.0182 13.5101 13.5953C12.5283 13.7711 11.5665 14.0596 10.6352 14.4276C10.7999 14.1143 10.9551 13.7948 11.0999 13.4716Z"
                                    fill="black"
                                    fill-opacity="0.2"
                                  />
                                  <path
                                    d="M5 4H15V8H19V20H5V4ZM3.9985 2C3.44749 2 3 2.44405 3 2.9918V21.0082C3 21.5447 3.44476 22 3.9934 22H20.0066C20.5551 22 21 21.5489 21 20.9925L20.9997 7L16 2H3.9985ZM10.4999 7.5C10.4999 9.07749 10.0442 10.9373 9.27493 12.6534C8.50287 14.3757 7.46143 15.8502 6.37524 16.7191L7.55464 18.3321C10.4821 16.3804 13.7233 15.0421 16.8585 15.49L17.3162 13.5513C14.6435 12.6604 12.4999 9.98994 12.4999 7.5H10.4999ZM11.0999 13.4716C11.3673 12.8752 11.6042 12.2563 11.8037 11.6285C12.2753 12.3531 12.8553 13.0182 13.5101 13.5953C12.5283 13.7711 11.5665 14.0596 10.6352 14.4276C10.7999 14.1143 10.9551 13.7948 11.0999 13.4716Z"
                                    fill="black"
                                    fill-opacity="0.2"
                                  />
                                  <path
                                    d="M5 4H15V8H19V20H5V4ZM3.9985 2C3.44749 2 3 2.44405 3 2.9918V21.0082C3 21.5447 3.44476 22 3.9934 22H20.0066C20.5551 22 21 21.5489 21 20.9925L20.9997 7L16 2H3.9985ZM10.4999 7.5C10.4999 9.07749 10.0442 10.9373 9.27493 12.6534C8.50287 14.3757 7.46143 15.8502 6.37524 16.7191L7.55464 18.3321C10.4821 16.3804 13.7233 15.0421 16.8585 15.49L17.3162 13.5513C14.6435 12.6604 12.4999 9.98994 12.4999 7.5H10.4999ZM11.0999 13.4716C11.3673 12.8752 11.6042 12.2563 11.8037 11.6285C12.2753 12.3531 12.8553 13.0182 13.5101 13.5953C12.5283 13.7711 11.5665 14.0596 10.6352 14.4276C10.7999 14.1143 10.9551 13.7948 11.0999 13.4716Z"
                                    fill="black"
                                    fill-opacity="0.2"
                                  />
                                  <path
                                    d="M5 4H15V8H19V20H5V4ZM3.9985 2C3.44749 2 3 2.44405 3 2.9918V21.0082C3 21.5447 3.44476 22 3.9934 22H20.0066C20.5551 22 21 21.5489 21 20.9925L20.9997 7L16 2H3.9985ZM10.4999 7.5C10.4999 9.07749 10.0442 10.9373 9.27493 12.6534C8.50287 14.3757 7.46143 15.8502 6.37524 16.7191L7.55464 18.3321C10.4821 16.3804 13.7233 15.0421 16.8585 15.49L17.3162 13.5513C14.6435 12.6604 12.4999 9.98994 12.4999 7.5H10.4999ZM11.0999 13.4716C11.3673 12.8752 11.6042 12.2563 11.8037 11.6285C12.2753 12.3531 12.8553 13.0182 13.5101 13.5953C12.5283 13.7711 11.5665 14.0596 10.6352 14.4276C10.7999 14.1143 10.9551 13.7948 11.0999 13.4716Z"
                                    fill="black"
                                    fill-opacity="0.2"
                                  />
                                </svg>
                                {fileName}
                              </span>
                            </div>
                            <div
                              onClick={handleImportCancel}
                              style={{
                                cursor: "pointer",
                              }}
                            >
                              <svg
                                onClick={() => setfileToUpload(null)}
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                              >
                                <path
                                  d="M8.00045 7.05767L11.3003 3.75781L12.2431 4.70062L8.94325 8.00047L12.2431 11.3003L11.3003 12.2431L8.00045 8.94327L4.70063 12.2431L3.75781 11.3003L7.05765 8.00047L3.75781 4.70062L4.70063 3.75781L8.00045 7.05767Z"
                                  fill="#950F27"
                                />
                              </svg>
                            </div>
                            <Button
                              id="view_cons_file"
                              style={{
                                width: 98,
                              }}
                            >
                              <h2 id="view">View</h2>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <path
                                  d="M16.0052 9.41421L7.39858 18.0208L5.98438 16.6066L14.591 8H7.00519V6H18.0052V17H16.0052V9.41421Z"
                                  fill="#635BFF"
                                />
                              </svg>
                            </Button>
                          </div>
                        </div>
                      ) : (
                        <div className="col-12">
                          <label
                            htmlFor="file-input"
                            className="d-flex align-items-center justify-content-center flex-column"
                            id="upload_pi_file"
                            style={{
                              cursor: "pointer",
                              marginTop: 8,
                            }}
                          >
                            <div id="upload_csv_text">
                              {fileName && fileName}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <g opacity="0.4">
                                  <path
                                    d="M12 12.5858L16.2426 16.8284L14.8284 18.2426L13 16.415V22H11V16.413L9.17157 18.2426L7.75736 16.8284L12 12.5858ZM12 2C15.5934 2 18.5544 4.70761 18.9541 8.19395C21.2858 8.83154 23 10.9656 23 13.5C23 16.3688 20.8036 18.7246 18.0006 18.9776L18 17C18 13.6863 15.3137 11 12 11C8.7616 11 6.12243 13.5656 6.00414 16.7751L6 17L6.00039 18.9776C3.19696 18.7252 1 16.3692 1 13.5C1 10.9656 2.71424 8.83154 5.04648 8.19411C5.44561 4.70761 8.40661 2 12 2Z"
                                    fill="#635BFF"
                                  />
                                </g>
                              </svg>
                            </div>
                          </label>
                          <TextInputWithAll
                            name="file-input"
                            type="file"
                            onChange={handleChange}
                            id="file-input"
                            ref={fileInputRef}
                            style={{
                              display: "none",
                            }}
                            // accept=".csv"
                          />
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div
            className="container"
            style={{
              maxHeight: "fit-content",
              width: "100%",
              marginTop: isLoading ? "110px" : "50px",
            }}
          >
            <DataGrid
              rows={calculatedRows}
              columns={columns}
              hideFooterPagination={true}
              hideFooter={true}
              autoHeight
              rowHeight={80}
              showCellRightBorder
              // getRowClassName={getRowClassName}
              // getCellClassName={getCellClassName}
            />
          </div>
          {optionShow && (
            <div
              className="options-dropdown ellipsisOption"
              style={{
                top: optionPosition.top,
                left: optionPosition.left,
              }}
            >
              <div
                className="option"
                style={{
                  textDecoration: "none",
                  color: "#535260",
                  cursor: "pointer",
                }}
              >
                Pay Supplier
              </div>
            </div>
          )}
          <div
            style={{
              borderBottom: "0.5px solid var(--outlineVariant)",
            }}
          >
            <div className="row">
              <div className="col-6"></div>
              <div className="col-6">
                <div
                  className="d-flex flex-column"
                  style={{
                    gap: 40,
                    borderBottom: "0.5px solid var(--outlineVariant)",
                    marginTop: 100,
                  }}
                >
                  <div className="d-flex flex-row" style={{ gap: 151 }}>
                    <h2 id="total_cost_text">Total Cost in RMB</h2>
                    <p id="total_cost">
                      <FormatCurrency amount={totalTTL_RMB} />
                    </p>
                  </div>
                  <div
                    className="po_input position-relative d-flex align-items-center"
                    style={{ marginBottom: 28 }}
                  >
                    <h2 id="total_cost_text" style={{ width: 164 }}>
                      Convert & Ex.Rate
                    </h2>
                    <DropDownInputWithoutLabel
                      options={currency}
                      value={ccurrencyID}
                      onChange={handleCurrencyChange}
                      id="cust_div"
                      style={{
                        width: 180,
                        margin: 0,
                        padding: "12px 16px",
                        marginLeft: 13,
                      }}
                      placeholder="Select Transportation"
                    />
                    <TextInputWithAll
                      style={{ width: 98, paddingRight: 0, margin: 0 }}
                      className="po_input"
                      value={currencyFactory}
                      onChange={currency_convertor}
                    />
                  </div>
                  <div className="d-flex flex-row" style={{ gap: 151 }}>
                    <h2 id="total_cost_text">Total Cost in {currencyLabel}</h2>
                    <p id="total_cost">
                      {currencySymbol} <USDCurrency amount={usd_value} />
                    </p>
                  </div>
                  <div
                    className="d-flex flex-row align-items-center"
                    style={{ gap: 106 }}
                  >
                    <div
                      className="po_input position-relative d-flex justify-content-center align-items-center"
                      style={{ gap: 32 }}
                    >
                      <h2 id="total_cost_text" style={{ width: 145 }}>
                        Agent Commission
                      </h2>
                      <TextInputWithAll
                        style={{ width: 100, paddingRight: 25 }}
                        value={commission}
                        onChange={(e) => agent_Commission(e.target.value)}
                      />{" "}
                      <div
                        style={{ top: 19, right: "-20px" }}
                        id="remaining_characters"
                        className="position-absolute translate-middle-x "
                      >
                        %
                      </div>
                    </div>
                    {/* <div ><input id="agent_edit_div"/></div> */}
                    <p id="total_cost" style={{ margin: 0 }}>
                      {currencySymbol} <USDCurrency amount={commissionValue} />
                    </p>
                  </div>
                  <div
                    className="po_input position-relative d-flex align-items-center"
                    style={{ marginBottom: 28 }}
                  >
                    <h2 id="total_cost_text" style={{ width: 164 }}>
                      Inland Transportation
                    </h2>
                    <DropDownInputWithoutLabel
                      options={containers}
                      value={containerID}
                      onChange={handleContainerChange}
                      id="cust_div"
                      style={{
                        width: 180,
                        margin: 0,
                        padding: "12px 16px",
                        marginLeft: 13,
                      }}
                      placeholder="Select Transportation"
                    />
                    <input
                      style={{ width: 98, paddingRight: 0, margin: 0 }}
                      className="po_input"
                      value={transportCost}
                      onChange={addTransportCost}
                    />
                  </div>
                </div>

                <div
                  className="d-flex flex-column"
                  style={{
                    gap: 20,
                    margin: "20px 72px 80px 0px",
                  }}
                >
                  <div className="d-flex flex-row" style={{ gap: 151 }}>
                    <h2 id="total_cost_text">Total Value</h2>
                    <h2 id="total_cost" style={{ fontWeight: 600 }}>
                      {currencySymbol}
                      <USDCurrency amount={finalAmount} />
                    </h2>
                  </div>
                  <div
                    className="d-flex flex-row align-items-center"
                    style={{ gap: 13 }}
                  >
                    <h2 id="total_cost_text" style={{ width: 524 }}>
                      SAY "{currencyLabel} {words}" ONLY
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="d-flex flex-column gap-4"
            style={{
              marginTop: 44,
            }}
          >
            <div className="d-flex flex-row align-items-center gap-2">
              <div
                style={{
                  width: 8,
                  height: 44,
                  background: "var(--tertiaryContainer, #BA3CBB)",
                }}
              ></div>
              <h2 id="shipping_details">Delivery Info</h2>
            </div>
            <div className="d-flex flex-row " style={{ gap: "64px" }}>
              <div className="d-flex flex-column gap-2">
                <label>Delivery Date</label>
                <TextInputWithAll
                  type="date"
                  placeholder="Enter Delivery Date"
                  value={deliveryDate}
                  onChange={(e) =>
                    setdeliveryDate(convertToUnixTimestamp(e.target.value))
                  }
                />
              </div>
              <div className="d-flex flex-column gap-2">
                <label>Vessel Name</label>
                <TextInputWithAll
                  type="text"
                  placeholder="Enter Vessel Name"
                  value={vessel_Name}
                  onChange={(e) => setvessel_Name(e.target.value)}
                />
              </div>
            </div>
            <div
              className="d-flex flex-row "
              style={{ gap: "64px", marginBottom: 108 }}
            >
              <div className="d-flex flex-column gap-2">
                <label>Sailing Date</label>
                <TextInputWithAll
                  type="date"
                  value={shippingDate}
                  onChange={(e) =>
                    setshippingDate(convertToUnixTimestamp(e.target.value))
                  }
                  placeholder="Enter Sailing Date"
                />
              </div>
              <DropDownInput
                label="Payment Type"
                id="select_status"
                options={TypeOptions}
                value={paymentName}
                placeholder="Select Type"
                onChange={(e) => setpaymentType(e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>
      <div id="po_footer" className="pi_footer">
        <div id="add_supplier_buttons" className="container">
          {" "}
          <a
            href="/suppliers/supplier-info"
            className="text-decoration-none"
            onClick={handleActiveTab}
          >
            {" "}
            <div id="cancel">Cancel</div>
          </a>
          <div
            className="d-flex align-items-center justify-content-right "
            style={{ gap: "12px" }}
          >
            <a className="text-decoration-none" onClick={handleModify}>
              <div id="submitsupplier">Update Invoice</div>
            </a>
            <ToastContainer
              position="bottom-center"
              style={{ width: "max-content" }}
            />
          </div>
        </div>
      </div>
    </>
  );
}
// href="/suppliers/supplier-info"
export default ModifyInvoice;
