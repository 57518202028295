import React from "react";
import "../../Styles/DropDownUpload.css"
const DropDownInput = ({
  options,
  value,
  onChange,
  label,
  placeholder,
  name,
  id,
  tag
}) => {
  return (
    <div>
      <div>
        <label htmlFor={id}>{label} {tag}</label>
        <br />
        <select id={id} name={name} value={value} onChange={onChange} className="">
          <option value="" disabled>
            {placeholder}
          </option>
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>

        <svg 

          xmlns="http://www.w3.org/2000/svg"
          width="14"
          height="8"
          viewBox="0 0 14 8"
          fill="none"
          className="custom_select"
        >
          <path d="M7.00063 5.1714L11.9504 0.22168L13.3646 1.63589L7.00063 7.9999L0.636719 1.63589L2.05093 0.22168L7.00063 5.1714Z" />
        </svg>
      </div>
    </div>
  );
};

export default DropDownInput;
