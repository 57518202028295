import { Modal } from "react-bootstrap";
import "../../../Styles/PurchaseHistory.css";
import ProductImage from "../../../assets/product_image.png";
import Image from "../../../assets/Product_Package.png"
import { makeStyles } from "@material-ui/styles";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import po_img from "../../../assets/po_img.png";
import { makeApiCall } from "../../../StaticComponents/API";

const useStyles = makeStyles({
  header: {
    "& .MuiDataGrid-columnHeaderTitle": { fontWeight: "bold" },
    "& .MuiDataGrid-menuIcon": { display: "none" },
  },

  row: {
    "& .MuiDataGrid-cell--textLeft": { textAlign: "left !important" },
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "var(--size-140) !important",
  },

  headerId: {
    minWidth: "115px !important",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontSize: "14px",
  },

  headerProductName: {
    minWidth: "185px !important",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontSize: "14px",
  },

  headerSupplier: {
    minWidth: "180px !important",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontSize: "14px",
  },

  headerDate: {
    minWidth: "146px !important",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontSize: "14px",
  },

  headerPrice: {
    minWidth: "93px !important",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontSize: "14px",
  },
});

export default function SupplierPurchaseHistory(props) {

  function convertUnixTimestampToDate(unixTimestamp) {
    const date = new Date(unixTimestamp * 1000);
    const formattedDate = `${String(date.getDate()).padStart(2, '0')}-${String(date.getMonth() + 1).padStart(2, '0')}-${date.getFullYear()}`;
    return formattedDate;
}

  const [pageSize, setPageSize] = useState(10);

  const [PHData, setPHData] = useState({
    xscdata: "",
  });
  const classes = useStyles();

  const columns = [
    {
      field: "id",
      headerName: "Order No",
      width: 113,
      cellClassName: "sticky-column",
      headerClassName: classes.headerId,
    },
    {
      field: "PRODUCT_NAME",
      headerName: "Product Name",
      width: 185,
      editable: false,
      align: "left",
      headerClassName: classes.headerProductName,
    },
    {
      field: "SUPPLIER",
      headerName: "Supplier",
      width: 180,
      editable: false,
      align: "left",
      headerClassName: classes.headerSupplier,
    },
    {
      field: "DATE",
      headerName: "On This Date",
      width: 146,
      editable: false,
      align: "left",
      headerClassName: classes.headerDate,
    },
    {
      field: "PRICE",
      headerName: "Price",
      width: 93,
      editable: false,
      align: "left",
      headerClassName: classes.headerPrice,
    },
  ];

  const picture = () => (
    <div id="po_pic_bg">
      <img src={po_img} />
    </div>
  );

  const copyIcon = () => (
    <svg
      style={{ cursor: "pointer" }}
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 0V2H17.0066C17.5552 2 18 2.44495 18 2.9934V19.0066C18 19.5552 17.5551 20 17.0066 20H0.9934C0.44476 20 0 19.5551 0 19.0066V2.9934C0 2.44476 0.44495 2 0.9934 2H4V0H14ZM4 4H2V18H16V4H14V6H4V4ZM6 14V16H4V14H6ZM6 11V13H4V11H6ZM6 8V10H4V8H6ZM12 2H6V4H12V2Z"
        fill="#635BFF"
      />
    </svg>
  );

  const [rows, setRows] = useState([]);
  let token = localStorage.getItem("token");
  // useEffect(() => {
  // 	makeApiCall({
  // 	  mod: "Supplier",
  // 	  action: "view-purchase-history",
  // 	  payload: {CLIENT_SUPPLIER_ID:133,P_ID:395,CURRENT_PAGE:1,ITEM_PER_PAGE:10},
  // 	  headers: {
  // 		Authorization: `Bearer ${token}`,
  // 	  },
  // 	})
  // 	  .then((response) => {
  // 		const supplierPH = response.XscData.ORDER_DETAILS;
  // 		console.log(supplierPH)
  // 		if (Array.isArray(supplierPH)) {
  // 		  const mappedRows = supplierPH.map((supplier) => ({
  // 			id: supplier.ORDER_NO,
  // 			PRODUCT_NAME: supplier.PRODUCT_NAME,
  // 			SUPPLIER: supplier.SUPPLIER,
  // 			DATE: supplier.ON_THIS_DATE,
  // 			PRICE: supplier.PRICE,

  // 		  }));
  // 		  setRows(mappedRows);
  // 		} else {
  // 		  console.error("suppliers PH data is not an array or is missing");
  // 		}
  // 		setPHData({
  // 			xscdata: response.XscData,
  // 		  });
  // 	  })

  // 	  .catch((error) => console.error("Error fetching data:", error));
  //   }, [token, props.searchQuery]);

  const rowsWithIcons = rows.map((row) => ({
    ...row,
    PICTURE: picture(),
    PH: copyIcon(),
  }));

  const [purchaseHistory, setPurchaseHistory] = useState({
    xscdata: "",
  });

  // <div className="historyContent">
  // 	<div className="div">
  // 		<img src={ProductImage} className="productImage" />
  // 		<hr className="verticalBreaker" />
  // 		<div className="productId">
  // 			<h2 id="id">{PHData.xscdata.SUPP_PROD_ID}</h2>
  // 			<p id="text">Sup.Prod.ID</p>
  // 		</div>
  // 		<hr className="verticalBreaker" />
  // 		<div className="productId">
  // 			<h2 id="id">{PHData.xscdata.MNX_PROD_ID}</h2>
  // 			<p id="text">Mnx.Prod.ID</p>
  // 		</div>
  // 		<hr className="verticalBreaker" />
  // 		<div className="productId">
  // 			<h2 id="id">{"\u00a5 "}{PHData.xscdata.PER_PRICE}</h2>
  // 			<p id="text">Per Piece</p>
  // 		</div>
  // 		<hr className="verticalBreaker" />
  // 		<div className="productId">
  // 			<h2 id="id">{PHData.xscdata.QTY_IN_INVENTORY}</h2>
  // 			<p id="text">Qty. in Inventory</p>
  // 		</div>
  // 	</div>

  // let token = localStorage.getItem("token");
  const API_URL=process.env.REACT_APP_API_URL;
  let CLIENT_SUPPLIER_ID = localStorage.getItem("CLIENT_SUPPLIER_ID");
  let PRODUCT_ID = localStorage.getItem("PRODUCT_ID");
  const [imageURL,setimageURL]=useState(null)
  const getData = () => {
    const payload = {
      P_ID: PRODUCT_ID,
      CLIENT_SUPPLIER_ID: CLIENT_SUPPLIER_ID,
    };
    makeApiCall({
      mod: "Supplier",
      action: "view-purchase-history",
      payload: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((res) => {
      console.log(res, "view-purchase-history");
      setimageURL(res.XscData.PRODUCT_IMAGE)
      const purchaseitems = res.XscData.ORDER_DETAILS;
      if (Array.isArray(purchaseitems)) {
        const mappedRows = purchaseitems.map((itemcounts) => ({
          id: itemcounts.ORDER_NO || "N/A",
          PRODUCT_NAME: itemcounts.PRODUCT_NAME || "N/A",
          SUPPLIER: itemcounts.SUPPLIER || "N/A",
          DATE: convertUnixTimestampToDate(itemcounts.ON_THIS_DATE)  || "N/A",
          PRICE: `${itemcounts.CURRENCY_SYMBOL} ${itemcounts.PRICE}` || 0
        }));
        setRows(mappedRows);
        setPurchaseHistory({
          xscdata: res.XscData,
        });
      } else {
        console.error("Supplier View Purchase History has no Data");
      }
    });
  };

  useEffect(() => {
    getData();
  }, [props.show,CLIENT_SUPPLIER_ID,PRODUCT_ID]);

  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="filter_text"
          >
            View Purchase History
          </Modal.Title>
        </Modal.Header>

        <div className="historyContent">
          <div className="div">
            <img src={imageURL ? `${API_URL}media/${imageURL}`: Image} className="productImage" />
            <hr className="verticalBreaker" />
            <div className="productId">
              <h2 id="id">{purchaseHistory.xscdata?.SUPP_PROD_ID || "N/A"}</h2>
              <p id="text">Sup.Prod.ID</p>
            </div>
            <hr className="verticalBreaker" />
            <div className="productId">
              <h2 id="id">{purchaseHistory.xscdata?.MNX_PROD_ID || "N/A"}</h2>
              <p id="text">Mnx.Prod.ID</p>
            </div>
            <hr className="verticalBreaker" />
            <div className="productId">
              <h2 id="id">¥{purchaseHistory.xscdata?.PER_PRICE || 0}</h2>
              <p id="text">Per Piece</p>
            </div>
            <hr className="verticalBreaker" />
            <div className="productId">
              <h2 id="id">
                {purchaseHistory.xscdata?.QTY_IN_INVENTORY || 0}
              </h2>
              <p id="text">Qty. in Inventory</p>
            </div>
          </div>

          <div
            style={{
              height: 600,
              width: 736,
              marginBottom: "80px",
              alignSelf: "center",
              justifyContent: "center",
            }}
          >
            <DataGrid
              rows={rowsWithIcons}
              columns={columns}
              pageSize={pageSize}
              rowsPerPageOptions={[10, 20, 50]}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              disableSelectionOnClick
              classes={{
                columnHeader: classes.header,
                row: classes.row,
              }}
            />
          </div>
        </div>
      </Modal>
    </>
  );
}
