import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import container_img from "../../assets/container_img.png";
import { makeApiCall } from "../../StaticComponents/API";

function ContainerDetail(props) {

  // Define options arrays
const lengthOptions = [
  { value: "MTR", label: "m" },
  { value: "CM", label: "cm" },
  { value: "IN", label: "in" },
  { value: "FEET", label: "feet" },
  { value: "MM", label: "mm" },
];

const weightOptions = [
  { value: "GM", label: "g" },
  { value: "KG", label: "Kg" },
  { value: "LBS", label: "lbs" },
];

const volumeOptions = [
  { value: "CB_CM", label: "cm³" },
  { value: "CB_MTR", label: "m³" },
  { value: "CB_FEET", label: "feet³" },
  { value: "CB_MM", label: "mm³" },
  { value: "CB_IN", label: "in³" },
];

// Utility function to get label from value
const getLabelFromValue = (value, options) => {
  const option = options.find(option => option.value === value);
  return option ? option.label : "NA";
};

  console.log(props.id, "id");
  const ID = props.id;
  const [containerDetail, setContainerDetail] = useState({
    xscdata: "",
  });

  let APP_CLIENT_ID = localStorage.getItem("CLIENT_ID");

  let token = localStorage.getItem("token");

  const getData = () => {
    const payload = {
      CONTAINER_ID: ID,
      APP_CLIENT_ID: APP_CLIENT_ID
    };
    makeApiCall({
      mod: "Shipping",
      action: "get-container-detail",
      payload: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((res) => {
      console.log(res, "container-detail");
      setContainerDetail({
        xscdata: res.XscData,
      });
    });
  };

  useEffect(() => {
    if (props.show === true) {
      getData();
    }
  }, [props.show]);
// const onImageError = (e)=>{
//   e.target.className = "d-none"
// }

const onImageError = (e) => {
  e.target.style.display = 'none'; // Hide image if error occurs
};

const containerIconUrl = process.env.REACT_APP_API_URL + `/media/${containerDetail.xscdata.CONTAINER_ICON}`;

  return (
    <div>
      <div>
        <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title
              id="contained-modal-title-vcenter"
              className="filter_text"
            >
              Container Detail
            </Modal.Title>
          </Modal.Header>

          <div id="container_data">
          {containerDetail.xscdata.CONTAINER_ICON && (
            <div>
              <img
                src={containerIconUrl}
                alt="Container Icon"
                onError={onImageError}
              />
            </div>
          )}
            <div>
              <div id="container_data_title">
                Alias:{" "}
                <span id="container_data_content">
                  {containerDetail.xscdata.ALIAS_NAME}
                </span>
              </div>
            </div>
            <div>
              <div id="container_data_title">
                Description:{" "}
                <span id="container_data_content">
                  {containerDetail.xscdata.DESCRIPTION}
                </span>
              </div>
            </div>
            <div>
              <div id="container_data_title">
                Length:{" "}
                <span id="container_data_content">
                {containerDetail.xscdata.LENGTH} {getLabelFromValue(containerDetail.xscdata.SIZE_UNIT, lengthOptions)}
                </span>
              </div>
            </div>
            <div>
              <div id="container_data_title">
                Width:{" "}
                <span id="container_data_content">
                {containerDetail.xscdata.WIDTH} {getLabelFromValue(containerDetail.xscdata.SIZE_UNIT, lengthOptions)}
                </span>
              </div>
            </div>
            <div>
              <div id="container_data_title">
                Height:{" "}
                <span id="container_data_content">
                {containerDetail.xscdata.HEIGHT} {getLabelFromValue(containerDetail.xscdata.SIZE_UNIT, lengthOptions)}
                </span>
              </div>
            </div>
            <div>
              <div id="container_data_title">
                Capacity:{" "}
                <span id="container_data_content">
                {containerDetail.xscdata.VOLUME} {getLabelFromValue(containerDetail.xscdata.VOLUME_UNIT, volumeOptions)}
                </span>
              </div>
            </div>
            <div>
              <div id="container_data_title">
                Tare Weight:{" "}
                <span id="container_data_content">
                {containerDetail.xscdata.TARE_WT} {getLabelFromValue(containerDetail.xscdata.WEIGHT_UNIT, weightOptions)}
                </span>
              </div>
            </div>
            <div>
              <div id="container_data_title">
                Max Cargo Weight:{" "}
                <span id="container_data_content">
                {containerDetail.xscdata.MAX_WEIGHT} {getLabelFromValue(containerDetail.xscdata.WEIGHT_UNIT, weightOptions)}
                </span>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
}
export default ContainerDetail;
