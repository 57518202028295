import React, { useState } from "react";
import OrderTopBar from "./OrderTopBar";
import OrdersDashboard from "./OrdersDashboard";

export default function OrderDashboardLayout() {
	const [custOrdersActiveTab, setCustOrdersActiveTab] = useState(
		localStorage.getItem("CustOrdersActiveTab") === ""
		  ? "PI"
		  : localStorage.getItem("CustOrdersActiveTab")
	  );

	const handleTabChange = (tab) => {
		localStorage.setItem("CustOrdersActiveTab", tab);
		setCustOrdersActiveTab(tab);
	};
	return (
		<div>
			<OrderTopBar activeTab={custOrdersActiveTab} onTabChange={handleTabChange} />
			<OrdersDashboard activeTab={custOrdersActiveTab} />
		</div>
	);
}
