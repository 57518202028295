import { useState } from "react";
import { FormCheck, Modal } from "react-bootstrap";
import SearchBar from "../../StaticComponents/SearchBar";
import { useEffect } from "react";
import { makeApiCall } from "../../StaticComponents/API";

export default function SupplierOrderFilter(props) {
  const [currentOrderStage, setCurrentOrderStage] = useState(true);
  const [supplierName, setSupplierName] = useState(false);
  const [orderstages, setOrderstages] = useState([]);
  const [suppliers, setsuppliers] = useState([]);
  const token = localStorage.getItem("token");
  const APP_CLIENT_ID = localStorage.getItem("CLIENT_ID");
  const CLIENT_SUPPLIER_ID = localStorage.getItem("CLIENT_SUPPLIER_ID");

  const handleSupplierName = () => {
    setSupplierName(true);
    setCurrentOrderStage(false);
  };
  const handleCurrentOrderstage = () => {
    setSupplierName(false);
    setCurrentOrderStage(true);
  };

  const [searchkeyword, setsearchkeyword] = useState();
 
  const [supname, setsupname] = useState("");
  const onSearch = (value) => {
    setsearchkeyword(value);
   
  };
  
  const [selectedFilters, setSelectedFilters] = useState({
    currentOrderStage: [],
    supplierName: [],
  });

  const [filtercount, setfiltercount] = useState(0);

  useEffect(() => {
    let count = 0;
    if (selectedFilters.currentOrderStage.length > 0) {
      count += 1;
    }
    if (selectedFilters.supplierName.length > 0) {
      count += 1;
    }
    setfiltercount(count);
     props.onNotify(count);
  }, [selectedFilters]);
  useEffect(() => {
    const payload = {
      APP_CLIENT_ID: APP_CLIENT_ID,
      SEARCH_KEYWORD: searchkeyword,
      CURRENT_PAGE: 1,
    };

    // Replace 'YOUR_API_URL' with the URL of your API
    makeApiCall({
      mod: "Supplier",
      action: "supplier-name-filter",
      payload: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        console.log(
          "Suppliers names filtered successfully",
          response.XscData.SUPPLIER_LIST
        );
        if (Array.isArray(response.XscData.SUPPLIER_LIST)) {
          setsuppliers(response.XscData.SUPPLIER_LIST);
        } else {
          console.error("Unexpected data ", response.XscData.SUPPLIER_LIST);
        }
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, [searchkeyword]);
 
  useEffect(() => {
  const payload= {CLIENT_SUPPLIER_ID:CLIENT_SUPPLIER_ID}

    makeApiCall({
      mod: "Filters",
      action: "current-order-stage_supp",
      payload: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        console.log("current Order Stage filtered successfully", response);
        if (Array.isArray(response.XscData.ORDER_STATUS)) {
          setOrderstages(response.XscData.ORDER_STATUS);
        } else {
          console.error("Unexpected data", response.XscData.ORDER_STATUS);
        }
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, [searchkeyword]);
  console.log(orderstages);
 
 
  const handleCheckboxChange = (filterType, value) => {
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      [filterType]: prevFilters[filterType].includes(value)
        ? prevFilters[filterType].filter((item) => item !== value)
        : [...prevFilters[filterType], value],
    }));
  };
  console.log(selectedFilters);
  const handleRemoveFilter = (filterType, value) => {
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      [filterType]: prevFilters[filterType].filter((item) => item !== value),
    }));
  };
  const handleresetbutton=()=>{
    setSelectedFilters({
      currentOrderStage: [],
    supplierName: [],
    })
  }
  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="filter_text d-flex gap-3"
          >
            Filters{" "}
            {filtercount > 0 ? (
              <div>({filtercount} selected)</div>
            ) : (
              <div></div>
            )}
          </Modal.Title>
        </Modal.Header>

        <div id="selected_fil">
          {selectedFilters["currentOrderStage"].map((stages) => (
            <div id="selected_filters_bg">
              {stages}
              <svg
              
              onClick={()=> handleRemoveFilter("currentOrderStage",stages)}
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  d="M7.99967 14.6654C4.31777 14.6654 1.33301 11.6806 1.33301 7.9987C1.33301 4.3168 4.31777 1.33203 7.99967 1.33203C11.6815 1.33203 14.6663 4.3168 14.6663 7.9987C14.6663 11.6806 11.6815 14.6654 7.99967 14.6654ZM7.99967 7.0559L6.11405 5.17027L5.17125 6.11308L7.05687 7.9987L5.17125 9.8843L6.11405 10.8271L7.99967 8.9415L9.88527 10.8271L10.8281 9.8843L8.94247 7.9987L10.8281 6.11308L9.88527 5.17027L7.99967 7.0559Z"
                  fill="#5D6B82"
                />
              </svg>
            </div>
          ))}
          {selectedFilters["supplierName"].map((names) => (
            <div id="selected_filters_bg">
              {names}
              <svg
               onClick={()=> handleRemoveFilter("supplierName",names)}
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  d="M7.99967 14.6654C4.31777 14.6654 1.33301 11.6806 1.33301 7.9987C1.33301 4.3168 4.31777 1.33203 7.99967 1.33203C11.6815 1.33203 14.6663 4.3168 14.6663 7.9987C14.6663 11.6806 11.6815 14.6654 7.99967 14.6654ZM7.99967 7.0559L6.11405 5.17027L5.17125 6.11308L7.05687 7.9987L5.17125 9.8843L6.11405 10.8271L7.99967 8.9415L9.88527 10.8271L10.8281 9.8843L8.94247 7.9987L10.8281 6.11308L9.88527 5.17027L7.99967 7.0559Z"
                  fill="#5D6B82"
                />
              </svg>
            </div>
          ))}
        </div>
        <div id="filter_content_bg">
          <div id="fil_side_menu_bg">
            <div id="fil_side_menu">
              <div className="position-relative  d-flex align-items-center">
                {currentOrderStage && (
                  <div id="active_fil_indicator" className=" left-0 "></div>
                )}
                <div
                  id="single_fil_menu"
                  onClick={handleCurrentOrderstage}
                  style={{
                    color: currentOrderStage && "var(--primaryContainer)",
                    fontWeight: currentOrderStage && "600",
                    cursor: "pointer",
                  }}
                >
                  Current Order Stage
                </div>
              </div>
            </div>
            <div id="fil_side_menu">
              <div className="position-relative d-flex align-items-center ">
                {supplierName && (
                  <div id="active_fil_indicator" className=""></div>
                )}
                <div
                  id="single_fil_menu"
                  onClick={handleSupplierName}
                  style={{
                    color: supplierName && "var(--primaryContainer)",
                    fontWeight: supplierName && "600",
                    cursor: "pointer",
                  }}
                >
                  Supplier Name
                </div>
              </div>
            </div>
          </div>

          {currentOrderStage && (
            <div id="fil_content_bg">
             {!currentOrderStage &&  <SearchBar onSearch={onSearch} />}
              {Array.isArray(orderstages) && orderstages.length > 0 ? (
                orderstages.map((orderstage) => (
                  <div
                    className="d-flex align-items-center justify-content-center"
                    id="fil_single_item"
                    key={orderstage.STATUS}
                  >
                    <div>
                      <FormCheck
                        type="checkbox"
                        className="checkbox"
                        checked={selectedFilters.currentOrderStage.includes(
                          orderstage.TYPE
                        )}
                        onChange={() =>
                          handleCheckboxChange("currentOrderStage", orderstage.TYPE)
                        }
                      ></FormCheck>
                    </div>
                    <div>{orderstage.TYPE}</div>
                  </div>
                ))
              ) : (
                <div>No state available</div>
              )}
            </div>
          )}

          {supplierName && (
            <div id="fil_content_bg">
              <SearchBar onSearch={onSearch}/>
              {Array.isArray(suppliers) && suppliers.length > 0 ? (
                suppliers.map((supplier) => (
                  <div
                    className="d-flex align-items-center justify-content-center"
                    id="fil_single_item"
                    key={supplier.CLIENT_SUPPLIER_ID}
                  >
                    {" "}
                    <div>
                      {" "}
                      <FormCheck
                        type="checkbox"
                        className="checkbox"
                        checked={selectedFilters.supplierName.includes(
                          supplier.NAME
                        )}
                        onChange={() =>
                          handleCheckboxChange("supplierName", supplier.NAME)
                        }
                      ></FormCheck>
                    </div>
                    <div>{supplier.NAME}</div>
                  </div>
                ))
              ) : (
                <div>No suppliers available</div>
              )}
            </div>
          )}
        </div>

        <Modal.Footer>
          <button id="reset_btn" className="btn" onClick={handleresetbutton}>
            Reset{" "}
          </button>
          <button id="apply_btn" className="btn">
            Apply Filters
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
