import React from "react";
import "../../Styles/SupplierInfo.css";

const OrdersHeader = ({ activeTab, onTabChange }) => {
  return (
    <div>
      <div
        id="suppliers_topbar_bg"
        style={{ paddingLeft: "0", paddingRight: "0" }}
      >
        <div className="container d-flex justify-content-between align-items-center">
          <div id="dashboard_header">Orders</div>
        </div>
      </div>
      <div id="supplier_menu_bg">
        <div className="container">
          <div id="supplier_menus">
            {" "}
            <div
              style={{
                color:
                  activeTab === "CustomersOrders" && "var(--primaryContainer)",
                fontSize: activeTab === "CustomersOrders" && "14px",
                fontWeight: activeTab === "CustomersOrders" && "600",
                cursor: "pointer",
              }}
              id="supplier_header_menu"
              onClick={() => onTabChange("CustomersOrders")}
            >
              Customers Orders
              {activeTab === "CustomersOrders" && (
                <div
                  id="supplier_active_tab"
                  className="position-absolute  bottom-0 start-50 translate-middle-x"
                ></div>
              )}
            </div>
            <div
              style={{
                color:
                  activeTab === "SuppliersOrders" && "var(--primaryContainer)",
                fontSize: activeTab === "SuppliersOrders" && "14px",
                fontWeight: activeTab === "SuppliersOrders" && "600",
                cursor: "pointer",
              }}
              id="supplier_header_menu"
              onClick={() => onTabChange("SuppliersOrders")}
            >
              Suppliers Orders
              {activeTab === "SuppliersOrders" && (
                <div
                  id="supplier_active_tab"
                  className="position-absolute  bottom-0 start-50 translate-middle-x"
                ></div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrdersHeader;
