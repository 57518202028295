import React, { useEffect, useState } from "react";
import Login from "./Components/Login";
import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import ResetPassword from "./Components/ResetPassword";
import NewPassword from "./Components/NewPassword";
import DashboardLayout from "./Components/DashboardLayout";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Suppliers from "./Pages/Suppliers";
import DashboardMenu from "./Components/DashboardMenu";
import Home from "./Pages/Home";
import Customers from "./Pages/Customers";
import Inventory from "./Pages/Inventory";
import Users from "./Pages/User";
import AuthenticateComponent from "./Components/AuthenticateComponents";
import ProductDetails from "./Components/Inventory/ProductDetails";
import AddSupplier from "./Components/Supplier/AddSupplier";
import EditSupplier from "./Components/Supplier/EditSupplier";
import ImportSupplier from "./Components/Supplier/ImportSupplier";
import SupplierInfo from "./Components/Supplier/SupplierInfo";
import SupplierProductPage from "./Components/Supplier/SupplierProductPage";
import AddProduct from "./Components/Supplier/Products/AddProduct";
import SupplierInfoDashboardLayout from "./Components/Supplier/SupplierInfoDashboardLayout";
import ProductInfo from "./Components/Supplier/Products/ProductInfo";
import EditProduct from "./Components/Supplier/Products/EditProduct";
import ImportProducts from "./Components/Supplier/Products/ImportProducts";
import CreatePO from "./Components/Supplier/Orders/CreatePO";
import EditPO from "./Components/Supplier/Orders/EditPO";
import ViewPO from "./Components/Supplier/Orders/ViewPO";
import UploadQuotation from "./Components/Supplier/Orders/UploadQuotations";
import SHDashboardLayout from "./Components/SHDetails/SHDashboardLayout";
import ReportsInfoHeader from "./Components/Reports/ReportsInfoHeader";
import AddCustomer from "./Components/Customers/AddCustomer";
import ImportCustomers from "./Components/Customers/ImportCustomers";
import EditCustomer from "./Components/Customers/EditCustomer";
import CustomerInfo from "./Components/Customers/CustomerInfo";
import CustomerInfoDashboardLayout from "./Components/Customers/CustomerInfoDashboardLayout";
import SearchProducts from "./Components/GlobalSearch/SearchProducts";
import EditContainerDetails from "./Components/SHDetails/EditContainerDetails";
import AddContainer from "./Components/SHDetails/AddContainer";
import AddCarton from "./Components/SHDetails/AddCarton";
import EditCarton from "./Components/SHDetails/EditCarton";
import GlobalOrders from "./Components/GlobalOrderSearch/GlobalOrders";
import OrdersHeader from "./Components/GlobalOrderSearch/OrdersHeader";
import GlobalOrdersDashboardLayout from "./Components/GlobalOrderSearch/GlobalOrdersDashboardLayout";
import CreatePI from "./Components/Customers/Orders/CreatePI";
import CreateShipping from "./Components/Customers/Orders/CreateShipping";
import ModifyShipping from "./Components/Customers/Orders/ModifyShipping.js";
import ViewShipping from "./Components/Customers/Orders/ViewShipping.js";
import ModifyPI from "./Components/Customers/Orders/ModifyPI.js";
import ViewPI from "./Components/Customers/Orders/ViewPI.js";
import CreateInvoice from "./Components/Customers/Orders/CreateInvoice.js";
import ModifyInvoice from "./Components/Customers/Orders/ModifyInvoice.js";
import ViewInvoice from "./Components/Customers/Orders/ViewInvoice.js";
import PreviewShipping from "./Components/Customers/Orders/PreviewShipping.js";
import UploadInvoice from "./Components/Supplier/Orders/UploadInvoice.js";
import ModifySuppliersInvoice from "./Components/Supplier/Orders/ModifyInvoice.js";
import ViewSupplierInvoice from "./Components/Supplier/Orders/ViewSupplierInvoice.js";
import Profile from "./Components/Profile/Profile.js";

import "react-toastify/dist/ReactToastify.css";
import ChangePassword from "./Components/Profile/ChangePassword.js";
import AddProductC from "./Components/Supplier/Products/AddProduct.js";
// import SupplierProductPageC from "./Components/Supplier/SupplierProductPageC.js";
import ScrollToTop from "./StaticComponents/ScrollToTop.js";
import LinkExpire from "./Components/LinkExpire.js";
import ViewShippingHistory from "./Components/Reports/ViewShippingHistory.js";
// import 'react-loading-skeleton/dist/skeleton.css'
function App() {
  const setDarkMode = () => {
    document.querySelector("body").setAttribute("data-theme", "dark");
  };

  const setLightMode = () => {
    document.querySelector("body").setAttribute("data-theme", "light");
  };

  // useEffect(() => {
  //   const handleKeyUp = (event) => {
  //     if (event.key === "d") {
  //       setDarkMode();
  //     } else if (event.key === "l") {
  //       setLightMode();
  //     }
  //   };

  //   const handleKeyDown = (event) => {
  //     if (event.key === "l") {
  //       setLightMode();
  //     } else if (event.key === "d") {
  //       setDarkMode();
  //     }
  //   };
  //   setLightMode();

  //   window.addEventListener("keyup", handleKeyUp);
  //   window.addEventListener("keydown", handleKeyDown);

  //   return () => {
  //     window.removeEventListener("keyup", handleKeyUp);
  //     window.removeEventListener("keydown", handleKeyDown);
  //   };
  // }, []);

  return (
    <div>
      <BrowserRouter>
        <ScrollToTop />

        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/forget-password" element={<ResetPassword />} />
          <Route path="/change-password" element={<NewPassword />} />
          <Route path="/link-expired" element={<LinkExpire />} />
          {/* <Route path="/" element={<DashboardLayout />} /> */}
        </Routes>
        <Routes>
          <Route element={<AuthenticateComponent />}>
            <Route path="/dashboard" element={<Home />} />
            <Route path="/suppliers" element={<Suppliers />} />
            <Route path="/customers" element={<Customers />} />
            <Route path="/inventory" element={<Inventory />} />
            <Route path="/reports" element={<ReportsInfoHeader />} />
            <Route
              path="/view-shipping-history"
              element={<ViewShippingHistory />}
            />
            <Route path="/users" element={<Users />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/changepassword" element={<ChangePassword />} />
            {/* Global search */}
            <Route path="/search-products" element={<SearchProducts />} />
            <Route
              path="/all-orders"
              element={<GlobalOrdersDashboardLayout />}
            />
            <Route
              path="/inventory/product-details/:MNX_PRODUCT_ID"
              element={<ProductDetails />}
            />
            {/* suppliers */}
            <Route path="/suppliers/add-supplier" element={<AddSupplier />} />
            <Route path="/suppliers/edit-supplier" element={<EditSupplier />} />
            <Route
              path="/suppliers/import-supplier"
              element={<ImportSupplier />}
            />
            <Route
              path="/suppliers/supplier-info"
              element={<SupplierInfoDashboardLayout />}
            />
            <Route
              path="/suppliers/supplier-info/supplier-products"
              element={<SupplierProductPage />}
            />
            <Route path="/suppliers/add-product" element={<AddProductC />} />
            <Route
              path="/suppliers/supplier-info/product-info"
              element={<ProductInfo />}
            />
            <Route
              path="/suppliers/supplier-info/product-info/edit-product"
              element={<EditProduct />}
            />
            <Route
              path="/products/import-products"
              element={<ImportProducts />}
            />
            {/* /po */}
            <Route path="/suppliers/create-po" element={<CreatePO />} />
            <Route path="/suppliers/edit-po" element={<EditPO />} />
            <Route path="/suppliers/view-po" element={<ViewPO />} />
        
            {/* qo */}
            <Route path="/suppliers/upload-qo" element={<UploadQuotation />} />
            <Route path="/suppliers/add-product" element={<AddProductC />} />
            {/* orders invoice */}
            <Route
              path="/suppliers/upload-invoice"
              element={<UploadInvoice />}
            />
            <Route
              path="/suppliers/modify-invoice"
              element={<ModifySuppliersInvoice />}
            />
            <Route
              path="/suppliers/view-invoice"
              element={<ViewSupplierInvoice />}
            />
            {/* customer */}
            <Route path="/customers/add-customer" element={<AddCustomer />} />
            <Route
              path="/customers/import-customers"
              element={<ImportCustomers />}
            />
            <Route path="/customers/edit-customer" element={<EditCustomer />} />
            <Route
              path="/customers/customer-info"
              element={<CustomerInfoDashboardLayout />}
            />
            <Route path="/customers/create-pi" element={<CreatePI />} />
            <Route path="/customers/modify-pi" element={<ModifyPI />} />
            <Route path="/customers/view-pi" element={<ViewPI />} />
            <Route
              path="/customers/create-invoice"
              element={<CreateInvoice />}
            />
            <Route
              path="/customers/modify-invoice"
              element={<ModifyInvoice />}
            />
            <Route path="/customers/view-invoice" element={<ViewInvoice />} />
            <Route
              path="/customers/create-shipping"
              element={<CreateShipping />}
            />
            <Route
              path="/customers/modify-shipping"
              element={<ModifyShipping />}
            />
            <Route path="/customers/view-shipping" element={<ViewShipping />} />
            <Route
              path="/customers/preview-shipping"
              element={<PreviewShipping />}
            />{" "}
            {/* sh */}
            <Route path="/shipping" element={<SHDashboardLayout />} />
            <Route path="/suppliers/add-product" element={<AddProductC />} />
            <Route
              path="/shipping/edit-container"
              element={<EditContainerDetails />}
            />
            <Route path="/shipping/add-container" element={<AddContainer />} />
            <Route path="/shipping/edit-carton" element={<EditCarton />} />
            <Route path="/shipping/add-carton" element={<AddCarton />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
