import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { FormCheck } from "react-bootstrap";
import "../../Styles/SupplierFilter.css";
import { makeApiCall } from "../../StaticComponents/API";
import SearchBar from "../../StaticComponents/SearchBar";

export default function InventoryFilter(props) {
  const APP_CLIENT_ID = localStorage.getItem("CLIENT_ID");
  const token = localStorage.getItem("token");
  const [supplierName, setSupplierName] = useState(true);
  const [productName, setProductName] = useState(false);
  const [suppliers, setSuppliers] = useState([]);
  const [products, setproducts] = useState("");
  const [filtercount, setfiltercount] = useState(0);
  const [selectedFilters, setSelectedFilters] = useState({
    supplierName: [],
    productName: ["Screw", "MIRROR", "Charger", "USB Cable"],
  });
  const [searchkeyword, setsearchkeyword] = useState("");
  const onSearch = (value) => {
    setSearchKeyword(value);
  };

  useEffect(() => {
    let count = 0;

    if (selectedFilters.supplierName.length > 0) {
      count += 1;
    }
    // if (selectedFilters.productName.length > 0) {
    //   count += 1;
    // }
    setfiltercount(count);
    props.onNotify(count);
  }, [selectedFilters]);
  useEffect(() => {
    const payload = {
      APP_CLIENT_ID: APP_CLIENT_ID,
      SEARCH_KEYWORD: searchkeyword,
      CURRENT_PAGE: 1,
    };

    makeApiCall({
      mod: "Supplier",
      action: "supplier-name-filter",
      payload: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => {
      console.log(response, "supplier names filtered successfullly");
      setSuppliers(response.XscData.SUPPLIER_LIST);
    });
  }, [searchkeyword]);
  const handleProductName = () => {
    setProductName(true);
    setSupplierName(false);
  };
  const handleSupplierName = () => {
    setProductName(false);
    setSupplierName(true);
  };
  const handleCheckboxChange = (filterType, value) => {
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      [filterType]: prevFilters[filterType].includes(value)
        ? prevFilters[filterType].filter((item) => item !== value)
        : [...prevFilters[filterType], value],
    }));
  };
  const handlereset = () => {
    setSelectedFilters({
      supplierName: [],
      productName: ["Screw", "MIRROR", "Charger", "USB Cable"],
    });
  };
  const [searchKeyword, setSearchKeyword] = useState("");


  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="filter_text d-flex gap-3"
          >
            Filters
            {filtercount > 0 ? (
              <div>({filtercount} selected)</div>
            ) : (
              <div></div>
            )}
          </Modal.Title>
        </Modal.Header>

        <div id="selected_fil">
          {selectedFilters.supplierName.length > 0 &&
            selectedFilters.supplierName.map((filter, index) => (
              <div id="selected_filters_bg">
                {filter}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M7.99967 14.6654C4.31777 14.6654 1.33301 11.6806 1.33301 7.9987C1.33301 4.3168 4.31777 1.33203 7.99967 1.33203C11.6815 1.33203 14.6663 4.3168 14.6663 7.9987C14.6663 11.6806 11.6815 14.6654 7.99967 14.6654ZM7.99967 7.0559L6.11405 5.17027L5.17125 6.11308L7.05687 7.9987L5.17125 9.8843L6.11405 10.8271L7.99967 8.9415L9.88527 10.8271L10.8281 9.8843L8.94247 7.9987L10.8281 6.11308L9.88527 5.17027L7.99967 7.0559Z"
                    fill="#5D6B82"
                  />
                </svg>
              </div>
            ))}
        </div>
        <div id="filter_content_bg">
          <div id="fil_side_menu_bg">
            <div id="fil_side_menu">
              <div className="position-relative d-flex align-items-center">
                {supplierName && (
                  <div id="active_fil_indicator" className=""></div>
                )}
                <div
                  id="single_fil_menu"
                  onClick={handleSupplierName}
                  style={{
                    color: supplierName && "var(--primaryContainer)",
                    fontWeight: supplierName && "600",
                    cursor: "pointer",
                  }}
                >
                  Supplier Name
                </div>
              </div>
            </div>
            {/* <div id="fil_side_menu">
              <div className="position-relative d-flex align-items-center">
                {productName && (
                  <div id="active_fil_indicator" className=""></div>
                )}
                <div
                  id="single_fil_menu"
                  onClick={handleProductName}
                  style={{
                    color: productName && "var(--primaryContainer)",
                    fontWeight: productName && "600",
                    cursor: "pointer",
                  }}
                >
                  Product Namee
                </div>
              </div>
            </div> */}
          </div>

          {/* for supplier name */}
          {supplierName && (
            <div id="fil_content_bg">
              <SearchBar onSearch={onSearch}/>
              {Array.isArray(suppliers) && suppliers.length > 0 ? (
                suppliers.map((supplier) => (
                  <div
                    key={supplier.CLIENT_SUPPLIER_ID}
                    className="d-flex align-items-center justify-content-center"
                    id="fil_single_item"
                  >
                    <FormCheck
                      type="checkbox"
                      className="checkbox"
                      checked={selectedFilters.supplierName.includes(
                        supplier.NAME
                      )}
                      onChange={() =>
                        handleCheckboxChange("supplierName", supplier.NAME)
                      }
                    />
                    <div>{supplier.NAME}</div>
                  </div>
                ))
              ) : (
                <div>No suppliers available</div>
              )}
            </div>
          )}

          {/* for SupplierType */}
          {/* {productName && (
            <div id="fil_content_bg">
              <SearchBar onSearch={onSearch} />

              {Array.isArray(products) && products.length > 0 ? (
                products.map((product) => (
                  <div
                    key={product.CLIENT_SUPPLIER_ID}
                    className="d-flex align-items-center justify-content-center"
                    id="fil_single_item"
                  >
					<label
                  className="checkbox d-flex align-items-center"
                  id="fil_single_item"
                >
                    <FormCheck
                      type="checkbox"
                      className="checkbox"
                      checked={selectedFilters.productName.includes(
                        product.NAME
                      )}
                      onChange={() =>
                        handleCheckboxChange("productName", product.NAME)
                      }
                    />
                    <div>{product.NAME}</div>
					</label>
                  </div>
                ))
              ) : (
                <div>No products available</div>
              )}
            </div>
          )} */}
        </div>

        <Modal.Footer>
          <button id="reset_btn" className="btn" onClick={handlereset}>
            Reset{" "}
          </button>
          <button id="apply_btn" className="btn">
            Apply Filters
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
