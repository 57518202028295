import React, { useEffect, useState } from "react";
import "../Styles/Suppliers.css";
import SearchBar from "../StaticComponents/SearchBar";
import Filter from "../StaticComponents/Filter";
import SupplierTable from "../Components/Supplier/SuppliersTable";
import SupplierFilter from "../Components/Supplier/SupplierFilter";
import { makeApiCall } from "../StaticComponents/API";
import { useNavigate } from "react-router-dom";

function Suppliers() {
  const [modalShow, setModalShow] = React.useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [favoriteCount, setFavoriteCount] = useState();

  let APP_CLIENT_ID = localStorage.getItem("CLIENT_ID");

  let token = localStorage.getItem("token");
let navigate = useNavigate("")
  const handleSearch = (query) => {
    setSearchQuery(query);
  };
  const [notify, setnotify] = useState(0);
  const onNotify = (value) => {
    setnotify(value);
  };
  const [filters,setfilter]=useState([])
  const onValue=(value)=>{
    setfilter(value)
  }
  console.log(notify);
  const [supplierDetails, setSupplierDetails] = React.useState({
    xscdata: "",
  });

  const getData = () => {
    const payload = {
      APP_CLIENT_ID: APP_CLIENT_ID,
    };

    makeApiCall({
      mod: "Supplier",
      action: "list-supplier",
      payload: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      }
    }).then((res) => {
      console.log(res, "supplier-info");
      setSupplierDetails({
        xscdata: res.XscData,
      });
      setFavoriteCount(res.XscData.FAVOURITE_SUPPLIER);
    });
  };

  useEffect(() => {
    getData();
  }, []);

  const updateFavoriteCount = (newCount) => {
    setFavoriteCount(newCount);
  };

  return (
    <div id="supp_bg">
      <div
        id="suppliers_topbar_bg"
        style={{ paddingLeft: "0", paddingRight: "0" }}
      >
        <div className="container d-flex justify-content-between align-items-center">
          <div id="dashboard_header">Suppliers</div>
          <div id="supplier_header_buttons">
            <a href="/suppliers/import-supplier">
              <button className="btn" id="import_supplier_button">
                Import Suppliers
              </button>
            </a>

            <a href="/suppliers/add-supplier" className="text-decoration-none">
              {" "}
              <button className="btn" id="add_supplier_button">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M11 11V5H13V11H19V13H13V19H11V13H5V11H11Z"
                    fill="#F7F7F7"
                  />
                </svg>
                Add Supplier
              </button>
            </a>
          </div>
        </div>
      </div>
      <div className="container">
        {" "}
        <div className="row" id="sup_overview_heading">
          <div className="col-md-4">
            <div id="supp_title">
              Total Suppliers
              <div id="suppliers_count">
                {supplierDetails.xscdata.TOTAL_SUPPLIERS}
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div id="supp_title">
              Regular Suppliers
              <div id="suppliers_count">
                {supplierDetails.xscdata.REGULAR_SUPPLIER}
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div id="supp_title">
              Favourite Suppliers
              <div id="suppliers_count">{favoriteCount}</div>
            </div>
          </div>
        </div>
        <div className="d-flex" id="supp_filters">
          <div>
            <SearchBar onSearch={handleSearch} />
          </div>
          <div onClick={() => setModalShow(true)} style={{ cursor: "pointer" }}>
            <Filter notify={notify} />
          </div>
          <SupplierFilter
            show={modalShow}
            onHide={() => setModalShow(false)}
            onNotify={onNotify}
            onValue={onValue}
          />
        </div>
        <div id="supp_tab_bg">
          <SupplierTable
            searchQuery={searchQuery}
            notify={notify}
            onFavoriteCountUpdate={updateFavoriteCount}
            filters={filters}
          />
        </div>
      </div>
    </div>
  );
}

export default Suppliers;
