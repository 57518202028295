import { Modal } from "react-bootstrap";
import "../../Styles/PurchaseHistory.css";
import ProductImage from "../../assets/product_image.png";
import { makeStyles } from "@material-ui/styles";
import { DataGrid } from "@mui/x-data-grid";
import { useState, useEffect } from "react";
import { makeApiCall } from "../../StaticComponents/API";
import po_img from "../../assets/po_img.png";
import nodataimage from "../../assets/no_data_image_3.png";

const useStyles = makeStyles({
  header: {
    "& .MuiDataGrid-columnHeaderTitle": { fontWeight: "bold" },
    "& .MuiDataGrid-menuIcon": { display: "none" },
  },

  row: {
    "& .MuiDataGrid-cell--textLeft": { textAlign: "center !important" },
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "var(--size-140) !important",
  },

  headerId: {
    minWidth: "115px !important",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontSize: "14px",
  },

  headerProductName: {
    minWidth: "185px !important",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontSize: "14px",
  },

  headerSupplier: {
    minWidth: "180px !important",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontSize: "14px",
  },

  headerDate: {
    minWidth: "146px !important",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontSize: "14px",
  },

  headerPrice: {
    minWidth: "93px !important",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontSize: "14px",
  },
});

export default function ReportPurchaseHistory(props) {
  const [pageSize, setPageSize] = useState(10);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);

  // const [pageSize, setPageSize] = React.useState(10);
  const API_URL = process.env.REACT_APP_API_URL;
  const [image, setImage] = useState(0);

  const classes = useStyles();
  const [page, setPage] = useState(0);

  const [purchaseHistory, setPurchaseHistory] = useState({
    xscdata: "",
    xscdataCurrency: "",
  });

  const onImageErr = (e) => {
    e.target.src = po_img;
  };

  const Image_URL = purchaseHistory.xscdata.PRODUCT_IMAGE
    ? `${API_URL}/media/${purchaseHistory.xscdata.PRODUCT_IMAGE}`
    : po_img;

  const handlepagechange = (newpage) => {
    setPage(newpage);
  };
  const handlepagesizechange = (num) => {
    setPageSize(num);
    setPage(0);
  };

  const columns = [
    {
      field: "id",
      headerName: "Order No",
      width: 113,
      cellClassName: "sticky-column",
      headerClassName: classes.headerId,
    },
    {
      field: "PRODUCT_NAME",
      headerName: "Product Name",
      width: 185,
      editable: false,
      align: "left",
      headerClassName: classes.headerProductName,
    },
    {
      field: "SUPPLIER",
      headerName: "Supplier",
      width: 180,
      editable: false,
      align: "left",
      headerClassName: classes.headerSupplier,
    },
    {
      field: "DATE",
      headerName: "On This Date",
      width: 146,
      editable: false,
      align: "left",
      headerClassName: classes.headerDate,
    },
    {
      field: "PRICE",
      headerName: "Price",
      width: 93,
      editable: false,
      align: "left",
      headerClassName: classes.headerPrice,
    },
  ];

  const copyIcon = () => (
    <svg
      style={{ cursor: "pointer" }}
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 0V2H17.0066C17.5552 2 18 2.44495 18 2.9934V19.0066C18 19.5552 17.5551 20 17.0066 20H0.9934C0.44476 20 0 19.5551 0 19.0066V2.9934C0 2.44476 0.44495 2 0.9934 2H4V0H14ZM4 4H2V18H16V4H14V6H4V4ZM6 14V16H4V14H6ZM6 11V13H4V11H6ZM6 8V10H4V8H6ZM12 2H6V4H12V2Z"
        fill="#635BFF"
      />
    </svg>
  );

  const initialRows = [
    {
      id: "I091",
      PRODUCT_NAME: "12PCS MIRROR FASTE...",
      SUPPLIER: "BNS Clothes Service",
      DATE: "16-July-2024",
      PRICE: "\u00a5 1.20",
    },
  ];

  
  function convertUnixTimestampToDate(unixTimestamp) {
    const date = new Date(unixTimestamp * 1000);
    const formattedDate = `${String(date.getDate()).padStart(2, '0')}-${String(date.getMonth() + 1).padStart(2, '0')}-${date.getFullYear()}`;
    return formattedDate;
}

  const [rows, setRows] = useState(initialRows);

  const rowsWithIcons = rows.map((row) => ({
    ...row,
    PH: copyIcon(),
  }));

  let CLIENT_SUPPLIER_ID = localStorage.getItem("CLIENT_SUPPLIER_ID");

  let token = localStorage.getItem("token");
  const getData = () => {
    const payload = {
      P_ID: localStorage.getItem("PRODUCT_ID"),
      CLIENT_SUPPLIER_ID: CLIENT_SUPPLIER_ID,
      CURRENT_PAGE: page + 1,
      ITEM_PER_PAGE: pageSize,
    };
    makeApiCall({
      mod: "Supplier",
      action: "view-purchase-history",
      payload: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((res) => {
      console.log(res, "view-purchase-history");
      const purchaseitems = res.XscData.ORDER_DETAILS;
      if (Array.isArray(purchaseitems)) {
        const mappedRows = purchaseitems.map((itemcounts) => ({
          id: itemcounts.ORDER_NO,
          PRODUCT_NAME: itemcounts.PRODUCT_NAME,
          SUPPLIER: itemcounts.SUPPLIER,
          DATE: convertUnixTimestampToDate(itemcounts.ON_THIS_DATE) || "N/A",
          PRICE:`${itemcounts.CURRENCY_SYMBOL} ${itemcounts.PRICE}`,
       
        }));
        setRows(mappedRows);
        setPurchaseHistory({
          xscdata: res.XscData,
          xscdataCurrency: res.XscData.CURRENCY_SYMBOL,
        });
      } else {
        console.error("Supplier View Purchase History has no Data");
      }
    });
  };

  console.log(props.productId, props.clientSupplierId);

  useEffect(() => {
    getData();
  }, [props.productId, props.clientSupplierId]);

  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="filter_text"
          >
            View Purchase History
          </Modal.Title>
        </Modal.Header>
        <div className="historyContent">
          <div className="div">
            <img
              src={Image_URL}
              className="productImage"
              onError={onImageErr}
            />
            <hr className="verticalBreaker" />

            <div className="productId">
              <h2 id="id">{purchaseHistory.xscdata.SUPP_PROD_ID}</h2>
              <p id="text">Sup.Prod.ID</p>
            </div>
            <hr className="verticalBreaker" />

            <div className="productId">
              <h2 id="id">{purchaseHistory.xscdata.MNX_PROD_ID}</h2>
              <p id="text">Mnx.Prod.ID</p>
            </div>
            <hr className="verticalBreaker" />

            <div className="productId">
              <h2 id="id">
              ¥{purchaseHistory.xscdata.PER_PRICE}
                {purchaseHistory.xscdataCurrency}
              </h2>
              <p id="text">Per Piece</p>
            </div>

            <hr className="verticalBreaker" />

            <div className="productId">
              <h2 id="id">{purchaseHistory.xscdata.QTY_IN_INVENTORY}</h2>
              <p id="text">Qty. in Inventory</p>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              height: 600,
              width: 736,
              marginBottom: "80px",
              alignSelf: "center",
              alignItems: "center",
              justifyContent: "center",
            }}
            className={classes.modalContent}
          >
            <DataGrid
              localeText={{
                noRowsLabel: (
                  <div className="no_rows_css">
                    <img src={nodataimage} alt="" />
                    <div id="no_data_found_text">
                      You don’t have Purchase History for this product
                    </div>
                  </div>
                ),
              }}
              rows={rows}
              columns={columns}
              pageSize={pageSize}
              pagination
              paginationMode="server"
              rowCount={total}
              rowsPerPageOptions={
                rows.length < 50
                  ? [10, 20, 50]
                  : rows.length > 20
                  ? [10, 20, rows.length]
                  : rows.length > 10
                  ? [10, rows.length]
                  : [rows.length]
              }
              onPageChange={handlepagechange}
              onPageSizeChange={handlepagesizechange}
              loading={loading}
              disableSelectionOnClick
              classes={{
                columnHeader: classes.header,
                row: classes.row,
              }}
              className={classes.dataGrid}
            />
          </div>
        </div>
      </Modal>
    </>
  );
}
