import React, { useRef, useState, useEffect } from "react";
import logo from "../../../assets/Manex Logo.png";
import { DataGrid } from "@mui/x-data-grid";
import { Button } from "react-bootstrap";
import "../../../Styles/CreatePI.css";
import SearchShipping from "./SearchShipping.js";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { makeStyles } from "@material-ui/styles";
import "../../../Styles/CreateShipping.css";
import { CircleSpinner } from "react-spinners-kit";
import TextInput from "../../Inputs/TextInput.js";
import TextInputWithAll from "../../Inputs/TextInputWithAll.js";
import DropDownInput from "../../Inputs/DropDownInput.js";
import { makeApiCall } from "../../../StaticComponents/API.js";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useLocation } from "react-router-dom";
import TimestampToDate from "../../../StaticComponents/DateFormat.js";

const useStyles = makeStyles({
  subheadingRow: {
    background: "var(--SurfaceContainerLow, #F5F2FF)",
    textAlign: "center",
    fontWeight: "bold",
    minHeight: "32px !important",
    maxHeight: "32px !important",
    "& .MuiDataGrid-cell": {
      minHeight: "32px !important",
      maxHeight: "32px !important",
      borderRight: "none !important",
      borderBottom: "none !important",
      minWidth: "1000px !important",
      maxWidth: "1000px !important",
    },
  },

  totalRow: {
    textAlign: "center",
    fontWeight: "bold",
    minHeight: "32px !important",
    maxHeight: "32px !important",
    "& .MuiDataGrid-cell": {
      border: "none !important",
      lineHeight: "32px !important",
      minHeight: "32px !important",
      maxHeight: "32px !important",
    },
  },

  subheadingCell: {
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    paddingLeft: 10,
    width: "100%",
    border: "0.5px solid var(--outlineVariant) !important",
    lineHeight: "24px !important",
    minHeight: "32px !important",
    maxHeight: "32px !important",
  },

  padding: { padding: "0px !important" },
});

export default function ModifyShipping() {
  const APP_CLIENT_ID = localStorage.getItem("CLIENT_ID");
  //const CLIENT_CUST_ID=localStorage.getItem("CLIENT_CUST_ID")
  const [upload, setupload] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [searchShow, setSearchShow] = useState(false);
  const [file, setFile] = useState();
  const [fileName, setFileName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const fileInputRef = useRef(null);
  const uploadTimeoutRef = useRef(null);
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const [containers, setcontainers] = useState([]);
  const [delivery, setDelivery] = useState([]);
  const [shippingDate, setshippingDate] = useState("");
  const [deliveryDate, setdeliveryDate] = useState("");
  const [shippingItems, setShippingItems] = useState([]);
  // const [clientData, setClientData] = useState(null);
  // const [customerData, setCustomerData] = useState(null);
  const [invoiceData, setInvoiceData] = useState([]);
  const [packageInfo, setPackageInfo] = useState([]);
  const [CONTAINER_NUM, setCONTAINER_NUM] = useState("");
  const [sealnumber, setsealnumber] = useState("");
  const [vesslname, setvesselname] = useState("");
  const [managerStatus, setManagerStatus] = useState("");
  const [paymenttype, setpaymenttype] = useState("");
  const [deliveryStatus, setdeliveryStatus] = useState("");
  const [SHIPPING_COMPANY_NAME, setSHIPPING_COMPANY_NAME] = useState("");
  const [shippingDetails, setShippingDetails] = useState({
    SHIPPING_COMPANY_NAME: "",
    CONTAINER_NUM: "",
    SEAL_NUMBER: "",
    VESSEL_NAME: "",
    DELIVERY_DATE: "",
    PAYMENT_TYPE: "",
    DELIVERY_STATUS: "",
    SHIPPING_MARK: "",
  });
  const [shippingMarks, setShippingMarks] = useState("");
  const [clientData, setClientData] = useState({
    xscdata: "",
  });
  const [custData, setCustData] = useState({
    xscdata: "",
  });

  function convertToUnixTimestamp(dateString) {
    const date = new Date(dateString);
    const unixTimestamp = Math.floor(date.getTime() / 1000);
    return unixTimestamp;
  }
  function convertUnixTimestampToDate(unixTimestamp) {
    const date = new Date(unixTimestamp * 1000);
    const formattedDate = `${date.getFullYear()}-${String(
      date.getMonth() + 1
    ).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")}`;
    return formattedDate;
  }

  const SHIPMENT_ID = localStorage.getItem("SHIPMENT_ID");

  const classes = useStyles();
  const columns = [
    {
      field: "MNX_ID",
      headerName: "Mnx. Item ID",
      width: 148,
      renderCell: (params) => {
        return params.row.SUBHEADING ? <div>{params.value}</div> : null;
      },
    },
    {
      field: "CUST_ITEM_ID",
      headerName: "Cust. Item ID",
      width: 148,
    },
    {
      field: "DESCRIPTION",
      headerName: "Description",
      width: 275,
    },
    {
      field: "CTN",
      headerName: "CTN",
      width: 148,
      type: "number",
      align: "left",
      renderCell: (params) => {
        return params.row.id_desc === "Total" ? (
          <div className={classes.subheadingCell}>{params.value}</div>
        ) : null;
      },
    },
    {
      field: "INN",
      headerName: "INN",
      width: 148,
    },
    {
      field: "CTN_QTY",
      headerName: "CTN/QTY",
      width: 275,
      cellClassName: "po_input",
    //   renderCell: (params) => {
    //     if (managerStatus === null) {
    //       return !params.row.SUBHEADING ? (
    //         params.row.id_desc === "Total" ? null : (
    //           <TextInput
    //             type="number"
    //             value={params.row.CTN_QTY || ""} // Ensure a valid initial value
    //             onChange={(e) =>
    //               handleInputCtnChange(params.row.id, e.target.value)
    //             }
    //           />
    //         )
    //       ) : null;
    //     }
    //   },
    },
    {
      field: "TOTAL_QTY",
      headerName: "Total QTY",
      width: 125,
      renderCell: (params) => {
        return params.row.id_desc === "Total" ? (
          <div className={classes.subheadingCell}>{params.value}</div>
        ) : null;
      },
    },
    {
      field: "CBM_CTN",
      headerName: "CBM/CTN",
      width: 148,
      cellClassName: "po_input",
      renderCell: (params) => {
        if (managerStatus === null) {
          return !params.row.SUBHEADING ? (
            params.row.id_desc === "Total" ? null : (
              <TextInput
                type="text"
                value={params.value || ""} // Ensure the value is valid
                onChange={(e) => handleInputChange(e, params.row.id, "CBM_CTN")}
              />
            )
          ) : null;
        }

        // If managerStatus is 1, return null or something else
        return null;
      },
    },
    {
      field: "TTL_CBM",
      headerName: "TTL CBM",
      width: 130,
      renderCell: (params) => {
        return params.row.id_desc === "Total" ? (
          <div className={classes.subheadingCell}>{params.value}</div>
        ) : null;
      },
    },
    {
      field: "CBM_PCS",
      headerName: "CBM/PCS",
      width: 148,
      cellClassName: "po_input",
    },
    {
      field: "NG_KG",
      headerName: "N.G/K.G",
      width: 148,
      cellClassName: "po_input",
      renderCell: (params) => {
        if (managerStatus === null) {
          return !params.row.SUBHEADING ? (
            params.row.id_desc === "Total" ? null : (
              <TextInput
                type="number"
                value={params.row.NG_KG || ""} // Fallback to an empty string if undefined
                onChange={(e) =>
                  handleInputNWChange(params.row.id, e.target.value)
                }
              />
            )
          ) : null;
        }

        // If managerStatus is 1, return null (or something else if needed)
        return null;
      },
    },
    {
      field: "GW_KG",
      headerName: "G.W/K.G",
      width: 148,
      cellClassName: "po_input",
      renderCell: (params) => {
        if (managerStatus === null) {
          return !params.row.SUBHEADING ? (
            params.row.id_desc === "Total" ? null : (
              <TextInput
                type="text"
                value={params.row.GW_KG}
                onChange={(e) =>
                  handleInputGWChange(params.row.id, e.target.value)
                }
              />
            )
          ) : null;
        }
        return null;
      },
    },
    {
      field: "TOTAL_NW",
      headerName: "Total N.W",
      width: 148,
      renderCell: (params) => {
        return params.row.id_desc === "Total" ? (
          <div className={classes.subheadingCell}>{params.value}</div>
        ) : (
          <div>{params.value}</div>
        );
      },
    },

    {
      field: "NW_PCS",
      headerName: "N.W/PCS",
      width: 148,
      cellClassName: "po_input",
      renderCell: (params, e) => {
        const rowData = rows.find((row) => row.id === params.row.id);
        if (managerStatus === null) {
          return !params.row.SUBHEADING ? (
            params.row.id_desc === "Total" ? null : (
              <TextInput
                type="text"
                tabIndex="0"
                value={rowData[params.field] || ""} // Get value directly from state
                onChange={(e) =>
                  handleInputChange(e, params.row.id, params.field)
                }
              />
            )
          ) : null;
        }
      },
    },
    {
      field: "TOTAL_GW",
      headerName: "Total G.W",
      width: 148,
      renderCell: (params) => {
        return params.row.id_desc === "Total" ? (
          <div className={classes.subheadingCell}>{params.value}</div>
        ) : null;
      },
    },
    {
      field: "GW_PCS",
      headerName: "G.W/PCS",
      width: 148,
      cellClassName: "po_input",
      renderCell: (params, e) => {
        const rowData = rows.find((row) => row.id === params.row.id);
        if (managerStatus === null) {
          return !params.row.SUBHEADING ? (
            params.row.id_desc === "Total" ? null : (
              <TextInput
                type="text"
                tabIndex="0"
                value={rowData[params.field] || ""} // Get value directly from state
                onChange={(e) =>
                  handleInputChange(e, params.row.id, params.field)
                }
              />
            )
          ) : null;
        }
      },
    },
  ];

  const initialRows = [];
  const [selectedIds, setSelectedIds] = useState([]);

  const handleSelectedIds = (ids) => {
    setSelectedIds(ids);
    console.log(ids);
    setSearchShow(false);
    getTableData(ids);
  };

  useEffect(() => {
    console.log(selectedIds, "selectedIds");
  }, [selectedIds]);

  const [tableData, setTableData] = useState({
    xscdata: "",
  });

  const CLIENT_CUST_ID = localStorage.getItem("CLIENT_CUST_ID");

  const getTableData = (ids) => {
    const payload = {
      CLIENT_CUST_ID: CLIENT_CUST_ID,
      LIST_INVOICE_ID: [1],
    };

    makeApiCall({
      mod: "Shipping",
      action: "get_invoice_detail_for_shipping",
      payload: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((res) => {
      console.log(payload);
      console.log(res, "get_invoice_detail_for_shipping");
      const shippingItems2 = res.XscData.INVOICE_DATA;
      console.log(shippingItems2);
      if (Array.isArray(shippingItems2)) {
        const mappedRows = shippingItems2.flatMap((invoice) => {
          // Add the subheading row for the invoice
          const invoiceRow = {
            SUBHEADING: true,
            id: invoice.CUST_INV_ID,
            MNX_ID: `Invoice no: ${invoice.CUST_INV_NUM}`,
            CUST_ITEM_ID: "",
            DESCRIPTION: "",
            CTN: "",
            INN: "",
            CTN_QTY: "",
            TOTAL_QTY: "",
            CBM_CTN: "",
            TTL_CBM: "",
            CBM_PCS: "",
            NG_KG: "",
            GW_KG: "",
            TOTAL_NW: "",
            NW_PCS: "",
            TOTAL_GW: "",
            GW_PCS: "",
          };

          // Map items under this invoice
          const itemsRows = invoice.ITEMS_LIST.map((item) => {
            return {
              SUBHEADING: false,
              id: `${invoice.CUST_INV_ID}-${item.CLIENT_ITEM_NUM}`, // Unique ID for item
              MNX_ID: item.CLIENT_ITEM_NUM ?? "N/A",
              CUST_ITEM_ID: item.CUST_ITEM_NUM ?? "N/A",
              DESCRIPTION: item.DESCRIPTION ?? "N/A",
              CTN: item.TOTAL_CTN ?? "N/A",
              INN: item.INNER ?? "N/A",
              CTN_QTY: item.QTY_PER_CTN ?? "N/A",
              TOTAL_QTY:
                item.QTY_PER_CTN && item.TOTAL_CTN
                  ? item.QTY_PER_CTN * item.TOTAL_CTN
                  : "N/A",
              CBM_CTN: item.CBM_PER_CTN ?? "N/A",
              TTL_CBM: item.CBM_PER_CTN ?? "N/A",
              CBM_PCS: item.CLIENT_ITEM_NUM ?? "N/A",
              NG_KG: item.NG_KG ?? "N/A",
              GW_KG: item.GW_KG ?? "N/A",
              TOTAL_NW:
                item.NG_KG && item.TOTAL_CTN
                  ? item.NG_KG * item.TOTAL_CTN
                  : "N/A",
              NW_PCS: item.NG_KG ?? "N/A",
              TOTAL_GW:
                item.GW_KG && item.TOTAL_CTN
                  ? item.GW_KG * item.TOTAL_CTN
                  : "N/A",
              GW_PCS: item.GW_KG ?? "N/A",
            };
          });

          // Return both the invoice row and its items
          return [invoiceRow, ...itemsRows];
        });
        const lastInvoiceItems =
          shippingItems2[shippingItems2.length - 1]?.ITEMS_LIST || [];
        const totalRow = {
          SUBHEADING: false,
          id: "total-row",
          id_desc: "Total",
          MNX_ID: "",
          CUST_ITEM_ID: "",
          DESCRIPTION: "",
          CTN: lastInvoiceItems.reduce(
            (sum, item) => sum + (item.TOTAL_CTN || 0),
            0
          ), // sum CTN
          INN: "",
          PACKING: "",
          TOTAL_QTY: lastInvoiceItems
            .reduce(
              (sum, item) => sum + (item.QTY_PER_CTN * item.TOTAL_CTN || 0),
              0
            )
            .toFixed(2), // sum TOTAL_QTY
          CBM_CTN: "",
          TTL_CBM: lastInvoiceItems
            .reduce((sum, item) => sum + (item.CBM_PER_CTN || 0), 0)
            .toFixed(2), // sum TTL_CBM
          CBM_PCS: "",
          NG_KG: "",
          GW_KG: "",
          TOTAL_NW: lastInvoiceItems
            .reduce((sum, item) => sum + (item.NG_KG * item.TOTAL_CTN || 0), 0)
            .toFixed(2), // sum TOTAL_NW
          NW_PCS: "",
          TOTAL_GW: lastInvoiceItems
            .reduce((sum, item) => sum + (item.GW_KG * item.TOTAL_CTN || 0), 0)
            .toFixed(2), // sum TOTAL_GW
          GW_PCS: "",
        };

        // Add the total row to the end of mappedRows
        mappedRows.push(totalRow);

        setRows(mappedRows);

        setTableData({
          xscdata: res.XscData.INVOICE_DATA,
        });
        setShippingItems(res.XscData.INVOICE_DATA);
      } else {
        console.error("Client, Customer and Invoice has no data");
      }
    });
  };
  console.log(tableData);

  useEffect(() => {
    getTableData();
  }, []);

  const getInitialData = () => {
    const payload = {
      CLIENT_CUST_ID: CLIENT_CUST_ID,
      SHIPMENT_ID: SHIPMENT_ID,
    };

    makeApiCall({
      mod: "Shipping",
      action: "view_packaging",
      payload: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((res) => {
      console.log(res, "customer-reports");
      setInvoiceData(res.XscData.INVOICE_DATA);
      const shippingItems2 = res.XscData.INVOICE_DATA;
      const shipping_details = res.XscData.SHIPPING_DETAILS;
      setSelectedContainer(shipping_details.CONTAINER_ID);
      setCONTAINER_NUM(shipping_details.CONTAINER_NUM);
      setsealnumber(shipping_details.SEAL_NUMBER);
      setvesselname(shipping_details.VESSEL_NAME);
      setpaymenttype(shipping_details.PAYMENT_TYPE);
      setshippingDate(shipping_details.SAILING_DATE);
      setdeliveryDate(shipping_details.EXPECTED_DELIVERY_DATE);
      setdeliveryStatus(shipping_details.DELIVERY_ID);
      setSHIPPING_COMPANY_NAME(shipping_details.SHIPPING_COMPANY_NAME);
      setManagerStatus(localStorage.getItem("SHIPPING_STATUS"));
      if (Array.isArray(shippingItems2)) {
        const mappedRows = shippingItems2.flatMap((invoice) => {
          const invoiceRow = {
            SUBHEADING: true,
            id: invoice.CUST_INV_ID,
            MNX_ID: `Invoice no: ${invoice.CUST_INV_NUM}`,
            CUST_ITEM_ID: "",
            DESCRIPTION: "",
            CTN: "",
            INN: "",
            CTN_QTY: "",
            TOTAL_QTY: "",
            CBM_CTN: "",
            TTL_CBM: "",
            CBM_PCS: "",
            NG_KG: "",
            GW_KG: "",
            TOTAL_NW: "",
            NW_PCS: "",
            TOTAL_GW: "",
            GW_PCS: "",
          };

          const itemsRows = invoice.PRODUCT_DETAILS.map((item) => {
            return {
              SUBHEADING: false,
              id: `${invoice.CUST_INV_ID}-${item.CLIENT_ITEM_NUM}`,
              MNX_ID: item.CLIENT_ITEM_NUM ?? "N/A",
              CUST_ITEM_ID: item.CUST_ITEM_NUM ?? "N/A",
              DESCRIPTION: item.DESCRIPTION ?? "N/A",
              CTN: item.TOTAL_CTN ?? "N/A",
              INN: item.P_INNER ?? "N/A",
              CTN_QTY: item.QTY_PER_CTN ?? "N/A",
              TOTAL_QTY:
                item.QTY_PER_CTN && item.TOTAL_CTN
                  ? item.QTY_PER_CTN * item.TOTAL_CTN
                  : "N/A",
              CBM_CTN: item.CBM_PER_CTN ?? "N/A",
              TTL_CBM: item.CBM_PER_CTN * item.TOTAL_CTN ?? "N/A",
              CBM_PCS: item.CLIENT_ITEM_NUM ?? "N/A",
              NG_KG: item.NG_KG ?? "N/A",
              GW_KG: item.GW_KG ?? "N/A",
              TOTAL_NW:
                item.NG_KG && item.TOTAL_CTN
                  ? item.NG_KG * item.TOTAL_CTN
                  : "N/A",
              NW_PCS:
                item.QTY_PER_CTN && item.NG_KG
                  ? (item.NG_KG / item.QTY_PER_CTN).toFixed(2)
                  : "N/A",
              TOTAL_GW:
                item.GW_KG && item.TOTAL_CTN
                  ? item.GW_KG * item.TOTAL_CTN
                  : "N/A",
              GW_PCS:
                item.QTY_PER_CTN && item.GW_KG
                  ? (item.GW_KG / item.QTY_PER_CTN).toFixed(2)
                  : "N/A",
            };
          });

          return [invoiceRow, ...itemsRows];
        });
        const lastInvoiceItems =
          shippingItems2[shippingItems2.length - 1]?.PRODUCT_DETAILS || [];
        const totalRow = {
          SUBHEADING: false,
          id: "total-row",
          id_desc: "Total",
          MNX_ID: "",
          CUST_ITEM_ID: "",
          DESCRIPTION: "",
          CTN: lastInvoiceItems.reduce(
            (sum, item) => sum + (item.TOTAL_CTN || 0),
            0
          ), // sum CTN
          INN: "",

          TOTAL_QTY: lastInvoiceItems
            .reduce(
              (sum, item) => sum + (item.QTY_PER_CTN * item.TOTAL_CTN || 0),
              0
            )
            .toFixed(2), // sum TOTAL_QTY
          CBM_CTN: "",
          TTL_CBM: lastInvoiceItems
            .reduce(
              (sum, item) => sum + (item.CBM_PER_CTN * item.TOTAL_CTN || 0),
              0
            )
            .toFixed(2), // sum TTL_CBM
          CBM_PCS: "",
          NG_KG: "",
          GW_KG: "",
          TOTAL_NW: lastInvoiceItems
            .reduce((sum, item) => sum + (item.NG_KG * item.TOTAL_CTN || 0), 0)
            .toFixed(2), // sum TOTAL_NW
          NW_PCS: "",
          TOTAL_GW: lastInvoiceItems
            .reduce((sum, item) => sum + (item.GW_KG * item.TOTAL_CTN || 0), 0)
            .toFixed(2), // sum TOTAL_GW
          GW_PCS: "",
        };

        // Add the total row to the end of mappedRows
        mappedRows.push(totalRow);

        setRows(mappedRows);

        // setTableData({
        //   xscdata: res.XscData.INVOICE_DATA,
        // });
        // setShippingItems(res.XscData.INVOICE_DATA)
      } else {
        console.error("Client, Customer and Invoice has no data");
      }
      // const formattedRows = res.XscData.INVOICE_DATA[0].PRODUCT_DETAILS.map((item, index) => ({
      // 	id: index.toString(),
      // 	MNX_ID: item.CLIENT_ITEM_NUM ?? "N/A",
      // 	CUST_ITEM_ID: item.CUST_ITEM_NUM ?? "N/A",
      // 	DESCRIPTION: item.DESCRIPTION ?? "N/A",
      // 	CTN: item.TOTAL_CTN ?? "N/A",
      // 	INN: item.P_INNER ?? "N/A",
      // 	CTN_QTY: item.QTY_PER_CTN ?? "N/A",
      // 	TOTAL_QTY:parseFloat(item.QTY_PER_CTN)*parseFloat(item.TOTAL_CTN),
      // 	CBM_CTN: item.CBM_PER_CTN ?? "N/A",
      // 	TTL_CBM: item.TTL_CBM ?? "N/A",
      // 	CBM_PCS: item.CLIENT_ITEM_NUM ?? "N/A",
      // 	NG_KG: item.NG_KG ?? "N/A",
      // 	GW_KG: item.GW_KG ?? "N/A",
      // 	TOTAL_NW: item.TTL_NT_WT,
      // 	NW_PCS: item.NG_KG ?? "N/A",
      // 	TOTAL_GW: item.TTL_GW_WT,
      // 	GW_PCS: item.GW_KG ?? "N/A",
      // }));
      //  setRows(formattedRows);
      setPackageInfo(res.XscData.PACKAGE_INFO);
      setShippingDetails(res.XscData.SHIPPING_DETAILS);
      setShippingMarks(res.XscData.SHIPPING_DETAILS.SHIPPING_MARK);
      //setdeliveryDate(convertUnixTimestampToDate(res.XscData.SHIPPING_DETAILS.EXPECTED_DELIVERY_DATE));
      //setshippingDate(convertUnixTimestampToDate(res.XscData.SHIPPING_DETAILS.SAILING_DATE))
      setFile(res.XscData.SHIPPING_DETAILS.SHIPMENT_DOC);
      setFileName(res.XscData.SHIPPING_DETAILS.SHIPMENT_DOC);
    });
  };

  useEffect(() => {
    getInitialData();
  }, []);

  const initialShippingValues = {
    APP_CLIENT_ID: localStorage.getItem("CLIENT_ID"),
    CLIENT_CUST_ID: localStorage.getItem("CLIENT_CUST_ID"),
    CONTAINER_ID: "",
    CONTAINER_NUM: "",
    SEAL_NUMBER: "",
    VESSEL_NAME: "",
    SAILING_DATE: "",
    EXPECTED_DELIVERY_DATE: "",
    PAYMENT_TYPE: "",
    DELIVERY_STATUS: "",
    SHIPPING_COMPANY_NAME: "",
    SHIPPING_MARK: "",
    CUST_INV_ID: "",
    PRODUCT_ID: "",
    TOTAL_ITEM: "",
    TOTAL_CTN: "",
    CBM_PER_CTN: "",
    NW_KG: "",
    GW_KG: "",
  };

  const [rows, setRows] = useState(initialRows);

  useEffect(() => {
    // Perform actions when rows change
    console.log("Rows have changed:", rows);
  }, [rows]);

  const handleInputChange = (e, rowId, field) => {
    console.log(
      `Updating row ${rowId}, field ${field} with value ${e.target.value}`
    );
    const newRows = rows.map((row) => {
      if (row.id === rowId) {
        return { ...row, [field]: e.target.value }; // dynamically update the correct field
      }
      return row;
    });
    setRows(newRows); // Update the state with the new rows
  };

  const handleInputCtnChange = (id, newQtyPerCtn) => {
    setRows((prevRows) => {
      return prevRows.map((row) => {
        if (row.id === id) {
          const newTotalQty = newQtyPerCtn * row.CTN;
          return { ...row, CTN_QTY: newQtyPerCtn, TOTAL_QTY: newTotalQty };
        }
        return row;
      });
    });
  };

  const handleInputNWChange = (id, value) => {
    setRows((prevRows) =>
      prevRows.map((row) => {
        if (row.id === id) {
          const updatedRow = { ...row, NG_KG: parseFloat(value) || 0 };

          updatedRow.TOTAL_NW = updatedRow.NG_KG * updatedRow.CTN;

          return updatedRow;
        }
        return row;
      })
    );
  };

  const handleInputGWChange = (id, value) => {
    setRows((prevRows) =>
      prevRows.map((row) => {
        if (row.id === id) {
          const updatedRow = { ...row, GW_KG: parseFloat(value) || 0 };

          updatedRow.TOTAL_GW = updatedRow.GW_KG * updatedRow.CTN;

          return updatedRow;
        }
        return row;
      })
    );
  };

  const handleTextInputChange = (e) => {
    const { name, value } = e.target;
    setdata({
      ...data,
      [name]: value,
    });
  };

  const getRowClassName = (params) => {
    if (params.row.id_desc === "Total") {
      return classes.totalRow;
    } else if (params.row.SUBHEADING && params.row.id_desc === undefined) {
      return classes.subheadingRow;
    } else {
      return "";
    }
  };

  const getCellClassName = (params) => {
    return params.row.id_desc === "Total" ? classes.padding : "";
  };

  const handleChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setIsLoading(true);
      console.log("Selected file:", e.target.files[0]);
      console.log("Selected file:", e.target.files[0].lastModifiedDate);
      setFileName(e.target.files[0].name);

      uploadTimeoutRef.current = setTimeout(() => {
        setupload(true);
        //setFile(URL.createObjectURL(e.target.files[0]));
        setFile(e.target.files[0]);
        setFileName(e.target.files[0].name);
        setIsLoading(false);
      }, 2000);
    } else {
      console.error("No file selected");
    }
  };
  // console.log(localStorage.getItem("selectedData"), "selectedData");

  const handleImportCancel = () => {
    setFile(null);
    setFileName("");
    setIsLoading(false);

    if (uploadTimeoutRef.current) {
      clearTimeout(uploadTimeoutRef.current);
      uploadTimeoutRef.current = null;
    }

    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }

    console.log("File upload canceled");
  };

  const [data, setdata] = useState(initialShippingValues);

  const handleSubmit = (e) => {
    e.preventDefault();

    const checkRequiredFields = () => {
      let missingFields = [];

      const shipmentList = shippingItems.flatMap((invoice) => {
        return invoice.ITEMS_LIST.map((item) => {
          return {
            CUST_INV_ID: invoice.CUST_INV_ID,
            PRODUCT_ID: item.PRODUCT_ID,
            TOTAL_ITEM: item.TOTAL_QTY,
            TOTAL_CTN: item.TOTAL_CTN,
            CBM_PER_CTN: item.CBM_PER_CTN,
            NW_KG: item.NG_KG,
            GW_KG: item.GW_KG,
          };
        });
      });

      console.log(shipmentList, "shipment");

      const payload = {
        APP_CLIENT_ID: APP_CLIENT_ID,
        CLIENT_CUST_ID: CLIENT_CUST_ID,
        SHIPMENT_ID: SHIPMENT_ID,
        CONTAINER_TYPE: selectedContainer,
        CONTAINER_NUM: CONTAINER_NUM,
        SEAL_NUMBER: sealnumber,
        VESSEL_NAME: vesslname,
        SAILING_DATE: shippingDate,
        EXPECTED_DELIVERY_DATE: deliveryDate,
        PAYMENT_TYPE: paymenttype,
        DELIVERY_STATUS: selectedDelivery,
        SHIPPING_COMPANY_NAME: SHIPPING_COMPANY_NAME,
        SHIPPING_MARK: shippingMarks,
        SHIP_ITEM_LIST: shipmentList,
      };
      console.log("PAYLOAD for modify S&H", payload);

      if (!payload.APP_CLIENT_ID) missingFields.push("App Client ID");
      if (!payload.CLIENT_CUST_ID) missingFields.push("Client Customer ID");
      if (!payload.CONTAINER_TYPE) missingFields.push("Container ID");
      if (!payload.CONTAINER_NUM) missingFields.push("Container Number");
      if (!payload.SEAL_NUMBER) missingFields.push("Seal Number");
      if (!payload.VESSEL_NAME) missingFields.push("Vessel Name");
      if (!payload.SAILING_DATE) missingFields.push("Sailing Date");
      if (!payload.EXPECTED_DELIVERY_DATE)
        missingFields.push("Expected Delivery Date");
      if (!payload.PAYMENT_TYPE) missingFields.push("Payment Type");
      if (!payload.SHIPPING_COMPANY_NAME)
        missingFields.push("Shipping Company Name");
      if (!payload.SHIPPING_MARK) missingFields.push("Shipping Mark");

      return { missingFields, payload };
    };

    const { missingFields, payload } = checkRequiredFields();
    console.log(payload, "payload data");

    if (missingFields.length > 0) {
      const errorMessage = `Please fill in the following fields: ${missingFields.join(
        ", "
      )}`;
      toast.error(errorMessage, {
        position: "bottom-center",
      });
      return;
    }

    const formData = new FormData();

    for (const key in payload) {
      formData.append(key, payload[key]);
    }

    formData.append("mod", "Shipping");
    formData.append("action", "update_packaging");
    formData.append("payload", JSON.stringify(payload));
    formData.append("file", file);

    axios({
      method: "post",
      url: process.env.REACT_APP_API_URL,
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        localStorage.setItem("SHIPMENT_ID", SHIPMENT_ID);
        console.log(res, "Packaging and Shipping details added successfully!");
        res.data.XscStatus !== 0
          ? toast.success(res.data.XscMessage) && navigate("/customers/customer-info")
          : toast.error(res.data.XscMessage);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getData = () => {
    const payload = {
      CLIENT_CUST_ID: CLIENT_CUST_ID,
    };

    makeApiCall({
      mod: "Customer",
      action: "get_client_cust_details",
      payload: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((res) => {
      console.log(res, "customer-reports");
      setClientData({
        xscdata: res.XscData.CUST_DATA,
      });
      setCustData({
        xscdata: res.XscData.CLIENT_DATA,
      });
    });
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    const payload = {
      APP_CLIENT_ID: localStorage.getItem("CLIENT_ID"),
    };
    makeApiCall({
      mod: "Shipping",
      action: "get-ctn-list",
      payload: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => {
      console.log("response of the container list", response);
      const lists = response.XscData.CTN_LIST;
      if (Array.isArray(lists) && lists.length > 0) {
        const mappedRows = lists.map((list) => ({
          value: list.ID,
          label: list.ALIAS_NAME,
        }));
        setcontainers(mappedRows);
      }
    });
  }, []);

  const [selectedContainer, setSelectedContainer] = useState("");
  const handleContainerChange = (e) => {
    const selectedId = e.target.value;
    setSelectedContainer(selectedId);
  };

  useEffect(() => {
    makeApiCall({
      mod: "Shipping",
      action: "list-delivery-status",
      payload: {},
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => {
      console.log("response of the delivery status", response);
      const lists = response.XscData;
      if (Array.isArray(lists) && lists.length > 0) {
        const mappedRows = lists.map((list) => ({
          value: list.ID,
          label: list.DELIVERY_STATUS,
        }));
        setDelivery(mappedRows);
      }
    });
  }, []);

  const [selectedDelivery, setselectedDelivery] = useState(0);

  const handleDeliveryChange = (e) => {
    setselectedDelivery(e.target.value);
	console.log(selectedDelivery,"del")
  };

  const [selectedPaymentType, setSelectedPaymentType] = useState("");

  const paymentTypes = [
    { value: "TT", label: "T/T" },
    { value: "CASH", label: "CASH" },
    { value: "CARD", label: "CARD" },
    { value: "ONLINE", label: "ONLINE" },
    { value: "OTHERS", label: "OTHERS" },
  ];

  const handlePaymentTypeChange = (e) => {
    console.log(e.target.value);
    //   setSelectedPaymentType(e.target.value);
    setShippingDetails((prevDetails) => ({
      ...prevDetails,
      PAYMENT_TYPE: e.target.value,
    }));
  };

  const handleShippingMarkChange = (value) => {
    setShippingMarks((prevData) => ({
      ...prevData,
      SHIPPING_MARK: value,
    }));
  };
  function unixTimestampToDate(unixTimestamp) {
    // Convert Unix timestamp to milliseconds
    const date = new Date(unixTimestamp * 1000);

    // Format the date
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    // Return formatted date as YYYY-MM-DD
    return `${year}-${month}-${day}`;
  }
  const calculatedRows = rows.map((item) => {
    if (!item.SUBHEADING && item.id !== "total-row") {
      return {
        ...item,
        // CTN: item.CTN ?? "N/A",
        // INN: item.INN ?? "N/A",
        // CTN_QTY: item.CTN_QTY ?? "N/A",
        // TOTAL_QTY:
        //   item.CTN_QTY && item.CTN
        //     ? item.CTN_QTY * item.CTN
        //     : "N/A",
        // CBM_CTN: item.CBM_CTN ?? "N/A",
        CBM_PCS: (item.CBM_CTN / item.CTN_QTY).toFixed(4) ?? "N/A",
        TTL_CBM:
          item.CBM_CTN && item.CTN
            ? (item.CBM_CTN * item.CTN).toFixed(2)
            : "N/A",
        // CBM_PCS: item.CBM_PCS ?? "N/A",
        // NG_KG: item.NG_KG ?? "N/A",
        // GW_KG: item.GW_KG ?? "N/A",
        TOTAL_NW:
          item.NG_KG && item.CTN ? (item.NG_KG * item.CTN).toFixed(2) : "N/A",
        NW_PCS:
          item.CTN_QTY && item.NG_KG
            ? (item.NG_KG / item.CTN_QTY).toFixed(2)
            : "",
        TOTAL_GW:
          item.GW_KG && item.CTN ? (item.GW_KG * item.CTN).toFixed(2) : "N/A",
        GW_PCS:
          item.CTN_QTY && item.GW_KG
            ? (item.GW_KG / item.CTN_QTY).toFixed(2)
            : "",
      };
    }
    return item;
  });

  const totalCTNCount = rows.reduce((sum, row) => {
    if (row.SUBHEADING === false && row.id_desc !== "Total") {
      return sum + (row.CTN !== "N/A" ? row.CTN : 0);
    }
    return sum;
  }, 0);
  const totalQTY = rows.reduce((sum, row) => {
    if (row.SUBHEADING === false && row.id_desc !== "Total") {
      return sum + (row.TOTAL_QTY !== "N/A" ? parseFloat(row.TOTAL_QTY) : 0);
    }
    return sum;
  }, 0);
  const totalG_W = rows.reduce((sum, row) => {
    if (row.SUBHEADING === false && row.id_desc !== "Total") {
      return sum + (row.TOTAL_GW !== "N/A" ? parseFloat(row.TOTAL_GW) : 0);
    }
    return sum;
  }, 0);
  const totalN_W = calculatedRows.reduce((sum, row) => {
    if (row.SUBHEADING === false && row.id_desc !== "Total") {
      return sum + (row.TOTAL_NW !== "N/A" ? parseFloat(row.TOTAL_NW) : 0);
    }
    return sum;
  }, 0);
  const totalCBM = calculatedRows
    .reduce((sum, row) => {
      if (row.SUBHEADING === false && row.id_desc !== "Total") {
        return sum + (row.TTL_CBM !== "N/A" ? parseFloat(row.TTL_CBM) : 0);
      }
      return sum;
    }, 0)
    .toFixed(2);
  const totalRows = calculatedRows.map((item) => {
    if (!item.SUBHEADING && item.id === "total-row") {
      return {
        ...item,
        // CTN: item.CTN ?? "N/A",
        // INN: item.INN ?? "N/A",
        // CTN_QTY: item.CTN_QTY ?? "N/A",
        TOTAL_QTY: totalQTY,
        // CBM_CTN: item.CBM_CTN ?? "N/A",
        TTL_CBM: totalCBM,
        // CBM_PCS: item.CBM_PCS ?? "N/A",
        // NG_KG: item.NG_KG ?? "N/A",
        // GW_KG: item.GW_KG ?? "N/A",
        TOTAL_NW: totalN_W,
        // NW_PCS:
        //   item.CTN_QTY && item.NG_KG
        //     ? (item.NG_KG / item.CTN_QTY).toFixed(2)
        //     : "N/A",
        TOTAL_GW: totalG_W,
        // GW_PCS:
        //   item.CTN_QTY && item.GW_KG
        //     ? (item.GW_KG / item.CTN_QTY).toFixed(2)
        //     : "N/A",
      };
    }
    return item;
  });
  const API_URL = process.env.REACT_APP_API_URL;
  const handleView = () => {
    if (upload === false) {
      if (file) {
        const fileUrl = `${API_URL}media/${file}`;
        window.open(fileUrl, "_blank");
      }
    } else {
      const fileUrl = URL.createObjectURL(file);
      window.open(fileUrl, "_blank");
    }
  };
  console.log("SHIPENT DETAILS", shippingMarks);
  return (
    <div>
      <div id="suppliers_topbar_bg" className="">
        <div className="container d-flex justify-content-between align-items-center">
          <div
            id="dashboard_sub_header"
            className="d-flex align-items-center"
            style={{ gap: "16px" }}
          >
            <a href="/customers/customer-info" className="text-decoration-none">
              {" "}
              <span id="cancel_po_button">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M10.5859 12.0001L2.79297 4.20718L4.20718 2.79297L12.0001 10.5858L19.793 2.79297L21.2072 4.20718L13.4143 12.0001L21.2072 19.7929L19.793 21.2072L12.0001 13.4143L4.20718 21.2072L2.79297 19.7929L10.5859 12.0001Z"
                    fill="var(--onSurface)"
                  />
                  <path
                    d="M10.5859 12.0001L2.79297 4.20718L4.20718 2.79297L12.0001 10.5858L19.793 2.79297L21.2072 4.20718L13.4143 12.0001L21.2072 19.7929L19.793 21.2072L12.0001 13.4143L4.20718 21.2072L2.79297 19.7929L10.5859 12.0001Z"
                    fill="black"
                    fill-opacity="0.2"
                  />
                  <path
                    d="M10.5859 12.0001L2.79297 4.20718L4.20718 2.79297L12.0001 10.5858L19.793 2.79297L21.2072 4.20718L13.4143 12.0001L21.2072 19.7929L19.793 21.2072L12.0001 13.4143L4.20718 21.2072L2.79297 19.7929L10.5859 12.0001Z"
                    fill="black"
                    fill-opacity="0.2"
                  />
                  <path
                    d="M10.5859 12.0001L2.79297 4.20718L4.20718 2.79297L12.0001 10.5858L19.793 2.79297L21.2072 4.20718L13.4143 12.0001L21.2072 19.7929L19.793 21.2072L12.0001 13.4143L4.20718 21.2072L2.79297 19.7929L10.5859 12.0001Z"
                    fill="black"
                    fill-opacity="0.2"
                  />
                  <path
                    d="M10.5859 12.0001L2.79297 4.20718L4.20718 2.79297L12.0001 10.5858L19.793 2.79297L21.2072 4.20718L13.4143 12.0001L21.2072 19.7929L19.793 21.2072L12.0001 13.4143L4.20718 21.2072L2.79297 19.7929L10.5859 12.0001Z"
                    fill="black"
                    fill-opacity="0.2"
                  />
                </svg>
              </span>{" "}
            </a>
            Modify Packaging, S&H
          </div>
        </div>
      </div>
      <div id="create_po_bg">
        <div className="container">
          <div id="search_and_upload">
            {managerStatus === null && (
              <Button
                id="search_previous_invoice"
                onClick={() => setSearchShow(true)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M18.031 16.6168L22.3137 20.8995L20.8995 22.3137L16.6168 18.031C15.0769 19.263 13.124 20 11 20C6.032 20 2 15.968 2 11C2 6.032 6.032 2 11 2C15.968 2 20 6.032 20 11C20 13.124 19.263 15.0769 18.031 16.6168ZM16.0247 15.8748C17.2475 14.6146 18 12.8956 18 11C18 7.1325 14.8675 4 11 4C7.1325 4 4 7.1325 4 11C4 14.8675 7.1325 18 11 18C12.8956 18 14.6146 17.2475 15.8748 16.0247L16.0247 15.8748Z"
                    fill="white"
                  />
                </svg>
                <span id="search_text_sh">Search Invoice</span>
              </Button>
            )}

            <SearchShipping show={searchShow} onHide={handleSelectedIds} />
          </div>
          <div id="po_logo" align="center">
            <img src={logo} alt="logo" />
            <div id="po_header_text"> {custData.xscdata.NAME} </div>
          </div>
          <div className="row" id="about_po_header">
            <div className="col-6">
              <div id="po_supplier_text">Supplier</div>
              <div id="po_supplier_name">{custData.xscdata.NAME}</div>
              <div id="po_supplier_address">
                {custData.xscdata.ADDR_1}, {custData.xscdata.CITY},{" "}
                {custData.xscdata.COUNTRY}
              </div>
              <div id="po_supplier_ph">
                TEL:{" "}
                <span id="po_supplier_no">
                  {custData.xscdata.CONTACT_NUMBER}
                </span>
              </div>
              <div id="po_supplier_ph">
                FAX: <span id="po_supplier_no"> {custData.xscdata.FAX}</span>
              </div>
              <div id="po_supplier_ph">
                Invoice No:
                <span id="po_supplier_no">
                  {" "}
                  {shippingItems.map((item) => {
                    return (
                      <span id="po_supplier_no">{" " + item.CUST_INV_NUM}</span>
                    );
                  })}
                </span>
              </div>
              <div id="po_supplier_ph">
                Date:
                {managerStatus === "Approved" ? (
                  <span id="po_supplier_no">
                    {" " + unixTimestampToDate(shippingDate)}
                  </span>
                ) : (
                  <DatePicker
                    id="cust_date_div"
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    dateFormat="dd-MMMM-yyyy"
                  />
                )}
              </div>
            </div>
            <div className="col-6 " id="about_po_left_bg" align="end">
              <div id="content_wrapper">
                {" "}
                <div id="po_supplier_text">Client</div>
                <div id="po_supplier_name">{clientData.xscdata.NAME}</div>
                <div id="po_supplier_address">
                  {clientData.xscdata.ADDR_1}, {clientData.xscdata.CITY},{" "}
                  {clientData.xscdata.COUNTRY}
                </div>
                <div id="po_supplier_ph">
                  TEL:{" "}
                  <span id="po_supplier_no">
                    {clientData.xscdata.CONTACT_NUMBER}
                  </span>
                </div>
                <div id="po_supplier_ph">
                  FAX: <span id="po_supplier_no">{clientData.xscdata.FAX}</span>
                </div>
                <div id="po_supplier_ph">
                  Customer PO Number:{" "}
                  {shippingItems.map((item) => {
                    return (
                      <span id="po_supplier_no">{" " + item.CUST_PO_NUM}</span>
                    );
                  })}{" "}
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              maxHeight: "fit-content",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <DataGrid
              rows={totalRows}
              columns={columns.map((col) => ({
                ...col,
                filterable: false,
                sortable: false,
              }))}
              hideFooterPagination={true}
              hideFooter={true}
              autoHeight
              rowHeight={80}
              showCellRightBorder
              getRowClassName={getRowClassName}
              getCellClassName={getCellClassName}
              disableColumnMenu={true}
              disableColumnFilter={true}
              sortingOrder={[]}
            />
          </div>

          <div
            className="d-flex flex-column gap-4"
            style={{
              marginTop: 45,
            }}
          >
            <div className="d-flex flex-row align-items-center gap-2">
              <div
                style={{
                  width: 8,
                  height: 44,
                  background: "var(--tertiaryContainer, #BA3CBB)",
                }}
              ></div>
              <h2 id="shipping_details">Shipping Details</h2>
            </div>
            <div className="" style={{ display: "flex", gap: "50px" }}>
              <div className="d-flex flex-column gap-1">
                <label>Shipping Company Name</label>
                <TextInputWithAll
                  type="text"
                  placeholder="Enter Company Name"
                  name="SHIPPING_COMPANY_NAME"
                  onChange={(e) => setSHIPPING_COMPANY_NAME(e.target.value)}
                  value={SHIPPING_COMPANY_NAME}
                />
              </div>
              <div className="d-flex flex-column">
                <DropDownInput
                  options={containers}
                  label="Container Type"
                  onChange={handleContainerChange}
                  id="select_status"
                  placeholder="Select Container Type"
                  name="CONTAINER_ID"
                  value={selectedContainer}
                />
              </div>
            </div>
            <div className="" style={{ display: "flex", gap: "50px" }}>
              <div className="d-flex flex-column gap-1">
                <label>Container No</label>
                <TextInputWithAll
                  type="text"
                  placeholder="Enter Container No"
                  name="CONTAINER_NUM"
                  onChange={(e) => setCONTAINER_NUM(e.target.value)}
                  value={CONTAINER_NUM}
                />
              </div>
              <div className="d-flex flex-column gap-1">
                <label>Seal No</label>
                <TextInputWithAll
                  type="text"
                  placeholder="Enter Seal No"
                  name="SEAL_NUMBER"
                  onChange={(e) => setsealnumber(e.target.value)}
                  value={sealnumber}
                />
              </div>
            </div>
            <div className="" style={{ display: "flex", gap: "50px" }}>
              <div className="d-flex flex-column gap-1">
                <label>Vessel Name</label>
                <TextInputWithAll
                  type="text"
                  placeholder="Enter Vessel Name"
                  name="VESSEL_NAME"
                  onChange={(e) => setvesselname(e.target.value)}
                  value={vesslname}
                />
              </div>
              <div className="d-flex flex-column gap-1">
                <label>Sailing Date</label>
                <TextInputWithAll
                  type="date"
                  value={convertUnixTimestampToDate(shippingDate)}
                  onChange={(e) =>
                    setshippingDate(convertToUnixTimestamp(e.target.value))
                  }
                  placeholder="Enter Sailing Date"
                />
              </div>
            </div>
            <div className="" style={{ display: "flex", gap: "50px" }}>
              <div className="d-flex flex-column gap-1">
                <label>Delivery Date</label>
                <TextInputWithAll
                  type="date"
                  value={convertUnixTimestampToDate(deliveryDate)}
                  placeholder="Enter Delivery Date"
                  onChange={(e) =>
                    setdeliveryDate(convertToUnixTimestamp(e.target.value))
                  }
                />
              </div>

              <div className="d-flex flex-column gap-1">
                <DropDownInput
                  label="Payment Type"
                  options={paymentTypes}
                  id="select_status"
                  placeholder="Select Payment Type"
                  name="PAYMENT_TYPE"
                  value={shippingDetails.PAYMENT_TYPE}
                  onChange={handlePaymentTypeChange}
                />
              </div>
            </div>
            <div className="  " style={{}}>
              <DropDownInput
                label="Delivery Status"
                options={delivery}
                id="select_status"
				value={selectedDelivery}
                placeholder="Select Status"
                name="DELIVERY_STATUS"
                onChange={handleDeliveryChange}
              />
            </div>
          </div>

          <div
            className="d-flex flex-column gap-4"
            style={{
              marginTop: 45,
            }}
          >
            <div className="d-flex flex-row align-items-center gap-2">
              <div
                style={{
                  width: 8,
                  height: 44,
                  background: "var(--tertiaryContainer, #BA3CBB)",
                }}
              ></div>
              <h2 id="shipping_details">Shipping Marks</h2>
            </div>
            <textarea
              name="SHIPPING_MARK"
              className="container"
              id="text_container_123"
              style={{ padding: "20px" }}
              value={shippingMarks}
              onChange={(e) => handleShippingMarkChange(e.target.value)}
            />
          </div>

          <div
            className="d-flex flex-column align-items-start"
            style={{ marginTop: 44, gap: 24 }}
          >
            <div
              className="d-flex flex-row align-items-center"
              style={{
                gap: 12,
              }}
            >
              <div
                style={{
                  width: 8,
                  height: 44,
                  flexShrink: 0,
                  background: "var(--Schemes-Tertiary-Container, #ba3cbb)",
                }}
              ></div>
              <span id="customer_po_text">Upload Consignment</span>
            </div>

            <div
              className="d-flex flex-column align-items-start gap-2"
              style={{ marginBottom: 214 }}
            >
              <p id="upload_text">Upload Consignment file</p>
              {isLoading ? (
                <div id="upload_pi_file">
                  <div className="">
                    <div id="about_po_header">
                      <span
                        className="d-flex align-items-center"
                        style={{ gap: "4px" }}
                      >
                        <CircleSpinner
                          size={20}
                          loading={isLoading}
                          color="var(--primaryContainer)"
                        />
                        Uploading Your File
                      </span>
                      <div>{fileName}</div>
                    </div>
                  </div>
                  <div
                    id="file_upload_cancel"
                    onClick={handleImportCancel}
                    style={{
                      marginTop: 8,
                    }}
                  >
                    Cancel
                  </div>
                </div>
              ) : (
                <div id="import_supplier_bg">
                  {file ? (
                    <div>
                      <div
                        id="upload_pi_file"
                        style={{
                          border: "none",
                        }}
                        className="d-flex flex-row align-items-center gap-2"
                      >
                        <div id="upload_file_text">
                          <span
                            className="d-flex align-items-center"
                            style={{
                              gap: "4px",
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <path
                                d="M5 4H15V8H19V20H5V4ZM3.9985 2C3.44749 2 3 2.44405 3 2.9918V21.0082C3 21.5447 3.44476 22 3.9934 22H20.0066C20.5551 22 21 21.5489 21 20.9925L20.9997 7L16 2H3.9985ZM10.4999 7.5C10.4999 9.07749 10.0442 10.9373 9.27493 12.6534C8.50287 14.3757 7.46143 15.8502 6.37524 16.7191L7.55464 18.3321C10.4821 16.3804 13.7233 15.0421 16.8585 15.49L17.3162 13.5513C14.6435 12.6604 12.4999 9.98994 12.4999 7.5H10.4999ZM11.0999 13.4716C11.3673 12.8752 11.6042 12.2563 11.8037 11.6285C12.2753 12.3531 12.8553 13.0182 13.5101 13.5953C12.5283 13.7711 11.5665 14.0596 10.6352 14.4276C10.7999 14.1143 10.9551 13.7948 11.0999 13.4716Z"
                                fill="#1B1B24"
                              />
                              <path
                                d="M5 4H15V8H19V20H5V4ZM3.9985 2C3.44749 2 3 2.44405 3 2.9918V21.0082C3 21.5447 3.44476 22 3.9934 22H20.0066C20.5551 22 21 21.5489 21 20.9925L20.9997 7L16 2H3.9985ZM10.4999 7.5C10.4999 9.07749 10.0442 10.9373 9.27493 12.6534C8.50287 14.3757 7.46143 15.8502 6.37524 16.7191L7.55464 18.3321C10.4821 16.3804 13.7233 15.0421 16.8585 15.49L17.3162 13.5513C14.6435 12.6604 12.4999 9.98994 12.4999 7.5H10.4999ZM11.0999 13.4716C11.3673 12.8752 11.6042 12.2563 11.8037 11.6285C12.2753 12.3531 12.8553 13.0182 13.5101 13.5953C12.5283 13.7711 11.5665 14.0596 10.6352 14.4276C10.7999 14.1143 10.9551 13.7948 11.0999 13.4716Z"
                                fill="black"
                                fill-opacity="0.2"
                              />
                              <path
                                d="M5 4H15V8H19V20H5V4ZM3.9985 2C3.44749 2 3 2.44405 3 2.9918V21.0082C3 21.5447 3.44476 22 3.9934 22H20.0066C20.5551 22 21 21.5489 21 20.9925L20.9997 7L16 2H3.9985ZM10.4999 7.5C10.4999 9.07749 10.0442 10.9373 9.27493 12.6534C8.50287 14.3757 7.46143 15.8502 6.37524 16.7191L7.55464 18.3321C10.4821 16.3804 13.7233 15.0421 16.8585 15.49L17.3162 13.5513C14.6435 12.6604 12.4999 9.98994 12.4999 7.5H10.4999ZM11.0999 13.4716C11.3673 12.8752 11.6042 12.2563 11.8037 11.6285C12.2753 12.3531 12.8553 13.0182 13.5101 13.5953C12.5283 13.7711 11.5665 14.0596 10.6352 14.4276C10.7999 14.1143 10.9551 13.7948 11.0999 13.4716Z"
                                fill="black"
                                fill-opacity="0.2"
                              />
                              <path
                                d="M5 4H15V8H19V20H5V4ZM3.9985 2C3.44749 2 3 2.44405 3 2.9918V21.0082C3 21.5447 3.44476 22 3.9934 22H20.0066C20.5551 22 21 21.5489 21 20.9925L20.9997 7L16 2H3.9985ZM10.4999 7.5C10.4999 9.07749 10.0442 10.9373 9.27493 12.6534C8.50287 14.3757 7.46143 15.8502 6.37524 16.7191L7.55464 18.3321C10.4821 16.3804 13.7233 15.0421 16.8585 15.49L17.3162 13.5513C14.6435 12.6604 12.4999 9.98994 12.4999 7.5H10.4999ZM11.0999 13.4716C11.3673 12.8752 11.6042 12.2563 11.8037 11.6285C12.2753 12.3531 12.8553 13.0182 13.5101 13.5953C12.5283 13.7711 11.5665 14.0596 10.6352 14.4276C10.7999 14.1143 10.9551 13.7948 11.0999 13.4716Z"
                                fill="black"
                                fill-opacity="0.2"
                              />
                              <path
                                d="M5 4H15V8H19V20H5V4ZM3.9985 2C3.44749 2 3 2.44405 3 2.9918V21.0082C3 21.5447 3.44476 22 3.9934 22H20.0066C20.5551 22 21 21.5489 21 20.9925L20.9997 7L16 2H3.9985ZM10.4999 7.5C10.4999 9.07749 10.0442 10.9373 9.27493 12.6534C8.50287 14.3757 7.46143 15.8502 6.37524 16.7191L7.55464 18.3321C10.4821 16.3804 13.7233 15.0421 16.8585 15.49L17.3162 13.5513C14.6435 12.6604 12.4999 9.98994 12.4999 7.5H10.4999ZM11.0999 13.4716C11.3673 12.8752 11.6042 12.2563 11.8037 11.6285C12.2753 12.3531 12.8553 13.0182 13.5101 13.5953C12.5283 13.7711 11.5665 14.0596 10.6352 14.4276C10.7999 14.1143 10.9551 13.7948 11.0999 13.4716Z"
                                fill="black"
                                fill-opacity="0.2"
                              />
                            </svg>
                            {fileName}
                          </span>
                        </div>
                        <div
                          onClick={handleImportCancel}
                          style={{
                            cursor: "pointer",
                          }}
                        >
                          <svg
                            onClick={() => setFile(null)}
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                          >
                            <path
                              d="M8.00045 7.05767L11.3003 3.75781L12.2431 4.70062L8.94325 8.00047L12.2431 11.3003L11.3003 12.2431L8.00045 8.94327L4.70063 12.2431L3.75781 11.3003L7.05765 8.00047L3.75781 4.70062L4.70063 3.75781L8.00045 7.05767Z"
                              fill="#950F27"
                            />
                          </svg>
                        </div>
                        <Button
                          id="view_cons_file"
                          style={{
                            width: 98,
                          }}
                          onClick={handleView}
                        >
                          <h2 id="view">View</h2>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M16.0052 9.41421L7.39858 18.0208L5.98438 16.6066L14.591 8H7.00519V6H18.0052V17H16.0052V9.41421Z"
                              fill="#635BFF"
                            />
                          </svg>
                        </Button>
                      </div>
                    </div>
                  ) : (
                    <div className="col-12">
                      <label
                        htmlFor="file-input"
                        className="d-flex align-items-center justify-content-center flex-column"
                        id="upload_pi_file"
                        style={{
                          cursor: "pointer",
                          marginTop: 8,
                        }}
                      >
                        <div id="upload_csv_text">
                          {fileName && fileName}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <g opacity="0.4">
                              <path
                                d="M12 12.5858L16.2426 16.8284L14.8284 18.2426L13 16.415V22H11V16.413L9.17157 18.2426L7.75736 16.8284L12 12.5858ZM12 2C15.5934 2 18.5544 4.70761 18.9541 8.19395C21.2858 8.83154 23 10.9656 23 13.5C23 16.3688 20.8036 18.7246 18.0006 18.9776L18 17C18 13.6863 15.3137 11 12 11C8.7616 11 6.12243 13.5656 6.00414 16.7751L6 17L6.00039 18.9776C3.19696 18.7252 1 16.3692 1 13.5C1 10.9656 2.71424 8.83154 5.04648 8.19411C5.44561 4.70761 8.40661 2 12 2Z"
                                fill="#635BFF"
                              />
                            </g>
                          </svg>
                        </div>
                      </label>
                      <TextInputWithAll
                        name="file-input"
                        type="file"
                        onChange={handleChange}
                        id="file-input"
                        ref={fileInputRef}
                        style={{
                          display: "none",
                        }}
                        // accept=".csv"
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        <div id="po_footer" className="pi_footer">
          <div id="add_supplier_buttons" className="container">
            {" "}
            <a href="/customers/customer-info" className="text-decoration-none">
              {" "}
              <div id="cancel">Cancel</div>
            </a>
            <div
              className="d-flex align-items-center justify-content-right "
              style={{ gap: "12px" }}
            >
              <a
                href="/customers/view-shipping"
                className="text-decoration-none"
              >
                <div id="preview_packaging">
                  Preview Packaging, S&H{" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M16.0052 9.41421L7.39858 18.0208L5.98438 16.6066L14.591 8H7.00519V6H18.0052V17H16.0052V9.41421Z"
                      fill="#635BFF"
                    />
                  </svg>
                </div>
              </a>
              <a
                href="/customers/customer-info"
                className="text-decoration-none"
              >
                <div id="submitsupplier" onClick={handleSubmit}>
                  Update Packaging, S&H{" "}
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
        position="bottom-center"
        style={{ width: "max-content" }}
      />
    </div>
  );
}
