import React from 'react'
import CustomersOrdersTable from './CustomersOrdersTable'
import SuppliersOrdersTable from './SuppliersOrdersTable'

const GlobalOrders = ({ activeTab, onTabChange }) => {
  return (
    <div>
      <div>{activeTab === "CustomersOrders" && <CustomersOrdersTable/>}</div>
      <div>{activeTab === "SuppliersOrders" && <SuppliersOrdersTable/>}</div>
    </div>
  )
}

export default GlobalOrders