import React, { useState, useEffect } from "react";
import logo from "../../../assets/Manex Logo.png";
import { DataGrid } from "@mui/x-data-grid";
import po_img from "../../../assets/po_img.png";
import { Button } from "react-bootstrap";
import "../../../Styles/CreatePI.css";
import "react-datepicker/dist/react-datepicker.css";
import { makeStyles } from "@material-ui/styles";
import { useNavigate, useSearchParams } from "react-router-dom";
import { makeApiCall } from "../../../StaticComponents/API";
import default_image from "../../../assets/Product_Package.png";

import num2words from "num2words";

const useStyles = makeStyles({
  subheadingRow: {
    textAlign: "center",
    fontWeight: "bold",
    minHeight: "32px !important",
    maxHeight: "32px !important",
    "& .MuiDataGrid-cell": {
      border: "none !important",
      lineHeight: "32px !important",
      minHeight: "32px !important",
      maxHeight: "32px !important",
    },
  },

  subheadingCell: {
    width: "100%",
    border: "0.5px solid var(--outlineVariant) !important",
    lineHeight: "32px !important",
    minHeight: "32px !important",
    maxHeight: "32px !important",
  },

  padding: { padding: "0px !important" },
});
function ViewPI() {
  const API_URL=process.env.REACT_APP_API_URL;
  const [poNumber, setPoNumber] = useState("");
  let [searchParams] = useSearchParams();
  let APP_CLIENT_ID = localStorage.getItem("CLIENT_ID");
  console.log("Hello");
  const[weightUnit, setWeightUnit] = useState("");
  const [currencySymbol, setCurrencySymbol] = useState("");
  const CLIENT_CUST_ID = localStorage.getItem("CLIENT_CUST_ID");
  const CUST_PI_ID = localStorage.getItem("CUST_PI_ID");
  const [pageSize, setPageSize] = React.useState(10);
  const[file, setFile] = useState("");
  const[fileName, setFileName] = useState("");
  const navigate  = useNavigate();


  const picture = () => (
    <div id="po_pic_bg">
      <img src={po_img} />
    </div>
  );

  const [createPoInfo, setCreatePoInfo] = React.useState({
    customerData: "",
    clientData: "",
    amountDetails: "",
    poDetails: "",
  });

  const classes = useStyles();

  const columns = [
    {
      field: "MNX_ID",
      headerName: "Mnx. Item ID",
      width: 148,
      cellClassName: "po_input",
      align: "left",
    },
    {
      field: "CUST_ITEM_ID",
      headerName: "Cust. Item ID",
      width: 148,
      cellClassName: "po_input",
      align: "left",
    },
    {
      field: "TOTAL_QTY",
      headerName: "Total QTY",
      width: 125,
      align: "left",
    },
    {
      field: "PICTURE",
      headerName: "Picture",
      width: 120,
      align: "left",
      renderCell: (params) => {
				const imageUrl = params.row.PICTURE ? `${API_URL}/media/${params.row.PICTURE}` : default_image;
				return params.row.id_desc === "Total" ? null : (
				  <div id="po_pic_bg">
				   <img
				  src={imageUrl}
				  alt="Product Image"
				  style={{ width: "100%", height: "auto" }}
				/>
				  </div>
				);
			  },
    },
    {
      field: "CTN_QTY",
      headerName: "CTN/QTY",
      width: 148,
      align: "left",
    },
    {
      field: "INNER",
      headerName: "Inner",
      width: 148,
      align: "left",
    },
    {
      field: "CTN",
      headerName: "CTN",
      width: 148,
      type: "number",
      align: "left",
      cellClassName: "po_input",
      renderCell: (params) => {
				return params.row.id_desc === "Total" ? (
					<div className={classes.subheadingCell} style={{textAlign:"left", paddingLeft:"10px"}}>{params.value}</div>
				) : null;
			},
    },
    {
      field: "DESCRIPTION",
      headerName: "Description",
      width: 275,
      align: "left",
      cellClassName: "pi_input",
    },
    {
      field: "PRICE",
      headerName: "Price",
      width: 130,
      align: "left",
      cellClassName: "po_input",
      renderCell: (params) => {
        return params.row.id_desc === "Total" ? null : (
          <div className="">{params.row.CURRENCY_SYMBOL}{params.value}</div>
        );
      },
    },
    {
      field: "TOTAL_AMOUNT",
      headerName: "Total Amount",
      width: 150,
      align: "left",
      renderCell: (params) => {
				return params.row.id_desc === "Total" ? (
					<div className={classes.subheadingCell} style={{textAlign:"left", paddingLeft:"10px"}}>{currencySymbol}{params.value}</div>
				) : (
          <div>
            {params.row.CURRENCY_SYMBOL}
            {params.value}
          </div>
        );
			},
    },
    {
      field: "BARCODE",
      headerName: "Barcode",
      width: 275,
      align: "left",
      cellClassName: "pi_input",
    },
    {
      field: "DUN_BARCODE",
      headerName: "Dun Barcode",
      width: 275,
      align: "left",
      cellClassName: "pi_input",
    },
    {
      field: "PACKING",
      headerName: "Packing",
      width: 275,
      align: "left",
      cellClassName: "pi_input",
    },
    {
      field: "CBM",
      headerName: "CBM",
      width: 130,
      align: "left",
      cellClassName: "po_input",
    },
    {
      field: "TTL_CBM",
      headerName: "TTL CBM",
      width: 130,
      align: "left",
      renderCell: (params) => {
				return params.row.id_desc === "Total" ? (
					<div className={classes.subheadingCell} style={{textAlign:"left", paddingLeft:"10px"}}>{params.value}</div>
				) : null;
			},
    },
    {
      field: "WEIGHT",
      headerName: "Weight",
      width: 200,
      align: "left",
      cellClassName: "po_input",
      renderCell: (params) =>{
        return params.row.id_desc === "Total" ? null : (
          <div>{`${params.value} ${params.row.WEIGHT_UNIT}`}</div>
        )
      }
     
    },
    {
      field: "TTL_WEIGHT",
      headerName: "TTL Weight",
      width: 145,
      align: "left",
      renderCell: (params) => {
				return params.row.id_desc === "Total" ? (
					<div className={classes.subheadingCell} style={{textAlign:"left", paddingLeft:"10px"}}>{`${params.value} ${weightUnit}`}</div>
				) : (`${params.value} ${params.row.WEIGHT_UNIT}`);
			},
    },
  ];

 
  const [rows, setRows] = useState([]);
  let calculatedRows = [];
  const initialRows = [
		{
			id: "",
			id_desc: "Total",
			SUP_ITEM_ID: "",
			TOTAL_QTY: "",
			PICTURE: "",
			CTN_QTY: "",
			INNER: "",
			CTN: 0,
			DESCRIPTION: "",
			PRICE: "",
			TOTAL_AMOUNT: 0,
			BARCODE: "",
			DUN_BARCODE: "",
			PACKING: "",
			CBM: "",
			TTL_CBM: 0,
			WEIGHT: "",
			TTL_WEIGHT: 0,
		},
	];


  const rowsWithIcons = rows.map((row) => ({
    ...row,
    PICTURE: picture(),
  }));

  if (Array.isArray(rows) && rows.length > 0) {
    calculatedRows = rows.map((row) => ({
      ...row,
      TOTAL_AMOUNT: row.TOTAL_QTY * row.PRICE,
      TTL_WEIGHT: row.TOTAL_QTY * row.WEIGHT,
      TTL_CBM: row.TOTAL_QTY * row.CBM,
      // Calculate TOTAL_AMOUNT based on TOTAL_QTY and PRICE
    }));
  }
  const totalAmountAllRows = calculatedRows.reduce(
    (acc, row) => acc + row.TOTAL_AMOUNT,
    0
  );
  const totalCBMAllRows = calculatedRows.reduce(
    (acc, row) => acc + row.TTL_CBM,
    0
  );
  const totalWeightAllRows = calculatedRows.reduce(
    (acc, row) => acc + row.TTL_WEIGHT,
    0
  );
  const CTNAllRows = calculatedRows.reduce((acc, row) => acc + row.CTN, 0);
  const resultRows = calculatedRows.map((row) => {
    if (row.id_desc === "Total") {
      return {
        ...row,
        TOTAL_AMOUNT: totalAmountAllRows,
        CTN: CTNAllRows,
        TTL_CBM: totalCBMAllRows,
        TTL_WEIGHT: totalWeightAllRows,
      };
    }
    return row;
  });

  const getRowClassName = (params) => {
    return params.row.id_desc === "Total" ? classes.subheadingRow : "";
  };

  const getCellClassName = (params) => {
    return params.row.id_desc === "Total" ? classes.padding : "";
  };

  let token = localStorage.getItem("token");
   
  function convertUnixTimestampToDate(unixTimestamp) {
    const date = new Date(unixTimestamp * 1000);
    const formattedDate = `${String(date.getDate()).padStart(2, '0')}-${String(date.getMonth() + 1).padStart(2, '0')}-${date.getFullYear()}`;
    return formattedDate;
   }
  
  const getData = () => {
    const payload = {
      CUST_PI_ID: CUST_PI_ID,
      CLIENT_CUST_ID: CLIENT_CUST_ID,
    };
   console.log(payload);
    makeApiCall({
      mod: "CustomerPI",
      action: "view_cust_pi",
      payload: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((res) => {
      console.log(res,"view PI");
      const data = res.XscData.PI_ITEMS;

      if (Array.isArray(data)) {
        const mappedRows = data.map((po) => ({
          id: po.PRODUCT_ID,
          MNX_ID: po.CLIENT_ITEM_NUM,
          CUST_ITEM_ID: po.CUST_ITEM_NUM,
          TOTAL_QTY: po.TOTAL_QTY,
          PICTURE: po.PRODUCT_MM,
          CTN_QTY: po.QTY_PER_CTN,
          INNER: po.P_INNER,
          CTN: po.TOTAL_CTN,
          DESCRIPTION: po.DESCRIPTION,
          PRICE: po.PRICE_PER_ITEM,
          TOTAL_AMOUNT: (po.PRICE_PER_ITEM * po.TOTAL_QTY),
          BARCODE: po.CUST_BAR_CODE || po.CLIENT_BAR_CODE,
          DUN_BARCODE: po.CUST_DUN_BAR_CODE || po.CLIENT_DUN_BAR_CODE,
          PACKING: po.PACKING === null || "" ? "N/A" : po.PACKING,
          CBM: po.CTN_VOLUME,
          TTL_CBM: po.TOTAL_CTN * po.CTN_VOLUME,
          WEIGHT: parseFloat(po.PROD_WT).toFixed(2),
          TTL_WEIGHT: (po.TOTAL_QTY * po.PROD_WT),
          WEIGHT_UNIT: po.PROD_WT_UNIT,
          CURRENCY_SYMBOL: po.CURRENCY_SYMBOL,
        }));
        setCreatePoInfo({
          customerData: res.XscData.CLIENT_DATA,
          clientData: res.XscData.CUST_DATA,
          amountDetails: res.XscData.PI_INFO,
        });
        setCurrencySymbol(res.XscData.PI_ITEMS[0].CURRENCY_SYMBOL);
        setWeightUnit(res.XscData.PI_ITEMS[0].PROD_WT_UNIT);
        setFile(res.XscData.PI_INFO.PO_FILE);
        setFileName(res.XscData.PI_INFO.PO_FILE);
        //settotal(response.XscData.TOTAL_CTN);
        const finalRows = [...mappedRows, ...initialRows]
        setRows(finalRows);
      } else {
        console.log(res, "Customer view proforma details");
      }
    });
  };
 
  useEffect(() => {
    getData();
  },[]);

  const handleBack = () => {
    navigate(`/customers/customer-info`)
  }
   const handleFile = () => {
    const fileUrl = `${API_URL}media/${fileName}`;
    window.open(fileUrl, '_blank');
   }

  const convertAmountToText = (amount) => {
    if (typeof amount !== "number") {
      return "AMOUNT NOT AVAILABLE";
    }

    const [whole, fraction] = amount.toFixed(2).split("."); // Split into dollars and cents
    const wholeText = num2words(whole).toUpperCase(); // Convert whole number to text
    const fractionText = num2words(fraction).toUpperCase(); // Convert cents to text

    return `${wholeText} DOLLARS AND ${fractionText} CENTS`;
  };
  return (
    <div>
      <div id="suppliers_topbar_bg" className="">
        <div className="container d-flex justify-content-between align-items-center">
          <div id="header_name" className="d-flex " style={{display:"flex", gap:"12px"}}>
            <div>
              <svg
                style={{cursor:"pointer"}}
               onClick = {() => handleBack()}
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M7.82843 11.0009H20V13.0009H7.82843L13.1924 18.3648L11.7782 19.779L4 12.0009L11.7782 4.22266L13.1924 5.63687L7.82843 11.0009Z"
                  fill="var(--onSurface)"
                />
                <path
                  d="M7.82843 11.0009H20V13.0009H7.82843L13.1924 18.3648L11.7782 19.779L4 12.0009L11.7782 4.22266L13.1924 5.63687L7.82843 11.0009Z"
                  fill="black"
                  fill-opacity="0.2"
                />
                <path
                  d="M7.82843 11.0009H20V13.0009H7.82843L13.1924 18.3648L11.7782 19.779L4 12.0009L11.7782 4.22266L13.1924 5.63687L7.82843 11.0009Z"
                  fill="black"
                  fill-opacity="0.2"
                />
                <path
                  d="M7.82843 11.0009H20V13.0009H7.82843L13.1924 18.3648L11.7782 19.779L4 12.0009L11.7782 4.22266L13.1924 5.63687L7.82843 11.0009Z"
                  fill="black"
                  fill-opacity="0.2"
                />
                <path
                  d="M7.82843 11.0009H20V13.0009H7.82843L13.1924 18.3648L11.7782 19.779L4 12.0009L11.7782 4.22266L13.1924 5.63687L7.82843 11.0009Z"
                  fill="black"
                  fill-opacity="0.2"
                />
              </svg>
            </div>

            <span className="ps-2">
              {" "}
              View Proforma Invoice
              <div id="edit_supplier_id">
                {createPoInfo.amountDetails.CUST_PI_NUM}
              </div>
            </span>
          </div>
        </div>
      </div>
      <div id="create_po_bg">
        <div className="container">
          <div id="po_logo" align="center">
            <img src={logo} alt="logo" />
            <div id="po_header_text">{createPoInfo.customerData.NAME}</div>
          </div>
          <div className="row" id="about_po_header">
            <div className="col-6">
              <div id="po_supplier_text">Supplier</div>
              <div id="po_supplier_name">{createPoInfo.customerData.NAME}</div>
              <div id="po_supplier_address">
                {createPoInfo.customerData.ADDR_1} <br />
                {createPoInfo.customerData.ADDR_2}
              </div>
              <div id="po_supplier_ph">
                TEL:{" "}
                <span id="po_supplier_no">
                  {" "}
                  {createPoInfo.customerData.CONTACT_NUMBER}{" "}
                </span>
              </div>
              <div id="po_supplier_ph">
                FAX:{" "}
                <span id="po_supplier_no">
                  {" "}
                  {createPoInfo.customerData.FAX}{" "}
                </span>
              </div>
              <div id="po_supplier_ph">
                Proforma Invoice No:{" "}
                <span id="po_supplier_no">
                  {" "}
                  {createPoInfo.amountDetails.CUST_PI_NUM}
                </span>
              </div>
              <div id="po_supplier_ph">
                Date:{" "}
                <span id="po_supplier_no">
                  {convertUnixTimestampToDate(createPoInfo.amountDetails.CREATED_ON)}{" "}
                </span>
              </div>
            </div>
            <div className="col-6 " id="about_po_left_bg" align="end">
              <div id="content_wrapper">
                {" "}
                <div id="po_supplier_text">Client</div>
                <div id="po_supplier_name">{createPoInfo.clientData.NAME}</div>
                <div id="po_supplier_address">
                  {createPoInfo.clientData.ADDR_1} <br />
                  {createPoInfo.clientData.ADDR_2}
                </div>
                <div id="po_supplier_ph">
                  TEL:{" "}
                  <span id="po_supplier_no">
                    {createPoInfo.clientData.CONTACT_NUMBER}
                  </span>
                </div>
                <div id="po_supplier_ph">
                  FAX:{" "}
                  <span id="po_supplier_no">{createPoInfo.clientData.FAX}</span>
                </div>
                <div id="po_supplier_ph">
                  Customer PO No: <span id="po_supplier_no">{createPoInfo.amountDetails.CUST_PO_NUM}</span>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              maxHeight: "fit-content",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <DataGrid
              rows={resultRows}
              columns={columns}
              pageSize={pageSize}
              hideFooterPagination={true}
              hideFooter={true}
              getRowId={(row) => row.id}
              paginationMode="server"
              rowsPerPageOptions={
                // [10, 20, 50]
                rows.length < 50
                  ? [10, 20, 50] // Default options
                  : rows.length > 20
                  ? [10, 20, rows.length] // Adjust if less than 50
                  : rows.length > 10
                  ? [10, rows.length] // Adjust if less than 20
                  : [rows.length] // Show all rows if less than 10
              }
              autoHeight
              rowHeight={80}
              showCellRightBorder
              getRowClassName={getRowClassName}
              getCellClassName={getCellClassName}
            />
          </div>
          <div className="row">
            <div className="col-5"></div>
            <div className="col-7">
              <div
                className="d-flex flex-column"
                style={{
                  gap: 20,
                  borderBottom: "0.5px solid var(--outlineVariant)",
                  marginTop: 60,
                }}
              >
                   <div className="d-flex flex-row" style={{ gap: 151 }}>
                    <h2 id="total_cost_text">
                      Total Cost in{" "}
                      {createPoInfo.amountDetails.OPERATING_CURRENCY ||
                        ""}
                    </h2>
                    <p id="total_cost">
                      {createPoInfo.amountDetails
                        .OPERATING_CURRENCY_SYMBOL || ""}
                      {createPoInfo.amountDetails.TOTAL_GOODS_AMOUNT ||
                        "N/A"}
                    </p>
                  </div>
                  <div className="d-flex flex-row" style={{ gap: 151 }}>
                    <h2 id="total_cost_text">
                      Total Cost in{" "}
                      {createPoInfo.amountDetails.INVOICE_CURRENCY || ""}{" "}
                      *{" "}
                      {createPoInfo.amountDetails.INVOICE_CURRENCY_RATE ||
                        ""}
                    </h2>
                    <p id="total_cost">
                      {createPoInfo?.amountDetails?.INVOICE_CURRENCY_SYMBOL
                        ? createPoInfo.amountDetails.INVOICE_CURRENCY_SYMBOL
                        : ""}

                      {createPoInfo?.amountDetails?.TOTAL_GOODS_AMOUNT &&
                      createPoInfo?.amountDetails?.INVOICE_CURRENCY_RATE
                        ? (
                            createPoInfo.amountDetails.TOTAL_GOODS_AMOUNT *
                            createPoInfo.amountDetails.INVOICE_CURRENCY_RATE
                          ).toFixed(2)
                        : "N/A"}
                    </p>
                  </div>
                  <div className="d-flex flex-row" style={{ gap: 151 }} F>
                    <h2 id="total_cost_text">Agent Commission {`(${createPoInfo.amountDetails.AGENTS_COMMISION_PERCENTAGE} %)`}</h2>
                    <p id="total_cost">
                      {createPoInfo.amountDetails.INVOICE_CURRENCY_SYMBOL ||" "}
                      {((createPoInfo.amountDetails.AGENTS_COMMISION_PERCENTAGE) * (createPoInfo.amountDetails.TOTAL_GOODS_AMOUNT * createPoInfo.amountDetails.INVOICE_CURRENCY_RATE)/100).toFixed(2) || "N/A"}
                    </p>
                  </div>
                  <div
                    className="d-flex flex-row"
                    style={{ gap: 119, marginBottom: 28 }}
                  >
                    <h2 id="total_cost_text" style={{ width: 264 }}>
                      Inland Transportation: {`${createPoInfo.amountDetails.INLAND_TRANS}`}
                    </h2>
                    <p id="total_cost">
                      {createPoInfo.amountDetails.INVOICE_CURRENCY_SYMBOL ||
                        ""}
                      {createPoInfo.amountDetails.SHIPPING_COST || "N/A"}
                    </p>
                  </div>
                </div>

                <div
                  className="d-flex flex-column"
                  style={{
                    gap: 20,
                    margin: "20px 72px 80px 0px",
                  }}
                >
                  <div className="d-flex flex-row" style={{ gap: 151 }}>
                    <h2 id="total_cost_text">Total Value</h2>
                    <h2 id="total_cost" style={{ fontWeight: 600 }}>
                      {createPoInfo.amountDetails.INVOICE_CURRENCY_SYMBOL ||
                        ""}
                      {createPoInfo.amountDetails.TOTAL_INV_AMOUNT || "N/A"}
                    </h2>
                  </div>
                  <div
                    className="d-flex flex-row align-items-center"
                    style={{ gap: 13 }}
                  >
                    <h2 id="total_cost_text" style={{ width: 524 }}>
                      SAY “{" "}
                      {createPoInfo.amountDetails.OPERATING_CURRENCY || " "} {""}
                      {convertAmountToText(
                        createPoInfo.amountDetails.TOTAL_INV_AMOUNT || "N/A"
                      )}
                      ” ONLY
                    </h2>
                  </div>
                </div>
              </div>
            </div>
      
          <div
            className="d-flex flex-column align-items-start"
            style={{ marginTop: 44, gap: 24, marginBottom: 191, }}
          >
            <div
              className="d-flex flex-row align-items-center"
              style={{
                gap: 12,
              }}
            >
              <div
                style={{
                  width: 8,
                  height: 44,
                  flexShrink: 0,
                  background: "var(--primparyContainer, #635bff)",
                }}
              ></div>
              <span id="customer_po_text">Customer PO File</span>
            </div>
           {file ? (
            <div
              className="d-flex flex-row align-items-center"
              style={{
                
                marginLeft: 17,
                gap: 36,
              }}
            >
              <div className="d-flex flex-row align-items-center gap-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M5 4H15V8H19V20H5V4ZM3.9985 2C3.44749 2 3 2.44405 3 2.9918V21.0082C3 21.5447 3.44476 22 3.9934 22H20.0066C20.5551 22 21 21.5489 21 20.9925L20.9997 7L16 2H3.9985ZM10.4999 7.5C10.4999 9.07749 10.0442 10.9373 9.27493 12.6534C8.50287 14.3757 7.46143 15.8502 6.37524 16.7191L7.55464 18.3321C10.4821 16.3804 13.7233 15.0421 16.8585 15.49L17.3162 13.5513C14.6435 12.6604 12.4999 9.98994 12.4999 7.5H10.4999ZM11.0999 13.4716C11.3673 12.8752 11.6042 12.2563 11.8037 11.6285C12.2753 12.3531 12.8553 13.0182 13.5101 13.5953C12.5283 13.7711 11.5665 14.0596 10.6352 14.4276C10.7999 14.1143 10.9551 13.7948 11.0999 13.4716Z"
                    fill="var(--onSurface)"
                  />
                  <path
                    d="M5 4H15V8H19V20H5V4ZM3.9985 2C3.44749 2 3 2.44405 3 2.9918V21.0082C3 21.5447 3.44476 22 3.9934 22H20.0066C20.5551 22 21 21.5489 21 20.9925L20.9997 7L16 2H3.9985ZM10.4999 7.5C10.4999 9.07749 10.0442 10.9373 9.27493 12.6534C8.50287 14.3757 7.46143 15.8502 6.37524 16.7191L7.55464 18.3321C10.4821 16.3804 13.7233 15.0421 16.8585 15.49L17.3162 13.5513C14.6435 12.6604 12.4999 9.98994 12.4999 7.5H10.4999ZM11.0999 13.4716C11.3673 12.8752 11.6042 12.2563 11.8037 11.6285C12.2753 12.3531 12.8553 13.0182 13.5101 13.5953C12.5283 13.7711 11.5665 14.0596 10.6352 14.4276C10.7999 14.1143 10.9551 13.7948 11.0999 13.4716Z"
                    fill="black"
                    fill-opacity="0.2"
                  />
                  <path
                    d="M5 4H15V8H19V20H5V4ZM3.9985 2C3.44749 2 3 2.44405 3 2.9918V21.0082C3 21.5447 3.44476 22 3.9934 22H20.0066C20.5551 22 21 21.5489 21 20.9925L20.9997 7L16 2H3.9985ZM10.4999 7.5C10.4999 9.07749 10.0442 10.9373 9.27493 12.6534C8.50287 14.3757 7.46143 15.8502 6.37524 16.7191L7.55464 18.3321C10.4821 16.3804 13.7233 15.0421 16.8585 15.49L17.3162 13.5513C14.6435 12.6604 12.4999 9.98994 12.4999 7.5H10.4999ZM11.0999 13.4716C11.3673 12.8752 11.6042 12.2563 11.8037 11.6285C12.2753 12.3531 12.8553 13.0182 13.5101 13.5953C12.5283 13.7711 11.5665 14.0596 10.6352 14.4276C10.7999 14.1143 10.9551 13.7948 11.0999 13.4716Z"
                    fill="black"
                    fill-opacity="0.2"
                  />
                  <path
                    d="M5 4H15V8H19V20H5V4ZM3.9985 2C3.44749 2 3 2.44405 3 2.9918V21.0082C3 21.5447 3.44476 22 3.9934 22H20.0066C20.5551 22 21 21.5489 21 20.9925L20.9997 7L16 2H3.9985ZM10.4999 7.5C10.4999 9.07749 10.0442 10.9373 9.27493 12.6534C8.50287 14.3757 7.46143 15.8502 6.37524 16.7191L7.55464 18.3321C10.4821 16.3804 13.7233 15.0421 16.8585 15.49L17.3162 13.5513C14.6435 12.6604 12.4999 9.98994 12.4999 7.5H10.4999ZM11.0999 13.4716C11.3673 12.8752 11.6042 12.2563 11.8037 11.6285C12.2753 12.3531 12.8553 13.0182 13.5101 13.5953C12.5283 13.7711 11.5665 14.0596 10.6352 14.4276C10.7999 14.1143 10.9551 13.7948 11.0999 13.4716Z"
                    fill="black"
                    fill-opacity="0.2"
                  />
                  <path
                    d="M5 4H15V8H19V20H5V4ZM3.9985 2C3.44749 2 3 2.44405 3 2.9918V21.0082C3 21.5447 3.44476 22 3.9934 22H20.0066C20.5551 22 21 21.5489 21 20.9925L20.9997 7L16 2H3.9985ZM10.4999 7.5C10.4999 9.07749 10.0442 10.9373 9.27493 12.6534C8.50287 14.3757 7.46143 15.8502 6.37524 16.7191L7.55464 18.3321C10.4821 16.3804 13.7233 15.0421 16.8585 15.49L17.3162 13.5513C14.6435 12.6604 12.4999 9.98994 12.4999 7.5H10.4999ZM11.0999 13.4716C11.3673 12.8752 11.6042 12.2563 11.8037 11.6285C12.2753 12.3531 12.8553 13.0182 13.5101 13.5953C12.5283 13.7711 11.5665 14.0596 10.6352 14.4276C10.7999 14.1143 10.9551 13.7948 11.0999 13.4716Z"
                    fill="black"
                    fill-opacity="0.2"
                  />
                </svg>
                <p id="filename">{fileName}</p>
              </div>
              <Button id="view_cons_file" style={{ width: 98 }} onClick={handleFile}>
                <h2 id="view">View</h2>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M16.0052 9.41421L7.39858 18.0208L5.98438 16.6066L14.591 8H7.00519V6H18.0052V17H16.0052V9.41421Z"
                    fill="#635BFF"
                  />
                </svg>
              </Button>
            </div>
            ):(<p style={{ marginLeft: 22}}>N/A</p>)}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewPI;
