import React, { useCallback, useDebugValue, useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { FormCheck } from "react-bootstrap";
import { useEffect } from "react";
import SearchBar from "../../StaticComponents/SearchBar";
import { makeApiCall } from "../../StaticComponents/API";
import { debounce } from 'lodash';

const CustomerFilters = (props) => {
  const [Location, setLocation] = useState(true);
  const [CustomerName, setCustomerName] = useState(false);
  const [CustomerType, setCustomerType] = useState(false);
  const [CustomerStatus, setCustomerStatus] = useState(false);

  const [Customers, setCustomers] = useState([]);
  const [countries, setcountries] = useState([]);
  const [CustomersType, setCustomersType] = useState([]);
  const [CustomersStatus, setCustomersStatus] = useState([]);

  const [custsearch, setcustsearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMoreCustomers, setHasMoreCustomers] = useState(true);
  const [loading, setLoading] = useState(false);
  const [showText, setShowText] = useState(true);
  const containerRef = useRef(null);
  let token = localStorage.getItem("token");

  const onSearch = (value) => {
    setcustsearch(value);
    currentPageRef.current = 1;

    // Optionally, fetch new suppliers immediately after search
    setHasMoreCustomers(true); // Reset to allow further fetching
    setCustomers([]);
  };
  const [locationname, setlocationname] = useState("");

  const [modalShow, setModalShow] = React.useState(false);
  const APP_CLIENT_ID = localStorage.getItem("CLIENT_ID");
  const [filtercount, setfiltercount] = useState(0);
  const customersContainerRef = useRef(null);

  const [selectedFilters, setSelectedFilters] = useState({
    location: [],
    customerName: [],
    customerType: [],
    customerStatus: [],
  });
  const handleEvent = () => {
    setLocation(true);
    setCustomerName(false);
    setCustomerType(false);
    setCustomerStatus(false);
  };

  const handleCustomerStatus = () => {
    setCustomerType(true);
    setCustomerName(false);
    setLocation(false);
    setCustomerStatus(false);
  };
  const handleCustomerName = () => {
    setCustomerType(false);
    setCustomerName(true);
    setLocation(false);
    setCustomerStatus(false);
  };

  const handleAtendee = () => {
    setCustomerStatus(true);
    setLocation(false);
    setCustomerType(false);
    setCustomerName(false);
  };


  useEffect(() => {
    let count = 0;
    if (selectedFilters.customerName.length > 0) {
      count += 1;
    }
    if (selectedFilters.location.length > 0) {
      count += 1;
    }
    if (selectedFilters.customerStatus.length > 0) {
      count += 1;
    }
    if (selectedFilters.customerType.length > 0) {
      count += 1;
    }
    setfiltercount(count);
    props.onNotify(count);
  }, [selectedFilters]);

  useEffect(() => {
    fetchCustomers();
  }, [custsearch]);


  const fetchCustomers = () => {
    if (loading || !hasMoreCustomers) return;
    setLoading(true);

    const payload = {
      APP_CLIENT_ID: APP_CLIENT_ID,
      SEARCH_KEYWORD: custsearch,
      CURRENT_PAGE: currentPageRef.current,
    };
    makeApiCall({
      mod: "Customer",
      action: "customer-name-filter",
      payload: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        console.log(
          "Customers names filtered successfully",
          response.XscData.CUSTOMER_LIST
        );
        if (Array.isArray(response.XscData.CUSTOMER_LIST)) {
          setCustomers(prevCustomers => [
            ...prevCustomers,
            ...response.XscData.CUSTOMER_LIST
          ]);
          currentPageRef.current += 1;
          setCurrentPage((prevPage) => prevPage + 1);
          setHasMoreCustomers(response.XscData.CUSTOMER_LIST.length > 0);
        } else {
          console.error("Unexpected data ", response.XscData.CUSTOMER_LIST);
          setHasMoreCustomers(false);
        }
        setLoading(false);
      })
      .catch((error) =>{ console.error("Error fetching data:", error);
        setLoading(false);
      });
  };
  console.log(currentPage);

  const modalContent = document.querySelector("#filter_content_bg"); 
  const loadingRef = useRef(loading);
  const hasMoreRef = useRef(hasMoreCustomers);
  const currentPageRef = useRef(currentPage);
  useEffect(() => {
    loadingRef.current = loading;
    hasMoreRef.current = hasMoreCustomers;
  }, [loading, hasMoreCustomers]);

  useEffect(() => {
    let debounceTimeout;

    const handleScroll = () => {
      clearTimeout(debounceTimeout);

      debounceTimeout = setTimeout(() => {
        if (
          modalContent.scrollHeight - modalContent.scrollTop <=
            modalContent.clientHeight + 800 &&
          !loadingRef.current &&
          hasMoreRef.current
        ) {
          fetchCustomers();
        }
      }, 400); 
    };

    if (modalContent) {
      modalContent.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (modalContent) {
        modalContent.removeEventListener("scroll", handleScroll);
      }
    };
  }, [modalContent]);


  useEffect(() => {
    const payload = {
      CURRENT_PAGE: "",
      SEARCH_KEYWORD: locationname,
      ITEM_PER_PAGE: "",
    };
    makeApiCall({
      mod: "Location",
      action: "get-countries",
      payload: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        console.log(
          "Location filtered successfully",
          response.XscData.COUNTRY_NAMES
        );
        if (Array.isArray(response.XscData.COUNTRY_NAMES)) {
          setcountries(response.XscData.COUNTRY_NAMES);
        } else {
          console.error("Unexpected data", response.XscData.COUNTRY_LIST);
        }
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, [locationname]);

  useEffect(() => {
    makeApiCall({
      mod: "Filters",
      action: "type-filter",
      Payload: { APP_CLIENT_ID: APP_CLIENT_ID },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        console.log(
          "Customers TYPE filtered successfully",
          response.XscData.FAV_FILTER
        );
        if (Array.isArray(response.XscData.FAV_FILTER)) {
          setCustomersType(response.XscData.FAV_FILTER);
        } else {
          console.error("Unexpected data ", response.XscData.FAV_FILTER);
        }
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  useEffect(() => {
    makeApiCall({
      mod: "Filters",
      action: "status-filter",
      Payload: { APP_CLIENT_ID: APP_CLIENT_ID },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        console.log(
          "Customers status filtered successfully",
          response.XscData.STATUS_FILTER
        );
        if (Array.isArray(response.XscData.STATUS_FILTER)) {
          setCustomersStatus(response.XscData.STATUS_FILTER);
        } else {
          console.error("Unexpected data ", response.XscData.STATUS_FILTER);
        }
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, []);
  console.log("CUstoer list", Customers);

  const handleCheckboxChange = (filterType, value) => {
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      [filterType]: prevFilters[filterType].includes(value)
        ? prevFilters[filterType].filter((item) => item !== value)
        : [...prevFilters[filterType], value],
    }));
  };

  const handleSearch = (value) => {
     setlocationname(value);
  }
  console.log(selectedFilters);
  console.log("countries", countries);
  const handleRemoveFilter = (filterType, value) => {
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      [filterType]: prevFilters[filterType].filter((item) => item !== value),
    }));
  };
  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="filter_text d-flex gap-3"
          >
            Filters{" "}
            {filtercount > 0 ? (
              <div>({filtercount} selected)</div>
            ) : (
              <div></div>
            )}
          </Modal.Title>
        </Modal.Header>

        <div id="selected_fil">
          {
            /*Location &&*/
            selectedFilters["location"].map((place,index) => (
              <div id="selected_filters_bg" key={index}>
                {place}
                <svg
                onClick={()=> handleRemoveFilter("location",place)}
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M7.99967 14.6654C4.31777 14.6654 1.33301 11.6806 1.33301 7.9987C1.33301 4.3168 4.31777 1.33203 7.99967 1.33203C11.6815 1.33203 14.6663 4.3168 14.6663 7.9987C14.6663 11.6806 11.6815 14.6654 7.99967 14.6654ZM7.99967 7.0559L6.11405 5.17027L5.17125 6.11308L7.05687 7.9987L5.17125 9.8843L6.11405 10.8271L7.99967 8.9415L9.88527 10.8271L10.8281 9.8843L8.94247 7.9987L10.8281 6.11308L9.88527 5.17027L7.99967 7.0559Z"
                    fill="#5D6B82"
                  />
                </svg>
              </div>
            ))
          }
          {
            /*CustomerName &&*/
            selectedFilters["customerName"].map((name) => (
              <div id="selected_filters_bg">
                {name}
                <svg
                 onClick={()=> handleRemoveFilter("customerName",name)}
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M7.99967 14.6654C4.31777 14.6654 1.33301 11.6806 1.33301 7.9987C1.33301 4.3168 4.31777 1.33203 7.99967 1.33203C11.6815 1.33203 14.6663 4.3168 14.6663 7.9987C14.6663 11.6806 11.6815 14.6654 7.99967 14.6654ZM7.99967 7.0559L6.11405 5.17027L5.17125 6.11308L7.05687 7.9987L5.17125 9.8843L6.11405 10.8271L7.99967 8.9415L9.88527 10.8271L10.8281 9.8843L8.94247 7.9987L10.8281 6.11308L9.88527 5.17027L7.99967 7.0559Z"
                    fill="#5D6B82"
                  />
                </svg>
              </div>
            ))
          }
          {
            /*CustomerType &&*/
            selectedFilters["customerType"].map((type) => (
              <div id="selected_filters_bg">
                {type}
                <svg
                   onClick={()=> handleRemoveFilter("customerType",type)}
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M7.99967 14.6654C4.31777 14.6654 1.33301 11.6806 1.33301 7.9987C1.33301 4.3168 4.31777 1.33203 7.99967 1.33203C11.6815 1.33203 14.6663 4.3168 14.6663 7.9987C14.6663 11.6806 11.6815 14.6654 7.99967 14.6654ZM7.99967 7.0559L6.11405 5.17027L5.17125 6.11308L7.05687 7.9987L5.17125 9.8843L6.11405 10.8271L7.99967 8.9415L9.88527 10.8271L10.8281 9.8843L8.94247 7.9987L10.8281 6.11308L9.88527 5.17027L7.99967 7.0559Z"
                    fill="#5D6B82"
                  />
                </svg>
              </div>
            ))
          }
          {
            /*CustomerStatus &&*/
            selectedFilters["customerStatus"].map((status) => (
              <div id="selected_filters_bg">
                {status}
                <svg
                 onClick={()=> handleRemoveFilter("customerStatus",status)}
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M7.99967 14.6654C4.31777 14.6654 1.33301 11.6806 1.33301 7.9987C1.33301 4.3168 4.31777 1.33203 7.99967 1.33203C11.6815 1.33203 14.6663 4.3168 14.6663 7.9987C14.6663 11.6806 11.6815 14.6654 7.99967 14.6654ZM7.99967 7.0559L6.11405 5.17027L5.17125 6.11308L7.05687 7.9987L5.17125 9.8843L6.11405 10.8271L7.99967 8.9415L9.88527 10.8271L10.8281 9.8843L8.94247 7.9987L10.8281 6.11308L9.88527 5.17027L7.99967 7.0559Z"
                    fill="#5D6B82"
                  />
                </svg>
              </div>
            ))
          }
        </div>
        <div id="filter_content_bg">
          <div id="fil_side_menu_bg">
            <div id="fil_side_menu">
              <div className="position-relative d-flex align-items-center">
                {Location && <div id="active_fil_indicator" className=""></div>}
                <div
                  id="single_fil_menu"
                  onClick={handleEvent}
                  style={{
                    color: Location && "var(--primaryContainer)",
                    fontWeight: Location && "600",
                    cursor: "pointer",
                  }}
                >
                  Location
                </div>
              </div>
            </div>
            <div id="fil_side_menu">
              <div className="position-relative d-flex align-items-center">
                {CustomerName && (
                  <div id="active_fil_indicator" className=""></div>
                )}
                <div
                  id="single_fil_menu"
                  onClick={handleCustomerName}
                  style={{
                    color: CustomerName && "var(--primaryContainer)",
                    fontWeight: CustomerName && "600",
                    cursor: "pointer",
                  }}
                >
                  Customer Name
                </div>
              </div>
            </div>
            <div
              id="fil_side_menu"
              onClick={handleCustomerStatus}
              style={{ cursor: "pointer" }}
            >
              <div className="position-relative d-flex align-items-center">
                {CustomerType && (
                  <div id="active_fil_indicator" className=""></div>
                )}

                <div
                  id="single_fil_menu"
                  style={{
                    color: CustomerType && "var(--primaryContainer)",
                    fontWeight: CustomerType && "600",
                  }}
                >
                  Customer Type
                </div>
              </div>
            </div>
            <div
              id="fil_side_menu"
              onClick={handleAtendee}
              style={{ cursor: "pointer" }}
            >
              <div className="position-relative d-flex align-items-center">
                {CustomerStatus && (
                  <div id="active_fil_indicator" className=""></div>
                )}

                <div
                  id="single_fil_menu"
                  style={{
                    color: CustomerStatus && "var(--primaryContainer)",
                    fontWeight: CustomerStatus && "600",
                  }}
                >
                  Customer Status
                </div>
              </div>
            </div>
          </div>

          {/* for Location */}
          {Location && (
            <div id="fil_content_bg">
              <SearchBar onSearch={handleSearch}/>

              {Array.isArray(countries) && countries.length > 0 ? (
                countries.map((country) => (
                  <div
                    className="d-flex align-items-center justify-content-center"
                    id="fil_single_item"
                    key={country.ID}
                  >
                   
                    <label
                      className="checkbox d-flex align-items-center"
                      id="fil_single_item"
                    >
                      <FormCheck
                        type="checkbox"
                        // id="default-checkbox"
                        className="checkbox"
                        checked={selectedFilters.location.includes(
                          country.COUNTRY
                        )}
                        onChange={() =>
                          handleCheckboxChange("location", country.COUNTRY)
                        }
                      ></FormCheck>
                    
                    <div>{country.COUNTRY}</div>
                    </label>
                  </div>
                ))
              ) : (
                <div>No Customers available</div>
              )}
            </div>
          )}
          {/* for Customer name */}
          {CustomerName && (
            <div id="fil_content_bg">
              <SearchBar onSearch={onSearch} />

              {Array.isArray(Customers) && Customers.length > 0 ? (
                Customers.map((customer) => (
                  <div
                    className="d-flex align-items-center justify-content-center"
                    id="fil_single_item"
                    key={Customers.CLIENT_CUST_ID}
                  >
                    <label
                      className="checkbox d-flex align-items-center"
                      id="fil_single_item"
                    >
                      <FormCheck
                        type="checkbox"
                        // id="default-checkbox"
                        className="checkbox"
                        checked={selectedFilters.customerName.includes(
                          customer.CUSTOMERS_NAME
                        )}
                        onChange={() =>
                          handleCheckboxChange(
                            "customerName",
                            customer.CUSTOMERS_NAME
                          )
                        }
                      ></FormCheck>
                   
                    <div>{customer.CUSTOMERS_NAME}</div>
                    </label>
                  </div>
                ))
              ) : (
                <div>No Customers available</div>
              )}
            </div>
          )}

          {/* for CustomerType */}
          {CustomerType && (
            <div id="fil_content_bg">
              {Array.isArray(CustomersType) && CustomersType.length > 0 ? (
                CustomersType.map((type) => (
                  <div
                    //   style={{ marginTop: "1.5rem" }}
                    key={type.IS_FAV}
                    className="d-flex align-items-center "
                    id="fil_single_item"
                    style={{
                      marginBottom: "0px",
                    }}
                  >
                   <label
                      className="checkbox d-flex align-items-center"
                      id="fil_single_item"
                    >
                      <FormCheck
                        type="checkbox"
                        // id="default-checkbox"
                        className="checkbox"
                        checked={selectedFilters.customerType.includes(
                          type.TYPE
                        )}
                        onChange={() =>
                          handleCheckboxChange("customerType", type.TYPE)
                        }
                      ></FormCheck>
                 
                    <div>{type.TYPE} Customers</div>
                    </label>
                  </div>
                ))
              ) : (
                <div>No Customer Types Available</div>
              )}
            </div>
          )}
          {/* for CustomerStatus */}
          {CustomerStatus && (
            <div id="fil_content_bg">
              {Array.isArray(CustomersStatus) && CustomersStatus.length > 0 ? (
                CustomersStatus.map((status) => (
                  <div
                    style={{ marginBottom: "0px" }}
                    className="d-flex align-items-center "
                    id="fil_single_item"
                    key={status.STATUS}
                  >
                   <label
                      className="checkbox d-flex align-items-center"
                      id="fil_single_item"
                    >
                      <FormCheck
                        type="checkbox"
                        // id="default-checkbox"
                        className="checkbox"
                        checked={selectedFilters.customerStatus.includes(
                          status.TYPE
                        )}
                        onChange={() =>
                          handleCheckboxChange("customerStatus", status.TYPE)
                        }
                      ></FormCheck>
                    
                    <div>{status.TYPE}</div>
                    </label>
                  </div>
                ))
              ) : (
                <div>No Customer Status Available</div>
              )}
            </div>
          )}
        </div>

        <Modal.Footer>
        <button
            id="reset_btn"
            className="btn"
            onClick={() =>
              setSelectedFilters({
                location: [],
                customerName: [],
                customerType: [],
                customerStatus: [],
              })
            }
          >
            Reset{" "}
          </button>
          <button id="apply_btn" className="btn">
            Apply Filters
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CustomerFilters;
