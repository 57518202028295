import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { FormCheck } from "react-bootstrap";
import slider from "../../../assets/slider min.png";
import ProgressBar from "../../../assets/Rectangle 53.png";
import Slider from "react-slider";
// import "rc-slider/assets/index.css";
import "../../../Styles/ProductFilter.css";
import TextInputWithAll from "../../Inputs/TextInputWithAll";
// import { Range } from "rc-slider";
import { makeApiCall } from "../../../StaticComponents/API";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

const ProductFilter = (props) => {
  const [ProductPrice, setProductPrice] = useState(true);
  const [ProductStatus, setProductStatus] = useState([]);
  const [value, setValue] = useState(0);
  const [productsStatus, setProductsStatus] = useState(false);
  const [priceRange, setPriceRange] = useState({ min: 0, max: 0 });
  const [errorMessage, setErrorMessage] = useState("");
  let [searchParams] = useSearchParams();
  const CLIENT_SUPPLIER_ID = searchParams.get("id");
  const APP_CLIENT_ID=localStorage.getItem("CLIENT_ID")
  const [selectedFilters, setSelectedFilters] = useState({
    ProductPrice: [],
    ProductStatus: [],
  });
  const navigate = useNavigate();

  let token = localStorage.getItem("token");

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const handleProductPrice = () => {
    setProductPrice(true);
    setProductsStatus(false);
  };

  const handleProductStatus = () => {
    setProductPrice(false);
    setProductsStatus(true);
  };
  const [filtercount, setfiltercount] = useState(0);

  useEffect(() => {
    let count = 0;
    if (selectedFilters.ProductStatus.length > 0) {
      count += 1;
    }
    if (selectedFilters.ProductPrice.length > 0) {
      count += 1;
    }
    setfiltercount(count);
    props.onNotify(count);
  }, [selectedFilters, priceRange]);

  useEffect(() => {
    makeApiCall({
      mod: "Filters",
      action: "status-filter",
      Payload: { APP_CLIENT_ID: APP_CLIENT_ID },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        console.log(response);
        console.log(
          "Supplier status filtered successfully",
          response.XscData.STATUS_FILTER
        );
        if (Array.isArray(response.XscData.STATUS_FILTER)) {
          setProductStatus(response.XscData.STATUS_FILTER);
        } else {
          console.error("Unexpected data ", response.XscData.STATUS_FILTER);
        }
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  useEffect(() => {
    makeApiCall({
      mod: "Products",
      action: "filter-product-price",
      payload: { APP_CLIENT_ID: APP_CLIENT_ID },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        console.log(response);
        console.log("Product price filtered successfully", response.XscData);
        if (response.XscData[0].MAX && response.XscData[0].MIN) {
          console.log(response.XscData[0].MAX);
          setPriceRange({
            min: response.XscData[0].MIN,
            max: response.XscData[0].MAX,
          });
        }
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  const handleInputChange = (e, type) => {
    const value = e.target.value;

    // If input is empty, set the value to an empty string to avoid NaN
    if (value === "") {
      setPriceRange((prev) => ({
        ...prev,
        [type]: "",
      }));
    } else {
      const parsedValue = parseFloat(value);

      // Only update the value if it's a valid number
      if (!isNaN(parsedValue)) {
        setPriceRange((prev) => ({
          ...prev,
          [type]: parsedValue,
        }));
      }
    }
  };

  useEffect(() => {
    setSelectedFilters((prev) => ({
      ...prev,
      ProductPrice: [`¥${priceRange.min}`, `¥${priceRange.max}`],
    }));
  }, [priceRange]);

  const handleStatusChange = (statusType) => {
    setSelectedFilters((prev) => ({
      ...prev,
      ProductStatus: prev.ProductStatus.includes(statusType)
        ? prev.ProductStatus.filter((item) => item !== statusType)
        : [...prev.ProductStatus, statusType],
    }));
  };
  const validatePrices = () => {
    if (
      priceRange.min !== "" &&
      priceRange.max !== "" &&
      priceRange.min > priceRange.max
    ) {
      toast.error("Max Price cannot be less than Min Price");
      return false;
    }
    setErrorMessage("");
    return true;
  };

  const handleFilter = async (e) => {
    e.preventDefault();
    if (validatePrices()) {
      // Proceed with form submission or API call
      console.log("Form submitted successfully", priceRange);
      props.onHide();
    } else {
      // Show error message if validation fails
      console.error("Validation error: Max Price is less than Min Price");
    }
    const payload = {
      CLIENT_SUPPLIER_ID: CLIENT_SUPPLIER_ID,
      CURRENT_PAGE: 1,
      FILTER: {
        PRICE: {
          MIN: priceRange[0],
          MAX: priceRange[1],
        },
        STATUS:
          selectedFilters.ProductStatus.length > 0
            ? selectedFilters.ProductStatus[0]
            : 1,
        KEYWORD: "",
      },
    };

    try {
      const response = await makeApiCall({
        mod: "Products",
        action: "list-supplier-product",
        payload: payload,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      console.log("Products  filtered successfully", response);

      if (response && response.XscMessage) {
        toast.success(response.XscMessage);
      }
      navigate(`/suppliers/supplier-info?id=${CLIENT_SUPPLIER_ID}`);
    } catch (error) {
      console.error("Error filtering suppliers:", error);
      toast.error("An error occurred while filtering suppliers.");
    }
  };

  const handleCheckboxChange = (filterType, value) => {
    setSelectedFilters((prevFilters) => {
      if (filterType === "ProductStatus") {
        return {
          ...prevFilters,
          ProductStatus: prevFilters.ProductStatus.includes(value)
            ? prevFilters.ProductStatus.filter((item) => item !== value)
            : [value],
          orderStatus: prevFilters.orderStatus,
        };
      }
      return {
        ...prevFilters,
        [filterType]: prevFilters[filterType].includes(value)
          ? prevFilters[filterType].filter((item) => item !== value)
          : [...prevFilters[filterType], value],
      };
    });
  };

  const handleRemoveFilter = (filterType, value) => {
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      [filterType]: prevFilters[filterType].filter((item) => item !== value),
    }));
  };

  console.log(productsStatus);

  // console.log(priceRange.min, priceRange.max);
  // console.log(CLIENT_SUPPLIER_ID);

  const handleApplyFilters = () => {
    props.onNotify(selectedFilters);
    props.onHide();
  };
  console.log(selectedFilters, "selected filters");

  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="filter_text d-flex"
          >
            Filters{" "}
            {filtercount > 0 ? (
              <div> ({filtercount} selected)</div>
            ) : (
              <div></div>
            )}
          </Modal.Title>
        </Modal.Header>

        <div id="selected_fil">
          <div
            className="selected-filters-bg d-flex"
            style={{ paddingLeft: "12px" }}
          >
            {selectedFilters.ProductPrice.length > 0 && (
              <span className="filter-tag" style={{ marginTop: "8px" }}>
                Price: {selectedFilters.ProductPrice.join(" - ")}
                <svg
                  onClick={() =>
                    setSelectedFilters((prev) => ({
                      ...prev,
                      ProductPrice: [],
                    }))
                  }
                  className="remove-filter-btn"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M7.99967 14.6667C4.31777 14.6667 1.33301 11.6819 1.33301 7.99999C1.33301 4.31809 4.31777 1.33333 7.99967 1.33333C11.6815 1.33333 14.6663 4.31809 14.6663 7.99999C14.6663 11.6819 11.6815 14.6667 7.99967 14.6667ZM7.99967 7.05719L6.11405 5.17157L5.17125 6.11437L7.05687 7.99999L5.17125 9.88559L6.11405 10.8284L7.99967 8.94279L9.88527 10.8284L10.8281 9.88559L8.94247 7.99999L10.8281 6.11437L9.88527 5.17157L7.99967 7.05719Z"
                    fill="#535260"
                  />
                </svg>
              </span>
            )}
            {selectedFilters.ProductStatus.length > 0 &&
              selectedFilters.ProductStatus.map((filter, index) => (
                <span
                  key={index}
                  className="filter-tag"
                  style={{ marginTop: "8px" }}
                >
                  {filter}

                  <svg
                    onClick={() => handleRemoveFilter("ProductStatus", filter)}
                    className="remove-filter-btn"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M7.99967 14.6667C4.31777 14.6667 1.33301 11.6819 1.33301 7.99999C1.33301 4.31809 4.31777 1.33333 7.99967 1.33333C11.6815 1.33333 14.6663 4.31809 14.6663 7.99999C14.6663 11.6819 11.6815 14.6667 7.99967 14.6667ZM7.99967 7.05719L6.11405 5.17157L5.17125 6.11437L7.05687 7.99999L5.17125 9.88559L6.11405 10.8284L7.99967 8.94279L9.88527 10.8284L10.8281 9.88559L8.94247 7.99999L10.8281 6.11437L9.88527 5.17157L7.99967 7.05719Z"
                      fill="#535260"
                    />
                  </svg>
                </span>
              ))}
          </div>
        </div>
        <div id="filter_content_bg">
          <div id="fil_side_menu_bg">
            <div id="fil_side_menu">
              <div className="position-relative d-flex align-items-center">
                {ProductPrice && (
                  <div id="active_fil_indicator" className=""></div>
                )}
                <div
                  id="single_fil_menu"
                  onClick={handleProductPrice}
                  style={{
                    color: ProductPrice && "var(--primaryContainer)",
                    fontWeight: ProductPrice && "600",
                    cursor: "pointer",
                  }}
                >
                  Product Price
                </div>
              </div>
            </div>
            <div id="fil_side_menu">
              <div className="position-relative d-flex align-items-center">
                {productsStatus && (
                  <div id="active_fil_indicator" className=""></div>
                )}
                <div
                  id="single_fil_menu"
                  onClick={handleProductStatus}
                  style={{
                    color: productsStatus && "var(--primaryContainer)",
                    fontWeight: productsStatus && "600",
                    cursor: "pointer",
                  }}
                >
                  Product Status
                </div>
              </div>
            </div>
            {/* <div id="fil_side_menu">
				<div className="position-relative d-flex align-items-center">
				  {paymentsStatus && (
					<div
					  id="active_fil_indicator"
					  className=""
					></div>
				  )}
				  <div
					id="single_fil_menu"
					onClick={handlePaymentStatus}
					style={{
					  color: paymentsStatus && "var(--primaryContainer)",
					  fontWeight: paymentsStatus && "600",
					  cursor: "pointer",
					}}
				  >
					Payment Status
				  </div>
				</div>
			  </div> */}
          </div>
          {ProductPrice && (
            <div id="fil_content_bg">
              <div
                className="d-flex align-items-center justify-content-center"
                id="fil_single_item"
              >
                {" "}
                <div>
                  {" "}
                  <div className="prod_price">
                    <div className="prod_price_left">
                      <div className="prod_price_left_text">
                        <div className="prod_price_left_text1">Min Price</div>
                        <div className="prod_price_left_text2">
                          ¥{" "}
                          <input
                            type="text"
                            value={priceRange.min}
                            onChange={(e) => handleInputChange(e, "min")}
                            className="price-input"
                            style={{
                              width: "172px",
                              height: "40px",
                              border: "none",
                              outline: "none",
                              paddingLeft: "0",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="divider_price"></div>
                    <div className="prod_price_right">
                      <div className="prod_price_left_text">
                        <div className="prod_price_left_text1">Max Price</div>
                        <div className="prod_price_left_text2">
                          ¥{" "}
                          <input
                            type="text"
                            value={priceRange.max}
                            onChange={(e) => handleInputChange(e, "max")}
                            className="price-input"
                            style={{
                              width: "172px",
                              height: "40px",
                              border: "none",
                              outline: "none",
                              paddingLeft: "0",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* {ProductStatus && (
            <div id="fil_content_bg">
              {productsStatus.map((status, index) => (
                <div className="d-flex align-items-center" id="fil_single_item">
                  <label
                    className="d-flex align-items-center"
                    id="fil_single_item"
                  >
                    <div>
                      <FormCheck
                        type="checkbox"
                        // id="default-checkbox"
                        onChange={() => handleStatusChange(status.TYPE)}
                        checked={selectedFilters.ProductStatus.includes(
                          status.TYPE
                        )}
                        className="checkbox"
                      ></FormCheck>
                    </div>
                    <div>{status.TYPE}</div>
                  </label>
                </div>
              ))}
            </div>
          )} */}

          {productsStatus && (
            <div id="fil_content_bg">
              {Array.isArray(ProductStatus) && ProductStatus.length > 0 ? (
                ProductStatus.map((ProductStatus) => (
                  <div
                    className="d-flex align-items-center justify-content-center"
                    id="fil_single_item"
                    key={ProductStatus.STATUS}
                    onClick={() =>
                      handleCheckboxChange("ProductStatus", ProductStatus.TYPE)
                    }
                  >
                    <div>
                      <FormCheck
                        type="checkbox"
                        className="checkbox"
                        checked={selectedFilters.ProductStatus.includes(
                          ProductStatus.TYPE
                        )}
                      ></FormCheck>
                    </div>
                    <div>{ProductStatus.TYPE}</div>
                  </div>
                ))
              ) : (
                <div>No Payment Status available</div>
              )}
            </div>
          )}
        </div>

        {/* product price */}

        <Modal.Footer>
          <button
            id="reset_btn"
            className="btn"
            onClick={() =>
              setSelectedFilters({
                ProductPrice: [],
                ProductStatus: [],
              })
            }
          >
            Reset{" "}
          </button>
          <button id="apply_btn" className="btn" onClick={handleApplyFilters}>
            Apply Filters
          </button>
        </Modal.Footer>
      </Modal>
      <ToastContainer
        position="bottom-center"
        style={{ width: "max-content" }}
      />
    </>
  );
};

export default ProductFilter;
