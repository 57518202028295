import React, { useState, useCallback, useEffect, useRef } from "react";
import logo from "../../../assets/Manex Logo.png";
import debounce from "lodash/debounce"; // You'll need lodash for debouncing
import { DataGrid } from "@mui/x-data-grid";
import po_img from "../../../assets/po_img.png";
import SearchPO from "./SearchPO";
import SupplierPurchaseHistory from "../PriceHistory/SupplierPurchaseHistory"
import PurchaseHistory from "../Products/PurchaseHistory";
import { makeStyles } from "@material-ui/styles";
import TextInput from "../../Inputs/TextInput";
import TextInputWithAll from "../../Inputs/TextInputWithAll";
import DropDownInputWithoutLabel from "../../Inputs/DropDownInputWithoutLabel";
import DropDownInput from "../../Inputs/DropDownInput";
import { makeApiCall } from "../../../StaticComponents/API";
import "../../../Styles/CreatePO.css";
import { useLocation, useSearchParams } from "react-router-dom";
import { Autocomplete, TextField } from "@mui/material";
import "../../../Styles/createPOnew.css";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import default_image from "../../../assets/Product_Package.png";

import FormatCurrency from "../../../StaticComponents/FormatCurrency";
import USDCurrency from "../../../StaticComponents/USDCurrency";
import PreviewPO from "./PreviewPO";
// import { useLocation } from "react-router-dom";
import Stack from "@mui/material/Stack";
const useStyles = makeStyles({
  subheadingRow: {
    textAlign: "center",
    fontWeight: "bold",
    minHeight: "32px !important",
    maxHeight: "32px !important",
    "& .MuiDataGrid-cell": {
      border: "none !important",
      lineHeight: "32px !important",
      minHeight: "32px !important",
      maxHeight: "32px !important",
      zIndex: 10,
    },
  },

  subheadingCell: {
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    paddingLeft: 10,
    width: "100%",
    border: "0.5px solid var(--outlineVariant) !important",
    lineHeight: "24px !important",
    minHeight: "32px !important",
    maxHeight: "32px !important",
    zIndex: 10,
    display:"flex",
    justifyContent:"center"
  },

  padding: { padding: "0px !important" },
});

function CreatePO() {
  const API_URL=process.env.REACT_APP_API_URL;
 
  const [CurrencySymbol,setCurrencySymbol]=useState("")
  const [added,setAdded]=useState(0)
  const [searchShow, setSearchShow] = useState(false);
  const [show, setShow] = useState(false);
  const [customerName, setCustomerName] = useState("");
  const [productid, setproductid] = useState();
  const [suggestions, setSuggestions] = useState([]);
  const [productdetails, setproductdetails] = useState();
  const [Unit, setUnit] = useState("KG");
  const [optionShow, setOptionShow] = useState(false);
  const [optionPosition, setOptionPosition] = useState({ top: 0, left: 0 });
  const [currentStatus, setCurrentStatus] = useState("");
  const ellipsisRef = useRef(null);
  const navigate = useNavigate();
  const [shippingMarks, setshippingMarks] = useState(null);
  const [delivaeryDate, setdeliveryDate] = useState(null);
  const [Warehouse, setWarehouse] = useState(null);
  const [paymentType, setpaymenyType] = useState("");
  const [SUPP_PO_ID, setSUPP_PO_ID] = useState([]);
  const [historyShow,setHistoryShow]=useState(false)
  const [customerID, setcustomerID] = useState(null);
  const [rows, setRows] = useState([]);

  const initialRows1=[	{
    id: "",
    id_desc: "Total",
    SUP_ITEM_ID: "",
    TOTAL_QTY: "",
    PICTURE: "",
    CTN_QTY: "",
    INNER: "",
    CTN: "",
    DESCRIPTION: "",
    PRICE: "",
    TOTAL_AMOUNT: 0,
    BARCODE: "",
    DUN_BARCODE: "",
    PACKING: "",
    CBM: "",
    TTL_CBM: 0,
    WEIGHT: "",
    TTL_WEIGHT: 0,
  },]

  useEffect(()=>{
    if(rows.length===0){
      setAdded(0)
    }
  },[rows])


  const handleCancel = () => {
    navigate("/suppliers/supplier-info");
  };



  const getDetails = () => {
    const payload = {
      SUPP_PO_ID: SUPP_PO_ID[0],
    };

    makeApiCall({
      mod: "SupplierPO",
      action: "get_items_from_po",
      payload: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        console.log("modify-po-info", res);
        const count = rows.length;
       let  existingProductIds = new Set(rows.map(row => row.PRODUCT_ID));
       let addedProductIds = new Set();
       setCurrencySymbol(res.XscData.PO_DETAILS[0].OPERATING_CURRENCY_SYMBOL)
       const initialRows = res.XscData.PO_DETAILS
       .filter((item) => {
        const productId = item.PRODUCT_ID;
        
        if (!existingProductIds.has(productId) && !addedProductIds.has(productId)) {
          addedProductIds.add(productId);
          return true; 
        }
        return false;
      })
       .map((item, index) => ({
         id: (count + index + 1).toString(),
         MNX_ID: item.CLIENT_ITEM_NUM,
         SUP_ITEM_ID: item.SUPP_ITEM_NUM,
         TOTAL_QTY: item.TOTAL_QTY, 
         INNER: item.P_INNER,
         PICTURE: "Value 9", 
         CTN: item.TOTAL_CTN,
         CTN_QTY: item.QTY_PER_CTN || "",
         DESCRIPTION: item.DESCRIPTION || "",
         PRICE: item.PRICE || "",
         BARCODE: item.SUPP_BAR_CODE || item.CLIENT_BAR_CODE || "",
         DUN_BARCODE: item.SUPP_DUN_BAR_CODE || item.CLIENT_DUN_BAR_CODE || "",
         PACKING: item.PACKING || "",
         CBM: item.CBM || "",
         WEIGHT: item.WEIGHT || "",
         PRODUCT_ID: item.PRODUCT_ID,
         CURRENCY_SYMBOL: item.OPERATING_CURRENCY_SYMBOL,
         MM_FILE_NAME:item.MM_FILE_NAME
       }));
         
        const idSuggestionsMap = initialRows.reduce(
          (acc, item, index) => {
            const rowId = (count + index + 1).toString();
            acc[rowId] = item.MNX_ID; 
            return acc;
          },
          {}
        );
        const idSuggestionsMaps = initialRows.reduce(
          (acc, item, index) => {
            const rowId = (count + index + 1).toString();
            acc[rowId] = item.SUP_ITEM_ID; // Mapping id to CLIENT_ITEM_NUM
            return acc;
          },
          {}
        );

        setidsuggestions((prev) => ({
          ...prev,
          mnxItemId: {
            ...prev.mnxItemId,
            ...idSuggestionsMap,
          },
        }));
        setidsuggestions((prev) => ({
          ...prev,
          supItemId: {
            ...prev.supItemId,
            ...idSuggestionsMaps,
          },
        }));
if(initialRows.length!=0){
 setAdded((prevadded)=> prevadded+1)
}
        setRows((prevRows) => {
          if(prevRows.length ===0){
            return [...prevRows,...initialRows]
          }else{
          
            const newRows = [...prevRows, ...initialRows];
            return newRows;
          }
     
        });
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const handlesearchPO = (value) => {
    setSUPP_PO_ID(value);
    console.log("PREVIOUS PO DETAIL", value);
  };
  useEffect(() => {
    getDetails();
  }, [SUPP_PO_ID]);

  console.log("PREVIOUS PO DETAIL", SUPP_PO_ID);
  
 
  const [itemSuggestions, setItemSuggestions] = useState({
    mnxItemId: [],
    supItemId: [],
  });
  const paymentMethods = [
    // {label:"Select Mode",value:null},
    { label: "T/T", value: "T/T" },
    { label: "CASH", value: "CASH" },
    { label: "CARD", value: "CARD" },
    { label: "ONLINE", value: "ONLINE" },
    { label: "OTHERS", value: "OTHERS" },
  ];

  const [idSuggestions, setidsuggestions] = useState({
    mnxItemId: [],
    supItemId: [],
  });
  const inputRefs = useRef({});

  const [clicked, setclicked] = useState(false);
  const [filteredInvoiceItems, setFilteredInvoiceItems] = useState([]);
  const CLIENT_SUPPLIER_ID = localStorage.getItem("CLIENT_SUPPLIER_ID");
  let token = localStorage.getItem("token");
  const APP_CLIENT_ID = localStorage.getItem("CLIENT_ID");
  let [searchParams] = useSearchParams();
  // const ID = searchParams.get("id");
  // const ID = 133;
  const [createPoInfo, setCreatePoInfo] = React.useState({
    supplierdata: "",
    clientData: "",
  });

  const [invoiceItems, setInvoiceItems] = useState([]);
  const [clientNum, setclientNum] = useState();
  const [activeRow, setActiveRow] = useState("");
  const handlefocus = (id) => {
    setActiveRow(id);
  };

  const [selectedProductId, setSelectedProductId] = useState(null);
  const [itemDescription, setItemDescription] = useState({});
  const [itemValue, setItemValue] = useState({});
  function convertToUnixTimestamp(date) {
    return Math.floor(new Date(date).getTime() / 1000);
  }

  const WeightOptions = [
    { value: "KG", label: "Kgs" },
    { value: "GM", label: "Gms" },
    { value: "LBS", label: "lbs" },
  ];
  function getLabelByValue(value) {
    const option = WeightOptions.find((opt) => opt.value === value);
    return option ? option.label : null;
  }
  const CountryOptions = [
    { value: "1", label: "China" },
    { value: "2", label: "China" },
  ];

  const handleSuggestionClick = (suggestion, rowId) => {
    handleItemChange(
      { target: { value: suggestion.itemNo } },
      rowId,
      "mnxItemId"
    );

    setItemSuggestions((prev) => ({ ...prev, mnxItemId: [] }));

    if (inputRefs.current[rowId]) {
      inputRefs.current[rowId].focus();
    }
  };

  const handleItemChange = (event, rowId, type) => {
    const value = event.target.value;
    setItemValue((prev) => ({ ...prev, [rowId]: value }));
    searchItem(value, type, rowId);
    setActiveRow(value);
    setclicked(false);
    setActiveRow(rowId);
    setidsuggestions((prev) => ({
      ...prev,
      mnxItemId: {
        ...prev.mnxItemId,
        [rowId]: event.target.value,
      },
    }));
  };
  const handleItemChange1 = (event, rowId, type) => {
    const value = event.target.value;
    console.log("SUP ITEM ID", value);
    setItemValue((prev) => ({ ...prev, [rowId]: value }));
    searchItem(value, type, rowId);
    setActiveRow(value);
    setclicked(false);
    setActiveRow(rowId);
    setidsuggestions((prev) => ({
      ...prev,
      supItemId: {
        ...prev.supItemId,
        [rowId]: event.target.value,
      },
    }));
  };

  useEffect(() => {
    console.log("Current Item Suggestions:", itemSuggestions);
  }, [itemSuggestions]);

  const classes = useStyles();

  const location = useLocation();
  const { selectedPOId } = location.state || {};
  const [poDetails, setPoDetails] = useState([]);
  const getproduct = (id, CLIENT_ITEM_NUM, productid, rowId) => {
    const isDuplicate = Object.values(idSuggestions?.mnxItemId || {}).includes(CLIENT_ITEM_NUM);
    const isDuplicateSupplier = Object.values(idSuggestions?.supItemId || {}).includes(CLIENT_ITEM_NUM);
  if (isDuplicate) {
    toast.error("Item number already exists!");
    return; 
  }
  if (isDuplicateSupplier) {
    toast.error("Item number already exists!");
    return; 
  }
    const payload = {
      PRODUCT_ID: productid,
      CLIENT_CUST_ID: customerID,
    };
    // console.log("iddd", id);
    console.log("client itemm numbeererer", CLIENT_ITEM_NUM);

    makeApiCall({
      mod: "Products",
      action: "get_product_details_for_po",
      payload,
      headers: { Authorization: `Bearer ${token}` },
    }).then((res) => {
      console.log(res, "response");

      const mainData = [res.XscData.PRODUCT_DETAILS];
    setCurrencySymbol(res.XscData.PRODUCT_DETAILS.OPERATING_CURRENCY_SYMBOL)
      const mappedData = mainData.map((item, index) => {
        // Mapping the product details
        const mappedItem = {
          id: id.toString(),
          MNX_ID: item.CLIENT_ITEM_NUM,
          CUST_ITEM_ID: item.CLIENT_ITEM_NUM || "",
          SUP_ITEM_ID: item.SUPP_ITEM_NUM,
          TOTAL_QTY: "",
          PICTURE: "Value 9",
          CTN_QTY: item.QTY_PER_CTN || "",
          INNER: item.INNER,
          CTN: "",
          DESCRIPTION: item.DESCRIPTION || "",
          PRICE: item.PRICE || "",
          PH: "",
          TOTAL_AMOUNT: "",
          BARCODE:
            item.CUST_BAR_CODE ||
            item.SUPP_BAR_CODE ||
            item.CLIENT_BAR_CODE ||
            "",
          DUN_BARCODE:
            item.CUST_DUN_BAR_CODE ||
            item.SUPP_DUN_BAR_CODE ||
            item.CLIENT_DUN_BAR_CODE ||
            "",
          PACKING: item.PACKING || "",
          CBM: item.CBM || "",
          TTL_CBM: "",
          WEIGHT: item.WEIGHT || "",
          TTL_WEIGHT: "",
          DEL: "Value 10",
          PRODUCT_ID: productid,
          CURRENCY_SYMBOL: item.OPERATING_CURRENCY_SYMBOL,
          MM_FILE_NAME:item.MM_FILE_NAME
        };

        // Set id suggestions for mnxItemId
        setidsuggestions((prev) => ({
          ...prev,
          mnxItemId: {
            ...prev.mnxItemId,
            [id]: item.CLIENT_ITEM_NUM, // Assign item.CLIENT_ITEM_NUM here
          },
        }));
        setidsuggestions((prev) => ({
          ...prev,
          supItemId: {
            ...prev.supItemId,
            [id]: item.SUPP_ITEM_NUM,
          },
        }));

        return mappedItem;
      });
      setRows((prevRows) => {
        return prevRows.map((row) => {
          if (row.id === id) {

            return mappedData[0];
          } else {

          }
          return row;
        });
      });
    });
    // Process mappedData as needed
  };

  console.log("ROWS", rows);
  let calculatedRows = [];
  if (Array.isArray(rows) && rows.length > 0) {
    calculatedRows = rows.map((row) => ({
      ...row,
      TOTAL_QTY: row.CTN * row.CTN_QTY,
    }));
  }
  let calculatedRows1 = [];
  if (Array.isArray(rows) && rows.length > 0) {
    calculatedRows1 = calculatedRows.map((row) => ({
      ...row,

      TTL_WEIGHT: parseFloat((row.TOTAL_QTY * row.WEIGHT).toFixed(2)),
      TTL_CBM: row.CTN * row.CBM,
      TOTAL_AMOUNT: row.TOTAL_QTY * row.PRICE,
    }));
  }
  const totalAmountAllRows = calculatedRows1.reduce(
    (acc, row) => acc + row.TOTAL_AMOUNT,
    0
  );
  const totalQTY=calculatedRows1.reduce(
    (acc, row) => acc + row.TOTAL_QTY,
    0
  )
  const totalCBMAllRows = calculatedRows1.reduce(
    (acc, row) => acc + row.TTL_CBM,
    0
  );
  const totalWeightAllRows = calculatedRows1.reduce(
    (acc, row) => acc + row.TTL_WEIGHT,
    0
  );
  const CTNAllRows = calculatedRows1.reduce((acc, row) => {
    const ctnValue = parseInt(row.CTN, 10) || 0;
    return acc + ctnValue;
  }, 0);

  // calculatedRows1.push(rows)

  const TotalRows= added>=1? [...calculatedRows1,...initialRows1]:[...calculatedRows1]
  const resultRows = TotalRows.map((row) => {
    if (row.id_desc === "Total") {
      return {
        ...row,
        id: "total_ctn",
        TOTAL_QTY:totalQTY,
        TOTAL_AMOUNT: totalAmountAllRows,
        CTN: CTNAllRows,
        TTL_CBM: totalCBMAllRows,
        TTL_WEIGHT: totalWeightAllRows,
      };
    }
    return row;
  });
  const columns = [
    {
      field: "MNX_ID",
      headerName: "Mnx. Item ID",
      width: 148,
      cellClassName: "po_input",
      align: "center",
      renderCell: (params) => {
        const inputValue = idSuggestions.mnxItemId[params.row.id];
        const suggestions = itemSuggestions.mnxItemId || [];
        return params.row.id_desc === "Total" ? null : (
          <div style={{ position: "relative" }}>
            <input
              type="text"
              value={inputValue}
              onChange={(e) => handleItemChange(e, params.row.id, "mnxItemId")}
              ref={(el) => (inputRefs.current[params.row.id] = el)}
              style={{ width: "100%", padding: "8px", boxSizing: "border-box" }}
            />
            {activeRow === params.row.id &&
              !clicked &&
              suggestions.length > 0 && (
                <div className="suggestions-dropdown">
                  {suggestions.map((suggestion) => (
                    <div
                      key={suggestion.id}
                      className="suggestion-item"
                      onClick={() => {
                        setproductid(suggestion.id);
                        setclientNum(suggestion.itemNo);
                        handleInputChange(
                          { target: { value: suggestion.itemNo } },
                          params.row.id,
                          "id"
                        );
                        setidsuggestions((prev) => ({
                          ...prev,
                          mnxItemId: {
                            ...prev.mnxItemId, // Spread previous values of mnxItemId
                            [params.row.id]: suggestion.itemNo, // Dynamically set mnxItemId for the specific row id
                          },
                        }));

                        setItemSuggestions((prev) => ({
                          ...prev,
                          mnxItemId: [],
                        }));
                        if (inputRefs.current[params.row.id]) {
                          inputRefs.current[params.row.id].focus();
                        }
                        setclicked(true);
                        getproduct(
                          params.row.id,
                          suggestion.itemNo,
                          suggestion.id
                        );
                      }}
                    >
                      {suggestion.itemNo}
                    </div>
                  ))}
             
                </div>
              )}
          </div>
        );
      },
    },

    {
      field: "SUP_ITEM_ID",
      headerName: "Sup. Item Id",
      width: 148,
      cellClassName: "po_input",
      align: "center",
      renderCell: (params) => {
        const inputValue = idSuggestions.supItemId[params.row.id];
        const suggestions = itemSuggestions.supItemId || [];
        return params.row.id_desc === "Total" ? null : (
          <div style={{ position: "relative" }}>
            <input
              type="text"
              value={inputValue}
              onChange={(e) => handleItemChange1(e, params.row.id, "supItemId")}
              ref={(el) => (inputRefs.current[params.row.id] = el)}
              style={{ width: "100%", padding: "8px", boxSizing: "border-box" }}
            />
            {activeRow === params.row.id &&
              !clicked &&
              suggestions.length > 0 && (
                <div className="suggestions-dropdown">
                  {suggestions.map((suggestion) => (
                    <div
                      key={suggestion.id}
                      className="suggestion-item"
                      onClick={() => {
                        setproductid(suggestion.id);
                        setclientNum(suggestion.itemNo);
                        // handleInputChange(
                        //   { target: { value: suggestion.itemNo } },
                        //   params.row.id,
                        //   "id"
                        // );
                        setidsuggestions((prev) => ({
                          ...prev,
                          supItemId: {
                            ...prev.supItemId, // Spread previous values of mnxItemId
                            [params.row.id]: suggestion.itemNo, // Dynamically set mnxItemId for the specific row id
                          },
                        }));

                        setItemSuggestions((prev) => ({
                          ...prev,
                          mnxItemId: [],
                        }));
                        if (inputRefs.current[params.row.id]) {
                          inputRefs.current[params.row.id].focus();
                        }
                        setclicked(true);
                        getproduct(
                          params.row.id,
                          suggestion.itemNo,
                          suggestion.id
                        );
                      }}
                    >
                      {suggestion.itemNo}
                    </div>
                  ))}
                  
                </div>
              )}
          </div>
        );
      },
    },

    {
      field: "TOTAL_QTY",
      headerName: "Total QTY",
      width: 150,
      align: "center",
      renderCell: (params) => {
        return params.row.id_desc === "Total" ? (
          <div className={classes.subheadingCell} >{params.value}</div>
        ) : (
          <div>
            {params.value}
          </div>
        );
      },
    },
    {
      field: "PICTURE",
      headerName: "Picture",
      width: 120,
      align: "center",
      renderCell: (params) => {
        const imageUrl = params.row.MM_FILE_NAME ? `${API_URL}/media/${params.row.MM_FILE_NAME}` : default_image;
        return params.row.id_desc === "Total" ? null : (
          <div id="po_pic_bg">
           <img
          src={imageUrl}
          alt="Product Image"
          style={{ width: "100%", height: "auto" }}
        />
          </div>
        );
      },
    },
    {
      field: "CTN_QTY",
      headerName: "CTN/QTY",
      width: 150,

      align: "center",
    },
    {
      field: "INNER",
      headerName: "Inner",
      width: 150,
      align: "center",
      cellClassName: "po_input",
      renderCell: (params) => {
        return params.row.id_desc === "Total" ? null : (
          <TextInput
            type="text"
            value={params.value}
            onChange={(e) => {
              const inputValue = e.target.value;
              if (/^\d*$/.test(inputValue)) {
                handleInputChange1(e, params.row.id, "INNER");
              }
            }}
          />
        );
      },
    },
    {
      field: "CTN",
      headerName: "CTN",
      width: 150,
      cellClassName: "po_input",
      align: "center",
      renderCell: (params) => {
        return params.row.id_desc === "Total" ? (
          <div className={classes.subheadingCell}>{params.value}</div>
        ) : (
          <TextInput
            type="text"
            value={params.value}
            onChange={(e) => {
              const value=e.target.value;
              if (/^\d*$/.test(value)) {
                handleInputChange1(e, params.row.id, "CTN")
              }
              }}
          />
        );
      },
    },
    {
      field: "DESCRIPTION",
      headerName: "Description",
      width: 320,
      cellClassName: "po_input",
      align: "center",
      renderCell: (params) => {
        return params.row.id_desc === "Total" ? null : (
          <TextInput
            type="text"
            value={params.value}
            onChange={(e) =>
              handleInputChange1(e, params.row.id, "DESCRIPTION")
            }
          />
        );
      },
    },
    {
      field: "PRICE",
      headerName: "Price",
      width: 180,
      align: "center",
      cellClassName: "po_input",
      renderCell: (params) => {
        return params.row.id_desc === "Total" ? null : (
          <div className="d-flex" style={{position:"relative"}}>
               <TextInput
               style={{paddingLeft:"30px"}}
               type="text"
               value={params.value}
               onChange={(e) => {
                 const value=e.target.value;
                //  if (/^\d*$/.test(value)) {
                   handleInputChange1(e, params.row.id, "PRICE")
                //  }
                 }}
          />
          <div style={{position:"absolute",left:"12px"}}>{params.row.CURRENCY_SYMBOL}</div>
          </div>
       
        );
      },
    },
    {
      field: "PH",
      headerName: "PH",
      width: 80,
      align: "center",
      renderCell: (params) => {
        return params.row.id_desc === "Total" ? null : (
          <svg
            style={{ cursor: "pointer" }}
            onClick={() => {setHistoryShow(true)
              localStorage.setItem("PRODUCT_ID",params.row.PRODUCT_ID)
            }}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M17 2V4H20.0066C20.5552 4 21 4.44495 21 4.9934V21.0066C21 21.5552 20.5551 22 20.0066 22H3.9934C3.44476 22 3 21.5551 3 21.0066V4.9934C3 4.44476 3.44495 4 3.9934 4H7V2H17ZM7 6H5V20H19V6H17V8H7V6ZM9 16V18H7V16H9ZM9 13V15H7V13H9ZM9 10V12H7V10H9ZM15 4H9V6H15V4Z"
              fill="var(--primaryContainer)"
            />
          </svg>
        );
      },
    },
    {
      field: "TOTAL_AMOUNT",
      headerName: "Total Amount",
      width: 160,
      align: "center",
      renderCell: (params) => {
        return params.row.id_desc === "Total" ? (
          <div className={classes.subheadingCell}>{CurrencySymbol}{params.value.toFixed(2)}</div>
        ) : (
          <div>
            {params.row.CURRENCY_SYMBOL}
            <USDCurrency amount={params.value} />
          </div>
        );
      },
    },
    {
      field: "BARCODE",
      headerName: "Barcode",
      width: 320,
      cellClassName: "po_input",
      align: "center",
      renderCell: (params) => {
        return params.row.id_desc === "Total" ? null : (
          <TextInput
            type="text"
            value={params.value}
            onChange={(e) => handleInputChange1(e, params.row.id, "BARCODE")}
          />
        );
      },
    },
    {
      field: "DUN_BARCODE",
      headerName: "Dun Barcode",
      width: 320,
      cellClassName: "po_input",
      align: "center",
      renderCell: (params) => {
        return params.row.id_desc === "Total" ? null : (
          <TextInput
            type="text"
            value={params.value}
            onChange={(e) =>
              handleInputChange1(e, params.row.id, "DUN_BARCODE")
            }
          />
        );
      },
    },
    {
      field: "PACKING",
      headerName: "Packing",
      width: 320,
      cellClassName: "po_input",
      align: "center",
      renderCell: (params) => {
        return params.row.id_desc === "Total" ? null : (
          <TextInput
            type="text"
            value={params.value}
            onChange={(e) => handleInputChange1(e, params.row.id, "PACKING")}
          />
        );
      },
    },
    {
      field: "CBM",
      headerName: "CBM",
      width: 130,
      cellClassName: "po_input",
      align: "center",
      renderCell: (params) => {
        return params.row.id_desc === "Total" ? null : (
          <TextInput
            type="text"
            value={params.value}
            onChange={(e) => handleInputChange1(e, params.row.id, "CBM")}
          />
        );
      },
    },
    {
      field: "TTL_CBM",
      headerName: "TTL CBM",
      width: 130,
      align: "center",
      renderCell: (params) => {
        return params.row.id_desc === "Total" ? (
          <div className={classes.subheadingCell}>{params.value.toFixed(4)}</div>
        ) : <div >{params.value.toFixed(4)}</div>;
      },
    },
    {
      field: "WEIGHT",
      headerName: "Weight",
      width: 240,
      cellClassName: "po_input",
      align: "center",
      renderCell: (params) => {
        return params.row.id_desc === "Total" ? null : (
          <div className="position-relative d-flex gap-0" id="dp_with_op">
            <TextInput
              type="text"
              id="weight_dp_down"
              value={params.value}
              onChange={(e) => handleInputChange1(e, params.row.id, "WEIGHT")}
            />
            <div>
              <select
                id="cust_div1"
                value={Unit}
                onChange={(e) => setUnit(e.target.value)}
              >
                <option value="" disabled>
                  select unit
                </option>
                {WeightOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
            <div
              style={{ top: 26, right: 0, paddingRight: 0 }}
              id="remaining_characters"
              className="position-absolute"
            ></div>
          </div>
        );
      },
    },
    {
      field: "TTL_WEIGHT",
      headerName: "TTL Weight",
      width: 148,
      align: "center",
      renderCell: (params) => {
        return params.row.id_desc === "Total" ? (
          <div className={classes.subheadingCell}>{params.value} {getLabelByValue(Unit)}</div>
        ) : <div>{params.value} {getLabelByValue(Unit)}</div>;
      },
    },
    {
      field: "DEL",
      headerName: " ",
      width: 60,
      align: "center",
      sortable: false,
      renderCell: (params) => {
        // Check if the row is not the "Total" row
        if (params.row.id_desc === "Total") return null;

        return (
          <div
            onClick={() => deleteRow(params.row.id)} // Call deleteRow function on click
            style={{ cursor: "pointer" }} // Change cursor to pointer
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M17 6H22V8H20V21C20 21.5523 19.5523 22 19 22H5C4.44772 22 4 21.5523 4 21V8H2V6H7V3C7 2.44772 7.44772 2 8 2H16C16.5523 2 17 2.44772 17 3V6ZM18 8H6V20H18V8ZM9 11H11V17H9V11ZM13 11H15V17H13V11ZM9 4V6H15V4H9Z"
                fill="var(--error)"
              />
            </svg>
          </div>
        );
      },
    },
  ];

  // const ITEMS = calculatedRows1.map((row) => ({
  //   // CLIENT_P_NUMBER: row.MNX_ID,
  //   //  SUPP_P_NUMBER: row.SUP_ITEM_ID,
  //   PRODUCT_ID: row.PRODUCT_ID,
  //   //  QTY_PER_CTN: row.CTN_QTY,
  //   INNER: row.INNER,
  //   P_DESCRIPTION: row.DESCRIPTION,
  //   PRICE_PER_ITEM: row.PRICE,
  //   BARCODE: row.BARCODE,
  //   DUN_BARCODE: row.DUN_BARCODE,
  //   PACKING: row.PACKING,
  //   CBM: row.CBM,
  //   WEIGHT: row.WEIGHT,
  //   WEIGHT_UNIT: Unit,
  //   TOTAL_QTY: row.TOTAL_QTY,
  //   TOTAL_CTN: row.CTN,
  // }));
  const ITEMS = calculatedRows1
  .filter(row => row.id_desc !== "Total")
  .map(row => ({
    // CLIENT_P_NUMBER: row.MNX_ID,
    // SUPP_P_NUMBER: row.SUP_ITEM_ID,
    PRODUCT_ID: row.PRODUCT_ID,
    // QTY_PER_CTN: row.CTN_QTY,
    INNER: row.INNER,
    P_DESCRIPTION: row.DESCRIPTION,
    PRICE_PER_ITEM: row.PRICE,
    BARCODE: row.BARCODE,
    DUN_BARCODE: row.DUN_BARCODE,
    PACKING: row.PACKING,
    CBM: row.CBM,
    WEIGHT: row.WEIGHT,
    WEIGHT_UNIT: Unit,  // Assuming 'Unit' is a predefined variable
    TOTAL_QTY: row.TOTAL_QTY,
    TOTAL_CTN: row.CTN,
  }));

  const handleInputChange = (event, rowId, field) => {
    const value = event.target.value;
    setclientNum(value);
    setItemValue((prev) => ({ ...prev, [rowId]: clientNum }));
    //searchItem(value, field, rowId); // Trigger search with rowId
  };
  const handleInputChange1 = (event, rowID, field) => {
    const value = event.target.value;
    setRows((prevRows) =>
      prevRows.map((row) =>
        row.id === rowID ? { ...row, [field]: value } : row
      )
    );
  };
  const searchCustomer = useCallback(
    debounce(async (value) => {
      if (value.length < 1) {
        setSuggestions([]);
        return;
      }

      try {
        const payload = { SEARCH_KEYWORD: value, APP_CLIENT_ID: APP_CLIENT_ID };
        console.log("Payload:", payload);
        const res = await makeApiCall({
          mod: "Customer",
          action: "search_customer",
          payload: payload,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        console.log("API response:", res); // Log the API response to check the data
        setSuggestions(res.XscData?.CUST_NAME_LIST || []);
      } catch (error) {
        console.error("Error fetching suggestions:", error);
        setSuggestions([]);
      }
    }, 300),
    []
  );

  const updateAllFields = (selectedItem, rowId) => {
    const { DESCRIPTION, PRICE, CUST_ITEM_ID, MNX_ID } = selectedItem;

    setItemDescription((prev) => ({ ...prev, [rowId]: DESCRIPTION }));
    setItemValue((prev) => ({
      ...prev,
      [rowId]: MNX_ID, // or another identifier you use
    }));

    // Update other fields if necessary
    // Assuming you want to update all related fields
    setFilteredInvoiceItems((prevItems) =>
      prevItems.map((item) =>
        item.MNX_ID === MNX_ID ? { ...item, DESCRIPTION, PRICE } : item
      )
    );
  };

  const searchItem = useCallback(
    debounce(async (value, type, rowId) => {
      if (value.length < 1) {
        setItemSuggestions((prev) => ({ ...prev, [type]: [] }));
        setFilteredInvoiceItems(invoiceItems); // Reset to full invoice items if no search value
        return;
      }

      try {
        const payload = {
          SEARCH_KEYWORD: value,
          APP_CLIENT_ID: APP_CLIENT_ID,
          CLIENT_SUPPLIER_ID: CLIENT_SUPPLIER_ID,
        };
        let action = "";

        if (type === "mnxItemId") {
          action = "search_prod_by_client_item_num";
        } else if (type === "supItemId") {
          action = "search_prod_by_supp_item_num";
        }

        const res = await makeApiCall({
          mod: "Products",
          action: action,
          payload: payload,
          headers: { Authorization: `Bearer ${token}` },
        });

        const itemList = res.XscData.ITEM_LIST || [];
        setItemSuggestions((prev) => ({
          ...prev,
          [type]: itemList.map((item) => ({
            id: item.PRODUCT_ID,
            itemNo: item.ITEM_NO,
            name: item.NAME,
          })),
        }));

        if (type === "mnxItemId") {
          const selectedItem = itemList.find((item) => item.ITEM_NO === value);
          if (selectedItem) {
            updateAllFields(selectedItem, rowId);
          }
        }

        // Filter invoice items based on the search result
        const filteredItems = invoiceItems.filter(
          (item) =>
            item.MNX_ID.includes(value) || item.CUST_ITEM_ID.includes(value)
        );
        setFilteredInvoiceItems(filteredItems);
      } catch (error) {
        console.error("Error fetching item suggestions:", error);
        setItemSuggestions((prev) => ({ ...prev, [type]: [] }));
        setFilteredInvoiceItems(invoiceItems); // Reset to full invoice items in case of error
      }
    }, 300),
    [APP_CLIENT_ID, token, invoiceItems]
  );
  const searchItem1 = useCallback(
    debounce(async (value, type, rowId) => {
      if (value.length < 1) {
        setItemSuggestions((prev) => ({ ...prev, [type]: [] }));
        setFilteredInvoiceItems(invoiceItems); // Reset to full invoice items if no search value
        return;
      }

      try {
        const payload = {
          SEARCH_KEYWORD: value,
          APP_CLIENT_ID: APP_CLIENT_ID,
          CLIENT_SUPPLIER_ID: CLIENT_SUPPLIER_ID,
        };

        const action = "search_prod_by_supp_item_num";

        const res = await makeApiCall({
          mod: "Products",
          action: action,
          payload: payload,
          headers: { Authorization: `Bearer ${token}` },
        });

        const itemList = res.XscData.ITEM_LIST || [];
        setItemSuggestions((prev) => ({
          ...prev,
          [type]: itemList.map((item) => ({
            id: item.PRODUCT_ID,
            itemNo: item.ITEM_NO,
            name: item.NAME,
          })),
        }));

        if (type === "mnxItemId") {
          const selectedItem = itemList.find((item) => item.ITEM_NO === value);
          if (selectedItem) {
            updateAllFields(selectedItem, rowId);
          }
        }

        // Filter invoice items based on the search result
        const filteredItems = invoiceItems.filter(
          (item) =>
            item.MNX_ID.includes(value) || item.CUST_ITEM_ID.includes(value)
        );
        setFilteredInvoiceItems(filteredItems);
      } catch (error) {
        console.error("Error fetching item suggestions:", error);
        setItemSuggestions((prev) => ({ ...prev, [type]: [] }));
        setFilteredInvoiceItems(invoiceItems); // Reset to full invoice items in case of error
      }
    }, 300),
    [APP_CLIENT_ID, token, invoiceItems]
  );
  
  const handleCustomerChange = (event) => {
    const value = event.target.value;
    setCustomerName(value);
    searchCustomer(value);
    if (value === "") {
      setcustomerID(null);
    }
  };

  const handleAddNewItem = () => {
    if(customerID){
     setAdded((prevadded)=> prevadded+1)
      const newRow = {
        id: rows.length + 1,
        MNX_ID: "",
        SUP_ITEM_ID: "",
        TOTAL_QTY: "1008",
        PICTURE: "Value 9",
        CTN_QTY: "",
        INNER: "",
        CTN: "",
        DESCRIPTION: "",
        PRICE: "",
        PH: "",
        TOTAL_AMOUNT: "¥7,459.20",
        BARCODE: "",
        DUN_BARCODE: "",
        PACKING: "",
        CBM: "",
        TTL_CBM: "0.73",
        WEIGHT: "",
        TTL_WEIGHT: "1,024.80",
        DEL: "Value 10",
      };
      setRows([...rows, newRow]);
    }
     else {
      toast.error("Please select a customer above", {
        position: "bottom-center",
      });
    }
  };

  const getData = () => {
    const payload = {
      CLIENT_SUPP_ID: CLIENT_SUPPLIER_ID,
    };

    makeApiCall({
      mod: "SupplierPO",
      action: "get-client-supp-details",
      payload: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((res) => {
      console.log(res, "create-po-info");
      setCreatePoInfo({
        clientData: res.XscData.CLIENT_DATA,
        supplierdata: res.XscData.SUPP_DATA,
      });
    });
  };

  useEffect(() => {
    getData();
  }, []);

  const getRowClassName = (params) => {
    return params.row.id_desc === "Total" ? classes.subheadingRow : "";
  };

  const getCellClassName = (params) => {
    return params.row.id_desc === "Total" ? classes.padding : "";
  };

  const deleteRow = (rowId) => {
    const updatedRows = rows.filter((row) => row.id !== rowId);
    setRows(updatedRows);
   
  const updatedMnxItemId = { ...idSuggestions.mnxItemId };
  const updatedSupItemId = { ...idSuggestions.supItemId };

  delete updatedMnxItemId[rowId];
  delete updatedSupItemId[rowId];

  setidsuggestions({
    mnxItemId: updatedMnxItemId,
    supItemId: updatedSupItemId,
  });
  };
  console.log("PRODUCT ID", productid);
  console.log("Rows ", rows);
  console.log("itemsuggestiom", itemSuggestions);
  const payload11 = {
    APP_CLIENT_ID: APP_CLIENT_ID,
    CLIENT_SUPPLIER_ID: CLIENT_SUPPLIER_ID,
    SHIPPING_MARKS: shippingMarks,
    DELIVERY_DATE: delivaeryDate,
    WAREHOUSE_ADDR: Warehouse,
    PAYMENT_TYPE: paymentType,
    ITEMS: [...ITEMS],
  };
  const handleCreatePO = () => {
    
    for (let i = 0; i < ITEMS.length; i++) {
      const item = ITEMS[i];
  
      // if (!item.PRODUCT_ID) {
      //   toast.error(`Product ID is required for item ${i + 1}`, { position: "bottom-center" });
      //   return;
      // }
      if (!item.P_DESCRIPTION || item.P_DESCRIPTION=="") {
        toast.error(`Description is required for item ${i + 1}`, { position: "bottom-center" });
        return;
      }
      if (!item.PRICE_PER_ITEM || item.PRICE_PER_ITEM=="" ) {
        toast.error(`Price per item is required for item ${i + 1}`, { position: "bottom-center" });
        return;
      }
      if (!item.BARCODE || item.BARCODE==="") {
        toast.error(`Barcode is required for item ${i + 1}`, { position: "bottom-center" });
        return;
      }
      if (!item.TOTAL_QTY) {
        toast.error(`Total quantity is required for item ${i + 1}`, { position: "bottom-center" });
        return;
      }
      if (!item.TOTAL_CTN) {
        toast.error(`Total cartons are required for item ${i + 1}`, { position: "bottom-center" });
        return;
      }
    }
    if(!customerID){
      toast.error("Please select customer above",{
        position:"bottom-center"
      })
      return
    }
    if (!shippingMarks) {
      toast.error("Shipping Marks are required", { position: "bottom-center" });
      return;
    }
    if (!paymentType) {
      toast.error("Payment Type is required", { position: "bottom-center" });
      return;
    }
    if (!delivaeryDate) {
      toast.error("Delivery Date is required", { position: "bottom-center" });
      return;
    }
    
    if (!Warehouse) {
      toast.error("Warehouse is required", { position: "bottom-center" });
      return;
    }
    
   
    

    const payload = {
      APP_CLIENT_ID: APP_CLIENT_ID,
      CLIENT_CUST_ID: customerID,
      CLIENT_SUPPLIER_ID: CLIENT_SUPPLIER_ID,
      SHIPPING_MARKS: shippingMarks,
      DELIVERY_DATE: delivaeryDate,
      WAREHOUSE_ADDR: Warehouse,
      PAYMENT_TYPE: paymentType,
      ITEMS: [...ITEMS],
    };

    makeApiCall({
      mod: "SupplierPO",
      action: "create_supp_po",
      payload: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => {
      console.log("PO response", response);
      if (response.XscStatus === 1) {
        toast.success("PO created successfully", {
          position: "bottom-center",
        });
        setTimeout(() => {
          localStorage.setItem("SUPP_PO_ID", response.XscData.SUPP_PO_ID);
          navigate("/suppliers/view-po");
        }, 1000);
      } else {
        toast.error("Error in creating PO", {
          position: "bottom-center",
        });
      }
    });
  };
  const [previewshow, setpreviewshow] = useState(false);
  console.log("ITEMS PAYLOAD", ITEMS);
  console.log("PAYLOAD!!!", payload11);
  console.log("CUSTOMER ID", customerID);
  console.log("ROWS WITH TOTAL", resultRows);
  const [createDate, setcreateDate] = useState("");

  const PreviewPayload = {
    rows: resultRows || [],
    CUSTOMER_Name: customerName.NAME || "N/A",
    SHIPPING_MARKS: shippingMarks || "N/A",
    DELIVERY_DATE: delivaeryDate || "N/A",
    WAREHOUSE_ADDR: Warehouse || "N/A",
    PAYMENT_TYPE: paymentType || "N/A",
    CREATED: createDate || "N/A",
  };

  console.log("ID SUGGESTION",idSuggestions)
  return (
    <div>
      <PreviewPO
        show={previewshow}
        onHide={() => setpreviewshow(false)}
        payload={PreviewPayload}
      />
      <div id="suppliers_topbar_bg" className="">
        <div className="container d-flex justify-content-between align-items-center">
          <div id="header_name" className="d-flex ">
            <div>
              <a href="/suppliers/supplier-info">
                <span id="cancel_po_button">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M10.5859 12.0001L2.79297 4.20718L4.20718 2.79297L12.0001 10.5858L19.793 2.79297L21.2072 4.20718L13.4143 12.0001L21.2072 19.7929L19.793 21.2072L12.0001 13.4143L4.20718 21.2072L2.79297 19.7929L10.5859 12.0001Z"
                      fill="var(--onSurface)"
                    />
                    <path
                      d="M10.5859 12.0001L2.79297 4.20718L4.20718 2.79297L12.0001 10.5858L19.793 2.79297L21.2072 4.20718L13.4143 12.0001L21.2072 19.7929L19.793 21.2072L12.0001 13.4143L4.20718 21.2072L2.79297 19.7929L10.5859 12.0001Z"
                      fill="black"
                      fill-opacity="0.2"
                    />
                    <path
                      d="M10.5859 12.0001L2.79297 4.20718L4.20718 2.79297L12.0001 10.5858L19.793 2.79297L21.2072 4.20718L13.4143 12.0001L21.2072 19.7929L19.793 21.2072L12.0001 13.4143L4.20718 21.2072L2.79297 19.7929L10.5859 12.0001Z"
                      fill="black"
                      fill-opacity="0.2"
                    />
                    <path
                      d="M10.5859 12.0001L2.79297 4.20718L4.20718 2.79297L12.0001 10.5858L19.793 2.79297L21.2072 4.20718L13.4143 12.0001L21.2072 19.7929L19.793 21.2072L12.0001 13.4143L4.20718 21.2072L2.79297 19.7929L10.5859 12.0001Z"
                      fill="black"
                      fill-opacity="0.2"
                    />
                    <path
                      d="M10.5859 12.0001L2.79297 4.20718L4.20718 2.79297L12.0001 10.5858L19.793 2.79297L21.2072 4.20718L13.4143 12.0001L21.2072 19.7929L19.793 21.2072L12.0001 13.4143L4.20718 21.2072L2.79297 19.7929L10.5859 12.0001Z"
                      fill="black"
                      fill-opacity="0.2"
                    />
                  </svg>
                </span>{" "}
              </a>
            </div>

            <span className="ps-2  d-flex align-items-center">
              {" "}
              Create Purchase Order
              {/* <div id="edit_supplier_id">MNXS-2024-0001</div> */}
            </span>
          </div>
        </div>
      </div>
      <div id="create_po_bg">
        <div className="container">
          <div id="po_logo" align="center">
            <img src={`${API_URL}media/${createPoInfo.clientData.LOGO}`} alt="logo" className="bg-bang-bg" />
            <div id="po_header_text">{createPoInfo.clientData.NAME}</div>
          </div>
          <div className="row" id="about_po_header">
            <div className="col-6">
              <div id="po_supplier_text">Supplier</div>
              <div id="po_supplier_name">{createPoInfo.supplierdata.NAME}</div>
              <div id="po_supplier_address">
                {`${createPoInfo.supplierdata.ADDR_1},${createPoInfo.supplierdata.ADDR_2}`}
              </div>
              <div id="po_supplier_ph">
                TEL:{" "}
                <span id="po_supplier_no">
                  {createPoInfo.supplierdata.CONTACT_NUMBER}
                </span>
              </div>
              <div id="po_supplier_ph">
                FAX:{" "}
                <span id="po_supplier_no">{createPoInfo.supplierdata.FAX}</span>
              </div>
              <div id="po_supplier_ph">
                Purchase Order No: <span id="po_supplier_no"></span>
              </div>
              <div id="po_supplier_ph" className="d-flex align-items-center">
                Date:
                <span id="po_supplier_no">
                  <TextInputWithAll
                    type="date"
                    className="date_input"
                    onChange={(e) => setcreateDate(e.target.value)}
                  />
                </span>
              </div>
            </div>
            <div className="col-6 " id="about_po_left_bg" align="end">
              <div id="content_wrapper">
                {" "}
                <div id="po_supplier_text">Order By</div>
                <div id="po_supplier_name">{createPoInfo.clientData.NAME}</div>
                <div id="po_supplier_address">
                  {`${createPoInfo.clientData.ADDR_1},${createPoInfo.clientData.ADDR_2}`}
                </div>
                <div id="po_supplier_ph">
                  TEL:{" "}
                  <span id="po_supplier_no">
                    {createPoInfo.clientData.CONTACT_NUMBER}
                  </span>
                </div>
                <div id="po_supplier_ph">
                  FAX:{" "}
                  <span id="po_supplier_no">{createPoInfo.clientData.FAX}</span>
                </div>
                <div id="po_supplier_ph" className="d-flex align-items-center">
                  Customer:{" "}
                  <TextInputWithAll
                    id="cust_div"
                    type="text"
                    placeholder="Enter customer"
                    value={customerName.NAME}
                    onChange={handleCustomerChange}
                  />
                  <div>
                    {suggestions.length > 0 && (
                      <div className="suggestions-dropdown">
                        {suggestions.map((suggestion) => (
                          <div
                            key={suggestion.CLIENT_CUST_ID}
                            className="suggestion-item"
                            onClick={() => {
                              setCustomerName(suggestion);
                              setSuggestions([]);
                              setcustomerID(suggestion.CLIENT_CUST_ID);
                            }}
                          >
                            {suggestion.NAME}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="searc_po_bg" onClick={() => setSearchShow(true)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M18.029 16.6168L22.3117 20.8995L20.8975 22.3137L16.6148 18.031C15.0749 19.263 13.122 20 10.998 20C6.03005 20 1.99805 15.968 1.99805 11C1.99805 6.032 6.03005 2 10.998 2C15.966 2 19.998 6.032 19.998 11C19.998 13.124 19.261 15.0769 18.029 16.6168ZM16.0227 15.8748C17.2455 14.6146 17.998 12.8956 17.998 11C17.998 7.1325 14.8655 4 10.998 4C7.13055 4 3.99805 7.1325 3.99805 11C3.99805 14.8675 7.13055 18 10.998 18C12.8936 18 14.6126 17.2475 15.8728 16.0247L16.0227 15.8748Z"
                fill="white"
              />
            </svg>{" "}
            Search Previous PO
          </div>
          <SearchPO
            show={searchShow}
            onHide={() => setSearchShow(false)}
            onSelect={handlesearchPO}
          />
          <div
            style={{
              //minHeight: "100px",
              maxHeight: "fit-content",
              width: "100%",
              marginTop: "20px",
              zIndex: 1,
            }}
          >
            <DataGrid
             
              localeText={{
                noRowsLabel: (
                  <div className="no_rows_css">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM11 7H13V9H11V7ZM11 11H13V17H11V11Z"
                        fill="#535260"
                      />
                    </svg>
                    Enter & Select customer name & start adding your item
                  </div>
                ),
              }}
              rows={resultRows}
              columns={columns.map((col) => ({ ...col, filterable: false, sortable: false }))}
              hideFooterPagination={true}
              hideFooter={true}
              autoHeight
              rowHeight={80}
              showCellRightBorder
              getCellClassName={getCellClassName}
              getRowClassName={getRowClassName}
              disableColumnMenu={true}  
              disableColumnFilter={true}
              sortingOrder={[]}
            />
          </div>

          <div id="add_new">
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M11 11V5H13V11H19V13H13V19H11V13H5V11H11Z"
                  fill="#635BFF"
                />
              </svg>
            </div>
            <div onClick={handleAddNewItem} style={{cursor:"pointer"}}>Add New Items</div>
          </div>
          <div
            className="d-flex flex-column gap-4"
            style={{
              marginTop: 45,
              paddingBottom: 44,
              borderBottom: "0.5px solid var(--outlineVariant) !important",
            }}
          >
            <div className="d-flex flex-row align-items-center gap-2">
              <div
                style={{
                  width: 8,
                  height: 44,
                  background: "var(--tertiaryContainer, #BA3CBB)",
                }}
              ></div>
              <h2 id="shipping_details">Shipping Marks</h2>
            </div>
            <textarea
              name="postContent"
              // className="container"
              style={{ paddingLeft: "20px" }}
              id="text_container_123"
              value={shippingMarks}
              onChange={(e) => setshippingMarks(e.target.value)}
            />
          </div>
          <div id="po_terms_condtions">
            <div id="po_terms_title">Terms & Conditions</div>
            <div>
              EACH CARTON SHOULD BE STRONG EXPORT CARTON WITH DOUBLE STRAP
              WITHOUT CHINESE WRITING, THE SHIPPING MARK SHOULD BE STICKED ON
              BOTH SIDE.箱子使用牢固无中文外贸箱, 并带有打包带, 唛头贴四方.
              (正唛两张, 侧唛两张)
            </div>
            <div>
              IF THE SHIPPMENT GOODS ARE NOT ACCORDING TO OUR REQUIRMENT, WE
              HAVE THE RIGHT TO REJECT THE GOODS 如不按我方要求生产货物,
              我方将有权要求产家反工或拒收货物.
            </div>
            <div>
              THE ERROR QUANTITY FOR EACH ITEM MUST BE WITHIN 10 PIECES.
              每款货误差数量必须在10个之内。
            </div>
            <div>
              THE PACKING , QUANTITY PER CARTON AND QUANTITY PER INNER BOX
              SHOULD SAME AS PER ORDER CONFIRMED.
            </div>
            <div
              className="d-flex create_po_deliverydate"
              style={{ marginTop: "44px" }}
            >
              <div id="po_del_date">交货期 Delivery date:</div>
              <div id="po_supplier_ph" className="d-flex align-items-center">
                <span id="po_supplier_no">
                  <TextInputWithAll
                    type="date"
                    className="date_input"
                    onChange={(e) =>
                      setdeliveryDate(convertToUnixTimestamp(e.target.value))
                    }
                  />
                </span>
              </div>
            </div>
            <div className="create_po_deliverydate">
              <div id="po_del_date">交货地址 Ware house add:</div>
              <div id="po_supplier_ph" className="d-flex align-items-center">
                <span id="po_supplier_no">
                  <TextInputWithAll
                    type="text"
                    className="date_input"
                    value={Warehouse}
                    onChange={(e) => setWarehouse(e.target.value)}
                  />
                </span>
              </div>
            </div>
            <div className="create_po_deliverydate">
              <div id="po_del_date">付款方式(PAYMENT TERMS):</div>
              <div id="po_supplier_ph" className="d-flex align-items-center">
                <span id="po_supplier_no1">
                  <DropDownInputWithoutLabel
                    options={paymentMethods}
                   value={paymentType}
                    onChange={(e) => setpaymenyType(e.target.value)}
                    id="cust_div2"
                    style={{
                      width: 208,
                      margin: 0,
                      paddingRight:"10px"
                    }}
                    placeholder="Select Payment Mode"
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
        <PurchaseHistory show={show} onHide={() => setShow(false)} />
        <div id="add_supplier_footer" className="">
          <div id="add_supplier_buttons" className="container">
            <div>
              <div id="cancel" onClick={handleCancel}>
                Cancel
              </div>
            </div>
            <div
              className="d-flex align-items-center justify-content-right "
              style={{ gap: "12px" }}
            >
              <a
                onClick={() => setpreviewshow(true)}
                className="text-decoration-none"
              >
                <div id="previewpo">
                  Preview PO{" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M16.0032 9.41421L7.39663 18.0208L5.98242 16.6066L14.589 8H7.00324V6H18.0032V17H16.0032V9.41421Z"
                      fill="#635BFF"
                    />
                  </svg>
                </div>
              </a>
              <a className="text-decoration-none" onClick={handleCreatePO}>
                <div id="submitsupplier">Create PO</div>
              </a>
              <ToastContainer
                position="bottom-center"
                style={{ width: "max-content" }}
              />
              <PurchaseHistory
          show={historyShow}
          onHide={() => setHistoryShow(false)}
        />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
//href="/suppliers/preview-po"
export default CreatePO;
