import { Button, Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../Styles/AddPayment.css"
import { useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import { CircleSpinner } from "react-spinners-kit";
import TextInputWithAll from "../Inputs/TextInputWithAll";
import DropDownInputWithoutLabel from "../Inputs/DropDownInputWithoutLabel";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";
import { makeApiCall } from "../../StaticComponents/API";

export default function AddNewPayment({ show, onHide }) {
  const [receiveAmt, setReceiveAmt] = useState();
  const [transactionNo, setTransactionNo] = useState();
  const [startDate, setStartDate] = useState(new Date());
  const [file, setFile] = useState();
  const [fileName, setFileName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const fileInputRef = useRef(null);
  const uploadTimeoutRef = useRef(null);
  const [fileToUpload, setfileToUpload] = useState(null);
  const [currencySymbol, setCurrencySymbol] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [invoiceDetails, setInvoiceDetails] = useState({
    invoiceNo: '',
    invoiceAmount: '',
    dueAmount: ''
  });

  const PaymentOptions = [
    { value: "CASH", label: "CASH" },
    { value: "ONLINE", label: "ONLINE" },
    { value: "CARD", label: "CARD" },
    { value: "T/T", label: "T/T" },
    { value: "OTHERS", label: "OTHERS" },
  ];
  let token = localStorage.getItem("token");
  const CUST_INVOICE_ID = localStorage.getItem("CUST_INVOICE_ID");

  useEffect(() => {
    const payload = {CUST_INVOICE_ID: CUST_INVOICE_ID};
    makeApiCall({
      mod: "Customer",
      action: "recieve_payment_history",
      payload: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
      
        console.log("request", payload);
        console.log("CUSTOMER report  fetched", response);
        if(response.XscData && response.XscData.INVOICE_DETAILS && response.XscStatus === 1){
          const invoice = response.XscData.INVOICE_DETAILS[0];
          const currencySymbol = invoice.CURRENCY_SYMBOL;
          setCurrencySymbol(currencySymbol);
          setInvoiceDetails({
              invoiceNo: invoice.INVOICE_ID,
              invoiceAmount: `${currencySymbol}${invoice.INVOICE_AMOUNT}`,
              dueAmount: `${currencySymbol}${invoice.DUE_AMOUNT}`
          });
      }
       
      });
  }, [CUST_INVOICE_ID]);

  const handleChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setIsLoading(true);
      console.log("Selected file:", e.target.files[0]);
      console.log("Selected file:", e.target.files[0].lastModifiedDate);
      setFileName(e.target.files[0].name);
      setfileToUpload(e.target.files[0]);

      uploadTimeoutRef.current = setTimeout(() => {
        setFile(e.target.files[0]);
        setFileName(e.target.files[0].name);
        setIsLoading(false);
      }, 2000); 
    } else {
      console.error("No file selected");
    }
  };
  const handleOpen = () => {
    // Assuming fileToUpload is the file object you want to display
    const url = URL.createObjectURL(fileToUpload); // Create a URL for the file
    if (url) {
      window.open(url, '_blank'); // Open the file URL in a new tab
    }
  };
  const handleImportCancel = () => {
    setFile(null);
    setFileName("");
    setIsLoading(false);
    setfileToUpload(null);

    if (uploadTimeoutRef.current) {
      clearTimeout(uploadTimeoutRef.current);
      uploadTimeoutRef.current = null;
    }

    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }

    console.log("File upload canceled");
  };

  
  const handleamtChange = (e) => {
    const value = e.target.value;
    if (value === '' || /^\d+(\.\d{0,2})?$/.test(value)) {
      setReceiveAmt(value);
    } else {
      return;
    }
  }
  
  const handletransnochange = (e) => {
    const value = e.target.value;
    if (value === '' || /^\d+$/.test(value)) {
      setTransactionNo(value);
    } else {
      return;
    }
  }



  const handleSubmit = () => {  
    const amountrec = parseFloat(receiveAmt); 
    const transactionId = parseInt(transactionNo);
    const dueAmount = parseFloat(invoiceDetails.dueAmount.replace(currencySymbol, '').replace(/,/g, ''));
    const payload = {
      CUST_INVOICE_ID: CUST_INVOICE_ID, 
      AMOUNT_PAID: amountrec, 
      PAYMENT_METHOD: paymentMethod, 
      TRANSACTION_ID: transactionId,
    };

     // Validation function for fields
     const validateFields = () => {
      let errors = [];

      if (!amountrec || isNaN(amountrec)) {
        errors.push("Received Amount");
      } else if (amountrec > dueAmount) {
        errors.push("Received amount should not be greater than due amount");
      }

        if (!payload.PAYMENT_METHOD) {
          errors.push("Payment Type");
        }

      if (!payload.TRANSACTION_ID) {
        errors.push("Transaction No");
      }

      return errors;
    };

    const missingFields = validateFields();
  
    if (missingFields.length > 0) {
      const errorMessage = `Please check the following fields: ${missingFields.join(
        ", "
      )}`;
      toast.error(errorMessage, {
        position: "bottom-center",
      });
     
      return;
    }

    console.log(payload);
    const formData=new FormData();
    formData.append("mod","Customer")
    formData.append("action","receive_payment_insert")
    formData.append("payload",JSON.stringify(payload))
    formData.append("file", fileToUpload);
    if(file){
      formData.append("file",file);
    }
    console.log(formData.get("payload"));
    console.log(formData.get("file"));
    axios({
      method: "post",
      url: process.env.REACT_APP_API_URL,
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        console.log(res, "New Payment Added");
        if (res.data.XscStatus === 1) {
          toast.success(" Payment Added Successfully", {
            position: "bottom-center",
          });
          setPaymentMethod("");
          setReceiveAmt("");
          setTransactionNo("");
          setFile("");
          
        }
      })
      .catch((error) => {
        console.log(error);
      });
     onHide();
    }

    const handleCancel = () =>{
          setPaymentMethod("");
          setReceiveAmt("");
          setTransactionNo("");
          setFile("");
          onHide();
    }
  
console.log(paymentMethod);
  return (
    <Modal
      show={show}
      onHide={onHide}
      centered
      className="add-custom-modal"
      size="lg"
    >
      <Modal.Body>
      <h2 id="receive_payment"  style={{paddingLeft:"20px", paddingTop:"20px"}}>Add New Payment</h2>
      <div style={{ height: 700, width: "100%", marginBottom: "2px", paddingLeft:"20px", paddingRight:"20px"}}>
          <table>
            <tr>
              <th id="th">
                <span id="table_key">Invoice No</span>
              </th>
              <td id="td">
                <span id="table_value">{invoiceDetails.invoiceNo}</span>
              </td>
            </tr>
            <tr>
              <th id="th">
                <span id="table_key">Invoice Amount</span>
              </th>
              <td id="td">
                <span id="table_value">{invoiceDetails.invoiceAmount}</span>
              </td>
            </tr>
            <tr>
              <th id="th">
                <span id="table_key">Due Amount</span>
              </th>
              <td id="td">
                <span id="table_value">{invoiceDetails.dueAmount}</span>
              </td>
            </tr>
            <tr>
              <th id="th">
                <span id="table_key">Received Amount</span>
              </th>
              <td id="td">
                <span id="table_value">
                  <TextInputWithAll
                    style={{ width: 323, margin: 0 }}
                    value={receiveAmt}
                    id="cust_div"
                    onChange={handleamtChange}
                  />
                </span>
              </td>
            </tr>
            <tr>
              <th id="th">
                <span id="table_key">Transaction No</span>
              </th>
              <td id="td">
                <span id="table_value">
                  <TextInputWithAll
                    style={{ width: 323, margin: 0 }}
                    value={transactionNo}
                    id="cust_div"
                    onChange={handletransnochange}
                  />
                </span>
              </td>
            </tr>
            <tr>
              <th id="th">
                <span id="table_key">Payment Type</span>
              </th>
              <td id="td">
                <DropDownInputWithoutLabel
                  options={PaymentOptions}
                  id="cust_div"
                  value={paymentMethod}
                   onChange = {(e) =>{ 
                  console.log("Selected payment method:", e)
                  setPaymentMethod(e.target.value)}}
                  style={{ width: 323, margin: 0, padding: "12px 16px" }}
                  placeholder="Select Status"
                />
              </td>
            </tr>
            <tr>
              <th id="th">
                <span id="table_key">Payment Date</span>
              </th>
              <td id="td">
                <span id="table_value">
                  <DatePicker
                    id="cust_date"
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    dateFormat="dd-MMMM-yyyy"
                    style={{ width: 323, margin: 0 }}
                  />
                </span>
              </td>
            </tr>
            <tr>
              <th id="th">
                <span id="table_key">Upload Payment File</span>
              </th>
              <td id="td">
                <div className="d-flex flex-row align-items-start gap-2">
                  {isLoading ? (
                    <div id="upload_pi_file" style={{ width: 323 }}>
                      <div
                        className="d-flex flex-row align-items-center"
                        style={{ gap: 120 }}
                      >
                        <div id="upload_file_text">
                          <span
                            className="d-flex align-items-center"
                            style={{ gap: "4px" }}
                          >
                            <CircleSpinner
                              size={20}
                              loading={isLoading}
                              color="var(--primaryContainer)"
                            />
                            Uploading Your File
                          </span>
                          <div>{fileName}</div>
                        </div>
                        <svg
                          style={{
                            cursor: "pointer",
                          }}
                          onClick={handleImportCancel}
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                        >
                          <path
                            d="M8.00045 7.05767L11.3003 3.75781L12.2431 4.70062L8.94325 8.00047L12.2431 11.3003L11.3003 12.2431L8.00045 8.94327L4.70063 12.2431L3.75781 11.3003L7.05765 8.00047L3.75781 4.70062L4.70063 3.75781L8.00045 7.05767Z"
                            fill="#950F27"
                          />
                        </svg>
                      </div>
                    </div>
                  ) : (
                    <div id="import_supplier_bg">
                      {file ? (
                        <div>
                          <span id="table_value">
                            <div id="uploaded_file" style={{ gap: 110 }}>
                              <div
                                className="d-flex flex-row align-items-center gap-1"
                                style={{
                                  marginLeft: 16,
                                }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  viewBox="0 0 16 16"
                                  fill="none"
                                >
                                  <path
                                    d="M3.33333 2.66634H10V5.33301H12.6667V13.333H3.33333V2.66634ZM2.66567 1.33301C2.29833 1.33301 2 1.62904 2 1.99421V14.0051C2 14.3628 2.29651 14.6663 2.66227 14.6663H13.3377C13.7034 14.6663 14 14.3656 14 13.9947L13.9998 4.66634L10.6667 1.33301H2.66567ZM6.99993 4.99967C6.99993 6.05133 6.69613 7.29121 6.18329 8.43527C5.66858 9.58347 4.97429 10.5665 4.25016 11.1457L5.03643 12.2211C6.98807 10.9199 9.14887 10.0277 11.239 10.3263L11.5441 9.03387C9.76233 8.43994 8.33327 6.65963 8.33327 4.99967H6.99993ZM7.39993 8.98074C7.5782 8.58314 7.73613 8.17054 7.86913 7.75201C8.18353 8.23507 8.5702 8.67847 9.00673 9.06321C8.3522 9.18041 7.711 9.37274 7.09013 9.61807C7.19993 9.40921 7.3034 9.19621 7.39993 8.98074Z"
                                    fill="#1B1B24"
                                  />
                                  <path
                                    d="M3.33333 2.66634H10V5.33301H12.6667V13.333H3.33333V2.66634ZM2.66567 1.33301C2.29833 1.33301 2 1.62904 2 1.99421V14.0051C2 14.3628 2.29651 14.6663 2.66227 14.6663H13.3377C13.7034 14.6663 14 14.3656 14 13.9947L13.9998 4.66634L10.6667 1.33301H2.66567ZM6.99993 4.99967C6.99993 6.05133 6.69613 7.29121 6.18329 8.43527C5.66858 9.58347 4.97429 10.5665 4.25016 11.1457L5.03643 12.2211C6.98807 10.9199 9.14887 10.0277 11.239 10.3263L11.5441 9.03387C9.76233 8.43994 8.33327 6.65963 8.33327 4.99967H6.99993ZM7.39993 8.98074C7.5782 8.58314 7.73613 8.17054 7.86913 7.75201C8.18353 8.23507 8.5702 8.67847 9.00673 9.06321C8.3522 9.18041 7.711 9.37274 7.09013 9.61807C7.19993 9.40921 7.3034 9.19621 7.39993 8.98074Z"
                                    fill="black"
                                    fill-opacity="0.2"
                                  />
                                  <path
                                    d="M3.33333 2.66634H10V5.33301H12.6667V13.333H3.33333V2.66634ZM2.66567 1.33301C2.29833 1.33301 2 1.62904 2 1.99421V14.0051C2 14.3628 2.29651 14.6663 2.66227 14.6663H13.3377C13.7034 14.6663 14 14.3656 14 13.9947L13.9998 4.66634L10.6667 1.33301H2.66567ZM6.99993 4.99967C6.99993 6.05133 6.69613 7.29121 6.18329 8.43527C5.66858 9.58347 4.97429 10.5665 4.25016 11.1457L5.03643 12.2211C6.98807 10.9199 9.14887 10.0277 11.239 10.3263L11.5441 9.03387C9.76233 8.43994 8.33327 6.65963 8.33327 4.99967H6.99993ZM7.39993 8.98074C7.5782 8.58314 7.73613 8.17054 7.86913 7.75201C8.18353 8.23507 8.5702 8.67847 9.00673 9.06321C8.3522 9.18041 7.711 9.37274 7.09013 9.61807C7.19993 9.40921 7.3034 9.19621 7.39993 8.98074Z"
                                    fill="black"
                                    fill-opacity="0.2"
                                  />
                                  <path
                                    d="M3.33333 2.66634H10V5.33301H12.6667V13.333H3.33333V2.66634ZM2.66567 1.33301C2.29833 1.33301 2 1.62904 2 1.99421V14.0051C2 14.3628 2.29651 14.6663 2.66227 14.6663H13.3377C13.7034 14.6663 14 14.3656 14 13.9947L13.9998 4.66634L10.6667 1.33301H2.66567ZM6.99993 4.99967C6.99993 6.05133 6.69613 7.29121 6.18329 8.43527C5.66858 9.58347 4.97429 10.5665 4.25016 11.1457L5.03643 12.2211C6.98807 10.9199 9.14887 10.0277 11.239 10.3263L11.5441 9.03387C9.76233 8.43994 8.33327 6.65963 8.33327 4.99967H6.99993ZM7.39993 8.98074C7.5782 8.58314 7.73613 8.17054 7.86913 7.75201C8.18353 8.23507 8.5702 8.67847 9.00673 9.06321C8.3522 9.18041 7.711 9.37274 7.09013 9.61807C7.19993 9.40921 7.3034 9.19621 7.39993 8.98074Z"
                                    fill="black"
                                    fill-opacity="0.2"
                                  />
                                  <path
                                    d="M3.33333 2.66634H10V5.33301H12.6667V13.333H3.33333V2.66634ZM2.66567 1.33301C2.29833 1.33301 2 1.62904 2 1.99421V14.0051C2 14.3628 2.29651 14.6663 2.66227 14.6663H13.3377C13.7034 14.6663 14 14.3656 14 13.9947L13.9998 4.66634L10.6667 1.33301H2.66567ZM6.99993 4.99967C6.99993 6.05133 6.69613 7.29121 6.18329 8.43527C5.66858 9.58347 4.97429 10.5665 4.25016 11.1457L5.03643 12.2211C6.98807 10.9199 9.14887 10.0277 11.239 10.3263L11.5441 9.03387C9.76233 8.43994 8.33327 6.65963 8.33327 4.99967H6.99993ZM7.39993 8.98074C7.5782 8.58314 7.73613 8.17054 7.86913 7.75201C8.18353 8.23507 8.5702 8.67847 9.00673 9.06321C8.3522 9.18041 7.711 9.37274 7.09013 9.61807C7.19993 9.40921 7.3034 9.19621 7.39993 8.98074Z"
                                    fill="black"
                                    fill-opacity="0.2"
                                  />
                                </svg>
                                <p id="file_name">{fileName}</p>
                                <svg 
                                  style={{
                                    cursor: "pointer",
                                  }}
                                  onClick={handleImportCancel}
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  viewBox="0 0 16 16"
                                  fill="none"
                                >
                                  <path
                                    d="M8.00045 7.05767L11.3003 3.75781L12.2431 4.70062L8.94325 8.00047L12.2431 11.3003L11.3003 12.2431L8.00045 8.94327L4.70063 12.2431L3.75781 11.3003L7.05765 8.00047L3.75781 4.70062L4.70063 3.75781L8.00045 7.05767Z"
                                    fill="#950F27"
                                  />
                                </svg>
                              </div>
                              <Button id="viewFile" onClick={handleOpen}>
                                <h2 id="view_text">View</h2>
                                <svg
                                
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  viewBox="0 0 16 16"
                                  fill="none"
                                >
                                  <path
                                    d="M10.6688 6.27614L4.93109 12.0139L3.98828 11.0711L9.72601 5.33333H4.66883V4H12.0021V11.3333H10.6688V6.27614Z"
                                    fill="#635BFF"
                                  />
                                </svg>
                              </Button>
                            </div>
                          </span>
                        </div>
                      ) : (
                        <div className="col-12">
                          <label
                            htmlFor="file-input"
                            className="d-flex align-items-center justify-content-center flex-column"
                            id="upload_pi_file"
                            style={{
                              width: 323,
                              cursor: "pointer",
                            }}
                          >
                            <div id="upload_csv_text">
                              {fileName && fileName}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <g opacity="0.4">
                                  <path
                                    d="M12 12.5858L16.2426 16.8284L14.8284 18.2426L13 16.415V22H11V16.413L9.17157 18.2426L7.75736 16.8284L12 12.5858ZM12 2C15.5934 2 18.5544 4.70761 18.9541 8.19395C21.2858 8.83154 23 10.9656 23 13.5C23 16.3688 20.8036 18.7246 18.0006 18.9776L18 17C18 13.6863 15.3137 11 12 11C8.7616 11 6.12243 13.5656 6.00414 16.7751L6 17L6.00039 18.9776C3.19696 18.7252 1 16.3692 1 13.5C1 10.9656 2.71424 8.83154 5.04648 8.19411C5.44561 4.70761 8.40661 2 12 2Z"
                                    fill="#635BFF"
                                  />
                                </g>
                              </svg>
                            </div>
                          </label>
                          <TextInputWithAll
                            name="file-input"
                            type="file"
                            onChange={handleChange}
                            id="file-input"
                            ref={fileInputRef}
                            style={{
                              display: "none",
                            }}
                            accept=".csv"
                          />
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </td>
            </tr>
          </table>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div id="po_footer" className="pi_footer" style={{ border: "none" }}>
          <div id="add_supplier_buttons" className="container">
            {" "}
            <a className="text-decoration-none">
              {" "}
              <div id="cancel" onClick={handleCancel}>Cancel</div>
            </a>
            <div
              className="d-flex align-items-center justify-content-right "
              style={{ gap: "12px" }}
            >
              <a
                // href="/customers/customer-info"
                className="text-decoration-none"
              >
                <div id="submitsupplier" onClick={handleSubmit}>Submit</div>
              </a>
            </div>
          </div>
        </div>
      </Modal.Footer>
      <ToastContainer position="bottom-center" style={{ width: "max-content" }} />
    </Modal>
  );
}
