import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../../Styles/ReceivePayment.css";
import { DataGrid } from "@mui/x-data-grid";
import ViewPayment from "./ViewPayment";
import AddNewPayment from "./AddNewPayment";
import EditPayment from "./EditPayment";
import { makeApiCall } from "../../../StaticComponents/API";

export default function ReceivePayment({ show, onHide }) {
  const [addNew, setAddNew] = useState(false);
  const [edit, setEdit] = useState(false);
  const [view, setView] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [rows, setRows] = useState([]);
  const [FileName, setFileName] = useState("");
  const [invoiceDetails, setInvoiceDetails] = useState({
    invoiceNo: '',
    invoiceAmount: '',
    dueAmount: ''
  });
  const API_URL = process.env.REACT_APP_API_URL;
  const [currencySymbol, setCurrencySymbol] = useState('¥');
  let token = localStorage.getItem("token");
  const CUST_INVOICE_ID = localStorage.getItem("CUST_INV_ID")

  useEffect(() => {
    const payload = {CUST_INVOICE_ID: CUST_INVOICE_ID};
    makeApiCall({
      mod: "Customer",
      action: "recieve_payment_history",
      payload: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
      
        console.log("request", payload);
        console.log("CUSTOMER report records fetched", response);
        if(response.XscData && response.XscData.INVOICE_DETAILS){
          const invoice = response.XscData.INVOICE_DETAILS[0];
          setCurrencySymbol(invoice.CURRENCY_SYMBOL || '');
          setInvoiceDetails({
              invoiceNo: invoice.INVOICE_ID,
              invoiceAmount: invoice.INVOICE_AMOUNT,
              dueAmount: invoice.DUE_AMOUNT,
          });
      }
      if(response.XscData && response.XscData.PAYMENT_DETAILS){
          const paymentRows = response.XscData.PAYMENT_DETAILS.map((payment,index) => ({
              id: payment.TRANSACTION_ID || 'N/A', 
              PAYMENT_ID: index + 1 || "N/A",
              PAYMENT_TYPE: payment.PAYMENT_TYPE,
              TRANSACTION_NO: payment.TRANSACTION_ID || 'N/A',
              AMOUNT_SETTLED: `${payment.CURRENCY_SYMBOL || ''}${payment.AMOUNT_SETTLED || 'N/A'}`,
              FILE_NAME: payment.FILE_NAME,
              INV_AR_ID:payment.INV_AR_ID,
            }));
            setRows(paymentRows);
            console.log(paymentRows);
      } 
    })
    .catch((error) => {
      console.log(error);
    });
}, [CUST_INVOICE_ID, token,show]);

  const columns = [
    {
      field: "PAYMENT_ID",
      headerName: "Payment No",
      width: 130,
      cellClassName: "sticky-column",
      align: "left",
    },
    {
      field: "PAYMENT_TYPE",
      headerName: "Payment Type",
      width: 145,
      editable: false,
      align: "left",
    },
    {
      field: "TRANSACTION_NO",
      headerName: "Transaction No",
      width: 162,
      editable: false,
      align: "left",
    },
    {
      field: "AMOUNT_SETTLED",
      headerName: "Amount Settled",
      width: 164,
      editable: false,
      align: "left",
    },
    {
      field: "ACTION",
      width: 104,
      editable: false,
      sortable: false,
      align: "left",
      renderHeader: () => <div></div>,
      renderCell: (params) => {
        const fileName = params.row.FILE_NAME;
        const id = params.row.INV_AR_ID;
        return (
        <div
          className="d-flex flex-row align-items-center"
          style={{ gap: 15, paddingTop: 6 }}
        >
          <div
            className="d-flex flex-column align-items-center"
            style={{ gap: 6 }}
          >
            <div
              className="d-flex flex-row align-items-center"
              style={{ cursor: params.row.FILE_NAME ? "pointer" : "default", width:"50px" }}
              onClick={() => params.row.FILE_NAME && handleView(params.row.FILE_NAME)}
            >
              {params.row.FILE_NAME && (
                <>
                  <h2 id="view_file">View</h2>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M10.6688 6.27614L4.93109 12.0139L3.98828 11.0711L9.72601 5.33333H4.66883V4H12.0021V11.3333H10.6688V6.27614Z"
                      fill="#635BFF"
                    />
                  </svg>
                </>
              )}
            </div>
          </div>
          <svg
            onClick={() => handleEdit(id)}
            style={{ cursor: "pointer" }}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M6.41421 15.8895L16.5563 5.74738L15.1421 4.33316L5 14.4753V15.8895H6.41421ZM7.24264 17.8895H3V13.6468L14.435 2.21184C14.8256 1.82132 15.4587 1.82132 15.8492 2.21184L18.6777 5.04027C19.0682 5.43079 19.0682 6.06395 18.6777 6.45447L7.24264 17.8895ZM3 19.8895H21V21.8895H3V19.8895Z"
              fill="#1B1B24"
            />
            <path
              d="M6.41421 15.8895L16.5563 5.74738L15.1421 4.33316L5 14.4753V15.8895H6.41421ZM7.24264 17.8895H3V13.6468L14.435 2.21184C14.8256 1.82132 15.4587 1.82132 15.8492 2.21184L18.6777 5.04027C19.0682 5.43079 19.0682 6.06395 18.6777 6.45447L7.24264 17.8895ZM3 19.8895H21V21.8895H3V19.8895Z"
              fill="black"
              fill-opacity="0.2"
            />
            <path
              d="M6.41421 15.8895L16.5563 5.74738L15.1421 4.33316L5 14.4753V15.8895H6.41421ZM7.24264 17.8895H3V13.6468L14.435 2.21184C14.8256 1.82132 15.4587 1.82132 15.8492 2.21184L18.6777 5.04027C19.0682 5.43079 19.0682 6.06395 18.6777 6.45447L7.24264 17.8895ZM3 19.8895H21V21.8895H3V19.8895Z"
              fill="black"
              fill-opacity="0.2"
            />
            <path
              d="M6.41421 15.8895L16.5563 5.74738L15.1421 4.33316L5 14.4753V15.8895H6.41421ZM7.24264 17.8895H3V13.6468L14.435 2.21184C14.8256 1.82132 15.4587 1.82132 15.8492 2.21184L18.6777 5.04027C19.0682 5.43079 19.0682 6.06395 18.6777 6.45447L7.24264 17.8895ZM3 19.8895H21V21.8895H3V19.8895Z"
              fill="black"
              fill-opacity="0.2"
            />
            <path
              d="M6.41421 15.8895L16.5563 5.74738L15.1421 4.33316L5 14.4753V15.8895H6.41421ZM7.24264 17.8895H3V13.6468L14.435 2.21184C14.8256 1.82132 15.4587 1.82132 15.8492 2.21184L18.6777 5.04027C19.0682 5.43079 19.0682 6.06395 18.6777 6.45447L7.24264 17.8895ZM3 19.8895H21V21.8895H3V19.8895Z"
              fill="black"
              fill-opacity="0.2"
            />
          </svg>
        </div>
        )
      },
    },
  ];

  const handleAddNew = () => {
    setAddNew(true);
	onHide()
  };

  const handleEdit = (id) => {
    console.log(id)
    localStorage.setItem("INV_AR_ID", id);
    setEdit(true);
	  onHide();

  };

  const handleView = (fileName) => {
    const fileUrl = `${API_URL}media/${fileName}`; // Adjust the path as needed
    window.open(fileUrl, '_blank');
  //   setView(true);
	// onHide();
  };
  console.log(invoiceDetails.dueAmount);

  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        centered
        size="lg"
        style={{ width: "100%" }}
        className="custom-modal"
      >
        <Modal.Body>
          <div id="header">
            <h2 id="receive_payment">Receive Payment</h2>
          </div>
          <div id="body">
            <div id="invoice">
              Invoice No: <span id="invoice_no"> {invoiceDetails.invoiceNo}</span>
            </div>
            <div id="invoice">
              Invoice Amount: <span id="invoice_no">{currencySymbol}{invoiceDetails.invoiceAmount}</span>
            </div>
            <div id="invoice">
              Due Amount: <span id="invoice_no">{currencySymbol}{invoiceDetails.dueAmount}</span>
            </div>
            <div
              style={{
                height: 331,
                width: "100%",
                marginBottom: 20,
                marginTop: 20,
              }}
            >
              <DataGrid
                rows={rows}
                columns={columns}
                pageSize={pageSize}
                rowsPerPageOptions={[10, 20, 50]}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                disableSelectionOnClick
                hideFooterPagination
                hideFooterRowCount
                hideFooter
                hideFooterSelectedRowCount
              />
            </div>
            <div className="d-flex flex-row gap-1">
            {parseFloat(invoiceDetails.dueAmount) > 0 && (
              <>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M11 11V5H13V11H19V13H13V19H11V13H5V11H11Z"
                  fill="#635BFF"
                />
              </svg>{" "}
              <h2 id="add_payment" onClick={handleAddNew}>
                Add New Payment
              </h2>
              </>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <AddNewPayment show={addNew} onHide={() => setAddNew(false)} />
      <EditPayment show={edit} onHide={() => setEdit(false)} />
      {/* <ViewPayment show={view} onHide={() => setView(false)} /> */}
    </>
  );
}
