import React from "react";
import SupplierInfo from "./SupplierInfo";
import OrderDashboardLayout from "./Orders/OrderDashboardLayout";
import SupplierProductPage from "./SupplierProductPage";
import SupplierReportPage from "./SupplierReportPage.js";
import SupplierPriceHistory from "./SupplierPriceHistory.js";

function SupplierDashboard({ activeTab, onTabChange }) {
  
  return (
    <div>
      <div>{activeTab === "Overview" && <SupplierInfo />}</div>
      <div>{activeTab === "Products" && <SupplierProductPage/>}</div>
      <div>{activeTab === "Orders" && <OrderDashboardLayout />}</div>
      <div>{activeTab === "Report" && <SupplierReportPage />}</div>
      <div>{activeTab === "PriceHistory" && <SupplierPriceHistory />}</div>
    </div>
  );
}

export default SupplierDashboard;
