import React, { useEffect, useState } from "react";
import "react-phone-number-input/style.css";
import DeleteCustomer from "./DeleteCustomer";
import TextInputWithAll from "../Inputs/TextInputWithAll";
import TextInput from "../Inputs/TextInput";
import DropDownInput from "../Inputs/DropDownInput";
import { makeApiCall } from "../../StaticComponents/API";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";

const EditCustomer = () => {
  const maxChars = 50;
  const navigate = useNavigate();
  const [inputValue, setInputValue] = useState("");
  const [charCount, setCharCount] = useState(maxChars);
  const [file, setFile] = useState();
  const [image, setImage] = useState(null);
  const [filename, setFilename] = useState("");
  const [value, setValue] = useState();
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [states, setStates] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedCities, setSelectedCities] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [selectedDistrict, setSelectedDistrict] = useState("");
  const [selectedCity, setSelectedCity] = useState("");

  let [searchParams] = useSearchParams();
  let token = localStorage.getItem("token");
  const CLIENT_CUST_ID = searchParams.get("id");
  const initialvalues = {
    ADDR_1: "",
    ADDR_2: "",
    CITY: "",
    CITY_ID: null,
    CITY_NAME: "",
    COMPANY_ID: null,
    COUNTRY: "",
    COUNTRY_ID: null,
    COUNTRY_NAME: "n",
    CREATED_ON: null,
    CUST_NAME: "",
    CUST_NUM: "",
    DISTRICT_ID: null,
    DISTRICT_NAME: null,
    EMAIL: "",
    FAX: "",
    LOGO: "",
    PHONE_NUMBER: "",
    POSTAL_CODE: "",
    PROVINCE: "",
    REG_NUMBER: "",
    STATE: "",
    STATE_ID: null,
    TAX_NUMBER: null,
    WEBSITE: "",
    IS_CUST_FAV: "N",
  };
  const [customerinfo, setcustomerinfo] = useState(initialvalues);

  const handleCancel = () => {
    window.history.go(-1);
  };

  useEffect(() => {
    const payload = { CLIENT_CUST_ID: CLIENT_CUST_ID };
    makeApiCall({
      mod: "Location",
      action: "get-countries",
      payload: { CURRENT_PAGE: "", SEARCH_KEYWORD: "" },
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        const countries = response.XscData.COUNTRY_NAMES;
        if (Array.isArray(countries)) {
          setCountries(
            countries.map((country) => ({
              value: country.ID,
              label: country.COUNTRY,
            }))
          );
        }
      })
      .catch(console.error);

    // Fetch supplier details if an ID is present
    if (CLIENT_CUST_ID) {
      makeApiCall({
        mod: "Customer",
        action: "get-customer-detail",
        payload: payload,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then((res) => {
        console.log(res, "edit customer info");
        console.log("all states", states);
        const custdata = res.XscData.INFO;
        setcustomerinfo(custdata);
        console.log(res.XscData.COUNTRY_ID);
        setSelectedCountry(res.XscData.INFO.COUNTRY_ID || "");
        setSelectedState(res.XscData.INFO.STATE_ID || "");
        setSelectedCity(res.XscData.INFO.CITY_ID || "");
        console.log("customer data", customerinfo);
      });
      console.log(customerinfo, "Cust Info");
      console.log(selectedCity);
      console.log(selectedCountry);
      console.log(selectedState);
    }
  }, [CLIENT_CUST_ID]);
  useEffect(() => {
    if (selectedCountry) {
      makeApiCall({
        mod: "Location",
        action: "get-states",
        payload: {
          COUNTRY_ID: selectedCountry,
          CURRENT_PAGE: "",
          SEARCH_KEYWORD: "",
        },
        headers: { Authorization: `Bearer ${token}` },
      })
        .then((res) => {
          const states = res.XscData.STATE_NAMES;
          if (Array.isArray(states)) {
            const mappedRows = states.map((state) => ({
              value: state.ID,
              label: state.STATE,
            }));
            setStates(mappedRows);
          }
        })
        .catch(console.error);
    } else {
      setStates([]);
      setSelectedState("");
      setCities([]);
      setSelectedCity("");
    }
  }, [selectedCountry]);

  useEffect(() => {
    if (selectedState) {
      makeApiCall({
        mod: "Location",
        action: "get-cities",
        payload: {
          DISTRICT_ID: selectedState,
          CURRENT_PAGE: "",
          SEARCH_KEYWORD: "",
        },
        headers: { Authorization: `Bearer ${token}` },
      })
        .then((res) => {
          const cities = res.XscData.CITY_NAMES;
          if (Array.isArray(cities)) {
            const mappedRows = cities.map((city) => ({
              value: city.ID,
              label: city.CITY,
            }));
            setCities(mappedRows);
          }
        })
        .catch(console.error);
    } else {
      setCities([]);
      setSelectedCity("");
    }
  }, [selectedState]);

  const handleCountryChange = (event) => {
    const value = event.target.value;
    setSelectedCountry(value);
    setcustomerinfo((prev) => ({ ...prev, COUNTRY_ID: value }));
  };

  const handleStateChange = (event) => {
    const value = event.target.value;
    setSelectedState(value);
    setcustomerinfo((prev) => ({ ...prev, STATE_ID: value }));
  };

  const handleCityChange = (event) => {
    const value = event.target.value;
    setSelectedCity(value);
    setcustomerinfo((prev) => ({ ...prev, CITY_ID: value }));
    console.log("selectedCity", selectedCity);
  };
  const StatusOptions = [
    { value: 1, label: "Active" },
    { value: 0, label: "Inactive" },
  ];

  const [show, setShow] = useState(false);

  const handleInputChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;

    const phoneRegex = /^\d*$/;

    if (name === "PHONE_NUMBER" && !phoneRegex.test(value)) {
      setcustomerinfo({
        ...customerinfo,
        IS_CUST_FAV: customerinfo.IS_CUST_FAV || "Y",
        [name]: customerinfo[name],
      });
    } else {
      setcustomerinfo((prev) => ({
        ...prev,
        [name]: value,
      }));
    }

    setInputValue(value);
  };

  const handleChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setFile(URL.createObjectURL(e.target.files[0]));
      setImage(e.target.files[0]);
      setFilename(e.target.files[0].name);
    } else {
      console.error("No file selected");
    }
  };
  console.log("imageuploaded", image);
  useEffect(() => {
    const payload = {
      CLIENT_CUST_ID: CLIENT_CUST_ID,
    };
  }, [CLIENT_CUST_ID]);
  console.log("all countris", countries);
  console.log("country", selectedCountry);
  console.log("State", selectedState);

  const initialvalues1 = {
    CLIENT_CUST_ID: CLIENT_CUST_ID,
    NAME: customerinfo.CUST_NAME,
    EMAIL: customerinfo.EMAIL,
    REG_NUMBER: customerinfo.REG_NUMBER,
    CONTACT_NUMBER: customerinfo.PHONE_NUMBER,
    ALT_CONTACT_NUMBER: customerinfo.PHONE_NUMBER,
    TAX: customerinfo.TAX_NUMBER,
    FAX: customerinfo.FAX,
    WEBSITE: customerinfo.WEBSITE,
    ADDR_1: customerinfo.ADDR_1,
    ADDR_2: customerinfo.ADDR_2,
    COUNTRY_ID: customerinfo.COUNTRY_ID,
    STATE_ID: customerinfo.STATE_ID,
    DISTRICT_ID: customerinfo.DISTRICT_ID,
    CITY_ID: customerinfo.CITY_ID,
    POSTAL_CODE: customerinfo.POSTAL_CODE,
    IS_ACTIVE:customerinfo.IS_ACTIVE,
  };

  const [data, setData] = useState(initialvalues1);

  const checkRequiredFields = () => {
    let missingFields = [];

    if (!customerinfo.CUST_NAME) missingFields.push("Customer Name");
    if (!customerinfo.REG_NUMBER) missingFields.push("Registration Number");
    if (!customerinfo.EMAIL) missingFields.push("Email Address");
    if (!customerinfo.PHONE_NUMBER) missingFields.push("Contact Number");
    if (!customerinfo.FAX) missingFields.push("FAX");
    if (!customerinfo.TAX_NUMBER) missingFields.push("TAX NUMBER");

    return missingFields;
  };
  
  const updateCustomer = async (e) => {
    e.preventDefault();

    const emailRegex =
      /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9]))+$/;

    const payload = {
      CLIENT_CUST_ID: CLIENT_CUST_ID,
      NAME: customerinfo.CUST_NAME,
      EMAIL: customerinfo.EMAIL,
      REG_NUMBER: customerinfo.REG_NUMBER,
      CONTACT_NUMBER: customerinfo.PHONE_NUMBER,
      ALT_CONTACT_NUMBER: customerinfo.PHONE_NUMBER,
      TAX: customerinfo.TAX_NUMBER,
      FAX: customerinfo.FAX,
      WEBSITE: customerinfo.WEBSITE,
      ADDR_1: customerinfo.ADDR_1,
      ADDR_2: customerinfo.ADDR_2,
      COUNTRY_ID: customerinfo.COUNTRY_ID,
      STATE_ID: customerinfo.STATE_ID,
      DISTRICT_ID: customerinfo.DISTRICT_ID,
      CITY_ID: customerinfo.CITY_ID,
      POSTAL_CODE: customerinfo.POSTAL_CODE,
      IS_CUST_FAV: customerinfo.IS_CUST_FAV,
      IS_ACTIVE:customerinfo.IS_ACTIVE,
    };

    
    const checkRequiredFields = () => {
      let missingFields = [];
  
      if (!payload.NAME) missingFields.push("Customer Name");
      if (!payload.EMAIL || !emailRegex.test(payload.EMAIL)) missingFields.push("Email Address");
      if (!payload.CONTACT_NUMBER) missingFields.push("Phone Number");
      if (!payload.COUNTRY_ID) missingFields.push("Country");
      if (!payload.ADDR_1) missingFields.push("Address line 1");
      if (!payload.DISTRICT_ID) missingFields.push("State/Province");
      if (!payload.CITY_ID) missingFields.push("City");
  
      return missingFields;
    };

    const missingFields = checkRequiredFields();

    if (missingFields.length > 0) {
      missingFields.forEach(field => {
        toast.error(`Please fill in the required field: ${field}`);
      });
      return; 
    }
    // const missingFields = checkRequiredFields(payload);
    console.log("payload", payload);
    console.log("customer info", customerinfo);
    if (missingFields.length > 0) {
      const errorMessage = `Please fill in the following fields: ${missingFields.join(
        ", "
      )}`;
      toast.error(errorMessage, {
        position: "bottom-center",
        width: "max-content",
      });
    } else if (!emailRegex.test(payload.EMAIL)) {
      toast.error("Enter correct email!!", {
        position: "bottom-center",
        width: "max-content",
      });
    } else {
      const payload1 = {
        COMPANY_ID: customerinfo.COMPANY_ID,
      };
      console.log("companyid", payload1.COMPANY_ID);
      const formData = new FormData();
      formData.append("mod", "Company");
      formData.append("action", "change-company-logo");
      formData.append("payload", JSON.stringify(payload1));
      formData.append("file", image);
      axios({
        method: "post",
        url: process.env.REACT_APP_API_URL,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {
          console.log(res, "supplier image edited");
          if (res.XscStatus === 1) {
            toast.success("image uploaded", {
              position: "bottom-center",
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
      makeApiCall({
        mod: "Customer",
        action: "update-customer",
        payload: payload,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then((res) => {
        console.log(res, "edit customer");
        if (res.XscStatus === 1) {
          toast.success("items updated", {
            position: "bottom-center",
          });
          setTimeout(() => {
            navigate("/customers");
          }, 500);
        }
      });
    }
  };
  const API_URL = process.env.REACT_APP_API_URL;
  return (
    <div id="supp_bg" className="">
      <div id="suppliers_topbar_bg" className="">
        <div className=" d-flex justify-content-between align-items-center">
          <div id="header_name" className="d-flex container">
            <div>
              <a href="/customers">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M7.82843 11.0009H20V13.0009H7.82843L13.1924 18.3648L11.7782 19.779L4 12.0009L11.7782 4.22266L13.1924 5.63687L7.82843 11.0009Z"
                    fill="var(--onSurface)"
                  />
                  <path
                    d="M7.82843 11.0009H20V13.0009H7.82843L13.1924 18.3648L11.7782 19.779L4 12.0009L11.7782 4.22266L13.1924 5.63687L7.82843 11.0009Z"
                    fill="black"
                    fill-opacity="0.2"
                  />
                  <path
                    d="M7.82843 11.0009H20V13.0009H7.82843L13.1924 18.3648L11.7782 19.779L4 12.0009L11.7782 4.22266L13.1924 5.63687L7.82843 11.0009Z"
                    fill="black"
                    fill-opacity="0.2"
                  />
                  <path
                    d="M7.82843 11.0009H20V13.0009H7.82843L13.1924 18.3648L11.7782 19.779L4 12.0009L11.7782 4.22266L13.1924 5.63687L7.82843 11.0009Z"
                    fill="black"
                    fill-opacity="0.2"
                  />
                  <path
                    d="M7.82843 11.0009H20V13.0009H7.82843L13.1924 18.3648L11.7782 19.779L4 12.0009L11.7782 4.22266L13.1924 5.63687L7.82843 11.0009Z"
                    fill="black"
                    fill-opacity="0.2"
                  />
                </svg>
              </a>
            </div>

            <span className="ps-2">
              {" "}
              Edit Customer
              <div id="edit_supplier_id">{customerinfo.CUST_NUM}</div>
            </span>
          </div>
        </div>
      </div>
      <div className="container">
        <div id="add_supplier_bg">
          <form className="row">
            <div className="col-12">
              <label
                style={{ width: "380px" }}
                htmlFor="file-input"
                className="d-flex align-items-center"
                id=""
              >
                <div id="upload_img_bg" style={{ cursor: "pointer" }}>
                  {customerinfo.LOGO !== null ? (
                    file ? (
                      <img src={file} alt="Uploaded file" />
                    ) : (
                      <img
                        src={API_URL + `media/${customerinfo.LOGO}`}
                        alt="Uploaded file"
                      />
                    )
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="40"
                      height="40"
                      viewBox="0 0 40 40"
                      fill="none"
                    >
                      <path
                        d="M34.9987 25V30H39.9987V33.3333H34.9987V38.3333H31.6654V33.3333H26.6654V30H31.6654V25H34.9987ZM35.0124 5C35.9254 5 36.6654 5.74158 36.6654 6.65567V21.6667H33.332V8.33333H6.66536V31.665L23.332 15L28.332 20V24.715L23.332 19.714L11.377 31.6667H23.332V35H4.98503C4.07211 35 3.33203 34.2585 3.33203 33.3443V6.65567C3.33203 5.74127 4.09088 5 4.98503 5H35.0124ZM13.332 11.6667C15.173 11.6667 16.6654 13.159 16.6654 15C16.6654 16.841 15.173 18.3333 13.332 18.3333C11.4911 18.3333 9.9987 16.841 9.9987 15C9.9987 13.159 11.4911 11.6667 13.332 11.6667Z"
                        fill="#5A5892"
                      />
                    </svg>
                  )}
                </div>
                <div id="upload_img_text">
                  <>
                    Upload Customer Profile Picture
                    <div id="img_size">It should be 400 by 400 pixels</div>
                    <div id="upload_img_button">
                      {file ? "Upload" : "Change"}
                    </div>
                  </>
                </div>
              </label>
              <TextInputWithAll
                name="file-input"
                type="file"
                onChange={handleChange}
                id="file-input"
                style={{ display: "none" }}
                maxLength={1}
                accept="image/*"
              />
            </div>

            <div className="col-5" id="add_supplier_input">
              <div className="position-relative" style={{ width: "380px" }}>
                <label>
                  Customer Name <span style={{ color: "#950F27" }}>*</span>
                </label>
                <br />
                <TextInputWithAll
                  type="text"
                  onChange={handleInputChange}
                  name="CUST_NAME"
                  maxLength={maxChars}
                  value={customerinfo.CUST_NAME}
                  //value={customerData.NAME}
                />{" "}
                <div
                  id="remaining_characters"
                  className="position-absolute transalate-middle-x "
                >
                  {customerinfo.CUST_NAME.length}/50
                </div>
              </div>{" "}
              <div>
                <label>
                  Phone Number <span style={{ color: "#950F27" }}>*</span>
                </label>
                <br />
                {/* <input type="text" /> */}
                <div id="phone_input">
                  <TextInput
                    type=""
                    //value={data.contactNumber}
                    name="PHONE_NUMBER"
                    onChange={handleInputChange}
                    value={customerinfo.PHONE_NUMBER}
                    pattern="/^[0-9]+$/"
                    id="PHONE_NUMBER"
                    required
                  />
                </div>
              </div>
              <div>
                <label>
                  Address line 1 <span style={{ color: "#950F27" }}>*</span>
                </label>
                <br />
                <TextInput
                  type="text"
                  name="ADDR_1"
                  onChange={handleInputChange}
                  value={customerinfo.ADDR_1}
                />
              </div>
              <div>
                <DropDownInput
                  options={countries}
                  id="selectCountry"
                  value={selectedCountry}
                  name="country"
                  onChange={handleCountryChange}
                  label={
                    <div>
                      Country <span style={{ color: "#950F27" }}>*</span>
                    </div>
                  }
                  placeholder="Select Country"
                  required
                />
              </div>
              <DropDownInput
                options={cities}
                id="selectCountry"
                value={selectedCity}
                name="city"
                onChange={handleCityChange}
                label={
                  <div>
                    City <span style={{ color: "#950F27" }}>*</span>
                  </div>
                }
                placeholder="Select City"
                required
              />
              <div>
                <label>Fax no</label>
                <br />
                <TextInput
                  type="text"
                  name="FAX"
                  onChange={handleInputChange}
                  value={customerinfo.FAX}
                />
              </div>
            </div>
            <div className="col-5" id="add_supplier_input">
              <div className="position-relative" style={{ width: "380px" }}>
                <label>
                  Email Address <span style={{ color: "#950F27" }}>*</span>
                </label>
                <br />
                <TextInput
                  type="email"
                  name="EMAIL"
                  value={customerinfo.EMAIL}
                  onChange={handleInputChange}
                />{" "}
                {/* <div
                  id="remaining_characters"
                  className="position-absolute transalate-middle-x "
                >
                  {charCount}/50
                </div> */}
              </div>{" "}
              <div>
                <label>Website</label>
                <br />
                <TextInput
                  type="text"
                  value={customerinfo.WEBSITE}
                  onChange={handleInputChange}
                  name="WEBSITE"
                />
              </div>
              <div>
                <label>Address line 2</label>
                <br />
                <TextInput
                  type="text"
                  value={customerinfo.ADDR_2}
                  name="ADDR_2"
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <DropDownInput
                  options={states}
                  id="selectCountry"
                  value={selectedState}
                  name="State"
                  onChange={handleStateChange}
                  label={
                    <div>
                      State/Province <span style={{ color: "#950F27" }}>*</span>
                    </div>
                  }
                  placeholder="Select State"
                  required
                />
              </div>
              <div>
                <label>Tax no</label>
                <br />
                <TextInput
                  type="text"
                  value={customerinfo.TAX_NUMBER}
                  name="TAX_NUMBER"
                  onChange={handleInputChange}
                />
              </div>
              <DropDownInput
                options={StatusOptions}
                id="selectCountry"
                label="Customer Status"
                placeholder="Select Status"
                nama="IS_SUP_FAV"
                // value={customerinfo.IS_SUPP_FAV}
                value={customerinfo.IS_ACTIVE === null ? "1" : customerinfo.IS_ACTIVE} // Ensures correct display
                onChange={(e) => {
                const newStatus = e.target.value; // Get selected value
                setcustomerinfo((prev) => ({
                   ...prev,
                   IS_ACTIVE: newStatus, // Update IS_ACTIVE in state
                 }));
                 }}
              />
            </div>
          </form>
        </div>
      </div>
      <div id="add_supplier_footer" className="">
        <div className="container">
          <div id="add_supplier_buttons" className="">
            {" "}
            <div id="delete_supplier_button" onClick={() => setShow(true)}>
              Delete Customer
            </div>
            <DeleteCustomer show={show} onHide={() => setShow(false)} />
            <div
              className="d-flex align-items-center justify-content-right "
              style={{ gap: "12px" }}
            >
              <div
                id="cancel"
                onClick={handleCancel}
                style={{ cursor: "pointer" }}
              >
                Cancel
              </div>
              <div id="submitsupplier" onClick={updateCustomer}>
                Update Customer
              </div>
            </div>
          </div>
          <ToastContainer
          position="bottom-center"
          style={{ width: "max-content" }}
        />
        </div>
      </div>
    </div>
  );
};

export default EditCustomer;
