import React, { useState, useEffect } from "react";
import Filter from "../../StaticComponents/Filter";
import SearchBar from "../../StaticComponents/SearchBar";
import "../../Styles/ReportComponents.css";
import ReportCustomerFilter from "./ReportCustomerFilter";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { Dropdown } from "react-bootstrap";
import ReportCustomerTable from "./ReportCustomerTable";
import DatePickerModal from "./DatePickerModal";
import { makeApiCall } from "../../StaticComponents/API";

export default function ReportCustomer() {
  const [modalShow, setModalShow] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const selectionRange = {
    startDate: startDate,
    endDate: endDate,
    key: "selection",
  };
  const [searchkeyword, setsearchkeyword] = useState("");
  const [notify, setnotify] = useState(0);
  const onNotify = (value) => {
    setnotify(value);
  };
  const onSearch = (value) => {
    setsearchkeyword(value);
  };
  const handleSelect = (data) => {
    setStartDate(data.selection.startDate);
    setEndDate(data.selection.endDate);
  };

  let APP_CLIENT_ID = localStorage.getItem("CLIENT_ID");

  let token = localStorage.getItem("token");

  const [customerReportDetails, setCustomerReportDetails] = React.useState({
    xscdata: "",
  });

  const getData = () => {
    const payload = {
      APP_CLIENT_ID: APP_CLIENT_ID,
    };

    makeApiCall({
      mod: "Report",
      action: "customer-report",
      payload: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((res) => {
      console.log(res, "report-customer-info");
      setCustomerReportDetails({
        xscdata: res.XscData,
      });
    });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div id="report_bg">
      <div className="container">
        <div id="report_overview_heading" className="row">
          <div className="col-md-4">
            <div id="total_stock">
              Total Customer
              <div id="total_count">
                {customerReportDetails.xscdata.TOTAL_CUSTOMER}
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div id="total_stock">
              Regular Customer
              <div id="total_count">
                {customerReportDetails.xscdata.REGULAR_CUSTOMERS}
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div id="total_stock">
              Favourite Customer
              <div id="total_count">
                {customerReportDetails.xscdata.FAVORITE_CUSTOMERS}
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex" id="invFilters">
          <SearchBar onSearch={onSearch} />
          <div onClick={() => setModalShow(true)} style={{ cursor: "pointer" }}>
            <Filter notify={notify} />
          </div>
          <ReportCustomerFilter
            show={modalShow}
            onHide={() => setModalShow(false)}
            onNotify={onNotify}
          />
          <Dropdown>
            <Dropdown.Toggle
              style={{
                marginLeft: "12px",
                width: "301px",
                height: "40px",
                backgroundColor: "var(--onSurfaceLowest)",
                color: "var(--onSurface)",
                border: "1px solid var(--outline1)",
                borderRadius: "var(--cr---3)",
              }}
            >
              Date
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <DatePickerModal />
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div id="report_tab_bg">
          <h2 className="totoal_1000_results">
            {customerReportDetails.xscdata.TOTAL_CUSTOMER} Customers
          </h2>
          <ReportCustomerTable searchkeyword={searchkeyword} />
        </div>
      </div>
    </div>
  );
}
