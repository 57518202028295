import React, { useEffect, useState } from "react";
import logo from "../../assets/Manex Logo.png";
import { DataGrid } from "@mui/x-data-grid";
import { Button } from "react-bootstrap";
import "../../Styles/CreatePI.css";
import "react-datepicker/dist/react-datepicker.css";
import { makeStyles } from "@material-ui/styles";
import "../../Styles/CreateShipping.css";
import { makeApiCall } from "../../StaticComponents/API";

const useStyles = makeStyles({
  subheadingRow: {
    background: "var(--SurfaceContainerLow, #F5F2FF)",
    textAlign: "center",
    fontWeight: "bold",
    minHeight: "32px !important",
    maxHeight: "32px !important",
    "& .MuiDataGrid-cell": {
      minHeight: "32px !important",
      maxHeight: "32px !important",
      borderRight: "none !important",
      borderBottom: "none !important",
      minWidth: "1000px !important",
      maxWidth: "1000px !important",
    },
  },

  totalRow: {
    textAlign: "center",
    fontWeight: "bold",
    minHeight: "32px !important",
    maxHeight: "32px !important",
    "& .MuiDataGrid-cell": {
      border: "none !important",
      lineHeight: "32px !important",
      minHeight: "32px !important",
      maxHeight: "32px !important",
    },
  },

  subheadingCell: {
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    paddingLeft: 10,
    width: "100%",
    border: "0.5px solid var(--outlineVariant) !important",
    lineHeight: "24px !important",
    minHeight: "32px !important",
    maxHeight: "32px !important",
  },

  padding: { padding: "0px !important" },
});

export default function ViewShippingHistory() {
  const classes = useStyles();
  const token = localStorage.getItem("token");
  const [PackagingInfo, setPackagingInfo] = useState({});
  const [shipping_details, setshipping_details] = useState({});
  const [shippingmarks, setshippingmarks] = useState([]);
  const columns = [
    {
      field: "MNX_ID",
      headerName: "Mnx. Item ID",
      width: 148,
      renderCell: (params) => {
        return params.id === "subheading" ? (
          <div className={classes.subheadingCell}>{params.value}</div>
        ) : (
          params.value
        );
      },
    },
    {
      field: "CUST_ITEM_ID",
      headerName: "Cust. Item ID",
      width: 148,
      renderCell: (params) => {
        return params.id === "subheading" ? (
          <div className={classes.subheadingCell}>{params.value}</div>
        ) : (
          params.value
        );
      },
    },
    {
      field: "DESCRIPTION",
      headerName: "Description",
      width: 275,
      renderCell: (params) => {
        return params.id === "subheading" ? (
          <div className={classes.subheadingCell}>{params.value}</div>
        ) : (
          params.value
        );
      },
    },
    {
      field: "CTN",
      headerName: "CTN",
      width: 148,
      type: "number",
      align: "left",
      renderCell: (params) => {
        return params.row.id_desc === "Total" ? (
          <div className={classes.subheadingCell}>{params.value}</div>
        ) : null;
      },
    },
    {
      field: "INN",
      headerName: "INN",
      width: 148,
    },
    {
      field: "PACKING",
      headerName: "Packing",
      width: 275,
    },
    {
      field: "TOTAL_QTY",
      headerName: "Total QTY",
      width: 125,
      renderCell: (params) => {
        return params.row.id_desc === "Total" ? (
          <div className={classes.subheadingCell}>{params.value}</div>
        ) : null;
      },
    },
    {
      field: "CBM_CTN",
      headerName: "CBM/CTN",
      width: 148,
    },
    {
      field: "TTL_CBM",
      headerName: "TTL CBM",
      width: 130,
      renderCell: (params) => {
        return params.row.id_desc === "Total" ? (
          <div className={classes.subheadingCell}>{params.value}</div>
        ) : null;
      },
    },
    {
      field: "CBM_PCS",
      headerName: "CBM/PCS",
      width: 148,
      cellClassName: "po_input",
    },
    {
      field: "NG_KG",
      headerName: "N.G/K.G",
      width: 148,
    },
    {
      field: "GW_KG",
      headerName: "G.W/K.G",
      width: 148,
    },
    {
      field: "TOTAL_NW",
      headerName: "Total N.W",
      width: 148,
      renderCell: (params) => {
        return params.row.id_desc === "Total" ? (
          <div className={classes.subheadingCell}>{params.value}</div>
        ) : null;
      },
    },
    {
      field: "NW_PCS",
      headerName: "N.W/PCS",
      width: 148,
    },
    {
      field: "TOTAL_GW",
      headerName: "Total G.W",
      width: 148,
      renderCell: (params) => {
        return params.row.id_desc === "Total" ? (
          <div className={classes.subheadingCell}>{params.value}</div>
        ) : null;
      },
    },
    {
      field: "GW_PCS",
      headerName: "G.W/PCS",
      width: 148,
    },
  ];

  const initialRows = [
    {
      SUBHEADING: true,
      id: "1",
      MNX_ID: "Invoice no: MNX-2024-0001",
      CUST_ITEM_ID: "",
      DESCRIPTION: "",
      CTN: "",
      INN: "",
      PACKING: "",
      TOTAL_QTY: "",
      CBM_CTN: "",
      TTL_CBM: "",
      CBM_PCS: "",
      NG_KG: "",
      GW_KG: "",
      TOTAL_NW: "",
      NW_PCS: "",
      TOTAL_GW: "",
      GW_PCS: "",
    },
    {
      SUBHEADING: false,
      id: "2",
      MNX_ID: "F-1215",
      CUST_ITEM_ID: "S-1315",
      DESCRIPTION: 'Concrete Nails 2"',
      CTN: 10,
      INN: "",
      PACKING: "144",
      TOTAL_QTY: "1440",
      CBM_CTN: "0.04",
      TTL_CBM: "0.40",
      CBM_PCS: "0.000278",
      NG_KG: "18.70",
      GW_KG: "19.70",
      TOTAL_NW: "187.00",
      NW_PCS: "0.129861",
      TOTAL_GW: "197.00",
      GW_PCS: "0.136806",
    },
    {
      SUBHEADING: false,
      id: "3",
      MNX_ID: "F-1216",
      CUST_ITEM_ID: "S-1316",
      DESCRIPTION: 'Concrete Nails 2.5"',
      CTN: 9,
      INN: "",
      PACKING: "144",
      TOTAL_QTY: "1296",
      CBM_CTN: "0.04",
      TTL_CBM: "0.49",
      CBM_PCS: "0.000375",
      NG_KG: "20.70",
      GW_KG: "21.70",
      TOTAL_NW: "186.30",
      NW_PCS: "0.143750",
      TOTAL_GW: "195.30",
      GW_PCS: "0.150694",
    },
    {
      SUBHEADING: true,
      id: "4",
      MNX_ID: "Invoice no: MNX-2024-0002",
      CUST_ITEM_ID: "",
      DESCRIPTION: "",
      CTN: "",
      INN: "",
      PACKING: "",
      TOTAL_QTY: "",
      CBM_CTN: "",
      TTL_CBM: "",
      CBM_PCS: "",
      NG_KG: "",
      GW_KG: "",
      TOTAL_NW: "",
      NW_PCS: "",
      TOTAL_GW: "",
      GW_PCS: "",
    },
    {
      SUBHEADING: false,
      id: "5",
      MNX_ID: "F-1215",
      CUST_ITEM_ID: "S-1315",
      DESCRIPTION: 'Concrete Nails 2"',
      CTN: 10,
      INN: "",
      PACKING: "144",
      TOTAL_QTY: "1440",
      CBM_CTN: "0.04",
      TTL_CBM: "0.40",
      CBM_PCS: "0.000278",
      NG_KG: "18.70",
      GW_KG: "19.70",
      TOTAL_NW: "187.00",
      NW_PCS: "0.129861",
      TOTAL_GW: "197.00",
      GW_PCS: "0.136806",
    },
    {
      SUBHEADING: false,
      id: "6",
      MNX_ID: "F-1216",
      CUST_ITEM_ID: "S-1316",
      DESCRIPTION: 'Concrete Nails 2.5"',
      CTN: 9,
      INN: "",
      PACKING: "144",
      TOTAL_QTY: "1296",
      CBM_CTN: "0.04",
      TTL_CBM: "0.49",
      CBM_PCS: "0.000375",
      NG_KG: "20.70",
      GW_KG: "21.70",
      TOTAL_NW: "186.30",
      NW_PCS: "0.143750",
      TOTAL_GW: "195.30",
      GW_PCS: "0.150694",
    },
    {
      SUBHEADING: false,
      id: "7",
      id_desc: "Total",
      MNX_ID: "",
      CUST_ITEM_ID: "",
      DESCRIPTION: "",
      CTN: 19,
      INN: "",
      PACKING: "",
      TOTAL_QTY: "2,736",
      CBM_CTN: "",
      TTL_CBM: "0.89",
      CBM_PCS: "",
      NG_KG: "",
      GW_KG: "",
      TOTAL_NW: "373.3",
      NW_PCS: "",
      TOTAL_GW: "392.3",
      GW_PCS: "",
    },
  ];
  const [rows, setRows] = useState([]);
  function unixTimestampToDate(unixTimestamp) {
    const date = new Date(unixTimestamp * 1000);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }
  const handleInputChange = (e, rowId, field) => {
    console.log(e);
    const newRows = rows.map((row) => {
      if (row.id === rowId) {
        return { ...row, [field]: e.target.value };
      }
      return row;
    });
    setRows(newRows);
  };

  const getRowClassName = (params) => {
    if (params.row.id_desc === "Total") {
      return classes.totalRow;
    } else if (params.row.SUBHEADING && params.row.id_desc === undefined) {
      return classes.subheadingRow;
    } else {
      return "";
    }
  };

  const getCellClassName = (params) => {
    return params.row.id_desc === "Total" ? classes.padding : "";
  };

  const [clientData, setClientData] = useState({
    xscdata: "",
  });
  const [custData, setCustData] = useState({
    xscdata: "",
  });

  const getData = () => {
    const payload = {
      CLIENT_CUST_ID: localStorage.getItem("CLIENT_CUST_ID"),
    };

    makeApiCall({
      mod: "Customer",
      action: "get_client_cust_details",
      payload: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((res) => {
      console.log(res, "customer-reports");
      setClientData({
        xscdata: res.XscData.CLIENT_DATA,
      });
      setCustData({
        xscdata: res.XscData.CUST_DATA,
      });
    });
  };

  useEffect(() => {
    getData();
    getDetails();
  }, []);
  const SHIPMENT_ID = localStorage.getItem("SHIPMENT_ID");
  const CLIENT_CUST_ID = localStorage.getItem("CLIENT_CUST_ID");
  const getDetails = () => {
    const payload = {
      SHIPMENT_ID: SHIPMENT_ID,
      CLIENT_CUST_ID: CLIENT_CUST_ID,
    };
    makeApiCall({
      mod: "Shipping",
      action: "view_packaging",
      payload: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => {
      console.log("PAYLOAD FOR VIEW SH",payload)
      console.log("RESPONSE FOR VIEW S&H", response);
      setPackagingInfo(response.XscData.PACKAGE_INFO);
      setshipping_details(response.XscData.SHIPPING_DETAILS);
      setshippingmarks(response.XscData.SHIPPING_DETAILS.SHIPPING_MARK);
      const shippingItems2 = response.XscData.INVOICE_DATA;
      if (Array.isArray(shippingItems2)) {
        const mappedRows = shippingItems2.flatMap((invoice) => {
          const invoiceRow = {
            SUBHEADING: true,
            id: invoice.CUST_INV_ID,
            MNX_ID: `Invoice no: ${invoice.CUST_INV_NUM}`,
            CUST_ITEM_ID: "",
            DESCRIPTION: "",
            CTN: "",
            INN: "",
            CTN_QTY: "",
            TOTAL_QTY: "",
            CBM_CTN: "",
            TTL_CBM: "",
            CBM_PCS: "",
            NG_KG: "",
            GW_KG: "",
            TOTAL_NW: "",
            NW_PCS: "",
            TOTAL_GW: "",
            GW_PCS: "",
          };

          const itemsRows = invoice.PRODUCT_DETAILS.map((item) => {
            return {
              SUBHEADING: false,
              id: `${invoice.CUST_INV_ID}-${item.CLIENT_ITEM_NUM}`,
              MNX_ID: item.CLIENT_ITEM_NUM ?? "N/A",
              CUST_ITEM_ID: item.CUST_ITEM_NUM ?? "N/A",
              DESCRIPTION: item.DESCRIPTION ?? "N/A",
              CTN: item.TOTAL_CTN ?? "N/A",
              INN: item.INNER ?? "N/A",
              CTN_QTY: item.QTY_PER_CTN ?? "N/A",
              TOTAL_QTY:
                item.QTY_PER_CTN && item.TOTAL_CTN
                  ? item.QTY_PER_CTN * item.TOTAL_CTN
                  : "N/A",
              CBM_CTN: item.CBM_PER_CTN ?? "N/A",
              TTL_CBM: item.CBM_PER_CTN ?? "N/A",
              CBM_PCS: item.CLIENT_ITEM_NUM ?? "N/A",
              NG_KG: item.NG_KG ?? "N/A",
              GW_KG: item.GW_KG ?? "N/A",
              TOTAL_NW:
                item.NG_KG && item.TOTAL_CTN
                  ? item.NG_KG * item.TOTAL_CTN
                  : "N/A",
              NW_PCS: item.NG_KG ?? "N/A",
              TOTAL_GW:
                item.GW_KG && item.TOTAL_CTN
                  ? item.GW_KG * item.TOTAL_CTN
                  : "N/A",
              GW_PCS: item.GW_KG ?? "N/A",
            };
          });

          return [invoiceRow, ...itemsRows];
        });
        const lastInvoiceItems =
          shippingItems2[shippingItems2.length - 1]?.PRODUCT_DETAILS || [];
        const totalRow = {
          SUBHEADING: false,
          id: "total-row",
          id_desc: "Total",
          MNX_ID: "",
          CUST_ITEM_ID: "",
          DESCRIPTION: "",
          CTN: lastInvoiceItems.reduce(
            (sum, item) => sum + (item.TOTAL_CTN || 0),
            0
          ), // sum CTN
          INN: "",
          PACKING: "",
          TOTAL_QTY: lastInvoiceItems
            .reduce(
              (sum, item) => sum + (item.QTY_PER_CTN * item.TOTAL_CTN || 0),
              0
            )
            .toFixed(2), // sum TOTAL_QTY
          CBM_CTN: "",
          TTL_CBM: lastInvoiceItems
            .reduce((sum, item) => sum + (item.CBM_PER_CTN || 0), 0)
            .toFixed(2), // sum TTL_CBM
          CBM_PCS: "",
          NG_KG: "",
          GW_KG: "",
          TOTAL_NW: lastInvoiceItems
            .reduce((sum, item) => sum + (item.NG_KG * item.TOTAL_CTN || 0), 0)
            .toFixed(2), // sum TOTAL_NW
          NW_PCS: "",
          TOTAL_GW: lastInvoiceItems
            .reduce((sum, item) => sum + (item.GW_KG * item.TOTAL_CTN || 0), 0)
            .toFixed(2),
          GW_PCS: "",
        };

        mappedRows.push(totalRow);

        setRows(mappedRows);
      } else {
        console.error("Client, Customer and Invoice has no data");
      }
    });
  };
  return (
    <div>
      <div id="suppliers_topbar_bg" className="">
        <div className="container d-flex justify-content-between align-items-center">
          <div
            className="d-flex flex-column align-items-center"
            style={{ gap: "4px" }}
          >
            <div
              id="header_name"
              className="d-flex align-items-center"
              style={{ gap: "16px" }}
            >
              <a
                href="/reports"
                onClick={() =>
                  localStorage.setItem("activeTab", "Shipping History")
                }
                className="text-decoration-none"
              >
                {" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M7.82843 11.0009H20V13.0009H7.82843L13.1924 18.3648L11.7782 19.779L4 12.0009L11.7782 4.22266L13.1924 5.63687L7.82843 11.0009Z"
                    fill="#1B1B24"
                  />
                  <path
                    d="M7.82843 11.0009H20V13.0009H7.82843L13.1924 18.3648L11.7782 19.779L4 12.0009L11.7782 4.22266L13.1924 5.63687L7.82843 11.0009Z"
                    fill="black"
                    fill-opacity="0.2"
                  />
                  <path
                    d="M7.82843 11.0009H20V13.0009H7.82843L13.1924 18.3648L11.7782 19.779L4 12.0009L11.7782 4.22266L13.1924 5.63687L7.82843 11.0009Z"
                    fill="black"
                    fill-opacity="0.2"
                  />
                  <path
                    d="M7.82843 11.0009H20V13.0009H7.82843L13.1924 18.3648L11.7782 19.779L4 12.0009L11.7782 4.22266L13.1924 5.63687L7.82843 11.0009Z"
                    fill="black"
                    fill-opacity="0.2"
                  />
                  <path
                    d="M7.82843 11.0009H20V13.0009H7.82843L13.1924 18.3648L11.7782 19.779L4 12.0009L11.7782 4.22266L13.1924 5.63687L7.82843 11.0009Z"
                    fill="black"
                    fill-opacity="0.2"
                  />
                </svg>{" "}
              </a>

              <h2 id="view_shipping">View S&H</h2>
            </div>
            <p id="shipping_id">C0987</p>
          </div>
        </div>
      </div>
      <div id="create_po_bg">
        <div className="container">
          <div id="po_logo" align="center">
            <img src={logo} alt="logo" />
            <div id="po_header_text"> {custData.xscdata.NAME} </div>
          </div>
          <div className="row" id="about_po_header">
            <div className="col-6">
              <div id="po_supplier_text">Supplier</div>
              <div id="po_supplier_name">{custData.xscdata.NAME}</div>
              <div id="po_supplier_address">
                {custData.xscdata.ADDR_1}, {custData.xscdata.CITY},{" "}
                {custData.xscdata.COUNTRY}
              </div>
              <div id="po_supplier_ph">
                TEL:{" "}
                <span id="po_supplier_no">
                  {custData.xscdata.CONTACT_NUMBER}
                </span>
              </div>
              <div id="po_supplier_ph">
                FAX: <span id="po_supplier_no"> {custData.xscdata.FAX}</span>
              </div>
              <div id="po_supplier_ph">
                Invoice No: <span id="po_supplier_no"> MNXS-2024-0001</span>
              </div>
              <div id="po_supplier_ph">
                Tax Purchase Order No:{" "}
                <span id="po_supplier_no">{custData.xscdata.TAX_NUMBER}</span>
              </div>
            </div>
            <div className="col-6 " id="about_po_left_bg" align="end">
              <div id="content_wrapper">
                {" "}
                <div id="po_supplier_text">Client</div>
                <div id="po_supplier_name">{clientData.xscdata.NAME}</div>
                <div id="po_supplier_address">
                  {clientData.xscdata.ADDR_1}, {clientData.xscdata.CITY},{" "}
                  {clientData.xscdata.COUNTRY}
                </div>
                <div id="po_supplier_ph">
                  TEL:{" "}
                  <span id="po_supplier_no">
                    {" "}
                    {clientData.xscdata.CONTACT_NUMBER}
                  </span>
                </div>
                <div id="po_supplier_ph">
                  FAX: <span id="po_supplier_no">{clientData.xscdata.FAX}</span>
                </div>
                <div id="po_supplier_ph">
                  Customer PO No: <span id="po_supplier_no">UP987</span>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              maxHeight: "fit-content",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <DataGrid
              rows={rows}
              columns={columns}
              hideFooterPagination={true}
              hideFooter={true}
              autoHeight
              rowHeight={80}
              showCellRightBorder
              getRowClassName={getRowClassName}
              getCellClassName={getCellClassName}
            />
          </div>

          <div
            className="d-flex flex-column gap-4"
            style={{
              marginTop: 45,
              borderBottom: "0.5px solid var(--outlineVariant)",
            }}
          >
            <div className="d-flex flex-row align-items-center gap-2">
              <div
                style={{
                  width: 8,
                  height: 44,
                  background: "var(--primparyContainer, #635bff)",
                }}
              ></div>
              <h2 id="shipping_details">Package Info</h2>
            </div>
            <div
              className="d-flex flex-column"
              style={{
                gap: 12,
                marginLeft: 17,
                marginBottom: 44,
              }}
            >
              <div className="d-flex flex-row gap-2">
                <strong>Total CTNS:</strong> {PackagingInfo.TOTAL_CTN}
              </div>
              <div className="d-flex flex-row gap-2">
                <strong>Total QTY:</strong> {PackagingInfo.TOTAL_ITEM} | PCS
              </div>
              <div className="d-flex flex-row gap-2">
                <strong>Total N.W:</strong> {PackagingInfo.TTL_NT_WT} | KGS
              </div>
              <div className="d-flex flex-row gap-2">
                <strong>Total G.W:</strong> {PackagingInfo.TTL_GW_WT} | KGS
              </div>
              <div className="d-flex flex-row gap-2">
                <strong>Total CBM:</strong> {PackagingInfo.TTL_CBM}
              </div>
            </div>
          </div>

          <div
            className="d-flex flex-column gap-4"
            style={{
              marginTop: 52,
              borderBottom: "0.5px solid var(--outlineVariant)",
            }}
          >
            <div className="d-flex flex-row align-items-center gap-2">
              <div
                style={{
                  width: 8,
                  height: 44,
                  background: "var(--primparyContainer, #635bff)",
                }}
              ></div>
              <h2 id="shipping_details">Shipping Details</h2>
            </div>
            <div
              className="d-flex flex-column"
              style={{
                gap: 12,
                marginLeft: 17,
                marginBottom: 44,
              }}
            >
              <div className="d-flex flex-row gap-2">
                <strong>Shipping Company:</strong>{" "}
                {shipping_details.SHIPPING_COMPANY_NAME}
              </div>
              <div className="d-flex flex-row gap-2">
                <strong>Container No:</strong> {shipping_details.CONTAINER_NUM}
              </div>
              <div className="d-flex flex-row gap-2">
                <strong>Seal No:</strong> {shipping_details.SEAL_NUMBER}
              </div>
              <div className="d-flex flex-row gap-2">
                <strong>Vessel Name:</strong> {shipping_details.VESSEL_NAME}
              </div>
              <div className="d-flex flex-row gap-2">
                <strong>Sailing Date:</strong>{" "}
                {unixTimestampToDate(shipping_details.SAILING_DATE)}
              </div>
              <div className="d-flex flex-row gap-2">
                <strong>Expected Delivery Date:</strong>{" "}
                {unixTimestampToDate(shipping_details.EXPECTED_DELIVERY_DATE)}
              </div>
              <div className="d-flex flex-row gap-2">
                <strong>Payment Type:</strong> {shipping_details.PAYMENT_TYPE}
              </div>
            </div>
          </div>

          <div
            className="d-flex flex-column gap-4"
            style={{
              marginTop: 52,
              borderBottom: "0.5px solid var(--outlineVariant)",
            }}
          >
            <div className="d-flex flex-row align-items-center gap-2">
              <div
                style={{
                  width: 8,
                  height: 44,
                  background: "var(--primparyContainer, #635bff)",
                }}
              ></div>
              <h2 id="shipping_details">Shipping Marks</h2>
            </div>
            <div
              className="d-flex flex-column"
              style={{
                gap: 12,
                marginLeft: 17,
                marginBottom: 44,
              }}
            >
              {shippingmarks}
            </div>
          </div>

          <div
            className="d-flex flex-column align-items-start"
            style={{ marginTop: 44, gap: 24 }}
          >
            <div
              className="d-flex flex-row align-items-center"
              style={{
                gap: 12,
              }}
            >
              <div
                style={{
                  width: 8,
                  height: 44,
                  flexShrink: 0,
                  background: "var(--primparyContainer, #635bff)",
                }}
              ></div>
              <span id="customer_po_text">Consignment File</span>
            </div>

            <div
              className="d-flex flex-row align-items-center"
              style={{ marginBottom: 191, gap: 36 }}
            >
              <div className="d-flex flex-row align-items-center gap-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M5 4H15V8H19V20H5V4ZM3.9985 2C3.44749 2 3 2.44405 3 2.9918V21.0082C3 21.5447 3.44476 22 3.9934 22H20.0066C20.5551 22 21 21.5489 21 20.9925L20.9997 7L16 2H3.9985ZM10.4999 7.5C10.4999 9.07749 10.0442 10.9373 9.27493 12.6534C8.50287 14.3757 7.46143 15.8502 6.37524 16.7191L7.55464 18.3321C10.4821 16.3804 13.7233 15.0421 16.8585 15.49L17.3162 13.5513C14.6435 12.6604 12.4999 9.98994 12.4999 7.5H10.4999ZM11.0999 13.4716C11.3673 12.8752 11.6042 12.2563 11.8037 11.6285C12.2753 12.3531 12.8553 13.0182 13.5101 13.5953C12.5283 13.7711 11.5665 14.0596 10.6352 14.4276C10.7999 14.1143 10.9551 13.7948 11.0999 13.4716Z"
                    fill="var(--onSurface)"
                  />
                  <path
                    d="M5 4H15V8H19V20H5V4ZM3.9985 2C3.44749 2 3 2.44405 3 2.9918V21.0082C3 21.5447 3.44476 22 3.9934 22H20.0066C20.5551 22 21 21.5489 21 20.9925L20.9997 7L16 2H3.9985ZM10.4999 7.5C10.4999 9.07749 10.0442 10.9373 9.27493 12.6534C8.50287 14.3757 7.46143 15.8502 6.37524 16.7191L7.55464 18.3321C10.4821 16.3804 13.7233 15.0421 16.8585 15.49L17.3162 13.5513C14.6435 12.6604 12.4999 9.98994 12.4999 7.5H10.4999ZM11.0999 13.4716C11.3673 12.8752 11.6042 12.2563 11.8037 11.6285C12.2753 12.3531 12.8553 13.0182 13.5101 13.5953C12.5283 13.7711 11.5665 14.0596 10.6352 14.4276C10.7999 14.1143 10.9551 13.7948 11.0999 13.4716Z"
                    fill="black"
                    fill-opacity="0.2"
                  />
                  <path
                    d="M5 4H15V8H19V20H5V4ZM3.9985 2C3.44749 2 3 2.44405 3 2.9918V21.0082C3 21.5447 3.44476 22 3.9934 22H20.0066C20.5551 22 21 21.5489 21 20.9925L20.9997 7L16 2H3.9985ZM10.4999 7.5C10.4999 9.07749 10.0442 10.9373 9.27493 12.6534C8.50287 14.3757 7.46143 15.8502 6.37524 16.7191L7.55464 18.3321C10.4821 16.3804 13.7233 15.0421 16.8585 15.49L17.3162 13.5513C14.6435 12.6604 12.4999 9.98994 12.4999 7.5H10.4999ZM11.0999 13.4716C11.3673 12.8752 11.6042 12.2563 11.8037 11.6285C12.2753 12.3531 12.8553 13.0182 13.5101 13.5953C12.5283 13.7711 11.5665 14.0596 10.6352 14.4276C10.7999 14.1143 10.9551 13.7948 11.0999 13.4716Z"
                    fill="black"
                    fill-opacity="0.2"
                  />
                  <path
                    d="M5 4H15V8H19V20H5V4ZM3.9985 2C3.44749 2 3 2.44405 3 2.9918V21.0082C3 21.5447 3.44476 22 3.9934 22H20.0066C20.5551 22 21 21.5489 21 20.9925L20.9997 7L16 2H3.9985ZM10.4999 7.5C10.4999 9.07749 10.0442 10.9373 9.27493 12.6534C8.50287 14.3757 7.46143 15.8502 6.37524 16.7191L7.55464 18.3321C10.4821 16.3804 13.7233 15.0421 16.8585 15.49L17.3162 13.5513C14.6435 12.6604 12.4999 9.98994 12.4999 7.5H10.4999ZM11.0999 13.4716C11.3673 12.8752 11.6042 12.2563 11.8037 11.6285C12.2753 12.3531 12.8553 13.0182 13.5101 13.5953C12.5283 13.7711 11.5665 14.0596 10.6352 14.4276C10.7999 14.1143 10.9551 13.7948 11.0999 13.4716Z"
                    fill="black"
                    fill-opacity="0.2"
                  />
                  <path
                    d="M5 4H15V8H19V20H5V4ZM3.9985 2C3.44749 2 3 2.44405 3 2.9918V21.0082C3 21.5447 3.44476 22 3.9934 22H20.0066C20.5551 22 21 21.5489 21 20.9925L20.9997 7L16 2H3.9985ZM10.4999 7.5C10.4999 9.07749 10.0442 10.9373 9.27493 12.6534C8.50287 14.3757 7.46143 15.8502 6.37524 16.7191L7.55464 18.3321C10.4821 16.3804 13.7233 15.0421 16.8585 15.49L17.3162 13.5513C14.6435 12.6604 12.4999 9.98994 12.4999 7.5H10.4999ZM11.0999 13.4716C11.3673 12.8752 11.6042 12.2563 11.8037 11.6285C12.2753 12.3531 12.8553 13.0182 13.5101 13.5953C12.5283 13.7711 11.5665 14.0596 10.6352 14.4276C10.7999 14.1143 10.9551 13.7948 11.0999 13.4716Z"
                    fill="black"
                    fill-opacity="0.2"
                  />
                </svg>
                <p id="filename">{shipping_details.SHIPMENT_DOC}</p>
              </div>
              <a
                href={
                  process.env.REACT_APP_API_URL +
                  "media/" +
                  shipping_details.SHIPMENT_DOC
                }
                target="_blank"
                id="view_cons_file"
                style={{ width: 98 }}
              >
                <h2 id="view">View</h2>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M16.0052 9.41421L7.39858 18.0208L5.98438 16.6066L14.591 8H7.00519V6H18.0052V17H16.0052V9.41421Z"
                    fill="#635BFF"
                  />
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
