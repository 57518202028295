import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { makeApiCall } from "../../StaticComponents/API";
import default_supp from "../../assets/default_supp.png";
import TimestampToDate from "../../StaticComponents/DateFormat";

const CustomerInfo = () => {
  let [searchParams] = useSearchParams();
  let token = localStorage.getItem("token");
  const CLIENT_CUST_ID = localStorage.getItem("CLIENT_CUST_ID");
  const customer_name = localStorage.getItem("CUST_NAME")
  const [custInfo, setCustInfo] = useState({
    info: "",
    totalProducts: "",
    openOrders: "",
    outstandingPayments: "",
    transaction: "",
  });
  useEffect(() => {
    const payload = {
      CLIENT_CUST_ID: CLIENT_CUST_ID,
    };
    makeApiCall({
      mod: "Customer",
      action: "get-customer-detail",
      payload: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((res) => {
      console.log(res, "cust-info");
      setCustInfo({
        info: res.XscData.INFO,
        openOrders: res.XscData.ORDER_AND_PAYMENT_DETAILS.TOTAL_OPEN_ORDERS,
        outstandingPayments:
          res.XscData.ORDER_AND_PAYMENT_DETAILS.OUTSTANDING_PAYMENT_AMOUNT !=
            null &&
          res.XscData.ORDER_AND_PAYMENT_DETAILS.OUTSTANDING_PAYMENT_AMOUNT !== 0
            ? `¥${res.XscData.ORDER_AND_PAYMENT_DETAILS.OUTSTANDING_PAYMENT_AMOUNT}`
            : res.XscData.ORDER_AND_PAYMENT_DETAILS.OUTSTANDING_PAYMENT_AMOUNT,

        transaction:
          res.XscData.ORDER_AND_PAYMENT_DETAILS.TOTAL_TRANSACTION_AMOUNT !=
            null &&
          res.XscData.ORDER_AND_PAYMENT_DETAILS.TOTAL_TRANSACTION_AMOUNT !== 0
            ? `¥${res.XscData.ORDER_AND_PAYMENT_DETAILS.TOTAL_TRANSACTION_AMOUNT}`
            : res.XscData.ORDER_AND_PAYMENT_DETAILS.TOTAL_TRANSACTION_AMOUNT,
      });
    });
  }, []);
  const timestamp = custInfo.info.CREATED_ON;
  // const TimestampToDate = ({ timestamp }) => {
  //   const date = new Date(custInfo.info.CREATED_ON * 1000);
  //   const day = date.getDate();
  //   const month = date.toLocaleString("en-US", { month: "short" }); // Full month name
  //   const year = date.getFullYear();

  //   // Format the date as '19-July-2024'
  //   const formattedDate = `${day}-${month}-${year}`;
  //   // const formattedTime = date.toLocaleTimeString();

  //   return (
  //     <div>
  //       <p>{formattedDate}</p>
  //     </div>
  //   );
  // };

  const API_URL = process.env.REACT_APP_API_URL;

  const handleImageErr = (e) => {
    e.target.src = default_supp;
  };

  return (
    <div id="supp_info_container">
      {/* <SupplierHeader /> */}
      <div className="container">
        <div id="supp_info_main_container">
          <div id="supp_info_content">
            <div id="supp_info_details" align="left">
              <div id="supp_info_head">Customer Info</div>

              <div id="supp_logo">
                <img
                  src={API_URL + `media/${custInfo.info.LOGO}`}
                  onError={handleImageErr}
                />
              </div>
              <div id="supp_details_infos">
                <div id="supp_details_info">
                  <div id="supp_info_label">Customer Name</div>
                  <div id="supp_info_value">
                  {custInfo.info.CUST_NAME === null
                      ? "N/A"
                      : custInfo.info.CUST_NAME}
                  </div>
                </div>

                <div id="supp_details_info">
                  <div id="supp_info_label">Email Address</div>
                  <div id="supp_info_value">
                    {custInfo.info.EMAIL ? custInfo.info.EMAIL : "N/A"}
                  </div>
                </div>
                <div id="supp_details_info">
                  <div id="supp_info_label">Phone Number</div>

                  {/* +{custInfo.info.COUNTRY_ID}  */}
                  {custInfo.info.PHONE_NUMBER
                    ? custInfo.info.PHONE_NUMBER
                    : "N/A"}
                </div>

                <div id="supp_details_info">
                  <div id="supp_info_label">Website</div>
                  <div id="supp_info_value">
                    {custInfo.info.WEBSITE ? custInfo.info.WEBSITE : "N/A"}
                  </div>
                </div>
                <div id="supp_details_info">
                  <div id="supp_info_label">Registration Number</div>
                  <div id="supp_info_value">
                    {custInfo.info.REG_NUMBER
                      ? custInfo.info.REG_NUMBER
                      : "N/A"}
                  </div>
                </div>

                <div id="supp_details_info">
                  <div id="supp_info_label">Address</div>
                  <div id="supp_info_value">
                  {custInfo.info.ADDR_1 ? custInfo.info.ADDR_1 : "N/A"}
                  </div>
                </div>
                <div id="supp_details_info">
                  <div id="supp_info_label">Country</div>
                  <div id="supp_info_value">
                  {custInfo.info.COUNTRY ? custInfo.info.COUNTRY : "N/A"}
                  </div>
                </div>
                <div id="supp_details_info">
                  <div id="supp_info_label">Province/State</div>
                  <div id="supp_info_value">
                  {custInfo.info.STATE ? custInfo.info.STATE : "N/A"}
                  </div>
                </div>
                <div id="supp_details_info">
                  <div id="supp_info_label">City</div>
                  <div id="supp_info_value">
                  {custInfo.info.CITY ? custInfo.info.CITY : "N/A"}
                  </div>
                </div>
                <div id="supp_details_info">
                  <div id="supp_info_label">Zip/Postal</div>
                  <div id="supp_info_value">
                  {custInfo.info.POSTAL_CODE ? custInfo.info.POSTAL_CODE : "N/A"}
                  </div>
                </div>
                <div id="supp_details_info">
                  <div id="supp_info_label">TAX No</div>
                  <div id="supp_info_value">
                      {custInfo.info.TAX_NUMBER ? custInfo.info.TAX_NUMBER : "N/A"}
                  </div>
                </div>
                <div id="supp_details_info">
                  <div id="supp_info_label">FAX No</div>
                  <div id="supp_info_value">
                  {custInfo.info.FAX ? custInfo.info.FAX : "N/A"}
                  </div>
                </div>

                <div id="supp_details_info" style={{ marginBottom: "32px" }}>
                  <div id="supp_info_label">Created-On</div>
                  <div id="supp_info_value">
                    {" "}
                    <TimestampToDate timestamp={timestamp} />
                  </div>
                </div>
              </div>
            </div>
            <div id="supp_info_summary" align="left">
              <div id="supp_info_inner_div" align="left">
                <div id="supp_inner_left">
                  <div id="supp_inner_left_line"></div>
                  <div id="supp_inner_left_text">
                    <div id="inner_text_num">
                      {custInfo.openOrders == null ? 0 : custInfo.openOrders}
                    </div>
                    <div id="inner_text_text">Total Open Orders</div>
                  </div>
                </div>

                <div id="supp_inner_left">
                  <div id="supp_inner_left_line"></div>
                  <div id="supp_inner_left_text">
                    <div id="inner_text_num">
                      {custInfo.transaction == null ? 0 : custInfo.transaction}
                    </div>
                    <div id="inner_text_text">Total Transaction</div>
                  </div>
                </div>
              </div>

              <div id="supp_info_inner_div" align="left" style={{ gap: 53 }}>
                <div id="supp_inner_left">
                  <div id="supp_inner_left_line"></div>
                  <div id="supp_inner_left_text">
                    <div id="inner_text_num">
                      {custInfo.outstandingPayments === null
                        ? 0
                        : custInfo.outstandingPayments}
                    </div>
                    <div id="inner_text_text">Outstanding Payments</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerInfo;
