import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import container_img from "../../assets/container_img.png";
import { useSearchParams } from "react-router-dom";
import { makeApiCall } from "../../StaticComponents/API";


function CartonDetail(props) {
  // Define options arrays
const lengthOptions = [
  { value: "MTR", label: "m" },
  { value: "CM", label: "cm" },
  { value: "IN", label: "in" },
  { value: "FEET", label: "feet" },
  { value: "MM", label: "mm" },
];

const weightOptions = [
  { value: "GM", label: "g" },
  { value: "KG", label: "Kg" },
  { value: "LBS", label: "lbs" },
];

const volumeOptions = [
  { value: "CB_CM", label: "cm³" },
  { value: "CB_MTR", label: "m³" },
  { value: "CB_FEET", label: "feet³" },
  { value: "CB_MM", label: "mm³" },
  { value: "CB_IN", label: "in³" },
];

// Utility function to get label from value
const getLabelFromValue = (value, options) => {
  const option = options.find(option => option.value === value);
  return option ? option.label : "NA";
};

  console.log(props.id, "id");
  const ID = props .id;
  const [cartoonInfo, setCartoonInfo] = React.useState({
    xscdata: "",
  });

  let APP_CLIENT_ID = localStorage.getItem("CLIENT_ID");

  let token = localStorage.getItem("token");

  const getData = () => {
    const payload = {
      CTN_ID: ID,
      APP_CLIENT_ID: APP_CLIENT_ID,
    };

    makeApiCall({
      mod: "Shipping",
      action: "get-ctn-detail",
      payload: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((res) => {
      console.log(res, "cartoon-info");
      setCartoonInfo({
        xscdata: res.XscData,
      });
    });
  };

  useEffect(() => {
    if (props.show === true) {
      getData();
    }
  }, [props.show]);

  const onImageError = (e) => {
    e.target.style.display = 'none'; // Hide image if error occurs
  };
  
  const cartonIconUrl = process.env.REACT_APP_API_URL + `/media/${cartoonInfo.xscdata.CTN_ICON}`;

  return (
    <div>
      <div>
        <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title
              id="contained-modal-title-vcenter"
              className="filter_text"
            >
              Carton Detail
            </Modal.Title>
          </Modal.Header>

          <div id="container_data">
          {cartoonInfo.xscdata.CTN_ICON && (
            <div>
              <img
                src={cartonIconUrl}
                alt="Carton Icon"
                onError={onImageError}
              />
            </div>
          )}
            <div>
              <div id="container_data_title">
                Alias:{" "}
                <span id="container_data_content">
                  {cartoonInfo.xscdata.ALIAS_NAME}
                </span>
              </div>
            </div>
            <div>
              <div id="container_data_title">
                Description:{" "}
                <span id="container_data_content">
                  {cartoonInfo.xscdata.DESCRIPTION}
                </span>
              </div>
            </div>
            <div>
              <div id="container_data_title">
                Length:{" "}
                <span id="container_data_content">
                  {" "}
                  {cartoonInfo.xscdata.LENGTH}  {getLabelFromValue(cartoonInfo.xscdata.SIZE_UNIT, lengthOptions)}
                </span>
              </div>
            </div>
            <div>
              <div id="container_data_title">
                Width:{" "}
                <span id="container_data_content">
                  {" "}
                  {cartoonInfo.xscdata.WIDTH} {getLabelFromValue(cartoonInfo.xscdata.SIZE_UNIT, lengthOptions)}
                </span>
              </div>
            </div>
            <div>
              <div id="container_data_title">
                Height:{" "}
                <span id="container_data_content">
                  {" "}
                  {cartoonInfo.xscdata.HEIGHT}  {getLabelFromValue(cartoonInfo.xscdata.SIZE_UNIT, lengthOptions)}
                </span>
              </div>
            </div>
            <div>
              <div id="container_data_title">
                Capacity:{" "}
                <span id="container_data_content">
                  {cartoonInfo.xscdata.VOLUME}  {getLabelFromValue(cartoonInfo.xscdata.VOLUME_UNIT, volumeOptions)}
                </span>
              </div>
            </div>
            <div>
              <div id="container_data_title">
                Max Cargo Weight:{" "}
                <span id="container_data_content">
                  {cartoonInfo.xscdata.MAX_WEIGHT_CAPACITY}{" "}
                  {getLabelFromValue(cartoonInfo.xscdata.MAX_WEIGHT_CAPACITY_UNIT, weightOptions)}
                </span>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
}
export default CartonDetail;
