import React, { useState, useCallback, useEffect } from "react";
import logo from "../../../assets/Manex Logo.png";
import cross from "../../../assets/Cross.png";
import { DataGrid } from "@mui/x-data-grid";
import po_img from "../../../assets/po_img.png";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { makeStyles } from "@material-ui/styles";
import "../../../Styles/CreatePI.css";
import CustomerPurchaseHistory from "./CustomerPurchaseHistory";
import TextInputWithAll from "../../Inputs/TextInputWithAll";
import DropDownInput from "../../Inputs/DropDownInput";
import { alignItemsEndIcon } from "@progress/kendo-svg-icons";
import { makeApiCall } from "../../../StaticComponents/API";
import debounce from "lodash/debounce";
import { useNavigate, useSearchParams } from "react-router-dom";
import TextInputWithAllWithCross from "../../Inputs/TextInputWithAllWithCross";
import num2words from "num2words";
import PreviewInvoice from "./PreviewInvoice"
import { toast ,ToastContainer } from "react-toastify";

const useStyles = makeStyles({
  subheadingRow: {
    textAlign: "center",
    fontWeight: "bold",
    minHeight: "32px !important",
    maxHeight: "32px !important",
    "& .MuiDataGrid-cell": {
      border: "none !important",
      lineHeight: "32px !important",
      minHeight: "32px !important",
      maxHeight: "32px !important",
    },
  },

  subheadingCell: {
    width: "100%",
    border: "0.5px solid var(--outlineVariant) !important",
    lineHeight: "32px !important",
    minHeight: "32px !important",
    maxHeight: "32px !important",
  },

  padding: { padding: "0px !important" },
});

export default function CreateInvoice() {
  const [startDate, setStartDate] = useState(new Date());
  const [historyShow, setHistoryShow] = useState(false);
  const [poNumber, setPoNumber] = useState("N/A");
  const [invoiceNo, setInvoiceNO] = useState("N/A");
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [suggestions, setSuggestions] = useState([]);

  const [deliveryDate, setdeliveryDate] = useState("");
  const [shippingDate, setshippingDate] = useState("");
  const [vessel_Name, setvessel_Name] = useState("");
  const [paymentType, setpaymentType] = useState("");
  const [containerNumber, setContainerName] = useState("");
  const [sealNumber, setSealNumber] = useState("");
  const [previewShow, setpreviewShow] = useState(false);
  const [page, setpage] = useState(0);

  const [searchParams] = useSearchParams();

  let token = localStorage.getItem("token");

  const APP_CLIENT_ID = localStorage.getItem("CLIENT_ID");
  const CLIENT_CUST_ID = localStorage.getItem("CLIENT_CUST_ID");

  const PaymentOptions = [
    { value: "1", label: "T/T" },
    { value: "2", label: "T/T" },
  ];
  const handlePaymentChange = (e) => {
    const selectedValue = e.target.value === "" ? null : e.target.value;
    setpaymentType(selectedValue);
  };
  const classes = useStyles();

  const columns = [
    {
      field: "MNX_ID",
      headerName: "Mnx. Item ID",
      width: 200,
    },
    {
      field: "CUST_ITEM_ID",
      headerName: "Cust. Item ID",
      width: 200,
    },
    {
      field: "DESCRIPTION",
      headerName: "Description Goods",
      width: 275,
    },
    {
      field: "TOTAL_QTY",
      headerName: "TTL QTY",
      width: 150,
    },
    {
      field: "PRICE",
      headerName: "RMB",
      width: 150,
    },
    {
      field: "RMB",
      headerName: "Total RMB",
      width: 130,
      cellClassName: "po_input",
    },
  ];

  const initialRows = [];
  const [rows, setRows] = useState(initialRows);

  const rowsWithIcons = rows.map((row) => ({
    ...row,
  }));

  const [createCusInvoice, setCreateCusInvoice] = React.useState({
    customerData: "",
    clientData: "",
  });
  const [invoiceDetails, setInvoiceDetails] = React.useState({
    invoiceDetailsSet: "",
  });
  const handlePreview = () => {
    setpreviewShow(true);
  };

  const handleexit = () => {
    setpreviewShow(false);
  };


  const getData = () => {
    const payload = {
      CLIENT_CUST_ID: CLIENT_CUST_ID,
    };

    makeApiCall({
      mod: "Customer",
      action: "get_client_cust_details",
      payload: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((res) => {
      console.log(res, "customer-details");
      setCreateCusInvoice({
        customerData: res.XscData.CLIENT_DATA,
        clientData: res.XscData.CUST_DATA,
      });
    });
  };

  useEffect(() => {
    getData();
  }, []);

  const resetInvoiceData = () => {
    setInvoiceNumber(" ");
    setPoNumber("N/A");
    setInvoiceNO("N/A");
    setRows([]);
    setInvoiceDetails({
      invoiceDetailsSet: "",
    });
  };

  const getDataForAmount = (CUST_PI_ID) => {
    if (!CUST_PI_ID) {
      resetInvoiceData();
      return;
    }

    console.log("Passing selectedInvoiceId:", CUST_PI_ID);

    const payload = {
      CLIENT_CUST_ID: CLIENT_CUST_ID,
      CUST_PI_ID: CUST_PI_ID,
    };

    makeApiCall({
      mod: "CustomerInvoice",
      action: "get_cust_invoice_details",
      payload: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((res) => {
      const data = res.XscData.PRODUCT_DETAILS;
      console.log(payload,"payload to get invoice details");
      console.log(res);
      if (Array.isArray(data)) {
        const mappedRows = data.map((po, index) => ({
          id: index,
          MNX_ID:po.MANEX_PROD_NO,
          CUST_ITEM_ID: po.CUST_PROD_NO,
          DESCRIPTION: po.DESCRIPTION,
          TOTAL_QTY: po.TOTAL_QTY,
          PRICE: po.TOTAL_RMB,
          RMB: po.RMB,
        }));
        setInvoiceDetails({
          invoiceDetailsSet: res.XscData.INVOICE_DETAILS,
        });
        setRows(mappedRows);
      } else {
        console.log(res, "Customer Invoice details");
      }
    });
  };

  useEffect(() => {
    getDataForAmount();
  }, []);

  const searchInvoiceNo = useCallback(
    debounce(async (value) => {
      if (value.length < 1) {
        setSuggestions([]);
        return;
      }

      try {
        const payload = {
          SEARCH_KEYWORD: "",
          CLIENT_CUST_ID: CLIENT_CUST_ID,
        };
        const res = await makeApiCall({
          mod: "CustomerInvoice",
          action: "list_invoice_num",
          payload: payload,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const invoices = res.XscData?.PI_LIST || [];
        console.log(res, "invoice list");
        setSuggestions(invoices);

        if (invoices.length > 0) {
          const firstInvoice = invoices;
          console.log("Selected Invoice ID:", firstInvoice.CUST_PI_ID);

          setPoNumber(firstInvoice.CUST_PO_NUM || "N/A");
          setInvoiceNO(firstInvoice.CUST_PI_NUM || "N/A");

          setData((prevData) => ({
            ...prevData,
            CUST_PI_ID: firstInvoice.CUST_PI_ID,
            CUST_INV_NUM: firstInvoice.CUST_PI_NUM,
          }));
        } else {
          setPoNumber("N/A");
          setInvoiceNO("N/A");
        }
      } catch (error) {
        console.error("Error fetching suggestions:", error);
        setSuggestions([]);
        setPoNumber("N/A");
        setInvoiceNO("N/A");
      }
    }, 300),
    [APP_CLIENT_ID, CLIENT_CUST_ID, token]
  );

  const handleInvoiceNumberChange = (event) => {
    const value = event.target.value;
    setInvoiceNumber(value);
    if (value === "") {
      resetInvoiceData();
    } else {
      getDataForAmount("");
      searchInvoiceNo(value);
    }
  };

  const convertAmountToText = (amount) => {
    if (typeof amount !== "number") {
      return "AMOUNT NOT AVAILABLE";
    }

    const [whole, fraction] = amount.toFixed(2).split("."); // Split into dollars and cents
    const wholeText = num2words(whole).toUpperCase(); // Convert whole number to text
    const fractionText = num2words(fraction).toUpperCase(); // Convert cents to text

    return `${wholeText} DOLLARS AND ${fractionText} CENTS`;
  };

  const TypeOptions = [
    { value: "CASH", label: "CASH" },
    { value: "ONLINE", label: "ONLINE" },
    { value: "CARD", label: "CARD" },
    { value: "T/T", label: "T/T" },
    { value: "OTHERS", label: "OTHERS" },
  ];

  const createInvoiceValues = {
    CUST_PI_ID: "",
    APP_CLIENT_ID: localStorage.getItem("CLIENT_ID"),
    CUST_INV_NUM: "",
    DELIVERY_DATE: deliveryDate,
    SHIPPING_DATE: shippingDate,
    VESSEL_NAME: vessel_Name,
    CONTAINER_NUM: containerNumber,
    PAYMENT_TYPE: paymentType,
    SEAL_NUM: sealNumber,
  };

  const [data, setData] = useState(createInvoiceValues);

  const payload = {
    APP_CLIENT_ID: localStorage.getItem("CLIENT_ID"),
    CUST_PI_ID: data.CUST_PI_ID,
    CUST_INV_NUM: data.CUST_INV_NUM,
    DELIVERY_DATE: deliveryDate,
    SHIPPING_DATE: shippingDate,
    VESSEL_NAME: vessel_Name,
    CONTAINER_NUM: containerNumber,
    PAYMENT_TYPE: paymentType,
    SEAL_NUM: sealNumber,
  };

  console.log("Payload", payload);
  let navigate = useNavigate();

 const handleSubmit = () => {
  if (!payload.DELIVERY_DATE) {
    toast.error("Delivery Date is required");
    return;
  }
  if (!payload.SHIPPING_DATE) {
    toast.error("Sailing Date is required");
    return;
  }
  if (!payload.VESSEL_NAME) {
    toast.error("Vessel Name is required");
    return;
  }
  if (!payload.CONTAINER_NUM) {
    toast.error("Container Number is required");
    return;
  }
  if (!payload.PAYMENT_TYPE) {
    toast.error("Payment Type is required");
    return;
  }
  if (!payload.SEAL_NUM) {
    toast.error("Seal Number is required");
    return;
  }



    makeApiCall({
      mod: "CustomerInvoice",
      action: "create_cust_invoice",
      payload: payload,
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
     if(res.XscStatus==1){
      res.XscStatus !== 0
      ? toast.success(res.XscMessage) &&
        setTimeout(()=>{navigate(`/customers/view-invoice?id=${res.XscData.CUST_INV_ID}`)},500)
      : toast.error(res.XscMessage);
     }else{
      toast.error(res.XscMessage,{
        position:"bottom-center"
      })
     }

        
      })
      .catch((error) => {
        console.log(error);
      });
  };


  function convertToUnixTimestamp(dateString) {
    const date = new Date(dateString);
    const unixTimestamp = Math.floor(date.getTime() / 1000);
    return unixTimestamp;
  }

  const payloadTOpreview = {
    APP_CLIENT_ID: localStorage.getItem("CLIENT_ID"),
    CUST_PI_ID: data.CUST_PI_ID,
    CUST_INV_NUM: data.CUST_INV_NUM,
    DELIVERY_DATE: deliveryDate,
    SHIPPING_DATE: shippingDate,
    VESSEL_NAME: vessel_Name,
    CONTAINER_NUM: containerNumber,
    PAYMENT_TYPE: paymentType,
    SEAL_NUM: sealNumber,
  }

  
  return (
    <div>
      <div id="suppliers_topbar_bg" className="">
        <div className="container d-flex justify-content-between align-items-center">
          <div
            id="dashboard_sub_header"
            className="d-flex align-items-center"
            style={{ gap: "16px" }}
          >
            <a href="/customers/customer-info" className="text-decoration-none">
              {" "}
              <span id="cancel_po_button">
                <svg
                  xmlns="httpwww.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M10.5859 12.0001L2.79297 4.20718L4.20718 2.79297L12.0001 10.5858L19.793 2.79297L21.2072 4.20718L13.4143 12.0001L21.2072 19.7929L19.793 21.2072L12.0001 13.4143L4.20718 21.2072L2.79297 19.7929L10.5859 12.0001Z"
                    fill="var(--onSurface)"
                  />
                  <path
                    d="M10.5859 12.0001L2.79297 4.20718L4.20718 2.79297L12.0001 10.5858L19.793 2.79297L21.2072 4.20718L13.4143 12.0001L21.2072 19.7929L19.793 21.2072L12.0001 13.4143L4.20718 21.2072L2.79297 19.7929L10.5859 12.0001Z"
                    fill="black"
                    fill-opacity="0.2"
                  />
                  <path
                    d="M10.5859 12.0001L2.79297 4.20718L4.20718 2.79297L12.0001 10.5858L19.793 2.79297L21.2072 4.20718L13.4143 12.0001L21.2072 19.7929L19.793 21.2072L12.0001 13.4143L4.20718 21.2072L2.79297 19.7929L10.5859 12.0001Z"
                    fill="black"
                    fill-opacity="0.2"
                  />
                  <path
                    d="M10.5859 12.0001L2.79297 4.20718L4.20718 2.79297L12.0001 10.5858L19.793 2.79297L21.2072 4.20718L13.4143 12.0001L21.2072 19.7929L19.793 21.2072L12.0001 13.4143L4.20718 21.2072L2.79297 19.7929L10.5859 12.0001Z"
                    fill="black"
                    fill-opacity="0.2"
                  />
                  <path
                    d="M10.5859 12.0001L2.79297 4.20718L4.20718 2.79297L12.0001 10.5858L19.793 2.79297L21.2072 4.20718L13.4143 12.0001L21.2072 19.7929L19.793 21.2072L12.0001 13.4143L4.20718 21.2072L2.79297 19.7929L10.5859 12.0001Z"
                    fill="black"
                    fill-opacity="0.2"
                  />
                </svg>
              </span>{" "}
            </a>
            Create Invoice
          </div>
        </div>
      </div>
      <div id="create_po_bg">
        <div className="container">
          <div className="ci_po_first">
            <div>Invoice Number</div>
          </div>
          <div id="ci_po_input">
            <label className="ci_po_label">Enter Invoice Number</label>
            <TextInputWithAll
              id="ci_po_input_field"
              type="text"
              placeholder="Enter Invoice Number"
              value={invoiceNumber.CUST_PI_NUM}
              onChange={handleInvoiceNumberChange}
            />

            
            <PreviewInvoice
              show={previewShow}
              onHide={handleexit}
              rows={rows}
              payload={payloadTOpreview}
              // file={filetoUpload || "file.txt"}
            />
            <div className="ci_po_suggestion">
              {suggestions.length > 0 && (
                <div className="suggestions-dropdown">
                  {suggestions.map((suggestion) => (
                    <div
                      key={suggestion.CUST_PI_NUM}
                      className="suggestion-item"
                      onClick={() => {
                        setInvoiceNumber(suggestion);
                        setPoNumber(suggestion.CUST_PO_NUM);
                        setInvoiceNO(suggestion.CUST_PI_NUM);
                        setData((prevData) => ({
                          ...prevData,
                          CUST_PI_ID: suggestion.CUST_PI_ID,
                          CUST_INV_NUM: suggestion.CUST_PI_NUM,
                        }));
                        getDataForAmount(suggestion.CUST_PI_ID);
                        setSuggestions([]);
                      }}
                    >
                      {suggestion.CUST_PI_NUM}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
          <div id="po_logo" align="center">
            <img src={logo} alt="logo" />
            <div id="po_header_text">MANEX EXPORTS (H.K.) LTD.</div>
          </div>
          <div className="row" id="about_po_header">
            <div className="col-6">
              <div id="po_supplier_text">Supplier</div>
              <div id="po_supplier_name">
                {createCusInvoice.customerData.NAME}
              </div>
              <div id="po_supplier_address">
                {createCusInvoice.customerData.ADDR_1} <br />
                {createCusInvoice.customerData.ADDR_2}
              </div>
              <div id="po_supplier_ph">
                TEL:{" "}
                <span id="po_supplier_no">
                  {createCusInvoice.customerData.CONTACT_NUMBER}
                </span>
              </div>
              <div id="po_supplier_ph">
                FAX:{" "}
                <span id="po_supplier_no">
                  {" "}
                  {createCusInvoice.customerData.FAX}
                </span>
              </div>
              <div id="po_supplier_ph">
                Invoice No: <span id="po_supplier_no">{invoiceNo}</span>
              </div>
              <div id="po_supplier_ph">
                Date:
                <DatePicker
                  id="cust_date_div"
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  dateFormat="dd-MMMM-yyyy"
                />
              </div>
            </div>
            <div className="col-5 " id="about_po_left_bg" align="end">
              <div id="content_wrapper">
                {" "}
                <div id="po_supplier_text">Client</div>
                <div id="po_supplier_name">
                  {createCusInvoice.clientData.NAME}
                </div>
                <div id="po_supplier_address">
                  {createCusInvoice.clientData.ADDR_1} <br />
                  {createCusInvoice.clientData.ADDR_2}
                </div>
                <div id="po_supplier_ph">
                  TEL:{" "}
                  <span id="po_supplier_no">
                    {" "}
                    {createCusInvoice.clientData.CONTACT_NUMBER}
                  </span>
                </div>
                <div id="po_supplier_ph">
                  FAX:{" "}
                  <span id="po_supplier_no">
                    {" "}
                    {createCusInvoice.clientData.FAX}
                  </span>
                </div>
                <div id="po_supplier_ph">
                  Customer PO No: <span id="po_supplier_no">{poNumber}</span>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              maxHeight: "fit-content",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <DataGrid
              rows={rows}
              columns={columns}
              page={page}
              hideFooterPagination={true}
              hideFooter={true}
              autoHeight
              rowHeight={80}
              showCellRightBorder
            />
          </div>
          <div
            style={{
              borderBottom: "0.5px solid var(--outlineVariant)",
            }}
          >
            <div
              className="d-flex flex-column"
              style={{
                gap: 24,
                borderBottom: "0.5px solid var(--outlineVariant)",
                marginLeft: 500,
                marginTop: 100,
              }}
            >
              <div className="d-flex flex-row" style={{ gap: 151 }}>
                <h2 id="total_cost_text">
                  Total Cost in{" "}
                  {invoiceDetails?.invoiceDetailsSet?.OPERATING_CURRENCY ||
                    "RMB"}
                </h2>
                <p id="total_cost">
                  {invoiceDetails?.invoiceDetailsSet
                    ?.OPERATING_CURRENCY_SYMBOL || ""}
                  {invoiceDetails?.invoiceDetailsSet?.TOTAL_GOODS_AMOUNT
                    ? invoiceDetails.invoiceDetailsSet.TOTAL_GOODS_AMOUNT
                    : "N/A"}
                </p>
              </div>
              <div className="d-flex flex-row" style={{ gap: 151 }}>
                <h2 id="total_cost_text">
                  Total Cost in{""}{" "}
                  {invoiceDetails?.invoiceDetailsSet?.INVOICE_CURRENCY || "USD"}
                  *
                  {invoiceDetails?.invoiceDetailsSet?.INVOICE_CURRENCY_RATE ||
                    0.14}
                </h2>
                <p id="total_cost">
                  {invoiceDetails?.invoiceDetailsSet?.INVOICE_CURRENCY_SYMBOL
                    ? invoiceDetails.invoiceDetailsSet.INVOICE_CURRENCY_SYMBOL
                    : ""}

                  {invoiceDetails?.invoiceDetailsSet?.TOTAL_GOODS_AMOUNT &&
                  invoiceDetails?.invoiceDetailsSet?.INVOICE_CURRENCY_RATE
                    ? (
                        invoiceDetails.invoiceDetailsSet.TOTAL_GOODS_AMOUNT *
                        invoiceDetails.invoiceDetailsSet.INVOICE_CURRENCY_RATE
                      ).toFixed(2)
                    : "N/A"}
                </p>
              </div>

              <div className="d-flex flex-row" style={{ gap: 151 }}>
                <h2 id="total_cost_text">Agent Commission</h2>
                <p id="total_cost">
                  {invoiceDetails?.invoiceDetailsSet?.INVOICE_CURRENCY_SYMBOL ||
                    ""}
                  {invoiceDetails?.invoiceDetailsSet
                    ?.AGENTS_COMMISION_PERCENTAGE
                    ? invoiceDetails.invoiceDetailsSet
                        .AGENTS_COMMISION_PERCENTAGE
                    : "N/A"}
                </p>
              </div>
              <div
                className="d-flex flex-row"
                style={{ gap: 119, marginBottom: 28 }}
              >
                <h2 id="total_cost_text" style={{ width: 264 }}>
                  Inland Transportation: 1 X 40FTHQ
                </h2>
                <p id="total_cost">
                  {invoiceDetails?.invoiceDetailsSet?.INVOICE_CURRENCY_SYMBOL ||
                    ""}
                  {invoiceDetails?.invoiceDetailsSet?.INLAND_TRANSPORTATION ||
                    "N/A"}
                </p>
              </div>
            </div>

            <div
              className="d-flex flex-column"
              style={{
                gap: 20,
                margin: "20px 72px 40px 500px",
              }}
            >
              <div className="d-flex flex-row" style={{ gap: 151 }}>
                <h2 id="total_cost_text">Total Value</h2>
                <h2 id="total_cost" style={{ fontWeight: 600 }}>
                  {invoiceDetails?.invoiceDetailsSet?.INVOICE_CURRENCY_SYMBOL ||
                    ""}
                  {invoiceDetails?.invoiceDetailsSet?.TOTAL_INV_AMOUNT || "N/A"}
                </h2>
              </div>
              <div
                className="d-flex flex-row align-items-center"
                style={{ gap: 13 }}
              >
                <h2 id="total_cost_text" style={{ width: 424 }}>
                  SAY “{" "}
                  {invoiceDetails?.invoiceDetailsSet?.INVOICE_CURRENCY || ""}{" "}
                  {convertAmountToText(
                    invoiceDetails.invoiceDetailsSet.TOTAL_INV_AMOUNT
                  )}
                  ” ONLY
                </h2>
              </div>
            </div>
          </div>

          <div
            className="d-flex flex-column gap-4"
            style={{
              marginTop: 44,
            }}
          >
            <div className="d-flex flex-row align-items-center gap-2">
              <div
                style={{
                  width: 8,
                  height: 44,
                  background: "var(--tertiaryContainer, #BA3CBB)",
                }}
              ></div>
              <h2 id="shipping_details">Delivery Info</h2>
            </div>
            <div
              className="d-flex flex-row "
              style={{
                gap: "64px",
              }}
            >
              <div className="d-flex flex-column gap-2">
                <label>Delivery Date</label>
                <TextInputWithAll
                  type="date"
                  placeholder="Enter Delivery Date"
                  //value={e.target.value}
                  onChange={(e) =>
                    setdeliveryDate(convertToUnixTimestamp(e.target.value))
                  }
                />
              </div>
              <div className="d-flex flex-column " style={{ gap: "0px" }}>
                <label>Vessel Name</label>
                <TextInputWithAll
                  type="text"
                  placeholder="Enter Vessel Name"
                  className="date_without_symbol_id"
                  value={vessel_Name}
                  onChange={(e) => setvessel_Name(e.target.value)}
                />
              </div>
            </div>
            <div
              className="d-flex flex-row "
              style={{
                paddingRight: "0px",
                display: "flex",
                flexDirection: rows,
                gap: "64px",
                alignItems: "center",
              }}
            >
              <div className="d-flex flex-column gap-2">
                <label>Sailing Date</label>
                <TextInputWithAll
                  type="date"
                  // value={e.target.value}
                  onChange={(e) =>
                    setshippingDate(convertToUnixTimestamp(e.target.value))
                  }
                  placeholder="Enter Sailing Date"
                />
              </div>
              <div>
                <DropDownInput
                  label="Payment Type"
                  id="select_status"
                  options={TypeOptions}
                  value={paymentType}
                  placeholder="Select Type"
                  onChange={handlePaymentChange}
                />
              </div>
            </div>
            <div
              className="d-flex flex-row "
              style={{
                paddingRight: "0px",
                display: "flex",
                flexDirection: rows,
                gap: "64px",
                alignItems: "center",
                marginBottom: "108px",
              }}
            >
              <div className="d-flex flex-column " style={{ gap: "0px" }}>
                <label>Container No</label>
                <TextInputWithAll
                  type="text"
                  placeholder="Enter Container No"
                  className="date_without_symbol_id"
                  value={containerNumber}
                  onChange={(e) => setContainerName(e.target.value)}
                />
              </div>
              <div className="d-flex flex-column" style={{ gap: "0px" }}>
                <label>Seal No</label>
                <TextInputWithAll
                  type="text"
                  placeholder="Enter Seal No"
                  className="date_without_symbol_id"
                  value={sealNumber}
                  onChange={(e) => setSealNumber(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
        <div id="po_footer" className="pi_footer">
          <div id="add_supplier_buttons" className="container">
            {" "}
            <a href="/customers/customer-info" className="text-decoration-none">
              {" "}
              <div id="cancel">Cancel</div>
            </a>
            <div
              className="d-flex align-items-center justify-content-right "
              style={{ gap: "12px" }}
            >
              <a
                // href="/customers/view-invoice"
                onClick={() => handlePreview()}
                className="text-decoration-none"
                style={{
                  cursor: "pointer",
                }}
              >
                <div id="preview_packaging">
                  Preview Invoice{" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M16.0052 9.41421L7.39858 18.0208L5.98438 16.6066L14.591 8H7.00519V6H18.0052V17H16.0052V9.41421Z"
                      fill="#635BFF"
                    />
                  </svg>
                </div>
              </a>
              <a
              //  href="/customers/customer-info"
                className="text-decoration-none"
              >
                <div
                  id="submitsupplier"
                  type="submit"
                  className="submit"
                  onClick={handleSubmit}
                >
                  Create Invoice
                </div>
              </a>
            </div>
          </div>
        </div>
        <CustomerPurchaseHistory
          show={historyShow}
          onHide={() => setHistoryShow(false)}
        />
      </div>
      <ToastContainer
        position="bottom-center"
        style={{ width: "max-content" }}
      />
    </div>
  );
}
