import React, { useState } from "react";
import "../App.css";
import TextInputWithAll from "../Components/Inputs/TextInputWithAll";

function SearchBar({onSearch,...props}) {
  const [searchTerm, setSearchTerm] = useState("");
  const handleChange = (e) => {
     const value = e.target.value;
     setSearchTerm(value);
     onSearch(value);
     
  };
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent form submission
      onSearch(searchTerm); // Trigger the search with the current term
    }
  }
  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent default form submission behavior
    onSearch(searchTerm); // Trigger the search when the form is submitted
  };
  return (
    <div>
      <form
          onSubmit={handleSubmit}
        className="form-inline"
        // className="w-50"
      >
        <div className="position-relative d-flex" id="search_bg">
          <button
            className="btn  my-sm-0 position-absolute top-50 translate-middle-y"
            id="search_button"
            type="submit"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <path
                d="M12.0203 11.0779L14.8755 13.933L13.9327 14.8758L11.0775 12.0207C10.0509 12.842 8.74901 13.3333 7.33301 13.3333C4.02101 13.3333 1.33301 10.6453 1.33301 7.33334C1.33301 4.02134 4.02101 1.33334 7.33301 1.33334C10.645 1.33334 13.333 4.02134 13.333 7.33334C13.333 8.74934 12.8417 10.0513 12.0203 11.0779ZM10.6828 10.5832C11.498 9.74308 11.9997 8.59708 11.9997 7.33334C11.9997 4.75501 9.91134 2.66668 7.33301 2.66668C4.75467 2.66668 2.66634 4.75501 2.66634 7.33334C2.66634 9.91168 4.75467 12 7.33301 12C8.59674 12 9.74274 11.4983 10.5829 10.6831L10.6828 10.5832Z"
                 fill="var(--onSurface)"
              />
            </svg>
          </button>
          <TextInputWithAll
            //   onChange={(e) => setCertId(e.target.value.trimStart())}
            //   value={certId}
            maxLength={24}
            id="cert_input"
            className="form-control  "
            value={searchTerm}
            onChange={handleChange}
            // type="search"
            required
            onKeyDown={handleKeyDown}
            
            placeholder="Search"
            aria-label="Search"
          />
        </div>
      </form>
    </div>
  );
}

export default SearchBar;
