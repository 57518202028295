import React, { useEffect, useRef, useState } from "react";
import { Dropdown } from "react-bootstrap";
import SearchBar from "../../../StaticComponents/SearchBar";
import Filter from "../../../StaticComponents/Filter";
import { DataGrid } from "@mui/x-data-grid";
import ReceivePayment from "./ReceivePayment";
import InvoiceFilter from "./InvoiceFilter";
import { useNavigate, useSearchParams } from "react-router-dom";
import "../../../App.css";
import { makeApiCall } from "../../../StaticComponents/API";
import PaySupplier from "./PaySupplier";
import nodataimage from "../../../assets/no-data-file.png";

function Invoice() {
  const [modalShow, setModalShow] = useState(false);
  const [optionShow, setOptionShow] = useState(false);
  let [searchParams] = useSearchParams();
  const [pageSize, setPageSize] = React.useState(10); // Default page size
  const ellipsisRef = useRef(null);
  const [invoiceStatus, setInvoiceStatus] = useState("");
  const [show, setShow] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [orderID, setorderID] = useState();
  const [page, setpage] = useState(0);
  const navigate = useNavigate();
  const [selectedRow, setSelectedRow] = useState(null);
  const [invoiceCount, setinvoiceCount] = useState();
  const [invId, setInvID] = useState();
  const [selectedId, setSelectedId] = useState(null);

  //  const[optionPosition,setOptionPosition] = useState()

  const [optionPosition, setOptionPosition] = useState({
    top: 0,
    left: 0,
  });
  const [paymentStatus, setPaymentStatus] = useState("");
  const getInvoiceStatus = (invoiceStatus, paymentStatus) => {
    if (invoiceStatus === null && paymentStatus === 0)
      return "Pending Approval";
    if (invoiceStatus === null && paymentStatus === 1)
      return "Pending Approval";
    if (invoiceStatus === 0) return "Rejected";
    if (invoiceStatus === 1 && paymentStatus === 0) return "Payment Due";
    if (invoiceStatus === 1 && paymentStatus === 1) return "Completed";

    return "Pending Approval"; // Default to "Pending Approval"
  };
  const handlePagesize = (newPageSize) => {
    setPageSize(newPageSize);
    setpage(0);
  };
  const InvoiceTable = ({ invoiceStatus, paymentStatus }) => {};

  const invoiceState = getInvoiceStatus(invoiceStatus, paymentStatus);

  // const [optionShow, setOptionShow] = useState(false);
  const APP_CLIENT_ID = localStorage.getItem("CLIENT_ID");

  let token = localStorage.getItem("token");
  const CLIENT_SUPPLIER_ID = localStorage.getItem("CLIENT_SUPPLIER_ID");
  const handleEllipsisClick = (
    e,
    invoiceStatus,
    paymentStatus,
    orderID,
    orderNo,
    params
  ) => {
    // if (!params.row) return;
    const rect = e.currentTarget.getBoundingClientRect();
    console.log(orderID);
    setOptionPosition({
      top: rect.bottom + window.scrollY,
      left: rect.left + window.scrollX,
    });
    
    setInvID(params.row.id);
    setOptionShow(true);

    const invoiceStatusText = getInvoiceStatus(invoiceStatus, paymentStatus);

    setInvoiceStatus(invoiceStatusText);

    console.log(invoiceStatusText, "inv");
    localStorage.setItem("ORDER_ID", orderID);
    localStorage.setItem("ORDER_NUM", orderNo);
    localStorage.setItem("INVOICE_ID", orderID);
  };

  const handleClickOutside = (event) => {
    if (ellipsisRef.current && !ellipsisRef.current.contains(event.target)) {
      setOptionShow(false);
    }
  };
  const handleSearch = (query) => {
    setSearchQuery(query);
  };

  const handlepagechange = (newpage) => {
    setpage(newpage);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const columns = [
    {
      field: "ORDER_NO",
      headerName: "Order No",
      width: 120,
      cellClassName: "sticky-column",
    },
    {
      field: "TOTAL_ITEM",
      headerName: "Total Item",
      width: 130,
      editable: false,
      align: "left",
      renderCell: (params) => params.value,
    },
    {
      field: "TOTAL_CTNS",
      headerName: "Total CTNS",
      width: 150,
      editable: false,
      align: "left",
      renderCell: (params) => params.value,
    },

    {
      field: "CREATED_ON",
      headerName: "Created On",
      width: 150,
      editable: false,
      align: "left",
    },
    {
      field: "TOTAL_AMOUNT",
      headerName: "Total Amount",
      width: 170,
      editable: false,
      align: "left",
    },
    {
      field: "PENDING_AMOUNT",
      headerName: "Pending Amount",
      width: 190,
      editable: false,
      align: "left",
    },

    {
      field: "PAYMENT_STATUS",
      headerName: "Payment Status",
      width: 200,
      editable: false,
      align: "left",
      renderCell: (params) => {
        const statusValue = PAYMENT_STATUS_MAP[params.value]; // Get the mapped status text
        const className = STATUS_CLASS_MAP[statusValue] || ""; // Get the class based on the status

        return <div className={className}>{statusValue}</div>;
      },
    },

    {
      field: "INVOICE_STATUS",
      headerName: "Invoice Status",
      width: 200,
      editable: false,
      align: "left",
      renderCell: (params) => {
        const invoiceStatus = params.row.INVOICE_STATUS;
        const paymentStatus = params.row.PAYMENT_STATUS;
        const invoiceNum = params.row.INVOICE_NO;

        const invoiceStatusText = getInvoiceStatus(
          invoiceStatus,
          paymentStatus
        );

        const className = STATUS_CLASS_MAP[invoiceStatusText] || "";

        return <div className={className}>{invoiceStatusText}</div>;
      },
    },

    {
      field: "ACTION",
      headerName: "Action",
      width: 150,
      editable: false,
      sortable: false,
      align: "left",
      renderCell: (params) => {
        setSelectedRow(params.row.Id);

        const invoiceStatus = params.row.INVOICE_STATUS;
        const paymentStatus = params.row.PAYMENT_STATUS;
        const orderID = params.row.id;
        const orderNo = params.row.ORDER_NO;
        const invoiceId = params.row.id;
        return (
          <div
            style={{
              cursor: "pointer",
              width: "20px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            onClick={(e) =>
              handleEllipsisClick(
                e,
                invoiceStatus,
                paymentStatus,
                orderID,
                orderNo,
                params
              )
            }
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="4"
              height="18"
              viewBox="0 0 4 18"
              fill="none"
            >
              <path
                d="M2 0C1.175 0 0.5 0.675 0.5 1.5C0.5 2.325 1.175 3 2 3C2.825 3 3.5 2.325 3.5 1.5C3.5 0.675 2.825 0 2 0ZM2 15C1.175 15 0.5 15.675 0.5 16.5C0.5 17.325 1.175 18 2 18C2.825 18 3.5 17.325 3.5 16.5C3.5 15.675 2.825 15 2 15ZM2 7.5C1.175 7.5 0.5 8.175 0.5 9C0.5 9.825 1.175 10.5 2 10.5C2.825 10.5 3.5 9.825 3.5 9C3.5 8.175 2.825 7.5 2 7.5Z"
                fill="#5D6B82"
              />
            </svg>
          </div>
        );
      },
    },
  ];

  const initialRows = [];

  const [rows, setRows] = useState(initialRows);

  const handleShow = () => {
    setShow(true);
  };

  const rowsWithIcons = rows.map((row) => ({
    ...row,
  }));

  const PAYMENT_STATUS_MAP = {
    0: "Unpaid",
    1: "Paid",
  };
  const API_URL = process.env.REACT_APP_API_URL;

  const STATUS_CLASS_MAP = {
    "Pending Approval": "invoice_pending_approval_bg", // Class for pending approval
    Rejected: "invoice_unpaid_reject_bg", // Class for rejected
    Completed: "invoice_completed_status_bg", // Class for completed
    Unpaid: "invoice_unpaid_reject_bg", // Reuse rejected for unpaid
    Paid: "invoice_completed_status_bg", // Reuse completed for paid
    "Payment Due": "invoice_payment_due_bg", // Class for payment due
  };

  const handleViewInvoice = (params) => {
    const id = invId;
    console.log("Navigating to invoice with ID:", invId);
    navigate(`/suppliers/view-invoice?id=${id}`);
  };

  const handlePaySupplier = () => {
    console.log("hello");
    setShow(true);
  };

  function convertUnixTimestampToDate(unixTimestamp) {
    const date = new Date(unixTimestamp * 1000);
    const formattedDate = `${String(date.getDate()).padStart(2, "0")}-${String(
      date.getMonth() + 1
    ).padStart(2, "0")}-${date.getFullYear()}`;
    return formattedDate;
  }

  useEffect(() => {
    const payload = {
      CLIENT_SUPP_ID: CLIENT_SUPPLIER_ID,
      CURRENT_PAGE: page + 1,
      SEARCH_KEYWORD: searchQuery,
      ITEM_PER_PAGE: pageSize,
    };
    makeApiCall({
      mod: "SupplierInvoice",
      action: "supplier_invoice",
      payload: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => {
      console.log(CLIENT_SUPPLIER_ID);
      console.log(("supplier invoice list", response));

      console.log("PAYLOAD", payload);
      setinvoiceCount(response.XscData.TOTAL_INVOICE);
      const supplierInvoiceList = response.XscData.SUPPLIER_INVOICE;
      if (
        Array.isArray(supplierInvoiceList) &&
        supplierInvoiceList.length > 0
      ) {
        const mappedRows = supplierInvoiceList.map((invoiceList) => ({
          id: invoiceList.INV_ID,
          ORDER_NO: invoiceList.ORDER_NO,
          TOTAL_ITEM: invoiceList.TOTAL_ITEM,
          TOTAL_CTNS: invoiceList.TOTAL_CTN,
          CREATED_ON: convertUnixTimestampToDate(invoiceList.CREATED_ON || "N/A"),
          TOTAL_AMOUNT: `${invoiceList.CURRENCY_SYMBOL}${invoiceList.TOTAL_AMOUNT || "0"}`,
          PENDING_AMOUNT: `${invoiceList.CURRENCY_SYMBOL}${invoiceList.DUE_AMOUNT || "0"}`,
          PAYMENT_STATUS: invoiceList.PAYMENT_STATUS,
          INVOICE_STATUS: invoiceList.INVOICE_STATUS,
          ACTION: (
            <Dropdown className="table_more" style={{ border: "none" }}>
              <Dropdown.Toggle
                id="dropdown-basic"
                style={{
                  backgroundColor: "white",
                  color: "#5D6B82",
                  fontSize: "1rem",
                  fontWeight: "400",
                  lineHeight: "1.5rem",
                  border: "none",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="4"
                  height="18"
                  viewBox="0 0 4 18"
                  fill="none"
                >
                  <path
                    d="M2 0C1.175 0 0.5 0.675 0.5 1.5C0.5 2.325 1.175 3 2 3C2.825 3 3.5 2.325 3.5 1.5C3.5 0.675 2.825 0 2 0ZM2 15C1.175 15 0.5 15.675 0.5 16.5C0.5 17.325 1.175 18 2 18C2.825 18 3.5 17.325 3.5 16.5C3.5 15.675 2.825 15 2 15ZM2 7.5C1.175 7.5 0.5 8.175 0.5 9C0.5 9.825 1.175 10.5 2 10.5C2.825 10.5 3.5 9.825 3.5 9C3.5 8.175 2.825 7.5 2 7.5Z"
                    fill="#5D6B82"
                  />
                </svg>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={(e, params) => handleViewInvoice(e, params)}
                >
                  View Invoice
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          ),
        }));
        setRows(mappedRows);
      }
    });
  }, [page, searchQuery, pageSize]);

  const handleApproveStatusChange = (row) => {
    const id = localStorage.getItem("ORDER_ID");
    console.log("Approving invoice with ID:", id);

    const payload = {
      SUPP_INV_ID: id,
      APPROVAL_STATUS: 1,
    };
    console.log("Payload for approval:", payload);

    // Optimistically update the UI
    setRows((prevRows) =>
      prevRows.map((r) => (r.id === id ? { ...r, INVOICE_STATUS: 1 } : r))
    );

    makeApiCall({
      mod: "SupplierInvoice",
      action: "approve-reject-invoice",
      payload: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        console.log("API Response for status update:", response);

        if (response?.XscStatus !== 1) {
          console.error("Error in API response:", response);
          // Optionally revert the optimistic update if the call fails
          setRows((prevRows) =>
            prevRows.map((r) =>
              r.id === id ? { ...r, INVOICE_STATUS: null } : r
            )
          );
        } else {
          console.log("Status successfully updated");
        }
      })
      .catch((error) => {
        console.error("Error updating status:", error);
        // Revert optimistic update on error
        setRows((prevRows) =>
          prevRows.map((r) =>
            r.id === id ? { ...r, INVOICE_STATUS: null } : r
          )
        );
      });

    setOptionShow(false);
  };

  const handleRejectStatusChange = (row) => {
    const id = localStorage.getItem("ORDER_ID");
    console.log("Rejecting invoice with ID:", id);

    const payload = {
      SUPP_INV_ID: id,
      APPROVAL_STATUS: 0,
    };

    console.log("Payload for rejection:", payload);

    // Optimistically update the UI
    setRows((prevRows) =>
      prevRows.map((r) => (r.id === id ? { ...r, INVOICE_STATUS: 0 } : r))
    );

    makeApiCall({
      mod: "SupplierInvoice",
      action: "approve-reject-invoice",
      payload: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        console.log("API Response for status update:", response);

        if (response?.XscStatus !== 1) {
          console.error("Error in API response:", response);
          // Revert optimistic update if needed
          setRows((prevRows) =>
            prevRows.map((r) =>
              r.id === id ? { ...r, INVOICE_STATUS: null } : r
            )
          );
        } else {
          console.log("Status successfully updated");
        }
      })
      .catch((error) => {
        console.error("Error updating status:", error);
        // Revert optimistic update on error
        setRows((prevRows) =>
          prevRows.map((r) =>
            r.id === id ? { ...r, INVOICE_STATUS: null } : r
          )
        );
      });

    setOptionShow(false);
  };


  // const handleINExcel = () => {
  //   const payload = {
  //     CLIENT_CUST_ID: CLIENT_CUST_ID,
  //     CUST_INV_ID: selectedId,
  //     APP_CLIENT_ID: APP_CLIENT_ID,
  //   };
  //   makeApiCall({
  //     mod: "SupplierInvoice",
  //     action: "customer_invoice_excel",
  //     payload: payload,
  //     headers: {
  //       Authorization: `Bearer ${token}`,
  //     },
  //   }).then((response) => {
  //     console.log("payload of invoice excel", payload);
  //     console.log("Invoice Excel SuccesS ", response);
  //     if (response.XscStatus === 1) {
  //       const url = `${API_URL}/${response.XscData.URL}`;
  //       fetch(url)
  //         .then((response) => response.blob())
  //         .then((blob) => {
  //           const link = document.createElement("a");
  //           link.href = URL.createObjectURL(blob);
  //           link.download = "invoice.xlsx"; // Set the filename here
  //           link.click();
  //         });
  //     }
  //   });
  // };

  const handleINExcel = () =>{

  }
  const handleINVPDF = () => {
    const payload = {
      CLIENT_SUPPLIER_ID: CLIENT_SUPPLIER_ID,
      SUPP_INV_ID: selectedId,
      APP_CLIENT_ID: APP_CLIENT_ID,
    };
    makeApiCall({
      mod: "SupplierInvoice",
      action: "supplier_invoice_pdf",
      payload: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => {
      console.log("payload of invoice pdf", payload);
      console.log("Invoice PDF SUCCESS ", response);
      if (response.XscStatus === 1) {
        const url = `${API_URL}/${response.XscData.URL}`;
        fetch(url)
          .then((response) => response.blob())
          .then((blob) => {
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = "invoice.pdf"; // Set the filename here
            link.click();
          });
      }
    });
  };
  // Your getInvoiceStatus and rendering logic remain unchanged.

  return (
    <div>
      <div className="container">
        <div>
          <div id="po_heading">Invoice</div>
          <div
            className="d-flex justify-content-between"
            id="supp_filters"
            style={{ marginTop: "20px" }}
          >
            <div className="d-flex">
              <div>
                <SearchBar onSearch={handleSearch} />
              </div>
              <div
                onClick={() => setModalShow(true)}
                style={{ cursor: "pointer" }}
              >
                <Filter />
              </div>
              <InvoiceFilter
                show={modalShow}
                onHide={() => setModalShow(false)}
              />
            </div>
            <a href="/suppliers/upload-invoice">
              <div id="create_po_button">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M12 12.5858L16.2426 16.8284L14.8284 18.2426L13 16.415V22H11V16.413L9.17157 18.2426L7.75736 16.8284L12 12.5858ZM12 2C15.5934 2 18.5544 4.70761 18.9541 8.19395C21.2858 8.83154 23 10.9656 23 13.5C23 16.3688 20.8036 18.7246 18.0006 18.9776L18.0009 16.9644C19.6966 16.7214 21 15.2629 21 13.5C21 11.567 19.433 10 17.5 10C17.2912 10 17.0867 10.0183 16.8887 10.054C16.9616 9.7142 17 9.36158 17 9C17 6.23858 14.7614 4 12 4C9.23858 4 7 6.23858 7 9C7 9.36158 7.03838 9.7142 7.11205 10.0533C6.91331 10.0183 6.70879 10 6.5 10C4.567 10 3 11.567 3 13.5C3 15.2003 4.21241 16.6174 5.81986 16.934L6.00005 16.9646L6.00039 18.9776C3.19696 18.7252 1 16.3692 1 13.5C1 10.9656 2.71424 8.83154 5.04648 8.19411C5.44561 4.70761 8.40661 2 12 2Z"
                    fill="#F7F7F7"
                  />
                </svg>
                Upload Invoice
              </div>
            </a>
          </div>
        </div>
        <div
          id="po_table"
          style={{
            height: 600,
            width: "100%",
            marginBottom: "80px",
            position: "relative",
            overflow: "hidden",
          }}
        >
          <DataGrid
            localeText={{
              noRowsLabel: (
                <div className="no_rows_css">
                  <img src={nodataimage} alt="" />
                  <div id="no_data_found_text">
                    You don’t have Invoices for this supplier yet
                  </div>

                  <div
                    id="create_po_button"
                    onClick={() => navigate("/customers/create-pi")}
                    style={{ marginTop: "28px" }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M11 11V5H13V11H19V13H13V19H11V13H5V11H11Z"
                        fill="#F7F7F7"
                      />
                    </svg>
                    Upload Invoice
                  </div>
                </div>
              ),
            }}
            style={{ position: "relative", overflow: "hidden" }}
            rows={rows}
            columns={columns}
            pageSize={pageSize}
            rowCount={invoiceCount}
            page={page}
            pagination
            paginationMode="server"
            rowsPerPageOptions={[10, 20, 50]} // Options for number of rows per page
            onPageSizeChange={handlePagesize}
            onPageChange={handlepagechange}
            // checkboxSelection
            disableSelectionOnClick
          />
        </div>
        {optionShow && (
          <div
            className="options-dropdown ellipsisOption"
            ref={ellipsisRef}
            style={{
              top: optionPosition.top,
              left: optionPosition.left,
            }}
          >
            {invoiceStatus == "Pending Approval" && (
              <div className="d-flex flex-column align-items-start gap-2">
                <a
                  id="dropdown_options"
                  href="/suppliers/modify-invoice"
                  style={{
                    textDecoration: "none",
                    color: "#535260",
                  }}
                >
                  Modify Invoice
                </a>
                <a
                  id="dropdown_options"
                  onClick={handleViewInvoice}
                  // href="/suppliers/view-invoice"
                  style={{
                    textDecoration: "none",
                    color: "#535260",
                    cursor: "pointer",
                  }}
                >
                  View Invoice
                </a>
                <a
                  id="dropdown_options"
                  // onClick={handleShow}
                  href={`/suppliers/view-po?id=${CLIENT_SUPPLIER_ID}`}
                  style={{
                    textDecoration: "none",
                    color: "#535260",
                  }}
                >
                  View PO
                </a>
                <div
                  onClick={() => {
                    handleApproveStatusChange(selectedRow, 1);
                    setOptionShow(false);
                  }}
                  id="dropdown_options"
                  // href="/suppliers/upload-invoice"
                  style={{
                    textDecoration: "none",
                    color: "#535260",
                    cursor: "pointer",
                  }}
                >
                  Approve
                </div>
                <div
                  onClick={() => {
                    handleRejectStatusChange(selectedRow, 0);
                    setOptionShow(false);
                  }}
                  id="dropdown_options"
                  // href="/suppliers/upload-invoice"
                  style={{
                    textDecoration: "none",
                    color: "#535260",
                    cursor: "pointer",
                  }}
                >
                  Reject
                </div>
              </div>
            )}
            {invoiceStatus == "Completed" && (
              <div className="d-flex flex-column align-items-start gap-2">
                <div
                  id="dropdown_options"
                  onClick={handlePaySupplier}
                  style={{
                    textDecoration: "none",
                    color: "#535260",
                    cursor: "pointer",
                  }}
                >
                  Pay Supplier
                </div>

                <a
                  id="dropdown_options"
                  onClick={handleViewInvoice}
                  style={{
                    textDecoration: "none",
                    color: "#535260",
                    cursor: "pointer",
                  }}
                >
                  View Invoice
                </a>
                {/* <a
                  id="dropdown_options"
                  // href="/events/edit-event"
                  style={{
                    textDecoration: "none",
                    color: "#535260",
                    cursor: "pointer",
                  }}
                  className="d-flex flex-row align-items-center gap-2"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="14"
                    viewBox="0 0 12 14"
                    fill="none"
                  >
                    <path
                      d="M6.8 6.99992L8.66667 9.66658H7.06667L6 8.14279L4.93333 9.66658H3.33333L5.2 6.99992L3.33333 4.33325H4.93333L6 5.85705L7.06667 4.33325H8V1.66659H1.33333V12.3333H10.6667V4.33325H8.66667L6.8 6.99992ZM0 0.994452C0 0.629285 0.298327 0.333252 0.665667 0.333252H8.66667L11.9998 3.66658L12 12.9949C12 13.3659 11.7034 13.6666 11.3377 13.6666H0.662267C0.296507 13.6666 0 13.3631 0 13.0054V0.994452Z"
                      fill="#635BFF"
                    />
                  </svg>
                  Download Excel
                </a> */}
                <a
                  id="dropdown_options"
                  // href="/events/edit-event"
                  style={{
                    textDecoration: "none",
                    color: "#535260",
                    cursor: "pointer",
                  }}
                  onClick={handleINExcel}
                  className="d-flex flex-row align-items-center gap-2"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="14"
                    viewBox="0 0 12 14"
                    fill="none"
                  >
                    <path
                      d="M6.8 6.99992L8.66667 9.66658H7.06667L6 8.14279L4.93333 9.66658H3.33333L5.2 6.99992L3.33333 4.33325H4.93333L6 5.85705L7.06667 4.33325H8V1.66659H1.33333V12.3333H10.6667V4.33325H8.66667L6.8 6.99992ZM0 0.994452C0 0.629285 0.298327 0.333252 0.665667 0.333252H8.66667L11.9998 3.66658L12 12.9949C12 13.3659 11.7034 13.6666 11.3377 13.6666H0.662267C0.296507 13.6666 0 13.3631 0 13.0054V0.994452Z"
                      fill="#635BFF"
                    />
                  </svg>
                  Download Excel
                </a>
                <a
                  id="dropdown_options"
                  // href="/events/edit-event"
                  style={{
                    textDecoration: "none",
                    color: "#535260",
                    cursor: "pointer",
                  }}
                  onClick={handleINVPDF}
                  className="d-flex flex-row align-items-center gap-2"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="16"
                    viewBox="0 0 14 16"
                    fill="none"
                  >
                    <path
                      d="M3.33333 2.66659H10V5.33325H12.6667V13.3333H3.33333V2.66659ZM2.66567 1.33325C2.29833 1.33325 2 1.62929 2 1.99445V14.0054C2 14.3631 2.29651 14.6666 2.66227 14.6666H13.3377C13.7034 14.6666 14 14.3659 14 13.9949L13.9998 4.66658L10.6667 1.33325H2.66567ZM6.99993 4.99992C6.99993 6.05158 6.69613 7.29145 6.18329 8.43552C5.66858 9.58372 4.97429 10.5667 4.25016 11.146L5.03643 12.2213C6.98807 10.9202 9.14887 10.028 11.239 10.3266L11.5441 9.03412C9.76233 8.44019 8.33327 6.65988 8.33327 4.99992H6.99993ZM7.39993 8.98098C7.5782 8.58338 7.73613 8.17078 7.86913 7.75225C8.18353 8.23532 8.5702 8.67872 9.00673 9.06345C8.3522 9.18065 7.711 9.37298 7.09013 9.61832C7.19993 9.40945 7.3034 9.19645 7.39993 8.98098Z"
                      fill="#635BFF"
                    />
                  </svg>
                  Download Pdf
                </a>
              </div>
            )}
            {invoiceStatus == "Payment Due" && (
              <div className="d-flex flex-column align-items-start gap-2">
                <div
                  id="dropdown_options"
                  // href="/suppliers/upload-invoice"
                  onClick={handlePaySupplier}
                  style={{
                    textDecoration: "none",
                    color: "#535260",
                    cursor: "pointer",
                  }}
                >
                  Pay Supplier
                </div>

                <a
                  id="dropdown_options"
                  onClick={handleViewInvoice}
                  style={{
                    textDecoration: "none",
                    color: "#535260",
                    cursor: "pointer",
                  }}
                >
                  View Invoice
                </a>
                <a
                  id="dropdown_options"
                  // href="/events/edit-event"
                  style={{
                    textDecoration: "none",
                    color: "#535260",
                    cursor: "pointer",
                  }}
                  className="d-flex flex-row align-items-center gap-2"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="14"
                    viewBox="0 0 12 14"
                    fill="none"
                  >
                    <path
                      d="M6.8 6.99992L8.66667 9.66658H7.06667L6 8.14279L4.93333 9.66658H3.33333L5.2 6.99992L3.33333 4.33325H4.93333L6 5.85705L7.06667 4.33325H8V1.66659H1.33333V12.3333H10.6667V4.33325H8.66667L6.8 6.99992ZM0 0.994452C0 0.629285 0.298327 0.333252 0.665667 0.333252H8.66667L11.9998 3.66658L12 12.9949C12 13.3659 11.7034 13.6666 11.3377 13.6666H0.662267C0.296507 13.6666 0 13.3631 0 13.0054V0.994452Z"
                      fill="#635BFF"
                    />
                  </svg>
                  Download Excel
                </a>
                <a
                  id="dropdown_options"
                  // href="/events/edit-event"
                  style={{
                    textDecoration: "none",
                    color: "#535260",
                    cursor: "pointer",
                    paddingRight: "3px",
                  }}
                  className="d-flex flex-row align-items-center gap-2"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="16"
                    viewBox="0 0 14 16"
                    fill="none"
                  >
                    <path
                      d="M3.33333 2.66659H10V5.33325H12.6667V13.3333H3.33333V2.66659ZM2.66567 1.33325C2.29833 1.33325 2 1.62929 2 1.99445V14.0054C2 14.3631 2.29651 14.6666 2.66227 14.6666H13.3377C13.7034 14.6666 14 14.3659 14 13.9949L13.9998 4.66658L10.6667 1.33325H2.66567ZM6.99993 4.99992C6.99993 6.05158 6.69613 7.29145 6.18329 8.43552C5.66858 9.58372 4.97429 10.5667 4.25016 11.146L5.03643 12.2213C6.98807 10.9202 9.14887 10.028 11.239 10.3266L11.5441 9.03412C9.76233 8.44019 8.33327 6.65988 8.33327 4.99992H6.99993ZM7.39993 8.98098C7.5782 8.58338 7.73613 8.17078 7.86913 7.75225C8.18353 8.23532 8.5702 8.67872 9.00673 9.06345C8.3522 9.18065 7.711 9.37298 7.09013 9.61832C7.19993 9.40945 7.3034 9.19645 7.39993 8.98098Z"
                      fill="#635BFF"
                    />
                  </svg>
                  Download Pdf
                </a>
                <a
                  id="dropdown_options"
                  href={`/suppliers/view-po?id=${CLIENT_SUPPLIER_ID}`}
                  style={{
                    textDecoration: "none",
                    color: "#535260",
                    cursor: "pointer",
                  }}
                >
                  View PO
                </a>
              </div>
            )}
          </div>
        )}
      </div>
      <PaySupplier show={show} onHide={() => setShow(false)} />
    </div>
  );
}

export default Invoice;
