import { Modal } from "react-bootstrap";
import "../../../Styles/PurchaseHistory.css";
import ProductImage from "../../../assets/Product_Package.png";
import { makeStyles } from "@material-ui/styles";
import { DataGrid } from "@mui/x-data-grid";
import React, { useState, useEffect } from "react";
import po_img from "../../../assets/po_img.png";
import { makeApiCall } from "../../../StaticComponents/API";
import { useSearchParams } from "react-router-dom";

const useStyles = makeStyles({
  header: {
    "& .MuiDataGrid-columnHeaderTitle": { fontWeight: "bold" },
    "& .MuiDataGrid-menuIcon": { display: "none" },
  },

  row: {
    "& .MuiDataGrid-cell--textLeft": { textAlign: "left !important" },
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "var(--size-140) !important",
  },

  headerId: {
    minWidth: "115px !important",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontSize: "14px",
  },

  headerProductName: {
    minWidth: "185px !important",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontSize: "14px",
  },

  headerSupplier: {
    minWidth: "180px !important",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontSize: "14px",
  },

  headerDate: {
    minWidth: "146px !important",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontSize: "14px",
  },

  headerPrice: {
    minWidth: "93px !important",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontSize: "14px",
  },
});
export default function CustomerPurchaseHistory(props) {
  const [pageSize, setPageSize] = useState(10);
  let token = localStorage.getItem("token");
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState();
  let [searchParams] = useSearchParams();
  const [purchaseInfo, setPurchaseInfo] = React.useState({
    xscdata: "",
  });
  const [img, setImg] = useState("");
  const [custProdId, setCustProdId] = useState("");
  const [mnxProdId, setMnxProdId] = useState("");
  const [perPiece, setPerPiece] = useState(0);
  const [qtyInInv, setQtyInInv] = useState(0);
  const [currencySymbol, setCurrencySymbol] = useState("");

  const classes = useStyles();

  // const ID = props.id;
  // const pId = props.pid;
  const CLIENT_CUST_ID = localStorage.getItem("CLIENT_CUST_ID");
  const PRODUCT_ID = localStorage.getItem("PRODUCT_ID");
  console.log("hello");
  console.log(PRODUCT_ID);

  const columns = [
    {
      field: "INVOICE_NUM",
      headerName: "Invoice No",
      width: 113,
      cellClassName: "sticky-column",
      headerClassName: classes.headerId,
      
    },
    {
      field: "PRODUCT_NAME",
      headerName: "Product Name",
      width: 185,
      editable: false,
      align: "left",
      headerClassName: classes.headerProductName,
    },
    {
      field: "CUSTOMER",
      headerName: "Customer",
      width: 180,
      editable: false,
      align: "left",
      headerClassName: classes.headerSupplier,
    },
    {
      field: "DATE",
      headerName: "On This Date",
      width: 146,
      editable: false,
      align: "left",
      headerClassName: classes.headerDate,
    },
    {
      field: "PRICE",
      headerName: "Price",
      width: 93,
      editable: false,
      align: "left",
      headerClassName: classes.headerPrice,
    },
  ];

  function convertUnixTimestampToDate(unixTimestamp) {
    const date = new Date(unixTimestamp * 1000);
    const formattedDate = `${String(date.getDate()).padStart(2, '0')}-${String(date.getMonth() + 1).padStart(2, '0')}-${date.getFullYear()}`;
    return formattedDate;
}

  const picture = () => (
    <div id="po_pic_bg">
      <img src={po_img} />
    </div>
  );

  const apiUrl  = process.env.REACT_APP_API_URL;
  

  const copyIcon = () => (
    <svg
      style={{ cursor: "pointer" }}
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 0V2H17.0066C17.5552 2 18 2.44495 18 2.9934V19.0066C18 19.5552 17.5551 20 17.0066 20H0.9934C0.44476 20 0 19.5551 0 19.0066V2.9934C0 2.44476 0.44495 2 0.9934 2H4V0H14ZM4 4H2V18H16V4H14V6H4V4ZM6 14V16H4V14H6ZM6 11V13H4V11H6ZM6 8V10H4V8H6ZM12 2H6V4H12V2Z"
        fill="#635BFF"
      />
    </svg>
  );

  const initialRows = [];

  const [rows, setRows] = useState(initialRows);

  const rowsWithIcons = rows.map((row) => ({
    ...row,
    PICTURE: picture(),
    PH: copyIcon(),
  }));

  useEffect(() => {
      const payload = {
        CLIENT_CUST_ID: CLIENT_CUST_ID,
        P_ID: PRODUCT_ID,
        CURRENT_PAGE: page + 1,
      };

      makeApiCall({
        mod: "Customer",
        action: "view-purchase-history",
        payload: payload,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          console.log("request", payload);
          console.log("View Purchase History Listed Successfully", response);
          const history = response.XscData.INVOICE_DETAILS;
          if (Array.isArray(history)) {
            const mappedRows = history.map((history) => ({
              id:history.ID,
              INVOICE_NUM: history.INVOICE_NO  || "N/A",
              PRODUCT_NAME: history.PRODUCT_NAME || "N/A",
              CUSTOMER: history.CUSTOMER  || "N/A",
              DATE: convertUnixTimestampToDate(history.ON_THIS_DATE)  || "N/A",
              PRICE: `${history.CURRENCY_SYMBOL} ${history.PRICE}`  || "0",
            }));

            setRows(mappedRows);
          }
          setMnxProdId(response.XscData.MNX_PROD_ID);
          setQtyInInv(response.XscData.QTY_IN_INVENTORY);
          setCustProdId(response.XscData.CUST_PROD_ID);
          setPerPiece(response.XscData.PER_PRICE);
          setTotal(response.XscData.TOTAL_PO);
          setImg(response.XscData.PRODUCT_IMAGE);
          setPurchaseInfo({
            xscdata: response.XscData,
          });
          setCurrencySymbol(response.XscData.CURRENCY_SYMBOL)
        })
        .catch((error) => {
          console.log(error);
        });
    
  }, [PRODUCT_ID]);

  const handleImageError = (e) =>{
    e.target.src = ProductImage
    }

  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="filter_text"
          >
            View Purchase History
          </Modal.Title>
        </Modal.Header>

        <div className="historyContent">
          <div className="div">
           <img
              src={apiUrl + `media/${img}`}
              className="productImage"
              onError={handleImageError}
            />
            <hr className="verticalBreaker" />
            <div className="productId">
              <h2 id="id">{custProdId}</h2>
              <p id="text">Cust.Prod.ID</p>
            </div>
            <hr className="verticalBreaker" />
            <div className="productId">
              <h2 id="id">{mnxProdId}</h2>
              <p id="text">Mnx.Prod.ID</p>
            </div>
            <hr className="verticalBreaker" />
            <div className="productId">
              <h2 id="id">{currencySymbol}{" "}{perPiece}</h2>
              <p id="text">Per Piece</p>
            </div>
            <hr className="verticalBreaker" />
            <div className="productId">
              <h2 id="id">{qtyInInv}</h2>
              <p id="text">Qty. in Inventory</p>
            </div>
          </div>

          <div
            style={{
              height: 600,
              width: 736,
              marginBottom: "80px",
              alignSelf: "center",
              justifyContent:"center",
            }}
          >
            <DataGrid
              rows={rowsWithIcons}
              columns={columns}
              pageSize={pageSize}
              rowsPerPageOptions={[10, 20, 50]}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              disableSelectionOnClick
              classes={{
                columnHeader: classes.header,
                row: classes.row,
              }}
            />
          </div>
        </div>
      </Modal>
    </>
  );
}
