import { useState } from "react";
import Filter from "../../StaticComponents/Filter";
import SearchBar from "../../StaticComponents/SearchBar";
import "../../Styles/ReportComponents.css";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { Dropdown } from "react-bootstrap";
import ReportSupplierTable from "./ReportSupplierTable";
import DatePickerModal from "./DatePickerModal";
import ReportSupplierFilter from "./ReportSupplierFilter";

export default function ReportSupplier() {
  const [modalShow, setModalShow] = useState(false);
  const [totalsuppliers, settotalsuppliers] = useState(0);
  const [regularsuppliers, setregularsuppliers] = useState(0);
  const [favouritesuppliers, setfavouritesuppliers] = useState(0);
  const setvalues = (total, regular, fav) => {
    settotalsuppliers(total);
    setregularsuppliers(regular);
    setfavouritesuppliers(fav);
  };
  const [searchkeyword, setsearchkeyword] = useState("");
  const [notify, setnotify] = useState();

  const onNotify = (value) => {
    setnotify(value);
  };
  const onSearch = (value) => {
    setsearchkeyword(value);
  };
  return (
    <div id="report_bg">
      <div className="container">
        <div id="report_overview_heading" className="row">
          <div className="col-md-4">
            <div id="total_stock">
              Total Suppliers
              <div id="total_count">{totalsuppliers}</div>
            </div>
          </div>
          <div className="col-md-4">
            {" "}
            <div id="total_stock">
              Regular Suppliers
              <div id="total_count">{regularsuppliers}</div>
            </div>
          </div>

          <div className="col-md-4">
            {" "}
            <div id="total_stock">
              Favourite Suppliers
              <div id="total_count">{favouritesuppliers}</div>
            </div>
          </div>
        </div>
        <div className="d-flex" id="invFilters">
          <SearchBar onSearch={onSearch} />
          <div onClick={() => setModalShow(true)} style={{ cursor: "pointer" }}>
            <Filter notify={notify} />
          </div>
          <ReportSupplierFilter
            show={modalShow}
            onHide={() => setModalShow(false)}
            onNotify={onNotify}
          />
          <Dropdown>
            <Dropdown.Toggle
              style={{
                marginLeft: "12px",
                width: "301px",
                height: "40px",
                backgroundColor: "var(--onSurfaceLowest)",
                color: "var(--onSurface)",
                border: "1px solid var(--outline1)",
                borderRadius: "var(--cr---3)",
              }}
            >
              Date
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <DatePickerModal />
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div id="report_tab_bg">
          <h2 className="totoal_1000_results">{totalsuppliers} Suppliers</h2>
          <ReportSupplierTable
            setvalues={setvalues}
            searchkeyword={searchkeyword}
          />
        </div>
      </div>
    </div>
  );
}
