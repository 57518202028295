import React, { useEffect, useState } from "react";
import logo from "../../../assets/Manex Logo.png";
import { DataGrid } from "@mui/x-data-grid";
import { Button } from "react-bootstrap";
import "../../../Styles/CreatePI.css";
import "react-datepicker/dist/react-datepicker.css";
import { makeStyles } from "@material-ui/styles";
import "../../../Styles/CreateShipping.css";
import { align } from "@progress/kendo-drawing";
import { makeApiCall } from "../../../StaticComponents/API";
import Modal from "react-bootstrap/Modal";
const useStyles = makeStyles({
  subheadingRow: {
    background: "var(--SurfaceContainerLow, #F5F2FF)",
    textAlign: "center",
    fontWeight: "bold",
    minHeight: "32px !important",
    maxHeight: "32px !important",
    "& .MuiDataGrid-cell": {
      minHeight: "32px !important",
      maxHeight: "32px !important",
      borderRight: "none !important",
      borderBottom: "none !important",
      minWidth: "1000px !important",
      maxWidth: "1000px !important",
    },
  },

  totalRow: {
    textAlign: "center",
    fontWeight: "bold",
    minHeight: "32px !important",
    maxHeight: "32px !important",
    "& .MuiDataGrid-cell": {
      border: "none !important",
      lineHeight: "32px !important",
      minHeight: "32px !important",
      maxHeight: "32px !important",
    },
  },

  subheadingCell: {
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    paddingLeft: 10,
    width: "100%",
    border: "0.5px solid var(--outlineVariant) !important",
    lineHeight: "24px !important",
    minHeight: "32px !important",
    maxHeight: "32px !important",
  },

  padding: { padding: "0px !important" },
});

export default function PreviewShipping(props) {
  const classes = useStyles();
  const CUST_NUM = localStorage.getItem("CUST_NUM");
  const CUST_ID = localStorage.getItem("CUST_ID");
  const token = localStorage.getItem("token");
  const APP_CLIENT_ID = localStorage.getItem("CLIENT_ID");
  const [containers, setcontainers] = useState([]);
  const CLIENT_CUST_ID = localStorage.getItem("CLIENT_CUST_ID");

  const columns = [
    {
      field: "MNX_ID",
      headerName: "Mnx. Item ID",
      width: 148,
      renderCell: (params) => {
        return params.id === "subheading" ? (
          <div className={classes.subheadingCell}>{params.value}</div>
        ) : (
          params.value
        );
      },
    },
    {
      field: "CUST_ITEM_ID",
      headerName: "Cust. Item ID",
      width: 148,
      renderCell: (params) => {
        return params.id === "subheading" ? (
          <div className={classes.subheadingCell}>{params.value}</div>
        ) : (
          params.value
        );
      },
    },
    {
      field: "DESCRIPTION",
      headerName: "Description",
      width: 275,
      renderCell: (params) => {
        return params.id === "subheading" ? (
          <div className={classes.subheadingCell}>{params.value}</div>
        ) : (
          params.value
        );
      },
    },
    {
      field: "CTN",
      headerName: "CTN",
      width: 148,
      type: "number",
      align: "left",
      renderCell: (params) => {
        return params.row.id_desc === "Total" ? (
          <div className={classes.subheadingCell}>{params.value}</div>
        ) : null;
      },
    },
    {
      field: "INN",
      headerName: "INN",
      width: 148,
    },
    {
      field: "PACKING",
      headerName: "Packing",
      width: 275,
    },
    {
      field: "TOTAL_QTY",
      headerName: "Total QTY",
      width: 125,
      renderCell: (params) => {
        return params.row.id_desc === "Total" ? (
          <div className={classes.subheadingCell}>{params.value}</div>
        ) : null;
      },
    },
    {
      field: "CBM_CTN",
      headerName: "CBM/CTN",
      width: 148,
    },
    {
      field: "TTL_CBM",
      headerName: "TTL CBM",
      width: 130,
      renderCell: (params) => {
        return params.row.id_desc === "Total" ? (
          <div className={classes.subheadingCell}>{params.value}</div>
        ) : null;
      },
    },
    {
      field: "CBM_PCS",
      headerName: "CBM/PCS",
      width: 148,
      cellClassName: "po_input",
    },
    {
      field: "NG_KG",
      headerName: "N.G/K.G",
      width: 148,
    },
    {
      field: "GW_KG",
      headerName: "G.W/K.G",
      width: 148,
    },
    {
      field: "TOTAL_NW",
      headerName: "Total N.W",
      width: 148,
      renderCell: (params) => {
        return params.row.id_desc === "Total" ? (
          <div className={classes.subheadingCell}>{params.value}</div>
        ) : null;
      },
    },
    {
      field: "NW_PCS",
      headerName: "N.W/PCS",
      width: 148,
    },
    {
      field: "TOTAL_GW",
      headerName: "Total G.W",
      width: 148,
      renderCell: (params) => {
        return params.row.id_desc === "Total" ? (
          <div className={classes.subheadingCell}>{params.value}</div>
        ) : null;
      },
    },
    {
      field: "GW_PCS",
      headerName: "G.W/PCS",
      width: 148,
    },
  ];

  const [rows, setRows] = useState(props.rows);

  const [clientData, setClientData] = useState({
    xscdata: "",
  });
  const [custData, setCustData] = useState({
    xscdata: "",
  });
  console.log("PAYLOAD FOR PREVIEW SHIPPING", props.payload);
  console.log("ROWS TO SHOW", props.rows);
  console.log("CONSIGNMENT FILE", props.file);
  const data = props.payload;
  const getData = () => {
    const payload = {
      CLIENT_CUST_ID: CLIENT_CUST_ID,
    };

    makeApiCall({
      mod: "Customer",
      action: "get_client_cust_details",
      payload: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((res) => {
      console.log(res, "customer-reports");
      setClientData({
        xscdata: res.XscData.CUST_DATA,
      });
      setCustData({
        xscdata: res.XscData.CLIENT_DATA,
      });
    });
  };
  useEffect(() => {
    getData();
  }, []);
  const handleInputChange = (e, rowId, field) => {
    console.log(e);
    const newRows = rows.map((row) => {
      if (row.id === rowId) {
        return { ...row, [field]: e.target.value };
      }
      return row;
    });
    setRows(newRows);
  };

  const getRowClassName = (params) => {
    if (params.row.id_desc === "Total") {
      return classes.totalRow;
    } else if (params.row.SUBHEADING && params.row.id_desc === undefined) {
      return classes.subheadingRow;
    } else {
      return "";
    }
  };
  function convertTimestampToDateTime(timestamp) {
    const date = new Date(timestamp);
    const formattedDate = date.toLocaleDateString(); // Adjusts to local time and format
    return formattedDate;
  }
  const getCellClassName = (params) => {
    return params.row.id_desc === "Total" ? classes.padding : "";
  };

  return (
    <div>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div id="suppliers_topbar_bg" className="">
          <div className="container d-flex justify-content-between align-items-center">
            <div
              className="d-flex flex-column align-items-center"
              style={{ gap: "4px" }}
            >
              <div
                id="header_name"
                className="d-flex align-items-center"
                style={{ gap: "16px" }}
              >
                <a
                  href="/customers/create-shipping"
                  className="text-decoration-none"
                >
                  {" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M7.82843 11.0009H20V13.0009H7.82843L13.1924 18.3648L11.7782 19.779L4 12.0009L11.7782 4.22266L13.1924 5.63687L7.82843 11.0009Z"
                      fill="#1B1B24"
                    />
                    <path
                      d="M7.82843 11.0009H20V13.0009H7.82843L13.1924 18.3648L11.7782 19.779L4 12.0009L11.7782 4.22266L13.1924 5.63687L7.82843 11.0009Z"
                      fill="black"
                      fill-opacity="0.2"
                    />
                    <path
                      d="M7.82843 11.0009H20V13.0009H7.82843L13.1924 18.3648L11.7782 19.779L4 12.0009L11.7782 4.22266L13.1924 5.63687L7.82843 11.0009Z"
                      fill="black"
                      fill-opacity="0.2"
                    />
                    <path
                      d="M7.82843 11.0009H20V13.0009H7.82843L13.1924 18.3648L11.7782 19.779L4 12.0009L11.7782 4.22266L13.1924 5.63687L7.82843 11.0009Z"
                      fill="black"
                      fill-opacity="0.2"
                    />
                    <path
                      d="M7.82843 11.0009H20V13.0009H7.82843L13.1924 18.3648L11.7782 19.779L4 12.0009L11.7782 4.22266L13.1924 5.63687L7.82843 11.0009Z"
                      fill="black"
                      fill-opacity="0.2"
                    />
                  </svg>{" "}
                </a>

                <h2 id="view_shipping">Preview S&H</h2>
              </div>
              <p id="shipping_id">{CUST_NUM}</p>
            </div>
          </div>
        </div>
        <div id="create_po_bg">
          <div className="container">
            <div id="po_logo" align="center">
              <img src={logo} alt="logo" />
              <div id="po_header_text">{custData.xscdata.NAME}</div>
            </div>
            <div className="row" id="about_po_header">
              <div className="col-6">
                <div id="po_supplier_text">Supplier</div>
                <div id="po_supplier_name">{custData.xscdata.NAME}</div>
                <div id="po_supplier_address">
                  {custData.xscdata.ADDR_1}, {custData.xscdata.CITY},{" "}
                  {custData.xscdata.COUNTRY}
                </div>
                <div id="po_supplier_ph">
                  TEL:{" "}
                  <span id="po_supplier_no">
                    {" "}
                    {custData.xscdata.CONTACT_NUMBER}
                  </span>
                </div>
                <div id="po_supplier_ph">
                  FAX: <span id="po_supplier_no"> {custData.xscdata.FAX}</span>
                </div>
                <div id="po_supplier_ph">
                  Invoice No: <span id="po_supplier_no"> MNXS-2024-0001</span>
                </div>
                <div id="po_supplier_ph">
                  Invoice No: <span id="po_supplier_no"> 20-July-2024</span>
                </div>
              </div>
              <div className="col-6 " id="about_po_left_bg" align="end">
                <div id="content_wrapper">
                  {" "}
                  <div id="po_supplier_text">Client</div>
                  <div id="po_supplier_name">{clientData.xscdata.NAME}</div>
                  <div id="po_supplier_address">
                    {clientData.xscdata.ADDR_1}, {clientData.xscdata.CITY},{" "}
                    {clientData.xscdata.COUNTRY}
                  </div>
                  <div id="po_supplier_ph">
                    TEL:{" "}
                    <span id="po_supplier_no">
                      {" "}
                      {clientData.xscdata.CONTACT_NUMBER}
                    </span>
                  </div>
                  <div id="po_supplier_ph">
                    FAXX:{" "}
                    <span id="po_supplier_no"> {clientData.xscdata.FAX}</span>
                  </div>
                  <div id="po_supplier_ph">
                    Customer PO No: <span id="po_supplier_no">UP987</span>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                maxHeight: "fit-content",
                width: "100%",
                marginTop: "20px",
              }}
            >
              <DataGrid
                rows={props.rows}
                columns={columns}
                hideFooterPagination={true}
                hideFooter={true}
                autoHeight
                rowHeight={80}
                showCellRightBorder
                getRowClassName={getRowClassName}
                getCellClassName={getCellClassName}
              />
            </div>

            <div
              className="d-flex flex-column gap-4"
              style={{
                marginTop: 45,
                borderBottom: "0.5px solid var(--outlineVariant)",
              }}
            >
              <div className="d-flex flex-row align-items-center gap-2">
                <div
                  style={{
                    width: 8,
                    height: 44,
                    background: "var(--primparyContainer, #635bff)",
                  }}
                ></div>
                <h2 id="shipping_details">Package Info</h2>
              </div>
              <div
                className="d-flex flex-column"
                style={{
                  gap: 12,
                  marginLeft: 17,
                  marginBottom: 44,
                }}
              >
                <div className="d-flex flex-row gap-2">
                  <strong>Total CTNS:</strong> {data.TOTAL_CTN}
                </div>
                <div className="d-flex flex-row gap-2">
                  <strong>Total QTY:</strong> {data.TOTAL_QTY} | PCS
                </div>
                <div className="d-flex flex-row gap-2">
                  <strong>Total N.W:</strong> {data.TOTAL_NW} | KGS
                </div>
                <div className="d-flex flex-row gap-2">
                  <strong>Total G.W:</strong> {data.TOTAL_GW} | KGS
                </div>
                <div className="d-flex flex-row gap-2">
                  <strong>Total CBM:</strong> {data.TOTAL_CBM}
                </div>
              </div>
            </div>

            <div
              className="d-flex flex-column gap-4"
              style={{
                marginTop: 52,
                borderBottom: "0.5px solid var(--outlineVariant)",
              }}
            >
              <div className="d-flex flex-row align-items-center gap-2">
                <div
                  style={{
                    width: 8,
                    height: 44,
                    background: "var(--primparyContainer, #635bff)",
                  }}
                ></div>
                <h2 id="shipping_details">Shipping Details</h2>
              </div>
              <div
                className="d-flex flex-column"
                style={{
                  gap: 12,
                  marginLeft: 17,
                  marginBottom: 44,
                }}
              >
                <div className="d-flex flex-row gap-2">
                  <strong>Shipping Company:</strong>{" "}
                  {data.SHIPPING_COMPANY_NAME}
                </div>
                <div className="d-flex flex-row gap-2">
                  <strong>Container No:</strong> {data.CONTAINER_NUM}
                </div>
                <div className="d-flex flex-row gap-2">
                  <strong>Seal No:</strong> {data.SEAL_NUMBER}
                </div>
                <div className="d-flex flex-row gap-2">
                  <strong>Vessel Name:</strong> {data.VESSEL_NAME}
                </div>
                <div className="d-flex flex-row gap-2">
                  <strong>Sailing Date:</strong>{" "}
                  {convertTimestampToDateTime(data.SAILING_DATE)}
                </div>
                <div className="d-flex flex-row gap-2">
                  <strong>Expected Delivery Date:</strong>{" "}
                  {convertTimestampToDateTime(data.EXPECTED_DELIVERY_DATE)}
                </div>
                <div className="d-flex flex-row gap-2">
                  <strong>Payment Type:</strong> {data.PAYMENT_TYPE}
                </div>
              </div>
            </div>

            <div
              className="d-flex flex-column gap-4"
              style={{
                marginTop: 52,
                borderBottom: "0.5px solid var(--outlineVariant)",
              }}
            >
              <div className="d-flex flex-row align-items-center gap-2">
                <div
                  style={{
                    width: 8,
                    height: 44,
                    background: "var(--primparyContainer, #635bff)",
                  }}
                ></div>
                <h2 id="shipping_details">Shipping Marks</h2>
              </div>
              <div
                className="d-flex flex-column"
                style={{
                  gap: 12,
                  marginLeft: 17,
                  marginBottom: 44,
                }}
              >
                {/* <div className="d-flex flex-row gap-2">
								<strong>Company Name:</strong> LAFAYETTE
							</div>
							<div className="d-flex flex-row gap-2">
								<strong>Type:</strong> Hummer Tools
							</div>
							<div className="d-flex flex-row gap-2">
								<strong>Destination:</strong> Venezezula
							</div>
							<div className="d-flex flex-row gap-2">
								<strong>Proforma Invoice No:</strong>{" "}
								MNX-2024-0001, MNX-2024-0002
							</div>
							<div className="d-flex flex-row gap-2">
								<strong>Made In:</strong> China
							</div>
							<div className="d-flex flex-row gap-2">
								<strong>Container No:</strong> CAAU8079171
							</div> */}
                {data.SHIPPING_MARK}
              </div>
            </div>

            <div
              className="d-flex flex-column align-items-start"
              style={{ marginTop: 44, gap: 24 }}
            >
              <div
                className="d-flex flex-row align-items-center"
                style={{
                  gap: 12,
                }}
              >
                <div
                  style={{
                    width: 8,
                    height: 44,
                    flexShrink: 0,
                    background: "var(--primparyContainer, #635bff)",
                  }}
                ></div>
                <span id="customer_po_text">Consignment File</span>
              </div>

              <div
                className="d-flex flex-row align-items-center"
                style={{ marginBottom: 191, gap: 36 }}
              >
                <div className="d-flex flex-row align-items-center gap-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M5 4H15V8H19V20H5V4ZM3.9985 2C3.44749 2 3 2.44405 3 2.9918V21.0082C3 21.5447 3.44476 22 3.9934 22H20.0066C20.5551 22 21 21.5489 21 20.9925L20.9997 7L16 2H3.9985ZM10.4999 7.5C10.4999 9.07749 10.0442 10.9373 9.27493 12.6534C8.50287 14.3757 7.46143 15.8502 6.37524 16.7191L7.55464 18.3321C10.4821 16.3804 13.7233 15.0421 16.8585 15.49L17.3162 13.5513C14.6435 12.6604 12.4999 9.98994 12.4999 7.5H10.4999ZM11.0999 13.4716C11.3673 12.8752 11.6042 12.2563 11.8037 11.6285C12.2753 12.3531 12.8553 13.0182 13.5101 13.5953C12.5283 13.7711 11.5665 14.0596 10.6352 14.4276C10.7999 14.1143 10.9551 13.7948 11.0999 13.4716Z"
                      fill="var(--onSurface)"
                    />
                    <path
                      d="M5 4H15V8H19V20H5V4ZM3.9985 2C3.44749 2 3 2.44405 3 2.9918V21.0082C3 21.5447 3.44476 22 3.9934 22H20.0066C20.5551 22 21 21.5489 21 20.9925L20.9997 7L16 2H3.9985ZM10.4999 7.5C10.4999 9.07749 10.0442 10.9373 9.27493 12.6534C8.50287 14.3757 7.46143 15.8502 6.37524 16.7191L7.55464 18.3321C10.4821 16.3804 13.7233 15.0421 16.8585 15.49L17.3162 13.5513C14.6435 12.6604 12.4999 9.98994 12.4999 7.5H10.4999ZM11.0999 13.4716C11.3673 12.8752 11.6042 12.2563 11.8037 11.6285C12.2753 12.3531 12.8553 13.0182 13.5101 13.5953C12.5283 13.7711 11.5665 14.0596 10.6352 14.4276C10.7999 14.1143 10.9551 13.7948 11.0999 13.4716Z"
                      fill="black"
                      fill-opacity="0.2"
                    />
                    <path
                      d="M5 4H15V8H19V20H5V4ZM3.9985 2C3.44749 2 3 2.44405 3 2.9918V21.0082C3 21.5447 3.44476 22 3.9934 22H20.0066C20.5551 22 21 21.5489 21 20.9925L20.9997 7L16 2H3.9985ZM10.4999 7.5C10.4999 9.07749 10.0442 10.9373 9.27493 12.6534C8.50287 14.3757 7.46143 15.8502 6.37524 16.7191L7.55464 18.3321C10.4821 16.3804 13.7233 15.0421 16.8585 15.49L17.3162 13.5513C14.6435 12.6604 12.4999 9.98994 12.4999 7.5H10.4999ZM11.0999 13.4716C11.3673 12.8752 11.6042 12.2563 11.8037 11.6285C12.2753 12.3531 12.8553 13.0182 13.5101 13.5953C12.5283 13.7711 11.5665 14.0596 10.6352 14.4276C10.7999 14.1143 10.9551 13.7948 11.0999 13.4716Z"
                      fill="black"
                      fill-opacity="0.2"
                    />
                    <path
                      d="M5 4H15V8H19V20H5V4ZM3.9985 2C3.44749 2 3 2.44405 3 2.9918V21.0082C3 21.5447 3.44476 22 3.9934 22H20.0066C20.5551 22 21 21.5489 21 20.9925L20.9997 7L16 2H3.9985ZM10.4999 7.5C10.4999 9.07749 10.0442 10.9373 9.27493 12.6534C8.50287 14.3757 7.46143 15.8502 6.37524 16.7191L7.55464 18.3321C10.4821 16.3804 13.7233 15.0421 16.8585 15.49L17.3162 13.5513C14.6435 12.6604 12.4999 9.98994 12.4999 7.5H10.4999ZM11.0999 13.4716C11.3673 12.8752 11.6042 12.2563 11.8037 11.6285C12.2753 12.3531 12.8553 13.0182 13.5101 13.5953C12.5283 13.7711 11.5665 14.0596 10.6352 14.4276C10.7999 14.1143 10.9551 13.7948 11.0999 13.4716Z"
                      fill="black"
                      fill-opacity="0.2"
                    />
                    <path
                      d="M5 4H15V8H19V20H5V4ZM3.9985 2C3.44749 2 3 2.44405 3 2.9918V21.0082C3 21.5447 3.44476 22 3.9934 22H20.0066C20.5551 22 21 21.5489 21 20.9925L20.9997 7L16 2H3.9985ZM10.4999 7.5C10.4999 9.07749 10.0442 10.9373 9.27493 12.6534C8.50287 14.3757 7.46143 15.8502 6.37524 16.7191L7.55464 18.3321C10.4821 16.3804 13.7233 15.0421 16.8585 15.49L17.3162 13.5513C14.6435 12.6604 12.4999 9.98994 12.4999 7.5H10.4999ZM11.0999 13.4716C11.3673 12.8752 11.6042 12.2563 11.8037 11.6285C12.2753 12.3531 12.8553 13.0182 13.5101 13.5953C12.5283 13.7711 11.5665 14.0596 10.6352 14.4276C10.7999 14.1143 10.9551 13.7948 11.0999 13.4716Z"
                      fill="black"
                      fill-opacity="0.2"
                    />
                  </svg>
                  <p id="filename">{props.file.name}</p>
                </div>
                <Button id="view_cons_file" style={{ width: 98 }}>
                  <h2 id="view">View</h2>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M16.0052 9.41421L7.39858 18.0208L5.98438 16.6066L14.591 8H7.00519V6H18.0052V17H16.0052V9.41421Z"
                      fill="#635BFF"
                    />
                  </svg>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
