import React, { useEffect, useState } from "react";
import "../../Styles/AddSupplier.css";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import DeleteContainer from "./DeleteContainer";
import TextInputWithAll from "../Inputs/TextInputWithAll";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { makeApiCall } from "../../StaticComponents/API";
import TextInput from "../Inputs/TextInput";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";
import DropDownInput from "../Inputs/DropDownInput";
import DropDownWithInput from "../Inputs/DropDownWithInput";
import { paddingRightIcon } from "@progress/kendo-svg-icons";
// import DeleteSupplier from "./DeleteSupplier";

function EditContainerDetails() {
  const maxChars = 50;
  const maxchardesc = 150;
  const [inputValue, setInputValue] = useState("");
  const [charCount, setCharCount] = useState(maxChars);
  const [file, setFile] = useState();
  const [value, setValue] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [status, setStatus] = useState("");
  const [image, setImage] = useState(null);
  const [filename, setFilename] = useState("");
  const [statusType, setStatusType] = useState("");
  // const [fileName, setFileName] = useState("");
  // const [imageFile, setImageFile] = useState(null);
  //   const [errorMessage, setErrorMessage] = useState("");
  let navigate = useNavigate()

  const [show, setShow] = useState(false);

  const initialvalues = {
    APP_CLIENT_ID: "",
    CONTAINER_ID: "",
    ALIAS_NAME: "",
    DESCRIPTION: "",
    // ID: "",
    CONTAINER_ICON: "",
    LENGTH: "",
    WIDTH: "",
    HEIGHT: "",
    TARE_WT: "",
    SIZE_UNIT: "",
    VOLUME: "",
    VOLUME_UNIT: "",
    MAX_WEIGHT: "",
    WEIGHT_UNIT: "",
    IS_ACTIVE: "",
  };

  const [data, setData] = useState(initialvalues);
  const [charCountAlias, setCharCountAlias] = useState(maxChars);
  const [charCountDesc, setCharCountDesc] = useState(maxchardesc);

  const StatusOptions = [
    { value: "1", label: "Active" },
    { value: "0", label: "Inactive" },
  ];

  const lengthOptions = [
    { value: "MTR", label: "m" },
    { value: "CM", label: "cm" },
    { value: "IN", label: "in" },
    { value: "FEET", label: "feet" },
    { value: "MM", label: "mm" },
  ];

  const weightOptions = [
    { value: "GM", label: "g" },
    { value: "KG", label: "Kg" },
    { value: "LBS", label: "lbs" },
  ];

  const volumeOptions = [
    { value: "CB_CM", label: "cm³" },
    { value: "CB_MTR", label: "m³" },
    { value: "CB_FEET", label: "feet³" },
    { value: "CB_MM", label: "mm³" },
    { value: "CB_IN", label: "in³" },
  ];

  let [searchParams] = useSearchParams();

  // const { id } = useParams();
  let token = localStorage.getItem("token");
  const APP_CLIENT_ID = localStorage.getItem("CLIENT_ID");
  const CONTAINER_ID = searchParams.get("id");
  console.log(APP_CLIENT_ID);
  console.log(CONTAINER_ID);

  useEffect(() => {
    makeApiCall({
      mod: "Shipping",
      action: "get-container-detail",
      payload: { APP_CLIENT_ID, CONTAINER_ID },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        console.log(response, "container-detail");
        if (response && response.XscData) {
          setData(response.XscData);
          setCharCountAlias(maxChars - response.XscData.ALIAS_NAME.length);
          setCharCountDesc(maxchardesc - response.XscData.DESCRIPTION.length);
          setLoading(false); // Set loading to false when data is fetched
          localStorage.setItem("id", response.XscData.CONTAINER_ID);
        }
        if (response && response.XscStatus) {
          setStatus(response.XscStatus);
        }
      })
      .catch((error) => {
        console.error("Error fetching Container details:", error);
        setError("Failed to fetch Container details.");
        setLoading(false); // Set loading to false even if there's an error
      });
  }, [token]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (
      ["LENGTH", "WIDTH", "HEIGHT", "VOLUME", "MAX_WEIGHT", "TARE_WT"].includes(name) &&
      !/^\d*\.?\d*$/.test(value) // Regular expression to allow numeric values, including decimal points
    ) {
      return; // Do nothing if value is not numeric
    }

    if (name === "VOLUME" && !/^\d*\.?\d*$/.test(value)) {
      return; // Do nothing if value is not numeric
    }

    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    // Update character count for alias name and description
    if (name === "ALIAS_NAME") {
      setCharCountAlias(maxChars - value.length);
    } else if (name === "DESCRIPTION") {
      setCharCountDesc(maxchardesc - value.length);
    }
  };

  const handleSelectChange = (event) => {
    const { name, value } = event.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      console.log("Selected file:", e.target.files[0]);
      console.log("Selected file:", e.target.files[0].lastModifiedDate);
      setFile(URL.createObjectURL(e.target.files[0]));
      setImage(e.target.files[0]);
      setFilename(e.target.files[0].name);
      //   setFileName(e.target.files[0].name);
    } else {
      console.error("No file selected");
    }
  };
  console.log("image uploaded", image);

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(data, "data");
    const payload = {
      APP_CLIENT_ID: APP_CLIENT_ID,
      CONTAINER_ID: CONTAINER_ID,
      ALIAS_NAME: data.ALIAS_NAME,
      DESCRIPTION: data.DESCRIPTION,
      LENGTH: data.LENGTH,
      WIDTH: data.WIDTH,
      HEIGHT: data.HEIGHT,
      SIZE_UNIT: data.SIZE_UNIT,
      TARE_WEIGHT: data.TARE_WT, 
      MAX_WEIGHT: data.MAX_WEIGHT, 
      WEIGHT_UNIT: data.WEIGHT_UNIT,
      VOLUME: data.VOLUME,
      VOLUME_UNIT: data.VOLUME_UNIT,
      IS_ACTIVE: data.IS_ACTIVE,
    };

    const checkRequiredFields = (payload) => {
      let missingFields = [];
      if (!payload.APP_CLIENT_ID) missingFields.push("App Client ID");
      if (!payload.CONTAINER_ID) missingFields.push("Container ID");
      if (!payload.ALIAS_NAME) missingFields.push("Alias Name");
      if (!payload.DESCRIPTION) missingFields.push("Description");
      if (payload.LENGTH === undefined || payload.LENGTH === null  || payload.LENGTH ==="")
        missingFields.push("Length");
      if (payload.WIDTH === undefined || payload.WIDTH === null || payload.WIDTH ==="")
        missingFields.push("Width");
      if (payload.HEIGHT === undefined || payload.HEIGHT === null || payload.HEIGHT ==="")
        missingFields.push("Height");
      if (!payload.SIZE_UNIT) missingFields.push("Size Unit");
      if (payload.TARE_WEIGHT === undefined || payload.TARE_WEIGHT === null ||payload.TARE_WEIGHT==="")
        missingFields.push("Tare Weight");
      if (payload.MAX_WEIGHT === undefined || payload.MAX_WEIGHT === null  || payload.MAX_WEIGHT ==="")
        missingFields.push("Max Weight");
      if (!payload.WEIGHT_UNIT) missingFields.push("Weight Unit");
      if (payload.VOLUME === undefined || payload.VOLUME === null || payload.VOLUME === "")
        missingFields.push("Volume");
      if (!payload.VOLUME_UNIT) missingFields.push("Volume Unit");
      if (payload.IS_ACTIVE === undefined || payload.IS_ACTIVE === null || payload.IS_ACTIVE ==="")
        missingFields.push("Status");

      return missingFields;
    };

    const missingFields = checkRequiredFields(payload);
    console.log(missingFields, "missing");
    console.log(payload, "payload");

    const formData = new FormData();
    formData.append("file", image);
    formData.append("mod", "Shipping");
    formData.append("action", "update-container");
    formData.append("payload", JSON.stringify(payload));

    console.log("image we are uploaded", formData.get("image"));

    axios({
      method: "post",
      url: process.env.REACT_APP_API_URL,
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        console.log(res, "container image edited");
        if (res.XscStatus === 1) {
          toast.success("image uploaded", {
            position: "bottom-center",
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });

    if (missingFields.length > 0) {
      const errorMessage = `Please fill in the following fields: ${missingFields.join(
        ", "
      )}`;
      toast.error(errorMessage, {
        position: "bottom-center",
      });
    } else {
      makeApiCall({
        mod: "Shipping",
        action: "update-container",
        payload: payload,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          console.log(response, "edit-detail");
          if (response && response.XscData) {
            setLoading(false); // Set loading to false when data is fetched
            setStatus(response.XscStatus);
            status == 1 && toast.success("Container Updated Successfully!") &&  navigate("/shipping")
          } else {
            toast.error("Failed to update Container.");
          }
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error updating container:", error);
          toast.error("Failed to update container.");
          setLoading(false);
        });
    }
  };

  const API_URL = process.env.REACT_APP_API_URL;

  return (
    <div id="supp_bg">
      <div id="suppliers_topbar_bg" className="">
        <div className="container d-flex justify-content-between align-items-center">
          <div id="dashboard_sub_header" className="d-flex ">
            <div>
           <a href="/shipping" > <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M7.82843 11.0009H20V13.0009H7.82843L13.1924 18.3648L11.7782 19.779L4 12.0009L11.7782 4.22266L13.1924 5.63687L7.82843 11.0009Z"
                  fill="var(--onSurface)"
                />
                <path
                  d="M7.82843 11.0009H20V13.0009H7.82843L13.1924 18.3648L11.7782 19.779L4 12.0009L11.7782 4.22266L13.1924 5.63687L7.82843 11.0009Z"
                  fill="black"
                  fill-opacity="0.2"
                />
                <path
                  d="M7.82843 11.0009H20V13.0009H7.82843L13.1924 18.3648L11.7782 19.779L4 12.0009L11.7782 4.22266L13.1924 5.63687L7.82843 11.0009Z"
                  fill="black"
                  fill-opacity="0.2"
                />
                <path
                  d="M7.82843 11.0009H20V13.0009H7.82843L13.1924 18.3648L11.7782 19.779L4 12.0009L11.7782 4.22266L13.1924 5.63687L7.82843 11.0009Z"
                  fill="black"
                  fill-opacity="0.2"
                />
                <path
                  d="M7.82843 11.0009H20V13.0009H7.82843L13.1924 18.3648L11.7782 19.779L4 12.0009L11.7782 4.22266L13.1924 5.63687L7.82843 11.0009Z"
                  fill="black"
                  fill-opacity="0.2"
                />
              </svg></a>  
            </div>

            <span className="ps-2"> Edit Container</span>
          </div>
        </div>
      </div>
      <div className="container">
        <div id="add_supplier_bg">
          <form className="row">
            <div className="col-12">
              <label
                style={{ width: "310px" }}
                htmlFor="file-input"
                className="d-flex align-items-center"
                id=""
              >
                <div id="upload_img_bg" style={{ cursor: "pointer" }}>
                  {file ? (
                    <img src={file} alt="Uploaded file" />
                  ) : data.CONTAINER_ICON ? (
                    <img
                      src={API_URL + `media/${data.CONTAINER_ICON}`}
                      alt="Container icon"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="40"
                      height="40"
                      viewBox="0 0 40 40"
                      fill="none"
                    >
                      <path
                        d="M34.9987 25V30H39.9987V33.3333H34.9987V38.3333H31.6654V33.3333H26.6654V30H31.6654V25H34.9987ZM35.0124 5C35.9254 5 36.6654 5.74158 36.6654 6.65567V21.6667H33.332V8.33333H6.66536V31.665L23.332 15L28.332 20V24.715L23.332 19.714L11.377 31.6667H23.332V35H4.98503C4.07211 35 3.33203 34.2585 3.33203 33.3443V6.65567C3.33203 5.74127 4.09088 5 4.98503 5H35.0124ZM13.332 11.6667C15.173 11.6667 16.6654 13.159 16.6654 15C16.6654 16.841 15.173 18.3333 13.332 18.3333C11.4911 18.3333 9.9987 16.841 9.9987 15C9.9987 13.159 11.4911 11.6667 13.332 11.6667Z"
                        fill="#5A5892"
                      />
                    </svg>
                  )}
                </div>
                <div id="upload_img_text">
                  {/* {fileName}{" "} */}
                  {/* {file  ? (
                    <div id="change_img_text">Change</div>
                  ) : (
                    <>
                      Upload Supplier Logo
                      <div id="img_size">It should be 400 by 400 pixels</div>
                      <div id="upload_img_button">Upload</div>
                    </>
                  )} */}
                  <>
                    Upload Container Icon(Optional)
                    <div id="img_size">It should be 400 by 400 pixels</div>
                    <div id="upload_img_button">
                      {file ? "Change" : "Upload"}
                    </div>
                  </>
                </div>
              </label>
              <TextInputWithAll
                name="file-input"
                type="file"
                onChange={handleChange}
                id="file-input"
                style={{ display: "none" }}
                maxLength={1}
                accept="image/*"
              />
            </div>

            <div className="col-5" id="add_supplier_input">
              <div className="position-relative" style={{ width: "380px" }}>
                <label>Alias Name</label>
                <br />
                <TextInputWithAll
                  name="ALIAS_NAME"
                  type="text"
                  onChange={handleInputChange}
                  maxLength={maxChars}
                  value={data.ALIAS_NAME}
                />{" "}
                <div
                  id="remaining_characters"
                  className="position-absolute transalate-middle-x "
                >
                  {charCountAlias}/50
                </div>
              </div>{" "}
              <div  className="position-relative" style={{ width: "380px" }}>
                <label>Length</label>
                <br />
                <DropDownWithInput
                  options={lengthOptions}
                  inputProps={{
                    className: "packaging_input",
                    name: "LENGTH",
                    value: data.LENGTH,
                    onChange: handleInputChange,
                    style: {
                      width: "310px",
                      marginRight: "0px",
                      border: "none",
                      background: "transparent",
                      marginTop: "0px",
                    },
                  }}
                  selectProps={{
                    name: "SIZE_UNIT",
                    className: "selectpackageing_type",
                    value: data.SIZE_UNIT,
                    onChange: handleSelectChange,
                    style: {
                      width: "55px",
                      border: "none",
                      background: "transparent",
                      outline: "none",
                      paddingLeft: "0px",
                    },
                  }}
                />
              </div>
              <div  className="position-relative" style={{ width: "380px" }}>
                <label>Height</label>
                <br />
                <DropDownWithInput
                  options={lengthOptions}
                  inputProps={{
                    className: "packaging_input",
                    name: "HEIGHT",
                    value: data.HEIGHT,
                    onChange: handleInputChange,
                    style: {
                      width: "310px",
                      marginRight: "0px",
                      border: "none",
                      background: "transparent",
                      marginTop: "0px",
                    },
                  }}
                  selectProps={{
                    name: "SIZE_UNIT",
                    className: "selectpackageing_type",
                    value: data.SIZE_UNIT,
                    onChange: handleSelectChange,
                    style: {
                      width: "55px",
                      border: "none",
                      background: "transparent",
                      outline: "none",
                      paddingLeft: "0px",
                    },
                  }}
                />
              </div>
              <div  className="position-relative" style={{ width: "380px" }}>
                <label>Tare Weight</label>
                <br />
                <DropDownWithInput
                  options={weightOptions}
                  inputProps={{
                    className: "packaging_input",
                    name: "TARE_WT",
                    value: data.TARE_WT,
                    onChange: handleInputChange,
                    style: {
                      width: "310px",
                      marginRight: "0px",
                      border: "none",
                      background: "transparent",
                      marginTop: "0px",
                    },
                  }}
                  selectProps={{
                    name: "WEIGHT_UNIT",
                    className: "selectpackageing_type",
                    value: data.WEIGHT_UNIT,
                    onChange: handleSelectChange,
                    style: {
                      width: "55px",
                      border: "none",
                      background: "transparent",
                      outline: "none",
                      paddingLeft: "0px",
                    },
                  }}
                />
              </div>
              <DropDownInput
                label="Container Status"
                options={StatusOptions}
                value={data.IS_ACTIVE} 
                onChange={(e) => {
                  console.log("Selected Status:", e.target.value);
                  setData((prevData) => ({
                    ...prevData,
                    IS_ACTIVE: e.target.value, 
                  }));
                }}
                id="selectCountry"
                placeholder="Select Status"
              />
            </div>
            <div className="col-5" id="add_supplier_input">
              <div className="position-relative" style={{ width: "380px" }}>
              <label>Description</label>
                <br />
                <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                <TextInputWithAll
                  name="DESCRIPTION"
                  type="text"
                  onChange={handleInputChange}
                  maxLength={maxchardesc}
                  value={data.DESCRIPTION}
                  style={{ paddingRight:"75px" }} 
                />{" "}
                <div
                  id="remaining_characters"
                  className="position-absolute transalate-middle-x "
                  
                >
                  {charCountDesc}/150
                </div>
              </div>
              </div>{" "}
              <div  className="position-relative" style={{ width: "380px" }}>
                <label>Width</label>
                <br />
                <DropDownWithInput
                  options={lengthOptions}
                  inputProps={{
                    name: "WIDTH",
                    className: "packaging_input",
                    value: data.WIDTH,
                    onChange: handleInputChange,
                    style: {
                      width: "310px",
                      marginRight: "0px",
                      border: "none",
                      background: "transparent",
                      marginTop: "0px",
                    },
                  }}
                  selectProps={{
                    name: "SIZE_UNIT",
                    className: "selectpackageing_type",
                    value: data.SIZE_UNIT,
                    onChange: handleSelectChange,
                    style: {
                      width: "55px",
                      border: "none",
                      background: "transparent",
                      outline: "none",
                      paddingLeft: "0px",
                    },
                  }}
                />
              </div>
              <div  className="position-relative" style={{ width: "380px" }}>
                <label>Capacity</label>
                <br />
                <DropDownWithInput
                  options={volumeOptions}
                  inputProps={{
                    name: "VOLUME",
                    className: "packaging_input",
                    value: data.VOLUME,
                    onChange: handleInputChange,
                    style: {
                      width: "310px",
                      marginRight: "0px",
                      border: "none",
                      background: "transparent",
                      marginTop: "0px",
                    },
                  }}
                  selectProps={{
                    name: "VOLUME_UNIT",
                    className: "selectpackageing_type",
                    value: data.VOLUME_UNIT,
                    onChange: handleSelectChange,
                    style: {
                      width: "60px",
                      border: "none",
                      background: "transparent",
                      outline: "none",
                      paddingLeft: "0px",
                    },
                  }}
                />
              </div>
              <div className="position-relative" style={{ width: "380px" }}>
                <label>Max Cargo Weight</label>
                <br />
                <DropDownWithInput
                  options={weightOptions}
                  inputProps={{
                    name: "MAX_WEIGHT",
                    className: "packaging_input",
                    value: data.MAX_WEIGHT,
                    onChange: handleInputChange,
                    style: {
                      width: "310px",
                      marginRight: "0px",
                      border: "none",
                      background: "transparent",
                      marginTop: "0px",
                    },
                  }}
                  selectProps={{
                    name: "WEIGHT_UNIT",
                    className: "selectpackageing_type",
                    value: data.WEIGHT_UNIT,
                    onChange: handleSelectChange,
                    style: {
                      width: "55px",
                      border: "none",
                      background: "transparent",
                      outline: "none",
                      paddingLeft: "0px",
                    },
                  }}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
      <div id="add_supplier_footer" className="">
        <div>
          <div id="add_supplier_buttons" className="container">
            {" "}
            <div id="delete_supplier_button" onClick={() => setShow(true)}>
              Delete Container
            </div>
            <DeleteContainer show={show} onHide={() => setShow(false)} />
            <div
              className="d-flex align-items-center justify-content-right "
              style={{ gap: "12px" }}
            >
             <a href="/shipping"> <div id="cancel">Cancel</div> </a>
              <div id="submitsupplier" onClick={handleSubmit}>Update Container</div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer position="bottom-center" style={{width:"max-content"}}/>
    </div>
  );
}

export default EditContainerDetails;
