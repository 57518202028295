import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { makeStyles } from "@material-ui/styles";
import PurchaseHistory from "./PurchaseHistory";
import { useNavigate, useParams } from "react-router-dom";
import po_img from "../../assets/po_img.png";
import { makeApiCall } from "../../StaticComponents/API";
import noDataImage from "../../assets/no_result_img.png";
import nodataimage from "../../assets/inventory_no_data_image.png";

export default function InventoryTable(props) {
  const API_URL = process.env.REACT_APP_API_URL;
  const [modalShow, setModalShow] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const navigate = useNavigate();
  const [page, setpage] = useState(0);
  const APP_CLIENT_ID = localStorage.getItem("CLIENT_ID");
  const [selectedProductId, setSelectedProductId] = useState(null);
  const [selectedClientSupplierId, setSelectedClientSupplierId] =
    useState(null);
  const token = localStorage.getItem("token");
  const [prodId, setProdID] = useState(null);
  const [selectedHistoryId, setselectedHistoryId] = React.useState(null);
  let clientSupplierID = localStorage.getItem("CLIENT_SUPPLIER_ID");
  const handlrepagechange = (newpage) => {
    setpage(newpage);
  };
  const columns = [
    {
      field: "supp_prod_id",
      headerName: "Sup.Prod.ID",
      width: 130,
      cellClassName: "sticky-column",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "MNX_ID",
      headerName: "Mnx.Prod.ID",
      width: 130,
      cellClassName: "sticky-column",
    },
    {
      field: "PRODUCT_NAME",
      headerName: "Product Name",
      width: 200,
      editable: false,
      align: "left",
    },
    {
      field: "PICTURE",
      headerName: "Picture",
      width: 130,
      editable: false,
      color: "#f5f2ff",
      renderCell: (params) => {
        const Image_URL = params.row.PICTURE
          ? `${API_URL}media/${params.row.PICTURE}`
          : po_img;
        return (
          <div id="po_pic_bg">
            <img src={Image_URL} />
          </div>
        );
      },
    },
    {
      field: "SUPPLIER",
      headerName: "Supplier",
      width: 200,
      editable: false,
      align: "left",
    },
    {
      field: "IN_STOCK",
      headerName: "In Stock",
      width: 113,
      editable: false,
      align: "left",
    },
    {
      field: "TO_SHIP",
      headerName: "To Ship",
      width: 109,
      editable: false,
      align: "left",
    },
    {
      field: "TO_RECEIVE",
      headerName: "To Receive",
      width: 132,
      editable: false,
      align: "left",
    },
    {
      field: "LP",
      headerName: "LP",
      width: 74,
      editable: false,
      align: "left",
    },
    {
      field: "PH",
      headerName: "PH",
      width: 75,
      editable: false,
      align: "left",
      renderCell: (params) => (
        <div
          onClick={(e) => {
            e.stopPropagation();
            const productId = params.row.id;
            const CLIENT_SUPPLIER_ID = params.row.clientSupplierID;

            localStorage.setItem("CLIENT_SUPPLIER_ID", CLIENT_SUPPLIER_ID);
            localStorage.setItem("PRODUCT_ID", productId);

            setSelectedProductId(productId);
            setSelectedClientSupplierId(CLIENT_SUPPLIER_ID);
            setModalShow(true);
            console.log("Selected IDs:", productId, CLIENT_SUPPLIER_ID);
          }}
        >
          {purchaseHistoryIcon()}
        </div>
      ),
    },
  ];

  const purchaseHistoryIcon = () => (
    <svg
      style={{ cursor: "pointer" }}
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 0V2H17.0066C17.5552 2 18 2.44495 18 2.9934V19.0066C18 19.5552 17.5551 20 17.0066 20H0.9934C0.44476 20 0 19.5551 0 19.0066V2.9934C0 2.44476 0.44495 2 0.9934 2H4V0H14ZM4 4H2V18H16V4H14V6H4V4ZM6 14V16H4V14H6ZM6 11V13H4V11H6ZM6 8V10H4V8H6ZM12 2H6V4H12V2Z"
        fill="#635BFF"
      />
    </svg>
  );

  const initialRows = [
    // {
    //   id: "S0121",
    //   MNX_ID: "M0124",
    //   PRODUCT_NAME: "12PCS MIRROR FASTE...",
    //   SUPPLIER: "BNS Clothes Service",
    //   IN_STOCK: 30,
    //   TO_SHIP: 20,
    //   TO_RECEIVE: 40,
    //   LP: "\u00a5 1.20",
    // },
  ];

  const [rows, setRows] = useState(initialRows);

  const rowsWithIcons = rows.map((row) => ({
    ...row,

    PH: purchaseHistoryIcon(),
  }));

  const { PRODUCT_ID } = useParams();

  const handleRowClick = (params) => {
    if (!params || !params.row) return;

    const productId = params.row.id;
    const CLIENT_SUPPLIER_ID = params.row.clientSupplierID;

    console.log("Row clicked:", params.row);
    console.log("Product ID:", productId);
    console.log("Client Supplier ID:", CLIENT_SUPPLIER_ID);

    localStorage.setItem("CLIENT_SUPPLIER_ID", CLIENT_SUPPLIER_ID);
    localStorage.setItem("PRODUCT_ID", productId);
    navigate(`/inventory/product-details/${productId}`);
  };

  useEffect(() => {
    const payload = {
      APP_CLIENT_ID: localStorage.getItem("CLIENT_ID"),
      CURRENT_PAGE: page + 1,
      SEARCH_KEYWORD: props.searchkeyword,
      // CLIENT_SUPP_ID: 133,
    };
    makeApiCall({
      mod: "Inventory",
      action: "list_inventory",
      payload: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        console.log("inventory list payload", payload);
        console.log(response, "Inventory List");
        const inventorydata = response.XscData.INVENTORY_LIST;
        const TOTAL_STOCK_PRODUCTS = response.XscData.TOTAL_STOCK_PRODUCTS;
        const PRODUCTS_TO_SHIP = response.XscData.TO_SHIP;
        const PRODUCTS_TO_RECEIVE = response.XscData.TO_RECEIVE;
        props.onValue(
          TOTAL_STOCK_PRODUCTS,
          PRODUCTS_TO_SHIP,
          PRODUCTS_TO_RECEIVE
        );
        const count = 0;
        if (Array.isArray(inventorydata)) {
          const mappedRows = inventorydata.map((inventory, index) => ({
            id: inventory.PRODUCT_ID,
            supp_prod_id: inventory.SUPP_PROD_ID,
            clientSupplierID: inventory.CLIENT_SUPP_ID,
            MNX_ID: inventory.MNX_PRODUCT_ID,
            PRODUCT_NAME: inventory.PRODUCT_NAME,
            SUPPLIER: inventory.SUPPLIER,
            IN_STOCK: inventory.IN_STOCK,
            TO_SHIP: inventory.TO_SHIP,
            TO_RECEIVE: inventory.TO_RECEIVE,
            LP: inventory.LAST_PRICE
              ? `${inventory.OPERATING_CURRENCY_SYMBOL} ${inventory.LAST_PRICE}`
              : 0,
            PICTURE: inventory.PICTURE,
          }));
          setRows(mappedRows);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [APP_CLIENT_ID, page, props.searchkeyword]);
  return (
    <>
      <div style={{ height: 600, width: "100%", marginTop: "20px" }}>
        <DataGrid
          localeText={{
            noRowsLabel: (
              <div className="no_rows_css" style={{ textAlign: "center" }}>
                <img
                  src={props.searchkeyword ? noDataImage : nodataimage}
                  alt=""
                  style={{ width: "103.26px", height: "100px" }}
                />
                <div
                  id="no_data_found_text"
                  style={{
                    fontFamily: "Inter",
                    fontWeight: 600,
                    fontSize: "22px",
                  }}
                >
                  {props.searchkeyword
                    ? "No results found in the list."
                    : "You don’t have any products in the Inventory as of now."}
                </div>
              </div>
            ),
          }}
          onRowClick={handleRowClick}
          rows={rowsWithIcons}
          columns={columns}
          rowHeight={80}
          pageSize={pageSize}
          onPageChange={handlrepagechange}
          rowsPerPageOptions={[10, 20, 50]}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          disableSelectionOnClick
          // initialState={{ pinnedColumns: { left: ['id'], right: ['actions'] } }}
        />
      </div>

      <PurchaseHistory
        show={modalShow}
        onHide={() => setModalShow(false)}
        productId={selectedProductId}
        clientSupplierId={selectedClientSupplierId}
      />
    </>
  );
}
