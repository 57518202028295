import React, { useState } from "react";
import logo from "../../../assets/Manex Logo.png";
import { DataGrid } from "@mui/x-data-grid";
import po_img from "../../../assets/po_img.png";
import SearchPO from "./SearchPO";
import PurchaseHistory from "../../Inventory/PurchaseHistory";
import "../../../Styles/Quotations.css";
import TextInput from "../../Inputs/TextInput";
import TextInputWithAll from "../../Inputs/TextInputWithAll";
function UploadQO() {
  const [searchShow, setSearchShow] = useState(false);
  const [historyShow, setHistoryShow] = useState(false);
  const columns = [
    {
      field: "id",
      headerName: "Item Id",
      width: 150,
      cellClassName: "po_input",
      align: "center",
      renderCell: (params) => (
        <TextInput
          type="text"
          value={params.value}
          onChange={(e) => handleInputChange(e, params.row.id, "id")}
        />
      ),
    },
    {
      field: "CTNS_QTY",
      headerName: "CTNS QTY",
      width: 150,
      cellClassName: "po_input",
      align: "center",

      renderCell: (params) => (
        <TextInput
          // type="number"
          value={params.value}
          onChange={(e) => handleInputChange(e, params.row.id, "CTNS_QTY")}
        />
      ),
    },
    {
      field: "PICTURE",
      headerName: "Picture",
      width: 100,
      align: "center",
      renderCell: (params) => (
        <div id="po_pic_bg">
          <img src={po_img} />
        </div>
      ),
    },
    {
      field: "PRODUCT_NAME",
      headerName: "Product Name",
      width: 180,
      align: "center",
    },
    {
      field: "TOTAL_QTY",
      headerName: "Total QTY",
      width: 150,
      align: "center",
    },
    {
      field: "PCS",
      headerName: "PCS/QTY",
      width: 130,
      type: "number",
      align: "center",
      cellClassName: "po_input",
      renderCell: (params) => (
        <TextInput
          type="text"
          value={params.value}
          onChange={(e) => handleInputChange(e, params.row.id, "PCS")}
        />
      ),
    },
    { field: "INN", headerName: "QTY/INN", width: 150 },
    {
      field: "RMB",
      headerName: "RMB/PCS",
      width: 130,
      align: "center",
      cellClassName: "po_input",
      renderCell: (params) => (
        <TextInput
          type="text"
          value={params.value}
          onChange={(e) => handleInputChange(e, params.row.id, "RMB")}
        />
      ),
    },
    {
      field: "PH",
      headerName: "PH",
      width: 80,
      align: "center",
      renderCell: (params) => (
        <svg
          style={{ cursor: "pointer" }}
          onClick={() => setHistoryShow(true)}
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M17 2V4H20.0066C20.5552 4 21 4.44495 21 4.9934V21.0066C21 21.5552 20.5551 22 20.0066 22H3.9934C3.44476 22 3 21.5551 3 21.0066V4.9934C3 4.44476 3.44495 4 3.9934 4H7V2H17ZM7 6H5V20H19V6H17V8H7V6ZM9 16V18H7V16H9ZM9 13V15H7V13H9ZM9 10V12H7V10H9ZM15 4H9V6H15V4Z"
            fill="var(--primaryContainer)"
          />
        </svg>
      ),
    },
    {
      field: "DEL",
      headerName: " ",
      width: 60,
      align: "center",
      renderCell: (params) => (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M17 6H22V8H20V21C20 21.5523 19.5523 22 19 22H5C4.44772 22 4 21.5523 4 21V8H2V6H7V3C7 2.44772 7.44772 2 8 2H16C16.5523 2 17 2.44772 17 3V6ZM18 8H6V20H18V8ZM9 11H11V17H9V11ZM13 11H15V17H13V11ZM9 4V6H15V4H9Z"
            fill="var(--error)"
          />
        </svg>
      ),
    },
  ];

  const initialRows = [
    {
      id: "F-1670",
      CTNS_QTY: 2,
      PICTURE: 30,
      PRODUCT_NAME: "PVC BIBCOK",
      TOTAL_QTY: 600,
      PCS: 300,
      INN: "N/A",
      RMB: "¥1.20",
      PH: "Value 9",
      DEL: "Value 10",
    },
    {
      id: "F-1671",
      CTNS_QTY: 2,
      PICTURE: 30,
      PRODUCT_NAME: "PVC BIBCOK",
      TOTAL_QTY: 600,
      PCS: 300,
      INN: "N/A",
      RMB: "¥1.20",
      PH: "Value 9",
      DEL: "Value 10",
    },
    // {
    //   id: 2,
    //   CTNS_QTY: 2,
    //   PICTURE: 25,
    //   PRODUCT_NAME: "PVC BIBCOK...",
    //   TOTAL_QTY: 600,
    //   PCS: 300,
    //   INN: "N/A",
    //   RMB: "¥1.20",
    //   PH: "Value 9",
    //   DEL: "Value 10",
    // },
  ];
  const [rows, setRows] = useState(initialRows);

  const handleInputChange = (e, rowId, field) => {
    const newRows = rows.map((row) => {
      if (row.id === rowId) {
        return { ...row, [field]: e.target.value };
      }
      return row;
    });
    setRows(newRows);
  };
  const handleAddNewItem = () => {
    const newRow = {
      id: rows.length + 1,
      CTNS_QTY: "",
      PICTURE: "",
      PRODUCT_NAME: "",
      TOTAL_QTY: "",
      PCS: "",
      INN: "",
      RMB: "",
      PH: "",
      DEL: "",
    };
    setRows([...rows, newRow]);
  };

  return (
    <div style={{ height: "100vh", position: "relative" }}>
      <div
        id="suppliers_topbar_bg"
        className=""
        style={{ position: "relative" }}
      >
        <div className="container d-flex justify-content-between align-items-center">
          <div
            id="dashboard_sub_header"
            className="d-flex align-items-center"
            style={{ gap: "16px" }}
          >
            <a href="/suppliers/supplier-info" className="text-decoration-none">
              {" "}
              <span id="cancel_po_button">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M10.5859 12.0001L2.79297 4.20718L4.20718 2.79297L12.0001 10.5858L19.793 2.79297L21.2072 4.20718L13.4143 12.0001L21.2072 19.7929L19.793 21.2072L12.0001 13.4143L4.20718 21.2072L2.79297 19.7929L10.5859 12.0001Z"
                    fill="var(--onSurface)"
                  />
                  <path
                    d="M10.5859 12.0001L2.79297 4.20718L4.20718 2.79297L12.0001 10.5858L19.793 2.79297L21.2072 4.20718L13.4143 12.0001L21.2072 19.7929L19.793 21.2072L12.0001 13.4143L4.20718 21.2072L2.79297 19.7929L10.5859 12.0001Z"
                    fill="black"
                    fill-opacity="0.2"
                  />
                  <path
                    d="M10.5859 12.0001L2.79297 4.20718L4.20718 2.79297L12.0001 10.5858L19.793 2.79297L21.2072 4.20718L13.4143 12.0001L21.2072 19.7929L19.793 21.2072L12.0001 13.4143L4.20718 21.2072L2.79297 19.7929L10.5859 12.0001Z"
                    fill="black"
                    fill-opacity="0.2"
                  />
                  <path
                    d="M10.5859 12.0001L2.79297 4.20718L4.20718 2.79297L12.0001 10.5858L19.793 2.79297L21.2072 4.20718L13.4143 12.0001L21.2072 19.7929L19.793 21.2072L12.0001 13.4143L4.20718 21.2072L2.79297 19.7929L10.5859 12.0001Z"
                    fill="black"
                    fill-opacity="0.2"
                  />
                  <path
                    d="M10.5859 12.0001L2.79297 4.20718L4.20718 2.79297L12.0001 10.5858L19.793 2.79297L21.2072 4.20718L13.4143 12.0001L21.2072 19.7929L19.793 21.2072L12.0001 13.4143L4.20718 21.2072L2.79297 19.7929L10.5859 12.0001Z"
                    fill="black"
                    fill-opacity="0.2"
                  />
                </svg>
              </span>{" "}
            </a>
            Upload Quotation
          </div>
        </div>
      </div>
      <div id="create_po_bg">
        <div className="container">
          <div className="row" id="about_po_header">
            <div className="col-6">
              <div id="qo_order_text">Order Number</div>

              <div id="qo_input_order">
                <label>Enter Order Number</label>
                <br />
                <TextInput />
              </div>
            </div>
            <div className="col-6 " id="about_po_left_bg" align="end">
              <div id="content_wrapper">
                {" "}
                <div id="qo_order_text">Quotation File</div>
                <div id="upload_qo_text">Upload Quotation File</div>
                <label
                  htmlFor="file-input-qo"
                  className="d-flex align-items-center justify-content-center flex-column"
                  id="file-input-label-qo"
                >
                  <div style={{ cursor: "pointer" }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <g opacity="0.4">
                        <path
                          d="M12 12.5858L16.2426 16.8284L14.8284 18.2426L13 16.415V22H11V16.413L9.17157 18.2426L7.75736 16.8284L12 12.5858ZM12 2C15.5934 2 18.5544 4.70761 18.9541 8.19395C21.2858 8.83154 23 10.9656 23 13.5C23 16.3688 20.8036 18.7246 18.0006 18.9776L18 17C18 13.6863 15.3137 11 12 11C8.7616 11 6.12243 13.5656 6.00414 16.7751L6 17L6.00039 18.9776C3.19696 18.7252 1 16.3692 1 13.5C1 10.9656 2.71424 8.83154 5.04648 8.19411C5.44561 4.70761 8.40661 2 12 2Z"
                          fill="#635BFF"
                        />
                      </g>
                    </svg>
                  </div>
                  <div id="upload_csv_text">{/* {file && fileName} */}</div>
                </label>
                <TextInputWithAll
                  name="file-input"
                  type="file"
                  //   onChange={handleChange}
                  id="file-input-qo"
                  //   ref={fileInputRef}
                  style={{ display: "none" }}
                  maxLength={1}
                  accept=".csv"
                />
              </div>
            </div>
          </div>

          <div
            style={{
              // height: "200px",
              maxHeight: "fit-content",
              width: "100%",
              marginTop: "40px",
            }}
          >
            <DataGrid
              rows={rows}
              columns={columns}
              hideFooterPagination={true}
              hideFooter={true}
              autoHeight
              rowHeight={80}
              showCellRightBorder
            />
          </div>
        </div>

        <PurchaseHistory
          show={historyShow}
          onHide={() => setHistoryShow(false)}
        />
        <div id="po_footer" style={{ marginTop: "52px" }}>
          <div id="add_supplier_buttons" className="container">
            {" "}
            <a href="/suppliers/supplier-info" className="text-decoration-none">
              {" "}
              <div id="cancel">Cancel</div>
            </a>
            <div
              className="d-flex align-items-center justify-content-right "
              style={{ gap: "12px" }}
            >
              <a href="/suppliers/preview-po" className="text-decoration-none">
                <div id="previewpo">
                  Preview PO{" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M16.0032 9.41421L7.39663 18.0208L5.98242 16.6066L14.589 8H7.00324V6H18.0032V17H16.0032V9.41421Z"
                      fill="#635BFF"
                    />
                  </svg>
                </div>
              </a>
              <a href="/suppliers/preview-po" className="text-decoration-none">
                <div id="submitsupplier">Approve Quotation & Update PO</div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UploadQO;
