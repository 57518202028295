import React from "react";
import ContainerDashboard from "./ContainerDashboard";
import CartonDashboard from "./CartonDashboard";

function ShippingDashboard({ activeTab, onTabChange }) {
  return (
    <div>
      {activeTab === "container" && <ContainerDashboard />}
      {activeTab === "carton" && <CartonDashboard />}
    </div>
  );
}

export default ShippingDashboard;
