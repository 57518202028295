import { useEffect, useRef, useState } from "react";
import { FormCheck, Modal } from "react-bootstrap";
import SearchBar from "../../../StaticComponents/SearchBar";
import { makeApiCall } from "../../../StaticComponents/API";

export default function CustomerReportFilter(props) {
  const [PaymentStatus, setPaymentStatus] = useState(false);
  const [paymentStatus, setpaymentStatus] = useState(true);
  const [currentPage, setcurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  let token = localStorage.getItem("token");
  const CLIENT_SUPPLIER_ID = localStorage.getItem("CLIENT_SUPPLIER_ID");

  useEffect(() => {
    setPaymentStatus(true);
  }, []);

  const [selectedFilters, setSelectedFilters] = useState({
    paymentStatus: [],
  });
  const [filtercount, setfiltercount] = useState(0);

  useEffect(() => {
    let count = 0;
    if (selectedFilters.paymentStatus.length > 0) {
      count += 1;
    }

    setfiltercount(count);
    // props.onNotify(count);
  }, [selectedFilters]);

  const modalContent = document.querySelector("#filter_content_bg"); // Adjust the selector to match your scrollable container
  const loadingRef = useRef(loading);
  const hasMoreRef = useRef(hasMore);
  const currentPageRef = useRef(currentPage);
  useEffect(() => {
    loadingRef.current = loading;
    hasMoreRef.current = hasMore;
  }, [loading, hasMore]);

  useEffect(() => {
    makeApiCall({
      mod: "Filters",
      action: "payment-status-filter",
      Payload: { APP_CLIENT_ID: 1, CLIENT_SUPP_ID: CLIENT_SUPPLIER_ID },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        console.log(
          "payment status filtered successfully",
          response.XscData.PAYMENT_STATUS
        );
        if (Array.isArray(response.XscData.PAYMENT_STATUS)) {
          setpaymentStatus(response.XscData.PAYMENT_STATUS);
        } else {
          console.error("Unexpected data ", response.XscData.PAYMENT_STATUS);
        }
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  const handleCheckboxChange = (filterType, value) => {
    setSelectedFilters((prevFilters) => {
      if (prevFilters[filterType].includes(value)) {
        return { ...prevFilters, [filterType]: [] };
      } else {
        return { ...prevFilters, [filterType]: [value] };
      }
    });
  };

  const handleRemoveFilter = (filterType, value) => {
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      [filterType]: prevFilters[filterType].filter((item) => item !== value),
    }));
  };

  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="filter_text"
          >
            Filters
            {filtercount > 0 ? (
              <div>({filtercount} selected)</div>
            ) : (
              <div></div>
            )}
          </Modal.Title>
        </Modal.Header>

        <div id="selected_fil">
          <div className="selected-filters-bg" style={{paddingLeft:"12px"}}>
            {selectedFilters.paymentStatus.length > 0 &&
              selectedFilters.paymentStatus.map((filter, index) => (
                <span key={index} className="filter-tag" style={{marginTop:"8px"}}>
                  {filter}
                  <svg
                    onClick={() => handleRemoveFilter("paymentStatus", filter)}
                    className="remove-filter-btn"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M7.99967 14.6667C4.31777 14.6667 1.33301 11.6819 1.33301 7.99999C1.33301 4.31809 4.31777 1.33333 7.99967 1.33333C11.6815 1.33333 14.6663 4.31809 14.6663 7.99999C14.6663 11.6819 11.6815 14.6667 7.99967 14.6667ZM7.99967 7.05719L6.11405 5.17157L5.17125 6.11437L7.05687 7.99999L5.17125 9.88559L6.11405 10.8284L7.99967 8.94279L9.88527 10.8284L10.8281 9.88559L8.94247 7.99999L10.8281 6.11437L9.88527 5.17157L7.99967 7.05719Z"
                      fill="#535260"
                    />
                  </svg>
                </span>
              ))}
          </div>
        </div>
        <div id="filter_content_bg">
          <div id="fil_side_menu_bg">
            <div
              id="fil_side_menu"
              //   onClick={handlePaymentStatus}
              style={{ cursor: "pointer" }}
            >
              <div className="position-relative d-flex align-items-center">
                {PaymentStatus && (
                  <div id="active_fil_indicator" className=""></div>
                )}
                <div
                  id="single_fil_menu"
                  style={{
                    color: PaymentStatus && "var(--primaryContainer)",
                    fontWeight: PaymentStatus && "600",
                  }}
                >
                  Payment Status
                </div>
              </div>
            </div>
          </div>

          {PaymentStatus && (
            <div id="fil_content_bg">
              {!PaymentStatus && <SearchBar />}
              {Array.isArray(paymentStatus) && paymentStatus.length > 0 ? (
                paymentStatus.map((status) => (
                  <div
                    key={status.STATUS}
                    onClick={() =>
                      handleCheckboxChange("paymentStatus", status.TYPE)
                    }
                    className="d-flex align-items-center justify-content-center"
                    id="fil_single_item"
                  >
                    <FormCheck
                      type="checkbox"
                      className="checkbox"
                      checked={selectedFilters.paymentStatus.includes(
                        status.TYPE
                      )}
                      
                    />
                    <div>{status.TYPE}</div>
                  </div>
                ))
              ) : (
                <div>No STATUS available</div>
              )}
            </div>
          )}
        </div>

        <Modal.Footer>
          <button
            id="reset_btn"
            className="btn"
            onClick={() =>
              setSelectedFilters({
                paymentStatus: [],
              })
            }
          >
            Reset{" "}
          </button>
          <button id="apply_btn" className="btn">
            Apply Filters
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
