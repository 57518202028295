import React from "react";
import "../../Styles/DropDownUpload.css"
const DropDownInputWithoutLabel = ({
	options,
	value,
	onChange,
	placeholder,
	name,
	id,
	style,
}) => {
	return (
		<div className="d-inline-flex justify-content-center align-items-center " >
			<select
			className="text_with_dropdown"
				id={id}
				name={name}
				value={value}
				onChange={onChange}
				style={style} 
			>
				<option value="" disabled>
					{placeholder}
				</option>
				{options.map((option) => (
					<option key={option.value} value={option.value} >
						{option.label}
					</option>
				))}
			</select>

			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="14"
				height="8"
				viewBox="0 0 14 8"
				fill="none"
				className="custom_select"
			>
				<path d="M7.00063 5.1714L11.9504 0.22168L13.3646 1.63589L7.00063 7.9999L0.636719 1.63589L2.05093 0.22168L7.00063 5.1714Z" />
			</svg>
		</div>
	);
};

export default DropDownInputWithoutLabel;
