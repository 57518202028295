import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { FormCheck } from "react-bootstrap";
import "../../../Styles/SupplierFilter.css";
import SearchBar from "../../../StaticComponents/SearchBar";
import { makeApiCall } from "../../../StaticComponents/API";

export default function ShippingFilter(props) {
  const [filtercount, setFilterCount] = useState(0);
 
  const [selectedFilters, setSelectedFilters] = useState({
    orderStatus: [],
  });
  const [OrderStatus, setOrderStatus] = useState([]);

  const handleSupplierName = () => {
    setOrderStatus(true);
  };

  useEffect(() => {
    let count = 0;
    if (selectedFilters.orderStatus.length > 0) {
      count += 1;
    }

    setFilterCount(count);
  }, [selectedFilters]);

  const token = localStorage.getItem("token");

  useEffect(() => {
    const payload = { APP_CLIENT_ID: 1 };
    makeApiCall({
      mod: "Filters",
      action: "order_status_filter_sup_cust",
      payload: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        console.log(
          "order status filtered successfully",
          response.XscData.ORDER_STATUS
        );
        if (Array.isArray(response.XscData.ORDER_STATUS)) {
          setOrderStatus(response.XscData.ORDER_STATUS);
        } else {
          console.error("Unexpected data", response.XscData.ORDER_STATUS);
        }
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, [token]);

  const handleCheckboxChange = (filterType, value) => {
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      [filterType]: prevFilters[filterType].includes(value)
        ? prevFilters[filterType].filter((item) => item !== value)
        : [...prevFilters[filterType], value],
    }));
  };

  const handleRemoveFilter = (filterType, value) => {
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      [filterType]: prevFilters[filterType].filter((item) => item !== value),
    }));
  };

  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="filter_text"
          >
            Filters
            {filtercount > 0 ? (
              <div>({filtercount} selected)</div>
            ) : (
              <div></div>
            )}
          </Modal.Title>
        </Modal.Header>

        <div id="selected_fil">
          <div className="selected-filters-bg" style={{paddingLeft:"20px", display:"flex"}}>
            {selectedFilters.orderStatus.length > 0 &&
              selectedFilters.orderStatus.map((filter, index) => (
                <span key={index} className="filter-tag" style={{marginTop:"8px"}} >
                  {filter}
                  <svg
                    onClick={() => handleRemoveFilter("orderStatus", filter)}
                    className="remove-filter-btn"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M7.99967 14.6667C4.31777 14.6667 1.33301 11.6819 1.33301 7.99999C1.33301 4.31809 4.31777 1.33333 7.99967 1.33333C11.6815 1.33333 14.6663 4.31809 14.6663 7.99999C14.6663 11.6819 11.6815 14.6667 7.99967 14.6667ZM7.99967 7.05719L6.11405 5.17157L5.17125 6.11437L7.05687 7.99999L5.17125 9.88559L6.11405 10.8284L7.99967 8.94279L9.88527 10.8284L10.8281 9.88559L8.94247 7.99999L10.8281 6.11437L9.88527 5.17157L7.99967 7.05719Z"
                      fill="#535260"
                    />
                  </svg>
                </span>
              ))}
          </div>
        </div>
        <div id="filter_content_bg">
          <div id="fil_side_menu_bg">
            <div
              id="fil_side_menu"
              //   onClick={handlePaymentStatus}
              style={{ cursor: "pointer" }}
            >
              <div className="position-relative d-flex align-items-center">
                {OrderStatus && (
                  <div id="active_fil_indicator" className=""></div>
                )}
                <div
                  id="single_fil_menu"
                  style={{
                    color: OrderStatus && "var(--primaryContainer)",
                    fontWeight: OrderStatus && "600",
                  }}
                >
                  Order Status
                </div>
              </div>
            </div>
          </div>

          {OrderStatus.length > 0 && (
            <div id="fil_content_bg">
              {OrderStatus.map((status) => (
                <div
                  key={status.STATUS}
                  className="d-flex align-items-center justify-content-center"
                  id="fil_single_item"
                >
                  <label className="checkbox d-flex align-items-center" id="fil_single_item">
                    <FormCheck
                      type="checkbox"
                      className="checkbox"
                      checked={selectedFilters.orderStatus.includes(status.TYPE)}
                      onChange={() => handleCheckboxChange("orderStatus", status.TYPE)}
                    />
                    <div>{status.TYPE}</div>
                  </label>
                </div>
              ))}
            </div>
          )}
        </div>

        <Modal.Footer>
        <button
            id="reset_btn"
            className="btn"
            onClick={() =>
              setSelectedFilters({
                orderStatus: [],
              })
            }
          >
            Reset{" "}
          </button>
          <button id="apply_btn" className="btn">
            Apply Filters
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
