import React, { useEffect, useState } from "react";
import logo from "../assets/Manex Logo.png";
import "../Styles/ResetPassword.css";
import axios from "axios";
import qs from "qs";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { Bars, ColorRing, Hourglass } from "react-loader-spinner";
// import { PulseSpinner } from "react-spinners-kit";
import { CircleLoader, DotLoader, PulseLoader } from "react-spinners";
import TextInput from "./Inputs/TextInput";
function ResetPassword() {
  const [email, setEmail] = useState("");
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState();

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const reqData = qs.stringify({
      mod: "Auth",
      action: "reset-password",
      payload: JSON.stringify({
        EMAIL: email,
      }),
    });
    axios({
      method: "POST",
      url: process.env.REACT_APP_API_URL,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      maxBodyLength: Infinity,
      data: reqData,
    }).then((res) => {
      console.log(res, "forget");
      setLoading(false);
      let status = res.data.XscStatus;
      let Message = res.data.XscMessage;
      status === 0 && toast.error(Message);
      status === 1 && setShow(true);
      console.log(status, show);
      // status === "Login Success" && navigate("/dashboard");
    });
  };
  return (
    <div id="login_bg" align="center">
      <div id="reset_password_bg">
        <div id="logo">
          <img src={logo} />
        </div>
        {/* reset password */}
        {!show && (
          <div>
            <div id="reset_pass_text">Reset Password</div>
            <div id="mail_text">
              Enter your mail to send a password reset link
            </div>
            <form onSubmit={handleSubmit}>
              <div id="input_label" align="left">
                <label>Email</label>
                <br />
                <TextInput
                  type="email"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  required
                />
              </div>
              <button className=" " id="reset_link">
                {loading ? (
                  <ColorRing
                    visible={true}
                    height="40"
                    width="40"
                    ariaLabel="color-ring-loading"
                    wrapperStyle={{}}
                    wrapperClass="color-ring-wrapper"
                    colors={[
                      "#FFFFFF",
                      "#FFFFFF",
                      "#FFFFFF",
                      "#FFFFFF",
                      "#FFFFFF",
                    ]}
                  />
                  // <DotLoader color="white" size={12}  />
                ) : (
                  "Send Link"
                )}
              </button>
            </form>
            <div id="remember_text">
              Remember your password?{" "}
              <span>
                <a
                  href="/"
                  className="text-decoration-none"
                  id="login_text_button"
                >
                  {" "}
                  Login
                </a>
              </span>
            </div>
          </div>
        )}

        {/* request sent */}
        {show && (
          <div>
            {" "}
            <div id="reset_pass_text">Request sent successfully</div>
            <div id="mail_text">
              A reset password link sent to <br />
              {email}
            </div>
            <a href="/" className="text-decoration-none">
              <button className=" " id="reset_link">
                Go back to login
              </button>
            </a>
          </div>
        )}
      </div>
      <ToastContainer position="top-right" />
    </div>
  );
}
export default ResetPassword;
