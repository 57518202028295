import React, { useEffect, useState } from "react";
import logo from "../../../assets/Manex Logo.png";
import { DataGrid } from "@mui/x-data-grid";
import { Button } from "react-bootstrap";
import "../../../Styles/CreatePI.css";
import "react-datepicker/dist/react-datepicker.css";
import { makeStyles } from "@material-ui/styles";
import "../../../Styles/CreateShipping.css";
import { align } from "@progress/kendo-drawing";
import { makeApiCall } from "../../../StaticComponents/API";
import Modal from "react-bootstrap/Modal";
const useStyles = makeStyles({
  subheadingRow: {
    background: "var(--SurfaceContainerLow, #F5F2FF)",
    textAlign: "center",
    fontWeight: "bold",
    minHeight: "32px !important",
    maxHeight: "32px !important",
    "& .MuiDataGrid-cell": {
      minHeight: "32px !important",
      maxHeight: "32px !important",
      borderRight: "none !important",
      borderBottom: "none !important",
      minWidth: "1000px !important",
      maxWidth: "1000px !important",
    },
  },

  totalRow: {
    textAlign: "center",
    fontWeight: "bold",
    minHeight: "32px !important",
    maxHeight: "32px !important",
    "& .MuiDataGrid-cell": {
      border: "none !important",
      lineHeight: "32px !important",
      minHeight: "32px !important",
      maxHeight: "32px !important",
    },
  },

  subheadingCell: {
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    paddingLeft: 10,
    width: "100%",
    border: "0.5px solid var(--outlineVariant) !important",
    lineHeight: "24px !important",
    minHeight: "32px !important",
    maxHeight: "32px !important",
  },

  padding: { padding: "0px !important" },
});

export default function PreviewInvoice(props) {
  const classes = useStyles();
  const CUST_NUM = localStorage.getItem("CUST_NUM");
  const CUST_ID = localStorage.getItem("CUST_ID");
  const token = localStorage.getItem("token");
  const APP_CLIENT_ID = localStorage.getItem("CLIENT_ID");
  const [containers, setcontainers] = useState([]);
  const CLIENT_CUST_ID = localStorage.getItem("CLIENT_CUST_ID");

  const columns = [
    {
      field: "id",
      headerName: "Mnx. Item ID",
      width: 200,
    },
    {
      field: "CUST_ITEM_ID",
      headerName: "Sup. Item ID",
      width: 200,
    },
    {
      field: "DESCRIPTION",
      headerName: "Description Goods",
      width: 275,
    },
    {
      field: "TOTAL_QTY",
      headerName: "TTL QTY",
      width: 150,
    },
    {
      field: "PRICE",
      headerName: "RMB",
      width: 150,
    },
    {
      field: "RMB",
      headerName: "Total RMB",
      width: 130,
      cellClassName: "po_input",
    },
  ];

  const [rows, setRows] = useState(props.rows);

  const [clientData, setClientData] = useState({
    xscdata: "",
  });
  const [custData, setCustData] = useState({
    xscdata: "",
  });
  console.log("PAYLOAD FOR PREVIEW INVOICE", props.payload);
  console.log("ROWS TO SHOW", props.rows);
  console.log("CONSIGNMENT FILE", props.file);
  const data = props.payload;
  const getData = () => {
    const payload = {
      CLIENT_CUST_ID: CLIENT_CUST_ID,
    };

    makeApiCall({
      mod: "Customer",
      action: "get_client_cust_details",
      payload: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((res) => {
      console.log(res, "customer-reports");
      setClientData({
        xscdata: res.XscData.CUST_DATA,
      });
      setCustData({
        xscdata: res.XscData.CLIENT_DATA,
      });
    });
  };
  useEffect(() => {
    getData();
  }, []);
  const handleInputChange = (e, rowId, field) => {
    console.log(e);
    const newRows = rows.map((row) => {
      if (row.id === rowId) {
        return { ...row, [field]: e.target.value };
      }
      return row;
    });
    setRows(newRows);
  };

  const getRowClassName = (params) => {
    if (params.row.id_desc === "Total") {
      return classes.totalRow;
    } else if (params.row.SUBHEADING && params.row.id_desc === undefined) {
      return classes.subheadingRow;
    } else {
      return "";
    }
  };
  function convertTimestampToDateTime(timestamp) {
    const date = new Date(timestamp);
    const formattedDate = date.toLocaleDateString();
    return formattedDate;
  }
  const getCellClassName = (params) => {
    return params.row.id_desc === "Total" ? classes.padding : "";
  };

  return (
    <div>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div id="suppliers_topbar_bg" className="">
          <div className="container d-flex justify-content-between align-items-center">
            <div
              className="d-flex flex-column align-items-center"
              style={{ gap: "4px" }}
            >
              <div
                id="header_name"
                className="d-flex align-items-center"
                style={{ gap: "16px" }}
              >
                <a
                  href="/customers/create-shipping"
                  className="text-decoration-none"
                >
                  {" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M7.82843 11.0009H20V13.0009H7.82843L13.1924 18.3648L11.7782 19.779L4 12.0009L11.7782 4.22266L13.1924 5.63687L7.82843 11.0009Z"
                      fill="#1B1B24"
                    />
                    <path
                      d="M7.82843 11.0009H20V13.0009H7.82843L13.1924 18.3648L11.7782 19.779L4 12.0009L11.7782 4.22266L13.1924 5.63687L7.82843 11.0009Z"
                      fill="black"
                      fill-opacity="0.2"
                    />
                    <path
                      d="M7.82843 11.0009H20V13.0009H7.82843L13.1924 18.3648L11.7782 19.779L4 12.0009L11.7782 4.22266L13.1924 5.63687L7.82843 11.0009Z"
                      fill="black"
                      fill-opacity="0.2"
                    />
                    <path
                      d="M7.82843 11.0009H20V13.0009H7.82843L13.1924 18.3648L11.7782 19.779L4 12.0009L11.7782 4.22266L13.1924 5.63687L7.82843 11.0009Z"
                      fill="black"
                      fill-opacity="0.2"
                    />
                    <path
                      d="M7.82843 11.0009H20V13.0009H7.82843L13.1924 18.3648L11.7782 19.779L4 12.0009L11.7782 4.22266L13.1924 5.63687L7.82843 11.0009Z"
                      fill="black"
                      fill-opacity="0.2"
                    />
                  </svg>{" "}
                </a>

                <h2 id="view_shipping">Preview Invoice</h2>
              </div>
              <p id="shipping_id">Invoice Number</p>
            </div>
          </div>
        </div>
        <div id="create_po_bg">
          <div className="container">
            <div id="po_logo" align="center">
              <img src={logo} alt="logo" />
              <div id="po_header_text">{custData.xscdata.NAME}</div>
            </div>
            <div className="row" id="about_po_header">
              <div className="col-6">
                <div id="po_supplier_text">Supplier</div>
                <div id="po_supplier_name">{custData.xscdata.NAME}</div>
                <div id="po_supplier_address">
                  {custData.xscdata.ADDR_1}, {custData.xscdata.CITY},{" "}
                  {custData.xscdata.COUNTRY}
                </div>
                <div id="po_supplier_ph">
                  TEL:{" "}
                  <span id="po_supplier_no">
                    {" "}
                    {custData.xscdata.CONTACT_NUMBER}
                  </span>
                </div>
                <div id="po_supplier_ph">
                  FAX: <span id="po_supplier_no"> {custData.xscdata.FAX}</span>
                </div>
                <div id="po_supplier_ph">
                  Invoice No: <span id="po_supplier_no"></span>
                </div>
                <div id="po_supplier_ph">
                  Invoice Date: <span id="po_supplier_no"> 20-July-2024</span>
                </div>
              </div>
              <div className="col-6 " id="about_po_left_bg" align="end">
                <div id="content_wrapper">
                  {" "}
                  <div id="po_supplier_text">Client</div>
                  <div id="po_supplier_name">{clientData.xscdata.NAME}</div>
                  <div id="po_supplier_address">
                    {clientData.xscdata.ADDR_1}, {clientData.xscdata.CITY},{" "}
                    {clientData.xscdata.COUNTRY}
                  </div>
                  <div id="po_supplier_ph">
                    TEL:{" "}
                    <span id="po_supplier_no">
                      {" "}
                      {clientData.xscdata.CONTACT_NUMBER}
                    </span>
                  </div>
                  <div id="po_supplier_ph">
                    FAXX:{" "}
                    <span id="po_supplier_no"> {clientData.xscdata.FAX}</span>
                  </div>
                  <div id="po_supplier_ph">
                    Customer PO No: <span id="po_supplier_no">UP987</span>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                maxHeight: "fit-content",
                width: "100%",
                marginTop: "20px",
              }}
            >
              <DataGrid
                rows={props.rows}
                columns={columns}
                hideFooterPagination={true}
                hideFooter={true}
                autoHeight
                rowHeight={80}
                showCellRightBorder
                getRowClassName={getRowClassName}
                getCellClassName={getCellClassName}
              />
            </div>

            <div
              className="d-flex flex-column gap-4"
              style={{
                marginTop: 45,
                borderBottom: "0.5px solid var(--outlineVariant)",
              }}
            >
              <div className="d-flex flex-row align-items-center gap-2">
                <div
                  style={{
                    width: 8,
                    height: 44,
                    background: "var(--primparyContainer, #635bff)",
                  }}
                ></div>
                <h2 id="shipping_details">Invoice details</h2>
              </div>
              <div
                className="d-flex flex-column"
                style={{
                  gap: 12,
                  marginLeft: 17,
                  marginBottom: 44,
                }}
              >
                <div className="d-flex flex-row gap-2">
                  <strong>Total Cost in RMB:</strong> {data.TOTAL_CTN}
                </div>
                <div className="d-flex flex-row gap-2">
                  <strong>Total Cost in USD</strong> {data.TOTAL_QTY} 
                </div>
                <div className="d-flex flex-row gap-2">
                  <strong>Agent Commission:</strong> {data.TOTAL_NW} 
                </div>
                <div className="d-flex flex-row gap-2">
                  <strong>Inland Transportation:</strong> {data.TOTAL_GW} 
                </div>
              </div>
            </div>

            <div
              className="d-flex flex-column gap-4"
              style={{
                marginTop: 52,
                borderBottom: "0.5px solid var(--outlineVariant)",
              }}
            >
              <div className="d-flex flex-row align-items-center gap-2">
                <div
                  style={{
                    width: 8,
                    height: 44,
                    background: "var(--primparyContainer, #635bff)",
                  }}
                ></div>
                <h2 id="shipping_details">Delivery Info</h2>
              </div>
              <div
                className="d-flex flex-column"
                style={{
                  gap: 12,
                  marginLeft: 17,
                  marginBottom: 44,
                }}
              >
                <div className="d-flex flex-row gap-2">
                  <strong>Delivery Date:</strong>{" "}
                  {convertTimestampToDateTime(data.DELIVERY_DATE)}
                </div>
                <div className="d-flex flex-row gap-2">
                  <strong>Sailing Date:</strong>{" "}
                  {convertTimestampToDateTime(data.SHIPPING_DATE)}
                </div>
                <div className="d-flex flex-row gap-2">
                  <strong>Container No:</strong> {data.CONTAINER_NUM}
                </div>
                <div className="d-flex flex-row gap-2">
                  <strong>Vessel Name:</strong> {data.VESSEL_NAME}
                </div>

                <div className="d-flex flex-row gap-2">
                  <strong>Payment Type:</strong> {data.PAYMENT_TYPE}
                </div>
                <div className="d-flex flex-row gap-2">
                  <strong>Seal No:</strong> {data.SEAL_NUM}
                </div>
              </div>
            </div>

            <div
              className="d-flex flex-column gap-4"
              style={{
                marginTop: 52,
                borderBottom: "0.5px solid var(--outlineVariant)",
              }}
            >
            
              <div
                className="d-flex flex-column"
                style={{
                  gap: 12,
                  marginLeft: 17,
                  marginBottom: 44,
                }}
              >
                {/* <div className="d-flex flex-row gap-2">
								<strong>Company Name:</strong> LAFAYETTE
							</div>
							<div className="d-flex flex-row gap-2">
								<strong>Type:</strong> Hummer Tools
							</div>
							<div className="d-flex flex-row gap-2">
								<strong>Destination:</strong> Venezezula
							</div>
							<div className="d-flex flex-row gap-2">
								<strong>Proforma Invoice No:</strong>{" "}
								MNX-2024-0001, MNX-2024-0002
							</div>
							<div className="d-flex flex-row gap-2">
								<strong>Made In:</strong> China
							</div>
							<div className="d-flex flex-row gap-2">
								<strong>Container No:</strong> CAAU8079171
							</div> */}
                {data.SHIPPING_MARK}
              </div>
            </div>

           
          </div>
        </div>
      </Modal>
    </div>
  );
}
