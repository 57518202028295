import React, { useState } from "react";
import "../../Styles/Inventory.css";
import SearchBar from "../../StaticComponents/SearchBar";
import CustomerPriceHistoryTable from "./PriceHistory/CustomerPriceHistoryTable";
import DatePickerModal from "../Reports/DatePickerModal";
import { Dropdown } from "react-bootstrap";

export default function CustomerPriceHistory() {
	const [modalShow, setModalShow] = useState(false);
	const [searchQuery, setSearchQuery] = useState("");

	const handleSearch = (query) => {
	  setSearchQuery(query);
	};
  

	return (
		<div id="inv_bg">
			<div className="container">
				<div className="d-flex" id="invFilters">
					<SearchBar onSearch={handleSearch}/>
					<div
						onClick={() => setModalShow(true)}
						style={{ cursor: "pointer" }}
					>
						<Dropdown>
							<Dropdown.Toggle
								style={{
									marginLeft: "12px",
									width: "301px",
									height: "40px",
									backgroundColor: "var(--onSurfaceLowest)",
									color: "var(--onSurface)",
									border: "1px solid var(--outline1)",
									borderRadius: "var(--cr---3)",
								}}
							>
								Date
							</Dropdown.Toggle>
							<Dropdown.Menu>
								<DatePickerModal />
							</Dropdown.Menu>
						</Dropdown>
					</div>
				</div>
				<div id="inv_tab_bg">
					<CustomerPriceHistoryTable searchQuery={searchQuery}/>
				</div>
			</div>
		</div>
	);
}
