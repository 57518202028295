import React from "react";

const TextInput = ({ type, value, name, onChange, pattern, id,style }) => {
  return (
    <div>
      <input
        required
        type={type}
        value={value}
        name={name}
        onChange={onChange}
        pattern={pattern}
        id={id}
        style={style}
      />
    </div>
  );
};

export default TextInput;
