import React, { useEffect, useState } from "react";
import OrderTopBar from "./OrderTopBar";
import OrdersDashboard from "./OrdersDashboard";

function OrderDashboardLayout() {
  const [activeTab, setActiveTab] = useState(
    localStorage.getItem("orderTab") === ""
      ? "PO"
      : localStorage.getItem("orderTab")
  );

  const handleTabChange = (tab) => {
    localStorage.setItem("orderTab", tab);
    setActiveTab(tab);
  };
  // useEffect(() => {
  //   localStorage.setItem("orderTab", "");
  // });
  return (
    <div>
      <OrderTopBar activeTab={activeTab} onTabChange={handleTabChange} />
      <OrdersDashboard activeTab={activeTab} />
    </div>
  );
}
export default OrderDashboardLayout;
