import "../../Styles/ProductDetails.css";
import ProductImageDetails from "./ProductImageDetails";
import ImagesAndDetails from "./ImagesAndDetails";
import CTNSInfo from "./CTNSInfo";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { makeApiCall } from "../../StaticComponents/API";
import { ToastContainer } from "react-bootstrap";

export default function ProductDetails() {
  //  const { PRODUCT_ID } = useParams();
  const [prodInfo, setProdInfo] = useState({
    product_details: "",
    prod_media: "",
    total_stock: "",
    to_receive: "",
    to_ship: "",
  });
  const navigate = useNavigate();
  const CLIENT_SUPPLIER_ID = localStorage.getItem("CLIENT_SUPPLIER_ID");
  const PRODUCT_ID = localStorage.getItem("PRODUCT_ID");
  let token = localStorage.getItem("token");
  const CLIENT_ID = localStorage.getItem("CLIENT_ID");

  const handleBack = () => {
    navigate("/inventory");
  };

  useEffect(() => {
    const fetchProductDetails = async () => {
      if (PRODUCT_ID) {
        console.log(PRODUCT_ID);
        const payload = { CLIENT_SUPPLIER_ID:CLIENT_SUPPLIER_ID, PRODUCT_ID: PRODUCT_ID };
    
        console.log("Payload sent to API:", payload);

        try {
          const res = await makeApiCall({
            mod: "Products",
            action: "get-product-details",
            payload: payload,
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });

          console.log("API Response:", res);

          if (res && res.XscData) {
            setProdInfo({
              product_details: res.XscData.PRODUCT_DETAILS,
              prod_media: res.XscData.PROD_MEDIA,
              total_stock: res.XscData.TOTAL_STOCK,
              to_receive: res.XscData.TO_RECEIVE,
              to_ship: res.XscData.TO_SHIP,
            });
          } else {
            console.error("Product details not found in response:", res);
          }
        } catch (error) {
          console.error("Error fetching product details:", error);
        }
      }
    };

    fetchProductDetails();
  }, [PRODUCT_ID, CLIENT_SUPPLIER_ID, token]);

  return (
    <div id="product_bg" className="">
      <div id="product_topbar_bg" className="" style={{}}>
        <div className="container d-flex justify-content-between">
          <div id="header_name" className="  d-flex">
            <div>
              <svg
                onClick={handleBack}
                style={{ cursor: "pointer" }}
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M7.82843 11.0009H20V13.0009H7.82843L13.1924 18.3648L11.7782 19.779L4 12.0009L11.7782 4.22266L13.1924 5.63687L7.82843 11.0009Z"
                  fill="var(--onSurface)"
                />
                <path
                  d="M7.82843 11.0009H20V13.0009H7.82843L13.1924 18.3648L11.7782 19.779L4 12.0009L11.7782 4.22266L13.1924 5.63687L7.82843 11.0009Z"
                  fill="black"
                  fill-opacity="0.2"
                />
                <path
                  d="M7.82843 11.0009H20V13.0009H7.82843L13.1924 18.3648L11.7782 19.779L4 12.0009L11.7782 4.22266L13.1924 5.63687L7.82843 11.0009Z"
                  fill="black"
                  fill-opacity="0.2"
                />
                <path
                  d="M7.82843 11.0009H20V13.0009H7.82843L13.1924 18.3648L11.7782 19.779L4 12.0009L11.7782 4.22266L13.1924 5.63687L7.82843 11.0009Z"
                  fill="black"
                  fill-opacity="0.2"
                />
                <path
                  d="M7.82843 11.0009H20V13.0009H7.82843L13.1924 18.3648L11.7782 19.779L4 12.0009L11.7782 4.22266L13.1924 5.63687L7.82843 11.0009Z"
                  fill="black"
                  fill-opacity="0.2"
                />
              </svg>
            </div>

            <span className="ps-2">
              {" "}
              Product Info
              <div id="edit_supplier_id">{`${prodInfo.product_details.SUPP_PROD_NUM}, ${prodInfo.product_details.CLIENT_PROD_NUM}`}</div>
            </span>
          </div>
        </div>
      </div>
      <div className="container">
        <ImagesAndDetails prodInfo={prodInfo} />
        <ProductImageDetails prodInfo={prodInfo} />
        <CTNSInfo prodInfo={prodInfo} />
      </div>
      <ToastContainer position="bottom-center" />
    </div>
  );
}
