import React, { useCallback, useEffect, useState } from "react";
import logo from "../../../assets/Manex Logo.png";
import cross from "../../../assets/Cross.png";
import { DataGrid } from "@mui/x-data-grid";
import po_img from "../../../assets/po_img.png";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { makeStyles } from "@material-ui/styles";
import "../../../Styles/CreatePI.css";
import CustomerPurchaseHistory from "./CustomerPurchaseHistory";
import TextInputWithAll from "../../Inputs/TextInputWithAll";
import DropDownInput from "../../Inputs/DropDownInput";
import { makeApiCall } from "../../../StaticComponents/API";
import debounce from "lodash/debounce";
import { useNavigate, useSearchParams } from "react-router-dom";

import num2words from "num2words";
import { toast, ToastContainer } from "react-toastify";

const useStyles = makeStyles({
  subheadingRow: {
    textAlign: "center",
    fontWeight: "bold",
    minHeight: "32px !important",
    maxHeight: "32px !important",
    "& .MuiDataGrid-cell": {
      border: "none !important",
      lineHeight: "32px !important",
      minHeight: "32px !important",
      maxHeight: "32px !important",
    },
  },

  subheadingCell: {
    width: "100%",
    border: "0.5px solid var(--outlineVariant) !important",
    lineHeight: "32px !important",
    minHeight: "32px !important",
    maxHeight: "32px !important",
  },

  padding: { padding: "0px !important" },
});

export default function ModifyInvoice() {
  const [startDate, setStartDate] = useState(new Date());
  const [historyShow, setHistoryShow] = useState(false);
  const [poNumber, setPoNumber] = useState("UP987");
  let token = localStorage.getItem("token");
  const [invoiceNumber, setInvoiceNumber] = useState(
    localStorage.getItem("INVOICE_NUM")
  );

  const navigate = useNavigate();

  const [PI_ID, setPI_ID] = useState(localStorage.getItem("INVOICE_ID"));

  const [suggestions, setsuggestions] = useState([]);
  const [searchParams] = useSearchParams();
  const APP_CLIENT_ID = localStorage.getItem("CLIENT_ID");

  const [containerNo, setcontainerNo] = useState("");
  const [sealNo, setSealNo] = useState("");
  const [deliveryDate, setdeliveryDate] = useState("");
  const [shippingDate, setshippingDate] = useState("");
  const [vessel_Name, setvessel_Name] = useState("");
  const [paymentType, setpaymentType] = useState("");

  const PaymentOptions = [
    { value: "CASH", label: "CASH" },
    { value: "ONLINE", label: "ONLINE" },
    { value: "CARD", label: "CARD" },
    { value: "T/T", label: "T/T" },
    { value: "OTHERS", label: "OTHERS" },
  ];

  const classes = useStyles();

  const columns = [
    {
      field: "MNX_ID",
      headerName: "Mnx. Item ID",
      width: 200,
    },
    {
      field: "CUST_ITEM_ID",
      headerName: "Cust. Item ID",
      width: 200,
    },
    {
      field: "DESCRIPTION",
      headerName: "Description Goods",
      width: 275,
    },
    {
      field: "TOTAL_QTY",
      headerName: "Total QTY",
      width: 150,
    },
    {
      field: "RMB",
      headerName: "RMB",
      width: 150,
    },
    {
      field: "TTL_RMB",
      headerName: "TTL RMB",
      width: 130,
    },
  ];

  const initialRows = [];
  const [rows, setRows] = useState(initialRows);

  const [viewInvoiceInfo, setViewInvoiceInfo] = React.useState({
    customerData: "",
    clientData: "",
    shippingDetails: "",
    summaryDetails: "",
  });

  let CLIENT_CUST_ID = localStorage.getItem("CLIENT_CUST_ID");

  function convertToUnixTimestamp(dateString) {
    const date = new Date(dateString);
    const unixTimestamp = Math.floor(date.getTime() / 1000);
    return unixTimestamp;
  }

  //const CUSTOMER_INVOICE_ID = searchParams.get("id");

  // useEffect(() => {
  //   const payload = {
  //     SEARCH_KEYWORD: "",
  //     CLIENT_CUST_ID: CLIENT_CUST_ID,
  //   };
  //   makeApiCall({
  //     mod: "CustomerInvoice",
  //     action: "list_invoice_num",
  //     payload: payload,
  //     headers: {
  //       Authorization: `Bearer ${token}`,
  //     },
  //   }).then((response) => {
  //     console.log("pauload", payload);
  //     console.log("response of Invoice list", response);
  //     setsuggestions(response.XscData.PI_LIST);
  //   });
  // }, []);

  function convertUnixTimestampToDate(unixTimestamp) {
    const date = new Date(unixTimestamp * 1000);
    const formattedDate = `${date.getFullYear()}-${String(
      date.getMonth() + 1
    ).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")}`;
    return formattedDate;
  }

  const CUST_INV_ID = searchParams.get("id");
  const handleModify = () => {
    const payload = {
      CUST_PI_ID: viewInvoiceInfo.summaryDetails.CUST_PI_ID,
      CUST_INV_NUM: viewInvoiceInfo.summaryDetails.CUST_INV_NUM,
      CUST_INV_ID: CUST_INV_ID,
      DELIVERY_DATE: convertToUnixTimestamp(deliveryDate),
      SHIPPING_DATE: convertToUnixTimestamp(shippingDate),
      PAYMENT_TYPE: paymentType,
      VESSEL_NAME: vessel_Name,
      CONTAINER_NUM: containerNo,
      SEAL_NUM: sealNo,
    };

    const checkRequiredFields = () => {
      let missingFields = [];
      
      if (!payload.DELIVERY_DATE) missingFields.push("Delivery Date");
      if (!payload.SHIPPING_DATE) missingFields.push("Sailing Date");
      if (!payload.VESSEL_NAME) missingFields.push("Vessel Name");
      if (!payload.PAYMENT_TYPE) missingFields.push("Payment Type");
      if (!payload.CONTAINER_NUM) missingFields.push("Container Number");
      if (!payload.SEAL_NUM) missingFields.push("Seal Number");
  
      return missingFields;
    };
  
    const missingFields = checkRequiredFields();
  
    // Step 3: If there are missing fields, show a toast and return early
    if (missingFields.length > 0) {
      const errorMessage = `Please fill in the following fields: ${missingFields.join(", ")}`;
      toast.error(errorMessage);
      console.log("Missing Fields:", missingFields);
      return; // Exit the function early
    }

    makeApiCall({
      mod: "CustomerInvoice",
      action: "update_cust_invoice",
      payload: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((res) => {
        console.log("API Response:", res);
        if (res.data.XscStatus !== 0) {
          toast.success(res.data.XscMessage);
          localStorage.setItem("activeTab", "Orders");
          localStorage.setItem("orderTab", "I");
          navigate("/customers/customer-info");
        } else {
          toast.error(res.data.XscMessage);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleInvoiceChange = (e) => {
    console.log(e.target.value);
    setInvoiceNumber(e.target.value);
    const payload = {
      SEARCH_KEYWORD: e.target.value,
      CLIENT_CUST_ID: CLIENT_CUST_ID,
    };
    makeApiCall({
      mod: "CustomerInvoice",
      action: "list_invoice_num",
      payload: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => {
      console.log("pauload", payload);
      console.log("response of Invoice List", response);
      setsuggestions(response.XscData.PI_LIST);
    });
  };

  const getData = (piId) => {
    const payload = {
      CLIENT_CUST_ID: CLIENT_CUST_ID,
      CUSTOMER_INVOICE_ID: piId,
    };
    console.log(piId, "pppppppppp");
    makeApiCall({
      mod: "CustomerInvoice",
      action: "view_cust_invoice_details",
      payload: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        console.log(response, "response");

        if (response.XscStatus === 1 && response.XscData) {
          const {
            SUMMARY_DETAILS,
            CUSTOMER_DETAILS,
            CLIENT_DATA,
            INVOICE_DETAILS,
            SHIPPING_DETAILS,
          } = response.XscData;

          const mappedRows = INVOICE_DETAILS.map((invoice) => ({
            id: invoice.CLIENT_PROD_NO,
            MNX_ID: invoice.CLIENT_PROD_NO,
            CUST_ITEM_ID: invoice.CUST_PROD_NO,
            DESCRIPTION: invoice.DESCRIPTION,
            TOTAL_QTY: invoice.TOTAL_QTY,
            RMB: invoice.PRICE_PER_ITEM,
            TTL_RMB: invoice.TOTAL_COST,
          }));

          setViewInvoiceInfo({
            customerData: CUSTOMER_DETAILS,
            clientData: CLIENT_DATA,
            shippingDetails: SHIPPING_DETAILS,
            summaryDetails: SUMMARY_DETAILS,
          });
          setRows(mappedRows);
        } else {
          console.log(response.XscMessage || "Failed to fetch data");
        }
      })
      .catch((error) => {
        console.error("API call failed: ", error);
      });
  };

  useEffect(() => {
    const payload = {
      CLIENT_CUST_ID: CLIENT_CUST_ID,
      CUSTOMER_INVOICE_ID: PI_ID,
    };

    makeApiCall({
      mod: "CustomerInvoice",
      action: "view_cust_invoice_details",
      payload: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        console.log(response, "response");

        if (response.XscStatus === 1 && response.XscData) {
          const {
            SUMMARY_DETAILS,
            CUSTOMER_DETAILS,
            CLIENT_DATA,
            INVOICE_DETAILS,
            SHIPPING_DETAILS,
          } = response.XscData;

          const mappedRows = INVOICE_DETAILS.map((invoice) => ({
            id: invoice.CLIENT_PROD_NO,
            MNX_ID: invoice.CLIENT_PROD_NO,
            CUST_ITEM_ID: invoice.CUST_PROD_NO,
            DESCRIPTION: invoice.DESCRIPTION,
            TOTAL_QTY: invoice.TOTAL_QTY,
            RMB: invoice.PRICE_PER_ITEM,
            TTL_RMB: invoice.TOTAL_COST,
          }));

          setViewInvoiceInfo({
            customerData: CUSTOMER_DETAILS,
            clientData: CLIENT_DATA,
            shippingDetails: SHIPPING_DETAILS,
            summaryDetails: SUMMARY_DETAILS,
          });
          setRows(mappedRows);
          setcontainerNo(SHIPPING_DETAILS.CONTAINER_NUM);
          setvessel_Name(SHIPPING_DETAILS.VESSEL_NAME);
          setdeliveryDate(convertUnixTimestampToDate(SHIPPING_DETAILS.DELIVERY_DATE));
          setSealNo(SHIPPING_DETAILS.SEAL_NUM);
          setshippingDate(convertUnixTimestampToDate(SHIPPING_DETAILS.SAILING_DATE));
          setpaymentType(SHIPPING_DETAILS.PAYMENT_TYPE);
        } else {
          console.log(response.XscMessage || "Failed to fetch data");
        }
      })
      .catch((error) => {
        console.error("API call failed: ", error);
      });
  }, []);

  function convertToUnixTimestamp(dateString) {
    const date = new Date(dateString);
    const unixTimestamp = Math.floor(date.getTime() / 1000);
    return unixTimestamp;
  }

  const convertAmountToText = (amount) => {
    if (typeof amount !== "number") {
      return "AMOUNT NOT AVAILABLE";
    }

    const [whole, fraction] = amount.toFixed(2).split("."); // Split into dollars and cents
    const wholeText = num2words(whole).toUpperCase(); // Convert whole number to text
    const fractionText = num2words(fraction).toUpperCase(); // Convert cents to text

    return `${wholeText} DOLLARS AND ${fractionText} CENTS`;
  };

  return (
    <div>
      <div id="suppliers_topbar_bg" className="">
        <div className="container d-flex justify-content-between align-items-center">
          <div
            id="dashboard_sub_header"
            className="d-flex align-items-center"
            style={{ gap: "16px" }}
          >
            <a href="/customers/customer-info" className="text-decoration-none">
              {" "}
              <span id="cancel_po_button">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M10.5859 12.0001L2.79297 4.20718L4.20718 2.79297L12.0001 10.5858L19.793 2.79297L21.2072 4.20718L13.4143 12.0001L21.2072 19.7929L19.793 21.2072L12.0001 13.4143L4.20718 21.2072L2.79297 19.7929L10.5859 12.0001Z"
                    fill="var(--onSurface)"
                  />
                  <path
                    d="M10.5859 12.0001L2.79297 4.20718L4.20718 2.79297L12.0001 10.5858L19.793 2.79297L21.2072 4.20718L13.4143 12.0001L21.2072 19.7929L19.793 21.2072L12.0001 13.4143L4.20718 21.2072L2.79297 19.7929L10.5859 12.0001Z"
                    fill="black"
                    fill-opacity="0.2"
                  />
                  <path
                    d="M10.5859 12.0001L2.79297 4.20718L4.20718 2.79297L12.0001 10.5858L19.793 2.79297L21.2072 4.20718L13.4143 12.0001L21.2072 19.7929L19.793 21.2072L12.0001 13.4143L4.20718 21.2072L2.79297 19.7929L10.5859 12.0001Z"
                    fill="black"
                    fill-opacity="0.2"
                  />
                  <path
                    d="M10.5859 12.0001L2.79297 4.20718L4.20718 2.79297L12.0001 10.5858L19.793 2.79297L21.2072 4.20718L13.4143 12.0001L21.2072 19.7929L19.793 21.2072L12.0001 13.4143L4.20718 21.2072L2.79297 19.7929L10.5859 12.0001Z"
                    fill="black"
                    fill-opacity="0.2"
                  />
                  <path
                    d="M10.5859 12.0001L2.79297 4.20718L4.20718 2.79297L12.0001 10.5858L19.793 2.79297L21.2072 4.20718L13.4143 12.0001L21.2072 19.7929L19.793 21.2072L12.0001 13.4143L4.20718 21.2072L2.79297 19.7929L10.5859 12.0001Z"
                    fill="black"
                    fill-opacity="0.2"
                  />
                </svg>
              </span>{" "}
            </a>
            Modify Invoice
          </div>
        </div>
      </div>
      <div id="create_po_bg">
        <div className="container">
          <div className="ci_po_first">
            <div>Invoice Number</div>
          </div>
          <div id="ci_po_input">
            <label className="ci_po_label">Enter Invoice Number</label>
            <TextInputWithAll
              id="ci_po_input_field"
              type="text"
              placeholder="Enter Invoice Number"
              value={invoiceNumber}
              onChange={(e) => handleInvoiceChange(e)}
            />
            <div className="ci_po_suggestion">
              {suggestions.length > 0 && (
                <div className="suggestions-dropdown">
                  {suggestions.map((suggestion) => (
                    <div
                      key={suggestion.CUST_PI_ID}
                      className="suggestion-item"
                      onClick={() => {
                        setPI_ID(suggestion.CUST_PI_ID);
                        setInvoiceNumber(suggestion.CUST_PI_NUM);
                        getData(suggestion.CUST_PI_ID);
                        setsuggestions([]);
                      }}
                    >
                      {suggestion.CUST_PI_NUM}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
          <div id="po_logo" align="center">
            <img src={logo} alt="logo" />
            <div id="po_header_text">MANEX EXPORTS (H.K.) LTD.</div>
          </div>
          <div className="row" id="about_po_header">
            <div className="col-6">
              <div id="po_supplier_text">Supplier</div>
              <div id="po_supplier_name">
                {viewInvoiceInfo.customerData.NAME || "N/A"}
              </div>
              <div id="po_supplier_address">
                {viewInvoiceInfo.customerData.ADDR_1 || "N/A"}
                <br />
                {viewInvoiceInfo.customerData.ADDR_2}
              </div>
              <div id="po_supplier_ph">
                TEL:{" "}
                <span id="po_supplier_no">
                  {" "}
                  {viewInvoiceInfo.customerData.CONTACT_NUMBER || "N/A"}
                </span>
              </div>
              <div id="po_supplier_ph">
                FAX:{" "}
                <span id="po_supplier_no">
                  {" "}
                  {viewInvoiceInfo.customerData.FAX || "N/A"}
                </span>
              </div>
              <div id="po_supplier_ph">
                Invoice No:{" "}
                <span id="po_supplier_no">
                  {viewInvoiceInfo.summaryDetails.CUST_INV_NUM || "N/A"}
                </span>
              </div>
              <div id="po_supplier_ph">
                Date:
                {/* <span id="po_supplier_no"><TimestampTo timestamp={viewInvoiceInfo.summaryDetails.CREATED_ON ||"N/A"}/></span> */}
              </div>
            </div>
            <div className="col-6 " id="about_po_left_bg" align="end">
              <div id="content_wrapper">
                {" "}
                <div id="po_supplier_text">Client</div>
                <div id="po_supplier_name">
                  {viewInvoiceInfo.clientData.NAME || "N/A"}
                </div>
                <div id="po_supplier_address">
                  {viewInvoiceInfo.clientData.ADDR_1 || "N/A"}
                  <br />
                  {viewInvoiceInfo.clientData.ADDR_2}
                </div>
                <div id="po_supplier_ph">
                  TEL:{" "}
                  <span id="po_supplier_no">
                    {viewInvoiceInfo.clientData.CONTACT_NUMBER || "N/A"}
                  </span>
                </div>
                <div id="po_supplier_ph">
                  FAX:{" "}
                  <span id="po_supplier_no">
                    {viewInvoiceInfo.clientData.FAX || "N/A"}
                  </span>
                </div>
                <div id="po_supplier_ph">
                  Customer PO No:{" "}
                  <span id="po_supplier_no">
                    {" "}
                    {viewInvoiceInfo.summaryDetails.CUST_PO_NUM || "N/A"}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              maxHeight: "fit-content",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <DataGrid
              rows={rows}
              columns={columns}
              hideFooterPagination={true}
              hideFooter={true}
              autoHeight
              rowHeight={80}
              showCellRightBorder
            />
          </div>
          <div
            style={{
              borderBottom: "0.5px solid var(--outlineVariant)",
            }}
          >
            <div className="row">
              <div className="col-6"></div>
              <div className="col-6">
                <div
                  className="d-flex flex-column"
                  style={{
                    gap: 40,
                    borderBottom: "0.5px solid var(--outlineVariant)",
                    marginTop: 100,
                  }}
                >
                  <div className="d-flex flex-row" style={{ gap: 151 }}>
                    <h2 id="total_cost_text">
                      Total Cost in{" "}
                      {viewInvoiceInfo.summaryDetails.OPERATING_CURRENCY ||
                        "RMB"}
                    </h2>
                    <p id="total_cost">
                      {viewInvoiceInfo.summaryDetails
                        .OPERATING_CURRENCY_SYMBOL || ""}
                      {viewInvoiceInfo.summaryDetails.TOTAL_GOODS_AMOUNT ||
                        "N/A"}
                    </p>
                  </div>
                  <div className="d-flex flex-row" style={{ gap: 151 }}>
                    <h2 id="total_cost_text">
                      Total Cost in{" "}
                      {viewInvoiceInfo.summaryDetails.INVOICE_CURRENCY || "USD"}{" "}
                      *{" "}
                      {viewInvoiceInfo.summaryDetails.INVOICE_CURRENCY_RATE ||
                        "0.14"}
                    </h2>
                    <p id="total_cost">
                      {viewInvoiceInfo?.summaryDetails?.INVOICE_CURRENCY_SYMBOL
                        ? viewInvoiceInfo.summaryDetails.INVOICE_CURRENCY_SYMBOL
                        : ""}

                      {viewInvoiceInfo?.summaryDetails?.TOTAL_GOODS_AMOUNT &&
                      viewInvoiceInfo?.summaryDetails?.INVOICE_CURRENCY_RATE
                        ? (
                            viewInvoiceInfo.summaryDetails.TOTAL_GOODS_AMOUNT *
                            viewInvoiceInfo.summaryDetails.INVOICE_CURRENCY_RATE
                          ).toFixed(2)
                        : "N/A"}
                    </p>
                  </div>
                  <div className="d-flex flex-row" style={{ gap: 151 }} F>
                    <h2 id="total_cost_text">Agent Commission</h2>
                    <p id="total_cost">
                      {viewInvoiceInfo.summaryDetails.INVOICE_CURRENCY_SYMBOL ||
                        " "}
                      {viewInvoiceInfo.summaryDetails
                        .AGENTS_COMMISION_PERCENTAGE || "N/A"}
                    </p>
                  </div>
                  <div
                    className="d-flex flex-row"
                    style={{ gap: 119, marginBottom: 28 }}
                  >
                    <h2 id="total_cost_text" style={{ width: 264 }}>
                      Inland Transportation: 1 X 40FTHQ
                    </h2>
                    <p id="total_cost">
                      {viewInvoiceInfo.summaryDetails.INVOICE_CURRENCY_SYMBOL ||
                        ""}
                      {viewInvoiceInfo.summaryDetails.TOTAL_INV_AMOUNT || "N/A"}
                    </p>
                  </div>
                </div>

                <div
                  className="d-flex flex-column"
                  style={{
                    gap: 20,
                    margin: "20px 72px 80px 0px",
                  }}
                >
                  <div className="d-flex flex-row" style={{ gap: 151 }}>
                    <h2 id="total_cost_text">Total Value</h2>
                    <h2 id="total_cost" style={{ fontWeight: 600 }}>
                      {viewInvoiceInfo.summaryDetails.INVOICE_CURRENCY_SYMBOL ||
                        ""}
                      {viewInvoiceInfo.summaryDetails.TOTAL_INV_AMOUNT || "N/A"}
                    </h2>
                  </div>
                  <div
                    className="d-flex flex-row align-items-center"
                    style={{ gap: 13 }}
                  >
                    <h2 id="total_cost_text" style={{ width: 524 }}>
                      SAY “{" "}
                      {viewInvoiceInfo.summaryDetails.INVOICE_CURRENCY || " "}{" "}
                      {convertAmountToText(
                        viewInvoiceInfo.summaryDetails.TOTAL_INV_AMOUNT || "N/A"
                      )}
                      ” ONLY
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="d-flex flex-column gap-4"
            style={{
              marginTop: 44,
            }}
          >
            <div className="d-flex flex-row align-items-center gap-2">
              <div
                style={{
                  width: 8,
                  height: 44,
                  background: "var(--tertiaryContainer, #BA3CBB)",
                }}
              ></div>
              <h2 id="shipping_details">Delivery Info</h2>
            </div>
            <div
              className="d-flex flex-row "
              style={{
                gap: "64px",
              }}
            >
              <div className="d-flex flex-column gap-2">
                <label>Delivery Date</label>
                <TextInputWithAll
                  type="date"
                  value={deliveryDate}
                   placeholder="Enter Delivery Date"
                  onChange={(e) =>
                    setdeliveryDate(convertToUnixTimestamp(e.target.value))
                  }
                 
                />
              </div>
              <div className="d-flex flex-column gap-2">
                <label>Vessel Name</label>

                <TextInputWithAll
                  type="text"
                  value={vessel_Name}
                  onChange={(e) => setvessel_Name(e.target.value)}
                  placeholder="Enter Vessel Name"
                />
              </div>
            </div>
            <div
              className="d-flex flex-row "
              style={{
                gap: "64px",
              }}
            >
              <div className="d-flex flex-column gap-2">
                <label>Sailing Date</label>
                <TextInputWithAll
                  type="date"
                  value={shippingDate}
                  onChange={(e) =>
                    setshippingDate(convertToUnixTimestamp(e.target.value))
                    
                  }
                  name="ShippingDate"
                  placeholder="Enter Sailing Date"
                />
              </div>

              <DropDownInput
                label="Payment Type"
                options={PaymentOptions}
                id="select_status"
                placeholder="Select Status"
                onChange={(e) => setpaymentType(e.target.value)}
              />
            </div>
            <div
              className="d-flex flex-row "
              style={{
                gap: "64px",
                marginBottom: 108,
              }}
            >
              <div className="d-flex flex-column gap-2">
                <label>Container No</label>
                <TextInputWithAll
                  type="text"
                  value={containerNo}
                  onChange={(e) => setcontainerNo(e.target.value)}
                  placeholder="Enter Container No"
                />
              </div>
              <div className="d-flex flex-column gap-2">
                <label>Seal No</label>
                <TextInputWithAll
                  type="text"
                  value={sealNo}
                  onChange={(e) => setSealNo(e.target.value)}
                  placeholder="Enter Seal No"
                />
              </div>
            </div>
          </div>
        </div>
        <div id="po_footer" className="pi_footer">
          <div id="add_supplier_buttons" className="container">
            {" "}
            <a href="/customers/customer-info" className="text-decoration-none">
              {" "}
              <div id="cancel">Cancel</div>
            </a>
            <div
              className="d-flex align-items-center justify-content-right "
              style={{ gap: "12px" }}
            >
              <a
                href="/customers/view-invoice"
                className="text-decoration-none"
              >
                <div id="preview_packaging">
                  Preview Invoice{" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M16.0052 9.41421L7.39858 18.0208L5.98438 16.6066L14.591 8H7.00519V6H18.0052V17H16.0052V9.41421Z"
                      fill="#635BFF"
                    />
                  </svg>
                </div>
              </a>
              <a
                // href="/customers/customer-info"
                className="text-decoration-none"
              >
                <div id="submitsupplier" onClick={handleModify}>
                  Update Invoice
                </div>
              </a>
              <ToastContainer
                position="bottom-center"
                style={{ width: "max-content" }}
              />
            </div>
          </div>
        </div>
        <CustomerPurchaseHistory
          show={historyShow}
          onHide={() => setHistoryShow(false)}
        />
      </div>
    </div>
  );
}
